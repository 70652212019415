import React, { Fragment } from "react";
import "../../styles.css";
import { Button } from "antd";
import line from "../../../../assets/images/svg-icons/line.svg";
import Heading from "../../../../components/heading";
import "./styles.css";
import { useForm } from "react-hook-form";
import moment from "moment";
import { Loader } from "../../../../shared/loaders";
import dayjs from "dayjs";


type FormInputs = {
  note: string;
};
function TeacherNoteWeek1(props: any) {
  const { register, handleSubmit, reset, setError, formState: { errors } } = useForm<FormInputs>();
  const formSubmit = (data) => {
    props.addNote(data.note);
    reset();
  };
  React.useEffect(() => {
    setError("note", {
      type: "manual",
      message: "Please fill note."
    });
  }, [setError])
  const { hideForm } = props;
  const windowsize = window.screen.width;
  const isMobileView = windowsize <= 1024;
  return (
    <div className="week__content">
      {props.weeklyNotes
        ?.filter((x) => x.weekNo == props.selectedWeekNumber)
        .map((week) => {
          return (
            <Fragment key={week.patientTrialWeekId}>
              <Heading
                heading={"Week " + week.weekNo + " Note"}
                subHeading={
                  isMobileView
                    ? ""
                    : "(" +
                      dayjs(week.patientTrialWeekStartDate).format(
                        "MM/DD/YYYY"
                      ) +
                      " - " +
                      dayjs(week.patientTrialWeekEndDate).format(
                        "MM/DD/YYYY"
                      ) +
                      ")"
                }
                styleName="weekHeading"
                ccfSubHeading="ccfSubHeading"
              />
              <img src={line} alt="line" />
              <div className="weekNote__list">
                {week.notes?.length <= 0 && (
                  <Heading
                    heading=""
                    subHeading="-No Note-"
                    styleName="weekNote__heading"
                  />
                )}
                {week.notes?.map((note) => {
                  let noteDate = dayjs(note.createdOn);
                  return (
                    <Fragment key={"notes_" + note.id}>
                      <div>
                        <h3 className="heading weekNote__heading">
                          {noteDate.format("dddd") +
                            " (" +
                            noteDate.format("MM/DD/YYYY") +
                            ")"}
                          <span className="time-stamp">
                            {noteDate.format("HH:mm")}
                          </span>
                        </h3>
                        <pre style={{ whiteSpace: "break-spaces" }}>
                          {note.note}
                        </pre>
                      </div>
                      {/* <Heading
                  heading={noteDate.format("dddd") + " (" + noteDate.format("MM/DD/YYYY") + ")"}
                  subHeading={note.note}
                  styleName="weekNote__heading"
                  ccfSubHeading="weekNote__subHeading"
                /> */}
                      <hr />
                    </Fragment>
                  );
                })}
              </div>
            </Fragment>
          );
        })}
      <form onSubmit={handleSubmit(formSubmit)}>
        <div className="week__form">
          <div className="medical-group textarea_group">
            <label className="form-label">Add Note</label>
            <textarea
              placeholder="If you have any specific note to share about patient please write here."
              defaultValue=""
              maxLength={1000}
              readOnly={props.selectedWeekNumber <= 0}
              className={
                !errors.note
                  ? "form-control textareaNote"
                  : "form-control textareaNote is-invalid"
              }
              {...register('note', {
                required: "Please fill note.",
                maxLength: 1000,
              })}
            ></textarea>
            {errors.note && (
              <div className="invalid-feedback">{errors.note?.message}</div>
            )}
          </div>
          <div className="drawer__btn right row">
            <Loader
              loading={props.isSaving}
              marginBottom="0px"
              marginTop="8px"
              width="368px"
            ></Loader>
            {!props.isSaving && (
              <Fragment>
                <Button
                  type="default"
                  size="large"
                  className="back_btn btn__margin col"
                  onClick={() => hideForm()}
                >
                  Close
                </Button>
                <Button
                  disabled={props.selectedWeekNumber <= 0}
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className="btn_add col"
                >
                  Submit Note
                </Button>
              </Fragment>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
export default TeacherNoteWeek1;
