import React, { Fragment } from "react";
import { HttpResponse } from "../../../../core";
import { AddSiblingPostModel } from "../../../../models/familyBackground/sibling/add-sibling-post.model";
import { AddSiblingModel } from "../../../../models/familyBackground/sibling/add-sibling.model";
import { SiblingDetailInfoModel } from "../../../../models/familyBackground/sibling/sibling-detail-info.model";
import { FamilyBackgroundService } from "../../../../services/familyBackground";
import { ShowSuccessMessage } from "../../../../shared/helpers";
import MedicalSiblingsAndBirthOrderForm from "./medicalSiblingsAndBirthOrderForm";

interface IState {
  patientId: string;
  isLoading: boolean;
  siblingList: AddSiblingPostModel;
  isFormSave?: Function;
  familyBackGroundStepUpdate?: Function;
 
}
export class MedicalSiblingsAndBirthOrder extends React.Component<any, IState> {
  private familyBackgroundService: FamilyBackgroundService;

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.familyBackgroundService = new FamilyBackgroundService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      patientId: this.props.patientId,
      siblingList: {
        patientId: this.props.patientId,
        siblings: [],
      } as AddSiblingPostModel,
    };
    return initialState;
  }
  componentDidMount() {
    this.loadData();
    window.scroll(0, 0);
  }

  postData = (data: any) => {
    let siblingPostData = data.siblingListModel;
    if (this.state.isLoading) {
      return;
    }
    if (data.isAutoSave) {
      this.setLoading(false);
    } else {
      this.setLoading(true);
    }
    this.familyBackgroundService
      .postSibling(siblingPostData)
      .then((res: HttpResponse<boolean>) => {
        if (res && res.result) {
        }
        if (!data.isAutoSave) {
          ShowSuccessMessage("Data Saved Successfully.");
          this.props.nextTabChange();
        }
        this.setLoading(false);
        this.props.isFormSave();
        this.loadData(data.isAutoSave);
      })
      .catch((ex) => {
        this.setLoading(false);
      });
  };
  previousTabChange = () => {
    this.props.previousTabChange();
  };

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }
  loadData = (isAutoSave?: any,) => {
    if (isAutoSave) {
      this.setLoading(false);
    } else {
      this.setLoading(true);
    }
    this.familyBackgroundService
      .getSiblingDetail(this.props.patientId)
      .then((res: HttpResponse<SiblingDetailInfoModel>) => {
          if (res && res.result) {
              this.props.familyBackGroundStepUpdate(3, res.result.noSibling);
              this.setLoading(false);
              if (isAutoSave) {
                  let pm = this.state.siblingList;
                  res.result.siblings.forEach((item, index) => {
                      pm.siblings[index].id = item.id;
                      pm.patientId = this.props.patientId;
                  });
                  this.setState(
                      {
                          siblingList: {
                              patientId: this.props.patientId,
                              siblings: pm.siblings,
                              noSibling: res.result.noSibling??true,
                          },
                      },
                      () => {
                      }
                  );
              }
              else {
                  this.setState(
                      {
                          siblingList: {
                              patientId: this.props.patientId,
                              siblings: res.result.siblings,
                              noSibling: res.result.noSibling??true,

                          },
                      },
                      () => {
                      }
                  );
              }

              if (res.result.noSibling == true && this.state.siblingList.siblings.length ==0) {
                  this.setState({
                      siblingList: {
                          patientId: this.props.patientId,
                          siblings: [
                              {
                                  id: "",
                                  name: "",
                                  age: 0,
                                  siblingType: 0,
                                  relationshipType: 0,

                              },
                          ],
                          noSibling: res.result.noSibling
                      } as AddSiblingPostModel,

                  });

              }
          }
        
      });
  };
  assignModel = (data: any) => {
    this.setState({
      siblingList: data.siblingListModel,
    });
  };
 
  deleteSibling = (siblingId) => {
    this.familyBackgroundService
      .deleteSibling(siblingId)
      .then((res: HttpResponse<boolean>) => {
        if (res && res.result) {
          this.loadData();
          
        }
      });
  };

  render() {
    return (
      <Fragment>
        <MedicalSiblingsAndBirthOrderForm
          onSubmit={this.postData}
          isSaving={this.state.isLoading}
          patientId={this.props.patientId}
          assignModel={this.assignModel}
          siblingList={this.state.siblingList}
          previousTabChange={this.previousTabChange}
          deleteSibling={this.deleteSibling}
        />
      </Fragment>
    );
  }
}
