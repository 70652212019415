import React, { Component } from "react";
import Tabs from "../../tabs";
import "../styles.css";
import { PrenatalPeriod } from "./prenatalPeriod";
import { PostnatalPeriod } from "./postNatalPeriod";
import { PerinatalPeriod } from "./perinatalPeriod";
import { MedicalPregnancy } from "./pregnancy";
import { BirthHistoryService } from "../../../services/birthHistory/birth-history.service";
import { GetBirthHistoryStepStatus } from "../../patient/models/get-birth-history-step-status.model";
import { HttpResponse } from "../../../core";


interface IProps {
  patientId: string;
  nextTabChange: Function;
  isFormSave?: Function;
}
interface State {
  tab: number;
  patientId: string;
  parentId: string;
  getBirthHistoryStepStatus?: GetBirthHistoryStepStatus
}
class MedicalBirthHistory extends Component<any, State> {
  private birthHistoryService: BirthHistoryService;
  state: State = { tab: 0, patientId: "", parentId: "" };
  changeTab(tab: any) {
    this.setState({
      tab,
    });
  }
  constructor(props) {
    super(props);
    this.birthHistoryService = new BirthHistoryService();
  }
  componentDidMount() {
    this.loadData();
  }
  loadData() {
    if (this.props.patientId) {
      this.birthHistoryService
        .getBirthHistoryStepStatus(this.props.patientId)
        .then((res: HttpResponse<GetBirthHistoryStepStatus>) => {
          if (res && res.result) {
            console.log(res.result);
            this.setState({ getBirthHistoryStepStatus: res.result });
            //this.getLastStep();
          }
        })
        .catch((ex) => {});
    }
  }
  birthHistoryStepUpdateHandler = (key, value) => {
    if (this.state.getBirthHistoryStepStatus) {
      if (key == 1) {
        this.state.getBirthHistoryStepStatus.pregnancyId = value;
      } else if (key == 2) {
        this.state.getBirthHistoryStepStatus.prenatalId = value;
      }
      else if (key == 3) {
        this.state.getBirthHistoryStepStatus.perinatalId = value;
      }
      else if (key == 4) {
        this.state.getBirthHistoryStepStatus.postnatalId = value;
      }
    }
  };
  render() {
    const { tab } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="medical__family_tabs d-none d-xl-block col-3">
            <Tabs
              styleName="familyTab"
              text="Pregnancy"
              steps=""
              stepsAct=""
              fullText="Pregnancy"
              familyTextName="A"
              image=""
              activeImage=""
              tabChange={() => this.changeTab(0)}
              selected={tab === 0}
              isData={this.state.getBirthHistoryStepStatus?.pregnancyId != null}
            />
            <Tabs
              styleName="familyTab"
              text="Prenatal Period"
              steps=""
              stepsAct=""
              fullText="Prenatal Period"
              familyTextName="B"
              image=""
              activeImage=""
              tabChange={() => this.changeTab(1)}
              selected={tab === 1}
              isData={this.state.getBirthHistoryStepStatus?.prenatalId != null}
            />
            <Tabs
              styleName="familyTab"
              text="Perinatal Period"
              steps=""
              stepsAct=""
              fullText="Perinatal Period"
              familyTextName="C"
              image=""
              activeImage=""
              tabChange={() => this.changeTab(2)}
              selected={tab === 2}
              isData={this.state.getBirthHistoryStepStatus?.perinatalId != null}
            />
            <Tabs
              styleName="familyTab"
              text="Postnatal Period"
              steps=""
              stepsAct=""
              fullText="Postnatal Period"
              familyTextName="D"
              image=""
              activeImage=""
              tabChange={() => this.changeTab(3)}
              selected={tab === 3}
              isData={this.state.getBirthHistoryStepStatus?.postnatalId != null}
            />
          </div>
          <div className="medical__family_content col">
            {tab === 0 && (
              <MedicalPregnancy
                patientId={this.props.patientId}
                nextTabChange={() => this.changeTab(1)}
                parentTabChange={this.props.parentTab}
                isFormSave={this.props.isFormSave}
                birthHistoryStepUpdate={this.birthHistoryStepUpdateHandler}
                isBirthHistoryOptional={this.state.getBirthHistoryStepStatus?.isBirthHistoryOptional}
              />
            )}
            {tab === 1 && (
              <PrenatalPeriod
                previousTabChange={() => this.changeTab(0)}
                nextTabChange={() => this.changeTab(2)}
                patientId={this.props.patientId}
                isFormSave={this.props.isFormSave}
                birthHistoryStepUpdate={this.birthHistoryStepUpdateHandler}
                isBirthHistoryOptional={this.state.getBirthHistoryStepStatus?.isBirthHistoryOptional}
              />
            )}
            {tab === 2 && (
              <PerinatalPeriod
                previousTabChange={() => this.changeTab(1)}
                nextTabChange={() => this.changeTab(3)}
                patientId={this.props.patientId}
                isFormSave={this.props.isFormSave}
                birthHistoryStepUpdate={this.birthHistoryStepUpdateHandler}
                isBirthHistoryOptional={this.state.getBirthHistoryStepStatus?.isBirthHistoryOptional}
              />
            )}
            {tab === 3 && (
              <PostnatalPeriod
                previousTabChange={() => this.changeTab(2)}
                patientId={this.props.patientId}
                parentTabChange={this.props.parentTab}
                nextTabChange={this.props.nextTabChange}
                isFormSave={this.props.isFormSave}
                birthHistoryStepUpdate={this.birthHistoryStepUpdateHandler}
                isBirthHistoryOptional={this.state.getBirthHistoryStepStatus?.isBirthHistoryOptional}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default MedicalBirthHistory;
