import React, { Component } from "react";
import { Modal, Popover } from "antd";
import MedicationStatus from "../../../medicationStatus";
import calendar from "../../../../assets/images/svg-icons/weekly_calendar.svg";
import parentMissing from "../../../../assets/images/svg-icons/reminder.svg";
import edit from "../../../../assets/images/svg-icons/edit.svg";
import note from "../../../../assets/images/svg-icons/note.svg";
import report from "../../../../assets/images/svg-icons/report.svg";
import deactivate from "../../../../assets/images/svg-icons/deactivate.svg";
import medicalRecord from "../../../../assets/images/svg-icons/socialMed-blue.svg";
import visitSummery from "../../../../assets/images/svg-icons/location.svg";
import "./styles.css";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { PatientListResponseModel } from "../../../../models/patientManagement/patient-list-response.model";
import { Pagination } from "../../../../shared/pagination/Pagination";
import { Loader } from "../../../../shared/loaders";
import { UserStatus } from "../../../../shared/enums/user-status.enum";
import { UserAccountStatus } from "../../../../shared/enums/show-user-status.enum";
import oops from "../../../../assets/images/svg-icons/no-result-found.svg";
import file from "../../../../assets/images/svg-icons/file.svg";
import dayjs from "dayjs";

interface IState {
  selectedRowKeys: any;
  patientManagementList: [];
  isModalVisible: boolean;
  id: string | null;
}
class PatientManagementTable extends Component<any, IState> {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      patientManagementList: this.props.patientManagementList,
      isModalVisible: false,
      id: null
    };
  }
  showDeactivateConfirm = (id,popOverId) => {
    this.setState({
      isModalVisible: true,
      id: id,
    });
    if(popOverId){
      document.getElementById(popOverId)?.click();
    }
  };
  handleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };
  handleDeactivate = () => {
    this.props.deactivatePatient(this.state.id);
    };
    handleAccountStatus = (val: any) => {
        let value = "-";
        if (val == 1) value = UserAccountStatus.AccountInitiated;
        else if (val == 2) value = UserAccountStatus.AccountInitiatedAndInvited;
        else if (val == 3) value = UserAccountStatus.AccountActivated;
        else if (val == 4) value = UserAccountStatus.AccountDeactivated;
        return value;
    };

  render() {
    const { selectedRowKeys } = this.state;
    const { sortActive, sortName, sortOrder } = this.props;
    return (
      <div className="mgmt__table">
        <table className="table table-hover">
          <thead>
            <tr key={"patientmgmt_"}>
              <th>
                <div className="mgmtTable__sorters">
                  <span>Patient</span>
                  <span className="sorter">
                    <span
                      className={`${
                        sortName === "Patient" && sortOrder === "asc"
                          ? "fas fa-caret-up caretActive"
                          : "fas fa-caret-up mgmt__caret"
                      }`}
                      onClick={(e) =>
                        this.props.handleSorting("Patient", "asc")
                      }
                    ></span>
                    <span
                      className={`${
                        sortName === "Patient" && sortOrder === "desc"
                          ? "fas fa-caret-down caretActive"
                          : "fas fa-caret-down mgmt__caret"
                      }`}
                      onClick={(e) =>
                        this.props.handleSorting("Patient", "desc")
                      }
                    ></span>
                  </span>
                </div>
              </th>
              <th>
                <div className="mgmtTable__sorters">
                  <span>Account Status</span>
                  <span className="sorter">
                    <span
                      className={`${
                        sortName === "AccountStatus" && sortOrder === "asc"
                          ? "fas fa-caret-up caretActive"
                          : "fas fa-caret-up mgmt__caret"
                      }`}
                      onClick={(e) =>
                        this.props.handleSorting("AccountStatus", "asc")
                      }
                    ></span>
                    <span
                      className={`${
                        sortName === "AccountStatus" && sortOrder === "desc"
                          ? "fas fa-caret-down caretActive"
                          : "fas fa-caret-down mgmt__caret"
                      }`}
                      onClick={(e) =>
                        this.props.handleSorting("AccountStatus", "desc")
                      }
                    ></span>
                  </span>
                </div>
              </th>
              <th>
                <div className="mgmtTable__sorters">
                  <span>Parent</span>
                  <span className="sorter">
                    <span
                      className={`${
                        sortName === "Parent" && sortOrder === "asc"
                          ? "fas fa-caret-up caretActive"
                          : "fas fa-caret-up mgmt__caret"
                      }`}
                      onClick={(e) => this.props.handleSorting("Parent", "asc")}
                    ></span>
                    <span
                      className={`${
                        sortName === "Parent" && sortOrder === "desc"
                          ? "fas fa-caret-down caretActive"
                          : "fas fa-caret-down mgmt__caret"
                      }`}
                      onClick={(e) =>
                        this.props.handleSorting("Parent", "desc")
                      }
                    ></span>
                  </span>
                </div>
              </th>
              <th>
                <div className="mgmtTable__sorters">
                  <span>Physician</span>
                  <span className="sorter">
                    <span
                      className={`${
                        sortName === "Physician" && sortOrder === "asc"
                          ? "fas fa-caret-up caretActive"
                          : "fas fa-caret-up mgmt__caret"
                      }`}
                      onClick={(e) =>
                        this.props.handleSorting("Physician", "asc")
                      }
                    ></span>
                    <span
                      className={`${
                        sortName === "Physician" && sortOrder === "desc"
                          ? "fas fa-caret-down caretActive"
                          : "fas fa-caret-down mgmt__caret"
                      }`}
                      onClick={(e) =>
                        this.props.handleSorting("Physician", "desc")
                      }
                    ></span>
                  </span>
                </div>
              </th>
              <th>
                <div className="mgmtTable__sorters">
                  <span>Teacher</span>
                  <span className="sorter">
                    <span
                      className={`${
                        sortName === "Teacher" && sortOrder === "asc"
                          ? "fas fa-caret-up caretActive"
                          : "fas fa-caret-up mgmt__caret"
                      }`}
                      onClick={(e) =>
                        this.props.handleSorting("Teacher", "asc")
                      }
                    ></span>
                    <span
                      className={`${
                        sortName === "Teacher" && sortOrder === "desc"
                          ? "fas fa-caret-down caretActive"
                          : "fas fa-caret-down mgmt__caret"
                      }`}
                      onClick={(e) =>
                        this.props.handleSorting("Teacher", "desc")
                      }
                    ></span>
                  </span>
                </div>
              </th>
              <th>
                <div className="mgmtTable__sorters">
                  <span>Monitoring Date</span>
                  <span className="sorter">
                    <span
                      className={`${
                        sortName === "MonitoringDate" && sortOrder === "asc"
                          ? "fas fa-caret-up caretActive"
                          : "fas fa-caret-up mgmt__caret"
                      }`}
                      onClick={(e) =>
                        this.props.handleSorting("MonitoringDate", "asc")
                      }
                    ></span>
                    <span
                      className={`${
                        sortName === "MonitoringDate" && sortOrder === "desc"
                          ? "fas fa-caret-down caretActive"
                          : "fas fa-caret-down mgmt__caret"
                      }`}
                      onClick={(e) =>
                        this.props.handleSorting("MonitoringDate", "desc")
                      }
                    ></span>
                  </span>
                </div>
              </th>
              <th>
                <div className="mgmtTable__sorters">
                  <span> Medical-Social Form </span>
                  <span className="sorter">
                    <span
                      className={`${
                        sortName === "MedicalForm" && sortOrder === "asc"
                          ? "fas fa-caret-up caretActive"
                          : "fas fa-caret-up mgmt__caret"
                      }`}
                      onClick={(e) =>
                        this.props.handleSorting("MedicalForm", "asc")
                      }
                    ></span>
                    <span
                      className={`${
                        sortName === "MedicalForm" && sortOrder === "desc"
                          ? "fas fa-caret-down caretActive"
                          : "fas fa-caret-down mgmt__caret"
                      }`}
                      onClick={(e) =>
                        this.props.handleSorting("MedicalForm", "desc")
                      }
                    ></span>
                  </span>
                </div>
              </th>
              <th>
                <div className="mgmtTable__sorters">
                  <span> Attention Required</span>
                  <span className="sorter">
                    <span
                      className={`${
                        sortName === "AttentionRequired" && sortOrder === "asc"
                          ? "fas fa-caret-up caretActive"
                          : "fas fa-caret-up mgmt__caret"
                      }`}
                      onClick={(e) =>
                        this.props.handleSorting("AttentionRequired", "asc")
                      }
                    ></span>
                    <span
                      className={`${
                        sortName === "AttentionRequired" && sortOrder === "desc"
                          ? "fas fa-caret-down caretActive"
                          : "fas fa-caret-down mgmt__caret"
                      }`}
                      onClick={(e) =>
                        this.props.handleSorting("AttentionRequired", "desc")
                      }
                    ></span>
                  </span>
                </div>
              </th>
              <th>
                <span>Weekly Status Updates</span>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!this.props.isLoading &&
              this.props.patientManagementList &&
              this.props.patientManagementList.map((item, index) => {
                return (
                  <tr key={"patientmgmt_" + index}>
                    <td>
                      <div className="mgmt__col1">
                        <div
                          className="mgmtUser-icon"
                          style={{
                            backgroundColor:
                              index % 2 == 0 ? "#d3dee8" : "#dedab7",
                          }}
                        >
                          <span>{item.initial}</span>
                        </div>
                        <div className="mgmtName-role">
                          {(item.userStatus == UserStatus.AccountActivated ||
                            item.isDeactivated) && (
                            <NavLink
                              to={
                                "/clinician/patientdashboard/" +
                                item.patientId +
                                "/wUpdate"
                              }
                            >
                              <span className="mgmt__line1">
                                {item.patientName}
                              </span>
                            </NavLink>
                          )}
                          {item.userStatus != UserStatus.AccountActivated &&
                            !item.isDeactivated && (
                              <span className="mgmt__line1">
                                {item.patientName}
                              </span>
                            )}
                          <span className="mgmt__line2">MRN: {item.mr_No}</span>
                          <span className="mgmt__line2">
                            DOB:{" "}
                            {item.dateOfBirth != null
                              ? dayjs(item.dateOfBirth).format("MM/DD/YYYY")
                              : "-"}
                            , {item.gender}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="mgmtName-role">
                        {this.handleAccountStatus(item.userStatus)}
                      </span>
                    </td>
                    <td>
                      <div className="mgmtName-role">
                        <span className="mgmt__line1">{item.guardianName}</span>
                        <span className="mgmt__line2">
                          {item.guardianEmailId}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="mgmtName-role">
                        <span className="mgmt__line1">
                          {item.physicianName}
                        </span>
                        <span className="mgmt__line2">
                          {item.physicianEmailId}
                        </span>
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div className="mgmtName-role">
                        <span className="mgmt__line1">{item.teacherName}</span>
                        <span className="mgmt__line2">
                          {item.teacherEmailId}
                        </span>
                      </div>
                    </td>
                    <td>
                      <span className="mgmt__address">
                        {item.trialStartDate != null
                          ? dayjs(item.trialStartDate).format("MM/DD/YYYY")
                          : "-"}
                      </span>
                    </td>
                    <td>
                      {" "}
                      <span className="mgmt__address">
                        {item.isMedicalSocialHistoryFormSubmitted == true
                          ? "Completed"
                          : "Incomplete"}
                      </span>
                    </td>
                    <td>
                      <span className="mgmt__address">
                        {item.severityLevel >= 5
                          ? "High Severity: " + item.severityLevel
                          : "No"}
                      </span>
                    </td>
                    <td>
                      {" "}
                      <div>
                        <MedicationStatus
                          text={"BL"}
                          backgroundColor={
                            item.isBaseLine == true
                              ? "var(--color-fadeGreen)"
                              : "var(--color-fadeOrange)"
                          }
                          color="var(--color-slaty)"
                          statusStyle="patient__header_status"
                        />
                        {item.parentMedicationStatusList &&
                          item.parentMedicationStatusList.map((item, i) => {
                            return (
                              <MedicationStatus
                                text={"W" + item.weekNo}
                                backgroundColor={
                                  item.hasData
                                    ? "var(--color-fadeGreen)"
                                    : "var(--color-fadeOrange)"
                                }
                                color="var(--color-slaty)"
                                statusStyle="patient__header_status"
                                index={i}
                              />
                            );
                          })}
                      </div>
                    </td>
                    <td>
                      <Popover
                        placement="leftTop"
                        className="mgmt__popover__content"
                        content={
                          <div className="mgmt__popover">
                            <ul>
                              {(item.userStatus ==
                                UserStatus.AccountActivated ||
                                item.isDeactivated) && (
                                <NavLink
                                  to={
                                    "/clinician/patientdashboard/" +
                                    item.patientId +
                                    "/wUpdate"
                                  }
                                >
                                  <li>
                                    <img src={calendar} alt="calendar" />
                                    View Weekly Updates
                                  </li>
                                </NavLink>
                              )}
                              {(item.userStatus ==
                                UserStatus.AccountActivated ||
                                item.isDeactivated) && (
                                <NavLink
                                  to={
                                    "/clinician/patientdashboard/" +
                                    item.patientId +
                                    "/vSummary"
                                  }
                                >
                                  <li>
                                    <img src={visitSummery} alt="calendar" />
                                    Visit Summary
                                  </li>
                                </NavLink>
                              )}
                              {(item.userStatus ==
                                UserStatus.AccountActivated ||
                                item.isDeactivated) && (
                                <NavLink
                                  to={
                                    "/clinician/patientdashboard/" +
                                    item.patientId +
                                    "/report"
                                  }
                                >
                                  <li>
                                    <img src={file} alt="calendar" />
                                    Final Report
                                  </li>
                                </NavLink>
                              )}
                              {(item.userStatus ==
                                UserStatus.AccountActivated ||
                                item.isDeactivated) && (
                                <NavLink
                                  to={
                                    "/clinician/patientdashboard/" +
                                    item.patientId +
                                    "/social"
                                  }
                                >
                                  <li>
                                    <img src={medicalRecord} alt="calendar" />
                                    Medical-Social History
                                  </li>
                                </NavLink>
                              )}
                              <NavLink
                                to={
                                  "/patient/addPatient/" +
                                  item.patientId +
                                  "/edit"
                                }
                              >
                                <li>
                                  <img src={edit} alt="edit" />
                                  Edit Patient
                                </li>
                              </NavLink>
                              {(item.userStatus ==
                                UserStatus.AccountActivated ||
                                item.isDeactivated) && (
                                <NavLink
                                  to={
                                    "/clinician/patientdashboard/" +
                                    item.patientId +
                                    "/cnote"
                                  }
                                >
                                  <li>
                                    <img src={note} alt="calendar" />
                                    View Clinician Note
                                  </li>
                                </NavLink>
                              )}
                              {item.userStatus == UserStatus.AccountActivated &&
                                item.isDeactivated && (
                                  <NavLink
                                    to={
                                      "/clinician/patientdashboard/" +
                                      item.patientId +
                                      "/ptnote"
                                    }
                                  >
                                    <li>
                                      <img src={note} alt="calendar" />
                                      View P/T Note
                                    </li>
                                  </NavLink>
                                )}
                              {item.userStatus == UserStatus.AccountActivated &&
                                item.isDeactivated != true && (
                                  <li
                                    onClick={() =>
                                      this.showDeactivateConfirm(
                                        item.patientId,
                                        "divPatientManagementPopover" +
                                          (index + 1)
                                      )
                                    }
                                  >
                                    <img src={deactivate} alt="calendar" />
                                    Deactivate Patient
                                  </li>
                                )}
                            </ul>
                          </div>
                        }
                        trigger="click"
                      >
                        <span>
                          <i
                            className="fa fa-ellipsis-v"
                            id={"divPatientManagementPopover" + (index + 1)}
                          ></i>
                        </span>
                      </Popover>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {!this.props.isLoading &&
          this.props.patientManagementList.length == 0 && (
            <div className="text-center">
              <div className="noRecord__table">
                <img src={oops} alt="oops" />
                <h2>No result found</h2>
                <p>
                  There are no matching records for your search, please try
                  again.
                </p>
              </div>
            </div>
          )}
        {this.props.isLoading && (
          <Loader
            loading={this.props.isLoading}
            marginBottom="0px"
            marginTop="8px"
            width="368px"
          />
        )}
        {!this.props.isLoading && (
          <div className="pull-right page-list">
            <Pagination
              currentPage={this.props.currentPage}
              pageCount={this.props.pageCount}
              onChangePage={this.props.onChangePage}
            />
          </div>
        )}
        <Modal
          title="Deactivate"
          open={this.state.isModalVisible}
          onOk={this.handleDeactivate}
          okType="primary"
          onCancel={this.handleCancel}
          okText="Yes"
          cancelText="No"
          //className="modal__row"
          closable={false}
        >
          <p>Are you sure you want to deactivate this patient?</p>
        </Modal>
      </div>
    );
  }
}
export default PatientManagementTable;
