import React, { Fragment } from "react";
import ChildGuardianInfo from "../../../components/childGuardianInfo";
import ContentCard from "../../../components/contentCard";
import MedicationStatus from "../../../components/medicationStatus";
import { PatientDetailModel } from "../../../components/patient/models/patient-detail.model";
import { MedicationStatusModel } from "../../../models/medication/medication-status.model";
import { Gender } from "../../../shared/enums/gender.enum";
interface IProps {
  patientDetailModel: PatientDetailModel;
  isLoading: boolean;
  bcColor: string;
  medicationStatus: MedicationStatusModel[];
  isBaseLine: boolean;
  trialStatus: number;
}
export function ChildDetailHeaderForm(props: IProps) {
  let patientDetailModel = props.patientDetailModel;
  patientDetailModel.teacherFirstName = patientDetailModel.teacherFirstName ? patientDetailModel.teacherFirstName : "";
  patientDetailModel.teacherLastName = patientDetailModel.teacherLastName ? patientDetailModel.teacherLastName : "";
  patientDetailModel.monitoringPhysicianFirstName = patientDetailModel.monitoringPhysicianFirstName ? patientDetailModel.monitoringPhysicianFirstName : "";
  patientDetailModel.monitoringPhysicianLastName = patientDetailModel.monitoringPhysicianLastName ? patientDetailModel.monitoringPhysicianLastName : "";
  let medicationStatus = props.medicationStatus;
  const handleGender = (val: any) => {
    let value = "-";
    if (val == Gender.Male) value = "Male";
    else if (val == Gender.Female) value = "Female";
    else if (val == Gender.Others) value = "Others";
    return value;
  };
    let options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    };
  return (
    <Fragment>
      <ContentCard
        styleName="childTab__status remove-cursor"
        contents={[
          <div className="status__wrapper">
            <div className="statusInfo__cover">
              {patientDetailModel != null && (
                <div className="user-details">
                  <span className="name_wrap" style={{ backgroundColor: props.bcColor }}>
                    {patientDetailModel.initials == null ? "" : patientDetailModel.initials}
                  </span>
                  <p className="ccfChild_username">
                    {patientDetailModel.lastName == null ? "" : patientDetailModel.lastName + ", "}
                    {patientDetailModel.firstName == null ? "" : patientDetailModel.firstName}
                    <br />
                    <span>MRN: {patientDetailModel.mr_No}</span> <br />
                    <span>
                      Date of Birth:{" "}
                      {patientDetailModel.dateOfBirth == null
                       ? ""
                        : new Date(patientDetailModel.dateOfBirth).toLocaleDateString(
                            "en",
                            options
                          )}
                      , {handleGender(patientDetailModel.gender)}
                    </span>
                  </p>
                </div>
              )}
            </div>

            <div className="statusInfo__cover">
                    {patientDetailModel.teacherFirstName && (
                <ChildGuardianInfo
                  designation="Teacher"
                  name={
                      patientDetailModel.teacherFirstName  + " " +  patientDetailModel.teacherLastName 
                  }
                  email={patientDetailModel.teacherEmailId ?? ""}
                  guardian="historyViewHeader"
                  guardianStyle="historyViewH5"
                />
              )}
            </div>
            <div className="statusInfo__cover">
              {patientDetailModel.monitoringPhysicianFirstName && (
                <ChildGuardianInfo
                  designation="Physician"
                  name={
                    patientDetailModel.monitoringPhysicianFirstName +
                    " " +
                    patientDetailModel.monitoringPhysicianLastName
                  }
                  email={patientDetailModel.monitoringPhysicianEmailId}
                  guardian="historyViewHeader"
                  guardianStyle="historyViewH5"
                />
              )}
            </div>
            {props.isBaseLine && medicationStatus.length > 0 && props.trialStatus == 2 && (
              <div>
                <ChildGuardianInfo designation="Medication Status" name="" email="" />
                <div>
                  {medicationStatus &&
                    medicationStatus.map((item, i) => {
                      return (
                        <MedicationStatus
                          text={"W" + item.weekNo}
                          backgroundColor={
                            item.hasData && item.weekType == 1
                              ? "var(--color-fadeGreen)"
                              : "var(--color-lightWhite)"
                          }
                          color="var(--color-navyBlue)"
                          statusStyle=""
                          index={i}
                        />
                      );
                    })}
                </div>
              </div>
            )}
          </div>,
        ]}
      />
    </Fragment>
  );
}
