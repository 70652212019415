import React, { Component } from "react";
import { AuthService } from "../components/auth/services/auth.service";
import { GetUserInfo, setCookie, ShowSuccessMessage } from "../shared/helpers";
import TopHeader from "./header/header";
import NavigationBar from "./navigation/navigation";
import "./styles.css";
import { AppConfig, HttpResponse } from "../core";
import { Role } from "../shared/enums/role.enum";
import { Modal, Button } from "antd";
import { AddUserModel, UserDetailModel } from "../models/users";
import { UserService } from "../services/user";
import { PatientService } from "../components/patient/services";
import { PatientDetailModel } from "../components/patient/models/patient-detail.model";
import { AddPatientModel } from "../components/patient/models/add-patient";
import { AddTeacherModel } from "../models/teacher/add-teacher.model";
import { TeacherModel } from "../models/teacher/teacher.model";
import { PatientsListByParentIdModel } from "../components/patient/models/patients-list-By-parentId.model";
import { RespondentInformation } from "../models/Respondent/RespondentInfo";
import { AddRespondentModel } from "../models/Respondent/AddRespondentModel";

export default class LayoutPage extends Component<any, any> {
  private service: AuthService;
  private patientService: PatientService;
  private userService: UserService;
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      designation: "",
      visible: false,
      isEditModalVisible: false,
      userModel: {} as AddUserModel,
      patientModel: {} as AddPatientModel,
      teacherModel: {} as AddTeacherModel,
      respondentModel: {} as RespondentInformation,
      reRenderForm: false,
    };
    this.service = new AuthService();
    this.userService = new UserService();
    this.patientService = new PatientService();
  }

  componentDidMount() {
    if (this.props.isPassExpire) {
      this.showModal();
    }
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
    var data = {
      returnUrl: window.location.href,
      email: this.props.email,
    };
    localStorage.clear();
    setCookie(AppConfig.refreshTokenKey, '', -1);
    window.location.href =
      AppConfig.identityUrl +
      "account/changepassword?email=" +
      data.email +
      "&returnUrl=" +
      data.returnUrl;
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancelEditProfile = () => {
    this.setState({
      isEditModalVisible: false,
    });
  };
  handleOpenEditProfile = () => {
    if (
        this.props.designation.includes(Role.SuperAdmin) ||
        this.props.designation.includes(Role.Admin) ||
        this.props.designation.includes(Role.Clinician)
    ) {
      this.loadUserDetail();
    }
    if (this.props.designation === Role.Parent) {
      this.loadParentData();
    }
    if (this.props.designation === Role.Teacher) {
      this.loadTeacherData();
    }
    if (this.props.designation === Role.Respondent) {
      this.loadRespondentData();
    }
  };
  loadUserDetail() {
    if (this.props.id) {
      this.userService
        .getUserById(this.props.id)
        .then((res: HttpResponse<UserDetailModel>) => {
          if (res && res.result) {
            let user: AddUserModel = {
              id: res.result.id,
              emailId: res.result.email,
              firstName: res.result.firstName,
              lastName: res.result.lastName,
              isInviteSend: res.result.isInviteSend,
              speciality: res.result.speciality,
              roles: res.result.roles,
              identityId: res.result.identityId,
            };
            this.setState(
              {
                userModel: user,
                reRenderForm: true,
                isEditModalVisible: true,
              },
              () => {
                this.setState({ reRenderForm: false });
              }
            );
          }
        })
        .catch((ex) => {});
    }
  }
  handleSave = (data) => {
    let user: AddUserModel = {
      id: this.props.id,
      firstName: data.firstName,
      lastName: data.lastName,
      speciality: data.speciality,
      emailId: this.state.userModel.emailId,
      identityId: this.state.userModel.identityId,
      firstNameError: data.firstNameError,
      lastNameError: data.lastNameError,
    };
    this.setState({
      userModel: user,
    });
    if (user.lastNameError == "" && user.firstNameError == "") {
      this.userService
        .postUser(this.state.userModel)
        .then((res: HttpResponse<string>) => {
          if (res && res.result) {
            ShowSuccessMessage("Profile updated successfully.");
            this.props.handleUserDetail(
              this.state.userModel.firstName,
              this.state.userModel.lastName
            );
            this.handleCancelEditProfile();
          }
        })
        .catch((ex) => {});
    }
  };
  logOut = () => {
    localStorage.clear();
    // set cookie expiry time to now, so browser will automatically clear the cookie
    setCookie(AppConfig.refreshTokenKey, '', -1);
    this.service.logOut();
  };
  changePassword = () => {
    this.service.changePassword(this.props.email);
    };
  
  loadParentData() {
      let id;
      this.patientService
          .getPatientsListByParentId(this.props.id)
          .then((res: HttpResponse<PatientsListByParentIdModel>) => {
              if (res && res.result) {
                  this.setState({ patientsListByParentIdModel: res.result });
                  if (res.result.patientDetails.length > 0) {

                      id = res.result.patientDetails[0].id;
                      this.loadPatientData(id);
                  }
              }
          })
          .catch((ex) => { });
  }
  handleParentSave = (data: AddPatientModel) => {
    let patient = data;
    patient.id = this.state.patientModel.id;
    patient.identityId = this.state.patientModel.identityId;
    patient.guardianId = this.state.patientModel.guardianId;
    patient.mr_No = this.state.patientModel.mr_No;
    patient.firstName = this.state.patientModel.firstName;
    patient.lastName = this.state.patientModel.lastName;
    patient.dateOfBirth = this.state.patientModel.dateOfBirth;
    patient.gender = this.state.patientModel.gender;
    patient.physicianModel.id = this.state.patientModel.physicianId;
    patient.physicianModel.pLastName = this.state.patientModel.physicianModel.physicianLastName;
    patient.physicianModel.speciality = this.state.patientModel.physicianModel.speciality;
    patient.physicianModel.hospitalName = this.state.patientModel.physicianModel.hospitalName;
    patient.physicianModel.pFirstName = this.state.patientModel.physicianModel.physicianFirstName;

    this.setState({
      patientModel: patient,
    });
    if (
      patient.relationshipError == "" &&
      patient.guardianFirstNameError == "" &&
      patient.guardianLastNameError == "" &&
      patient.contactNoError == "" &&
      patient.address1Error == "" &&
      patient.cityError == "" &&
      patient.stateError == "" &&
      patient.zipcodeError == ""
    ) {
      this.patientService
        .postUser(patient)
        .then((res: HttpResponse<any>) => {
          if (res && res.result) {
            ShowSuccessMessage("Profile updated successfully.");

            this.props.handleUserDetail(
              this.state.patientModel.guardianFirstName,
              this.state.patientModel.guardianLastName
            );
            this.handleCancelEditProfile();
          }
        })
        .catch((ex) => {});
    }
  };
  loadTeacherData = () => {
    if (this.props.id) {
      this.patientService
        .getTeacherById(this.props.id)
        .then((res: HttpResponse<TeacherModel>) => {
          if (res && res.result) {
            let teacher: AddTeacherModel = {
              teacherId: res.result.id,
              tFirstName: res.result.firstName,
              tLastName: res.result.lastName,
              schoolDistrict: res.result.schoolDistrict,
              schoolName: res.result.schoolName,
              schoolContactNo: res.result.schoolContactNo,
              tEmailId: res.result.emailId,
              identityId: res.result.identityId,
            };
            this.setState(
              {
                teacherModel: teacher,
                reRenderForm: true,
                isEditModalVisible: true,
              },
              () => {
                this.setState({ reRenderForm: false });
              }
            );
          }
        })
        .catch((ex) => {});
    }
  };
  handleTeacherSave = (data) => {
    let teacher: AddTeacherModel = {
      teacherId: this.props.id,
      tFirstName: data.tFirstName,
      tLastName: data.tLastName,
      schoolName: data.schoolName,
      tEmailId: this.state.teacherModel.tEmailId,
      schoolContactNo: data.schoolContactNo,
      schoolDistrict: data.schoolDistrict,
      identityId: this.state.teacherModel.identityId,
      tFirstNameError: data.tFirstNameError,
      tLastNameError: data.tLastNameError,
      schoolContactNoError: data.schoolContactNoError,
      schoolDistrictError: data.schoolDistrictError,
      schoolNameError: data.schoolNameError,
    };
    this.setState({
      teacherModel: teacher,
    });
    if (
      teacher.tLastNameError == "" &&
      teacher.tFirstNameError == "" &&
      teacher.schoolDistrictError == "" &&
      teacher.schoolContactNoError == "" &&
      teacher.schoolNameError == ""
    ) {
      this.patientService
        .postTeacher(this.state.teacherModel)
        .then((res: HttpResponse<string>) => {
          if (res && res.result) {
            ShowSuccessMessage("Profile updated successfully.");
            this.props.handleUserDetail(
              this.state.teacherModel.tFirstName,
              this.state.teacherModel.tLastName
            );
            this.handleCancelEditProfile();
          }
        })
        .catch((ex) => {});
    }
  };
  loadRespondentData = () => {
    console.log(this.props.id)
    if (this.props.id) {
      this.patientService
        .getRespondentById(this.props.id)
        .then((res: HttpResponse<RespondentInformation>) => {
          if (res && res.result) {
            console.log(res.result)
            let respondent: RespondentInformation = {
              id: res.result.id,
              firstName: res.result.firstName,
              lastName: res.result.lastName,
              email: res.result.email,
              relation: res.result.relation,
              getsDosageQuestions: res.result.getsDosageQuestions
            };
            this.setState(
              {
                respondentModel: respondent,
                reRenderForm: true,
                isEditModalVisible: true,
              },
              () => {
                this.setState({ reRenderForm: false });
              }
            );
          }
        })
        .catch((ex) => {});
    }
  };
  handleRespondentSave = (data) => {
    let teacher: AddTeacherModel = {
      teacherId: this.props.id,
      tFirstName: data.tFirstName,
      tLastName: data.tLastName,
      schoolName: data.schoolName,
      tEmailId: this.state.teacherModel.tEmailId,
      schoolContactNo: data.schoolContactNo,
      schoolDistrict: data.schoolDistrict,
      identityId: this.state.teacherModel.identityId,
      tFirstNameError: data.tFirstNameError,
      tLastNameError: data.tLastNameError,
      schoolContactNoError: data.schoolContactNoError,
      schoolDistrictError: data.schoolDistrictError,
      schoolNameError: data.schoolNameError,
    };
    this.setState({
      teacherModel: teacher,
    });
    if (
      teacher.tLastNameError == "" &&
      teacher.tFirstNameError == "" &&
      teacher.schoolDistrictError == "" &&
      teacher.schoolContactNoError == "" &&
      teacher.schoolNameError == ""
    ) {
      this.patientService
        .postTeacher(this.state.teacherModel)
        .then((res: HttpResponse<string>) => {
          if (res && res.result) {
            ShowSuccessMessage("Profile updated successfully.");
            this.props.handleUserDetail(
              this.state.teacherModel.tFirstName,
              this.state.teacherModel.tLastName
            );
            this.handleCancelEditProfile();
          }
        })
        .catch((ex) => {});
    }
  };
    private loadPatientData(id: any) {
        if (id) {
            this.patientService
                .getUserById(id)
                .then((res: HttpResponse<PatientDetailModel>) => {
                    if (res && res.result) {
                        let patient: AddPatientModel = {
                            id: res.result.id,
                            guardianId: res.result.guardianId,
                            mr_No: res.result.mr_No,
                            firstName: res.result.firstName,
                            lastName: res.result.lastName,
                            dateOfBirth: res.result.dateOfBirth,
                            gender: res.result.gender,
                            relationshipId: res.result.relationshipId,
                            guardianFirstName: res.result.guardianFirstName,
                            guardianLastName: res.result.guardianLastName,
                            contactNo: res.result.contactNo,
                            emailId: res.result.emailId,
                            addressModel: {
                                address1: res.result.addressModel.address1,
                                address2: res.result.addressModel.address2,
                                city: res.result.addressModel.city,
                                state: res.result.addressModel.state,
                                zipcode: res.result.addressModel.zipcode,
                            },
                            physicianModel: {
                                id: res.result.physicianId,
                                pLastName: res.result.physicianLastName,
                                speciality: res.result.speciality,
                                hospitalName: res.result.hospitalName,
                                pFirstName: res.result.physicianFirstName,
                            },
                            identityId: res.result.identityId,
                        };
                        this.setState(
                            {
                                patientModel: patient,
                                reRenderForm: true,
                                isEditModalVisible: true,
                            },
                            () => this.setState({ reRenderForm: false })
                        );
                    }
                })
                .catch((ex) => { });
        }
    }

  render() {
    return (
      <React.Fragment>
        <Modal
          title=""
          open={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okText="Yes"
          cancelText="No"
          closable={false}
          maskClosable={false}
        >
          <p>
            Your password will expire in 5 days. Click Yes if you want to change it now and No if
            you want to change it later.
          </p>
        </Modal>
        {this.props.designation === Role.Parent ? (
          <div className="header__wrapper">
            {!this.state.reRenderForm && (
              <TopHeader
                username={this.props.userName}
                designation={this.props.designation}
                logOut={this.logOut}
                initials={this.props.initials}
                headingStyle="teacherHeading__style"
                logoVisible={false}
                changePassword={this.changePassword}
                isEditModalVisible={this.state.isEditModalVisible}
                handleCancelEditProfile={this.handleCancelEditProfile}
                handleOpenEditProfile={this.handleOpenEditProfile}
                patient={this.state.patientModel}
                handleParentSave={this.handleParentSave}
              />
            )}
          </div>
        ) : (this.props.designation === Role.Teacher) ? (
          <div className="header__wrapper">
            {!this.state.reRenderForm && (
              <TopHeader
                username={this.props.userName}
                designation={this.props.designation}
                logOut={this.logOut}
                initials={this.props.initials}
                headingStyle="teacherHeading__style col-12 col-md-6"
                logoVisible={true}
                changePassword={this.changePassword}
                teacher={this.state.teacherModel}
                isEditModalVisible={this.state.isEditModalVisible}
                handleCancelEditProfile={this.handleCancelEditProfile}
                handleOpenEditProfile={this.handleOpenEditProfile}
                handleTeacherSave={this.handleTeacherSave}
              />
            )}
          </div>
        ) : (this.props.designation === Role.Respondent) ? (
          <div className="header__wrapper">
            {!this.state.reRenderForm && (
              <TopHeader
                username={this.props.userName}
                designation={this.props.designation}
                logOut={this.logOut}
                initials={this.props.initials}
                headingStyle="teacherHeading__style col-12 col-md-6"
                logoVisible={true}
                changePassword={this.changePassword}
                respondent={this.state.respondentModel}
                isEditModalVisible={this.state.isEditModalVisible}
                handleCancelEditProfile={this.handleCancelEditProfile}
                handleOpenEditProfile={this.handleOpenEditProfile}
                handleTeacherSave={this.handleTeacherSave}
              />
            )}
          </div>
        ) 
        : (
          <div className="header__admin">
            {!this.state.reRenderForm && (
              <TopHeader
                username={this.props.userName}
                designation={this.props.designation}
                logOut={this.logOut}
                initials={this.props.initials}
                addPatient={true}
                headingStyle="headingAdmin__style"
                changePassword={this.changePassword}
                isEditModalVisible={this.state.isEditModalVisible}
                handleCancelEditProfile={this.handleCancelEditProfile}
                handleOpenEditProfile={this.handleOpenEditProfile}
                user={this.state.userModel}
                handleSave={this.handleSave}
              />
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}
