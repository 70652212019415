import { AppConfig, HttpWrapper } from "../../core";
import { AddRespondentModel } from "../../models/Respondent/AddRespondentModel";
import { Relation } from "../../models/Respondent/Relation";
import { RespondentInformation } from "../../models/Respondent/RespondentInfo";
import { RespondentPatientModel } from "../../models/Respondent/RespondentPatientModel";
import { ParentWeeklyDataModel } from "../../models/parentWeeklyData/parent-weekly-data.model";
import { CheckEmailExistsModel } from "../../models/patient/check-email.model";
import { TeacherAdditionalQuestionWeeklyData } from "../../models/teacherWeeklyData/teacher-additional-question-weekly-data.model";
import { TeacherWeeklyDataModel } from "../../models/teacherWeeklyData/teacher-weekly-data.model";


export class RespondentService {
    private wrapper: HttpWrapper;
    private endpoint:  string;
    constructor() {
      this.wrapper = new HttpWrapper();
      this.endpoint = AppConfig.apiEndpoint + "respondent/"
    }

    getRelationList(){
        return this.wrapper.get<Relation[]>(this.endpoint + "relationlist")
    }
    getRespondentsById(id: string ) {
        return this.wrapper.get<RespondentInformation[]>(
          this.endpoint + "list/" + id
        );
    }

    addRespondent(respondentToAdd: AddRespondentModel){
      return this.wrapper.post<string>(this.endpoint + "create", respondentToAdd)
    }

    checkIsEmailExists(user: CheckEmailExistsModel){
      return this.wrapper.post<any>(
        AppConfig.apiEndpoint + "patient/checkEmailExists/",
        user
      ); 
    }
    getPatientData(){
      return this.wrapper.get<RespondentPatientModel>(this.endpoint + "patient-detail")
    }

    getTeacherWeeklyData(patientId: string){
      return this.wrapper.get<TeacherWeeklyDataModel>(
        this.endpoint + "teacherWeeklyData/detail/" + patientId
      );
    }

    getParentWeeklyData(patientId: string){
      return this.wrapper.get<ParentWeeklyDataModel>(
        this.endpoint + "parentweekdaydata/detail/" + patientId
      );
    }
    postTeacherTargetSymptomsData(request: TeacherWeeklyDataModel){
      return this.wrapper.post<any>(
        this.endpoint + "teacherWeeklyData/create/target-symptoms",
        request
      );
    }
    postTeacherAdditionalQuestionWeeklyData(request: TeacherAdditionalQuestionWeeklyData) {
      return this.wrapper.post<any>(
        this.endpoint + "teacherWeeklyData/additional/ques",
        request
      );
    }
    postParentTargetSymptomsData(request: TeacherWeeklyDataModel){
      return this.wrapper.post<any>(
        this.endpoint + "parentweekdaydata/create/target-symptoms",
        request
      );
    }
    postParentAdditionalQuestionWeeklyData(request: TeacherAdditionalQuestionWeeklyData) {
      return this.wrapper.post<any>(
        this.endpoint + "parentweekdaydata/create/additional-question",
        request
      );
    }
}

