import React from "react";
import "./styles.css";

export interface WeeklyStatusProps {
  weeklyHeading: string;
  statusStyle: string;
}

const WeeklyStatus: React.FC<WeeklyStatusProps> = ({ weeklyHeading, statusStyle }) => {
  return (
    <div className={`statusWrap ${statusStyle}`}>
      <p>{weeklyHeading}</p>
    </div>
  );
};
export default WeeklyStatus;
