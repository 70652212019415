import React, { Fragment, useEffect } from "react";
import Heading from "../../../../heading";
import { Button, Tooltip } from "antd";
import "./styles.css";
import { GetSeverity } from "../../../../patient/models/get-severity-text.model";
import { ClinicianWeeklyData } from "../../../../../models/clinicianWeeklyData/clinician-weekly-data.model";
import dayjs from "dayjs";
import { RespondentDayData } from "../../../../../models/Respondent/respondent-target-symptom-day-data.model";
import { RespondentWeeklyDataModel } from "../../../../../models/Respondent/respondent-weekly-data.model";

interface IProps {
  data? : RespondentWeeklyDataModel;
  index: number;
  styleName?:any
  targetOneAlias?:string;
  clinicianWeeklyData?:ClinicianWeeklyData;
  noteChangedData:Function;
}
function RespondentWeeklyRatingTable(props: IProps) {

    useEffect(() => {
      console.log("Props: ", props)
    },[props])
  const getClassName=(value:number)=>{
    switch(value){
      case 5:
        return "rating__five";
      case 6: 
        return "rating__seven";
      case 7:
        return "rating__seven";
      default : 
      return "rating__one";
    }
  }
  const getClassNameDot=(value:number)=>{
    switch(value){
      case 5:
        return "dot__five";
      case 6: 
        return "dot__seven";
      case 7:
        return "dot__seven";
      default : 
      return "dot__one";
    }
  }
  return (
    <div className="weeklyRating__table" key={props.index+1}> 
      <div id="scrollWrap" className={`weeklyTable__container weekRatingTable ${props.styleName}`}>
        <table>
          <thead>
            <tr className="side__effects">
              <th colSpan={2}>
                <Heading
                  heading={"Week " + (props.index+1)}
                  subHeading={props.data && props.data[props.index] && dayjs(props.data[props.index].weekStartDate).format("MM/DD/YYYY") + " - " + dayjs(props.data[props.index].weekEndDate).format("MM/DD/YYYY")}
                  styleName="rating__heading"
                  ccfSubHeading="rating__subHead"
                />
              </th>
              <th>
                <Heading heading="Overactivity; motor restlessness" styleName="weekTarget__head" />
              </th>
              <th>
                <Heading
                  heading="Impulsiveness; acting w/o thinking"
                  styleName="weekTarget__head"
                />
              </th>
              <th>
                <Heading
                  heading="Distractability; sustaining attention to tasks"
                  styleName="weekTarget__head"
                />
              </th>
              <th>
                <Heading heading="Task completion; finishing tasks" styleName="weekTarget__head" />
              </th>
              <th>
                <Heading heading="Being on time; accepting limits" styleName="weekTarget__head" />
              </th>
              <th>
                <Heading heading="Following instructions" styleName="weekTarget__head" />
              </th>
              <th>
                <Heading
                  heading="Frustration tolerance; appropriately expressing frustration"
                  styleName="weekTarget__head"
                />
              </th>
              <th>
                <Heading heading="Ability to calm self when excited" styleName="weekTarget__head" />
              </th>
              <th>
                <Heading heading="Peer relations" styleName="weekTarget__head" />
              </th>
              <th>
                <Heading heading="Adults relations" styleName="weekTarget__head" />
              </th>
              <th>
                <Heading heading={props.targetOneAlias} styleName="weekTarget__head" />
              </th>
              <th>
              <Heading heading="Weekly Ratings have been Noted" styleName="weekTarget__head" />
              </th>
            </tr>
          </thead>
          <tbody>
          {
              props.clinicianWeeklyData?.lstClinicianTargetSymptomsWeeklyData.filter(c=>c.isBaseLine==true).map((cliData,index)=>{
                return (
                  <Fragment key={"clinitianFragment" + (index + 1)}>
                    <tr>
                      <td
                        colSpan={2}
                        key={"clinitianOverActivity" + (index + 1)}
                      >
                        Baseline Rating
                      </td>
                      <td key={"clinitianOverActivityTd" + (index + 1)}>
                        {cliData.overActivity != null && (
                          <Tooltip
                            placement="top"
                            title={GetSeverity.text(cliData.overActivity)}
                          >
                            <span
                              key={"clinitianOverActivitySpan" + (index + 1)}
                              className={getClassName(cliData.overActivity)}
                            >
                              <span
                                className={`ratingSev__dot ${getClassNameDot(
                                  cliData.overActivity
                                )}`}
                              ></span>
                              {cliData.overActivity}
                            </span>
                          </Tooltip>
                        )}
                        {cliData.overActivity == null && (
                          <span key={"clinitianOverActivitySpan" + (index + 1)}>
                            -
                          </span>
                        )}
                      </td>
                      <td key={"clinitianImpulsivenessTd" + (index + 1)}>
                        {cliData.impulsiveness != null && (
                          <Tooltip
                            placement="top"
                            title={GetSeverity.text(cliData.impulsiveness)}
                          >
                            <span
                              key={"clinitianImpulsivenessSpan" + (index + 1)}
                              className={getClassName(cliData.impulsiveness)}
                            >
                              <span
                                className={`ratingSev__dot ${getClassNameDot(
                                  cliData.impulsiveness
                                )}`}
                              ></span>
                              {cliData.impulsiveness}
                            </span>
                          </Tooltip>
                        )}
                        {cliData.impulsiveness == null && (
                          <span
                            key={"clinitianImpulsivenessSpan" + (index + 1)}
                          >
                            -
                          </span>
                        )}
                      </td>
                      <td key={"clinitianDistractabilityTd" + (index + 1)}>
                        {cliData.distractability != null && (
                          <Tooltip
                            placement="top"
                            title={GetSeverity.text(cliData.distractability)}
                          >
                            <span
                              key={"clinitianDistractabilitySpan" + (index + 1)}
                              className={getClassName(cliData.distractability)}
                            >
                              <span
                                className={`ratingSev__dot ${getClassNameDot(
                                  cliData.distractability
                                )}`}
                              ></span>
                              {cliData.distractability}
                            </span>
                          </Tooltip>
                        )}
                        {cliData.distractability == null && (
                          <span
                            key={"clinitianDistractabilitySpan" + (index + 1)}
                          >
                            -
                          </span>
                        )}
                      </td>
                      <td key={"clinitianTaskCompletionTd" + (index + 1)}>
                        {cliData.taskCompletion != null && (
                          <Tooltip
                            key="12321"
                            placement="top"
                            title={GetSeverity.text(cliData.taskCompletion)}
                          >
                            <span
                              key={"clinitianTaskCompletionSpan" + (index + 1)}
                              className={getClassName(cliData.taskCompletion)}
                            >
                              <span
                                className={`ratingSev__dot ${getClassNameDot(
                                  cliData.taskCompletion
                                )}`}
                              ></span>
                              {cliData.taskCompletion}
                            </span>
                          </Tooltip>
                        )}
                        {cliData.taskCompletion == null && (
                          <span
                            key={"clinitianTaskCompletionSpan" + (index + 1)}
                          >
                            -
                          </span>
                        )}
                      </td>
                      <td key={"clinitianBeingOnTimeTd" + (index + 1)}>
                        {cliData.beingOnTime != null && (
                          <Tooltip
                            placement="top"
                            title={GetSeverity.text(cliData.beingOnTime)}
                          >
                            <span
                              key={"clinitianBeingOnTimeSpan" + (index + 1)}
                              className={getClassName(cliData.beingOnTime)}
                            >
                              <span
                                className={`ratingSev__dot ${getClassNameDot(
                                  cliData.beingOnTime
                                )}`}
                              ></span>
                              {cliData.beingOnTime}
                            </span>
                          </Tooltip>
                        )}
                        {cliData.beingOnTime == null && (
                          <span key={"clinitianBeingOnTimeSpan" + (index + 1)}>
                            -
                          </span>
                        )}
                      </td>
                      <td
                        key={"clinitianFollowingInstructionsTd" + (index + 1)}
                      >
                        {cliData.followingInstructions != null && (
                          <Tooltip
                            placement="top"
                            title={GetSeverity.text(
                              cliData.followingInstructions
                            )}
                          >
                            <span
                              key={
                                "clinitianFollowingInstructionsSpan" +
                                (index + 1)
                              }
                              className={getClassName(
                                cliData.followingInstructions
                              )}
                            >
                              <span
                                className={`ratingSev__dot ${getClassNameDot(
                                  cliData.followingInstructions
                                )}`}
                              ></span>
                              {cliData.followingInstructions}
                            </span>
                          </Tooltip>
                        )}
                        {cliData.followingInstructions == null && (
                          <span
                            key={
                              "clinitianFollowingInstructionsSpan" + (index + 1)
                            }
                          >
                            -
                          </span>
                        )}
                      </td>
                      <td key={"clinitianFrustrationToleranceTd" + (index + 1)}>
                        {cliData.frustrationTolerance != null && (
                          <Tooltip
                            placement="top"
                            title={GetSeverity.text(
                              cliData.frustrationTolerance
                            )}
                          >
                            <span
                              key={
                                "clinitianFrustrationToleranceSpan" +
                                (index + 1)
                              }
                              className={getClassName(
                                cliData.frustrationTolerance
                              )}
                            >
                              <span
                                className={`ratingSev__dot ${getClassNameDot(
                                  cliData.frustrationTolerance
                                )}`}
                              ></span>
                              {cliData.frustrationTolerance}
                            </span>
                          </Tooltip>
                        )}
                        {cliData.frustrationTolerance == null && (
                          <span
                            key={
                              "clinitianFrustrationToleranceSpan" + (index + 1)
                            }
                          >
                            -
                          </span>
                        )}
                      </td>
                      <td
                        key={
                          "clinitianAbilitytoCalmSelfWhenExcitedTd" +
                          (index + 1)
                        }
                      >
                        {cliData.abilitytoCalmSelfWhenExcited != null && (
                          <Tooltip
                            placement="top"
                            title={GetSeverity.text(
                              cliData.abilitytoCalmSelfWhenExcited
                            )}
                          >
                            <span
                              key={
                                "clinitianAbilitytoCalmSelfWhenExcitedSpan" +
                                (index + 1)
                              }
                              className={getClassName(
                                cliData.abilitytoCalmSelfWhenExcited
                              )}
                            >
                              <span
                                className={`ratingSev__dot ${getClassNameDot(
                                  cliData.abilitytoCalmSelfWhenExcited
                                )}`}
                              ></span>
                              {cliData.abilitytoCalmSelfWhenExcited}
                            </span>
                          </Tooltip>
                        )}
                        {cliData.abilitytoCalmSelfWhenExcited == null && (
                          <span
                            key={
                              "clinitianAbilitytoCalmSelfWhenExcitedSpan" +
                              (index + 1)
                            }
                          >
                            -
                          </span>
                        )}
                      </td>
                      <td key={"clinitianPeerRelationsTd" + (index + 1)}>
                        {cliData.peerRelations != null && (
                          <Tooltip
                            placement="top"
                            title={GetSeverity.text(cliData.peerRelations)}
                          >
                            <span
                              key={"clinitianPeerRelationsSpan" + (index + 1)}
                              className={getClassName(cliData.peerRelations)}
                            >
                              <span
                                className={`ratingSev__dot ${getClassNameDot(
                                  cliData.peerRelations
                                )}`}
                              ></span>
                              {cliData.peerRelations}
                            </span>
                          </Tooltip>
                        )}
                        {cliData.peerRelations == null && (
                          <span
                            key={"clinitianPeerRelationsSpan" + (index + 1)}
                          >
                            -
                          </span>
                        )}
                      </td>
                      <td key={"clinitianAdultsRelationsTd" + (index + 1)}>
                        {cliData.adultsRelations != null && (
                          <Tooltip
                            placement="top"
                            title={GetSeverity.text(cliData.adultsRelations)}
                          >
                            <span
                              key={"clinitianAdultsRelationsSpan" + (index + 1)}
                              className={getClassName(cliData.adultsRelations)}
                            >
                              <span
                                className={`ratingSev__dot ${getClassNameDot(
                                  cliData.adultsRelations
                                )}`}
                              ></span>
                              {cliData.adultsRelations}
                            </span>
                          </Tooltip>
                        )}
                        {cliData.adultsRelations == null && (
                          <span
                            key={"clinitianAdultsRelationsSpan" + (index + 1)}
                          >
                            -
                          </span>
                        )}
                      </td>
                      <td key={"clinitianTargetBehaviourTd" + (index + 1)}>
                        {cliData.targetOneAlias != null && (
                          <Tooltip
                            placement="top"
                            title={GetSeverity.text(cliData.targetOneAlias)}
                          >
                            <span
                              key={"clinitianTargetBehaviourSpan" + (index + 1)}
                              className={getClassName(cliData.targetOneAlias)}
                            >
                              <span
                                className={`ratingSev__dot ${getClassNameDot(
                                  cliData.targetOneAlias
                                )}`}
                              ></span>
                              {cliData.targetOneAlias}
                            </span>
                          </Tooltip>
                        )}
                        {cliData.targetOneAlias == null && (
                          <span
                            key={"clinitianTargetBehaviourSpan" + (index + 1)}
                          >
                            -
                          </span>
                        )}
                      </td>
                      <td key={"clinitianDataChangesNotedTd" + (index + 1)}>
                      <span
                            key={"clinitianDataChangesNotedSpan" + (index + 1)}
                          >
                            -
                          </span>
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            {props.data?.respondentTargetSymptomWeeklyDataDetail[props.index].respondentTargetSymptomWeekDayDataList?.map((day, index) => {
             return (
               <Fragment key={"respndentFragment" + (index + 1)}>
                 <tr key={"ratingTr" + (index + 1)}>
                   <td
                     rowSpan={day.respondentDayData.length +1}
                     className="rating__day"
                     key={"ratingDayNameTd" + (index + 1)}
                   >
                     <div className="roted-box">{day.weekDayName}</div>
                   </td>
                 </tr>
                 {day.respondentDayData && day.respondentDayData?.map((respondentData: RespondentDayData) => {
                     return (
                       <tr
                         className={
                           respondentData.modifiedAfterWeeksEnd
                             ? "modified"
                             : "noted"
                         }
                       >
                         {respondentData.respondentName &&
                           respondentData.respondentName !== "" && (
                             <Tooltip title={respondentData.respondentName}>
                               <td
                                 key={
                                   "relation" +
                                   (respondentData.id ?? "respondentId")
                                 }
                               >
                                 {respondentData.relation}
                               </td>
                             </Tooltip>
                           )}
                         {!respondentData.respondentName  && (
                               <td
                                 key={
                                   "relation" +
                                   (respondentData.id ?? "respondentId")
                                 }
                               >
                                 {respondentData.relation}
                               </td>
                           )}
                         <td
                           key={
                             "overActivityTd" +
                             (respondentData.id ?? "respondentId")
                           }
                         >
                           {/* <span className="rating__one"><span className="ratingSev__dot dot__one"></span>1</span> */}
                           {/* <span className="rating__five"><span className="ratingSev__dot dot__five"></span>5</span> */}
                           {respondentData.overActivity != null && (
                             <Tooltip
                               placement="top"
                               title={GetSeverity.text(
                                 respondentData.overActivity
                               )}
                             >
                               <span
                                 key={
                                   "overActivitySpan" +
                                   (respondentData.id ?? "respondentId")
                                 }
                                 className={getClassName(
                                   respondentData.overActivity
                                 )}
                               >
                                 <span
                                   className={`ratingSev__dot ${getClassNameDot(
                                     respondentData.overActivity
                                   )}`}
                                 ></span>
                                 {respondentData.overActivity}
                               </span>
                             </Tooltip>
                           )}
                           {respondentData.overActivity == null && (
                             <span
                               key={
                                 "overActivitySpan" +
                                 (respondentData.id ?? "respondentId")
                               }
                             >
                               -
                             </span>
                           )}
                         </td>
                         <td
                           key={
                             "impulsivenessTd" +
                             (respondentData.id ?? "respondentId")
                           }
                         >
                           {respondentData.impulsiveness != null && (
                             <Tooltip
                               placement="top"
                               title={GetSeverity.text(
                                 respondentData.impulsiveness
                               )}
                             >
                               <span
                                 key={
                                   "impulsivenessSpan" +
                                   (respondentData.id ?? "respondentId")
                                 }
                                 className={getClassName(
                                   respondentData.impulsiveness
                                 )}
                               >
                                 <span
                                   className={`ratingSev__dot ${getClassNameDot(
                                     respondentData.impulsiveness
                                   )}`}
                                 ></span>
                                 {respondentData.impulsiveness}
                               </span>
                             </Tooltip>
                           )}
                           {respondentData.impulsiveness == null && (
                             <span
                               key={
                                 "impulsivenessSpan" +
                                 (respondentData.id ?? "respondentId")
                               }
                             >
                               -
                             </span>
                           )}
                         </td>
                         <td
                           key={
                             "distractabilityTd" +
                             (respondentData.id ?? "respondentId")
                           }
                         >
                           {respondentData.distractability != null && (
                             <Tooltip
                               placement="top"
                               title={GetSeverity.text(
                                 respondentData.distractability
                               )}
                             >
                               <span
                                 key={
                                   "distractabilitySpan" +
                                   (respondentData.id ?? "respondentId")
                                 }
                                 className={getClassName(
                                   respondentData.distractability
                                 )}
                               >
                                 <span
                                   className={`ratingSev__dot ${getClassNameDot(
                                     respondentData.distractability
                                   )}`}
                                 ></span>
                                 {respondentData.distractability}
                               </span>
                             </Tooltip>
                           )}
                           {respondentData.distractability == null && (
                             <span
                               key={
                                 "distractabilitySpan" +
                                 (respondentData.id ?? "respondentId")
                               }
                             >
                               -
                             </span>
                           )}
                         </td>
                         <td
                           key={
                             "taskCompletionTd" +
                             (respondentData.id ?? "respondentId")
                           }
                         >
                           {respondentData.taskCompletion != null && (
                             <Tooltip
                               key="12321"
                               placement="top"
                               title={GetSeverity.text(
                                 respondentData.taskCompletion
                               )}
                             >
                               <span
                                 key={
                                   "taskCompletionSpan" +
                                   (respondentData.id ?? "respondentId")
                                 }
                                 className={getClassName(
                                   respondentData.taskCompletion
                                 )}
                               >
                                 <span
                                   className={`ratingSev__dot ${getClassNameDot(
                                     respondentData.taskCompletion
                                   )}`}
                                 ></span>
                                 {respondentData.taskCompletion}
                               </span>
                             </Tooltip>
                           )}
                           {respondentData.taskCompletion == null && (
                             <span
                               key={
                                 "taskCompletionSpan" +
                                 (respondentData.id ?? "respondentId")
                               }
                             >
                               -
                             </span>
                           )}
                         </td>
                         <td
                           key={
                             "beingOnTimeTd" +
                             (respondentData.id ?? "respondentId")
                           }
                         >
                           {respondentData.beingOnTime != null && (
                             <Tooltip
                               placement="top"
                               title={GetSeverity.text(
                                 respondentData.beingOnTime
                               )}
                             >
                               <span
                                 key={
                                   "beingOnTimeSpan" +
                                   (respondentData.id ?? "respondentId")
                                 }
                                 className={getClassName(
                                   respondentData.beingOnTime
                                 )}
                               >
                                 <span
                                   className={`ratingSev__dot ${getClassNameDot(
                                     respondentData.beingOnTime
                                   )}`}
                                 ></span>
                                 {respondentData.beingOnTime}
                               </span>
                             </Tooltip>
                           )}
                           {respondentData.beingOnTime == null && (
                             <span
                               key={
                                 "beingOnTimeSpan" +
                                 (respondentData.id ?? "respondentId")
                               }
                             >
                               -
                             </span>
                           )}
                         </td>
                         <td
                           key={
                             "followingInstructionsTd" +
                             (respondentData.id ?? "respondentId")
                           }
                         >
                           {respondentData.followingInstructions != null && (
                             <Tooltip
                               placement="top"
                               title={GetSeverity.text(
                                 respondentData.followingInstructions
                               )}
                             >
                               <span
                                 key={
                                   "followingInstructionsSpan" +
                                   (respondentData.id ?? "respondentId")
                                 }
                                 className={getClassName(
                                   respondentData.followingInstructions
                                 )}
                               >
                                 <span
                                   className={`ratingSev__dot ${getClassNameDot(
                                     respondentData.followingInstructions
                                   )}`}
                                 ></span>
                                 {respondentData.followingInstructions}
                               </span>
                             </Tooltip>
                           )}
                           {respondentData.followingInstructions == null && (
                             <span
                               key={
                                 "followingInstructionsSpan" +
                                 (respondentData.id ?? "respondentId")
                               }
                             >
                               -
                             </span>
                           )}
                         </td>
                         <td
                           key={
                             "frustrationToleranceTd" +
                             (respondentData.id ?? "respondentId")
                           }
                         >
                           {respondentData.frustrationTolerance != null && (
                             <Tooltip
                               placement="top"
                               title={GetSeverity.text(
                                 respondentData.frustrationTolerance
                               )}
                             >
                               <span
                                 key={
                                   "frustrationToleranceSpan" +
                                   (respondentData.id ?? "respondentId")
                                 }
                                 className={getClassName(
                                   respondentData.frustrationTolerance
                                 )}
                               >
                                 <span
                                   className={`ratingSev__dot ${getClassNameDot(
                                     respondentData.frustrationTolerance
                                   )}`}
                                 ></span>
                                 {respondentData.frustrationTolerance}
                               </span>
                             </Tooltip>
                           )}
                           {respondentData.frustrationTolerance == null && (
                             <span
                               key={
                                 "frustrationToleranceSpan" +
                                 (respondentData.id ?? "respondentId")
                               }
                             >
                               -
                             </span>
                           )}
                         </td>
                         <td
                           key={
                             "abilitytoCalmSelfWhenExcitedTd" +
                             (respondentData.id ?? "respondentId")
                           }
                         >
                           {respondentData.abilitytoCalmSelfWhenExcited !=
                             null && (
                             <Tooltip
                               placement="top"
                               title={GetSeverity.text(
                                 respondentData.abilitytoCalmSelfWhenExcited
                               )}
                             >
                               <span
                                 key={
                                   "abilitytoCalmSelfWhenExcitedSpan" +
                                   (respondentData.id ?? "respondentId")
                                 }
                                 className={getClassName(
                                   respondentData.abilitytoCalmSelfWhenExcited
                                 )}
                               >
                                 <span
                                   className={`ratingSev__dot ${getClassNameDot(
                                     respondentData.abilitytoCalmSelfWhenExcited
                                   )}`}
                                 ></span>
                                 {respondentData.abilitytoCalmSelfWhenExcited}
                               </span>
                             </Tooltip>
                           )}
                           {respondentData.abilitytoCalmSelfWhenExcited ==
                             null && (
                             <span
                               key={
                                 "abilitytoCalmSelfWhenExcitedSpan" +
                                 (respondentData.id ?? "respondentId")
                               }
                             >
                               -
                             </span>
                           )}
                         </td>
                         <td
                           key={
                             "peerRelationsTd" +
                             (respondentData.id ?? "respondentId")
                           }
                         >
                           {respondentData.peerRelations != null && (
                             <Tooltip
                               placement="top"
                               title={GetSeverity.text(
                                 respondentData.peerRelations
                               )}
                             >
                               <span
                                 key={
                                   "peerRelationsSpan" +
                                   (respondentData.id ?? "respondentId")
                                 }
                                 className={getClassName(
                                   respondentData.peerRelations
                                 )}
                               >
                                 <span
                                   className={`ratingSev__dot ${getClassNameDot(
                                     respondentData.peerRelations
                                   )}`}
                                 ></span>
                                 {respondentData.peerRelations}
                               </span>
                             </Tooltip>
                           )}
                           {respondentData.peerRelations == null && (
                             <span
                               key={
                                 "peerRelationsSpan" +
                                 (respondentData.id ?? "respondentId")
                               }
                             >
                               -
                             </span>
                           )}
                         </td>
                         <td
                           key={
                             "adultsRelationsTd" +
                             (respondentData.id ?? "respondentId")
                           }
                         >
                           {respondentData.adultsRelations != null && (
                             <Tooltip
                               placement="top"
                               title={GetSeverity.text(
                                 respondentData.adultsRelations
                               )}
                             >
                               <span
                                 key={
                                   "adultsRelationsSpan" +
                                   (respondentData.id ?? "respondentId")
                                 }
                                 className={getClassName(
                                   respondentData.adultsRelations
                                 )}
                               >
                                 <span
                                   className={`ratingSev__dot ${getClassNameDot(
                                     respondentData.adultsRelations
                                   )}`}
                                 ></span>
                                 {respondentData.adultsRelations}
                               </span>
                             </Tooltip>
                           )}
                           {respondentData.adultsRelations == null && (
                             <span
                               key={
                                 "adultsRelationsSpan" +
                                 (respondentData.id ?? "respondentId")
                               }
                             >
                               -
                             </span>
                           )}
                         </td>
                         <td
                           key={
                             "targetBehaviourTd" +
                             (respondentData.id ?? "respondentId")
                           }
                         >
                           {respondentData.targetOneAlias != null && (
                             <Tooltip
                               placement="top"
                               title={GetSeverity.text(
                                 respondentData.targetOneAlias
                               )}
                             >
                               <span
                                 key={
                                   "targetBehaviourSpan" +
                                   (respondentData.id ?? "respondentId")
                                 }
                                 className={getClassName(
                                   respondentData.targetOneAlias
                                 )}
                               >
                                 <span
                                   className={`ratingSev__dot ${getClassNameDot(
                                     respondentData.targetOneAlias
                                   )}`}
                                 ></span>
                                 {respondentData.targetOneAlias}
                               </span>
                             </Tooltip>
                           )}
                           {respondentData.targetOneAlias == null && (
                             <span
                               key={
                                 "targetBehaviourSpan" +
                                 (respondentData.id ?? "respondentId")
                               }
                             >
                               -
                             </span>
                           )}
                         </td>
                         <td
                           key={
                             "dataNotedTd" +
                             (respondentData.id ?? "respondentId")
                           }
                         >
                           {respondentData.modifiedAfterWeeksEnd && (
                             <Button
                               onClick={() =>
                                 props.noteChangedData(respondentData)
                               }
                             >
                               Noted
                             </Button>
                           )}
                           {!respondentData.modifiedAfterWeeksEnd && (
                             <span
                               key={
                                 "dataNotedSpan" +
                                 (respondentData.id ?? "respondentId")
                               }
                             >
                               -
                             </span>
                           )}
                         </td>
                       </tr>
                     );
                   })}
               </Fragment>
             );})}
          
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default RespondentWeeklyRatingTable;
