import React, { useEffect } from "react";
import Heading from "../../../heading";
import { Button } from "antd";
import "../../styles.css";
import { MedicationPostModel } from "../../../../models/medicalHistory/medication-post.model";
import { useForm } from "react-hook-form";
import { Loader } from "../../../../shared/loaders";

type MedicationHistoryFormValues = {
  childPresentOnMedicationType: string | undefined;
  childPresentOnMedicationPrescribedFor: string |undefined;
  childPreviouslyOnMedicationType: string |undefined;
  childPreviouslyOnMedicationPrescribedFor: string | undefined;
  childSpecialAccommodationsType: string |undefined;
  childSpecialAccommodationsFor: string | undefined;
}
interface IProps {
  medicationPostModel: MedicationPostModel;
  onSubmit: Function;
  isSaving: boolean;
  patientId: string;
  assignModel: Function;
  parentTabChange: Function;
}
export default function MedicationHistoryForm(props: IProps) {
  let medicationModel = props.medicationPostModel;
  const { register, formState: {errors}, handleSubmit, control, getValues, formState, setValue } = useForm<MedicationHistoryFormValues>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  useEffect(() =>{
    setInitialValues()
  }, [props]);

  function setInitialValues() {
    setValue("childPresentOnMedicationPrescribedFor", props.medicationPostModel.childPresentOnMedicationPrescribedFor);
    setValue("childPresentOnMedicationType", props.medicationPostModel.childPresentOnMedicationType);
    setValue("childPreviouslyOnMedicationPrescribedFor", props.medicationPostModel.childPreviouslyOnMedicationPrescribedFor);
    setValue("childPreviouslyOnMedicationType", props.medicationPostModel.childPreviouslyOnMedicationType);
    setValue("childSpecialAccommodationsFor", props.medicationPostModel.childSpecialAccommodationsFor);
    setValue("childSpecialAccommodationsType", props.medicationPostModel.childSpecialAccommodationsType);
  }
  const formSubmit = (data) => {
    medicationModel.patientId = props.patientId;
    medicationModel.childPresentOnMedicationType = data.childPresentOnMedicationType;
    medicationModel.childPresentOnMedicationPrescribedFor =
      data.childPresentOnMedicationPrescribedFor;
    medicationModel.childPreviouslyOnMedicationType = data.childPreviouslyOnMedicationType;
    medicationModel.childPreviouslyOnMedicationPrescribedFor =
      data.childPreviouslyOnMedicationPrescribedFor;
    medicationModel.childSpecialAccommodationsType = data.childSpecialAccommodationsType;
    medicationModel.childSpecialAccommodationsFor = data.childSpecialAccommodationsFor;
    props.assignModel({ medicationPostModel: medicationModel });
    if (checkForError()) {
      return false;
    }
    props.onSubmit({ medicationPostModel: medicationModel, isAutoSave: false });
  };
  const checkForError = () => {
    let hasError = false;
    medicationModel.isChildPresentOnMedicationError = "";
    medicationModel.isChildPreviouslyOnMedicationError = "";
    medicationModel.isChildSpecialAccommodationsError = "";
    medicationModel.childPresentOnMedicationTypeError = "";
    medicationModel.childPreviouslyOnMedicationTypeError = "";
    medicationModel.childSpecialAccommodationsTypeError = "";
    medicationModel.childPresentOnMedicationPrescribedForError = "";
    medicationModel.childPreviouslyOnMedicationPrescribedForError = "";
    medicationModel.childSpecialAccommodationsForError = "";
    if (
      medicationModel.isChildPresentOnMedication == true &&
      (medicationModel.childPresentOnMedicationType == "" ||
        medicationModel.childPresentOnMedicationType == null)
    ) {
      medicationModel.childPresentOnMedicationTypeError = "Please fill type.";
      hasError = true;
    }
    if (
      medicationModel.isChildPresentOnMedication == true &&
      (medicationModel.childPresentOnMedicationPrescribedFor == "" ||
        medicationModel.childPresentOnMedicationPrescribedFor == null)
    ) {
      medicationModel.childPresentOnMedicationPrescribedForError = "Please fill prescribed for.";
      hasError = true;
    }

    if (
      medicationModel.isChildPreviouslyOnMedication == true &&
      (medicationModel.childPreviouslyOnMedicationType == "" ||
        medicationModel.childPreviouslyOnMedicationType == null)
    ) {
      medicationModel.childPreviouslyOnMedicationTypeError = "Please fill type.";
      hasError = true;
    }

    if (
      medicationModel.isChildPreviouslyOnMedication == true &&
      (medicationModel.childPreviouslyOnMedicationPrescribedFor == "" ||
        medicationModel.childPreviouslyOnMedicationPrescribedFor == null)
    ) {
      medicationModel.childPreviouslyOnMedicationPrescribedForError = "Please fill prescribed for.";
      hasError = true;
    }

    if (
      medicationModel.isChildSpecialAccommodations == true &&
      (medicationModel.childSpecialAccommodationsType == "" ||
        medicationModel.childSpecialAccommodationsType == null)
    ) {
      medicationModel.childSpecialAccommodationsTypeError = "Please fill type.";
      hasError = true;
    }

    if (
      medicationModel.isChildSpecialAccommodations == true &&
      (medicationModel.childSpecialAccommodationsFor == "" ||
        medicationModel.childSpecialAccommodationsFor == null)
    ) {
      medicationModel.childSpecialAccommodationsForError = "Please fill accommodations for.";
      hasError = true;
    }

    if (medicationModel.isChildPresentOnMedication == undefined) {
      medicationModel.isChildPresentOnMedicationError = "Please select an option.";
      hasError = true;
    }
    if (medicationModel.isChildPreviouslyOnMedication == undefined) {
        medicationModel.isChildPreviouslyOnMedicationError = "Please select an option.";
      hasError = true;
    }
    if (medicationModel.isChildSpecialAccommodations == undefined) {
        medicationModel.isChildSpecialAccommodationsError = "Please select an option.";
      hasError = true;
    }
    props.assignModel({ medicationPostModel: medicationModel });
    return hasError;
  };
  const handleKeyUp = (event) => {
    setValuesInModel();
    if (event.key == "Tab") {
      saveDataOnTabChange();
    }
  };
  const handleOnFocus = (event) => {
    setValuesInModel();
    saveDataOnTabChange();
  };
  const saveDataOnTabChange = () => {
    if (!props.isSaving) {
      medicationModel.patientId = props.patientId;
      props.onSubmit({
        medicationPostModel: medicationModel,
        isAutoSave: true,
      });
    }
  };

  function setValuesInModel() {
    let model = getValues();
    setDataInModel(model);
  }
  const setDataInModel = (data) => {
    medicationModel.childPresentOnMedicationType = data.childPresentOnMedicationType;
    medicationModel.childPresentOnMedicationPrescribedFor = data.childPresentOnMedicationPrescribedFor;

    medicationModel.childPreviouslyOnMedicationType = data.childPreviouslyOnMedicationType;
    medicationModel.childPreviouslyOnMedicationPrescribedFor = data.childPreviouslyOnMedicationPrescribedFor;

    medicationModel.childSpecialAccommodationsType = data.childSpecialAccommodationsType;
    medicationModel.childSpecialAccommodationsFor = data.childSpecialAccommodationsFor;
  };

  const isSelected = (type: number, isChecked) => {
    setValuesInModel();
    switch (type) {
      case 1: {
        medicationModel.isChildPresentOnMedication = isChecked;
        medicationModel.isChildPresentOnMedicationError = "";
        if (!isChecked) {
          medicationModel.childPresentOnMedicationTypeError = "";
          medicationModel.childPresentOnMedicationType = "";
          medicationModel.childPresentOnMedicationPrescribedFor = "";
          medicationModel.childPresentOnMedicationPrescribedForError = "";
        }
        break;
      }
      case 2: {
        medicationModel.isChildPreviouslyOnMedication = isChecked;
        medicationModel.isChildPreviouslyOnMedicationError = "";
        if (!isChecked) {
          medicationModel.isChildPreviouslyOnMedication = isChecked;
          medicationModel.childPreviouslyOnMedicationTypeError = "";
          medicationModel.childPreviouslyOnMedicationPrescribedForError = "";
          medicationModel.childPreviouslyOnMedicationType = "";
          medicationModel.childPreviouslyOnMedicationPrescribedFor = "";
        }
        break;
      }
      case 3: {
        medicationModel.isChildSpecialAccommodations = isChecked;
        medicationModel.isChildSpecialAccommodationsError = "";
        if (!isChecked) {
          medicationModel.childSpecialAccommodationsTypeError = "";
          medicationModel.childSpecialAccommodationsForError = "";
          medicationModel.childSpecialAccommodationsType = "";
          medicationModel.childSpecialAccommodationsFor = "";
        }

        break;
      }
      default: {
        break;
      }
    }
    props.assignModel({ medicationPostModel: medicationModel });
    
    saveDataOnTabChange();
  };
  return (
    <div >
      <form onSubmit={handleSubmit(formSubmit)}>
        <div>
          <Heading heading="Medication" subHeading="" styleName="" />

          <div className="form">
            <div className="row align-items-end">
              <div className="medical-group btn-select col-12 col-md-4 mt-2">
                <label className="text-start">Is the child presently on medication?</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(1, true)}
                    className={
                      medicationModel.isChildPresentOnMedication == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(1, false)}
                    className={
                      medicationModel.isChildPresentOnMedication == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {medicationModel.isChildPresentOnMedicationError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {medicationModel.isChildPresentOnMedicationError}
                    </div>
                  )}
              </div>
              <div className="medical-group col-12 col-md-4  mt-2">
                <label className="form-label">Type</label>
                <input
                  type="text"
                  className="form-control mb-1"
                  defaultValue={medicationModel.childPresentOnMedicationType}
                  disabled={
                    !medicationModel.isChildPresentOnMedication ||
                    medicationModel.isChildPresentOnMedication == null
                  }
                  placeholder=""
                  {...register("childPresentOnMedicationType")}
                  onFocus={handleOnFocus}
                />
                
              </div>
              <div className="medical-group col-12 col-md-4 mt-2">
                <label className="form-label">Prescribed for</label>
                <input
                  type="text"
                  className="form-control mb-1"
                  defaultValue={medicationModel.childPresentOnMedicationPrescribedFor}
                  disabled={
                    !medicationModel.isChildPresentOnMedication ||
                    medicationModel.isChildPresentOnMedication == null
                  }
                  placeholder=""
                  {...register("childPresentOnMedicationPrescribedFor")}
                  onFocus={handleOnFocus}
                />
                {medicationModel.childPresentOnMedicationPrescribedForError && (
                  <div className="invalid-feedback" style={{ display: "block" }}>
                    {medicationModel.childPresentOnMedicationPrescribedForError}
                  </div>
                )}
              </div>
            </div>
            <div className="row mt-3 align-items-end">
              <div className="medical-group btn-select col-12 col-md-4 mt-2">
                <label className="text-start">Has the child previously been on medication?</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(2, true)}
                    className={
                      medicationModel.isChildPreviouslyOnMedication == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(2, false)}
                    className={
                      medicationModel.isChildPreviouslyOnMedication == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {medicationModel.isChildPreviouslyOnMedicationError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {medicationModel.isChildPreviouslyOnMedicationError}
                    </div>
                  )}
              </div>
              <div className="medical-group col-12 col-md-4  mt-2" >
                <label className="form-label">Type</label>
                <input
                  type="text"
                  className="form-control mb-1"
                  defaultValue={medicationModel.childPreviouslyOnMedicationType}
                  onFocus={handleOnFocus}
                  placeholder=""
                  disabled={
                    !medicationModel.isChildPreviouslyOnMedication ||
                    medicationModel.isChildPreviouslyOnMedication == null
                  }
                  {...register("childPreviouslyOnMedicationType")}
                />
                {medicationModel.childPreviouslyOnMedicationTypeError && (
                  <div className="invalid-feedback" style={{ display: "block" }}>
                    {medicationModel.childPreviouslyOnMedicationTypeError}
                  </div>
                )}
              </div>
              <div className="medical-group col-12 col-md-4  mt-2">
                <label className="form-label">Prescribed for</label>
                <input
                  type="text"
                  className="form-control mb-1"
                  defaultValue={medicationModel.childPreviouslyOnMedicationPrescribedFor}
                  onFocus={handleOnFocus}
                  placeholder=""
                  disabled={
                    !medicationModel.isChildPreviouslyOnMedication ||
                    medicationModel.isChildPreviouslyOnMedication == undefined
                  }
                  {...register("childPreviouslyOnMedicationPrescribedFor")}
                />
                {medicationModel.childPreviouslyOnMedicationPrescribedForError && (
                  <div className="invalid-feedback" style={{ display: "block" }}>
                    {medicationModel.childPreviouslyOnMedicationPrescribedForError}
                  </div>
                )}
              </div>
            </div>
            <div className="row mt-3 align-items-end">
              <div className="medical-group btn-select col-12 col-md-4 mt-2">
                <label className="text-start">
                  Aside from medication, do any of the child's medical/health conditions require
                  special accommodations (including the use of corrective lenses/glasses)?
                </label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(3, true)}
                    className={
                      medicationModel.isChildSpecialAccommodations == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(3, false)}
                    className={
                      medicationModel.isChildSpecialAccommodations == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {medicationModel.isChildSpecialAccommodationsError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {medicationModel.isChildSpecialAccommodationsError}
                    </div>
                  )}
              </div>
              <div className="medical-group col-12 col-md-4  mt-2" >
                <label className="form-label">Type</label>
                <input
                  type="text"
                  className="form-control mb-1"
                  defaultValue={medicationModel.childSpecialAccommodationsType}
                  disabled={
                    !medicationModel.isChildSpecialAccommodations ||
                    medicationModel.isChildSpecialAccommodations == undefined
                  }
                  placeholder=""
                  {...register("childSpecialAccommodationsType")}
                  onFocus={handleOnFocus}
                />
                {medicationModel.childSpecialAccommodationsTypeError && (
                  <div className="invalid-feedback" style={{ display: "block" }}>
                    {medicationModel.childSpecialAccommodationsTypeError}
                  </div>
                )}
              </div>
              <div className="medical-group col-12 col-md-4  mt-2" >
                <label className="form-label">Accommodations for</label>
                <input
                  type="text"
                  className="form-control mb-1"
                  defaultValue={medicationModel.childSpecialAccommodationsFor}
                  placeholder=""
                  disabled={
                    !medicationModel.isChildSpecialAccommodations ||
                    medicationModel.isChildSpecialAccommodations == undefined
                  }
                  {...register("childSpecialAccommodationsFor")}
                  onFocus={handleOnFocus}
                />
                {medicationModel.childSpecialAccommodationsForError && (
                  <div className="invalid-feedback" style={{ display: "block" }}>
                    {medicationModel.childSpecialAccommodationsForError}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="btnContainer">
            {!props.isSaving && (
              <Button
                type="default"
                size="large"
                className="back_btn"
                onClick={() => props.parentTabChange(3)}
              >
                <i className="fa fa-long-arrow-left"></i> Back
              </Button>
            )}
            {!props.isSaving && (
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="btn_add"
               
              >
                Save <i className="fa fa-long-arrow-right"></i>
              </Button>
            )}
            {props.isSaving && (
              <div style={{ height: "60px" }}>
                <Loader
                  loading={props.isSaving}
                  marginBottom="0px"
                  marginTop="8px"
                  width="368px"
                ></Loader>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
