import React, { Component, Fragment } from "react";
import "./patient.scss";
import PatientList from "./patientList/index";
import AddPatient from "./addPatient/index";
import imgAddUser from "../../assets/images/svg-icons/add-user.svg";
import PageTitleSetter from "../../shared/pageTitleSetter";
import BreadcrumSetter from "../../shared/breadcrumSetter";
import patientGrey from "../../assets/images/svg-icons/patient-grey.svg";
import managementGrey from "../../assets/images/svg-icons/management-grey.svg";
import { PatientService } from "./services";
import { PatientListFilterModel } from "./models/patient-list-filter.model";
import { HttpResponse } from "../../core";
import { PatientListResponseModel } from "./models/patient-list-response.model";
import withRouter from "../../shared/withRouter/withRouter";


interface IState {
  counter?: any,
  currentUserName?: any,
  isBreadcrumChange?: any,
  tabText?: any,
  currentId?: any
  isRefresh: boolean;
}
export class Patient extends Component<any, IState> {
  private patientService: PatientService;
  constructor(props: any) {
    super(props);
    this.patientService = new PatientService();
    this.state = {
      counter: 1,
      currentUserName: "",
      isBreadcrumChange: false,
      tabText: "",
      currentId: 0,
      isRefresh: false
    };
  }

  componentDidMount() {
    if (window.location.pathname == "/patient/addPatient/0/pt" || window.location.pathname == "/patient/addPatient/") {
      this.getSingleRecord();
      this.setState({ isRefresh: true })
    }

  }

  componentWillReceiveProps() {
    if (window.location.pathname=="/patient/addPatient/") {
      window.location.href=window.location.origin + '/patient/addPatient/0/pt';
    }
    if (window.location.pathname == "/patient/addPatient/0/pt" || window.location.pathname =="/patient/addPatient/") {
      this.getSingleRecord();
      this.setState({ isRefresh: true })
    }
    if (window.location.pathname == "/patient/addPatient/0") {
      this.setState({ isRefresh: true })
    }
   
   

  }

  getSingleRecord = () => {
    this.setState({ currentId: 0 });
    let filterModel: PatientListFilterModel = {
      searchText: "",
      pageSize: 10,
      currentPage: 1,
      totalItems: 0,
      accountStatus: 0
    };

    this.patientService
      .getPatientList(filterModel)
      .then((res: HttpResponse<PatientListResponseModel>) => {
        if (res && res.result) {
          this.setState({ currentId: res.result.collection[0].id }, () => {
            this.props.navigate(`/patient/addPatient/` + this.state.currentId + "/pt");
          })
        }
      });

  }



  patientSaved = () => {
    this.setState({ counter: this.state.counter + 1 });
  };
  // onAddNewClick = () => {
  //   this.setState(
  //     {
  //       currentUserName: "Add Patient",
  //     },
  //     () => this.props.history.push("/patient/addPatient/")
  //   );
  // };
  currentUserName = (data: any, isBreadcrumChange: boolean, tabText: string) => {
    this.setState({
      currentUserName: data,
      isBreadcrumChange: isBreadcrumChange,
      tabText: tabText,
    });
  };

  render() {
    return (
      <Fragment>
        <PageTitleSetter title="Patient Details" />
        <div>
          {!this.state.isBreadcrumChange && (
            <BreadcrumSetter
              crumType="static"
              crumItem1="Patient Details"
              crumItem2={this.state.currentUserName}
              crumIcon={patientGrey}
              crumItem3={this.state.tabText}
            />
          )}
          {this.state.isBreadcrumChange && (
            <BreadcrumSetter
              crumType="link"
              crumItem1="Patient Management"
              crumItem2={this.state.currentUserName}
              crumIcon={managementGrey}
              crumItem3={this.state.tabText}
            />
          )}
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2 d-none d-lg-block aside-menu">
              {/* <section className="registration">
                <div className="add-user">
                  <button
                    type="button"
                    className="btn btn-primary btn-block add-user-btn"
                    onClick={this.onAddNewClick}
                  >
                    <img src={imgAddUser} alt="userImage" />
                    Add Patient
                  </button>
                </div>
              </section> */}
              <PatientList
                {...this.props}
                counter={this.state.counter}
                isRefresh={this.state?.isRefresh}
                id={this.state.currentId}
              />
            </div>
            <div className="col-md-10 main-section">
              <div>
                <AddPatient
                  {...this.props}
                  onPatientSave={this.patientSaved}
                  OnCurrentUserName={this.currentUserName}
                  currentId={this.state.currentId}
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Patient);
