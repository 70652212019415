import React, { Component, Fragment } from "react";
import Tabs from "../../components/tabs";
import ContentCard from "../../components/contentCard";
import ChildGuardianInfo from "../../components/childGuardianInfo";
import MedicationStatus from "../../components/medicationStatus";
import PageTitleSetter from "../../shared/pageTitleSetter";
import { Link } from "react-router-dom";
import "./styles.css";
import { PatientWeeklyDataService } from "../../services/patientWeeklyData/patient-weekly-data.service";
import { HttpResponse } from "../../core";
import { ParentWeeklyDataModel } from "../../models/parentWeeklyData/parent-weekly-data.model";
import moment from "moment";
import ChildWeek from "./childWeek";
import ChildDetailHeader from "./childDetailHeader";
import { Nav } from "react-bootstrap";
import ChildWeekMobile from "./childWeekMobile";
import withRouter from "../../shared/withRouter/withRouter";
import dayjs from "dayjs";

interface IState {
  formVisible: boolean;
  patientId: string;
  isLoading: boolean;
  parentWeeklyData: ParentWeeklyDataModel;
  isFormSave?: Function;
  reRenderForm: boolean;
  isFormVisible: boolean;
  isPageLoading: boolean;
  autoSave: boolean;
  isCurrentWeek: boolean;
  currentWeekNo: number;
  isBaseLine: boolean;
  trialStatus: number;
  selectedPatientName: string;
  trialStartDate: Date | null;
  isMobileView: boolean;
  tab: number;
  dayNum: number;
}

export class ChildWeeklyUpdate extends Component<any, any> {
  private patientWeeklyDataService: PatientWeeklyDataService;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.patientWeeklyDataService = new PatientWeeklyDataService();
    this.changeTab = this.changeTab.bind(this);
    this.changeDayNum = this.changeDayNum.bind(this);
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      isPageLoading: false,
      autoSave: false,
      patientId: this.props.patientId,
      formVisible: false,
      reRenderForm: false,
      isFormVisible: true,
      isCurrentWeek: false,
      currentWeekNo: 0,
      isBaseLine: false,
      trialStatus: 0,
      trialStartDate: null,
      isMobileView: false,
      parentWeeklyData: {
        isBaseline: false,
        trialStatus: 0,
        trialStartDate: null,
        parentTargetSymptomWeeklyDataDetail: [],
        parentAdditionalQuestionDataDetail: [],
      },
      selectedPatientName: "",
      tab: 0,
      dayNum: 0,
    };
    return initialState;
  }
  changeTab(tab: number) {
    console.log("Tab Clicked changeTab from week: " + this.state.tab + " to : " + tab)
    if((this.state.currentWeekNo >= tab) == true){
      this.setState({
      tab,
    });
    }
  }
  changeDayNum(dayNum: number) {
    console.log("Day Clicked changeDayNum from week: " + this.state.dayNum + " to : " + dayNum)
    this.setState({
      dayNum,
    });
    this.loadData(false);
  }
  private setMobileView(mobileView: boolean) {
    this.setState({
      isMobileView: mobileView,
    });
  }
  componentDidMount() {
    let windowSize = window.screen.width;
    if (this.props.params.id) {
      if (windowSize <= 1024) {
        this.setMobileView(true);
      }
      this.loadData();
    }
    
  }
  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }
  loadData = (rerender?: any) => {
    this.setLoading(true);
    if (!this.state.autoSave) {
      this.setState({ isPageLoading: true });
    }
    this.patientWeeklyDataService
      .getPatientWeeklyData(this.props.params.id)
      .then((res: HttpResponse<ParentWeeklyDataModel>) => {
        if (!this.state.autoSave) {
          this.setState({ isPageLoading: false });
        }
        this.setLoading(false);
        this.setState({ autoSave: false });
        if (res && res.result) {
          this.setState({
            parentWeeklyDataModel: {
              ...this.state.parentWeeklyDataModel,
              trialStatus: res.result.trialStatus,
              parentTargetSymptomWeeklyDataDetail:
                res.result.parentTargetSymptomWeeklyDataDetail,
              parentAdditionalQuestionDataDetail:
                res.result.parentAdditionalQuestionDataDetail,
              targetOneAlias: res.result.targetOneAlias,
            },
          });
          if (rerender == false) {
            this.setState({
              isFormVisible: true,
              isBaseLine: res.result.isBaseline,
              trialStatus: res.result.trialStatus,
              trialStartDate: res.result.trialStartDate,
            });
            if (!this.state.isBaseLine) {
              this.props.navigate(
                "/medical/medicalhistory/" + this.props.params.id
              );
            }
          } else {
            res.result.parentTargetSymptomWeeklyDataDetail.forEach(item => {
              if (item.isCurrentWeek){
                this.setState({
                  currentWeekNo : item.weekNo,
                  tab : item.weekNo,
                });
              }
            })
            this.setState(
              {
                isFormVisible: true,
                isBaseLine: res.result.isBaseline,
                trialStatus: res.result.trialStatus,
                reRenderForm: true,
                trialStartDate: res.result.trialStartDate,
                
              },
              () => {
                this.setState({ reRenderForm: false });
              }
            );
            // if (!this.state.isBaseLine) {
            //     this.props.history.push("/medical/medicalhistory/" + this.props.params.id);
            //}
          }
        } else {
          this.setState({ isFormVisible: false });
        }
      })
      .catch((ex) => {
        this.setLoading(false);
      });
  };
  selectedPatientName = (data) => {
    this.setState({
      selectedPatientName: data,
    });
  };
  postTargetSymptomsData = (data: any) => {
    let targetSymptomsData = data;
    this.setState({ autoSave: true });
    if (this.state.isLoading) {
      return;
    }

    this.setLoading(true);
    this.patientWeeklyDataService
      .postParentTargetSymptomsData(targetSymptomsData)
      .then((res: HttpResponse<boolean>) => {
        this.setLoading(false);
        if (res && res.result) {
        }
        this.loadData(false);
      })
      .catch((ex) => {
        this.setLoading(false);
      });
  };

  assignWeeklyDataModel = (data: any) => {
    if (data != null && data.parentWeekDayData != null) {
      this.postTargetSymptomsData({
        parentTargetSymptomWeeklyDataDetail: data.parentWeekDayData,
      });
    }
  };
  assignWeeklyQuestionModel = (data: any) => {
    if (data != null && data.parentAdditionalQuestionList != null) {
      this.postParentAdditionalQuestionWeeklyData({
        parentAdditionalQuestionDataDetail: data.parentAdditionalQuestionList,
      });
    }
  };
  postParentAdditionalQuestionWeeklyData = (data: any) => {
    let additionalQuestion = data;
    this.setState({ autoSave: true });
    if (this.state.isLoading) {
      return;
    }
    this.patientWeeklyDataService
      .postParentAdditionalQuestionWeeklyData(additionalQuestion)
      .then((res: HttpResponse<boolean>) => {
        if (res && res.result) {
        }
        this.loadData(false);
      })
      .catch((ex) => {});
  };
  render() {
    //const { parentWeeklyData } = this.state;
    return (
      <Fragment>
        <PageTitleSetter title="Child Weekly Status" />
        <div className="child__status">
          {!this.state.reRenderForm && (
            <ChildDetailHeader
              patientId={this.props.params.id}
              bcColor={this.state.bcColor}
              isBaseLine={this.state.isBaseLine}
              selectedPatientName={this.selectedPatientName}
              trialStatus={this.state.trialStatus}
            ></ChildDetailHeader>
          )}
        </div>

        {!this.state.isPageLoading &&
          this.state.trialStatus == 0 &&
          !this.state.isBaseLine && (
            <div className="text-center">
              <h4 className="weekly__msg">
                Your form has been submitted successfully!
                <br /> Someone from the clinic will connect with you shortly.
              </h4>
            </div>
          )}
        {/* Past trial */}
        {this.state.trialStatus == 1 && !this.state.isPageLoading && (
          <div className="text-center">
            <h4 className="weekly__msg">
              The trial of this child is completed. Please reach out to the
              clinician for any further information. <br />
            </h4>
          </div>
        )}
        {/* Future trial */}
        {this.state.trialStatus == 3 && !this.state.isPageLoading && (
          <div className="text-center">
            <h4 className="weekly__msg">
              The daily update section will be available from date{" "}
              {this.state.trialStartDate != null
                ? dayjs(this.state.trialStartDate).format("MM/DD/YYYY")
                : ""}
              . <br />
            </h4>
          </div>
        )}
        {/* Present trial */}
        {this.state.trialStatus == 2 && !this.state.isBaseLine && (
          <div className="text-center">
            <h4 className="weekly__msg">
              Your form has been submitted successfully!
              <br /> Someone from the clinic will connect with you shortly.
            </h4>
          </div>
        )}
        {/* Present trial */}
        {!this.state.isPageLoading &&
          this.state.trialStatus == 2 &&
          !this.state.isMobileView &&
          this.state.isBaseLine && (
            <div className="weekly__severity_wrapper">
              <div className="weekly__severity_container">
                {this.state.parentWeeklyDataModel &&
                  this.state.parentWeeklyDataModel.parentTargetSymptomWeeklyDataDetail.map(
                    (item, index) => {
                      return (
                        <Fragment>
                          <Tabs
                            styleName={item.weekType == 3
                              ? "weeklySev__tab remove-cursor"
                              : "weeklySev__tab add-cursor"}
                            text={dayjs(item.weekStartDate).format(
                              "MM/DD/YYYY"
                            )}
                            steps=""
                            stepsAct=""
                            fullText={dayjs(item.weekStartDate).format(
                              "MM/DD/YYYY"
                            )}
                            familyTextName={"Week" + item.weekNo}
                            image=""
                            activeImage=""
                            tabChange={() => this.changeTab(item.weekNo)}
                            selected={item.weekNo == this.state.tab}
                          />
                        </Fragment>
                      );
                    }
                  )}
              </div>
              <div className="weekly__tableStr">
                {
                  <ChildWeek
                    patientId={this.props.params.id}
                    parentWeeklyDataModel={this.state.parentWeeklyDataModel}
                    assignWeeklyDataModel={this.assignWeeklyDataModel}
                    assignWeeklyQuestionModel={this.assignWeeklyQuestionModel}
                    styleName=""
                    selectedPatientName={this.state.selectedPatientName}
                    weekNo={this.state.tab}
                  />
                }
              </div>
            </div>
          )}
        {!this.state.isPageLoading &&
          this.state.trialStatus == 2 &&
          this.state.isMobileView &&
          this.state.isBaseLine && (
            <div className="weekly__severity_wrapper">
              {/* <Nav className="nav nav-pills nav-fill">
                {this.state.parentWeeklyDataModel &&
                  this.state.parentWeeklyDataModel.parentTargetSymptomWeeklyDataDetail.map(
                    (item, index) => {
                      return (
                        <Fragment>
                          <Tabs
                            styleName={"weeklySev__tab add-cursor"}
                            text={dayjs(item.weekStartDate).format(
                              "MM/DD/YYYY"
                            )}
                            steps=""
                            stepsAct=""
                            fullText={dayjs(item.weekStartDate).format(
                              "MM/DD/YYYY"
                            )}
                            familyTextName={"Week" + item.weekNo}
                            image=""
                            activeImage=""
                            tabChange={() => this.changeTab(item.weekNo)}
                            selected={item.weekNo == this.state.tab}
                          />
                        </Fragment>
                      );
                    }
                  )}
              </Nav>
              <br />
              <Nav className="nav nav-pills nav-fill">
                {this.state.parentWeeklyDataModel &&
                  this.state.parentWeeklyDataModel.parentTargetSymptomWeeklyDataDetail[this.state.tab - 1].parentTargetSymptomWeekDayDataList.map(
                    (item, index) => {
                      return (
                        <Fragment>
                          <Tabs
                            styleName={"weeklySev__tab add-cursor"}
                            text={dayjs(item.weekDayDate).format(
                              "MM/DD/YYYY"
                            )}
                            steps=""
                            stepsAct=""
                            fullText={dayjs(item.weekDayDate).format(
                              "MM/DD/YYYY"
                            )}
                            familyTextName={"Day " + item.weekDayNo}
                            image=""
                            activeImage=""
                            tabChange={() => this.changeDayNum(item.weekDayNo)}
                            selected={item.weekDayNo == this.state.dayNum}
                          />
                        </Fragment>
                      );
                    }
                  )}
              </Nav> */}
              <div className="weekly__tableStr">
                {
                  <ChildWeekMobile
                    patientId={this.props.params.id}
                    parentWeeklyDataModel={this.state.parentWeeklyDataModel}
                    assignWeeklyDataModel={this.assignWeeklyDataModel}
                    assignWeeklyQuestionModel={this.assignWeeklyQuestionModel}
                    styleName=""
                    selectedPatientName={this.state.selectedPatientName}
                    weekNo={this.state.tab}
                    dayNum={this.state.dayNum}
                    startDate={this.state.trialStartDate}
                  />
                }
              </div>
            </div>
          )}
      </Fragment>
    );
  }
}
export default withRouter(ChildWeeklyUpdate);
