import React, { useState } from "react";
import Heading from "../../../heading";
import { Button, DatePicker, Modal, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "../../styles.css";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { AddDiagnosisPostModel } from "../../../../models/diagnosis/add-diagnosis-post.model";
import { PreviousDiagnosisModel } from "../../../../models/diagnosis/diagnosis.model";
import { Loader } from "../../../../shared/loaders";
import dayjs from "dayjs";

interface IProps {
  diagnosisModel: AddDiagnosisPostModel;
  onSubmit: Function;
  isSaving: boolean;
  patientId: string;
  assignModel: Function;
  previousTabChange: Function;
  deleteDiagnosis: Function;
  isEnabled: boolean;
  formSaved: boolean;
  isShowModel: boolean;
  submitClick: Function;
  cancelSubmitForm: Function;
  submitForm: Function;
}
export default function AddPreviousDiagnosisForm(props: any) {
  let diagnosisModel = props.diagnosisModel;
  let formSaved = props.formSaved;
  let initailModel = {
    id: "",
    professionalName: "",
    diagnosisGiven: "",
    isReportAvailable: undefined,
    diagnosisDate: undefined,
  } as PreviousDiagnosisModel;
  const { register, formState: {errors}, handleSubmit, control, getValues, formState, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
  });
  const [isEnabled, setIsEnabled] = useState(props.isEnabled);
  const formSubmit = () => {
    if (isEnabled) {
      if (!checkForError()) {
        props.onSubmit({ diagnosisModel: diagnosisModel, isAutoSave: false });
      }
    } else {
        diagnosisModel.lstTherapyModel = [];
        diagnosisModel.listPreviousDiagnosisModel = [];
      props.onSubmit({ diagnosisModel: diagnosisModel, isAutoSave: false });
    }
  };

  const handleProfessionalNameChange = (event, i) => {
    diagnosisModel.listPreviousDiagnosisModel[i].professionalName = event.target.value;
    props.assignModel({ diagnosisModel: diagnosisModel });
  };
  const handlediagnosisGiven = (event, i) => {
    diagnosisModel.listPreviousDiagnosisModel[i].diagnosisGiven = event.target.value;
    props.assignModel({ diagnosisModel: diagnosisModel });
  };
  const reportAvailableChange = (isSelected, i) => {
    diagnosisModel.listPreviousDiagnosisModel[i].isReportAvailable = isSelected;
    diagnosisModel.listPreviousDiagnosisModel[i].isReportAvailableError = "";
    props.assignModel({ diagnosisModel: diagnosisModel });
    saveDataOnTabChange();
  };
  const diagnosisDateChange = (date, i) => {
    if (date == null) {
      diagnosisModel.listPreviousDiagnosisModel[i].diagnosisDate = null;
    } else {
        diagnosisModel.listPreviousDiagnosisModel[i].diagnosisDate = dayjs(date).format("MM/DD/YYYY");
    }
    setValue(
      "diagnosisDate" + i,
      dayjs(
        diagnosisModel.listPreviousDiagnosisModel[i].diagnosisDate
          ? new Date(diagnosisModel.listPreviousDiagnosisModel[i].diagnosisDate)
          : new Date()
      )
    );
    saveDataOnTabChange();
  };

  const checkForError = () => {
    let hasError = false;
    if (
      diagnosisModel.listPreviousDiagnosisModel != null &&
      diagnosisModel.listPreviousDiagnosisModel.length > 0
    ) {
      diagnosisModel.listPreviousDiagnosisModel.forEach((x) => {
        x.professionalNameError = "";
        x.diagnosisGivenError = "";
        x.diagnosisDateError = "";
        x.isReportAvailableError = "";
        if (x.professionalName == "") {
          x.professionalNameError = "Please fill name of professional name.";
          hasError = true;
        }
        if (x.diagnosisGiven == "" || x.diagnosisGiven == null) {
          x.diagnosisGivenError = "Please fill diagnosis.";
          hasError = true;
        }
        if (x.isReportAvailable == null) {
          x.isReportAvailableError = "Please select an option.";
          hasError = true;
        }
        if (x.diagnosisDate == null) {
          x.diagnosisDateError = "Please fill diagnosis date.";
          hasError = true;
        }
      });
    }
    return hasError;
  };
  const handleOnFocus = (event, i) => {
    saveDataOnTabChange();
  };

  const saveDataOnTabChange = () => {
    if (!props.isSaving) {
      props.onSubmit({ diagnosisModel: diagnosisModel, isAutoSave: true });
    }
  };

  const handleRemoveClick = (index) => {
    let idToDelete = diagnosisModel.listPreviousDiagnosisModel[index].id;
    if (idToDelete != "") {
      props.deleteDiagnosis(idToDelete);
    }
    diagnosisModel.listPreviousDiagnosisModel.splice(index, 1);
    props.assignModel({ diagnosisModel: diagnosisModel });
  };
  const handleAddClick = () => {
    diagnosisModel.listPreviousDiagnosisModel.push(initailModel);
    props.assignModel({ diagnosisModel: diagnosisModel });
  };
  const handleIsEnabledClick = (isEnabled: boolean) => {
    setIsEnabled(isEnabled);
    if (!isEnabled) {
      diagnosisModel.listPreviousDiagnosisModel = [];
      diagnosisModel.listPreviousDiagnosisModel.push(initailModel);
      props.assignModel({ diagnosisModel: diagnosisModel });
    }
  };
  return (
    <div>
      <Modal
        title=""
        open={props.isShowModel}
        onOk={props.submitForm}
        onCancel={props.cancelSubmitForm}
        okText="Yes"
        cancelText="No"
        closable={false}
        maskClosable={false}
      >
        {" "}
        <p>
        Are you ready to complete your form? You will not be allowed to make any further changes.
        </p>
      </Modal>
      <form onSubmit={handleSubmit(formSubmit)}>
        <div>
          <Heading heading="Previous Diagnosis" subHeading="" styleName="" />
          <div className="form">
            <div className="row">
                <label className="form-label col-12 col-md-5">
                  Has your child received a previous diagnosis for a behavioral, emotional or
                  intellectual difficulty by his/her school or another professional?
                  <br /> If yes, please complete the following:
                </label>
                <div className="btn-group btnGender col-6 col-md-3">
                  <button
                    type="button"
                    onClick={() => handleIsEnabledClick(true)}
                    className={
                      isEnabled == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => handleIsEnabledClick(false)}
                    className={
                      isEnabled == false 
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
              </div>
            </div>
            {diagnosisModel.listPreviousDiagnosisModel != null &&
              diagnosisModel.listPreviousDiagnosisModel.map((diagnosis, index) => {
                return (
                  <div className="row" key={index}>
                    <div className="medical-group col-12 col-md-3">
                      <label className="form-label">
                        Name of Professional who diagnosed
                      </label>
                      <input
                        type="text"
                        name={"professionalName" + index}
                        className={
                          !diagnosis.professionalNameError
                            ? "form-control mb-1"
                            : "form-control mb-1  is-invalid"
                        }
                        value={diagnosis.professionalName}
                        onChange={(e) => handleProfessionalNameChange(e, index)}
                        onFocus={(e) => handleOnFocus(e, index)}
                        disabled={!isEnabled}
                      />
                      {diagnosis.professionalNameError && (
                        <div className="invalid-feedback" style={{ display: "block" }}>
                          {diagnosis.professionalNameError}
                        </div>
                      )}
                    </div>
                    <div className="medical-group col-12 col-md-3">
                      <label className="form-label">Diagnosis given</label>
                      <input
                        type="text"
                        name={"diagnosisGiven" + index}
                        className={
                          !diagnosis.diagnosisGivenError
                            ? "form-control mb-1"
                            : "form-control mb-1  is-invalid"
                        }
                        value={diagnosis.diagnosisGiven}
                        onChange={(e) => handlediagnosisGiven(e, index)}
                        onFocus={(e) => handleOnFocus(e, index)}
                        disabled={!isEnabled}
                      />
                      {diagnosis.diagnosisGivenError && (
                        <div className="invalid-feedback" style={{ display: "block" }}>
                          {diagnosis.diagnosisGivenError}
                        </div>
                      )}
                    </div>

                    <div className="medical-group col-12 col-md-2  mb-3 btn-select">
                      <label className="form-label">Report available?</label>
                      <div
                         className={!isEnabled ? "col-6 col-md-12 disabled btn-group btnAdopted " : "col-6 col-md-12 btn-group btnAdopted "}
                      >
                        <button
                          type="button"
                          onClick={() => reportAvailableChange(true, index)}
                          className={
                            diagnosis.isReportAvailable == true
                            ? "btn btn-primary btn-sm btnAdopted_yes"
                            : "btn btn-outline-secondary btn-sm btnAdopted_others"
                          }
                          disabled={!isEnabled}
                        >
                          Yes
                        </button>
                        <button
                          type="button"
                          className={
                            diagnosis.isReportAvailable == false
                            ? "btn btn-primary btn-sm btnAdopted_yes"
                            : "btn btn-outline-secondary btn-sm btnAdopted_others"
                          }
                          onClick={() => reportAvailableChange(false, index)}
                          disabled={!isEnabled}
                        >
                          No
                        </button>
                        
                      </div>
                      {diagnosis.isReportAvailableError && (
                          <div className="invalid-feedback" style={{ display: "block" }}>
                            {diagnosis.isReportAvailableError}
                          </div>
                        )}
                    </div>
                    <div className="medical-group col-12 col-md-3 mb-3 dob">
                      <label className="form-label">Date of diagnosis</label>
                      <Controller
                        render={({field: { onChange, onBlur, value, name, ref }}) => (
                          <Tooltip placement="right" title="Date should be in MM/DD/YYYY format">
                          <DatePicker
                            onChange={(e) => {
                              diagnosisDateChange(e, index);
                            }}
                            className="datepicker"
                            inputReadOnly={false}
                            name={name}
                            value={
                              diagnosis.diagnosisDate == null
                                ? undefined
                                : dayjs(diagnosis.diagnosisDate)
                            }
                            placeholder="MM/DD/YYYY"
                            format="MM/DD/YYYY"
                            disabled={!isEnabled}
                          />
                          </Tooltip>
                        )}
                        name={"diagnosisDate" + index}
                        defaultValue={dayjs(diagnosis.diagnosisDate)}
                        control={control}
                      />
                      {diagnosis.diagnosisDateError && (
                        <div className="invalid-feedback" style={{ display: "block" }}>
                          {diagnosis.diagnosisDateError}
                        </div>
                      )}
                    </div>
                    {diagnosisModel.listPreviousDiagnosisModel.length > 1 && (
                       <i
                       className="far fa-trash-alt delete medical-group col-12 col-md-1 align-self-center text-center"
                       onClick={() => handleRemoveClick(index)}
                   ></i>
                    )}
                    <hr className="mt-3"/>
                  </div>
                );
              })}
            <div className="medical__medChild_formRow">
              <Button
                type="primary"
                size="large"
                disabled={!isEnabled}
                onClick={handleAddClick}
                block
                icon={<PlusOutlined />}
                className="addNew__row"
              >
                Add More
              </Button>
            </div>
          </div>
          <div className="medical__medChild_formRow">
            <div className="row">
            {!props.isSaving && (
              <Button
                type="default"
                size="large"
                icon={<i className="fa fa-long-arrow-left"></i>}
                className="back_btn col-4 col-md-2 mx-3"
                onClick={() => props.parentTabChange(8)}
              >
                Back
              </Button>
            )}
            {/* !props.formSaved */}
            {!props.isSaving && (
              <Button type="primary" size="large" htmlType="submit" className="btn_add col-4 col-md-2 mx-3">
                Save
              </Button>
            )}
                      <Tooltip placement="top" title="By clicking Submit, your form will be sent to the clinician. No further changes can be made.">
            {!props.isSaving && (
              <Button type="primary" size="large" className="btn_add col-4 col-md-2 mx-3" onClick={props.submitClick}>
                Submit
              </Button>
            )}
            </Tooltip>
            </div>
            
            {props.isSaving && (
              <div style={{ height: "60px" }}>
                <Loader
                  loading={props.isSaving}
                  marginBottom="0px"
                  marginTop="8px"
                  width="368px"
                ></Loader>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
