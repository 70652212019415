import React, { Fragment } from "react";
import { HttpResponse } from "../../../../core";
import { ShowErrorMessage, ShowSuccessMessage } from "../../../../shared/helpers";
import { AddPerenatalPeriodModel } from "../../../../models/birthHistory/perenatalPeriod/add-perenatal-period";
import { BirthHistoryService } from "../../../../services/birthHistory/birth-history.service";
import { AddPerenatalForm } from "./addPrenatalPeriodForm";
import { AddPerenatalPeriodPostModel } from "../../../../models/birthHistory/perenatalPeriod/add-prenatal-period-post";
import { FrequencyType } from "../../../../shared/enums/frequency-type";

interface IState {
  isLoading: boolean;
  tab: number;
  isReadonly: boolean;
  reRenderForm: boolean;
  perenatalPeriodModel: AddPerenatalPeriodModel;
  patientId: string;
  isFormSave?: Function;
  birthHistoryStepUpdate?: Function;
}
export class PrenatalPeriod extends React.Component<any, IState> {
  private birthHistoryService: BirthHistoryService;
  isComponentMounted: boolean = false;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.birthHistoryService = new BirthHistoryService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      tab: 1,
      isReadonly: false,
      perenatalPeriodModel: {} as AddPerenatalPeriodModel,
      reRenderForm: false,
      patientId: this.props.patientId,
    };
    return initialState;
  }

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }

  componentDidMount() {
    this.loadData();
    window.scroll(0, 0);
  }

  loadData(isAutoSave?: any) {
    if (this.state.patientId) {
      this.birthHistoryService
        .getPerenatalPeriod(this.state.patientId)
        .then((res: HttpResponse<AddPerenatalPeriodModel>) => {
          if (res && res.result) {
            this.props.birthHistoryStepUpdate(2,res.result.id);
            if (isAutoSave) {
              let pm = this.state.perenatalPeriodModel;
              pm.id = res.result.id;
              pm.patientId = res.result.patientId;
              this.setState({
                perenatalPeriodModel: pm,
              });
            } else {
              let perenatalPeriod: AddPerenatalPeriodModel = {
                id: res.result.id,
                patientId: res.result.patientId,
                smoked: res.result.smoked,
                smokedFrequency: res.result.smokedFrequency,
                consumedAlcohol: res.result.consumedAlcohol,
                consumedAlcoholFrequency: res.result.consumedAlcoholFrequency,
                illegalDrugs: res.result.illegalDrugs,
                illegalDrugsFrequency: res.result.illegalDrugsFrequency,
                tookOtherMedicine: res.result.tookOtherMedicine,
                specifyTookOtherMedicine: res.result.specifyTookOtherMedicine,
                complications: res.result.complications,
                specifyComplications: res.result.specifyComplications,
                stress: res.result.stress,
                specifyStress: res.result.specifyStress,
                comments: res.result.comments,
              };
              this.setState({
                perenatalPeriodModel: perenatalPeriod,
              });
            }
          }
        })
        .catch((ex) => {});
    }
  }

  postData = (data: any) => {
    let perenatalPeriod = data.perenatalPeriodModel;
    let perenatalPeriodPost: AddPerenatalPeriodPostModel = {
      id: perenatalPeriod.id,
      patientId: perenatalPeriod.patientId,
      smoked: perenatalPeriod.smoked,
      smokedFrequency: parseInt(FrequencyType[perenatalPeriod.smokedFrequency]),
      consumedAlcohol: perenatalPeriod.consumedAlcohol,
      consumedAlcoholFrequency: parseInt(FrequencyType[perenatalPeriod.consumedAlcoholFrequency]),
      illegalDrugs: perenatalPeriod.illegalDrugs,
      illegalDrugsFrequency: parseInt(FrequencyType[perenatalPeriod.illegalDrugsFrequency]),
      tookOtherMedicine: perenatalPeriod.tookOtherMedicine,
      specifyTookOtherMedicine: perenatalPeriod.specifyTookOtherMedicine,
      complications: perenatalPeriod.complications,
      specifyComplications: perenatalPeriod.specifyComplications,
      stress: perenatalPeriod.stress,
      specifyStress: perenatalPeriod.specifyStress,
      comments: perenatalPeriod.comments,
    };
    if (this.state.isLoading) {
      return;
    }
      if (data.isAutoSave) {
          this.setLoading(false);
      }
      else {
          this.setLoading(true);
      }
    this.birthHistoryService
      .postPerenatalPeriod(perenatalPeriodPost)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
          this.state.perenatalPeriodModel.id=res.result;
          if (!data.isAutoSave) {
            ShowSuccessMessage("Data Saved Successfully.");
            this.props.nextTabChange();
          }
        }
        this.setLoading(false);
        this.props.isFormSave();
        if (!data.isAutoSave) {
          this.loadData(data.isAutoSave);
        }
      })
      .catch((ex) => {
        this.setLoading(false);
        ShowErrorMessage("Error in creating prenatal period.");
      });
  };
  previousTabChange = () => {
    this.props.previousTabChange();
  };

  assignModel = (data: any) => {
    this.setState(
      {
        perenatalPeriodModel: data.perenatalPeriodModel,
        reRenderForm: true,
      },
      () => this.setState({ reRenderForm: false })
    );
  };

  render() {
    return (
      <Fragment>
        {!this.state.reRenderForm && (
          <AddPerenatalForm
            onSubmit={this.postData}
            isSaving={this.state.isLoading}
            perenatalPeriodModel={this.state.perenatalPeriodModel}
            patientId={this.props.patientId}
            assignModel={this.assignModel}
            isReadOnly={this.state.isReadonly}
            previousTabChange={this.previousTabChange}
            isBirthHistoryOptional={this.props.isBirthHistoryOptional}
          />
        )}
      </Fragment>
    );
  }
}
