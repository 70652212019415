import { AppConfig, HttpWrapper } from "../../core";
import { AddDiagnosisPostModel } from "../../models/diagnosis/add-diagnosis-post.model";

export class DiagnosisService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }
  /* Therapy Start */
  postDiagnosis(model: AddDiagnosisPostModel) {
    return this.wrapper.post<any>(AppConfig.apiEndpoint + "previousdiagnosis/create", model);
  }
  getDiagnosis(patientId: string) {
    return this.wrapper.get<any>(AppConfig.apiEndpoint + "previousdiagnosis/detail/" + patientId);
  }
  deleteDiagnosis(id: string) {
    return this.wrapper.delete<boolean>(AppConfig.apiEndpoint + "previousdiagnosis/delete/" + id);
  }
  updateParentIsFormSubmitted(patientId: string) {
    return this.wrapper.get<Boolean>(
      AppConfig.apiEndpoint + "patient/update-social-history-value/" + patientId
    );
  }
  /* Therapy End */
}
