import React, { Fragment, useEffect, useState } from "react";
import ChildGuardianInfo from "../../../components/childGuardianInfo";
import ContentCard from "../../../components/contentCard";
import MedicationStatus from "../../../components/medicationStatus";
import { MedicationStatusModel } from "../../../models/medication/medication-status.model";
import { TeacherDetailModel } from "../../../models/teacher/teacher-detail.model";
import { Gender } from "../../../shared/enums/gender.enum";
import { TeacherWeeklyDataService } from "../../../services/teacherWeeklyData/teacher-weekly-data.service";
import { HttpResponse } from "../../../core";
interface IProps {
  patientId: string | null;
    bcColor: string;
    isBaseline: boolean;
    trialStatus: number;
    selectedPatientName: Function;
}
export function TeacherDetailHeaderForm(props: IProps) {
  const teacherWeeklyDataService = new TeacherWeeklyDataService();
  const emptyTeacherDetailModel = {} as TeacherDetailModel;
  const emptyMedicationStatus = {} as MedicationStatusModel[];
  
  const [isLoading, setIsLoading] = useState<boolean>(false);
    const [teacherDetailModel, setTeacherDetailModel] = useState<TeacherDetailModel>(emptyTeacherDetailModel);
    const [reRendorForm, setReRendorForm] = useState<boolean>(false);
    const [patientId, setPatientId] = useState<string | null>(props.patientId);
    const [medicationStatus, setMedicationStatus] = useState<MedicationStatusModel[]>(emptyMedicationStatus);
    const [trialStatus, setTrialStatus] = useState<number>(props.trialStatus);
    const [patientName, setPatientName] = useState("");

    useEffect(() => {
      loadData();
      getMedicationStatusByPatientId();
  }, [patientId]);
  useEffect(() => {
      setPropsSelectedPatientName()
  }, [patientName])

  const setPropsSelectedPatientName = () => {
      props.selectedPatientName(patientName)
      setReRendorForm(false)
  }

  const loadData = () => {
      if (patientId != null) {
          setIsLoading(true);
          teacherWeeklyDataService.getUserById(patientId)
          .then((res: HttpResponse<TeacherDetailModel>) => {
              setIsLoading(false);
              if (res && res.result) {
                  setTeacherDetailModel(res.result);
                  setReRendorForm(true);
                  setPatientName(res.result.lastName + ","+ " " + res.result.firstName);
              }
          })
          .catch(() => {
              setIsLoading(false)
          })
      }
  };

  const getMedicationStatusByPatientId = () => {
      if (patientId != null) {
          teacherWeeklyDataService.getMedicationStatusByPatientId(patientId)
          .then((res: HttpResponse<MedicationStatusModel[]>) => {
              if (res && res.result){
                  setMedicationStatus(res.result)
              }
          })
          .catch(() => {})
      }
  };
  const handleGender = (val: any) => {
    let value = "-";
    if (val == Gender.Male) value = "Male";
    else if (val == Gender.Female) value = "Female";
    else if (val == Gender.Others) value = "Other";
    return value;
    };
    let options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    };
  return (
    <Fragment>
      <ContentCard
        styleName="childTab__status remove-cursor"
        contents={[
          <div className="status__wrapper">
            <div className="statusInfo__cover">
              {teacherDetailModel != null && (
                <div>
                  <span
                    className="name_wrap"
                    style={{ backgroundColor: props.bcColor }}
                  >
                    {teacherDetailModel.initials == null
                      ? ""
                      : teacherDetailModel.initials}
                  </span>
                  <p className="ccfChild_username">
                    {teacherDetailModel.lastName == null
                      ? ""
                      : teacherDetailModel.lastName + ", "}
                    {teacherDetailModel.firstName == null
                      ? ""
                      : teacherDetailModel.firstName}
                    <br />
                    <span>
                      Date of Birth:{" "}
                      {teacherDetailModel.dateOfBirth == null
                        ? ""
                        : new Date(
                            teacherDetailModel.dateOfBirth
                        ).toLocaleDateString('en', options)}
                      , {handleGender(teacherDetailModel.gender)}
                    </span>
                  </p>
                </div>
              )}
            </div>
            <div className="statusInfo__cover">
              <ChildGuardianInfo
                designation="Parent/Guardian"
                name={
                  teacherDetailModel.guardianFirstName
                    ? teacherDetailModel.guardianFirstName +
                      " " +
                      teacherDetailModel.guardianLastName
                    : ""
                }
                email={
                  teacherDetailModel.emailId ? teacherDetailModel.emailId : ""
                }
                guardian="historyViewHeader"
                guardianStyle="historyViewH5"
              />
            </div>
            {medicationStatus.length > 0 &&
              props.isBaseline &&
              props.trialStatus == 2 && (
                <div>
                  <ChildGuardianInfo
                    designation="Medication Status"
                    name=""
                    email=""
                  />
                  <div>
                    {medicationStatus &&
                      medicationStatus.map((item, i) => {
                        return (
                          <MedicationStatus
                            text={"W" + item.weekNo}
                            backgroundColor={
                              item.hasData &&
                              item.weekType ==1
                                ? "var(--color-fadeGreen)"
                                : "var(--color-lightWhite)"
                            }
                            color="var(--color-navyBlue)"
                            statusStyle=""
                            index={i}
                          />
                        );
                      })}
                  </div>
                </div>
              )}
          </div>,
        ]}
      />
    </Fragment>
  );
}
