import React, { Fragment, useDebugValue, useEffect, useState } from "react";
import { GetUserInfo, ShowSuccessMessage } from "../../shared/helpers";
import PageTitleSetter from "../../shared/pageTitleSetter";
import ChildDetailHeader from "../childWeeklyUpdate/childDetailHeader";
import { PatientService } from "../../components/patient/services";
import { HttpResponse } from "../../core";
import { PatientsListByParentIdModel } from "../../components/patient/models/patients-list-By-parentId.model";
import RespondentList from "../../components/addRespondent/RespondentList";
import { Button } from "antd";
import addPatient from "../../assets/images/svg-icons/add_patient.svg";
import "./styles.css";
import Modal from "antd/es/modal/Modal";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { RespondentInformation } from "../../models/Respondent/RespondentInfo";
import { Relation } from "../../models/Respondent/Relation";
import { RespondentService } from "../../services/respondent/RespondentService";
import { AddRespondentModel } from "../../models/Respondent/AddRespondentModel";

type AddRespondentFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  relation: string;
  doesGetDosageQuestions: boolean;
  doesNotGetDosageQuestions: boolean;
};
function AddRespondent() {
  const emptyRespondent: RespondentInformation = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    relation: {
      id: "",
      label: "",
      value: "",
    },
    getsDosageQuestions: false,
  };

  var userData = GetUserInfo();
  const [patientId, setPatientId] = useState("");
  const [childFirstName, setChildFirstName] = useState("");
  const [childLastName, setChildLastName] = useState("");
  const [bcColor, setBcColor] = useState(undefined);
  const [isBaseLine, setIsBaseLine] = useState(false);
  const [selectedPatientName, setSelectedPatientName] = useState("");
  const [trialStatus, setTrialStatus] = useState(0);
  const [showAddRespondent, setShowAddRespondent] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [getsDosageQuestions, setGetsDosageQuestions] = useState(false);
  const [respondentList, setRespondentList] = useState<RespondentInformation[]>(
    []
  );
  const [showDeleteRespondent, setShowDeleteRespondent] = useState(false);
  const [respondentToRemove, setRespondentToRemove] =
    useState<RespondentInformation>(emptyRespondent);
  const [relationOptions, setRelationOptions] = useState<Relation[]>([]);
  const [showEditRespondent, setShowEditRespondent] = useState(false);
  const [checkEmailExists, setCheckEmailExists] = useState(false);

  const form = useForm<AddRespondentFormValues>();
  const {
    register,
    control,
    handleSubmit,
    formState,
    getValues,
    setValue,
    reset,
  } = form;
  const { errors } = formState;

  const patientService = new PatientService();
  const respondentService = new RespondentService();

  useEffect(() => {
    getRelationOptions();
    loadPatientInfo();
  }, []);

  function loadPatientInfo() {
    patientService
      .getPatientsListByParentId(userData.userId)
      .then((res: HttpResponse<PatientsListByParentIdModel>) => {
        if (res && res.result && res.result.patientDetails.length > 0) {
          setPatientId(res.result.patientDetails[0].id);
          setChildFirstName(res.result.patientDetails[0].firstName);
          setChildLastName(res.result.patientDetails[0].lastName);
          getRespondentsList(res.result.patientDetails[0].id);
        }
      });
  }
  const getRelationOptions = () => {
    let relations: Relation[] = [];

    respondentService
      .getRelationList()
      .then((res: HttpResponse<Relation[]>) => {
        if (res && res.result && res.result.length > 0) {
          console.log("GetRelationOptions: ", res.result);
          let relationToAdd: Relation;
          res.result.map((item) => {
            relations.push(item);
          });
        }
      });

    setRelationOptions(relations);
  };

  const getRespondentsList = (id: string) => {
    let respondents: RespondentInformation[] = [];

    respondentService
      .getRespondentsById(id)
      .then((res: HttpResponse<RespondentInformation[]>) => {
        if (res && res.result && res.result.length > 0) {
          console.log("getRespondentList: ", res.result);
          res.result.map((item) => {
            respondents.push(item);
          });
          setRespondentList(respondents);
        }
      });
  };

  const addRespondent = () => {
    reset();
    setGetsDosageQuestions(false);
    setShowAddRespondent(true);
  };

  const handleSaveClick = (data: AddRespondentFormValues) => {
    console.log("Save button clicked", data);
    let respondentToAdd: AddRespondentModel = {
      firstName: "",
      lastName: "",
      email: "",
      relationId: "",
      getsDosageQuestions: false,
      isActive: false,
      guardianFirstName: userData.firstName ?? "",
      guardianLastName: userData.lastName ?? "",
      childFirstName: childFirstName,
      childLastName: childLastName,
      patientId: patientId,
    };
    let relation: Relation = { id: "", label: "", value: "" };
    relationOptions.map((item) => {
      if (item.value === data.relation) {
        relation = item;
      }
    });

    respondentToAdd.firstName = data.firstName;
    respondentToAdd.lastName = data.lastName;
    respondentToAdd.email = data.email;
    respondentToAdd.relationId = relation.id;
    respondentToAdd.getsDosageQuestions = getsDosageQuestions;
    respondentToAdd.isActive = true;
    respondentToAdd.patientId = patientId;

    console.log(respondentToAdd);
    respondentService
      .addRespondent(respondentToAdd)
      .then((res: HttpResponse<string>) => {
        if (res) {
          console.log("Respondent added: ", respondentToAdd);
          setShowAddRespondent(false);
          reset();
          ShowSuccessMessage("Invite Sent Successfully.");
          getRespondentsList(patientId);
        }
      });

    console.log(respondentList.length);
  };

  const ResendInvite = (data: RespondentInformation) =>{
    console.log("Resend Invite button clicked", data);
    let respondentToInvite: AddRespondentModel = {
      firstName: "",
      lastName: "",
      email: "",
      relationId: "",
      getsDosageQuestions: false,
      isActive: false,
      guardianFirstName: userData.firstName ?? "",
      guardianLastName: userData.lastName ?? "",
      childFirstName: childFirstName,
      childLastName: childLastName,
      patientId: patientId,
    };

    if (data.id){
      respondentToInvite.respondentId = data.id;
      respondentToInvite.firstName = data.firstName;
      respondentToInvite.lastName = data.lastName;
      respondentToInvite.email = data.email;
      respondentToInvite.relationId = data.relation.id;
      respondentToInvite.getsDosageQuestions = data.getsDosageQuestions;
      respondentToInvite.isActive = true;
      respondentToInvite.patientId = patientId;

      console.log(respondentToInvite);
      respondentService
        .addRespondent(respondentToInvite)
        .then((res: HttpResponse<string>) => {
          if (res) {
            console.log("Respondent added: ", respondentToInvite);
            setShowAddRespondent(false);
            reset();
            ShowSuccessMessage("Invite Sent Successfully.");
            getRespondentsList(patientId);
          }
        });
    }
    

    console.log(respondentList.length);
  }
  const cancelAddRespondent = () => {
    console.log("Cancel button clicked");
    setShowAddRespondent(false);
    reset();
    setGetsDosageQuestions(false);
  };

  const doesGetDosageQuestions = () => {
    setGetsDosageQuestions(true);
  };
  const doesNotGetDosageQuestions = () => {
    setGetsDosageQuestions(false);
  };

  const removeRespondent = (respondent: RespondentInformation) => {
    console.log("Call to remove id:", respondent.id);
    setRespondentToRemove(respondent);
    setShowDeleteRespondent(true);
  };

  const editRespondent = (respondent: RespondentInformation) => {
    console.log("Call to Edit id:", respondent.id);
    setValue("firstName", respondent.firstName);
    setValue("lastName", respondent.lastName);
    setValue("email", respondent.email);
    setValue("relation", respondent.relation.value);
    if (respondent.getsDosageQuestions) {
      setValue("doesGetDosageQuestions", true);
      setValue("doesNotGetDosageQuestions", false);
    } else {
      setValue("doesGetDosageQuestions", false);
      setValue("doesNotGetDosageQuestions", true);
    }
    setShowEditRespondent(true);
  };

  const handleEditClick = (data: AddRespondentFormValues) => {
    console.log("Save button clicked");
    let respondentToAdd: RespondentInformation = {
      firstName: "",
      lastName: "",
      email: "",
      relation: {
        id: "",
        label: "",
        value: "",
      },
      getsDosageQuestions: false,
    };
    let relation: Relation = { id: "", label: "", value: "" };
    relationOptions.map((item) => {
      if (item.value === data.relation) {
        relation = item;
      }
    });

    respondentToAdd.firstName = data.firstName;
    respondentToAdd.lastName = data.lastName;
    respondentToAdd.email = data.email;
    respondentToAdd.relation = relation;
    respondentToAdd.getsDosageQuestions = getsDosageQuestions;

    console.log(respondentToAdd);
    setRespondentList([...respondentList, respondentToAdd]);
    setShowAddRespondent(false);
    reset();
    console.log(respondentList.length);
  };

  const cancelEditRespondent = () => {
    console.log("Cancel button clicked");
    setShowEditRespondent(false);
    reset();
    setGetsDosageQuestions(false);
  };

  const cancelRemoveRespondent = () => {
    setRespondentToRemove(emptyRespondent);
    setShowDeleteRespondent(false);
  };

  const confirmRemoveRespondent = () => {
    let updatedList: RespondentInformation[] = [];
    respondentList.map((item) => {
      if (item.id !== respondentToRemove.id) {
        updatedList.push(item);
      }
    });
    setRespondentList(updatedList);
    setRespondentToRemove(emptyRespondent);
    setShowDeleteRespondent(false);
  };

  const isEmailExist = () => {
    const email = getValues("email");
    console.log("isEmailExist: ", email);

    respondentService
      .checkIsEmailExists({ id: undefined, emailId: email })
      .then((res: HttpResponse<any>) => {
        if (res.result) {
          if (res.result.isEmail === true) {
            setCheckEmailExists(true);
          } else {
            setCheckEmailExists(false);
          }
        } else {
          setCheckEmailExists(false);
        }
      })
      .catch((ex) => {
        console.log("Something Went wrong in isEmailExist");
      });
  };

  return (
    <Fragment>
      <PageTitleSetter title="Respondent Management" />

      <div className="child__status">
        {patientId !== "" && (
          <ChildDetailHeader
            patientId={patientId}
            bcColor={bcColor}
            isBaseLine={isBaseLine}
            selectedPatientName={setSelectedPatientName}
            trialStatus={trialStatus}
          ></ChildDetailHeader>
        )}
      </div>

      <div className="add_respondent">
        {/* <h3><strong>List of Respondent</strong> {userData.firstName + " "} {userData.lastName}</h3> */}
        <Button
          type="primary"
          size="large"
          className="btn_add"
          icon={
            <img
              src={addPatient}
              alt="addPatient"
              className="addPatient__img"
            />
          }
          onClick={addRespondent}
        >
          Add Respondent
        </Button>
      </div>
      {/* <Modal
          title="Remove Respondent"
          open={showDeleteRespondent}
          onOk={confirmRemoveRespondent}
          onCancel={cancelRemoveRespondent}
          okText="Remove Respondent"
          okType="primary"
          cancelText="Cancel"
          // closable={false}
          maskClosable={false}
          className="editProfile__modal"
          okButtonProps={{ disabled: disableSaveButton }}
        >
          <h3>
            Are you sure you want to remove{" "}
            <strong>
              {respondentToRemove.firstName} {respondentToRemove.lastName}
            </strong>
            ?
          </h3>
        </Modal> */}
      <Modal
        title="Add Respondent"
        open={showAddRespondent}
        onOk={handleSubmit(handleSaveClick)}
        onCancel={cancelAddRespondent}
        okText="Save and Invite"
        okType="primary"
        cancelText="Cancel"
        // closable={false}
        maskClosable={false}
        className=""
        okButtonProps={{ disabled: disableSaveButton }}
      >
        <form>
          <div className="form-control">
            <label htmlFor="firstName">First Name</label>
            <input
            className="form-control"
              type="text"
              id="firstName"
              {...register("firstName", {
                required: "First Name is required",
              })}
            />
            <p className="error">{errors.firstName?.message}</p>
          </div>
          <div className="form-control">
            <label htmlFor="lastName">Last Name</label>
            <input
            className="form-control"
              type="text"
              id="lastName"
              {...register("lastName", {
                required: "Last Name is required",
              })}
            />
            <p className="error">{errors.lastName?.message}</p>
          </div>
          <div className="form-control">
            <label htmlFor="email">email</label>
            <input
            className="form-control"
              type="email"
              id="email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value:
                    /^((([!#$%&'*+\-/=?^_`{|}~\w])|([!#$%&'*+\-/=?^_`{|}~\w][!#$%&'*+\-/=?^_`{|}~\.\w]{0,}[!#$%&'*+\-/=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*)$/,
                  message: "Invalid email format",
                },
                validate: {
                  exists: (value) =>
                    !checkEmailExists || "Email Already Exists.",
                },
                onBlur: (e) => isEmailExist(),
              })}
            />
            <p className="error">{errors.email?.message}</p>
          </div>
          <div className="form-control">
            <label htmlFor="relation">Relation</label>
            <br />
            <select
            className="form-control"
              placeholder="Select an Option"
              id="relation"
              {...register("relation", {
                onChange:  (e) => isEmailExist(),
                validate: {
                  isSelected: (fieldValue) => {
                    return (
                      fieldValue !== "" ||
                      "Please select respondents relation to patient."
                    );
                  },
                },
              })}
            >
              <option value="">Select Relation</option>
              {relationOptions.length > 0 &&
                relationOptions.map((item) => {
                  return (
                    <option key={item.id} value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
            </select>
            <p className="error">{errors.relation?.message}</p>
          </div>
          <div>
            <label htmlFor="getsDosageQuestions">
            Should this individual receive questions related to dosage and continuing the trial?
            </label>
            <div className="btn-addon btnGender">
              <Button
                className={
                  getsDosageQuestions
                    ? "btnGender_selected"
                    : "btnGender_unselected"
                }
                onClick={doesGetDosageQuestions}
              >
                Yes
              </Button>
              <Button
                className={
                  !getsDosageQuestions
                    ? "btnGender_selected"
                    : "btnGender_unselected"
                }
                onClick={doesNotGetDosageQuestions}
              >
                No
              </Button>
            </div>
          </div>
        </form>
      </Modal>
      {/* <Modal
          title="Edit Respondent"
          open={showEditRespondent}
          onOk={handleSubmit(handleEditClick)}
          onCancel={cancelEditRespondent}
          okText="Update Respondent"
          okType="primary"
          cancelText="Cancel"
          // closable={false}
          maskClosable={false}
          className="editProfile__modal"
          okButtonProps={{ disabled: disableSaveButton }}
        >
          <form>
            <div className="form-control">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                {...register("firstName", {
                  required: "First Name is required",
                })}
              />
              <p className="error">{errors.firstName?.message}</p>
            </div>
            <div className="form-control">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                {...register("lastName", {
                  required: "Last Name is required",
                })}
              />
              <p className="error">{errors.lastName?.message}</p>
            </div>
            <div className="form-control">
              <label htmlFor="email">email</label>
              <input
                type="email"
                id="email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value:
                      /^((([!#$%&'*+\-/=?^_`{|}~\w])|([!#$%&'*+\-/=?^_`{|}~\w][!#$%&'*+\-/=?^_`{|}~\.\w]{0,}[!#$%&'*+\-/=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*)$/,
                    message: "Invalid email format",
                  },
                })}
              />
              <p className="error">{errors.email?.message}</p>
            </div>
            <div className="form-control">
              <label htmlFor="relation">Relation</label>
              <select
                placeholder="Select an Option"
                id="relation"
                {...register("relation", {
                  validate: {
                    isSelected: (fieldValue) => {
                      return (
                        fieldValue !== "" ||
                        "Please select respondents relation to patient."
                      );
                    },
                  },
                })}
              >
                <option value="">Select Relation</option>
                {relationOptions.length > 0 && relationOptions.map((item) => {
                  return (
                    <option key={item.id} value={item.value}>{item.label}</option>
                  )
                })}
              </select>
              <p className="error">{errors.relation?.message}</p>
            </div>
            <div>
              <label htmlFor="getsDosageQuestions">
                Should recieve questions related to dosage and continuing the trial?
              </label>
              <div className="btn-addon btnGender">
                <button
                  type="button"
                  className={
                    getsDosageQuestions
                      ? "btnGender_selected"
                      : "btnGender_unselected"
                  }
                  onClick={doesGetDosageQuestions}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className={
                    !getsDosageQuestions
                      ? "btnGender_selected"
                      : "btnGender_unselected"
                  }
                  onClick={doesNotGetDosageQuestions}
                >
                  No
                </button>
              </div>
            </div>
          </form>
        </Modal> */}
      <div className="respondent_list">
        {patientId !== "" && respondentList.length > 0 && (
          <RespondentList
            patientId={patientId}
            respondentList={respondentList}
            removeRespondent={removeRespondent}
            editRespondent={editRespondent}
            resendInvite ={ResendInvite}
          />
        )}
      </div>
    </Fragment>
  );
}
export default AddRespondent;
