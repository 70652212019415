import React from "react";
import Heading from "../../../heading";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import "../../styles.css";
import { AddHomeCompositionPostModel } from "../../../../models/familyBackground/homeComposition/add-home-composition-post.model";
import { AddHomeCompositionModel } from "../../../../models/familyBackground/homeComposition/add-home-composition.model";
import { Loader } from "../../../../shared/loaders";
import { MemberType } from "../../../../shared/enums/member-type.enum";
interface IProps {
  homeCompositionList: AddHomeCompositionPostModel;
  onSubmit: Function;
  isSaving: boolean;
  patientId: string;
  assignModel: Function;
  previousTabChange: Function;
  deleteHomeComposition: Function;
}
export default function MedicalHomeCompositionForm(props: IProps) {
  let homeCompositionListModel = props.homeCompositionList;
  let initialModel = {
    name: "",
    timeInHouse: "",
    relationship: "",
  } as AddHomeCompositionModel;
  const { handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const formSubmit = () => {
    if (!checkForError()) {
      props.onSubmit({ homeCompositionListModel: homeCompositionListModel, isAutoSave: false });
    }
  };
  const checkForError = () => {
    let hasError = false;
    if (homeCompositionListModel.familyMemberComposition.length > 0) {
      homeCompositionListModel.familyMemberComposition.forEach((x) => {
        x.nameError = "";
        if (x.name == "") {
          x.nameError = "Please fill name.";
          hasError = true;
        }
        x.timeInHouseError = "";
        if (x.timeInHouse == "") {
          x.timeInHouseError = "Please fill time in home.";
          hasError = true;
        }
        x.relationshipError = "";
        if (x.relationship == "") {
          x.relationshipError = "Please fill relationship.";
          hasError = true;
        }
      });
    }
    if (homeCompositionListModel.nonFamilyMemberComposition.length > 0) {
      homeCompositionListModel.nonFamilyMemberComposition.forEach((x) => {
        x.nameError = "";
        if (x.name == "") {
          x.nameError = "Please fill name.";
          hasError = true;
        }
        x.timeInHouseError = "";
        if (x.timeInHouse == "") {
          x.timeInHouseError = "Please fill time in home.";
          hasError = true;
        }
        x.relationshipError = "";
        if (x.relationship == "") {
          x.relationshipError = "Please fill relationship.";
          hasError = true;
        }
      });
    }
    return hasError;
  };

  const handleKeyUp = (event, i, memberType) => {
    if (memberType == MemberType.Family) {
      if (
        event.key === "Tab" &&
        (homeCompositionListModel.familyMemberComposition[i].name ||
          homeCompositionListModel.familyMemberComposition[i].timeInHouse ||
          homeCompositionListModel.familyMemberComposition[i].relationship)
      ) {
        saveDataOnTabChange();
      }
    } else {
      if (
        event.key === "Tab" &&
        (homeCompositionListModel.nonFamilyMemberComposition[i].name ||
          homeCompositionListModel.nonFamilyMemberComposition[i].timeInHouse ||
          homeCompositionListModel.nonFamilyMemberComposition[i].relationship)
      ) {
        saveDataOnTabChange();
      }
    }
  };
  const handleOnFocus = (event, i) => {
    saveDataOnTabChange();
  };
  const saveDataOnTabChange = () => {
    if (!props.isSaving) {
      props.onSubmit({ homeCompositionListModel: homeCompositionListModel, isAutoSave: true });
    }
  };

  const handleRemoveClick = (index, memberType) => {
    if (memberType == MemberType.Family) {
      let idToDelete = homeCompositionListModel.familyMemberComposition[index].id;
      if (idToDelete != null) {
        props.deleteHomeComposition(idToDelete);
      }
      homeCompositionListModel.familyMemberComposition.splice(index, 1);
    } else {
      let idToDelete = homeCompositionListModel.nonFamilyMemberComposition[index].id;
      if (idToDelete != null) {
        props.deleteHomeComposition(idToDelete);
      }
      homeCompositionListModel.nonFamilyMemberComposition.splice(index, 1);
    }

    props.assignModel({ homeCompositionListModel: homeCompositionListModel });
  };
  const handleAddClick = (memberType) => {
    if (memberType == MemberType.Family) {
      initialModel.memberType = MemberType.Family;
      homeCompositionListModel.familyMemberComposition.push(initialModel);
    } else {
      initialModel.memberType = MemberType.NonFamily;
      homeCompositionListModel.nonFamilyMemberComposition.push(initialModel);
    }
    props.assignModel({ homeCompositionListModel: homeCompositionListModel });
  };

  const handleNameChange = (event, i, memberType) => {
    if (memberType == MemberType.Family) {
      homeCompositionListModel.familyMemberComposition[i].name = event.target.value;
    } else {
      homeCompositionListModel.nonFamilyMemberComposition[i].name = event.target.value;
    }

    props.assignModel({ homeCompositionListModel: homeCompositionListModel });
  };

  const handleTimeInHouseChange = (event, i, memberType) => {
    if (memberType == MemberType.Family) {
      homeCompositionListModel.familyMemberComposition[i].timeInHouse = event.target.value;
    } else {
      homeCompositionListModel.nonFamilyMemberComposition[i].timeInHouse = event.target.value;
    }

    props.assignModel({ homeCompositionListModel: homeCompositionListModel });
  };

  const handleRelationshipChange = (event, i, memberType) => {
    if (memberType == MemberType.Family) {
      homeCompositionListModel.familyMemberComposition[i].relationship = event.target.value;
    } else {
      homeCompositionListModel.nonFamilyMemberComposition[i].relationship = event.target.value;
    }

    props.assignModel({ homeCompositionListModel: homeCompositionListModel });
  };
  return (
    <div className="">
      <form onSubmit={handleSubmit(formSubmit)}>
        <div>
          <Heading heading="Home Composition" subHeading="" styleName="" />
          <p>
            List all other family members not listed is Sections A, B or C above (i.e, birth
            parents, primary caretakers, siblings) who reside in the primary home of the child.
            Include both adults and other children. Estimate the length of time they have lived in
            the home with the child.
          </p>
          
          <div className="form">
            {homeCompositionListModel != null &&
              homeCompositionListModel.familyMemberComposition != null &&
              homeCompositionListModel.familyMemberComposition.map((homeCompositions, index) => {
                return (
                  <div className="row" key={index}>
                    <div className="medical-group col-12 col-md-3">
                      <label className="form-label">Name</label>
                      <input
                        name={"name" + index}
                        className={
                          !homeCompositions.nameError ? "form-control mb-1" : "form-control mb-1  is-invalid"
                        }
                        value={homeCompositions.name}
                        placeholder="Name"
                        onChange={(e) => handleNameChange(e, index, MemberType.Family)}
                        onFocus={(e) => handleOnFocus(e, index)}
                      />
                      {homeCompositions.nameError && (
                        <div className="invalid-feedback" style={{ display: "block" }}>
                          {homeCompositions.nameError}
                        </div>
                      )}
                    </div>

                    <div className="medical-group col-12 col-md-3">
                      <label className="form-label">Time in Home</label>
                      <input
                        type="text"
                        className={
                          !homeCompositions.timeInHouseError
                            ? "form-control mb-1"
                            : "form-control mb-1  is-invalid"
                        }
                        value={homeCompositions.timeInHouse}
                        placeholder="Time in Home"
                        onChange={(e) => handleTimeInHouseChange(e, index, MemberType.Family)}
                        onFocus={(e) => handleOnFocus(e, index)}
                      />
                      {homeCompositions.timeInHouseError && (
                        <div className="invalid-feedback" style={{ display: "block" }}>
                          {homeCompositions.timeInHouseError}
                        </div>
                      )}
                    </div>
                    <div className="medical-group col-12 col-md-3">
                      <label className="form-label">Relationship</label>
                      <input
                        type="text"
                        name={"relationship" + index}
                        className={
                          !homeCompositions.relationshipError
                            ? "form-control mb-1"
                            : "form-control mb-1  is-invalid"
                        }
                        value={homeCompositions.relationship}
                        placeholder="Relationship"
                        onChange={(e) => handleRelationshipChange(e, index, MemberType.Family)}
                        onFocus={(e) => handleOnFocus(e, index)}
                      />
                      {homeCompositions.relationshipError && (
                        <div className="invalid-feedback" style={{ display: "block" }}>
                          {homeCompositions.relationshipError}
                        </div>
                      )}
                    </div>
                    <div className="medical-group col-12 col-md-3  align-self-center">
                      <i
                        className="far fa-trash-alt delete text-center"
                        onClick={() => handleRemoveClick(index, MemberType.Family)}
                      ></i>
                    </div>
                    <hr className="mt-3"/>
                  </div>
                );
              })}

            <div>
              <Button
                type="primary"
                size="large"
                onClick={() => handleAddClick(MemberType.Family)}
                block
                icon={<PlusOutlined />}
                className="addFamily__btn"
              >
                Add Family Members
              </Button>
            </div>
          </div>
          <div className="home__heading__wrapper">
            <Heading
              heading=""
              subHeading="List all non-family members who reside in the primary home of the child."
              styleName=""
            />
          </div>
          <div className="form">
            {homeCompositionListModel != null &&
              homeCompositionListModel.nonFamilyMemberComposition != null &&
              homeCompositionListModel.nonFamilyMemberComposition.map((homeCompositions, index) => {
                return (
                  <div className="row" key={index}>
                    <div className="medical-group col-12 col-md-3">
                      <label className="form-label">Name</label>
                      <input
                        name={"name" + index}
                        className={
                          !homeCompositions.nameError ? "form-control mb-1" : "form-control mb-1  is-invalid"
                        }
                        value={homeCompositions.name}
                        placeholder="Name"
                        onChange={(e) => handleNameChange(e, index, MemberType.NonFamily)}
                        onFocus={(e) => handleOnFocus(e, index)}
                      />
                      {homeCompositions.nameError && (
                        <div className="invalid-feedback" style={{ display: "block" }}>
                          {homeCompositions.nameError}
                        </div>
                      )}
                    </div>

                    <div className="medical-group col-12 col-md-3">
                      <label className="form-label">Time in Home</label>
                      <input
                        type="text"
                        className={
                          !homeCompositions.timeInHouseError
                            ? "form-control mb-1"
                            : "form-control mb-1  is-invalid"
                        }
                        value={homeCompositions.timeInHouse}
                        placeholder="Time in Home"
                        onChange={(e) => handleTimeInHouseChange(e, index, MemberType.NonFamily)}
                        onFocus={(e) => handleOnFocus(e, index)}
                      />
                      {homeCompositions.timeInHouseError && (
                        <div className="invalid-feedback" style={{ display: "block" }}>
                          {homeCompositions.timeInHouseError}
                        </div>
                      )}
                    </div>
                    <div className="medical-group col-12 col-md-3">
                      <label className="form-label">Relationship</label>
                      <input
                        type="text"
                        name={"relationship" + index}
                        className={
                          !homeCompositions.relationshipError
                            ? "form-control mb-1"
                            : "form-control mb-1  is-invalid"
                        }
                        value={homeCompositions.relationship}
                        placeholder="Relationship"
                        onChange={(e) => handleRelationshipChange(e, index, MemberType.NonFamily)}
                        onFocus={(e) => handleOnFocus(e, index)}
                      />
                      {homeCompositions.relationshipError && (
                        <div className="invalid-feedback" style={{ display: "block" }}>
                          {homeCompositions.relationshipError}
                        </div>
                      )}
                    </div>
                    <div className="medical-group col-12 col-md-3  align-self-center">
                      <i
                        className="far fa-trash-alt delete text-center"
                        onClick={() => handleRemoveClick(index, MemberType.NonFamily)}
                      ></i>
                    </div>
                    <hr className="mt-3"/>
                  </div>
                );
              })}
            <div>
              <Button
                type="primary"
                size="large"
                onClick={() => handleAddClick(MemberType.NonFamily)}
                block
                icon={<PlusOutlined />}
                className="addNonFamily__btn"
              >
                Add Non-Family Members
              </Button>
            </div>
          </div>
          <div className="btnContainer">
            {!props.isSaving && (
              <Button
                type="default"
                htmlType="submit"
                size="large"
                className="back_btn"
                onClick={() => props.previousTabChange(2)}
              >
                <i className="fa fa-long-arrow-left"></i> Back
              </Button>
            )}
            {!props.isSaving && (
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="btn_add"
              >
                Save <i className="fa fa-long-arrow-right"></i>
              </Button>
            )}
            {props.isSaving && (
              <div style={{ height: "60px" }}>
                <Loader
                  loading={props.isSaving}
                  marginBottom="0px"
                  marginTop="8px"
                  width="368px"
                ></Loader>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
