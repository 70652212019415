import { AppConfig, HttpWrapper } from "../../core";
import { AddChildTemperamentPostModel } from "../../models/developmentProfile/childTemperament/add-child-temperament-post.model";
import { DevelopmentalMilestonesPostModel } from "../../models/developmentProfile/developmental-milestones-post.model";
export class DevelopmentProfileService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }
  /* Developmental Milestones Start */
  postDevelopmentalMilestones(model: DevelopmentalMilestonesPostModel) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint +
        "developmentProfile/developmental-milestones/create",
      model
    );
  }
  getDevelopmentalMilestonesDetail(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint +
        "developmentProfile/developmental-milestones/detail/" +
        patientId
    );
  }
  /* Developmental Milestones End */
  
  /* Child Temperament Start */
   postChildTemperament(model: AddChildTemperamentPostModel) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint +
        "developmentProfile/child-temperament/create",
      model
    );
  }
  getChildTemperamentDetail(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint +
        "developmentProfile/child-temperament/detail/" +
        patientId
    );
  }
  getDevelopmentProfileStepStatus(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint +
        "developmentProfile/getDevelopmentProfileStepStatus/" +
        patientId
    );
  }
  /* Child Temperament End */
}
