//import moment from "moment";
import React, { Component, Fragment } from "react";
import { ClinicianWeeklyData } from "../../../../../models/clinicianWeeklyData/clinician-weekly-data.model";
import {
  AddendumData,
  downloadFinalReportModel,
  FinalReportSaveDataModel,
  severitySelectedData,
} from "../../../../../models/report/final-report-save-data";
import ChildGuardianInfo from "../../../../childGuardianInfo";
import ContentCard from "../../../../contentCard";
import Heading from "../../../../heading";
import { AddPatientMedicationModel } from "../../../../patient/models/add-patient-medication.model";
import { PatientDetailModel } from "../../../../patient/models/patient-detail.model";
import WeekwiseMedicationInfo from "../reportmedication";
import WeekwiseSideEffectInfo from "../reportSideEffects";
import Select from "react-select";
import { Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import { PatientService } from "../../../../patient/services";
import { AppConfig, HttpResponse, HttpWrapper } from "../../../../../core";
import { ClinicianWeeklyDataService } from "../../../../../services/clinicianWeeklyData/clinician-weekly-data.service";
import { PatientMedicationService } from "../../../../patient/services/patient.medication.service";
import { FinalReportDataService } from "../../../../../services/report/report-data-service";
import { PatientMedicationDetail } from "../../../../patient/models/patient-medication-detail";
import "./styles.css";
import pdf from "../../../../../assets/images/svg-icons/pdf.svg";
import { ShowSuccessMessage } from "../../../../../shared/helpers";
import { join } from "path";
import { FileDownloadService } from "../../../../../shared/FileDownloadService/file.download.service";
import TextArea from "antd/lib/input/TextArea";
import { Loader } from "../../../../../shared/loaders";
import Chart from "react-google-charts";
import { saveAs } from 'file-saver'
import logo from "../../../../../assets/images/CCFLogoFullColor.png"

import {
  CartesianGrid,
  LabelList,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import FinalReportChart from "./FinalReportChart/FinalReportChart";
import { asBlob } from "html-docx-js-typescript";
import dayjs from "dayjs";


interface IState {
  patientId: string;
  patientDetail: PatientDetailModel;
  isReadOnly: boolean;
  clinicianWeeklyData: ClinicianWeeklyData;
  medication: AddPatientMedicationModel;
  reportSavedData: FinalReportSaveDataModel;
  isShowModel: boolean;
  selectedOption: severitySelectedData[];
  selectedMedicationOptions: severitySelectedData;
  selectedParticipants: severitySelectedData[];
  selectedWeeksMissed: severitySelectedData[];
  isLoading: boolean;
  medicationOptions: severitySelectedData[];
  isDownloading: boolean;
  notes: string;
  isFormVisible: boolean;
  addendum: string;
  chartWrapper:any;
  chartData:number;
  addendums:AddendumData[];
  isAddendumError:boolean;
  handleClickLink?: Function;
}
const options = [
  { value: "0", label: "Non significant" },
  { value: "1", label: "No" },
  { value: "2", label: "Tolerable" },
  { value: "3", label: "Significant" },
];
const participantsOptions = [
  { value: "0", label: "Primary caregiver" },
  { value: "1", label: "Mother" },
  { value: "2", label: "Father" },
  { value: "3", label: "Patient" },
  { value: "4", label: "Sibling" },
  { value: "5", label: "Grand Parent" },
];
const weekOptions = [
  { value: "Week1", label: "Week1" },
  { value: "Week2", label: "Week2" },
  { value: "Week3", label: "Week3" },
  { value: "Week4", label: "Week4" },
  { value: "Week5", label: "Week5" },
];
const rows = [{
  name: "",
  uv: null, pv: null
}];
class FinalReportComponent extends Component<any, IState> {
  private patientService: PatientService;
  private clinicianWeeklyDataService: ClinicianWeeklyDataService;
  private patientMedicationService: PatientMedicationService;
  private finalReportDataService: FinalReportDataService;
  private wrapper: HttpWrapper;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.patientService = new PatientService();
    this.clinicianWeeklyDataService = new ClinicianWeeklyDataService();
    this.patientMedicationService = new PatientMedicationService();
    this.finalReportDataService = new FinalReportDataService();
    this.wrapper = new HttpWrapper();
  }

  private getInitialState() {
    let initialState: IState = {
      patientId: this.props.patientId,
      patientDetail: {} as PatientDetailModel,
      isReadOnly: false,
      clinicianWeeklyData: {} as ClinicianWeeklyData,
      medication: {} as AddPatientMedicationModel,
      reportSavedData: {} as FinalReportSaveDataModel,
      isShowModel: false,
      selectedOption: [] as severitySelectedData[],
      isLoading: true,
      selectedMedicationOptions: {} as severitySelectedData,
      medicationOptions: [] as severitySelectedData[],
      selectedParticipants: [] as severitySelectedData[],
      selectedWeeksMissed: [] as severitySelectedData[],
      isDownloading: false,
      notes: "",
      isFormVisible: false,
      addendum: "",
      chartWrapper:null,
      chartData:0,
      addendums:[],
      isAddendumError:false,
    };
    return initialState;
  }

  print = () => {
    if (this.state.chartWrapper === null) { console.error("ChartWrapper not ready yet"); }
      const image = this.state.chartWrapper.getChart().getImageURI();
    }
  

  handleSeverityChange = (selectedOption) => {
    this.setState({ selectedOption: selectedOption });
  };
  handleMedicationChange = (selectedOption) => {
    this.setState({ selectedMedicationOptions: selectedOption });
  };
  handleParticipantsChange = (selectedOption) => {
    this.setState({ selectedParticipants: selectedOption });
  };
  handleWeeksMissedChange = (selectedOption) => {
    this.setState({ selectedWeeksMissed: selectedOption });
  };
  handlenotesChange = (data) => {
    this.setState({ notes: data });
  };

  handleAddendumChange = (tata) => {
    this.setState({ addendum: tata.target.value });
  };

  componentDidMount = () => {
    if (this.props.patientId != null) {
      this.loadPatient();
      this.loadClinitianData();
      this.loadMedicationData();
      this.loadPatientFinalReport();
    }
  };
  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }
  loadPatientFinalReport() {
    if (this.state.patientId != undefined) {
      this.finalReportDataService
        .getFinalReportData(this.state.patientId)
        .then((res: HttpResponse<FinalReportSaveDataModel>) => {
          if (res && res.result) {
            
            this.setState({
              reportSavedData: res.result,
            });
            this.setState({ selectedOption: res.result?.severity });
            this.setState({ selectedMedicationOptions: res.result?.medication });
            this.setState({ selectedParticipants: res.result?.participants });
            this.setState({ selectedWeeksMissed: res.result?.weeksMissed });
            this.setState({ notes: res.result?.notes });
            this.setState({addendums:res.result?.addendums})
          }
        })
        .catch(() => {
          this.setLoading(false);
        });
    }
  }
  loadMedicationData() {
    if (this.state.patientId != undefined) {
      this.patientMedicationService
        .getmedicationByPatientId(this.state.patientId)
        .then((res: HttpResponse<PatientMedicationDetail>) => {
          if (res && res.result) {
            let medication: AddPatientMedicationModel = {
              isActive: res.result.isActive,
              patientId: res.result.patientId,
              patientTrialId: res.result.patientTrialId,
              trialNoOfWeeks: res.result.trialNoOfWeeks,
              trialStartDate: res.result.trialStartDate,
              trialEndDate: res.result.trialEndDate,
              createPatientVisitModels: res.result.patientVisitDetails,
              createPatientTrialWeekModels: res.result.patientTrialWeekDetails,
              diagnosisCodes: res.result.patientTrialDiagnosisCodes,
              patientTrialStyle: res.result.patientTrialStyle,
            };
            let medOptions = [] as severitySelectedData[];
            medication.createPatientTrialWeekModels.map((c) => {
              let value = "";
              if (c.createPatientTrailWeeklyMedicationModel?.medicationFamily != "" 
              && c.createPatientTrailWeeklyMedicationModel?.medicationFamily != "Placebo") {
                if (c.createPatientTrailWeeklyMedicationModel.medicationFamily == "Placebo") {
                  value = c.createPatientTrailWeeklyMedicationModel.medicationFamily;
                } else {
                  if(c.createPatientTrailWeeklyMedicationModel.medicationType=="Generic")
                  {
                    value =
                    c.createPatientTrailWeeklyMedicationModel.medicationFamily +
                    " (" +
                    c.createPatientTrailWeeklyMedicationModel.medicationGenericName +
                    " - "+ c.createPatientTrailWeeklyMedicationModel.medicationDosage +
                    ")";
                  }
                  else{
                    value =
                    c.createPatientTrailWeeklyMedicationModel.medicationFamily +
                    " (" +
                    c.createPatientTrailWeeklyMedicationModel.medicationBrandName +
                    " - "+ c.createPatientTrailWeeklyMedicationModel.medicationDosage +
                    ")";
                  }
                  
                }
                if (!medOptions.some((c) => c.label == value)) {
                  medOptions.push({
                    value: value,
                    label: value,
                  });
                }
              }
            });
            medOptions.push({
              value: "Medication Failure",
              label: "no benefit was observed"
            });
            this.setState({ medicationOptions: medOptions });
            this.setState({
              medication: medication,
            });
          }
        })
        .catch((ex) => {
          this.setLoading(false);
        });
    }
  }
  loadClinitianData = () => {
    this.setLoading(true);
    if (this.state.patientId != undefined) {
      this.clinicianWeeklyDataService
        .getClinicianWeeklyData(this.state.patientId)
        .then((res: HttpResponse<ClinicianWeeklyData>) => {
          if (res && res.result && res.result.lstClinicianSideEffectsWeeklyData != null) {
            
            let condolidateTS : any[]=[];
            let condolidateSE : any[]=[];
            res.result.lstClinicianTargetSymptomsWeeklyData.forEach(ts=>{
              let tsScore=0;
              tsScore+=ts.overActivity||0;
              tsScore+=ts.impulsiveness||0;
              tsScore+=ts.distractability||0;
              tsScore+=ts.taskCompletion||0;
              tsScore+=ts.beingOnTime||0;
              tsScore+=ts.followingInstructions||0;
              tsScore+=ts.frustrationTolerance||0;
              tsScore+=ts.abilitytoCalmSelfWhenExcited||0;
              tsScore+=ts.peerRelations||0;
              tsScore+=ts.adultsRelations||0;
              tsScore+=ts.targetOneAlias||0;
              
              if(ts.isBaseLine){

                condolidateTS.push({ WeekName: "Baseline",TsValue: tsScore});
              }
              else{
                condolidateTS.push({ WeekName: "Week" + ts.weekNo,TsValue: tsScore});
              }

            });
            res.result.lstClinicianSideEffectsWeeklyData.forEach(ts=>{
              let tsScore=0;
              tsScore+=ts.insomniaOrTroubleSleeping||0;
              tsScore+=ts.nightmares||0;
              tsScore+=ts.staresALotOrDayDreams||0;
              tsScore+=ts.talkLessWithOthers||0;
              tsScore+=ts.unInterestedWithOthers||0;
              tsScore+=ts.decreasedAppetite||0;
              tsScore+=ts.irritable||0;
              tsScore+=ts.stomachaches||0;
              tsScore+=ts.headaches||0;
              tsScore+=ts.drowsiness||0;
              tsScore+=ts.sadOrUnhappy||0;
              tsScore+=ts.pronetoCryingOrEasilyUpset||0;
              tsScore+=ts.anxiuosOrWorried||0;
              tsScore+=ts.perseveration||0;
              tsScore+=ts.fingernails||0;
              tsScore+=ts.euphoricOrUnusuallyHappyOrMania||0;
              tsScore+=ts.dizziness||0;
              tsScore+=ts.ticsOrNervousMovement||0;
              tsScore+=ts.overfocused||0;
              tsScore+=ts.reboundEffects||0;
              tsScore+=ts.hallucinosis||0;
              tsScore+=ts.flatAffectOrEmotionalBlunting||0;
              tsScore+=ts.dryMouth||0;
              tsScore+=ts.numbnessOrTinglingInExtremities||0;

              if(ts.isBaseLine){

                condolidateSE.push({ WeekName: "Baseline",SEValue: tsScore});
              }
              else{
                condolidateSE.push({ WeekName: "Week" + ts.weekNo,SEValue: tsScore});
              }

            });
            var maxVal=Math.max(...condolidateSE.map(c=>c.SEValue));
            var minval=Math.min(...condolidateSE.map(c=>c.SEValue));;
            if(Math.max(...condolidateSE.map(c=>c.SEValue))>Math.max(...condolidateTS.map(c=>c.TsValue))){
              maxVal = Math.max(...condolidateSE.map(c=>c.SEValue));
            }
            else
            {
              maxVal = Math.max(...condolidateTS.map(c=>c.TsValue));
            }
            if(Math.min(...condolidateSE.map(c=>c.SEValue))<Math.min(...condolidateTS.map(c=>c.TsValue))){
              minval = Math.min(...condolidateSE.map(c=>c.SEValue));
            }
            else
            {
              minval = Math.min(...condolidateTS.map(c=>c.TsValue));
            }
            let emptyData={name: "0", uv: null, pv: null}
            rows.length=0;
            rows.push(emptyData);
            condolidateSE.forEach(x=>{
              var ctsvalue=condolidateTS.filter(cts=>cts.WeekName==x.WeekName)[0].TsValue;
              let d = {
                name: x.WeekName,
                uv: ctsvalue, pv: x.SEValue
              };
              
                rows.push(d);
              
            });
            rows.push(emptyData);
            
            this.setState({

              chartData:maxVal+5,
              clinicianWeeklyData: res.result,
            });
            this.setState({ isFormVisible: true });
          } else {
            this.setState({ isFormVisible: false });
          }
          this.setLoading(false);
        })
        .catch(() => {
          this.setLoading(false);
        });
    }
  };

  loadPatient() {
    if (this.state.patientId != undefined) {
      this.patientService
        .getUserById(this.state.patientId)
        .then((res: HttpResponse<PatientDetailModel>) => {
          if (res && res.result) {
            
            this.setState({
              patientDetail: res.result,
            });
          }
        })
        .catch(() => {
          this.setLoading(false);
        });
    }
  }

  downloadReport = (image,format:number) => {
    this.setState({ isDownloading: true });
    let downloadModel: downloadFinalReportModel = {
      addendum: this.state.addendum,
      patientId: this.state.patientId,
      file:image,
      fileApiUrl:AppConfig.identityUrl+"ReportImage/"+this.state.patientId+".png"
    };
    if(format==1){
    

    this.wrapper
      .getFileByPost(AppConfig.apiEndpoint + "report/finalreport/download", downloadModel)
      .then((res) => {
          this.downloadFileByStream(res, "FinalReport_" + this.state.patientDetail.lastName + "_" + this.state.patientDetail.firstName+".pdf");
        this.setState({ isDownloading: false });
      });
    }
    else
    {
      
      this.finalReportDataService
      .getFinalReportHtml(downloadModel)
      .then((res: HttpResponse<string>) => {
        if (res && res.result) {
          
          this.setState({ isDownloading: false });
          asBlob(res.result,{margins: {top: 720,bottom:720,left:720,right:720}}).then(data => {
              saveAs(data, "FinalReport_" + this.state.patientDetail.lastName + "_" + this.state.patientDetail.firstName+".docx") // save as docx file
          }) 
        }
      })
      .catch(() => {
        
      });
    }
  };
  downloadFileByStream(result: any, filename: string): void {
    var file = new Blob([result], {
      type: "application/octet-binary",
    });
    var fileUrl = URL.createObjectURL(file);
    // for IE 10+
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, filename);
    } else {
      var element = document.createElement("a");
      element.href = fileUrl;
      element.setAttribute("download", filename);
      element.setAttribute("target", "_blank");
      document.body.appendChild(element); //Append the element to work in firefox
      element.click();
    }
  }
  saveDraftClick = () => {
    this.state.reportSavedData.isSaved = false;
      if (this.state.selectedOption != null && this.state.selectedOption.length > 0) {
          this.state.reportSavedData.severity = this.state.selectedOption;
      }
      else {
          this.state.reportSavedData.severity = [];
      }

    this.state.reportSavedData.medication = this.state.selectedMedicationOptions;

      if (this.state.selectedParticipants != null && this.state.selectedParticipants.length > 0) {
          this.state.reportSavedData.participants = this.state.selectedParticipants;
      }
      else {
          this.state.reportSavedData.participants = [];
      }
      if (this.state.selectedWeeksMissed != null && this.state.selectedWeeksMissed.length > 0) {
        this.state.reportSavedData.weeksMissed = this.state.selectedWeeksMissed;
    }
    else {
        this.state.reportSavedData.weeksMissed = [];
    }
    this.state.reportSavedData.notes = this.state.notes;
    this.state.reportSavedData.pateintId = this.state.patientId;
    //props.onSaveClick(props.reportSavedData)
    this.postReportData(this.state.reportSavedData);
  };
  cancelAddendumClick = ()=> {
    this.setState({       
      addendum:""
     });
  }
  addAddendumClick = ()=> {
    
    if(this.state.addendum==""){
      this.setState({isAddendumError:true});
    }
    else{
      this.setState({isAddendumError:false});
    
    var addendumData: AddendumData = {
      addendum : this.state.addendum,
      pateintId : this.state.patientId,
      addedOn : null
    };
    this.finalReportDataService
      .postFinalReportAddendumData(addendumData)
      .then((res: HttpResponse<AddendumData>) => {
        if (res && res.result) {
           this.state.addendums.unshift(res.result);
          // this.setState({ isShowModel: false });
          this.setState({ 
            addendums: this.state.addendums,
            addendum:""
           });
          ShowSuccessMessage("Data Saved Successfully.");
        }
      })
      .catch(() => {
        this.setLoading(false);
      });
    }
  }
  postReportData = (reportsaveModel) => {
    reportsaveModel.pateintId = this.state.patientId;
    this.finalReportDataService
      .postFinalReportData(reportsaveModel)
      .then((res: HttpResponse<string>) => {
        if (res && res.result) {
          this.setState({ isShowModel: false });
          this.setState({ reportSavedData: reportsaveModel });
          if(!reportsaveModel.isSaved){
          ShowSuccessMessage("Data Saved Successfully.");
          }
        }
      })
      .catch(() => {
        this.setLoading(false);
      });
  };

  saveClick = () => {
    this.setState({ isShowModel: true });
  };
  cancelSubmitForm = () => {
    this.setState({ isShowModel: false });
  };
  submitForm = () => {
    this.state.reportSavedData.isSaved = true;
    if (this.state.selectedOption.length > 0) {
      this.state.reportSavedData.severity = this.state.selectedOption;
    }

    this.state.reportSavedData.medication = this.state.selectedMedicationOptions;

    if (this.state.selectedParticipants.length > 0) {
      this.state.reportSavedData.participants = this.state.selectedParticipants;
    }
    if (this.state.selectedWeeksMissed.length > 0) {
      this.state.reportSavedData.weeksMissed = this.state.selectedWeeksMissed;
    }
    this.state.reportSavedData.notes = this.state.notes;
    this.state.reportSavedData.pateintId = this.state.patientId;
    this.postReportData(this.state.reportSavedData);
  };

  getStatusStyle = (value?: number) => {
    let statusStyle = "";
    if (value != null) {
      if (value == 5) {
        statusStyle = "weekModerate";
      } else if (value > 5) {
        statusStyle = "finalServr__orange";
      }
    }
    return statusStyle;
  };
  getBorderStyle = (value?: number) => {
    let borderStyle = "final__cgiSevr";
    if (value != null) {
      if (value == 5) {
        borderStyle = "final__cgiSevr finalServr__orange";
      } else if (value == 6) {
        borderStyle = "final__cgiSevr finalServr__extreme";
      } else if (value == 7) {
        borderStyle = "final__cgiSevr finalServr__extreme";
      }
    }
    return borderStyle;
  };
  weekDotStyle = (value?: number) => {
    let dotStyle = "dot";
    if (value != null) {
      if (value == 5) {
        dotStyle = "dot weekOrange";
      } else if (value == 6) {
        dotStyle = "dot weekExtremeDot";
      } else if (value == 7) {
        dotStyle = "dot weekExtremeDot";
      }
    }
    return dotStyle;
  };
  getSeverityText = (value?: number) => {
    let severityText = "";
    switch (value) {
      case 1:
        severityText = "Normal; Not at all 1";
        break;
      case 2:
        severityText = "Borderline Problem 2";
        break;
      case 4:
        severityText = "Moderate Problem 4";
        break;
      case 3:
        severityText = "Mild Problem 3";
        break;
      case 5:
        severityText = "Marked Problem 5";
        break;
      case 6:
        severityText = "Severe Problem 6";
        break;
      case 7:
        severityText = "Most Extreme Problem 7";
        break;
      default:
        severityText = "";
        break;
    }
    return severityText;
  };
  getImprovementText = (value?: number) => {
    let improvementText = "";
    switch (value) {
      case 1:
        improvementText = "Very Much Improved (1)";
        break;
      case 2:
        improvementText = "Improved (2)";
        break;
      case 3:
        improvementText = "Minimally Improved (3)";
        break;
      case 4:
        improvementText = "Unchanged (4)";
        break;
      case 5:
        improvementText = "Minimally Worse (5)";
        break;
      case 6:
        improvementText = "Worse (6)";
        break;
      case 7:
        improvementText = "Very Much Worse (7)";
        break;
      default:
        improvementText = "";
        break;
    }
    return improvementText;
  };
  handleClickLink = () => {
    this.props.handleClickLink(this.state.patientId);
}
  render() {
    return (
      <div>
        {!this.state.isLoading && (
          <div className="text-center">
            {!this.state.isFormVisible && (
              <h4 className="weekly__msg">
                You will see the final report for this patient only after the medication information
                is added for him.
                <br />
                            Click on <a className="link-text" onClick={() => this.handleClickLink()} href={void (0)}><u>link</u></a> to complete the medication information.
              </h4>
            )}
          </div>
        )}
        {!this.state.isLoading && this.state.isFormVisible && (
          <div id="scrollWrap" className="finalReport__container">
            <div className="finalReport">
              <div className="final__header">
                <div className="final__logo">
                <img width="200" src={logo} alt="logo" />
                </div>
                <div className="finalReport__name">
                  <Heading heading="Final Report" />
                </div>

                <div className="final__rghtContent">
                  <p className="final__ADHD">ADHD center for Evaluation & Treatment (ACET)</p>
                  <Heading
                    heading="Medication Trial Report For:"
                    subHeading={
                      this.state.patientDetail.lastName + ", " + this.state.patientDetail.firstName
                    }
                    styleName="viewMed__baseDesc"
                    ccfSubHeading="viewMed__subBaseDesc"
                  />
                  <Heading
                    heading="Diagnosis:"
                    subHeading={this.state.medication.diagnosisCodes
                      ?.map((c) => c.value)
                      .join(" | ")}
                    styleName="viewMed__baseDesc"
                    ccfSubHeading="viewMed__subBaseDesc"
                  />
                  <Heading
                    heading="MRN:"
                    subHeading={this.state.patientDetail.mr_No}
                    styleName="viewMed__baseDesc"
                    ccfSubHeading="viewMed__subBaseDesc"
                  />
                  <Heading
                    heading="Date:"
                    subHeading=
                    {
                      this.state.reportSavedData.savedOn==null?
                      dayjs().format("MM/DD/YYYY"):dayjs(this.state.reportSavedData.savedOn).format("MM/DD/YYYY")
                    }
                    styleName="viewMed__baseDesc"
                    ccfSubHeading="viewMed__subBaseDesc"
                  />
                </div>
              </div>
              <p className="final__medTrial">
                This report outlines data from the medication trial conducted for{" "}
                {this.state.patientDetail.lastName}, {this.state.patientDetail.firstName}. Parents
                and physicians may use this report to guide ongoing medication treatment. Should you need further information, <strong>contact the clinical staff at 216-448-6310</strong>.
              </p>
              <div className="finalReport__basicInfo">
                <div>
                  <Heading
                    heading="Parent's Name:"
                    subHeading={
                      this.state.patientDetail.guardianFirstName +
                      " " +
                      this.state.patientDetail.guardianLastName
                    }
                    styleName="viewMed__baseDesc"
                    ccfSubHeading="viewMed__subBaseDesc"
                  />
                  <Heading
                    heading="School:"
                    subHeading={
                      this.state.patientDetail.schoolName == null
                        ? " - "
                        : this.state.patientDetail.schoolName
                    }
                    styleName="viewMed__baseDesc"
                    ccfSubHeading="viewMed__subBaseDesc"
                  />
                </div>
                <div>
                  <Heading
                    heading="Physician Name:"
                    subHeading={
                      (this.state.patientDetail.physicianFirstName == null
                        ? " - "
                        : this.state.patientDetail.physicianFirstName) +
                      " " +
                      (this.state.patientDetail.physicianLastName == null
                        ? " "
                        : this.state.patientDetail.physicianLastName)
                    }
                    styleName="viewMed__baseDesc"
                    ccfSubHeading="viewMed__subBaseDesc"
                  />

                  <Heading
                    heading="Monitoring Physician:"
                    subHeading={
                      (this.state.patientDetail.monitoringPhysicianFirstName == null
                        ? " - "
                        : this.state.patientDetail.monitoringPhysicianFirstName) +
                      " " +
                      (this.state.patientDetail.monitoringPhysicianLastName == null
                        ? ""
                        : this.state.patientDetail.monitoringPhysicianLastName)
                    }
                    styleName="viewMed__baseDesc"
                    ccfSubHeading="viewMed__subBaseDesc"
                  />
                </div>
                <div>
                  <Heading
                    heading="Supervised By:"
                    subHeading="Michael Manos, Ph.D."
                    styleName="viewMed__baseDesc"
                    ccfSubHeading="viewMed__subBaseDesc"
                  />
                </div>

                <div className="finalReport__participants">
                  <Heading
                    heading="Participants:"
                    subHeading={
                      !this.state.reportSavedData.isSaved
                        ? ""
                        : this.state.selectedParticipants?.map((c) => c.label + " ").join("| ")
                    }
                    styleName="viewMed__baseDesc"
                    ccfSubHeading="viewMed__subBaseDesc"
                  />
                  {!this.state.reportSavedData.isSaved && (
                    <div className="medical-group multi-roles">
                      <Select
                        className={`reactSelect multi-roles ${
                          this.state.isReadOnly ? "role-disable" : ""
                        }`}
                        // name="finalSevr"
                        placeholder="Select Participants"
                        value={this.state.selectedParticipants || []}
                        onChange={this.handleParticipantsChange}
                        isMulti
                        options={participantsOptions}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="finalReport__basicInfo">
                <div>
                  <Heading
                    heading="Child's birthdate:"
                    subHeading={dayjs(this.state.patientDetail.dateOfBirth).format("MM/DD/YYYY")}
                    styleName="viewMed__baseDesc"
                    ccfSubHeading="viewMed__subBaseDesc"
                  />
                </div>
                <div>
                  <Heading
                    heading="Clinic Visits:"
                    subHeading={this.state.clinicianWeeklyData?.lstPatientVisitDetails
                      ?.filter((u) => u.visitDate != null)
                      .map((v) => dayjs(v.visitDate).format("MM/DD/YYYY"))
                      .join(" | ")}
                    styleName="viewMed__baseDesc"
                    ccfSubHeading="viewMed__subBaseDesc"
                  />
                </div>
                <div>
                  <Heading
                    heading="Placebo:"
                    subHeading=
                    {
                      this.state.medication.createPatientTrialWeekModels?.some(c=>c.createPatientTrailWeeklyMedicationModel.medicationFamily=="Placebo")
                      ?"Yes - "+ this.state.medication.createPatientTrialWeekModels.filter(c=>c.createPatientTrailWeeklyMedicationModel.medicationFamily=="Placebo")
                      .map((v) => dayjs(v.startDate).format("MM/DD/YYYY"))
                      .join(" | "):"No"}
                    styleName="viewMed__baseDesc"
                    ccfSubHeading="viewMed__subBaseDesc"
                  />
                </div>
              </div>
              <Heading subHeading="REPEATED MEASURES:" ccfSubHeading="finalReport__subHead" />
              <div className="finalReport__basicInfo">
                <div>
                  <Heading
                    heading="Broad Band:"
                    subHeading="CGI-P/T"
                    styleName="viewMed__baseDesc"
                    ccfSubHeading="viewMed__subBaseDesc"
                  />
                </div>
                <div>
                  <Heading
                    heading="Narrow Band:"
                    subHeading="ARS IV, SE-BMS"
                    styleName="viewMed__baseDesc"
                    ccfSubHeading="viewMed__subBaseDesc"
                  />
                </div>
              </div>
              <Heading subHeading="MEDICATION" ccfSubHeading="finalReport__subHead" />
              <div className="medication-box">
                  {this.state.medication.createPatientTrialWeekModels &&
                    this.state.medication.createPatientTrialWeekModels.map((row, i) => (
                      <WeekwiseMedicationInfo
                        weekCount={row.weekNo}
                        weekDate={row.startDate}
                        medFamily={row.createPatientTrailWeeklyMedicationModel.medicationFamily}
                        brandName={row.createPatientTrailWeeklyMedicationModel.medicationBrandName}
                        dosage={row.createPatientTrailWeeklyMedicationModel.medicationDosage}
                        format={row.createPatientTrailWeeklyMedicationModel.medicationFormat}
                        genericName={
                          row.createPatientTrailWeeklyMedicationModel.medicationGenericName
                        }
                      ></WeekwiseMedicationInfo>
                    ))}
                <hr />
              </div>
              <div className="finalReport__participants col-6">
                  <Heading
                    heading="Weeks Missed:"
                    subHeading={
                      !this.state.reportSavedData.isSaved
                        ? ""
                        : this.state.selectedWeeksMissed?.map((c) => c.label + " ").join("| ")
                    }
                    styleName="viewMed__baseDesc"
                    ccfSubHeading="viewMed__subBaseDesc"
                  />
                  {!this.state.reportSavedData.isSaved && (
                    <div className="medical-group multi-roles">
                      <Select
                        className={`reactSelect multi-roles ${
                          this.state.isReadOnly ? "role-disable" : ""
                        }`}
                        // name="finalSevr"
                        placeholder="Select Any Weeks Missed"
                        value={this.state.selectedWeeksMissed || []}
                        onChange={this.handleWeeksMissedChange}
                        isMulti
                        options={weekOptions}
                      />
                    </div>
                  )}
                </div>
                <hr />
              <Heading subHeading="TOTAL COMPOSITE CGI-S SCORES FOR SIDE EFFECTS AND TARGET SYMPTOMS" ccfSubHeading="finalReport__subHead" />
              <div>
              <FinalReportChart 
              data={rows}
              chartData={this.state.chartData}              
              addendum={this.state.addendum}
              clinicianWeeklyData={this.state.clinicianWeeklyData}
              medication={this.state.medication}
              reportSavedData={this.state.reportSavedData}
              isShowModel={this.state.isShowModel}
              selectedOption={this.state.selectedOption}
              selectedMedicationOptions={this.state.selectedMedicationOptions}              
              isLoading={this.state.isLoading}
              medicationOptions={this.state.medicationOptions}
              isDownloading={this.state.isDownloading}
              notes={this.state.notes}
              isFormVisible={this.state.isFormVisible}
              patientDetail={this.state.patientDetail}
              isReadOnly={this.state.isReadOnly}
              handleMedicationChange={this.handleMedicationChange}
              handleSeverityChange={this.handleSeverityChange}
              handlenotesChange={this.handlenotesChange}
              handleAddendumChange={this.handleAddendumChange}
              downloadReport={this.downloadReport}
              saveDraftClick={this.saveDraftClick}
              saveClick={this.saveClick}
              addAddendumClick={this.addAddendumClick}
              addendumList={this.state.addendums}
              cancelAddendumClick={this.cancelAddendumClick}
              isAddendumError={this.state.isAddendumError}
              weeksMissed={this.state.selectedWeeksMissed}
              ></FinalReportChart>
    
              </div>
              
              
              
              
            </div>
            <Modal
              title=""
              open={this.state.isShowModel}
              onOk={this.submitForm}
              onCancel={this.cancelSubmitForm}
              okText="Yes"
              cancelText="No"
              closable={false}
              maskClosable={false}
            >
              {" "}
              <p>
              Are you ready to submit the Final Report? You will not be allowed to make
                any changes after submitting the Final Report.
              </p>
            </Modal>
          </div>
        )}
        {this.state.isLoading && <Loader loading={this.state.isLoading} />}
      </div>
    );
  }
}

export default FinalReportComponent;
