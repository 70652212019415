import { Link } from "react-router-dom";
import React, { Fragment, useContext } from "react";
import { GlobalContext } from "../../shared/globalContext/globalContextProvider";
import "./index.css";

export const Breadcrumb = () => {
  const headerContext = useContext(GlobalContext);
  return (
    <Fragment>
      <div className="breadcrum__heading">
        {headerContext?.crumIcon != "" && (
          <Fragment>
          <img src={headerContext?.crumIcon} alt="user-img" />
          <span>&nbsp;</span>
          </Fragment>
        )}
        {headerContext?.crumType == "link" && (
          <Fragment>
            
            <Link to="/clinician/patientmanagement">
              {headerContext?.crumItem1}
            </Link>
          </Fragment>
        )}
        {headerContext?.crumType == "static" && headerContext?.crumItem1 != "" && (
          <Fragment>
            <span>{headerContext?.crumItem1}</span>
          </Fragment>
        )}
        {headerContext?.crumItem2 != "" && (
          <Fragment>
            <span> {" " + ">" + " "} </span>
            <span>{headerContext?.crumItem2}</span>
          </Fragment>
        )}
        {headerContext?.crumItem3 != "" && <span> {" " + ">" + " "} </span>}
        {headerContext?.crumItem3}
      </div>
    </Fragment>
  );
};
