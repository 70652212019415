import React, { useState } from "react";
import Heading from "../../../heading";
import { Button } from "antd";
import "../../styles.css";
import { useForm } from "react-hook-form";
import { AddDevelopmentalMilestonesModel } from "../../../../models/developmentProfile/add-developmental-milestones.model";
import { Loader } from "../../../../shared/loaders";
interface IProps {
  developmentalMilestonesList: AddDevelopmentalMilestonesModel[];
  onSubmit: Function;
  isSaving: boolean;
  patientId: string;
  assignModel: Function;
  previousTabChange: Function;
  isBirthHistoryOptional: boolean;
}
function DevelopmentalMilestonesForm(props: IProps) {
  let developmentalMilestonesModel = props.developmentalMilestonesList;
  const { register, handleSubmit, control, getValues, formState, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
  });
  const handleOnFocus = (event, i) => {
    setValuesInModel();
    saveDataOnTabChange();
  };

  const saveDataOnTabChange = () => {
    if (!props.isSaving) {
      props.onSubmit({
        developmentalMilestonesList: developmentalMilestonesModel,
        isAutoSave: true,
      });
    }
  };

  function setValuesInModel() {
    let model = getValues();
    developmentalMilestonesModel = Object.assign(developmentalMilestonesModel, model);
  }
  const formSubmit = (data) => {
    if (!checkForError()) {
      props.onSubmit({
        developmentalMilestonesList: developmentalMilestonesModel,
        isAutoSave: false,
      });
    }
    props.assignModel({
      developmentalMilestonesList: developmentalMilestonesModel,
    });
  };

  const checkForError = () => {
    if (props.isBirthHistoryOptional) {
      return false;
    }
    if (developmentalMilestonesModel.length > 0 ) {
      let hasError = false;
      developmentalMilestonesModel.forEach((x) => {
        x.yearError = "";
        if (x.years == null || x.years == "") {
          x.yearError = "Please fill years.";
          hasError = true;
        }
      });
      return hasError;
    }
  };

  const handleYearChange = (event, index) => {
    developmentalMilestonesModel[index].years = event.target.value;
    props.assignModel({
      developmentalMilestonesList: developmentalMilestonesModel,
    });
  };

  const handleMonthChange = (event, index) => {
    developmentalMilestonesModel[index].months = event.target.value;
    props.assignModel({
      developmentalMilestonesList: developmentalMilestonesModel,
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(formSubmit)}>
        <div>
          <Heading
            heading="Developmental Milestones"
            subHeading="Indicate the approximate age at which the child first did each of the following. Please be as accurate as possible."
            styleName=""
          />
          <div className="form">
            <div className="row">
              {developmentalMilestonesModel.map((item, index) => {
                return (
                  <div key={index} className="col-12 col-md-6">
                    <p className="devMilestone__para">{item.name}</p>
                    <div className="row">
                      <div className="medical-group col-6">
                        <label className="form-label">Years</label>
                        <input
                          type="text"
                          name={"years" + index}
                          className={!item.yearError ? "form-control mb-1" : "form-control mb-1  is-invalid"}
                          defaultValue={item.years == null ? "" : item.years}
                          placeholder=""
                          onChange={(e) => handleYearChange(e, index)}
                          onFocus={(e) => handleOnFocus(e, index)}
                        />
                        {item.yearError && (
                          <div className="invalid-feedback" style={{ display: "block" }}>
                            {item.yearError}
                          </div>
                        )}
                      </div>

                      <div className="medical-group col-6">
                        <label className="form-label">Months</label>
                        <input
                          type="text"
                          name={"months" + index}
                          className="form-control mb-1"
                          // maxLength={2} as per discussion
                          defaultValue={item.months == null ? "" : item.months}
                          placeholder=""
                          onChange={(ev) => handleMonthChange(ev, index)}
                          onFocus={(e) => handleOnFocus(e, index)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="btnContainer">
            {!props.isSaving && (
              <Button
                type="default"
                size="large"
                className="back_btn"
                onClick={() => props.previousTabChange(0)}
              >
                <i className="fa fa-long-arrow-left"></i> Back
              </Button>
            )}
            {!props.isSaving && (
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="btn_add"
              >
                Save <i className="fa fa-long-arrow-right"></i>
              </Button>
            )}
            {props.isSaving && (
              <div style={{ height: "60px" }}>
                <Loader
                  loading={props.isSaving}
                  marginBottom="0px"
                  marginTop="8px"
                  width="368px"
                ></Loader>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default DevelopmentalMilestonesForm;
