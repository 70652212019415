import { useContext } from "react";
import { GlobalContext } from "../globalContext/globalContextProvider";
interface IProps {
  crumItem1: string;
  crumItem2: string;
  crumItem3: string;
  crumType:string;
  crumIcon:string;
}
function BreadcrumSetter(props: IProps) {
  const context = useContext(GlobalContext);
  
  
  if(props.crumItem2 != "" || props.crumItem1=="Patient Management"|| props.crumItem1=="Dashboard" || props.crumItem1=="Medication Settings"){
    context?.changeBreadcrumb(props.crumItem1,props.crumItem2,props.crumItem3,props.crumType,props.crumIcon);
  }
  
  return null;
}
export default BreadcrumSetter;
