import React, { Component, Fragment } from "react";
import "./user.scss";
import UserList from "./userList";
import AddUser from "./addUser";
import PageTitleSetter from "../../shared/pageTitleSetter";
import imgAddUser from "../../assets/images/svg-icons/add-user.svg";
import BreadcrumSetter from "../../shared/breadcrumSetter";
import userGrey from "../../assets/images/svg-icons/user-grey.svg";
import { UserListFilterModel, UserResponseModel } from "../../models/users";
import { HttpResponse } from "../../core";
import { UserService } from "../../services/user";
import withRouter from "../../shared/withRouter/withRouter";

interface IState {
  counter?: any,
  currentUserName?: any,
  isBreadcrumbChange?: any,
  tabText?: any,
  currentId?: any
  isRefresh: boolean;
  reRenderForm?:boolean;
}
export class User extends Component<any, IState> {
  private userService: UserService;
  constructor(props) {
    super(props);
    this.userService = new UserService();
    this.state = {
      counter: 1,
      currentUserName: "",
      isBreadcrumbChange: false,
      tabText: "",
      reRenderForm: false,
      currentId: 0,
      isRefresh: false
    };
  }
  userSaved = () => {
    this.setState({ counter: this.state.counter + 1, reRenderForm: true }, () =>
    this.setState({ reRenderForm: false })
  );
  };
  currentUserName = (data: any, isBreadcrumbChange: boolean, tabText: string) => {
    this.setState({
      currentUserName: data,
      isBreadcrumbChange: isBreadcrumbChange,
      tabText: tabText,
    });
  };

  componentDidMount() {
    if (window.location.pathname == "/user/adduser/0/ut" || window.location.pathname == "/user/adduser/") {
      this.getSingleRecord();
      this.setState({ isRefresh: true })
    }

  }

  componentWillReceiveProps() {   
    if (window.location.pathname=="/user/adduser/") {
      window.location.href=window.location.origin + '/user/adduser/0/ut';
     }
    if (window.location.pathname == "/user/adduser/0/ut" || window.location.pathname =="/user/adduser/") {
      this.getSingleRecord();
      this.setState({ isRefresh: true })
    }
    if (window.location.pathname == "/user/adduser/0") {
      this.setState({ isRefresh: true })
    }
   
   

  }
  getSingleRecord = () => {
    this.setState({ currentId: 0 });
    let filterModel: UserListFilterModel = {
      searchText: "",
      pageSize: 10,
      currentPage: 1,
      totalItems: 0,
      pageCount: 0
    };

    this.userService
      .getUserList(filterModel)
      .then((res: HttpResponse<UserResponseModel>) => {
        if (res && res.result && res.result.collection[0]) {
          console.log("Get user List:", res.result)
          this.setState({ currentId: res.result.collection[0].id }, () => {
            this.props.navigate(`/user/adduser/` + this.state.currentId + "/ut");
          })
        }
      });

  }
  render() {
    return (
      <Fragment>
           <PageTitleSetter title="User Management" />

        <BreadcrumSetter
          crumType="static"
          crumItem1="User Management"
          crumItem2={this.state.currentUserName}
          crumItem3=""
          crumIcon={userGrey}
        />

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 aside-menu">
              {/* <section className="registration">
                <div className="add-user">
                  <button
                    type="button"
                    className="btn btn-primary btn-block add-user-btn"
                    onClick={this.onAddNewClick}
                  >
                    <img src={imgAddUser} alt="user-img" />
                    Add User
                  </button>
                </div>
              </section> */}
              {!this.state.reRenderForm && (
                <UserList {...this.props} counter={this.state.counter} isRefresh={this.state?.isRefresh} id={this.state.currentId} />
               )}
            </div>
            <div className="col-md-10 main-section">
              <div>
                <AddUser
                  {...this.props}
                  onUserSave={this.userSaved}
                  OnCurrentUserName={this.currentUserName}
                  currentId={this.state.currentId}
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(User);
