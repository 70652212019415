import React from "react";
import { PreviousDiagnosisModel } from "../../../../../../models/diagnosis/diagnosis.model";
import { Loader } from "../../../../../../shared/loaders";
import ChildGuardianInfo from "../../../../../childGuardianInfo";
import Heading from "../../../../../heading";
import moment from "moment";
import dayjs from "dayjs";

interface IProps {
  patientId: string;
  isLoading: boolean;
  previousDiagnosisModel: PreviousDiagnosisModel[];
}
const checkIfNullOrEmpty = (val: any) => {
  let data = "-";
  if (val == null || val == undefined || val == "" || val == "Invalid Date") {
    data = "-";
  } else {
    data = val;
  }
  return data;
};
const getDataForNull = (val: any) => {
  let data = "-";
  if (val == null || val == undefined) {
    data = "-";
  } else if (val) {
    data = "Yes";
  } else if (!val) {
    data = "No";
  }
  return data;
};
export default function DiagnosisViewForm(props: IProps) {
  let previousDiagnosis = props.previousDiagnosisModel;
  return (
    <div className="region_table">
      {!props.isLoading && (
        <div>
          <Heading
            heading="Previous Diagnosis"
            subHeading=""
            styleName="medicalGeneralDetail__style"
          />
          <ChildGuardianInfo
            designation="Has your child received a previous diagnosis for a behavioral, emotional or intellectual difficulty by his/her school or another professional?"
            name={previousDiagnosis.length > 0 ? "Yes" : "No"}
            email=""
            guardian="guardianStyle"
          />
          {previousDiagnosis != null &&
            previousDiagnosis.map((diagnosis, index) => {
              return (
                <div className="SMH__accord__sibContent" key={index}>
                  <ChildGuardianInfo
                    designation="Name of Professional who diagnosed your child"
                    name={checkIfNullOrEmpty(diagnosis.professionalName)}
                    email=""
                    guardian="guardianStyle"
                  />
                  <ChildGuardianInfo
                    designation="Diagnosis given"
                    name={checkIfNullOrEmpty(diagnosis.diagnosisGiven)}
                    email=""
                    guardian="guardianStyle"
                  />
                  <ChildGuardianInfo
                    designation="Report Available?"
                    name={getDataForNull(diagnosis.isReportAvailable)}
                    email=""
                    guardian="guardianStyle"
                  />
                  <ChildGuardianInfo
                    designation="Date of diagnosis"
                    name={checkIfNullOrEmpty(
                        dayjs(new Date(diagnosis.diagnosisDate ?? "").toLocaleDateString("fr-cA")).format("MM/DD/YYYY")
                    )}
                    email=""
                    guardian="guardianStyle"
                  />
                </div>
              );
            })}
        </div>
      )}
      <Loader loading={props.isLoading} marginBottom="0px" marginTop="8px" width="368px"></Loader>
    </div>
  );
}
