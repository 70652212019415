//import moment from "moment";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  CartesianGrid,
  LabelList,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useCurrentPng } from "recharts-to-png";
import FileSaver from "file-saver";
import Heading from "../../../../../heading";
import { ClinicianWeeklyData } from "../../../../../../models/clinicianWeeklyData/clinician-weekly-data.model";
import { AddPatientMedicationModel } from "../../../../../patient/models/add-patient-medication.model";
import {
  AddendumData,
  FinalReportSaveDataModel,
  severitySelectedData,
} from "../../../../../../models/report/final-report-save-data";
import WeekwiseSideEffectInfo from "../../reportSideEffects";
import { PatientDetailModel } from "../../../../../patient/models/patient-detail.model";
import { Button, Modal, Radio } from "antd";
import Select from "react-select";
import TextArea from "antd/lib/input/TextArea";
import pdf from "../../../../../../assets/images/svg-icons/pdf.svg";
import dayjs from "dayjs";
import {
  Editor,
  EditorState,
  RichUtils,
  convertFromHTML,
  ContentState,
  KeyBindingUtil,
  getDefaultKeyBinding,
} from "draft-js";
import "draft-js/dist/Draft.css";
import { stateToHTML } from "draft-js-export-html";

interface IProps {
  data: any[];
  clinicianWeeklyData: ClinicianWeeklyData;
  medication: AddPatientMedicationModel;
  reportSavedData: FinalReportSaveDataModel;
  isShowModel: boolean;
  selectedOption: severitySelectedData[];
  selectedMedicationOptions: severitySelectedData;
  isLoading: boolean;
  medicationOptions: severitySelectedData[];
  isDownloading: boolean;
  notes: string;
  isFormVisible: boolean;
  addendum: string;
  patientDetail: PatientDetailModel;
  isReadOnly: boolean;
  chartData: number;
  handleMedicationChange: Function;
  handleSeverityChange: Function;
  handlenotesChange: Function;
  handleAddendumChange: Function;
  downloadReport: Function;
  saveDraftClick: Function;
  saveClick: Function;
  addAddendumClick: Function;
  addendumList: AddendumData[];
  cancelAddendumClick: Function;
  isAddendumError: boolean;
  weeksMissed: severitySelectedData[];
}
function FinalReportChart(props: IProps) {
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );
  useEffect(() => {
    if (props.notes && props.notes !== "") {
      const blocksFromHTML = convertFromHTML(props.notes);
      const initialNote = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const initialEditorState = EditorState.createWithContent(initialNote);
      setEditorState(initialEditorState);
    }
  }, []);
  useEffect(() => {
    let html = stateToHTML(editorState.getCurrentContent());
    handlenotesChange(html);
  }, [editorState]);
  useEffect(() => {
    let ListOfWeeksMissed: string[] = [];

    props.weeksMissed?.forEach((week) => {
      ListOfWeeksMissed.push(week.value);
    });
    console.log("weeksMissed", ListOfWeeksMissed);
    const includedData = props.data.filter((row) => {
      return !ListOfWeeksMissed.includes(row.name);
    });
    let condolidateTS : any[]=[];
    console.log(includedData);
    includedData.forEach(element => {
      condolidateTS.push({name: element.name, uv: element.uv, pv: element.pv})
    });
    setRowDataName(condolidateTS);
    console.log("new array:", condolidateTS)
    setRowData(condolidateTS);
  }, [props.weeksMissed]);

  const setRowDataName = (data: any) => {
    if (
      data.length > 0 &&
      props.medication.createPatientTrialWeekModels != undefined
    ) {
      data.forEach((ts) => {
        if (ts.name == "Week1") {
          var medicationModel =
            props.medication?.createPatientTrialWeekModels.filter(
              (cts) => cts.weekNo == 1
            )[0].createPatientTrailWeeklyMedicationModel;

          if (medicationModel?.medicationDosage != "NA") {
            ts.name = `Week1 ${medicationModel?.medicationFamily} ${medicationModel?.medicationDosage}`;
          } else {
            ts.name = `Week1 ${medicationModel?.medicationFamily}`;
          }
        } else if (ts.name == "Week2") {
          var medicationModel =
            props.medication?.createPatientTrialWeekModels.filter(
              (cts) => cts.weekNo == 2
            )[0].createPatientTrailWeeklyMedicationModel;
          if (medicationModel?.medicationDosage != "NA") {
            ts.name = `Week2 ${medicationModel?.medicationFamily} ${medicationModel?.medicationDosage}`;
          } else {
            ts.name = `Week2 ${medicationModel?.medicationFamily}`;
          }
        } else if (ts.name == "Week3") {
          var medicationModel =
            props.medication?.createPatientTrialWeekModels.filter(
              (cts) => cts.weekNo == 3
            )[0].createPatientTrailWeeklyMedicationModel;
          if (medicationModel?.medicationDosage != "NA") {
            ts.name = `Week3 ${medicationModel?.medicationFamily} ${medicationModel?.medicationDosage}`;
          } else {
            ts.name = `Week3 ${medicationModel?.medicationFamily}`;
          }
        } else if (ts.name == "Week4") {
          var medicationModel =
            props.medication?.createPatientTrialWeekModels.filter(
              (cts) => cts.weekNo == 4
            )[0].createPatientTrailWeeklyMedicationModel;
          if (medicationModel?.medicationDosage != "NA") {
            ts.name = `Week4 ${medicationModel?.medicationFamily} ${medicationModel?.medicationDosage}`;
          } else {
            ts.name = `Week4 ${medicationModel?.medicationFamily}`;
          }
        } else if (ts.name == "Week5") {
          var medicationModel =
            props.medication?.createPatientTrialWeekModels.filter(
              (cts) => cts.weekNo == 5
            )[0].createPatientTrailWeeklyMedicationModel;
          if (medicationModel?.medicationDosage != "NA") {
            ts.name = `Week5 ${medicationModel?.medicationFamily} ${medicationModel?.medicationDosage}`;
          } else {
            ts.name = `Week5 ${medicationModel?.medicationFamily}`;
          }
        } else if (ts.name == "Week6") {
          var medicationModel =
            props.medication?.createPatientTrialWeekModels.filter(
              (cts) => cts.weekNo == 5
            )[0].createPatientTrailWeeklyMedicationModel;
          if (medicationModel?.medicationDosage != "NA") {
            ts.name = `Week6 ${medicationModel?.medicationFamily} ${medicationModel?.medicationDosage}`;
          } else {
            ts.name = `Week5 ${medicationModel?.medicationFamily}`;
          }
        }
      });
    }
  };

  const RadioGroup = Radio.Group;
  const options = [
    { value: "0", label: "Non significant" },
    { value: "1", label: "No" },
    { value: "2", label: "Tolerable" },
    { value: "3", label: "Significant" },
  ];
  const [rowData, setRowData] = useState(props.data);
  const [getPng, { ref, isLoading }] = useCurrentPng();
  const { hasCommandModifier } = KeyBindingUtil;
  let [showDownloadPopup, setshowDownloadPopup] = useState(false);
  const [downloadFormat, setDownloadFormat] = useState(1);
  const [addnumState, setaddnumState] = useState("");
  const [isBenefitObserved, setIsBenefitObserved] = useState(
    props.reportSavedData.medication?.value &&
      props.reportSavedData.medication.value == "Medication Failure"
      ? false
      : true
  );
  let addendumError = "";

  const getStatusStyle = (value?: number) => {
    let statusStyle = "";
    if (value != null) {
      if (value == 5) {
        statusStyle = "weekModerate";
      } else if (value > 5) {
        statusStyle = "finalServr__orange";
      }
    }
    return statusStyle;
  };
  const getBorderStyle = (value?: number) => {
    let borderStyle = "final__cgiSevr";
    if (value != null) {
      if (value == 5) {
        borderStyle = "final__cgiSevr finalServr__orange";
      } else if (value == 6) {
        borderStyle = "final__cgiSevr finalServr__extreme";
      } else if (value == 7) {
        borderStyle = "final__cgiSevr finalServr__extreme";
      }
    }
    return borderStyle;
  };
  const weekDotStyle = (value?: number) => {
    let dotStyle = "dot";
    if (value != null) {
      if (value == 5) {
        dotStyle = "dot weekOrange";
      } else if (value == 6) {
        dotStyle = "dot weekExtremeDot";
      } else if (value == 7) {
        dotStyle = "dot weekExtremeDot";
      }
    }
    return dotStyle;
  };
  const getSeverityText = (value?: number) => {
    let severityText = "";
    switch (value) {
      case 1:
        severityText = "Normal; Not at all 1";
        break;
      case 2:
        severityText = "Borderline Problem 2";
        break;
      case 4:
        severityText = "Moderate Problem 4";
        break;
      case 3:
        severityText = "Mild Problem 3";
        break;
      case 5:
        severityText = "Marked Problem 5";
        break;
      case 6:
        severityText = "Severe Problem 6";
        break;
      case 7:
        severityText = "Most Extreme Problem 7";
        break;
      default:
        severityText = "";
        break;
    }
    return severityText;
  };
  const getImprovementText = (value?: number) => {
    let improvementText = "";
    switch (value) {
      case 1:
        improvementText = "Very Much Improved (1)";
        break;
      case 2:
        improvementText = "Improved (2)";
        break;
      case 3:
        improvementText = "Minimally Improved (3)";
        break;
      case 4:
        improvementText = "Unchanged (4)";
        break;
      case 5:
        improvementText = "Minimally Worse (5)";
        break;
      case 6:
        improvementText = "Worse (6)";
        break;
      case 7:
        improvementText = "Very Much Worse (7)";
        break;
      default:
        improvementText = "";
        break;
    }
    return improvementText;
  };

  const handleMedicationChange = (selectedOption) => {
    props.handleMedicationChange(selectedOption);
    setIsBenefitObserved(
      selectedOption.value == "Medication Failure" ? false : true
    );
  };
  const handleSeverityChange = (selectedOption) => {
    props.handleSeverityChange(selectedOption);
  };
  const handlenotesChange = (selectedOption) => {
    props.handlenotesChange(selectedOption);
  };
  const handleAddendumChange = (selectedOption) => {
    setaddnumState(selectedOption.target.value);
    props.handleAddendumChange(selectedOption);
  };
  const downloadReport = () => {
    setshowDownloadPopup((showDownloadPopup = true));
  };
  const downloadconfirmClick = async () => {
    //alert("Download format: " + downloadFormat)
    await timeout(4000);
    if (downloadFormat === 1) {
      downloadFromServer();
    }
    if (downloadFormat === 2) {
      getDocFromServer();
    }
  };
  const timeout = (delay: number) => {
    return new Promise((res) => setTimeout(res, delay));
  };
  //get pdf format
  const downloadFromServer = React.useCallback(async () => {
    const png = await getPng();
    setshowDownloadPopup((showDownloadPopup = false));
    if (png) {
      props.downloadReport(png, 1);
      setDownloadFormat(1);
    }
  }, [getPng]);
  //get docx format
  const getDocFromServer = React.useCallback(async () => {
    const png = await getPng();
    setshowDownloadPopup((showDownloadPopup = false));
    if (png) {
      props.downloadReport(png, 2);
      setDownloadFormat(1);
    }
  }, [getPng]);
  const canceldownloadPopup = () => {
    setshowDownloadPopup((showDownloadPopup = false));
    setDownloadFormat(1);
  };

  const saveDraftClick = () => {
    props.saveDraftClick();
  };
  const addAddendumClick = () => {
    props.addAddendumClick();
    setaddnumState("");
  };
  const cancelAddendumClick = () => {
    props.cancelAddendumClick();
  };
  const saveClick = () => {
    props.saveClick();
  };
  function handleKeyBindingFn(e): string | null {
    if (e.keyCode === 49 && hasCommandModifier(e)) {
      return "unordered-list-item";
    }
    return getDefaultKeyBinding(e);
  }

  const handleKeyCommand = (command, editorState) => {
    if (command === "unordered-list-item") {
      setEditorState(RichUtils.toggleBlockType(editorState, command));
      return "handled";
    }
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };
  return (
    <div>
      {showDownloadPopup && (
        <div className="margin-leftPNew">
          <LineChart
            //key={"cgichart"}
            ref={ref} // Save the ref of the chart
            data={rowData}
            height={500}
            width={900}
            margin={{ top: 5, right: 30, left: 20, bottom: 25 }}
          >
            <XAxis dataKey="name" tick={{ width: 60, fontWeight:400, xHeight:60}} tickLine={true}
          minTickGap={-300}
          axisLine={false} style={{fontSize: '11px', lineHeight:'50px', textAlign:'center'}} >
        </XAxis>
            <YAxis
              domain={['dataMin', props.chartData]}  
              label={{ value: "Total Composition Score", 
              position: "insideLeft", angle: -90,   dy: 60}}
              style={{fontSize: '11px', lineHeight:'22px', textAlign:'center'}}
              textAnchor={'middle'}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend layout="vertical" verticalAlign="top"  align="center" wrapperStyle={{
            paddingBottom: "20px",
        }} margin={{ top: 5, right: 30, left: 20, bottom: 25 }} />
            <Line
              name="Total Side Effect Composite CGI-S Score"
              dataKey="pv"
              stroke="#5c2161"
              activeDot={{ r: 8 }}
              strokeWidth={2}
            >
              <LabelList position="top" offset={10} dataKey="pv" />
            </Line>
            <Line
              name="Total Target Symptom Composite CGI-S Scores"
              dataKey="uv"
              stroke="#f08122"
              activeDot={{ r: 8 }}
              strokeWidth={2}
            >
              <LabelList position="top" offset={10} dataKey="uv" />
            </Line>
          </LineChart>
        </div>
      )}
      {!showDownloadPopup && (
        <div className="margin-leftPNew">
          <LineChart
            data={rowData}
            height={500}
            width={900}
            margin={{ top: 5, right: 100, left: 20, bottom: 25 }}
          >
            <XAxis dataKey="name" tick={{ width: 60, fontWeight:400, xHeight:60}} tickLine={true}
          minTickGap={-300}
          axisLine={false} style={{fontSize: '11px', lineHeight:'50px', textAlign:'center'}} >
        </XAxis>
        <YAxis  
        domain={['dataMin', props.chartData]}  
        label={{ value: "Total Composition Score", 
        position: "insideLeft", angle: -90,   dy: 60}}
        style={{fontSize: '11px', lineHeight:'22px', textAlign:'center'}}
        textAnchor={'middle'}
         />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend
              layout="horizontal"
              verticalAlign="top"
              align="center"
              wrapperStyle={{
                paddingBottom: "20px",
              }}
              margin={{ top: 5, right: 30, left: 20, bottom: 25 }}
            />
            <Line
          name="Total Side Effect Composite CGI-S Score"
          dataKey="pv"
          stroke="#5c2161"
          activeDot={{ r: 8 }}
          strokeWidth={2}
        ><LabelList position="top" offset={10} dataKey="pv" /></Line>
        <Line name="Total Target Symptom Composite CGI-S Scores" 
        dataKey="uv" stroke="#f08122" activeDot={{ r: 8 }} strokeWidth={2} >
        <LabelList position="top" offset={10} dataKey="uv" />
        
        </Line>
          </LineChart>
        </div>
      )}
      <Heading subHeading="SIDE EFFECTS" ccfSubHeading="finalReport__subHead" />
      <div className="finalReport__medication">
        {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData &&
          props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
            (row, i) => (
              <WeekwiseSideEffectInfo
                key={"weekwiseinfo" + row.weekNo}
                weekCount={row.weekNo}
                weekDate={row.weekStartDate}
                insomniaOrTroubleSleeping={row.insomniaOrTroubleSleeping}
                nightmares={row.nightmares}
                staresALotOrDayDreams={row.staresALotOrDayDreams}
                decreasedAppetite={row.decreasedAppetite}
                talkLessWithOthers={row.talkLessWithOthers}
                unInterestedWithOthers={row.unInterestedWithOthers}
                sideEffectsData={row}
              ></WeekwiseSideEffectInfo>
            )
          )}
      </div>
      <hr />
      <div className="weeklyTable__container weekRatingTable finalReport__table">
        <table>
          <tbody>
            <tr>
              <td rowSpan={2} className="final__mainCGLSevr">
                <h3 className="heading rating__heading">
                  CGI - Side effects
                  <br /> (Severity)
                </h3>
              </td>
              {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData &&
                props.clinicianWeeklyData?.lstClinicianSideEffectsWeeklyData?.map(
                  (row, i) =>
                    !row.isBaseLine ? (
                      <td>
                        <Heading
                          heading={"Week " + row.weekNo}
                          subHeading={dayjs(row.weekStartDate).format(
                            "MM/DD/YYYY"
                          )}
                          styleName="heading rating__heading"
                          ccfSubHeading="finalReport__subHead"
                        />
                      </td>
                    ) : null
                )}
            </tr>
            <tr>
              {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData &&
                props.clinicianWeeklyData?.lstClinicianAddImprovementsWeeklyData.map(
                  (improvements, index) => {
                    return !improvements.isBaseLine ? (
                      <Fragment>
                        {improvements.weekType != 3 &&
                          !improvements.isBaseLine && (
                            <td>
                              <div
                                className={getBorderStyle(
                                  improvements.cgiSeverityOfSideEffects
                                )}
                              >
                                <h5 className="final__sevrName ">
                                  <span
                                    className={weekDotStyle(
                                      improvements.cgiSeverityOfSideEffects
                                    )}
                                  ></span>
                                  {getSeverityText(
                                    improvements.cgiSeverityOfSideEffects
                                  )}
                                </h5>
                              </div>
                            </td>
                          )}
                        {improvements.weekType != 3 &&
                          improvements.isBaseLine && (
                            <td className="empty"></td>
                          )}
                      </Fragment>
                    ) : null;
                  }
                )}
            </tr>
          </tbody>
        </table>
      </div>

      <div className="weeklyTable__container weekRatingTable finalReport__table">
        <table>
          <tbody>
            <tr>
              <td rowSpan={2} className="final__mainCGLSevr">
                <Heading
                  heading="CGI - Behavior (Improvement)"
                  styleName="heading rating__heading"
                />
              </td>
              {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData &&
                props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
                  (row, i) =>
                    !row.isBaseLine ? (
                      <td>
                        <Heading
                          heading={"Week " + row.weekNo}
                          subHeading={dayjs(row.weekStartDate).format(
                            "MM/DD/YYYY"
                          )}
                          styleName="heading rating__heading"
                          ccfSubHeading="finalReport__subHead"
                        />
                      </td>
                    ) : null
                )}
            </tr>
            <tr>
              {props.clinicianWeeklyData
                .lstClinicianAddImprovementsWeeklyData &&
                props.clinicianWeeklyData.lstClinicianAddImprovementsWeeklyData.map(
                  (improvements, index) => {
                    return !improvements.isBaseLine ? (
                      <Fragment>
                        {improvements.weekType != 3 &&
                          !improvements.isBaseLine && (
                            <td key={index + 1}>
                              <div
                                className={getBorderStyle(
                                  improvements.cgiImprovementOfbehavior
                                )}
                              >
                                <h5 className="final__sevrName ">
                                  <span
                                    className={weekDotStyle(
                                      improvements.cgiImprovementOfbehavior
                                    )}
                                  ></span>
                                  {getImprovementText(
                                    improvements.cgiImprovementOfbehavior
                                  )}
                                </h5>
                              </div>
                            </td>
                          )}
                        {improvements.weekType != 3 &&
                          improvements.isBaseLine && (
                            <td className="empty"></td>
                          )}
                      </Fragment>
                    ) : null;
                  }
                )}
            </tr>
          </tbody>
        </table>
      </div>
      <div
        className="weeklyTable__container weekRatingTable finalReport__table"
        style={{ border: "transparent" }}
      >
        <table>
          <thead>
            <tr className="side__effects">
              <th>Clinical Global Impressions</th>
              <th>1</th>
              <th>2</th>
              <th>3</th>
              <th>4</th>
              <th>5</th>
              <th>6</th>
              <th>7</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Heading
                  heading="Severity (CGI-S)"
                  styleName="heading rating__heading"
                />
              </td>
              <td>
                <Heading
                  subHeading="Normal; Not at all"
                  ccfSubHeading="finalReport__subHead"
                />
              </td>
              <td>
                <Heading
                  subHeading="Borderline Problem"
                  ccfSubHeading="finalReport__subHead"
                />
              </td>
              <td>
                <Heading
                  subHeading="Mild Problem"
                  ccfSubHeading="finalReport__subHead"
                />
              </td>
              <td>
                <Heading
                  subHeading="Moderate Problem"
                  ccfSubHeading="finalReport__subHead"
                />
              </td>
              <td>
                <Heading
                  subHeading="Marked Problem"
                  ccfSubHeading="finalReport__subHead"
                />
              </td>
              <td>
                <Heading
                  subHeading="Severe Problem"
                  ccfSubHeading="finalReport__subHead"
                />
              </td>
              <td>
                <Heading
                  subHeading="Most Extreme Problem"
                  ccfSubHeading="finalReport__subHead"
                />
              </td>
            </tr>
            <tr>
              <td>
                <Heading
                  heading="Improvement (CGI-I)"
                  styleName="heading rating__heading"
                />
              </td>
              <td>
                <Heading
                  subHeading="Very Much Improved"
                  ccfSubHeading="finalReport__subHead"
                />
              </td>
              <td>
                <Heading
                  subHeading="Improved"
                  ccfSubHeading="finalReport__subHead"
                />
              </td>
              <td>
                <Heading
                  subHeading="Minimally Improved"
                  ccfSubHeading="finalReport__subHead"
                />
              </td>
              <td>
                <Heading
                  subHeading="Unchanged"
                  ccfSubHeading="finalReport__subHead"
                />
              </td>
              <td>
                <Heading
                  subHeading="Minimally Worse"
                  ccfSubHeading="finalReport__subHead"
                />
              </td>
              <td>
                <Heading
                  subHeading="Worse"
                  ccfSubHeading="finalReport__subHead"
                />
              </td>
              <td>
                <Heading
                  subHeading="Very Much Worse"
                  ccfSubHeading="finalReport__subHead"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <Heading
        subHeading="MEDICATION RESPONSE"
        ccfSubHeading="finalReport__subHead"
      />
      {isBenefitObserved ? (
        <p className="final__medResponse">
          According to <strong>{props.patientDetail.firstName}</strong> own
          report, the reports of{" "}
          <strong>{props.patientDetail.firstName}'s </strong>
          parents and teacher, and from observations in the clinic,{" "}
          <strong>{props.patientDetail.firstName}</strong> indicated noteworthy
          behavioral benefit during the &nbsp;
          {props.reportSavedData.isSaved ? (
            <strong>{props.reportSavedData.medication?.label + " "}</strong>
          ) : (
            <div
              className="medical-group multi-roles"
              style={{ width: "30%", paddingTop: "10px" }}
            >
              <Select
                className={`reactSelect multi-roles ${
                  props.isReadOnly ? "role-disable" : ""
                }`}
                // name="finalSevr"
                placeholder="Select Medication"
                value={props.selectedMedicationOptions}
                onChange={handleMedicationChange}
                options={props.medicationOptions}
              />
            </div>
          )}
          &nbsp;&nbsp;once daily dosage trial.
        </p>
      ) : (
        <p className="final__medResponse">
          According to <strong>{props.patientDetail.firstName}</strong> own
          report, the reports of{" "}
          <strong>{props.patientDetail.firstName}'s </strong>
          parents and teacher, and from observations in the clinic,{" "}
          <strong>{props.patientDetail.firstName}</strong> indicated &nbsp;
          {props.reportSavedData.isSaved ? (
            <strong>{props.reportSavedData.medication?.label + " "}</strong>
          ) : (
            <div
              className="medical-group multi-roles"
              style={{ width: "30%", paddingTop: "10px" }}
            >
              <Select
                className={`reactSelect multi-roles ${
                  props.isReadOnly ? "role-disable" : ""
                }`}
                // name="finalSevr"
                placeholder="Select Medication"
                value={props.selectedMedicationOptions}
                onChange={handleMedicationChange}
                options={props.medicationOptions}
              />
            </div>
          )}
          &nbsp;&nbsp;during the trial dosages.
        </p>
      )}

      <Heading subHeading="SUMMARY" ccfSubHeading="finalReport__subHead" />
      <div className="report__summary">
        {isBenefitObserved ? (
          <div className="final__medResponse">
            According to these findings.{" "}
            <strong>{props.patientDetail.firstName} </strong> received the
            greatest benefit with &nbsp;
            {props.reportSavedData.isSaved ? (
              <strong>
                {props.reportSavedData.severity?.map((c) => c.label).join(", ")}
              </strong>
            ) : (
              <div
                className="medical-group multi-roles"
                style={{ width: "35%" }}
              >
                <Select
                  className={`reactSelect multi-roles ${
                    props.isReadOnly ? "role-disable" : ""
                  }`}
                  // name="finalSevr"
                  placeholder="Select Side Effects"
                  value={props.selectedOption}
                  onChange={handleSeverityChange}
                  isMulti
                  options={options}
                />{" "}
                &nbsp;
              </div>
            )}
            &nbsp; &nbsp;side effects from the{" "}
            <strong>
              {props.selectedMedicationOptions?.label
                ? props.selectedMedicationOptions?.label + " "
                : "NA "}
            </strong>
            once daily dose.
          </div>
        ) : (
          <div className="final__medResponse">
            According to these findings.{" "}
            <strong>{props.patientDetail.firstName} </strong> did not recieved a
            significant benefit from any of the trial dosages.
          </div>
        )}
      </div>

      <Heading
        subHeading="Notes"
        ccfSubHeading="finalReport__subHead text-uppercase mt-3"
      />
      <div className="report__summary">
        {props.reportSavedData.isSaved ? (
          <pre className="final__medResponse">{props.notes}</pre>
        ) : (
          // <TextArea
          //   rows={4}
          //   value={props.notes}
          //   placeholder="Please provide any additional information not captured above. You can also specify the medications taken by the patient prior to the trial.Please include as much detail as possible."
          //   allowClear
          //   onChange={handlenotesChange}
          // />
          <Editor
            placeholder="Please provide any additional information not captured above. You can also specify the medications taken by the patient prior to the trial.Please include as much detail as possible."
            editorState={editorState}
            onChange={setEditorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={handleKeyBindingFn}
          />
        )}
      </div>
      {props.reportSavedData.isSaved && (
        <Fragment>
          <Heading
            subHeading="Addendum"
            ccfSubHeading="finalReport__subHead text-uppercase mt-3"
          />
          <div className="pl-3 pr-3">
            {props.addendumList?.map((addendum, index) => {
              let noteDate = addendum.addedOn
                ? dayjs(addendum.addedOn)
                : dayjs();
              return (
                <Fragment key={"addendum_" + index}>
                  <div>
                    <h3 className="heading weekNote__heading">
                      {noteDate.format("dddd") +
                        " (" +
                        noteDate.format("MM/DD/YYYY") +
                        ")"}
                      <span className="timestamp">
                        {noteDate.format("HH:mm")}
                      </span>
                    </h3>
                    <pre style={{ whiteSpace: "break-spaces" }}>
                      {addendum.addendum}
                    </pre>
                  </div>

                  <hr />
                </Fragment>
              );
            })}
          </div>
          <div className="report__summary">
            <textarea
              maxLength={500}
              value={props.addendum}
              className={
                !props.isAddendumError
                  ? "form-control text-area-box"
                  : "form-control text-area-box is-invalid"
              }
              placeholder="Please provide any additional information"
              onChange={handleAddendumChange}
            ></textarea>
            {props.isAddendumError && (
              <div>
                <span className={"text-danger"}>
                  {" "}
                  Please enter the additional information
                </span>
              </div>
            )}

            <div className="d-block additional-box">
              <Button
                type="primary"
                size="large"
                onClick={addAddendumClick}
                className="btn_reset"
              >
                Add
              </Button>
              <Button
                type="primary"
                size="large"
                onClick={cancelAddendumClick}
                className="btn-link"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Fragment>
      )}

      <div
        className="btnContainer right"
        style={{ minWidth: 150, width: "initial", marginRight: 10 }}
      >
        <Button
          type="text"
          size="large"
          className="finalReport_dwnload"
          onClick={downloadReport}
          icon={<img src={pdf} alt="pdf download" />}
          disabled={props.isDownloading}
        >
          {props.isDownloading ? "DOWNLOADING" : "DOWNLOAD"}
        </Button>

        {!props.reportSavedData.isSaved ? (
          <Fragment>
            <Button
              type="primary"
              size="large"
              onClick={saveDraftClick}
              // disabled={props.reportSavedData.isSaved}
              className="btn_reset"
              // hidden={props.reportSavedData.isSaved}
            >
              Save as Draft
            </Button>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              // disabled={props.reportSavedData.isSaved}
              onClick={saveClick}
              // hidden={props.reportSavedData.isSaved}
              className="btn_add"
            >
              Submit
            </Button>{" "}
          </Fragment>
        ) : (
          ""
        )}
      </div>
      <Modal
        key="downloadPopup"
        title="Download"
        open={showDownloadPopup}
        onOk={downloadconfirmClick}
        onCancel={canceldownloadPopup}
        okText="Download"
        cancelText="Cancel"
        closable={false}
        maskClosable={false}
      >
        <p>Which format do you want to use?</p>
        <RadioGroup value={downloadFormat}>
          <Radio
            value={1}
            onClick={() => {
              setDownloadFormat(1);
            }}
          >
            Adobe PDF(.pdf)
          </Radio>
          <Radio
            value={2}
            onClick={() => {
              setDownloadFormat(2);
            }}
          >
            MS word document(.docx)
          </Radio>
        </RadioGroup>
      </Modal>
    </div>
  );
}
export default FinalReportChart;
function useRechartToPng(): [any, any] {
  throw new Error("Function not implemented.");
}
