import React from "react";
import { MedicationHistoryDetailModel } from "../../../../../../models/medicalHistory/medication-history-detail.model";
import { Loader } from "../../../../../../shared/loaders";
import ChildGuardianInfo from "../../../../../childGuardianInfo";
import Heading from "../../../../../heading";

interface IProps {
  patientId: string;
  isLoading: boolean;
  medicationHistoryDetailModel: MedicationHistoryDetailModel;
}
export default function MedicationHistoryTabForm(props: IProps) {
  let medicationDetail = props.medicationHistoryDetailModel.medicationDetail;
  let medicationProblem = props.medicationHistoryDetailModel.medicationProblemDetail;

  const getData = (val: any) => {
    let data = "No";
    if (val)
      data = "Yes";
    return data;
  };
  const getDataForNull = (val: any) => {
    let data = "-";
    if (val == null || val == undefined) {
      data = "-";
    } else if (val) {
      data = "Yes";
    } else if (!val) {
      data = "No";
    }
    return data;
  };
  const checkIfNullOrEmpty = (val: any) => {
    let data = "-";
    if (val == null || val == undefined || val == "") {
      data = "-";
    } else {
      data = val;
    }
    return data;
  };
  return (
    <div className="region_table">
      {!props.isLoading && (
        <div>
          <Heading heading="Medication" subHeading="" styleName="medicalGeneralDetail__style" />
          <div className="SMH__accord__homeContent">
            <ChildGuardianInfo
              designation="Is the child presently on medication?"
              name={getDataForNull(medicationDetail?.isChildPresentOnMedication)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Type"
              name={checkIfNullOrEmpty(medicationDetail?.childPresentOnMedicationType)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Prescribed for"
              name={checkIfNullOrEmpty(medicationDetail?.childPresentOnMedicationPrescribedFor)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__homeContent">
            <ChildGuardianInfo
              designation="Has the child previously been on medication?"
              name={getDataForNull(medicationDetail?.isChildPreviouslyOnMedication)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Type"
              name={checkIfNullOrEmpty(medicationDetail?.childPreviouslyOnMedicationType)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Prescribed for"
              name={checkIfNullOrEmpty(medicationDetail?.childPreviouslyOnMedicationPrescribedFor)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__homeContent">
            <ChildGuardianInfo
              designation="Aside from medication, do any of the child's medical/health conditions require special accommodations (including the use of corrective lenses/glasses)?"
              name={getDataForNull(medicationDetail?.isChildSpecialAccommodations)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Type"
              name={checkIfNullOrEmpty(medicationDetail?.childSpecialAccommodationsType)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Accommodations for"
              name={checkIfNullOrEmpty(medicationDetail?.childSpecialAccommodationsFor)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <hr />
          <Heading
            heading="Medical Problems"
            subHeading=""
            styleName="medicalGeneralDetail__style"
          />
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Frequent ear infections"
              name={getData(medicationProblem?.frequentEarInfections)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Weight problems"
              name={getData(medicationProblem?.weightProblems)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Mumps"
              name={getData(medicationProblem?.mumps)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Frequent colds"
              name={getData(medicationProblem?.frequentColds)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Frequent fevers"
              name={getData(medicationProblem?.frequentFevers)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Frequent nosebleeds"
              name={getData(medicationProblem?.frequentNosebleeds)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Chronic diarrhea"
              name={getData(medicationProblem?.chronicDiarrhea)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Instance(s) of poisoning"
              name={getData(medicationProblem?.instanceOfPoisoning)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Rheumatic fever"
              name={getData(medicationProblem?.rheumaticFever)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Seizures/convulsions"
              name={getData(medicationProblem?.seizuresConvulsions)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Difficulty with sucking"
              name={getData(medicationProblem?.difficultyWithSucking)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Eating problems"
              name={getData(medicationProblem?.eatingProblems)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Tonsillities"
              name={getData(medicationProblem?.tonsillitis)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Tremors"
              name={getData(medicationProblem?.tremors)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="High fever(104 or higher for 3 or more days)"
              name={getData(medicationProblem?.highFever)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Constipation"
              name={getData(medicationProblem?.constipation)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Dizziness/fainting"
              name={getData(medicationProblem?.dizzinessOrFainting)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Sleep apnea"
              name={getData(medicationProblem?.sleepApnea)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Pneumonia"
              name={getData(medicationProblem?.pneumonia)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Spells of vomiting"
              name={getData(medicationProblem?.spellsOfVomiting)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Snoring"
              name={getData(medicationProblem?.snoring)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Vision problems"
              name={getData(medicationProblem?.visionProblems)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Injuries or fractures"
              name={getData(medicationProblem?.injuriesOrFractures)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Interrupted sleep"
              name={getData(medicationProblem?.interruptedSleep)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Bronchitis"
              name={getData(medicationProblem?.bronchitis)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Scarlet fever"
              name={getData(medicationProblem?.scarletFever)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Irregular/arrhythmic breathing during sleep"
              name={getData(medicationProblem?.irregularOrArrhythmicBreathingDuringSleep)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Hearing problems"
              name={getData(medicationProblem?.hearingProblems)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Measles"
              name={getData(medicationProblem?.measles)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Food allergies"
              name={getData(medicationProblem?.foodAllergies)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Meningitis"
              name={getData(medicationProblem?.meningitis)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Frequent headaches"
              name={getData(medicationProblem?.frequentHeadaches)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Other allergies"
              name={getData(medicationProblem?.otherAllergies)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Heart problems"
              name={getData(medicationProblem?.heartProblems)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Anemia (low iron/sickle cell)"
              name={getData(medicationProblem?.anemia)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Operations/Surgery"
              name={getData(medicationProblem?.operationsOrSurgery)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Asthma"
              name={getData(medicationProblem?.asthma)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Head trauma/Blow on the head"
              name={getData(medicationProblem?.headTrauma)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Repeated/prolonged hospitalization"
              name={getData(medicationProblem?.repeatedOrProlongedHospitalization)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Kidney problems"
              name={getData(medicationProblem?.kidneyProblems)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Chicken pox"
              name={getData(medicationProblem?.chickenPox)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Other(please describe below)"
              name={getData(medicationProblem?.other)}
              email=""
              guardian="guardianStyle"
            />
           
          </div>
          <div className="SMH__content_row">
          <ChildGuardianInfo
              designation="Comments"
              name={checkIfNullOrEmpty(medicationProblem?.comments)}
              email=""
              guardian="guardianStyle"
            />
          </div>
        </div>
      )}
      <Loader loading={props.isLoading} marginBottom="0px" marginTop="8px" width="368px"></Loader>
    </div>
  );
}
