import React, { Component } from "react";
import { HttpResponse } from "../../../core";
import { PsychologicalTestingPostModel } from "../../../models/psychologicalTesting/psychological-testing-post.model";
import { AddTherapyPostModel } from "../../../models/therapy/add-therapy-post.model";
import { PsychologicalTestingService } from "../../../services/psychologicalTesting";
import { TherapyService } from "../../../services/therapy/therapy.service";
import { TherapyType } from "../../../shared/enums/therapy/therapy-type";
import { ShowSuccessMessage } from "../../../shared/helpers";
import Tabs from "../../tabs";
import "../styles.css";
import PsychologocialTesting from "./psychologocialTesting";

interface State {
  tab: number;
  patientId: string;
  isFormSave?: Function;
}
export default class Psychology extends Component<any, State> {
  state: State = { tab: 0, patientId: "" };
  changeTab(tab: any) {
    this.setState({
      tab,
    });
  }

  render() {
    const { tab } = this.state;
    return (
      <div className="container">
        <div className="row">
        <div className="medical__family_tabs d-none d-xl-block col-3">
          <Tabs
            styleName="familyTab"
            text="Psychological Testing"
            steps=""
            stepsAct=""
            fullText="Psychological Testing"
            familyTextName="A"
            image=""
            activeImage=""
            tabChange={() => this.changeTab(0)}
            selected={tab === 0}
          />
        </div>
        <div className="medical__family_content col">
          {tab === 0 && (
            <PsychologocialTesting
              parentTabChange={this.props.parentTab}
              patientId={this.props.patientId}
              nextTabChange={this.props.nextTabChange}
              isFormSave= {this.props.isFormSave}
            />
          )}
        </div>
      </div>
      </div>
      
    );
  }
}
