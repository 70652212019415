import * as React from "react";
import "../../layout/medicalHistory/styles.scss";
import "./styles.css";
import { Tooltip } from "antd";

function Tabs({
  text,
  selected,
  tabChange,
  styleName,
  image,
  steps,
  fullText,
  activeImage,
  familyTextName,
  stepsAct,
    isData,
  title
}: {
  text: string;
  selected: boolean;
  tabChange: () => void;
  styleName: string;
  image: string;
  steps: string;
  fullText: string;
  activeImage: string;
  familyTextName: string;
  stepsAct: string;
  isData?: boolean;
  title?: string;
}) {
  return (
    <section className="tabsWrapper">
      <div
        onClick={tabChange}
        className={`tabContainer ${styleName} ${selected ? "activeTab" : isData? "isData":""}`}
      >
        {selected ? (
          <div className="step-text">
            {image && (
              <Tooltip placement="top" title={title}>
                <img src={activeImage} className="img-fluid" alt="icon" />
              </Tooltip>
                      )}
            {familyTextName && (
              <p className="tab__familyName">{familyTextName}</p>
            )}
            <p>
              <span className="d-block">{steps} <br /></span>
              {fullText}
            </p>
          </div>
        ) : (
          <div className="step-text">
            {image && 
               <Tooltip placement="top" title={title}>
                <img src={image} className="img-fluid" alt="icon" />
                </Tooltip>}
            {familyTextName && (
              <p className="tab__familyName">{familyTextName}</p>
            )}
            <p>
              {stepsAct && (
                <span className="d-block">
                  {stepsAct} <br />
                </span>
              )}
              {text}
            </p>
          </div>
        )}
      </div>
    </section>
  );
}

export default Tabs;
