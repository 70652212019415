import React, { Component, Fragment } from 'react'
// import  Breadcrumbs from '../shared/Breadcrumbs'
import { LearnMoreRoute } from '../routing/LearnMoreRoute'
export class LearnMorePage extends Component {
    render() {
        return (
            <Fragment>
                <LearnMoreRoute />
            </Fragment>
        )
    }
}