import moment from "moment";
import React from "react";
import Heading from "../../../../heading";
import dayjs from "dayjs";

function WeekwiseSideEffectInfo({
  weekCount,
  weekDate,
  insomniaOrTroubleSleeping,
  nightmares,
  staresALotOrDayDreams,
  talkLessWithOthers,
  unInterestedWithOthers,
  decreasedAppetite,
  sideEffectsData,
}) {
  return (
    <div className="weeklyTable__container weekRatingTable finalReport__table">
      <table>
        <tbody>
          <tr>
            <td rowSpan={2} className="final__mainTD">
              <Heading
                heading={weekCount == null ? "Baseline" : "Week " + weekCount}
                subHeading={dayjs(weekDate).format("MM/DD/YYYY")}
                styleName="heading rating__heading"
                ccfSubHeading="finalReport__subHead"
              />
            </td>
            <td>
              <p className="final__sevr">Severity Rating</p>
              <h5 className="final__sevrName">
                <span className="dot weekExtremeDot"></span>
                Most Extreme 7
              </h5>
            </td>
            <td>
              <p className="final__sevr">Severity Rating</p>
              <h5 className="final__sevrName">
                <span className="dot weekExtremeDot"></span>
                Severe Problem 6
              </h5>
            </td>
            <td>
              <p className="final__sevr">Severity Rating</p>
              <h5 className="final__sevrName">
                <span className="dot weekOrange"></span>
                Marked Problem 5
              </h5>
            </td>
          </tr>
          <tr>
            <td>
              <ul>
                {insomniaOrTroubleSleeping == 7 ? <li>Insomnia or trouble sleeping</li> : null}
                {nightmares == 7 ? <li>Nightmares</li> : null}
                {staresALotOrDayDreams == 7 ? <li>Stares a lot or daydreams</li> : null}
                {talkLessWithOthers == 7 ? <li>Talk less with others</li> : null}
                {unInterestedWithOthers == 7 ? <li>Uninterested in others</li> : null}
                {decreasedAppetite == 7 ? <li>Decreased appetite</li> : null}

                {sideEffectsData.irritable == 7 ? <li>Irritable</li> : null}
                {sideEffectsData.stomachaches == 7 ? <li>Stomachaches</li> : null}
                {sideEffectsData.headaches == 7 ? <li>Headaches</li> : null}
                {sideEffectsData.drowsiness == 7 ? <li>Drowsiness</li> : null}
                {sideEffectsData.sadOrUnhappy == 7 ? <li>Sad/Unhappy</li> : null}
                {sideEffectsData.pronetoCryingOrEasilyUpset == 7 ? (
                  <li>Prone to crying/easily upset</li>
                ) : null}

                {sideEffectsData.anxiuosOrWorried == 7 ? <li>Anxious/worried</li> : null}
                {sideEffectsData.perseveration == 7 ? (
                  <li>Perseveration (verbal or behavioral)</li>
                ) : null}
                {sideEffectsData.fingernails == 7 ? <li>Bites/picks skin or fingernails</li> : null}
                {sideEffectsData.euphoricOrUnusuallyHappyOrMania == 7 ? (
                  <li>Euphoric/Unusally happy/Mania</li>
                ) : null}
                {sideEffectsData.dizziness == 7 ? <li>Dizziness</li> : null}
                {sideEffectsData.ticsOrNervousMovement == 7 ? (
                  <li>Tics or nervous movements</li>
                ) : null}
                {sideEffectsData.overfocused == 7 ? <li>Overfocused (tunes others out)</li> : null}

                {sideEffectsData.reboundEffects == 7 ? <li>"Rebound" effects</li> : null}
                {sideEffectsData.hallucinosis == 7 ? <li>Hallucinosis</li> : null}
                {sideEffectsData.flatAffectOrEmotionalBlunting == 7 ? (
                  <li>Flat affect/Emotional blunting</li>
                ) : null}
                {sideEffectsData.dryMouth == 7 ? <li>Dry mouth</li> : null}
                {sideEffectsData.numbnessOrTinglingInExtremities == 7 ? (
                  <li>Numbness or tingling in extremities</li>
                ) : null}

                {(insomniaOrTroubleSleeping != 7 && nightmares != 7 && staresALotOrDayDreams != 7 && talkLessWithOthers != 7 &&
                 unInterestedWithOthers != 7 && decreasedAppetite != 7 && sideEffectsData.irritable!= 7
                 && sideEffectsData.stomachaches != 7 && sideEffectsData.headaches != 7 && sideEffectsData.drowsiness != 7
                 && sideEffectsData.sadOrUnhappy != 7 && sideEffectsData.pronetoCryingOrEasilyUpset != 7
                 && sideEffectsData.anxiuosOrWorried != 7 && sideEffectsData.perseveration != 7 && sideEffectsData.fingernails != 7
                 && sideEffectsData.euphoricOrUnusuallyHappyOrMania != 7 && sideEffectsData.dizziness != 7
                 && sideEffectsData.ticsOrNervousMovement != 7 && sideEffectsData.overfocused!= 7 && sideEffectsData.reboundEffects != 7
                 && sideEffectsData.hallucinosis != 7 && sideEffectsData.flatAffectOrEmotionalBlunting != 7
                 && sideEffectsData.dryMouth != 7 && sideEffectsData.numbnessOrTinglingInExtremities != 7) ? <li>-None Reported-</li> : ""
                 }
              </ul>
            </td>
            <td>
              <ul>
                {insomniaOrTroubleSleeping == 6 ? <li>Insomnia or trouble sleeping</li> : null}
                {nightmares == 6 ? <li>Nightmares</li> : null}
                {staresALotOrDayDreams == 6 ? <li>Stares a lot or daydreams</li> : null}
                {talkLessWithOthers == 6 ? <li>Talk less with others</li> : null}
                {unInterestedWithOthers == 6 ? <li>Uninterested in others</li> : null}
                {decreasedAppetite == 6 ? <li>Decreased appetite</li> : null}
                {sideEffectsData.irritable == 6 ? <li>Irritable</li> : null}
                {sideEffectsData.stomachaches == 6 ? <li>Stomachaches</li> : null}
                {sideEffectsData.headaches == 6 ? <li>Headaches</li> : null}
                {sideEffectsData.drowsiness == 6 ? <li>Drowsiness</li> : null}
                {sideEffectsData.sadOrUnhappy == 6 ? <li>Sad/Unhappy</li> : null}
                {sideEffectsData.pronetoCryingOrEasilyUpset == 6 ? (
                  <li>Prone to crying/easily upset</li>
                ) : null}

                {sideEffectsData.anxiuosOrWorried == 6 ? <li>Anxious/worried</li> : null}
                {sideEffectsData.perseveration == 6 ? (
                  <li>Perseveration (verbal or behavioral)</li>
                ) : null}
                {sideEffectsData.fingernails == 6 ? <li>Bites/picks skin or fingernails</li> : null}
                {sideEffectsData.euphoricOrUnusuallyHappyOrMania == 6 ? (
                  <li>Euphoric/Unusally happy/Mania</li>
                ) : null}
                {sideEffectsData.dizziness == 6 ? <li>Dizziness</li> : null}
                {sideEffectsData.ticsOrNervousMovement == 6 ? (
                  <li>Tics or nervous movements</li>
                ) : null}
                {sideEffectsData.overfocused == 6 ? <li>Overfocused (tunes others out)</li> : null}

                {sideEffectsData.reboundEffects == 6 ? <li>"Rebound" effects</li> : null}
                {sideEffectsData.hallucinosis == 6 ? <li>Hallucinosis</li> : null}
                {sideEffectsData.flatAffectOrEmotionalBlunting == 6 ? (
                  <li>Flat affect/Emotional blunting</li>
                ) : null}
                {sideEffectsData.dryMouth == 6 ? <li>Dry mouth</li> : null}
                {sideEffectsData.numbnessOrTinglingInExtremities == 6 ? (
                  <li>Numbness or tingling in extremities</li>
                ) : null}
                {(insomniaOrTroubleSleeping != 6 && nightmares != 6 && staresALotOrDayDreams != 6 && talkLessWithOthers != 6 &&
                 unInterestedWithOthers != 6 && decreasedAppetite != 6 && sideEffectsData.irritable!= 6
                 && sideEffectsData.stomachaches != 6 && sideEffectsData.headaches != 6 && sideEffectsData.drowsiness != 6
                 && sideEffectsData.sadOrUnhappy != 6 && sideEffectsData.pronetoCryingOrEasilyUpset != 6
                 && sideEffectsData.anxiuosOrWorried != 6 && sideEffectsData.perseveration != 6 && sideEffectsData.fingernails != 6
                 && sideEffectsData.euphoricOrUnusuallyHappyOrMania != 6 && sideEffectsData.dizziness != 6
                 && sideEffectsData.ticsOrNervousMovement != 6 && sideEffectsData.overfocused!= 6 && sideEffectsData.reboundEffects != 6
                 && sideEffectsData.hallucinosis != 6 && sideEffectsData.flatAffectOrEmotionalBlunting != 6
                 && sideEffectsData.dryMouth != 6 && sideEffectsData.numbnessOrTinglingInExtremities != 6) ? <li>-None Reported-</li> : ""
                 }
              </ul>
            </td>
            <td>
              <ul>
                {insomniaOrTroubleSleeping == 5 ? <li>Insomnia or trouble sleeping</li> : null}
                {nightmares == 5 ? <li>Nightmares</li> : null}
                {staresALotOrDayDreams == 5 ? <li>Stares a lot or daydreams</li> : null}
                {talkLessWithOthers == 5 ? <li>Talk less with others</li> : null}
                {unInterestedWithOthers == 5 ? <li>Uninterested in others</li> : null}
                {decreasedAppetite == 5 ? <li>Decreased appetite</li> : null}
                {sideEffectsData.irritable == 5 ? <li>Irritable</li> : null}
                {sideEffectsData.stomachaches == 5 ? <li>Stomachaches</li> : null}
                {sideEffectsData.headaches == 5 ? <li>Headaches</li> : null}
                {sideEffectsData.drowsiness == 5 ? <li>Drowsiness</li> : null}
                {sideEffectsData.sadOrUnhappy == 5 ? <li>Sad/Unhappy</li> : null}
                {sideEffectsData.pronetoCryingOrEasilyUpset == 5 ? (
                  <li>Prone to crying/easily upset</li>
                ) : null}

                {sideEffectsData.anxiuosOrWorried == 5 ? <li>Anxious/worried</li> : null}
                {sideEffectsData.perseveration == 5 ? (
                  <li>Perseveration (verbal or behavioral)</li>
                ) : null}
                {sideEffectsData.fingernails == 5 ? <li>Bites/picks skin or fingernails</li> : null}
                {sideEffectsData.euphoricOrUnusuallyHappyOrMania == 5 ? (
                  <li>Euphoric/Unusally happy/Mania</li>
                ) : null}
                {sideEffectsData.dizziness == 5 ? <li>Dizziness</li> : null}
                {sideEffectsData.ticsOrNervousMovement == 5 ? (
                  <li>Tics or nervous movements</li>
                ) : null}
                {sideEffectsData.overfocused == 5 ? <li>Overfocused (tunes others out)</li> : null}

                {sideEffectsData.reboundEffects == 5 ? <li>"Rebound" effects</li> : null}
                {sideEffectsData.hallucinosis == 5 ? <li>Hallucinosis</li> : null}
                {sideEffectsData.flatAffectOrEmotionalBlunting == 5 ? (
                  <li>Flat affect/Emotional blunting</li>
                ) : null}
                {sideEffectsData.dryMouth == 5 ? <li>Dry mouth</li> : null}
                {sideEffectsData.numbnessOrTinglingInExtremities == 5 ? (
                  <li>Numbness or tingling in extremities</li>
                ) : null}
                {(insomniaOrTroubleSleeping != 5 && nightmares != 5 && staresALotOrDayDreams != 5 && talkLessWithOthers != 5 &&
                 unInterestedWithOthers != 5 && decreasedAppetite != 5 && sideEffectsData.irritable!= 5
                 && sideEffectsData.stomachaches != 5 && sideEffectsData.headaches != 5 && sideEffectsData.drowsiness != 5
                 && sideEffectsData.sadOrUnhappy != 5 && sideEffectsData.pronetoCryingOrEasilyUpset != 5
                 && sideEffectsData.anxiuosOrWorried != 5 && sideEffectsData.perseveration != 5 && sideEffectsData.fingernails != 5
                 && sideEffectsData.euphoricOrUnusuallyHappyOrMania != 5 && sideEffectsData.dizziness != 5
                 && sideEffectsData.ticsOrNervousMovement != 5 && sideEffectsData.overfocused!= 5 && sideEffectsData.reboundEffects != 5
                 && sideEffectsData.hallucinosis != 5 && sideEffectsData.flatAffectOrEmotionalBlunting != 5
                 && sideEffectsData.dryMouth != 5 && sideEffectsData.numbnessOrTinglingInExtremities != 5) ? <li>-None Reported-</li> : ""
                 }
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
export default WeekwiseSideEffectInfo;
