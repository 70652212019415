import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "antd";
import "../styles.css";
import { AddMedicationModel } from "../../../models/medication/add-medication.model";
import { DropdownItemModel } from "../../../shared/models/dropdown.model";
import { Loader } from "../../../shared/loaders";
import { PlusOutlined } from "@ant-design/icons";
import Heading from "../../heading";
import { MedicationDosageAndFormatModel } from "../../../models/medication/medication-dosage-and-format.model";

interface IProps {
  medicationModel: AddMedicationModel;
  isSaving: boolean;
  saveData: Function;
  // formatModel: DropdownItemModel[];
  // durationModel: DropdownItemModel[];
  medicationTypeModel: DropdownItemModel[];
  isEditMode: boolean;
  reset: Function;
  assignModel: Function;
  medicationDosageAndFormatModel: MedicationDosageAndFormatModel[];
  // onFormatChange: Function;
  onMedicationTypeChange: Function;
  // onReleaseChange: Function;
}

function MedicationForm(props: IProps) {
  const [brandName, setBrandName] = useState(props.medicationModel.brandName);
  const { register, setError, formState: { errors }, handleSubmit, reset, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
  });
  const [addDosageError, setaddDosageError] = useState("");
  let initialModel = {} as AddMedicationModel;
  let initialDosageModel = {
    id: "",
    format: "",
    dosage: "",
  } as MedicationDosageAndFormatModel;
//   useEffect(() => {
//     if(props.medicationModel) {
//       setBrandName(props.medicationModel.brandName);
//     }
// }, [props.medicationModel])
  let medicationInfo = props.medicationModel;
  let medicationDosageAndFormatModel = props.medicationDosageAndFormatModel;
  const setDataInModel = (data) => {
    medicationInfo.id =
      props.medicationModel.id != null ? props.medicationModel.id : medicationInfo.id;
    medicationInfo.family = data.family;
    medicationInfo.brandName = data.brandName;
    setBrandName(data.brandName);
    medicationInfo.genericName = data.genericName;
    medicationInfo.medicationFormats = medicationDosageAndFormatModel;
  };
  const formSubmit = (data, event) => {
    setDataInModel(data);

    if (!checkForError()) {
      reset();
      props.saveData(medicationInfo);
    }
  };
  const handleReset = () => {
    reset();
      props.reset();
      setaddDosageError("");
  };

  const handleAddClick = () => {
    medicationDosageAndFormatModel?.push(initialDosageModel);
    props.assignModel({
      medicationDosageAndFormatModel: medicationDosageAndFormatModel,
    });
      setaddDosageError("");
  };
  const handleMedicationType = (event: any) => {
    alert("medicationType changed to :" + event.target.value )
    let value = event.target.value;
    medicationInfo.medicationType = value;
if(value=="Generic"){
  setBrandName("NA");
}
else{
  setBrandName("");
}
    setValue("medicationType", value);
    props.onMedicationTypeChange(value);
  };
  const handleRemoveClick = (index) => {
    medicationDosageAndFormatModel.splice(index, 1);
    props.assignModel({
      medicationDosageAndFormatModel: medicationDosageAndFormatModel,
    });
  };
  const handleFormatChange = (event, i) => {
    medicationDosageAndFormatModel[i].format = event.target.value;
    props.assignModel({
      medicationDosageAndFormatModel: medicationDosageAndFormatModel,
    });
  };
  const handleDosageChange = (event, i) => {
    medicationDosageAndFormatModel[i].dosage = event.target.value;

    props.assignModel({
      medicationDosageAndFormatModel: medicationDosageAndFormatModel,
    });
  };

  const checkForError = () => {
    let hasError = false;
    medicationInfo.familyError = "";
    medicationInfo.genericError = "";
    medicationInfo.medicationTypeError = "";
    medicationInfo.brandNameError="";
    if (medicationDosageAndFormatModel.length > 0) {
      medicationDosageAndFormatModel.forEach((x) => {
        x.dosageError = "";
        x.formatError = "";
        if (x.dosage == "") {
          x.dosageError = "Please fill dosage strength.";
          hasError = true;
        }
        if (x.format == "") {
          x.formatError = "Please fill dosage form.";
          hasError = true;
        }
      });
      }
      if (medicationDosageAndFormatModel.length == 0) {
          setaddDosageError("Please add dosage.");
          hasError = true;          
         
      }
    if (medicationInfo.family == "") {
      medicationInfo.familyError = "Please fill medication family.";
      hasError = true;
    }
    if (medicationInfo.genericName == "") {
      medicationInfo.genericError = "Please fill generic name.";
      hasError = true;
    }
    if (medicationInfo.medicationType == "") {
      medicationInfo.medicationTypeError = "Please select medication type.";
      hasError = true;
    }
    if (medicationInfo.medicationType == "Branded" && medicationInfo.brandName=="") {
      medicationInfo.brandNameError = "Please fill brand name.";
      hasError = true;
    }
    return hasError;
  };
  return (
    <form onSubmit={handleSubmit(formSubmit)} className="medication__form__wrap">
      <div>
        <div className="medical-group medication-grp" style={{ width: "92%" }}>
          <label className="form-label">Medication Family</label>
          <input
            type="text"
            className={
              !medicationInfo.familyError ? "form-control phone_border" : "form-control  is-invalid"
            }
            defaultValue={medicationInfo.family}
            placeholder="Medication Family"
            {...register("family", {
              // required: "Please Fill Medication Family.",
              maxLength: 128,
            })}
          />
          {medicationInfo.familyError && (
            <div className="invalid-feedback">{medicationInfo.familyError}</div>
          )}
        </div>
        <div className="medical-group medication-grp" style={{ width: "92%" }}>
          <label className="form-label">Medication Type</label>
          <select
            className={
              !medicationInfo.medicationTypeError
                ? "form-control phone_border"
                : "form-control  is-invalid"
            }
            defaultValue={medicationInfo.medicationType}
            {...register("medicationType", {
              //required: "Please Select Medication Type.",
              maxLength: 128,
              //onChange: (e)=>handleMedicationType,
            })}
            onChange={handleMedicationType}
            placeholder="Medication Type"
          >
            <option value={""}> Select</option>
            {props.medicationTypeModel.map((item, index) => (
              <option key={index} value={item.value}>
                {item.text}
              </option>
            ))}
          </select>
          {!medicationInfo.medicationTypeError && (
            <i className="fa fa-angle-down med_arrow"></i>
          )}
          {medicationInfo.medicationTypeError && (
            <i className="fa fa-angle-down med_arrow" style={{ paddingRight: "15px"}}></i>
          )}
          
          {medicationInfo.medicationTypeError && (
            <div className="invalid-feedback" style={{ display: "block" }}>
              {medicationInfo.medicationTypeError}
            </div>
          )}
        </div>
        <div className="medical-group medication-grp" style={{ width: "92%" }}>
          <label className="form-label">Brand Name</label>
          <input
            type="text"
            className={"form-control phone_border"}
            value={brandName}
            placeholder={medicationInfo.medicationType != "Generic"?"Brand Name":"NA"}
            {...register("brandName", {
              maxLength: 128,
            })}
            onChange={(val)=>{setBrandName(val.target.value)}}
            disabled={medicationInfo.medicationType == "Generic"}
                  />
            {medicationInfo.brandNameError && (
                  <div className="invalid-feedback" style={{ display: "block" }}>
                      {medicationInfo.brandNameError}
                  </div>
              )}
              </div>
        <div className="medical-group medication-grp" style={{ width: "92%" }}>
          <label className="form-label">Generic Name</label>
          <input
            type="text"
            className={
              !medicationInfo.genericError
                ? "form-control phone_border"
                : "form-control  is-invalid"
            }
            defaultValue={medicationInfo.genericName}
            placeholder="Generic Name"
            {...register("genericName", {
              //required: "Please Fill Generic Name.",
              maxLength: 128,
            })}
          />
          {medicationInfo.genericError && (
            <div className="invalid-feedback" style={{ display: "block" }}>
              {medicationInfo.genericError}
            </div>
          )}
        </div>
        {/* // changes */}
        <Heading
          heading="Add Dosage Form and Dosage Strength."
                  subHeading="Multiple Dosage strengths should be separated by comma. To add another dosage form & its corresponding strengths, click on the Add Dosage button below."
          styleName="medSet__dosageHead"
          ccfSubHeading="medSet__dosageSubhead"
        />
        {medicationDosageAndFormatModel &&
          medicationDosageAndFormatModel?.map((item, index) => {
            return (
              <div key={index}>
                <div className="medical-group medication-grp" style={{ width: "92%" }}>
                  <label className="form-label">Dosage Form</label>
                  <input
                    type="text"
                    name={"format" + index}
                    className={
                      !item.formatError ? "form-control phone_border" : "form-control  is-invalid"
                    }
                    value={item.format}
                    onChange={(e) => handleFormatChange(e, index)}
                    placeholder="Dosage Form"
                  />
                  {item.formatError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {item.formatError}
                    </div>
                  )}
                </div>
                <div className="med_formDelt">
                  <div className="medical-group medication-grp">
                    <label className="form-label">Dosage Strength</label>
                    <input
                      type="text"
                      name={"dosage" + index}
                      className={
                        !item.dosageError ? "form-control phone_border" : "form-control  is-invalid"
                      }
                      value={item.dosage}
                      onChange={(e) => handleDosageChange(e, index)}
                      placeholder="Dosage Strength"
                    />
                    {item.dosageError && <div className="invalid-feedback">{item.dosageError}</div>}
                  </div>
                  <i
                    className="far fa-trash-alt delete"
                    style={{ paddingLeft: 10, marginTop: -19 }}
                    onClick={() => handleRemoveClick(index)}
                  ></i>
                </div>
              </div>
            );
          })}
        <div className="medical-group medication-grp" style={{ marginBottom: "50px" }}>
         <Button
          type="primary"
          size="large"
          onClick={handleAddClick}
          block
          icon={<PlusOutlined />}
          className="addNew__row"
          style={{ marginTop: 0, width: "92%", marginBottom: "4px"}}
        >
          Add Dosage
        </Button>
              {addDosageError && (
                  <div className="invalid-feedback" style={{ display: "block" }}>
                      {addDosageError}
                  </div>
                  )}    
         </div>
      </div>
          
      <div className="saving-form">
        <Loader loading={props.isSaving} marginBottom="0px" marginTop="8px" width="368px"></Loader>
      </div>
      <div className="button_wrapper">
        {!props.isSaving && (
          <Button type="primary" size="large" className="btn_reset" onClick={handleReset}>
            Reset
          </Button>
        )}
        {!props.isSaving && !props.isEditMode && (
          <Button type="primary" htmlType="submit" size="large" className="btn_add">
            Add
          </Button>
        )}
        {!props.isSaving && props.isEditMode && (
          <Button type="primary" htmlType="submit" size="large" className=" btn_add">
            Update
          </Button>
        )}
      </div>
    </form>
  );
}
export default MedicationForm;
