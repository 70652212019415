import React, { Fragment } from "react";
import { HttpResponse } from "../../../../../../core";
import { AddTherapyPostModel } from "../../../../../../models/therapy/add-therapy-post.model";
import { TherapyModel } from "../../../../../../models/therapy/therapy.model";
import { TherapyService } from "../../../../../../services/therapy/therapy.service";
import { TherapyType } from "../../../../../../shared/enums/therapy/therapy-type";
import MedicalTherapyViewForm from "./medicalTherapyViewForm";

interface IState {
  patientId: string;
  isLoading: boolean;
  childTherapylst: TherapyModel[];
  familyTherapylst: TherapyModel[];
}
export class MedicalTherapyTab extends React.Component<any, IState> {
  private therapyService: TherapyService;

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.therapyService = new TherapyService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      patientId: this.props.patientId,
      childTherapylst: [] as TherapyModel[],
      familyTherapylst: [] as TherapyModel[],
    };
    return initialState;
  }
  componentDidMount() {
    if (this.props.patientId != null) {
      this.loadChildTherapylstData();
      this.loadFamilyTherapylstData();
    }
  }
  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }
  loadChildTherapylstData = () => {
    if (this.state.patientId) {
      this.setLoading(true);
      this.therapyService
        .getTherapyDetail(this.props.patientId, TherapyType.Child)
          .then((res: HttpResponse<AddTherapyPostModel>) => {
          this.setLoading(false);
          if (res && res.result && res.result.lstTherapyModel.length > 0) {
            this.setState({
                childTherapylst: res.result.lstTherapyModel,
            });
          }
        })
        .catch((ex) => {
          this.setLoading(false);
        });
    }
  };
  loadFamilyTherapylstData = () => {
    if (this.state.patientId) {
      this.setLoading(true);
      this.therapyService
        .getTherapyDetail(this.props.patientId, TherapyType.Family)
          .then((res: HttpResponse<AddTherapyPostModel>) => {
          this.setLoading(false);
              if (res && res.result && res.result.lstTherapyModel.length > 0) {
            this.setState({
                familyTherapylst: res.result.lstTherapyModel,
            });
          }
        })
        .catch((ex) => {
          this.setLoading(false);
        });
    }
  };

  render() {
    return (
      <Fragment>
        <MedicalTherapyViewForm
          patientId={this.props.patientId}
          isLoading={this.state.isLoading}
          childTherapylst={this.state.childTherapylst}
          familyTherapylst={this.state.familyTherapylst}
        />
      </Fragment>
    );
  }
}
