import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "../../styles.css";
import Heading from "../../../heading";
import { Button } from "antd";
import { MedicationProblemPostModel } from "../../../../models/medicalHistory/medication-problem-post.model";
import { Loader } from "../../../../shared/loaders";

interface IProps {
  medicationProblemPostModel: MedicationProblemPostModel;
  onSubmit: Function;
  isSaving: boolean;
  patientId: string;
  assignModel: Function;
  isReadOnly: boolean;
  previousTabChange: Function;
}
export function MedicationProblemForm(props: IProps) {
  const {
    register,
    setError,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    formState,
    setValue,
  } = useForm<MedicationProblemPostModel>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  function setInitialValues() {
    setValue("anemia", props.medicationProblemPostModel.anemia);
    setValue("asthma", props.medicationProblemPostModel.asthma);
    setValue("bronchitis", props.medicationProblemPostModel.bronchitis);
    setValue("chickenPox", props.medicationProblemPostModel.chickenPox);
    setValue(
      "chronicDiarrhea",
      props.medicationProblemPostModel.chronicDiarrhea
    );
    setValue("comments", props.medicationProblemPostModel.comments);
    setValue("constipation", props.medicationProblemPostModel.constipation);
    setValue(
      "difficultyWithSucking",
      props.medicationProblemPostModel.difficultyWithSucking
    );
    setValue(
      "dizzinessOrFainting",
      props.medicationProblemPostModel.dizzinessOrFainting
    );
    setValue("eatingProblems", props.medicationProblemPostModel.eatingProblems);
    setValue("foodAllergies", props.medicationProblemPostModel.foodAllergies);
    setValue("frequentColds", props.medicationProblemPostModel.frequentColds);
    setValue(
      "frequentEarInfections",
      props.medicationProblemPostModel.frequentEarInfections
    );
    setValue("frequentFevers", props.medicationProblemPostModel.frequentFevers);
    setValue(
      "frequentHeadaches",
      props.medicationProblemPostModel.frequentHeadaches
    );
    setValue(
      "frequentNosebleeds",
      props.medicationProblemPostModel.frequentNosebleeds
    );
    setValue("headTrauma", props.medicationProblemPostModel.headTrauma);
    setValue(
      "hearingProblems",
      props.medicationProblemPostModel.hearingProblems
    );
    setValue("heartProblems", props.medicationProblemPostModel.heartProblems);
    setValue("highFever", props.medicationProblemPostModel.highFever);
    setValue(
      "injuriesOrFractures",
      props.medicationProblemPostModel.injuriesOrFractures
    );
    setValue(
      "instanceOfPoisoning",
      props.medicationProblemPostModel.instanceOfPoisoning
    );
    setValue(
      "interruptedSleep",
      props.medicationProblemPostModel.interruptedSleep
    );
    setValue(
      "irregularOrArrhythmicBreathingDuringSleep",
      props.medicationProblemPostModel.irregularOrArrhythmicBreathingDuringSleep
    );
    setValue("kidneyProblems", props.medicationProblemPostModel.kidneyProblems);
    setValue("measles", props.medicationProblemPostModel.measles);
    setValue("meningitis", props.medicationProblemPostModel.meningitis);
    setValue("mumps", props.medicationProblemPostModel.mumps);
    setValue(
      "operationsOrSurgery",
      props.medicationProblemPostModel.operationsOrSurgery
    );
    setValue("other", props.medicationProblemPostModel.other);
    setValue("otherAllergies", props.medicationProblemPostModel.otherAllergies);
    setValue("pneumonia", props.medicationProblemPostModel.pneumonia);
    setValue(
      "repeatedOrProlongedHospitalization",
      props.medicationProblemPostModel.repeatedOrProlongedHospitalization
    );
    setValue("rheumaticFever", props.medicationProblemPostModel.rheumaticFever);
    setValue("scarletFever", props.medicationProblemPostModel.scarletFever);
    setValue(
      "seizuresConvulsions",
      props.medicationProblemPostModel.seizuresConvulsions
    );
    setValue("sleepApnea", props.medicationProblemPostModel.sleepApnea);
    setValue("snoring", props.medicationProblemPostModel.snoring);
    setValue(
      "spellsOfVomiting",
      props.medicationProblemPostModel.spellsOfVomiting
    );
    setValue("tonsillitis", props.medicationProblemPostModel.tonsillitis);
    setValue("tremors", props.medicationProblemPostModel.tremors);
    setValue("visionProblems", props.medicationProblemPostModel.visionProblems);
    setValue("weightProblems", props.medicationProblemPostModel.weightProblems);
  }
  useEffect(() => {
    setInitialValues();
  }, [props]);

  let medicationProblemPostModel = props.medicationProblemPostModel;
  const formSubmit = (data) => {
    medicationProblemPostModel.patientId = props.patientId;
    medicationProblemPostModel.comments = data.comments;
    props.assignModel({
      medicationProblemPostModel: medicationProblemPostModel,
    });
    if (checkForError()) {
      return false;
    }
    props.onSubmit({
      medicationProblemPostModel: medicationProblemPostModel,
      isAutoSave: false,
    });
  };
  const checkForError = () => {
    let hasError = false;
    // medicationProblemPostModel.comments = "";

    // if (
    //   postnatalPeriodModel.breathing == false &&
    //   (postnatalPeriodModel.breathingDelayed == "" || postnatalPeriodModel.breathingDelayed == null)
    // ) {
    //   postnatalPeriodModel.breathingDelayedError = "Please Fill Breathing Delayed How Long.";
    //   hasError = true;
    // }

    // props.assignModel({ medicationProblemPostModel: medicationProblemPostModel });
    return hasError;
  };
  const handleKeyUp = (event) => {
    setValuesInModel();
    if (event.key === "Tab") {
      saveDataOnTabChange();
    }
  };
  const handleOnFocus = (event) => {
    setValuesInModel();
    saveDataOnTabChange();
  };
  const saveDataOnTabChange = () => {
    if (!props.isSaving) {
      medicationProblemPostModel.patientId = props.patientId;
      props.onSubmit({
        medicationProblemPostModel: medicationProblemPostModel,
        isAutoSave: true,
      });
    }
  };

  function setValuesInModel() {
    //let model = getValues();
    //medicationProblemPostModel = Object.assign(medicationProblemPostModel, model);
  }
  const isSelected = (type: number, event) => {
    switch (type) {
      case 1: {
        medicationProblemPostModel.frequentEarInfections = event.target.checked;
        break;
      }
      case 2: {
        medicationProblemPostModel.weightProblems = event.target.checked;
        break;
      }
      case 3: {
        medicationProblemPostModel.mumps = event.target.checked;
        break;
      }
      case 4: {
        medicationProblemPostModel.frequentColds = event.target.checked;
        break;
      }
      case 5: {
        medicationProblemPostModel.frequentFevers = event.target.checked;
        break;
      }
      case 6: {
        medicationProblemPostModel.frequentNosebleeds = event.target.checked;
        break;
      }
      case 7: {
        medicationProblemPostModel.chronicDiarrhea = event.target.checked;
        break;
      }
      case 8: {
        medicationProblemPostModel.instanceOfPoisoning = event.target.checked;
        break;
      }
      case 9: {
        medicationProblemPostModel.rheumaticFever = event.target.checked;
        break;
      }
      case 10: {
        medicationProblemPostModel.seizuresConvulsions = event.target.checked;
        break;
      }
      case 11: {
        medicationProblemPostModel.difficultyWithSucking = event.target.checked;
        break;
      }
      case 12: {
        medicationProblemPostModel.eatingProblems = event.target.checked;
        break;
      }
      case 13: {
        medicationProblemPostModel.tonsillitis = event.target.checked;
        break;
      }
      case 14: {
        medicationProblemPostModel.tremors = event.target.checked;
        break;
      }
      case 15: {
        medicationProblemPostModel.highFever = event.target.checked;
        break;
      }
      case 16: {
        medicationProblemPostModel.constipation = event.target.checked;
        break;
      }
      case 17: {
        medicationProblemPostModel.dizzinessOrFainting = event.target.checked;
        break;
      }
      case 18: {
        medicationProblemPostModel.sleepApnea = event.target.checked;
        break;
      }
      case 19: {
        medicationProblemPostModel.pneumonia = event.target.checked;
        break;
      }
      case 20: {
        medicationProblemPostModel.spellsOfVomiting = event.target.checked;
        break;
      }
      case 21: {
        medicationProblemPostModel.snoring = event.target.checked;
        break;
      }
      case 22: {
        medicationProblemPostModel.visionProblems = event.target.checked;
        break;
      }
      case 23: {
        medicationProblemPostModel.injuriesOrFractures = event.target.checked;
        break;
      }
      case 24: {
        medicationProblemPostModel.interruptedSleep = event.target.checked;
        break;
      }
      case 25: {
        medicationProblemPostModel.bronchitis = event.target.checked;
        break;
      }
      case 26: {
        medicationProblemPostModel.scarletFever = event.target.checked;
        break;
      }
      case 27: {
        medicationProblemPostModel.irregularOrArrhythmicBreathingDuringSleep =
          event.target.checked;
        break;
      }
      case 28: {
        medicationProblemPostModel.hearingProblems = event.target.checked;
        break;
      }
      case 29: {
        medicationProblemPostModel.measles = event.target.checked;
        break;
      }
      case 30: {
        medicationProblemPostModel.foodAllergies = event.target.checked;
        break;
      }
      case 31: {
        medicationProblemPostModel.meningitis = event.target.checked;
        break;
      }
      case 32: {
        medicationProblemPostModel.frequentHeadaches = event.target.checked;
        break;
      }
      case 33: {
        medicationProblemPostModel.otherAllergies = event.target.checked;
        break;
      }
      case 34: {
        medicationProblemPostModel.heartProblems = event.target.checked;
        break;
      }
      case 35: {
        medicationProblemPostModel.anemia = event.target.checked;
        break;
      }
      case 36: {
        medicationProblemPostModel.operationsOrSurgery = event.target.checked;
        break;
      }
      case 37: {
        medicationProblemPostModel.asthma = event.target.checked;
        break;
      }
      case 38: {
        medicationProblemPostModel.headTrauma = event.target.checked;
        break;
      }
      case 39: {
        medicationProblemPostModel.repeatedOrProlongedHospitalization =
          event.target.checked;
        break;
      }
      case 40: {
        medicationProblemPostModel.kidneyProblems = event.target.checked;
        break;
      }
      case 41: {
        medicationProblemPostModel.chickenPox = event.target.checked;
        break;
      }
      case 42: {
        medicationProblemPostModel.other = event.target.checked;
        break;
      }
      default: {
        break;
      }
    }
    props.assignModel({
      medicationProblemPostModel: medicationProblemPostModel,
    });
    setValuesInModel();
    saveDataOnTabChange();
  };
  return (
    <div>
      <form onSubmit={handleSubmit(formSubmit)}>
        <div>
          <Heading heading="Medical Problems" subHeading="" styleName="" />
          <p>
            Note significant or chronic health or medical problems in the
            child's medical history. (Check all that apply.)
          </p>

          <div className="form">
            <div className="row">
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="frequentEarInfections"
                  //defaultChecked={medicationProblemPostModel.frequentEarInfections}
                  {...register("frequentEarInfections")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(1, e)}
                />
                <label
                  className={
                    medicationProblemPostModel.frequentEarInfections === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="frequentEarInfections"
                >
                  Frequent ear infections
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="weightProblems"
                  //defaultChecked={medicationProblemPostModel.weightProblems}
                  {...register("weightProblems")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(2, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.weightProblems === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="weightProblems"
                >
                  Weight problems
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="mumps"
                  //defaultChecked={medicationProblemPostModel.mumps}
                  {...register("mumps")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(3, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.mumps === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="mumps"
                >
                  Mumps
                </label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="frequentColds"
                  //defaultChecked={medicationProblemPostModel.frequentColds}
                  {...register("frequentColds")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(4, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.frequentColds === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="frequentColds"
                >
                  Frequent colds
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="frequentFevers"
                  //defaultChecked={medicationProblemPostModel.frequentFevers}
                  {...register("frequentFevers")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(5, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.frequentFevers === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="frequentFevers"
                >
                  Frequent fevers
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="frequentNosebleeds"
                  //defaultChecked={medicationProblemPostModel.frequentNosebleeds}
                  {...register("frequentNosebleeds")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(6, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.frequentNosebleeds === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="frequentNosebleeds"
                >
                  Frequent nosebleeds
                </label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="chronicDiarrhea"
                  //defaultChecked={medicationProblemPostModel.chronicDiarrhea}
                  {...register("chronicDiarrhea")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(7, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.chronicDiarrhea === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="chronicDiarrhea"
                >
                  Chronic diarrhea
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="instanceOfPoisoning"
                  //defaultChecked={medicationProblemPostModel.instanceOfPoisoning}
                  {...register("instanceOfPoisoning")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(8, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.instanceOfPoisoning === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="instanceOfPoisoning"
                >
                  Instance(s) of poisoning
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="rheumaticFever"
                  //defaultChecked={medicationProblemPostModel.rheumaticFever}
                  {...register("rheumaticFever")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(9, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.rheumaticFever === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="rheumaticFever"
                >
                  Rheumatic fever
                </label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="seizuresConvulsions"
                  //defaultChecked={medicationProblemPostModel.seizuresConvulsions}
                  {...register("seizuresConvulsions")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(10, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.seizuresConvulsions === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="seizuresConvulsions"
                >
                  Seizures/convulsions
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="difficultyWithSucking"
                  //defaultChecked={medicationProblemPostModel.difficultyWithSucking}
                  {...register("difficultyWithSucking")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(11, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.difficultyWithSucking === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="difficultyWithSucking"
                >
                  Difficulty with sucking
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="eatingProblems"
                  //defaultChecked={medicationProblemPostModel.eatingProblems}
                  {...register("eatingProblems")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(12, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.eatingProblems === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="eatingProblems"
                >
                  Eating problems
                </label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="tonsillitis"
                  //defaultChecked={medicationProblemPostModel.tonsillitis}
                  {...register("tonsillitis")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(13, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.tonsillitis === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="tonsillitis"
                >
                  Tonsillitis
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="tremors"
                  //defaultChecked={medicationProblemPostModel.tremors}
                  {...register("tremors")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(14, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.tremors === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="tremors"
                >
                  Tremors
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="highFever"
                  //defaultChecked={medicationProblemPostModel.highFever}
                  {...register("highFever")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(15, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.highFever === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="highFever"
                >
                  High fever (104 or higher for 3 or more days)
                </label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="constipation"
                  //defaultChecked={medicationProblemPostModel.constipation}
                  {...register("constipation")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(16, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.constipation === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="constipation"
                >
                  Constipation
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="dizzinessOrFainting"
                  //defaultChecked={medicationProblemPostModel.dizzinessOrFainting}
                  {...register("dizzinessOrFainting")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(17, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.dizzinessOrFainting === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="dizzinessOrFainting"
                >
                  Dizziness/fainting
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="sleepApnea"
                  //defaultChecked={medicationProblemPostModel.sleepApnea}
                  {...register("sleepApnea")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(18, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.sleepApnea === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="sleepApnea"
                >
                  Sleep apnea
                </label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="pneumonia"
                  //defaultChecked={medicationProblemPostModel.pneumonia}
                  {...register("pneumonia")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(19, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.pneumonia === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="pneumonia"
                >
                  Pneumonia
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="spellsOfVomiting"
                  //defaultChecked={medicationProblemPostModel.spellsOfVomiting}
                  {...register("spellsOfVomiting")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(20, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.spellsOfVomiting === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="spellsOfVomiting"
                >
                  Spells of vomiting
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="snoring"
                  //defaultChecked={medicationProblemPostModel.snoring}
                  {...register("snoring")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(21, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.snoring === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="snoring"
                >
                  Snoring
                </label>
              </div>
            </div>

            <div className="row">
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="visionProblems"
                  //defaultChecked={medicationProblemPostModel.visionProblems}
                  {...register("visionProblems")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(22, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.visionProblems === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="visionProblems"
                >
                  Vision problems
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="injuriesOrFractures"
                  //defaultChecked={medicationProblemPostModel.injuriesOrFractures}
                  {...register("injuriesOrFractures")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(23, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.injuriesOrFractures === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="injuriesOrFractures"
                >
                  Injuries or fractures
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="interruptedSleep"
                  //defaultChecked={medicationProblemPostModel.interruptedSleep}
                  {...register("interruptedSleep")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(24, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.interruptedSleep === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="interruptedSleep"
                >
                  Interrupted sleep
                </label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="bronchitis"
                  //defaultChecked={medicationProblemPostModel.bronchitis}
                  {...register("bronchitis")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(25, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.bronchitis === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="bronchitis"
                >
                  Bronchitis
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="scarletFever"
                  //defaultChecked={medicationProblemPostModel.scarletFever}
                  {...register("scarletFever")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(26, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.scarletFever === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="scarletFever"
                >
                  Scarlet fever
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="irregularOrArrhythmicBreathingDuringSleep"
                  //defaultChecked={medicationProblemPostModel.irregularOrArrhythmicBreathingDuringSleep}
                  {...register("irregularOrArrhythmicBreathingDuringSleep")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(27, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.irregularOrArrhythmicBreathingDuringSleep === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="irregularOrArrhythmicBreathingDuringSleep"
                >
                  Irregular/arrhythmic breathing during sleep
                </label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="hearingProblems"
                  //defaultChecked={medicationProblemPostModel.hearingProblems}
                  {...register("hearingProblems")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(28, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.hearingProblems === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="hearingProblems"
                >
                  Hearing problems
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="measles"
                  //defaultChecked={medicationProblemPostModel.measles}
                  {...register("measles")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(29, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.measles === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="measles"
                >
                  Measles
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="foodAllergies"
                  //defaultChecked={medicationProblemPostModel.foodAllergies}
                  {...register("foodAllergies")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(30, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.foodAllergies === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="foodAllergies"
                >
                  Food allergies
                </label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="meningitis"
                  //defaultChecked={medicationProblemPostModel.meningitis}
                  {...register("meningitis")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(31, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.meningitis === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="meningitis"
                >
                  Meningitis
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="frequentHeadaches"
                  //defaultChecked={medicationProblemPostModel.frequentHeadaches}
                  {...register("frequentHeadaches")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(32, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.frequentHeadaches === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="frequentHeadaches"
                >
                  Frequent headaches
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="otherAllergies"
                  //defaultChecked={medicationProblemPostModel.otherAllergies}
                  {...register("otherAllergies")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(33, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.otherAllergies === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="otherAllergies"
                >
                  Other allergies
                </label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="heartProblems"
                  //defaultChecked={medicationProblemPostModel.heartProblems}
                  {...register("heartProblems")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(34, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.heartProblems === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="heartProblems"
                >
                  Heart problems
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="anemia"
                  //defaultChecked={medicationProblemPostModel.anemia}
                  {...register("anemia")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(35, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.anemia === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="anemia"
                >
                  Anemia (low iron/sickle cell)
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="operationsOrSurgery"
                  //defaultChecked={medicationProblemPostModel.operationsOrSurgery}
                  {...register("operationsOrSurgery")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(36, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.operationsOrSurgery === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="operationsOrSurgery"
                >
                  Operations/Surgery
                </label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="asthma"
                  //defaultChecked={medicationProblemPostModel.asthma}
                  {...register("asthma")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(37, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.asthma === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="asthma"
                >
                  Asthma
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="headTrauma"
                  //defaultChecked={medicationProblemPostModel.headTrauma}
                  {...register("headTrauma")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(38, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.headTrauma === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="headTrauma"
                >
                  Head trauma/Blow on the head
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="repeatedOrProlongedHospitalization"
                  //defaultChecked={medicationProblemPostModel.repeatedOrProlongedHospitalization}
                  {...register("repeatedOrProlongedHospitalization")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(39, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.repeatedOrProlongedHospitalization === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="repeatedOrProlongedHospitalization"
                >
                  Repeated/prolonged hospitalization
                </label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="kidneyProblems"
                  //defaultChecked={medicationProblemPostModel.kidneyProblems}
                  {...register("kidneyProblems")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(40, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.kidneyProblems === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="kidneyProblems"
                >
                  Kidney problems
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="chickenPox"
                  //defaultChecked={medicationProblemPostModel.chickenPox}
                  {...register("chickenPox")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(41, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.chickenPox === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="chickenPox"
                >
                  Chicken pox
                </label>
              </div>
              <div className="medical-group col-12 col-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="other"
                  //defaultChecked={medicationProblemPostModel.other}
                  {...register("other")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(42, e)}
                />

                <label
                  className={
                    medicationProblemPostModel.other === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  htmlFor="other"
                >
                  Other (please describe below)
                </label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-11 optional-field textarea_group">
                <label className="form-label">Comments</label>
                <span className="optional">Optional</span>
                <textarea
                  className="form-control textarea"
                  //defaultValue={medicationProblemPostModel.comments}
                  {...register("comments")}
                  onFocus={handleOnFocus}
                  placeholder="Please provide any additional information."
                ></textarea>
              </div>
            </div>
          </div>
          <div className="btnContainer">
            {!props.isSaving && (
              <Button
                type="default"
                size="large"
                className="back_btn"
                onClick={() => props.previousTabChange(0)}
              >
                <i className="fa fa-long-arrow-left"></i> Back
              </Button>
            )}
            {!props.isSaving && (
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="btn_add"
              >
                Save <i className="fa fa-long-arrow-right"></i>
              </Button>
            )}
            {props.isSaving && (
              <div style={{ height: "60px" }}>
                <Loader
                  loading={props.isSaving}
                  marginBottom="0px"
                  marginTop="8px"
                  width="368px"
                ></Loader>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
