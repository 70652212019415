import React from "react";
import { ReferralConcernPostModel } from "../../../../../../models/referralConcern/referral-concern-post.model";
import { Loader } from "../../../../../../shared/loaders";
import ChildGuardianInfo from "../../../../../childGuardianInfo";
import Heading from "../../../../../heading";

interface IProps {
    patientId: string;
    isLoading: boolean;
    referralConcernDetail: ReferralConcernPostModel;
  }
  export default function ReferralConcernViewForm(props: IProps) {
    let referral =props.referralConcernDetail;
    const checkIfNullOrEmpty = (val: any) => {
      let data = "-";
      if (val == null || val == undefined || val == "") {
        data = "-";
      } else {
        data = val;
      }
      return data;
    };
    return (
       <div className="region_table">{!props.isLoading && (<div>
        <Heading
          heading="Referral Concerns"
          subHeading=""
          styleName="medicalGeneralDetail__style"
        />
        <div className="SMH__accord__sibContent">
          <ChildGuardianInfo
            designation="Reading"
            name={(referral?.reading)?"Yes":"No"}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Oppositional/defiant"
            name={(referral?.oppositionalOrDefiant)?"Yes":"No"}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Poor peer relations"
            name={(referral?.poorPeerRelations)?"Yes":"No"}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Spelling"
            name={(referral?.spelling)?"Yes":"No"}
            email=""
            guardian="guardianStyle"
          />
        </div>
        <div className="SMH__accord__sibContent">
          <ChildGuardianInfo
            designation="Hyperactive"
            name={(referral?.hyperactive)?"Yes":"No"}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Anxiousness"
            name={(referral?.anxiousness)?"Yes":"No"}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Math"
            name={(referral?.math)?"Yes":"No"}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Aggressive/Acting out"
            name={(referral?.aggressiveOrActingout)?"Yes":"No"}
            email=""
            guardian="guardianStyle"
          />
        </div>
        <div className="SMH__accord__sibContent">
          <ChildGuardianInfo
            designation="Withdrawn/depressed"
            name={(referral?.withdrawnOrDepressed)?"Yes":"No"}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Handwriting"
            name={(referral?.handwriting)?"Yes":"No"}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Impulsive"
            name={(referral?.impulsive)?"Yes":"No"}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Inattention/distractibility"
            name={(referral?.inattentionOrDistractiability)?"Yes":"No"}
            email=""
            guardian="guardianStyle"
          />
        </div>
        <div className="SMH__accord__sibContent">
          <ChildGuardianInfo
            designation="Speech/language"
            name={(referral?.speechOrLanguage)?"Yes":"No"}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Disruptive in groups"
            name={(referral?.disruptiveInGroups)?"Yes":"No"}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Low self-esteem"
            name={(referral?.lowSelfEsteem)?"Yes":"No"}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Visual perceptual skills"
            name={(referral?.visualPerceptualSkills)?"Yes":"No"}
            email=""
            guardian="guardianStyle"
          />
        </div>
        <div className="SMH__accord__sibContent">
          <ChildGuardianInfo
            designation="Gross motor skills"
            name={(referral?.grossMotorSkills)?"Yes":"No"}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Awkward/clumsy"
            name={(referral?.awkwardOrClumsy)?"Yes":"No"}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Written expression"
            name={(referral?.writtenExpression)?"Yes":"No"}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Fine motor skills"
            name={(referral?.fineMotorSkills)?"Yes":"No"}
            email=""
            guardian="guardianStyle"
          />
        </div>
        <div className="SMH__accord__sibContent">
          <ChildGuardianInfo
            designation="Low frustration tolerance"
            name={(referral?.lowFrustrationTolerance)?"Yes":"No"}
            email=""
            guardian="guardianStyle"
          />
         
        </div>
        <div className="SMH__content_row">
          <ChildGuardianInfo
            designation="Comment"
            name={checkIfNullOrEmpty(referral?.comments)}
            email=""
            guardian="guardianStyle"
          />
        </div>
             
      </div>
  )}
   <Loader
        loading={props.isLoading}
        marginBottom="0px"
        marginTop="8px"
        width="368px"
      ></Loader>
  </div>
  );
  }


