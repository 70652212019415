import React, { Fragment } from "react";
import "../../styles.css";
import { Button } from "antd";
import line from "../../../../../../assets/images/svg-icons/line.svg";
import Heading from "../../../../../heading";
import "./styles.css";
import moment from "moment";
import dayjs from "dayjs";

function ParentTeacherListing(props: any) {
  const { hideForm } = props;
  return (
    <div className="week__content">
      {props.parentWeeklyNotes
        ?.filter((x) => x.weekNo == props.selectedWeekNumber)
        .map((week) => {
          return (
            <Fragment key={week.patientTrialWeekId}>
              <Heading
                heading={"Week " + week.weekNo + " Note"}
                subHeading={
                  "(" +
                  dayjs(week.patientTrialWeekStartDate).format("MM/DD/YYYY") +
                  " - " +
                  dayjs(week.patientTrialWeekEndDate).format("MM/DD/YYYY") +
                  ")"
                }
                styleName="weekHeading"
                ccfSubHeading="ccfPTHeading"
              />
              <img src={line} alt="line" />
            </Fragment>
          );
        })}
      <div className="rating__notes">
        
          <div className="notes__content">
            {props.respondentWeeklyNotes
              ?.filter((x) => x.weekNo == props.selectedWeekNumber)
              .map((week) => {
                return (
                  <Fragment>
                    <div className="weekNotePT__list">
                      {week.notes?.length <= 0 && (
                        <Heading
                          heading=""
                          subHeading="-No Note-"
                          styleName="weekNote__heading"
                        />
                      )}
                      {week.notes?.map((note) => {
                        let noteDate = dayjs(note.createdOn);
                        return (
                          <Fragment key={"notes_" + note.id}>
                            <div>
                              <h3 className="heading weekNote__heading">
                                {noteDate.format("dddd") +
                                  " (" +
                                  noteDate.format("MM/DD/YYYY") +
                                  ") - Note By: " + note.respondentRelation}
                                  <span className="timestamp">{noteDate.format("HH:mm")}</span>
                              </h3>
                              <pre style={{ whiteSpace: "break-spaces" }}>{note.note}</pre>
                            </div>

                            <hr />
                          </Fragment>
                        );
                      })}
                    </div>
                  </Fragment>
                );
              })}
          </div>
        
        
      </div>
      <div className="drawer__btn right">
      <Button
        type="default"
        size="large"
        className="back_btn"
        onClick={() => hideForm()}
      >
        Close
      </Button>
      </div>
    </div>
  );
}
export default ParentTeacherListing;
