export class GetSeverity {
    static  text = (value?: number) => {
        let severityText=""
        switch (value) {
          case 1:
            severityText="Normal; Not at all 1"
            break;
          case 2:
            severityText="Borderline Problem 2"
            break;
          case 4:
            severityText="Moderate Problem 4"
            break;
          case 3:
            severityText="Mild Problem 3"
            break;
          case 5:
            severityText="Marked Problem 5"
            break;
          case 6:
            severityText="Severe Problem 6"
            break;
          case 7:
            severityText="Most Extreme Problem 7"
            break;
          default: 
          severityText=""
            break;   
        }
        return severityText;
      };
}