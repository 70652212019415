import React, { useState } from "react";
import Heading from "../../../heading";
import { Button, DatePicker, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "../../styles.css";
import { AddTherapyPostModel } from "../../../../models/therapy/add-therapy-post.model";
import { TherapyModel } from "../../../../models/therapy/therapy.model";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { TherapyType } from "../../../../shared/enums/therapy/therapy-type";
import { Loader } from "../../../../shared/loaders";
import dayjs from "dayjs";

interface IProps {
  therapyModel: AddTherapyPostModel;
  onSubmit: Function;
  isSaving: boolean;
  patientId: string;
  assignModel: Function;
  previousTabChange: Function;
  deleteTherapy: Function;
  isEnabled: boolean;
}
export default function AddChildTherapyForm(props: any) {
  let therapyModel = props.therapyModel;
  let initailModel = {
    id: "",
    nameOfProfessionalOrOrganization: "",
    purpose: "",
    type: TherapyType.Child,
  } as TherapyModel;
  const { register, setError, formState: { errors }, handleSubmit, control, getValues, formState, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
  });
  const [isEnabled, setIsEnabled] = useState(props.isEnabled);
  const formSubmit = () => {
    if (isEnabled) {
      if (!checkForError()) {
        props.onSubmit({ therapyModel: therapyModel, isAutoSave: false });
      }
    } else {
      therapyModel.lstTherapyModel = [];
      props.onSubmit({ therapyModel: therapyModel, isAutoSave: false });
    }
  };

  const handleNameChange = (event, i) => {
    therapyModel.lstTherapyModel[i].nameOfProfessionalOrOrganization = event.target.value;
    props.assignModel({ therapyModel: therapyModel });
  };
  const handlePurposeChange = (event, i) => {
    therapyModel.lstTherapyModel[i].purpose = event.target.value;
    props.assignModel({ therapyModel: therapyModel });
  };
  const beginDateChange = (date, i) => {
    if (date == null) {
      therapyModel.lstTherapyModel[i].beginDate = null;
    } else {
        therapyModel.lstTherapyModel[i].beginDate = dayjs(date).format("MM/DD/YYYY");
    }
    setValue(
      "beginDate" + i,
      dayjs(
        therapyModel.lstTherapyModel[i].beginDate
          ? new Date(therapyModel.lstTherapyModel[i].beginDate)
          : new Date()
      )
    );
    saveDataOnTabChange();
  };
  const endDateChange = (date, i) => {
    if (date == null) {
      therapyModel.lstTherapyModel[i].endDate = null;
    } else {
        therapyModel.lstTherapyModel[i].endDate = dayjs(date).format("MM/DD/YYYY");
    }
    setValue(
      "endDate" + i,
      dayjs(
        therapyModel.lstTherapyModel[i].endDate
          ? new Date(therapyModel.lstTherapyModel[i].endDate)
          : new Date()
      )
    );
    saveDataOnTabChange();
  };
  const checkForError = () => {
    let hasError = false;
    if (therapyModel.lstTherapyModel != null && therapyModel.lstTherapyModel.length > 0) {
      therapyModel.lstTherapyModel.forEach((x) => {
        x.nameOfProfessionalOrOrganizationError = "";
        x.purposeError = "";
        x.beginDateError = "";
        x.endDateError = "";
        if (x.nameOfProfessionalOrOrganization == "") {
          x.nameOfProfessionalOrOrganizationError =
            "Please fill name of professional/organization.";
          hasError = true;
        }
        if (x.purpose == null || x.purpose == 0) {
          x.purposeError = "Please fill purpose.";
          hasError = true;
        }
        if (x.beginDate == null) {
          x.beginDateError = "Please fill begin date.";
          hasError = true;
        }
        // if (x.endDate == null) {
        //   x.endDateError = "Please fill end date.";
        //   hasError = true;
        // }
        if (x.beginDate != null && x.endDate != null) {
          if (
            new Date(new Date(x.beginDate).toDateString()) >
            new Date(new Date(x.endDate).toDateString())
          ) {
            x.endDateError = "End date should be greater than begin date.";
            hasError = true;
          }
        }
      });
    }
    return hasError;
  };
  const handleOnFocus = (event, i) => {
    saveDataOnTabChange();
  };

  const saveDataOnTabChange = () => {
    if (!props.isSaving) {
      props.onSubmit({ therapyModel: therapyModel, isAutoSave: true });
    }
  };

  const handleRemoveClick = (index) => {
    let idToDelete = therapyModel.lstTherapyModel[index].id;
    if (idToDelete != "") {
      props.deleteTherapy(idToDelete);
    }
    therapyModel.lstTherapyModel.splice(index, 1);
    props.assignModel({ therapyModel: therapyModel });
  };
  const handleAddClick = () => {
    therapyModel.lstTherapyModel.push(initailModel);
    props.assignModel({ therapyModel: therapyModel });
  };
  const handleIsEnabledClick = (isEnabled: boolean) => {
    setIsEnabled(isEnabled);
    if (!isEnabled) {
      therapyModel.lstTherapyModel = [];
      therapyModel.lstTherapyModel.push(initailModel);
      props.assignModel({ therapyModel: therapyModel });
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(formSubmit)}>
        <div>
          <Heading heading="Child Therapy" subHeading="" styleName="" />
          <div className="form">
            <div className="row">
                <label className="form-label col-12 col-md-5">
                  Has your child received this any formal psychotherapy/counseling services? <br /> If
                  yes, please complete the following:<br /> <small className="text-secondary">Leave End Date blank if therapy is ongoing</small>
                </label>
                <div className="btn-group btnGender col-6 col-md-3">
                  <button
                    type="button"
                    onClick={() => handleIsEnabledClick(true)}
                    className={
                      isEnabled == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => handleIsEnabledClick(false)}
                    className={
                      isEnabled == false 
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                </div>
              
            </div>
            {therapyModel.lstTherapyModel != null &&
              therapyModel.lstTherapyModel.map((therapy, index) => {
                return (
                  <div className="row mt-3" key={index}>
                    <div className="medical-group col-12 col-md-3">
                      <label className="form-label">Name of Professional/Organization</label>
                      <input
                        type="text"
                        name={"nameOfProfessionalOrOrganization" + index}
                        className={
                          !therapy.nameOfProfessionalOrOrganizationError
                            ? "form-control mb-1"
                            : "form-control mb-1  is-invalid"
                        }
                        value={therapy.nameOfProfessionalOrOrganization}
                        onChange={(e) => handleNameChange(e, index)}
                        onFocus={(e) => handleOnFocus(e, index)}
                        disabled={!isEnabled}
                      />
                      {therapy.nameOfProfessionalOrOrganizationError && (
                        <div className="invalid-feedback" style={{ display: "block" }}>
                          {therapy.nameOfProfessionalOrOrganizationError}
                        </div>
                      )}
                    </div>
                    <div className="medical-group col-12 col-md-3">
                      <label className="form-label">Purpose</label>
                      <input
                        type="text"
                        name={"purpose" + index}
                        className={
                          !therapy.purposeError ? "form-control mb-1" : "form-control mb-1  is-invalid"
                        }
                        value={therapy.purpose}
                        onChange={(e) => handlePurposeChange(e, index)}
                        onFocus={(e) => handleOnFocus(e, index)}
                        disabled={!isEnabled}
                      />
                      {therapy.purposeError && (
                        <div className="invalid-feedback" style={{ display: "block" }}>
                          {therapy.purposeError}
                        </div>
                      )}
                    </div>
                    <div className="medical-group col-12 col-md-2 dob mb-4">
                      <label className="form-label">Begin Date</label>
                      <Controller
                        render={({field: { onChange, onBlur, value, name, ref }}) => (
                          <Tooltip placement="right" title="Date should be in MM/DD/YYYY format">
                          <DatePicker
                            onChange={(e) => {
                              beginDateChange(e, index);
                            }}
                            className="datepicker"
                            inputReadOnly={false}
                            name={name}
                            value={
                              therapy.beginDate == null ? undefined : dayjs(therapy.beginDate)
                            }
                            placeholder="MM/DD/YYYY"
                            format="MM/DD/YYYY"
                            disabled={!isEnabled}
                          />
                          </Tooltip>
                        )}
                        name={"beginDate" + index}
                        defaultValue={dayjs(therapy.beginDate)}
                        control={control}
                      />
                      {therapy.beginDateError && (
                        <div className="invalid-feedback" style={{ display: "block" }}>
                          {therapy.beginDateError}
                        </div>
                      )}
                    </div>
                    <div className="medical-group col-12 col-md-2 mb-4 dob">
                      <label className="form-label">End Date</label>
                      <Controller
                        render={({field:{ onChange, onBlur, value, name, ref } }) => (
                          <Tooltip placement="right" title="Date should be in MM/DD/YYYY format">
                          <DatePicker
                            onChange={(e) => {
                              endDateChange(e, index);
                            }}
                            className="datepicker"
                            inputReadOnly={false}
                            name={name}
                            value={therapy.endDate == null ? undefined : dayjs(therapy.endDate)}
                            placeholder="MM/DD/YYYY"
                            format="MM/DD/YYYY"
                            disabled={!isEnabled}
                          />
                          </Tooltip>
                        )}
                        name={"endDate" + index}
                        defaultValue={dayjs(therapy.endDate)}
                        control={control}
                      />
                      {therapy.endDateError && (
                        <div className="invalid-feedback" style={{ display: "block" }}>
                          {therapy.endDateError}
                        </div>
                      )}
                    </div>
                    {therapyModel.lstTherapyModel.length > 1 && (
                      <i
                      className="far fa-trash-alt delete medical-group col-12 col-md-1 align-self-center text-center"
                      onClick={() => handleRemoveClick(index)}
                  ></i>
                    )}
                    <hr className="mt-3"/>
                  </div>
                );
              })}
            <div>
              <Button
                type="primary"
                size="large"
                disabled={!isEnabled}
                onClick={handleAddClick}
                block
                icon={<PlusOutlined />}
                className="addNew__row"
              >
                Add More
              </Button>
            </div>
          </div>
          <div className="btnContainer">
            <div>
              <Loader
                loading={props.isSaving}
                marginBottom="0px"
                marginTop="8px"
                width="368px"
              ></Loader>
            </div>
            {!props.isSaving && (
              <Button
                type="default"
                size="large"
                className="back_btn"
                onClick={() => props.parentTabChange(6)}
              >
                <i className="fa fa-long-arrow-left"></i> Back
              </Button>
            )}
            {!props.isSaving && (
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="btn_add"
              >
                Save <i className="fa fa-long-arrow-right"></i>
              </Button>
            )}
            {props.isSaving && <div style={{ height: "60px" }}></div>}
          </div>
        </div>
      </form>
    </div>
  );
}
