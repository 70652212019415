import React, { Fragment } from "react";
import BreadcrumSetter from "../../../shared/breadcrumSetter";
import PageTitleSetter from "../../../shared/pageTitleSetter";
import ContentCard from "../../contentCard";
import managementImage from "../../../assets/images/svg-icons/patient_management-blue.svg";
import weeklyImage from "../../../assets/images/svg-icons/patient_view_blue.svg";
import patientAdd from "../../../assets/images/svg-icons/add_patient_blue.svg";
import clinicianAdd from "../../../assets/images/svg-icons/add_user_blue.svg";
import medicationSettingImage from "../../../assets/images/svg-icons/settings_blue.svg";
import { Link,NavLink } from "react-router-dom";
import "./styles.css";
import { Role } from "../../../shared/enums/role.enum";
import calendar from "../../../assets/images/svg-icons/weekly_calendar.svg";

interface IProps {
username:string;
roles:string[]
}
function DashboardTab(props: IProps) {
  return (
    <Fragment>
      <PageTitleSetter title="Welcome to M3P Application" />
      <BreadcrumSetter
        crumType="static"
        crumItem1="Dashboard"
        crumItem2=""
        crumItem3=""
        crumIcon=""
      />
      <div className="dashboard__wrap">
        <ContentCard
          styleName="dashboard__content"
          contents={[
            <div className="dashboard__innerContent">
              <h3 className="dashboard__username">Hello {props.username},</h3>
              <h3>
                Welcome to <strong>Manos Medication Monitoring Platform</strong>
              </h3>
              <p>
                      Get started quickly by choosing a card below. You can also use the navigation to your left to access pages.
                      For more information, please reference our knowledge
                base. <Link to="/learnmore">Learn More</Link>.
              </p>
            </div>,
          ]}
        />
        <div className="dashboard__nav">
          <Link to="/clinician/patientmanagement">
            <ContentCard
              styleName="dashboard__links"
              contents={[
                <div className="dashboard__linkCard">
                  <img src={managementImage} alt="patient management" />
                  <h4>Patient Management</h4>
                  <p>Review and monitor all of your patients.</p>
                  <i className="fas fa-arrow-left dashboard__arrow"></i>
                </div>,
              ]}
            />
          </Link>
          <Link to="/clinician/patientdashboard/">
            <ContentCard
              styleName="dashboard__links"
              contents={[
                <div className="dashboard__linkCard">
                  <img src={weeklyImage} alt="patient management" />
                  <h4>Weekly Update</h4>
                  <p>Add, update, and review individual patients' clinical information.</p>
                  <i className="fas fa-arrow-left dashboard__arrow"></i>
                </div>, 
              ]}
            />
          </Link>
          <NavLink to="/patient/addPatient/0/pt"> 
            <ContentCard
              styleName="dashboard__links"
              contents={[
                <div className="dashboard__linkCard">
                  <img src={patientAdd} alt="patient management" />
                  <h4>Patient Details </h4>
                  <p>
                    Add, update, and manage patient and parent user profiles and contact
                    information.
                  </p>
                  <i className="fas fa-arrow-left dashboard__arrow"></i>
                </div>,
              ]}
            />
          </NavLink>
          {(props.roles &&  (props.roles.includes(Role.Admin) || props.roles.includes(Role.SuperAdmin))) && (
            <Fragment>
          <NavLink to="/user/adduser/0/ut">
            <ContentCard
              styleName="dashboard__links"
              contents={[
                <div className="dashboard__linkCard">
                  <img src={clinicianAdd} alt="patient management" />
                  <h4>User Management </h4>
                  <p> Add, manage, and view admin and clinician user profiles.</p>
                  <i className="fas fa-arrow-left dashboard__arrow"></i>
                </div>,
              ]}
            />
          </NavLink>
          <Link to="/medication/setting">
            <ContentCard
              styleName="dashboard__links"
              contents={[
                <div className="dashboard__linkCard">
                  <img src={medicationSettingImage} alt="patient management" />
                  <h4>Medication Settings</h4>
                  <p>Add, update, and view medication information.</p>
                  <i className="fas fa-arrow-left dashboard__arrow"></i>
                </div>,
              ]}
            />
          </Link>
          </Fragment> 
           )}
        </div>
      </div>
    </Fragment>
  );
}

export default DashboardTab;
