import React, { useEffect } from "react";
import Heading from "../../../heading";
import { Button } from "antd";
import "../../styles.css";
import { useForm } from "react-hook-form";
import { BehaviouralAssetsModel } from "../../../../models/behaviourProfile/behavioural-assets.model";
import BehaviourAssetsOption from "./behaviourAssetsOption";
import { Loader } from "../../../../shared/loaders";

type BehaviouralAssetsFormValues = {
  listsTheChildsHobbiesAndInterests: string | undefined;
  describeAnySpecialTalentsOrSkills: string | undefined;
  comments: string | undefined;
}

interface IProps {
  behavioralAssetsPostModel: BehaviouralAssetsModel;
  onSubmit: Function;
  isSaving: boolean;
  patientId: string;
  assignModel: Function;
  previousTabChange: Function;
}

function BehaviouralAssetsForm(props: IProps) {
  const { register, handleSubmit, control, getValues, formState, setValue } = useForm<BehaviouralAssetsFormValues>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  function setInitialValues(){
    setValue("listsTheChildsHobbiesAndInterests", props.behavioralAssetsPostModel.listsTheChildsHobbiesAndInterests);
    setValue("describeAnySpecialTalentsOrSkills", props.behavioralAssetsPostModel.describeAnySpecialTalentsOrSkills);
    setValue("comments", props.behavioralAssetsPostModel.comments);
  }
  useEffect(() => {
    setInitialValues();
  }, [props]);

  let behavioralAssetsPostModel = props.behavioralAssetsPostModel;
  const formSubmit = (data) => {
    behavioralAssetsPostModel.patientId = props.patientId;
    behavioralAssetsPostModel.comments = data.comments;
    behavioralAssetsPostModel.listsTheChildsHobbiesAndInterests =
      data.listsTheChildsHobbiesAndInterests;
    behavioralAssetsPostModel.describeAnySpecialTalentsOrSkills =
      data.describeAnySpecialTalentsOrSkills;
    props.assignModel({ behavioralAssetsPostModel: behavioralAssetsPostModel });
    if (checkForError()) {
      return false;
    }
    props.onSubmit({
      behavioralAssetsPostModel: behavioralAssetsPostModel,
      isAutoSave: false,
    });
  };

  const checkForError = () => {
    let hasError = false;
    behavioralAssetsPostModel.listsTheChildsHobbiesAndInterestsError = "";
    behavioralAssetsPostModel.describeAnySpecialTalentsOrSkillsError = "";
    if (
      behavioralAssetsPostModel.listsTheChildsHobbiesAndInterests == null ||
      behavioralAssetsPostModel.listsTheChildsHobbiesAndInterests == ""
    ) {
      behavioralAssetsPostModel.listsTheChildsHobbiesAndInterestsError =
        " Please fill lists the child's hobbies and interests  ";
      hasError = true;
    }
    if (
      behavioralAssetsPostModel.describeAnySpecialTalentsOrSkills == null ||
      behavioralAssetsPostModel.describeAnySpecialTalentsOrSkills == ""
    ) {
      behavioralAssetsPostModel.describeAnySpecialTalentsOrSkillsError =
        " Please fill describe any special talents or skills  ";
      hasError = true;
    }
    for (const objProp in behavioralAssetsPostModel) {
      if (
        objProp != "id" &&
        objProp != "patientId" &&
        objProp != "comments" &&
        objProp != "describeAnySpecialTalentsOrSkills" &&
        objProp != "listsTheChildsHobbiesAndInterests" &&
        objProp.indexOf("Error") < 0
      ) {
        if (behavioralAssetsPostModel[objProp] == null || behavioralAssetsPostModel[objProp] == 0) {
          behavioralAssetsPostModel[objProp + "Error"] = "Please select an option.";
          hasError = true;
        } else {
          behavioralAssetsPostModel[objProp + "Error"] = "";
        }
      }
    }

    props.assignModel({ behavioralAssetsPostModel: behavioralAssetsPostModel });
    return hasError;
  };

  const handleKeyUp = (event) => {
    setValuesInModel();
    if (event.key === "Tab") {
      saveDataOnTabChange();
    }
  };

  const saveDataOnTabChange = () => {
    if (!props.isSaving) {
      behavioralAssetsPostModel.patientId = props.patientId;
      props.onSubmit({
        behavioralAssetsPostModel: behavioralAssetsPostModel,
        isAutoSave: true,
      });
    }
  };

  function setValuesInModel() {
    let model = getValues();
    behavioralAssetsPostModel = Object.assign(behavioralAssetsPostModel, model);
  }

  const handleOnSelect = (propName: string, value: number) => {
    behavioralAssetsPostModel[propName] = value;
    behavioralAssetsPostModel[propName + "Error"] = "";
    props.assignModel({ behavioralAssetsPostModel: behavioralAssetsPostModel });
    setValuesInModel();
    saveDataOnTabChange();
  };

  const handleOnFocus = (event) => {
    setValuesInModel();
    saveDataOnTabChange();
  };
  return (
    <div className="medical__birth">
      <form onSubmit={handleSubmit(formSubmit)}>
        <div className="medical__birth_innerWrapper">
          <Heading
            heading="Behavioral Assets"
            subHeading="Using the following 5-point scale, evaluate the child on the following characteristics:"
            styleName=""
          />
          <div className="form form-box">
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Makes friends easily</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="makesFriendsEasily"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Is liked by other children</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="isLikedByOtherChildren"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Has at least two close friends</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="hasAtLeastTwoCloseFriends"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Likes to be part of a group</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="likesToBePartOfAGroup"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">
                  Willingly participates in activities with family members
                </label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="willinglyParticipatesInActivitiesWithFamilyMembers"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Wants to be accepted by others</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="wantsToBeAcceptedByOthers"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Demonstrates leadership in group</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="demonstratesLeadershipInGroup"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">
                  Plays/shares activities with friends at least once per week outside of school
                </label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="playsSharesActivitiesWithFriendsAtLeastOncePerWeek"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Participate cooperatively in a group</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="participateCooperativelyInAGroup"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Willingly accepts help from family members</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="willinglyAcceptsHelpFromFamilyMembers"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Generally likes school</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="generallyLikesSchool"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Attends school willingly</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="attendsSchoolWillingly"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Works well in small groups</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="worksWellInSmallGroups"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Talks about school activities</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="talksAboutSchoolActivities"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Follow rules at school</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="followRulesAtSchool"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Usually completes homework</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="usuallyCompletesHomework"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Works well in large groups</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="worksWellInLargeGroups"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Responds appropriately to authority in school</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="respondsAppropriatelyToAuthorityInSchool"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Engages in creative projects</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="engagesInCreativeProjects"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Initiates activities by him/herself</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="initiatesActivitiesByHimHerself"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Easily tolerates changes in routine</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="easilyToleratesChangesInRoutine"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Waits his/her turn</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="waitsHisHerTurn"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Has a good sense of humor</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="hasAGoodSenseOfHumor"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Doesn't give up early</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="doesntGiveupEarly"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Is a good sport</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="isAGoodSport"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">
                  Easily manages transitions from one activity to another
                </label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="easilyManagesTransitionsFromOneActivityToAnother"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Is generally enthusiastic</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="isGenerallyEnthusiastic"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Is friendly and outgoing</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="isFriendlyAndOutgoing"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Easily expresses feelings</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="easilyExpressesFeelings"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Is able to identify feelings</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="isAbleToIdentifyFeelings"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Likes to be helpful</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="likesToBeHelpful"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Handles challenges well</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="handlesChallengesWell"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Responds to acknowledgment</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="respondsToAcknowledgement"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Shows self confidence</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="showsSelfConfidence"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Easily acknowledges others</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="easilyAcknowledgesOthers"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Follows rules at home</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="followsRulesAtHome"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Takes proper care of property</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="takesProperCareOfProperty"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Admits wrongdoing</label>
                <BehaviourAssetsOption
                  model={behavioralAssetsPostModel}
                  propName="admitsWrongdoing"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__birth_formRow5">
              <div className="medical-group textarea_group">
                <label className="form-label">Lists the child's hobbies and interests</label>
                <input
                  className={
                    !behavioralAssetsPostModel.listsTheChildsHobbiesAndInterestsError
                      ? "form-control mb-1"
                      : "form-control mb-1  is-invalid"
                  }
                  defaultValue={behavioralAssetsPostModel.listsTheChildsHobbiesAndInterests}
                  onFocus={handleOnFocus}
                  {...register("listsTheChildsHobbiesAndInterests")}
                />
                {behavioralAssetsPostModel.listsTheChildsHobbiesAndInterestsError && (
                  <div className="invalid-feedback" style={{ display: "block" }}>
                    {behavioralAssetsPostModel.listsTheChildsHobbiesAndInterestsError}
                  </div>
                )}
              </div>
            </div>
            <div className="medical__birth_formRow5">
              <div className="medical-group textarea_group">
                <label className="form-label">Describe any special talents or skills</label>
                <input
                  className={
                    !behavioralAssetsPostModel.describeAnySpecialTalentsOrSkillsError
                      ? "form-control mb-1"
                      : "form-control mb-1  is-invalid"
                  }
                  defaultValue={behavioralAssetsPostModel.describeAnySpecialTalentsOrSkills}
                  onFocus={handleOnFocus}
                  {...register("describeAnySpecialTalentsOrSkills")}
                />
                {behavioralAssetsPostModel.describeAnySpecialTalentsOrSkillsError && (
                  <div className="invalid-feedback" style={{ display: "block" }}>
                    {behavioralAssetsPostModel.describeAnySpecialTalentsOrSkillsError}
                  </div>
                )}
              </div>
            </div>
            <div className="medical__birth_formRow5">
              <div className="medical-group optional-field textarea_group">
                <label className="form-label">Comments</label>
                <span className="optional">Optional</span>
                <textarea
                  className="form-control mb-1 textarea"
                  defaultValue={behavioralAssetsPostModel.comments}
                  onFocus={handleOnFocus}
                  {...register("comments")}
                  placeholder="Please provide any additional information."
                ></textarea>
              </div>
            </div>
          </div>
          <div className="btnContainer">
            {!props.isSaving && (
              <Button
                type="default"
                size="large"
                className="back_btn"
                onClick={() => props.previousTabChange(0)}
              >
                <i className="fa fa-long-arrow-left"></i> Back
              </Button>
            )}
            {!props.isSaving && (
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="btn_add"
              >
                Save <i className="fa fa-long-arrow-right"></i>
              </Button>
            )}
            {props.isSaving && (
              <div style={{ height: "60px" }}>
                <Loader
                  loading={props.isSaving}
                  marginBottom="0px"
                  marginTop="8px"
                  width="368px"
                ></Loader>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
export default BehaviouralAssetsForm;
