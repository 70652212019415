export class DiagnosisCodeType {
    value!: string;
    label!: string;
    static getDiagnosisCodeType() {
      const diagnosisCodeTypeList: DiagnosisCodeType[] = [
        {
          value: '314.01',
          label: '314.01'
        },
        {
          value: '314.00',
          label: '314.00'
        },
        {
          value: 'F90.2',
          label: 'F90.2'
        },
        {
          value: 'F90.0',
          label: 'F90.0'
        },
        {
          value: 'F90.1',
          label: 'F90.1'
        },
        {
          value: 'F90.8',
          label: 'F90.8'
        }
        ,
        {
          value: 'F90.9',
          label: 'F90.9'
        }
      ];
  
      return diagnosisCodeTypeList;
    }
  }
  