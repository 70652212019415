import React from "react";
import { DebounceInput } from "react-debounce-input";
import "./styles.css";

function Search({
  placeholder,
  variants,
  value,
  onChange,
  name,
  searchIcon,
}: {
  type: string;
  placeholder: string;
  variants: string;
  value?: string;
  onChange: (text: any) => void;
  name: string;
  searchIcon: string;
}) {
  return (
    <div className="searchBox ">
      {/* <input
        type="text"
        placeholder={placeholder}
        className={`search ${variants}`}
        defaultValue={value}
        onChange={onChange}
        name={name}
      /> */} 
      <DebounceInput
          minLength={0}
          type="text"
          debounceTimeout={500}
          placeholder={placeholder}
          className={`search ${variants} width-unset`}
          value={value}
          onChange={onChange}
          name={name} />
      {searchIcon && <img src={searchIcon} alt="search" />}
    </div>
  );
}
export default Search;
