import React from "react";
import { AddMedicationModel } from "../../../models/medication/add-medication.model";
import { DropdownItemModel } from "../../../shared/models/dropdown.model";
import { AddPatientMedicationModel } from "../models/add-patient-medication.model";
import "./styles.css";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { RangePickerProps } from "antd/es/date-picker";

interface IProps {
  medication: AddPatientMedicationModel;
  medicationInfoListViewModel: AddMedicationModel[];
  patientId: string;
  isReadOnly: boolean;
  assignModel: Function;
  medicationTypeModel: DropdownItemModel[];
  familyList: string[];
  brandList: string[];
  genericList: string[];
  assignMedicationTypeModel: Function;
  filterList: AddMedicationModel[];
  dosageList: string[];
  formatList: string[];
  selectedFamily: string;
  selectedBrandName: string;
  selectedFormat: string;
  selectedMedicationType: string;
  selectedDosage: string;
  selectedGenericName: string;
  weekCount: string;
  weekDate: string;
  weekEnd: string;
  index: number;
  medicationFamilyError?: string | null;
  medicationBrandError?: string | null;
  medicationGenericError?: string | null;
  medicationDosageError?: string | null;
  medicationFormatError?: string | null;
  medicationTypeError?: string | null;
  previousWeekStart: string | Date | null | undefined;
  nextWeekStart: string | Date | null | undefined;
  changeMedicationStartSchedule: Function;
  changeMedicationEndSchedule: Function;
}
function WeekwiseMedInfo(props: IProps) {
  let medicationModel = { ...props.medication };
  let medicationInfoListViewModel = props.medicationInfoListViewModel;
  let medicationTypeModel = props.medicationTypeModel;
  let familyList = props.familyList.sort();
  let brandList = props.brandList;

  let selectedFamily = props.selectedFamily || "";
  let selectedBrandName = props.selectedBrandName || "";
  let filterList = props.filterList;
  let dosageList = props.dosageList;
  let formatList = props.formatList;
  let genericList = props.genericList;
  let selectedFormat = props.selectedFormat || "";
  let selectedMedicationType = props.selectedMedicationType || "";
  let selectedDosage = props.selectedDosage || "";
  let selectedGenericName = props.selectedGenericName || "";
  let medicationFamilyError = props.medicationFamilyError;
  let medicationBrandError = props.medicationBrandError;
  let medicationGenericError = props.medicationGenericError;
  let medicationDosageError = props.medicationDosageError;
  let medicationFormatError = props.medicationFormatError;
  let medicationTypeError = props.medicationTypeError;

  // MEDICATION TYPE//
  if (props.selectedMedicationType != "") {
    filterList = medicationInfoListViewModel.filter(
      (x) => x.medicationType == props.selectedMedicationType && x.family == selectedFamily
    );
    brandList = [];
    filterList
      .map((x) => brandList.push(x.brandName))
      .filter((value, index, self) => self.indexOf(value) === index);

    brandList = brandList.sort();
    filterList = filterList.sort();

    if (selectedMedicationType == "Generic") {
      //generic list
      genericList = [];
      filterList
        .map((x) => genericList.push(x.genericName))
        .filter((value, index, self) => self.indexOf(value) === index);
      genericList = genericList.sort();
    }
  }

  //BRAND NAME
  if (props.selectedGenericName != "" && selectedMedicationType == "Generic") {
    var lst = filterList.filter(
      (x) => x.genericName == props.selectedGenericName && x.family == selectedFamily
    );
    var fList: string[] = [];
    lst.forEach((x) => {
      var ss = x.medicationFormats.map((y) => y.format);
      fList = fList.concat(ss).filter((value, index, self) => self.indexOf(value) === index);
    });

    formatList = fList.sort();
  }

  //BRAND NAME
  if (props.selectedBrandName != "" && selectedMedicationType != "Generic") {
    var lst = filterList.filter(
      (x) => x.brandName == props.selectedBrandName && x.family == selectedFamily
    );
    var fList: string[] = [];
    lst.forEach((x) => {
      var ss = x.medicationFormats.map((y) => y.format);
      fList = fList.concat(ss).filter((value, index, self) => self.indexOf(value) === index);
    });
    var gList: string[] = [];
    genericList = [];
    lst
      .map((x) => genericList.push(x.genericName))
      .filter((value, index, self) => self.indexOf(value) === index);
    formatList = fList.sort();
    if (selectedMedicationType != "Generic") {
      selectedGenericName = filterList.filter(
        (x) => x.family == selectedFamily && x.brandName == selectedBrandName
      )[0]?.genericName;
    }
  }

  //FORMAT//
  if (props.selectedFormat != "" && selectedMedicationType != "Generic") {
    var lst = filterList
      .filter((x) => x.brandName == selectedBrandName && x.family == selectedFamily)
      .filter((value, index, self) => self.indexOf(value) === index);

    let dList: string[] = [];
    let doLst: string[] = [];
    lst.forEach((x) => {
      dList = x.medicationFormats
        .filter((x) => x.format == props.selectedFormat)
        .map((t) => t.dosage);
      var s = dList.toString();
      doLst = s.split(",");
      dosageList = doLst.filter((value, index, self) => self.indexOf(value) === index);
    });
  }
  if (props.selectedFormat != "" && selectedMedicationType == "Generic") {
    var lst = filterList
      .filter((x) => x.genericName == selectedGenericName && x.family == selectedFamily)
      .filter((value, index, self) => self.indexOf(value) === index);

    let dList: string[] = [];
    let doLst: string[] = [];
    lst.forEach((x) => {
      dList = x.medicationFormats
        .filter((x) => x.format == props.selectedFormat)
        .map((t) => t.dosage);
      var s = dList.toString();
      doLst = s.split(",");
      dosageList = doLst.filter((value, index, self) => self.indexOf(value) === index);
    });
  }
  //FAMILY//

  const handleMedicationFamily = (event, index) => {
    medicationModel.createPatientTrialWeekModels[
      index
    ].createPatientTrailWeeklyMedicationModel.medicationFamily = event.target.value;
    medicationModel.createPatientTrialWeekModels[
      index
    ].createPatientTrailWeeklyMedicationModel.medicationId = "066cfa27-e4b6-4e18-984b-8624861104e4";
    medicationModel.createPatientTrialWeekModels[
      index
    ].createPatientTrailWeeklyMedicationModel.medicationType =
      event.target.value == "Placebo" ? "NA" : "";
    medicationModel.createPatientTrialWeekModels[
      index
    ].createPatientTrailWeeklyMedicationModel.medicationBrandName =
      event.target.value == "Placebo" ? "NA" : "";
    medicationModel.createPatientTrialWeekModels[
      index
    ].createPatientTrailWeeklyMedicationModel.medicationGenericName =
      event.target.value == "Placebo" ? "NA" : "";
    medicationModel.createPatientTrialWeekModels[
      index
    ].createPatientTrailWeeklyMedicationModel.medicationFormat =
      event.target.value == "Placebo" ? "NA" : "";
    medicationModel.createPatientTrialWeekModels[
      index
    ].createPatientTrailWeeklyMedicationModel.medicationDosage =
      event.target.value == "Placebo" ? "NA" : "";
    props.assignModel({ medication: medicationModel });
    props.assignMedicationTypeModel({
      selectedFamily: event.target.value,
      brandList: brandList,
      selectedBrandName: event.target.value == "Placebo" ? "NA" : "",
      selectedFormat: event.target.value == "Placebo" ? "NA" : "",
      selectedMedicationType: event.target.value == "Placebo" ? "NA" : "",
      selectedDosage: event.target.value == "Placebo" ? "NA" : "",
      selectedGenericName: event.target.value == "Placebo" ? "NA" : "",
    });
  };

  //MEDICATION TYPE
  const handleMedicationType = (event, index) => {
    medicationModel.createPatientTrialWeekModels[
      index
    ].createPatientTrailWeeklyMedicationModel.medicationType = event.target.value;
    props.assignModel({ medication: medicationModel });

    var filterList = medicationInfoListViewModel.filter(
      (x) => x.medicationType == event.target.value && x.family == selectedFamily
    );
    var brandList: string[] = [];
    filterList
      .map((x) => brandList.push(x.brandName))
      .filter((value, index, self) => self.indexOf(value) === index);

    if (selectedMedicationType == "Generic") {
      genericList = [];
      filterList
        .map((x) => genericList.push(x.genericName))
        .filter((value, index, self) => self.indexOf(value) === index);
      selectedBrandName = "";
    }
    medicationModel.createPatientTrialWeekModels[
      index
    ].createPatientTrailWeeklyMedicationModel.medicationBrandName = "";
    medicationModel.createPatientTrialWeekModels[
      index
    ].createPatientTrailWeeklyMedicationModel.medicationGenericName = "";
    medicationModel.createPatientTrialWeekModels[
      index
    ].createPatientTrailWeeklyMedicationModel.medicationFormat = "";
    medicationModel.createPatientTrialWeekModels[
      index
    ].createPatientTrailWeeklyMedicationModel.medicationDosage = "";
    props.assignMedicationTypeModel({
      filterList: filterList,
      selectedFamily: selectedFamily,
      brandList: brandList,
      selectedMedicationType: event.target.value,
      genericList: gList,
      selectedFormat: "",
      selectedDosage: "",
      selectedGenericName: "",
      selectedBrandName: "",
    });
  };
  //BRAND NAME
  const handleBrandName = (event, index) => {
    medicationModel.createPatientTrialWeekModels[
      index
    ].createPatientTrailWeeklyMedicationModel.medicationBrandName = event.target.value;

    var lst = filterList.filter(
      (x) => x.brandName == event.target.value && x.family == selectedFamily
    );
    var fList: string[] = [];
    lst.forEach((x) => {
      var ss = x.medicationFormats
        .map((y) => y.format)
        .filter((value, index, self) => self.indexOf(value) === index);
      fList = fList.concat(ss);
    });
    var gList: string[] = [];
    if (selectedMedicationType != "Generic") {
      selectedGenericName = filterList.filter(
        (x) => x.family == selectedFamily && x.brandName == event.target.value
      )[0]?.genericName;
      medicationModel.createPatientTrialWeekModels[
        index
      ].createPatientTrailWeeklyMedicationModel.medicationGenericName = selectedGenericName;
    }

    medicationModel.createPatientTrialWeekModels[
      index
    ].createPatientTrailWeeklyMedicationModel.medicationFormat = "";
    medicationModel.createPatientTrialWeekModels[
      index
    ].createPatientTrailWeeklyMedicationModel.medicationDosage = "";
    props.assignModel({ medication: medicationModel });
    props.assignMedicationTypeModel({
      filterList: filterList,
      selectedBrandName: event.target.value,
      brandList: brandList,
      selectedFamily: selectedFamily,
      formatList: fList,
      dosageList: dosageList,
      genericList: gList,
      selectedGenericName: selectedGenericName,
      selectedFormat: "",
      selectedDosage: "",
    });
  };
  // GENERIC NAME
  const handleGenericName = (event, index) => {
    medicationModel.createPatientTrialWeekModels[
      index
    ].createPatientTrailWeeklyMedicationModel.medicationGenericName = event.target.value;
    props.assignModel({ medication: medicationModel });

    var lst = filterList.filter(
      (x) => x.genericName == event.target.value && x.family == selectedFamily
    );
    var fList: string[] = [];
    lst.forEach((x) => {
      var ss = x.medicationFormats
        .map((y) => y.format)
        .filter((value, index, self) => self.indexOf(value) === index);
      fList = fList.concat(ss);
    });
    medicationModel.createPatientTrialWeekModels[
      index
    ].createPatientTrailWeeklyMedicationModel.medicationBrandName = "";
    medicationModel.createPatientTrialWeekModels[
      index
    ].createPatientTrailWeeklyMedicationModel.medicationFormat = "";
    medicationModel.createPatientTrialWeekModels[
      index
    ].createPatientTrailWeeklyMedicationModel.medicationDosage = "";
    props.assignMedicationTypeModel({
      filterList: filterList,
      selectedBrandName: selectedBrandName,
      brandList: brandList,
      selectedFamily: selectedFamily,
      formatList: fList,
      dosageList: dosageList,
      genericList: gList,
      selectedGenericName: event.target.value,
      selectedFormat: "",
      selectedDosage: "",
    });
  };

  //FORMAT//
  const handleMedicationFormat = (event, index) => {
    
    medicationModel.createPatientTrialWeekModels[
      index
    ].createPatientTrailWeeklyMedicationModel.medicationFormat = event.target.value;
    props.assignModel({ medication: medicationModel });
    var lst = filterList.filter(
      (x) =>
        ((x.brandName == selectedBrandName && x.medicationType!="Generic") || (x.genericName == selectedGenericName && x.medicationType =="Generic")) &&
        x.family == selectedFamily
    );
    let dList: string[] = [];
    let doLst: string[] = [];
    lst.forEach((x) => {
      dList = x.medicationFormats
        .filter((x) => x.format == event.target.value)
        .map((t) => t.dosage);
      var s = dList.toString();
      doLst = s.split(",");
    });
    medicationModel.createPatientTrialWeekModels[
      index
    ].createPatientTrailWeeklyMedicationModel.medicationDosage =
      event.target.value == "oral liquid"
        ? (medicationModel.createPatientTrialWeekModels[
            index
          ].createPatientTrailWeeklyMedicationModel.medicationDosage = doLst.toString())
        : "";

    props.assignMedicationTypeModel({
      filterList: filterList,
      selectedBrandName: selectedBrandName,
      brandList: brandList,
      selectedFamily: selectedFamily,
      dosageList: doLst,
      formatList: formatList,
      selectedFormat: event.target.value,
      selectedDosage: doLst,
    });
  };

  //DOSAGE//
  const handleMedicationDosage = (event, index) => {
    medicationModel.createPatientTrialWeekModels[
      index
    ].createPatientTrailWeeklyMedicationModel.medicationDosage = event.target.value;
    props.assignModel({ medication: medicationModel });
    props.assignMedicationTypeModel({
      filterList: filterList,
      selectedBrandName: selectedBrandName,
      brandList: brandList,
      selectedFamily: selectedFamily,
      formatList: formatList,
      selectedDosage: event.target.value,
    });
  };
    let options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    };
const addDays = (date, days) => {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return dayjs(result).format("MM/DD/YYYY");
    }
const enableDatePicker = () => {
  if (!props.medication.isPatientTrialStart){
    return true;
  }
  if (props.weekCount == 'Trial 1'){
    return true;
  }
  // if (dayjs(props.weekDate) < dayjs().endOf('day')){
  //   return true;
  // }
  else {
    return props.isReadOnly;
  }
};

const disabledStartDates: RangePickerProps['disabledDate'] = (day) => {
  // Can not select days before today and today
  let earliestStart = dayjs(props.previousWeekStart).endOf('day');
  // if (earliestStart < dayjs().endOf('day')){
  //   earliestStart = dayjs().endOf('day')
  // }
  // let latestStart = dayjs(props.nextWeekStart).endOf('day').add(-1, "day");
  // if (props.index === 4) {
  //   latestStart = latestStart.add(1, "day");
  // }
  return earliestStart > day;
};
const disabledEndDates: RangePickerProps['disabledDate'] = (day) => {
  // Can not select days before today and today
  let earliestStart = dayjs(addDays(props.weekDate,-1)).endOf('day');
  // if (earliestStart < dayjs().endOf('day')){
  //   earliestStart = dayjs().endOf('day')
  // }
  let latestStart = dayjs(addDays(props.weekDate,6)).endOf('day');
  // if (props.index === 4) {
  //   latestStart = latestStart.add(1, "day");
  // }
  return earliestStart >= day || latestStart <= day;
};
  return (
    <div className="week__wrapper">
      <h5>{props.weekCount}</h5>
      {/* <div className="date-input-label">Week Start</div> */}
      <DatePicker
        onChange={(e) => props.changeMedicationStartSchedule(e, props.index)}
        className="datepicker"
        getPopupContainer={() => document.getElementById("scrollWrap")!}
        inputReadOnly={false}
        placeholder="MM/DD/YYYY"
        format="MM/DD/YYYY"
        disabled={enableDatePicker()}
        allowClear={false}
        // value={dayjs(row.visitDate)}
        value={props.weekDate == null ? undefined : dayjs(props.weekDate)}
        disabledDate={disabledStartDates}
      />
      {/* <div className="date-input-label">Week End</div>
      <DatePicker
        onChange={(e) => props.changeMedicationEndSchedule(e, props.index)}
        className="datepicker"
        getPopupContainer={() => document.getElementById("scrollWrap")!}
        inputReadOnly={false}
        placeholder="MM/DD/YYYY"
        format="MM/DD/YYYY"
        disabled={props.isReadOnly ? true : false}
        allowClear={false}
        // value={dayjs(row.visitDate)}
        value={props.weekEnd == null ? undefined : dayjs(props.weekEnd)}
        disabledDate={disabledEndDates}
      /> */}

      <div className={`weekcard_wrapper medicatn_cover`}>
        <div className="medicatn__content">
          <div className="medical-group medication-grp">
            <label className="form-label">Medication Family</label>
            <select
              name={"family" + props.index}
              className="form-control"
              value={props.selectedFamily}
              onChange={(e) => handleMedicationFamily(e, props.index)}
              placeholder="Select Medicine Family"
              disabled={props.isReadOnly ? true : false}
            >
              <option value={""}>Select</option>
              {familyList &&
                familyList.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
            </select>
            <i className="fa fa-angle-down med_arrow"></i>
            {medicationFamilyError && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {medicationFamilyError}
              </div>
            )}
          </div>
          <div className="medical-group medication-grp">
            <label className="form-label">Medication Type</label>
            <select
              name={"medtype" + props.index}
              className="form-control"
              value={selectedMedicationType}
              onChange={(e) => handleMedicationType(e, props.index)}
              placeholder="Select Medication Type"
              disabled={
                props.isReadOnly || selectedFamily == "Placebo" ? true : false
              }
            >
              {props.selectedFamily != "Placebo" && (
                <option value={""}>Select</option>
              )}
              {props.selectedFamily == "Placebo" && (
                <option value={"NA"}>NA</option>
              )}
              {medicationTypeModel &&
                medicationTypeModel.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.text}
                  </option>
                ))}
            </select>
            <i className="fa fa-angle-down med_arrow"></i>
            {medicationTypeError && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {" "}
                {medicationTypeError}
              </div>
            )}
          </div>

          <div className="medical-group medication-grp">
            <label className="form-label">Brand Name</label>
            <select
              name={"brand" + props.index}
              className="form-control"
              value={props.selectedBrandName}
              onChange={(e) => handleBrandName(e, props.index)}
              placeholder="Select Brand Name"
              disabled={
                props.isReadOnly ||
                props.selectedMedicationType == "Generic" ||
                selectedFamily == "Placebo"
                  ? true
                  : false
              }
            >
              {selectedMedicationType == "Generic" && (
                <option value={"NA"}>NA</option>
              )}
              {selectedMedicationType != "Generic" && (
                <option value={""}> Select</option>
              )}
              {brandList &&
                brandList.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
            </select>

            <i className="fa fa-angle-down med_arrow"></i>

            {medicationBrandError && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {medicationBrandError}
              </div>
            )}
          </div>
          {selectedMedicationType == "Branded" && (
            <div className="medical-group medication-grp">
              <label className="form-label">Generic Name</label>
              <input
                type="text"
                name={"genericInput" + props.index}
                value={selectedGenericName}
                className={"form-control"}
                readOnly={
                  props.isReadOnly ||
                  selectedMedicationType == "Branded" ||
                  selectedFamily == "Placebo"
                    ? true
                    : false
                }
                placeholder="Generic Name"
              />
            </div>
          )}
          {selectedMedicationType != "Branded" && (
            <div className="medical-group medication-grp">
              <label className="form-label">Generic Name</label>
              <select
                name={"generic" + props.index}
                className="form-control"
                value={selectedGenericName}
                onChange={(e) => handleGenericName(e, props.index)}
                placeholder="Select Generic Family"
                disabled={
                  props.isReadOnly || selectedFamily == "Placebo" ? true : false
                }
              >
                <option value={""}>Select</option>
                {genericList &&
                  genericList.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
              </select>
              <i className="fa fa-angle-down med_arrow"></i>
              {medicationGenericError && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {medicationGenericError}
                </div>
              )}
            </div>
          )}
          <div className="medical-group medication-grp">
            <label className="form-label">Dosage Form</label>
            <select
              name={"format" + props.index}
              className="form-control"
              value={props.selectedFormat}
              onChange={(e) => handleMedicationFormat(e, props.index)}
              placeholder="Select Format "
              disabled={
                props.isReadOnly || selectedFamily == "Placebo" ? true : false
              }
            >
              <option value={""}> Select</option>
              {formatList &&
                formatList.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
            </select>
            <i className="fa fa-angle-down med_arrow"></i>
            {medicationFormatError && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {medicationFormatError}
              </div>
            )}
          </div>
          {selectedFormat == "oral liquid" && (
            <div className="medical-group medication-grp">
              <label className="form-label">Dosage Strength</label>
              <input
                type="text"
                name={"dosageInput" + props.index}
                value={props.selectedDosage}
                className={"form-control"}
                readOnly={
                  props.isReadOnly || selectedFamily == "Placebo" ? true : false
                }
                onChange={(e) => handleMedicationDosage(e, props.index)}
                placeholder="Dosage"
              />
              {medicationDosageError && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {medicationDosageError}
                </div>
              )}
            </div>
          )}
          {selectedFormat != "oral liquid" && (
            <div className="medical-group medication-grp">
              <label className="form-label">Dosage Strength</label>
              <select
                name={"dosage" + props.index}
                className="form-control"
                value={props.selectedDosage}
                onChange={(e) => handleMedicationDosage(e, props.index)}
                placeholder="Select Dosage"
                disabled={
                  props.isReadOnly || selectedFamily == "Placebo" ? true : false
                }
              >
                <option value={""}> Select</option>
                {dosageList &&
                  dosageList.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
              </select>
              <i className="fa fa-angle-down med_arrow"></i>

              {medicationDosageError && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {medicationDosageError}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default WeekwiseMedInfo;
