import React, { Component } from "react";
import Tabs from "../../tabs";
import "../styles.css";
import { MedicalSiblingsAndBirthOrder } from "./sibling";
import { PrimaryCareTaker } from "./primaryCareTaker";
import { MedicalHomeComposition } from "./homeComposition";
import { MedicalLearningBehavior } from "./learningAndBehavior";
import { FamilyTransition } from "./familyTransition";
import { BirthParent } from "./birthParents";
import { FamilyBackgroundService } from "../../../services/familyBackground";
import { HttpResponse } from "../../../core";
import { GetFamilyBackgroundStepStatus } from "../../patient/models/get-family-background-step-status.model";

interface IProps {
  patientId: string;
  nextTabChange: Function;
  isFormSave?: Function;
}
interface State {
  tab: number;
  patientId: string;
  parentId: string;
  getFamilyBackgroundStepStatus?: GetFamilyBackgroundStepStatus;
}
class MedicalFamilyForm extends Component<any, State> {
  private familyBackgroundService: FamilyBackgroundService;
  state: State = { tab: 0, patientId: "", parentId: "" };
  changeTab(tab: any) {
    this.setState({
      tab,
    })
  }
  constructor(props) {
    super(props);
    this.familyBackgroundService = new FamilyBackgroundService();
  }
  componentDidMount() {
    this.loadData();
  }
  loadData() {
    if (this.props.patientId) {
      this.familyBackgroundService
        .getFamilyBackgroundStepStatus(this.props.patientId)
        .then((res: HttpResponse<GetFamilyBackgroundStepStatus>) => {
          if (res && res.result) {
            console.log(res.result);
            this.setState({ getFamilyBackgroundStepStatus: res.result });
            //this.getLastStep();
          }
        })
        .catch((ex) => {});
    }
  }
  familyBackGroundStepUpdateHandler = (key, value) => {
    
    if (this.state.getFamilyBackgroundStepStatus) {
      if (key == 1) {
        this.state.getFamilyBackgroundStepStatus.birthParentId = value;
      } else if (key == 2) {
        this.state.getFamilyBackgroundStepStatus.primaryCareTakerId = value;
      }
      else if (key == 3) {
        this.state.getFamilyBackgroundStepStatus.siblingId = value;
      }
      else if (key == 4) {
        this.state.getFamilyBackgroundStepStatus.homeCompositionId = value;
      }
      else if (key == 5) {
        this.state.getFamilyBackgroundStepStatus.learningId = value;
      }
      else if (key == 6) {
        this.state.getFamilyBackgroundStepStatus.familyTransitionId = value;
      }
    }
  };

  // getLastStep() {
  //   if (this.state.getFamilyBackgroundStepStatus?.familyTransitionId != null) {
  //     this.setState({activeStep:6});
  //   } else if (this.state.getFamilyBackgroundStepStatus?.learningId != null) {
  //     this.setState({activeStep:5});
  //   } else if (
  //     this.state.getFamilyBackgroundStepStatus?.homeCompositionId != null
  //   ) {
  //     this.setState({activeStep:4});
  //   } else if (this.state.getFamilyBackgroundStepStatus?.siblingId != null) {
  //     this.setState({activeStep:3});
  //   } else if (
  //     this.state.getFamilyBackgroundStepStatus?.primaryCareTakerId != null
  //   ) {
  //     this.setState({activeStep:2});
  //   } else if (
  //     this.state.getFamilyBackgroundStepStatus?.birthParentId != null
  //   ) {
  //     this.setState({activeStep:1});
  //   }
  //  if((this.state.tab+1)>this.state.activeStep){
  //    this.setState({activeStep:this.state.tab+1})
  //  }
  // };
  render() {
    const { tab } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="medical__family_tabs d-none d-xl-block col-3">
            <Tabs
              styleName="familyTab"
              text="Birth Parents"
              steps=""
              stepsAct=""
              fullText="Birth Parents"
              familyTextName="A"
              image=""
              activeImage=""
              tabChange={() => this.changeTab(0)}
              selected={tab === 0}
              isData={
                this.state.getFamilyBackgroundStepStatus?.birthParentId != null
              }
            />
            <Tabs
              styleName="familyTab"
              text="Primary Caretaker's"
              steps=""
              stepsAct=""
              fullText="Primary Caretaker's"
              familyTextName="B"
              image=""
              activeImage=""
              tabChange={() => this.changeTab(1)}
              selected={tab === 1}
              isData={
                this.state.getFamilyBackgroundStepStatus?.primaryCareTakerId !=
                null
              }
            />
            <Tabs
              styleName="familyTab"
              text="Siblings & Birth Orders"
              steps=""
              stepsAct=""
              fullText="Siblings & Birth Orders"
              familyTextName="C"
              image=""
              activeImage=""
              tabChange={() => this.changeTab(2)}
              selected={tab === 2}
              isData={
                this.state.getFamilyBackgroundStepStatus?.siblingId != null
              }
            />
            <Tabs
              styleName="familyTab"
              text="Home Composition"
              steps=""
              stepsAct=""
              fullText="Home Composition"
              familyTextName="D"
              image=""
              activeImage=""
              tabChange={() => this.changeTab(3)}
              selected={tab === 3}
              isData={
                this.state.getFamilyBackgroundStepStatus?.homeCompositionId !=
                null
              }
            />
            <Tabs
              styleName="familyTab"
              text="Learning, Behavior, Mental"
              steps=""
              stepsAct=""
              fullText="Learning, Behavior, Mental"
              image=""
              activeImage=""
              familyTextName="E"
              tabChange={() => this.changeTab(4)}
              selected={tab === 4}
              isData={
                this.state.getFamilyBackgroundStepStatus?.learningId != null
              }
            />
            <Tabs
              styleName="familyTab"
              text="Family Transition"
              steps=""
              stepsAct=""
              fullText="Family Transition"
              familyTextName="F"
              image=""
              activeImage=""
              tabChange={() => this.changeTab(5)}
              selected={tab === 5}
              isData={
                this.state.getFamilyBackgroundStepStatus?.familyTransitionId !=
                null
              }
            />
          </div>
          <div className="medical__family_content col">
            {tab === 0 && (
              <BirthParent
                nextTabChange={() => this.changeTab(1)}
                patientId={this.props.patientId}
                parentId={this.props.parentId}
                parentTabChange={this.props.parentTab}
                isFormSave={this.props.isFormSave}
                familyBackGroundStepUpdate={
                  this.familyBackGroundStepUpdateHandler
                }
                isBirthHistoryOptional={this.state.getFamilyBackgroundStepStatus?.isBirthHistoryOptional}
              />
            )}
            {tab === 1 && (
              <PrimaryCareTaker
                previousTabChange={() => this.changeTab(0)}
                nextTabChange={() => this.changeTab(2)}
                patientId={this.props.patientId}
                isFormSave={this.props.isFormSave}
                familyBackGroundStepUpdate={
                  this.familyBackGroundStepUpdateHandler
                }
              />
            )}
            {tab === 2 && (
              <MedicalSiblingsAndBirthOrder
                patientId={this.props.patientId}
                nextTabChange={() => this.changeTab(3)}
                previousTabChange={() => this.changeTab(1)}
                isFormSave={this.props.isFormSave}
                familyBackGroundStepUpdate={
                  this.familyBackGroundStepUpdateHandler
                }
              />
            )}
            {tab === 3 && (
              <MedicalHomeComposition
                patientId={this.props.patientId}
                nextTabChange={() => this.changeTab(4)}
                previousTabChange={() => this.changeTab(2)}
                isFormSave={this.props.isFormSave}
                familyBackGroundStepUpdate={
                  this.familyBackGroundStepUpdateHandler
                }
              />
            )}
            {tab === 4 && (
              <MedicalLearningBehavior
                patientId={this.props.patientId}
                nextTabChange={() => this.changeTab(5)}
                previousTabChange={() => this.changeTab(3)}
                isFormSave={this.props.isFormSave}
                familyBackGroundStepUpdate={
                  this.familyBackGroundStepUpdateHandler
                }
              />
            )}
            {tab === 5 && (
              <FamilyTransition
                patientId={this.props.patientId}
                nextTabChange={this.props.nextTabChange}
                previousTabChange={() => this.changeTab(4)}
                isFormSave={this.props.isFormSave}
                familyBackGroundStepUpdate={
                  this.familyBackGroundStepUpdateHandler
                }
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default MedicalFamilyForm;
