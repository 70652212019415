import React, { Fragment } from "react";
import { parseJsonText } from "typescript";
import { HttpResponse } from "../../../../core";
import { PregnancyModel } from "../../../../models/birthHistory/pregnancy.model";
import { BirthHistoryService } from "../../../../services/birthHistory/birth-history.service";
import { ShowSuccessMessage } from "../../../../shared/helpers";
import PregnancyForm from "./medicalPregnancy";

interface IState {
  patientId: string;
  isLoading: boolean;
  pregnancy: PregnancyModel;
  isFormSave?: Function;
  birthHistoryStepUpdate?: Function;
  isBirthHistoryOptional: boolean;
}
export class MedicalPregnancy extends React.Component<any, IState> {
  private birthHistoryService: BirthHistoryService;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.birthHistoryService = new BirthHistoryService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      patientId: this.props.patientId,
      pregnancy: {
        patientId: this.props.patientId,
        isTroubleGettingPregnant: false,
        isUsedContraceptionPriorToPregnancy: false,
        isPlannedPregnancy: false,
        isUsedLegalDrugsOrOtherMedication: false,
        isAnemia: false,
        isKidneyProblem: false,
        isGermanMeasles: false,
        isDiabetes: false,
        isSevereEmotionalProblems: false,
        isHighBloodPressure: false,
        isExperienceMiscarriagePriorToPregnancy: false,
        isHighFever: false,
        isExperienceMiscarriageAfterToPregnancy: false,
        isVaginalInfection: false,
        comments: "",
      } as PregnancyModel,
      isBirthHistoryOptional: this.props.isBirthHistoryOptional,
    };
    return initialState;
  }
  componentDidMount() {
    this.loadData();
    window.scroll(0, 0);
  }

  postData = (data) => {
    console.log("postData: ", data)
    let pregnancyPostData = data.pregnancy;
    if (this.state.isLoading) {
      return;
      }
      if (data.isAutoSave) {
          this.setLoading(false);
      }
      else {
          this.setLoading(true);
      }
    this.birthHistoryService
      .postPregnancy(pregnancyPostData)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
          this.state.pregnancy.id=res.result;
        }
        this.setLoading(false);
        if (!data.isAutoSave) {
          ShowSuccessMessage("Data Saved Successfully.");
          this.props.nextTabChange();
        }
        this.props.isFormSave();
        if (!data.isAutoSave) {
          this.loadData(data.isAutoSave);
        }
      })
      .catch((ex) => {
        this.setLoading(false);
      });
  };
  parentTabChange = (tab: any) => {
    this.props.parentTabChange(tab);
  };

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }
    loadData = (isAutoSave?: any) => {
        if (isAutoSave) {
            this.setLoading(false);
        }
        else {
            this.setLoading(true);
        }
    this.birthHistoryService
      .getPregnancyDetail(this.props.patientId)
      .then((res: HttpResponse<PregnancyModel>) => {
        this.setLoading(false);
        if (res && res.result) {
          this.props.birthHistoryStepUpdate(1,res.result.id);
          if (isAutoSave) {
            let p = this.state.pregnancy;
            p.id = res.result.id;
            p.patientId = res.result.patientId;
            this.setState({
              pregnancy: p,
            });
          } else {
            this.setState({
              pregnancy: res.result,
            });
          }
        }
      });
  };
  assignModel = (data: any) => {
    this.setState({
      pregnancy: data.pregnancy,
    });
    
  };

  render() {
    return (
      <Fragment>
        <PregnancyForm
          onSubmit={this.postData}
          isSaving={this.state.isLoading}
          patientId={this.props.patientId}
          assignModel={this.assignModel}
          pregnancy={this.state.pregnancy}
          parentTabChange={this.parentTabChange}
          isBirthHistoryOptional={this.props.isBirthHistoryOptional}
        />
      </Fragment>
    );
  }
}
