import React from "react";
export class LearnMore extends React.Component {

    render() {
        return (<div className="learmore">
            <div className="text-center learmore_message">This documentation will be provided by ACET when available.<br />
        For more information on the ADHD Center, visit: <br /></div>
            <a className="learmore_link" href="https://my.clevelandclinic.org/pediatrics/departments/behavioral-health/adhd-center" target="_blank">
             https://my.clevelandclinic.org/pediatrics/departments/behavioral-health/adhd-center 
            </a>
            </div>);
    }
}

export default LearnMore;
