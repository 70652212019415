import React, { Fragment } from "react";
import { HttpResponse } from "../../../../../../core";
import { FamilyBackgroundDetailModel } from "../../../../../../models/familyBackgroundDetail/family-background-detail.model";
import { FamilyBackgroundDetailService } from "../../../../../../services/familyBackgroundDetail/family-background-detail.service";
import FamilyBackgroundForm from "./familyBackgroundForm";

interface IState {
    patientId: string;
    isLoading: boolean;
    familyBackgroundDetail: FamilyBackgroundDetailModel;
  }
  export class FamilyBackgroundTab extends React.Component<any, IState> {
    private familyBackgroundDetailService: FamilyBackgroundDetailService;
  
    constructor(props) {
      super(props);
      this.state = this.getInitialState();
      this.familyBackgroundDetailService = new FamilyBackgroundDetailService();
    }
    private getInitialState() {
      let initialState: IState = {
        isLoading: false,
        patientId: this.props.patientId,
        familyBackgroundDetail: {} as FamilyBackgroundDetailModel
      };
      return initialState;
    }
    componentDidMount() {
      if(this.props.patientId!=null){
        this.loadData();
     }  
    }
  
    previousTabChange = () => {
      this.props.previousTabChange();
    };
  
    private setLoading(loading: boolean) {
      this.setState({
        isLoading: loading,
      });
    }
    loadData = () => {
      this.setLoading(true);
      this.familyBackgroundDetailService
        .getFamilyBackgroundDetail(this.props.patientId)
        .then((res: HttpResponse<FamilyBackgroundDetailModel>) => {
          if (res && res.result) {
            this.setLoading(false);
            this.setState({
              familyBackgroundDetail: 
              res.result
            });
          }
        });
    };

    render() {
      return (
        <Fragment>
          <FamilyBackgroundForm
            patientId={this.props.patientId}
            isLoading={this.state.isLoading}
            familyBackgroundDetail={this.state.familyBackgroundDetail}
          />
        </Fragment>
      );
    }
  }
  