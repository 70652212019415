import React from "react";
import { Collapse } from "antd";
import { DownSquareOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
//import "antd/dist/antd.css";
import "./styles.css";
type AccordianProps = {
    title: string
    contents: any[]
    index: string
    medicalImg : string
    defaultActiveKey: number
    checkExpandAll: Function
}

const { Panel } = Collapse;

function Accordian(props: AccordianProps) {
  const onClick=(key: any)=>{
    let data={
      index:props.index,
      status: false
    }
    //alert(activePanel);
    if(key==null){
      data.status=false;
    }else{
      data.status=true;
    }
    props.checkExpandAll(data)
    //alert(activePanel);
  }
  return (
    <div className="accordian">
      <Collapse 
        defaultActiveKey={props.defaultActiveKey}
        accordion
        expandIcon={({ isActive }) => <DownSquareOutlined rotate={isActive ? 0 : 180} />}
        className="site-collapse-custom-collapse"
        onChange={onClick}
      >
        <Panel
          header={props.title}
          key={props.index}
          className="panel_header"
          extra={<img src={props.medicalImg} alt="medical" />}
        >
          {props.contents.map((content, index) => (
            <div className="content" key={index}>
              {content}
            </div>
          ))}
        </Panel>
      </Collapse>
    </div>
  );
}

Accordian.defaultProps = {
  title: "Family Background",
  contents: [],
};
export default Accordian;
