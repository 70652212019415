import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AddFamilyTransitionModel } from "../../../../models/familyBackground/familyTransition/add-family-transition.model";
import { Loader } from "../../../../shared/loaders";
import Heading from "../../../heading";
import "../../styles.css";

interface IProps {
  familyTransitionModel: AddFamilyTransitionModel;
  onSubmit: Function;
  isSaving: boolean;
  patientId: string;
  assignModel: Function;
  isReadOnly: boolean;
  previousTabChange: Function;
}
export function AddFamilyTransitionForm(props: IProps) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    formState,
    setValue,
  } = useForm<AddFamilyTransitionModel>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  useEffect(() => {
    setInitialValues();
  }, [props]);
  function setInitialValues() {
    setValue(
      "childChangedSchool",
      props.familyTransitionModel.childChangedSchool
    );
    setValue(
      "childExpSeparationFromCloseFrnds",
      props.familyTransitionModel.childExpSeparationFromCloseFrnds
    );
    setValue(
      "childExpSeparationFromParents",
      props.familyTransitionModel.childExpSeparationFromParents
    );
    setValue(
      "childHadProlongedIllness",
      props.familyTransitionModel.childHadProlongedIllness
    );
    setValue("comments", props.familyTransitionModel.comments);
    setValue(
      "deathOfClosedFriend",
      props.familyTransitionModel.deathOfClosedFriend
    );
    setValue(
      "deathOfFamilyMember",
      props.familyTransitionModel.deathOfFamilyMember
    );
    setValue(
      "familyChangedResidence",
      props.familyTransitionModel.familyChangedResidence
    );
    setValue(
      "familyChangedResidenceNo",
      props.familyTransitionModel.familyChangedResidenceNo
    );
    setValue(
      "familyMemberExpIllness",
      props.familyTransitionModel.familyMemberExpIllness
    );
    setValue("parentChangedJob", props.familyTransitionModel.parentChangedJob);
    setValue(
      "parentChangedJobNo",
      props.familyTransitionModel.parentChangedJobNo
    );
    setValue("parentDivorced", props.familyTransitionModel.parentDivorced);
    setValue(
      "parentExpSeparationMartialConflict",
      props.familyTransitionModel.parentExpSeparationMartialConflict
    );
    setValue("siblingLeftHome", props.familyTransitionModel.siblingLeftHome);
  }
  let familyTransitionModel = props.familyTransitionModel;
  const formSubmit = (data) => {
    familyTransitionModel.patientId = props.patientId;
    familyTransitionModel.familyChangedResidenceNo =
      data.familyChangedResidenceNo;
    familyTransitionModel.childExpSeparationFromParents =
      data.childExpSeparationFromParents;
    familyTransitionModel.parentChangedJobNo = data.parentChangedJobNo;
    familyTransitionModel.comments = data.comments;
    props.assignModel({ familyTransitionModel: familyTransitionModel });
    if (checkForError()) {
      return false;
    }
    props.onSubmit({
      familyTransitionModel: familyTransitionModel,
      isAutoSave: false,
    });
  };
  const checkForError = () => {
    let hasError = false;
    familyTransitionModel.familyChangedResidenceNoError = "";
    familyTransitionModel.parentChangedJobNoError = "";
    if (
      familyTransitionModel.familyChangedResidence &&
      (familyTransitionModel.familyChangedResidenceNo == undefined ||
        familyTransitionModel.familyChangedResidenceNo <= 0)
    ) {
      familyTransitionModel.familyChangedResidenceNoError =
        "Please fill no. of times residence changed.";
      hasError = true;
    }
    if (
      familyTransitionModel.parentChangedJob &&
      (familyTransitionModel.parentChangedJobNo == undefined ||
        familyTransitionModel.parentChangedJobNo <= 0)
    ) {
      familyTransitionModel.parentChangedJobNoError =
        "Please fill no. of times job changed.";
      hasError = true;
    }
    props.assignModel({ familyTransitionModel: familyTransitionModel });
    return hasError;
  };
  const handleKeyUp = (event) => {
    setValuesInModel();
    if (event.key === "Tab") {
      saveDataOnTabChange();
    }
  };
  const handleOnFocus = (event) => {
    setValuesInModel();
    saveDataOnTabChange();
  };
  const handleOnBlur = () => {
    setValuesInModel();
    saveDataOnTabChange();
  };

  const saveDataOnTabChange = () => {
    if (!props.isSaving) {
      familyTransitionModel.patientId = props.patientId;
      props.onSubmit({
        familyTransitionModel: familyTransitionModel,
        isAutoSave: true,
      });
    }
  };

  function setValuesInModel() {
    let model = getValues();
    familyTransitionModel = Object.assign(familyTransitionModel, model);
  }
  const isSelected = (type: number, event) => {
    setValuesInModel();
    switch (type) {
      case 1: {
        familyTransitionModel.parentDivorced = event.target.checked;
        break;
      }
      case 2: {
        familyTransitionModel.familyMemberExpIllness = event.target.checked;
        break;
      }
      case 3: {
        familyTransitionModel.deathOfFamilyMember = event.target.checked;
        break;
      }
      case 4: {
        familyTransitionModel.siblingLeftHome = event.target.checked;
        break;
      }
      case 5: {
        familyTransitionModel.childChangedSchool = event.target.checked;
        break;
      }
      case 6: {
        familyTransitionModel.childExpSeparationFromParents =
          event.target.checked;
        break;
      }
      case 7: {
        familyTransitionModel.childHadProlongedIllness = event.target.checked;
        break;
      }
      case 8: {
        familyTransitionModel.parentExpSeparationMartialConflict =
          event.target.checked;
        break;
      }
      case 9: {
        familyTransitionModel.deathOfClosedFriend = event.target.checked;
        break;
      }
      case 10: {
        familyTransitionModel.childExpSeparationFromCloseFrnds =
          event.target.checked;
        break;
      }
      case 11: {
        familyTransitionModel.familyChangedResidence = event.target.checked;
        if (!event.target.checked) {
          familyTransitionModel.familyChangedResidenceNo = 0;
          familyTransitionModel.familyChangedResidenceNoError = "";
        }
        break;
      }
      case 12: {
        familyTransitionModel.parentChangedJob = event.target.checked;
        if (!event.target.checked) {
          familyTransitionModel.parentChangedJobNo = 0;
          familyTransitionModel.parentChangedJobNoError = "";
        }
        break;
      }
      default: {
        break;
      }
    }
    props.assignModel({ familyTransitionModel: familyTransitionModel });
    saveDataOnTabChange();
  };
  const maxLengthCheck = (age) => {
    if (age.target.value.length > age.target.maxLength) {
      age.target.value = age.target.value.slice(0, age.target.maxLength);
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(formSubmit)}>
        <div>
          <Heading heading="Family Transition" subHeading="" styleName="" />
          <p>
            Please check the events or experiences that occurred in the last
            three years.
          </p>

          <div className="form">
            <div className="row">
              <div className="medical-group col-12 col-md-6">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="parent-divorced"
                  //defaultChecked={familyTransitionModel.parentDivorced}
                  {...register("parentDivorced")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(1, e)}
                />
                <label
                  htmlFor="parent-divorced"
                  className={
                    familyTransitionModel.parentDivorced === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                >
                  Parents divorced/separarted
                </label>
              </div>
              <div className="medical-group col-12 col-md-6">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="familyMemberExpIllness"
                  //defaultChecked={familyTransitionModel.familyMemberExpIllness}
                  {...register("familyMemberExpIllness")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(2, e)}
                />
                <label
                  htmlFor="familyMemberExpIllness"
                  className={
                    familyTransitionModel.familyMemberExpIllness === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                >
                  Family member experienced prolonged illness
                </label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-6">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="deathOfFamilyMember"
                  //defaultChecked={familyTransitionModel.deathOfFamilyMember}
                  {...register("deathOfFamilyMember")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(3, e)}
                />
                <label
                  htmlFor="deathOfFamilyMember"
                  className={
                    familyTransitionModel.deathOfFamilyMember === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                >
                  Death of family member
                </label>
              </div>
              <div className="medical-group col-12 col-md-6">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="siblingLeftHome"
                  //defaultChecked={familyTransitionModel.siblingLeftHome}
                  {...register("siblingLeftHome")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(4, e)}
                />
                <label
                  htmlFor="siblingLeftHome"
                  className={
                    familyTransitionModel.siblingLeftHome === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                >
                  Sibling left home
                </label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-6">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="childChangedSchool"
                  //defaultChecked={familyTransitionModel.childChangedSchool}
                  {...register("childChangedSchool")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(5, e)}
                />
                <label
                  htmlFor="childChangedSchool"
                  className={
                    familyTransitionModel.childChangedSchool === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                >
                  Child changed schools
                </label>
              </div>
              <div className="medical-group col-12 col-md-6">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="childExpSeparationFromParents"
                  //defaultChecked={familyTransitionModel.childExpSeparationFromParents}
                  {...register("childExpSeparationFromParents")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(6, e)}
                />
                <label
                  htmlFor="childExpSeparationFromParents"
                  className={
                    familyTransitionModel.childExpSeparationFromParents === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                >
                  Child experienced prolonged separation from parents
                </label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-6">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="childHadProlongedIllness"
                  //defaultChecked={familyTransitionModel.childHadProlongedIllness}
                  {...register("childHadProlongedIllness")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(7, e)}
                />
                <label
                  htmlFor="childHadProlongedIllness"
                  className={
                    familyTransitionModel.childHadProlongedIllness === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                >
                  Child had a prolonged illness
                </label>
              </div>
              <div className="medical-group col-12 col-md-6">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="parentExpSeparationMartialConflict"
                  //defaultChecked={familyTransitionModel.parentExpSeparationMartialConflict}
                  {...register("parentExpSeparationMartialConflict")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(8, e)}
                />
                <label
                  htmlFor="parentExpSeparationMartialConflict"
                  className={
                    familyTransitionModel.parentExpSeparationMartialConflict ===
                    true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                >
                  Parents experienced significant marital conflict
                </label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-6">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="deathOfClosedFriend"
                  //defaultChecked={familyTransitionModel.deathOfClosedFriend}
                  {...register("deathOfClosedFriend")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(9, e)}
                />
                <label
                  htmlFor="deathOfClosedFriend"
                  className={
                    familyTransitionModel.deathOfClosedFriend === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                >
                  Death of a close friend
                </label>
              </div>
              <div className="medical-group col-12 col-md-6">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="childExpSeparationFromCloseFrnds"
                  //defaultChecked={familyTransitionModel.childExpSeparationFromCloseFrnds}
                  {...register("childExpSeparationFromCloseFrnds")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(10, e)}
                />
                <label
                  htmlFor="childExpSeparationFromCloseFrnds"
                  className={
                    familyTransitionModel.childExpSeparationFromCloseFrnds ===
                    true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                >
                  Child experienced prolonged separation from close friends
                </label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-6 mt-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="familyChangedResidence"
                  //defaultChecked={familyTransitionModel.familyChangedResidence}
                  {...register("familyChangedResidence")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(11, e)}
                />
                <label
                  htmlFor="familyChangedResidence"
                  className={
                    familyTransitionModel.familyChangedResidence === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                >
                  Family changed residence - if so, number of times in the last
                  3 years?
                </label>
              </div>
              <div className="medical-group col-12 col-md-6">
                  <input
                    type="number"
                    className="form-control mb-1"
                    defaultValue={
                      familyTransitionModel.familyChangedResidenceNo == 0
                        ? ""
                        : familyTransitionModel.familyChangedResidenceNo
                    }
                    {...register("familyChangedResidenceNo")}
                    onFocus={handleOnFocus}
                    disabled={!familyTransitionModel.familyChangedResidence}
                    placeholder="No. of times"
                    style={{ marginTop: "20px" }}
                    maxLength={3}
                    onInput={maxLengthCheck}
                    onKeyDown={(e) => e.keyCode === 69 && e.preventDefault()}
                    //  onChange={(e) => setChangeResidence(e.target.defaultValue)}
                  />
                  {familyTransitionModel.familyChangedResidenceNoError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {familyTransitionModel.familyChangedResidenceNoError}
                    </div>
                  )}
                </div>
              
            </div>
            
            <div className="row">
              <div className="medical-group col-12 col-md-6 mt-md-4">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="parentChangedJob"
                  //defaultChecked={familyTransitionModel.parentChangedJob}
                  {...register("parentChangedJob")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(12, e)}
                />
                <label
                  htmlFor="parentChangedJob"
                  className={
                    familyTransitionModel.parentChangedJob === true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                >
                  Parent(s) changed job - if so, number of times in the last 3
                  years?
                </label>
                </div>
                <div className="medical-group col-12 col-md-6">
                  <input
                    type="number"
                    className="form-control mb-1"
                    defaultValue={
                      familyTransitionModel.parentChangedJobNo == 0
                        ? ""
                        : familyTransitionModel.parentChangedJobNo
                    }
                    {...register("parentChangedJobNo")}
                    onFocus={handleOnFocus}
                    placeholder="No. of times"
                    style={{ marginTop: "20px" }}
                    disabled={!familyTransitionModel.parentChangedJob}
                    maxLength={3}
                    onInput={maxLengthCheck}
                    onKeyDown={(e) => e.keyCode === 69 && e.preventDefault()}
                  />
                  {familyTransitionModel.parentChangedJobNoError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {familyTransitionModel.parentChangedJobNoError}
                    </div>
                  )}
                </div>
              </div>
            </div>
            
            <div className="row">
              <div className="medical-group col-12 col-md-6 optional-field textarea_group">
                <label className="form-label">Comments</label>
                <span className="optional">Optional</span>
                <textarea
                  className="form-control mb-1 textarea"
                  //defaultValue={familyTransitionModel.comments}
                  {...register("comments")}
                  onFocus={handleOnFocus}
                  placeholder="Please provide any additional information."
                ></textarea>
              </div>
            </div>

          <div className="btnContainer">
            {!props.isSaving && (
              <Button
                type="default"
                size="large"
                className="back_btn"
                onClick={() => props.previousTabChange(0)}
              >
                <i className="fa fa-long-arrow-left"></i> Back
              </Button>
            )}
            {!props.isSaving && (
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="btn_add"
              >
                Save <i className="fa fa-long-arrow-right"></i>
              </Button>
            )}
            {props.isSaving && (
              <div style={{ height: "60px" }}>
                <Loader
                  loading={props.isSaving}
                  marginBottom="0px"
                  marginTop="8px"
                  width="368px"
                ></Loader>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
