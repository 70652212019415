import { AppConfig, HttpWrapper } from "../../core";
import { BehavioralProblemPostModel } from "../../models/behaviourProfile/behaviour-problem-post.model";
import { BehaviouralAssetsModel } from "../../models/behaviourProfile/behavioural-assets.model";

export class BehaviourProfileService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }
  getBehavioralProblems(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint +
        "behaviourprofile/behavioral-problem/detail/" +
        patientId
    );
  }

  postBehavioralProblem(model: BehavioralProblemPostModel) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "behaviourprofile/behavioral-problem/create",
      model
    );
  }

  // behavioral-assets start
  getBehavioralAssests(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint +
        "behaviourprofile/behavioral-assets/detail/" +
        patientId
    );
  }

  postBehavioralAssests(model: BehaviouralAssetsModel) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "behaviourprofile/behavioral-assets/create",
      model
    );
  }
  //behavioral-assets end
  getBehaviourProfileDetail(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint + "patientDetail/behavior-profile-detail/" + patientId
    );
  }
  getBehaviourProfileStepStatus(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint +
        "behaviourprofile/getBehaviourProfileStepStatus/" +
        patientId
    );
  }
}
