import { AppConfig, HttpWrapper } from "../../core";
import { AddendumData, downloadFinalReportModel, FinalReportSaveDataModel } from "../../models/report/final-report-save-data";
import { BaselineVisitReportSaveDataModel, DownloadBaselineVisitReportModel } from "../../models/report/visit-report-save-data";
import { MidPointVisitReportSaveDataModel, DownloadMidPointVisitReportModel } from "../../models/report/mid-week-report-save-data";
import { FinalVisitReportSaveDataModel, DownloadFinalVisitReportModel } from "../../models/report/final-visit-report-save-data";

export class FinalReportDataService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }
  
  getFinalReportData(patientId: string) {
    return this.wrapper.get<FinalReportSaveDataModel>(
      AppConfig.apiEndpoint +
        "report/finalreport/" +
        patientId
    );
  }
  postFinalReportData(request: FinalReportSaveDataModel) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "report/finalreport/save",
      request);
  }
  downloadFinalReport(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint +
        "report/finalreport/download/" +
        patientId
    );
  }
  getFinalReportHtml(request: downloadFinalReportModel) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "report/finalreport/html",
      request);
  }
  postFinalReportAddendumData(request: AddendumData) {
    return this.wrapper.post<AddendumData>(
      AppConfig.apiEndpoint + "report/finalreport/addendum/add",
      request);
  }
  getBaselineVisitReportData(patientId: string) {
    return this.wrapper.get<BaselineVisitReportSaveDataModel>(
      AppConfig.apiEndpoint +
        "report/visitreport/baseline/" +
        patientId
    );
  }
  postBaselineVisitReportData(request: BaselineVisitReportSaveDataModel) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "report/visitreport/baseline/save",
      request);
  }

  postBaselineVisitReportAddendumData(request: AddendumData) {
    return this.wrapper.post<AddendumData>(
      AppConfig.apiEndpoint + "report/visitreport/baseline/addendum/add",
      request);
  }
  postMidPointVisitReportData(request: MidPointVisitReportSaveDataModel) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "report/visitreport/midpoint/save",
      request);
  }

  getBaslineReportHtml(request: DownloadBaselineVisitReportModel) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "report/visitreport/baseline/html",
      request);
  }

  postMidPointVisitReportAddendumData(request: AddendumData) {
    return this.wrapper.post<AddendumData>(
        AppConfig.apiEndpoint + "report/visitreport/midpoint/addendum/add",
      request);
  }
  getMidPointVisitReportData(patientId: string) {
    return this.wrapper.get<MidPointVisitReportSaveDataModel>(
      AppConfig.apiEndpoint +
        "report/visitreport/midpoint/" +
        patientId
    );
    }
    getMidPointReportHtml(request: DownloadMidPointVisitReportModel) {
        return this.wrapper.post<any>(
            AppConfig.apiEndpoint + "report/visitreport/midpoint/html",
            request);
    }
    postFinalVisitReportData(request: FinalVisitReportSaveDataModel) {
        return this.wrapper.post<any>(
            AppConfig.apiEndpoint + "report/visitreport/final/save",
            request);
    }

    postFinalVisitReportAddendumData(request: AddendumData) {
        return this.wrapper.post<AddendumData>(
            AppConfig.apiEndpoint + "report/visitreport/final/addendum/add",
            request);
    }
    getFinalVisitReportData(patientId: string) {
        return this.wrapper.get<FinalVisitReportSaveDataModel>(
            AppConfig.apiEndpoint +
            "report/visitreport/final/" +
            patientId
        );
    }
    getFinalVisitReportHtml(request: DownloadFinalVisitReportModel) {
        return this.wrapper.post<any>(
            AppConfig.apiEndpoint + "report/visitreport/final/html",
            request);
    }
}