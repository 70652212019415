import React from "react";
import Heading from "../../../heading";
import { Button } from "antd";
import "../../styles.css";
import { PregnancyModel } from "../../../../models/birthHistory/pregnancy.model";
import { useForm } from "react-hook-form";
import { Loader } from "../../../../shared/loaders";
interface IProps {
  pregnancy: PregnancyModel;
  onSubmit: Function;
  isSaving: boolean;
  patientId: string;
  assignModel: Function;
  parentTabChange: Function;
  isBirthHistoryOptional: boolean;
}
export default function PregnancyForm(props: IProps) {
  let pregnancy = props.pregnancy;
  const { register, handleSubmit, control, getValues, formState, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
  });
  const formSubmit = () => {
    if (checkForError()) {
      saveDataOnTabChange();
      return false;
    }
    props.onSubmit({ pregnancy: pregnancy, isAutoSave: false });
  };
  const checkForError = () => {
    let hasError = false;
    pregnancy.isTroubleGettingPregnantError = "";
    pregnancy.isUsedContraceptionPriorToPregnancyError = "";
    pregnancy.isPlannedPregnancyError = "";
    pregnancy.isUsedLegalDrugsOrOtherMedicationError = "";
    pregnancy.isAnemiaError = "";
    pregnancy.isKidneyProblemError = "";
    pregnancy.isGermanMeaslesError = "";
    pregnancy.isDiabetesError = "";
    pregnancy.isSevereEmotionalProblemsError = "";
    pregnancy.isHighBloodPressureError = "";
    pregnancy.isExperienceMiscarriagePriorToPregnancyError = "";
    pregnancy.isHighFeverError = "";
    pregnancy.isExperienceMiscarriageAfterToPregnancyError = "";
    pregnancy.isVaginalInfectionError = "";
    if (pregnancy.isTroubleGettingPregnant == null && !props.isBirthHistoryOptional) {
      pregnancy.isTroubleGettingPregnantError = "Please select an option.";
      hasError = true;
    }
    if (pregnancy.isUsedContraceptionPriorToPregnancy == null && !props.isBirthHistoryOptional) {
      pregnancy.isUsedContraceptionPriorToPregnancyError = "Please select an option.";
      hasError = true;
    }
    if (pregnancy.isPlannedPregnancy == null && !props.isBirthHistoryOptional) {
      pregnancy.isPlannedPregnancyError = "Please select an option.";
      hasError = true;
    }
    if (pregnancy.isUsedLegalDrugsOrOtherMedication == null && !props.isBirthHistoryOptional) {
      pregnancy.isUsedLegalDrugsOrOtherMedicationError = "Please select an option.";
      hasError = true;
    }
    if (pregnancy.isAnemia == null && !props.isBirthHistoryOptional) {
      pregnancy.isAnemiaError = "Please select an option.";
      hasError = true;
    }
    if (pregnancy.isKidneyProblem == null && !props.isBirthHistoryOptional) {
      pregnancy.isKidneyProblemError = "Please select an option.";
      hasError = true;
    }
    if (pregnancy.isGermanMeasles == null && !props.isBirthHistoryOptional) {
      pregnancy.isGermanMeaslesError = "Please select an option.";
      hasError = true;
    }
    if (pregnancy.isDiabetes == null && !props.isBirthHistoryOptional) {
      pregnancy.isDiabetesError = "Please select an option.";
      hasError = true;
    }
    if (pregnancy.isSevereEmotionalProblems == null && !props.isBirthHistoryOptional) {
      pregnancy.isSevereEmotionalProblemsError = "Please select an option.";
      hasError = true;
    }
    if (pregnancy.isHighBloodPressure == null && !props.isBirthHistoryOptional) {
      pregnancy.isHighBloodPressureError = "Please select an option.";
      hasError = true;
    }
    if (pregnancy.isExperienceMiscarriagePriorToPregnancy == null && !props.isBirthHistoryOptional) {
      pregnancy.isExperienceMiscarriagePriorToPregnancyError = "Please select an option.";
      hasError = true;
    }
    if (pregnancy.isHighFever == null && !props.isBirthHistoryOptional) {
      pregnancy.isHighFeverError = "Please select an option.";
      hasError = true;
    }
    if (pregnancy.isExperienceMiscarriageAfterToPregnancy == null && !props.isBirthHistoryOptional) {
      pregnancy.isExperienceMiscarriageAfterToPregnancyError = "Please select an option.";
      hasError = true;
    }
    if (pregnancy.isVaginalInfection == null && !props.isBirthHistoryOptional) {
      pregnancy.isVaginalInfectionError = "Please select an option.";
      hasError = true;
    }
    return hasError;
  };
  const isSelected = (type: number, isChecked) => {
    switch (type) {
      case 1: {
        pregnancy.isTroubleGettingPregnant = isChecked;
        props.assignModel({ pregnancy: pregnancy });
        break;
      }
      case 2: {
        pregnancy.isUsedContraceptionPriorToPregnancy = isChecked;
        props.assignModel({ pregnancy: pregnancy });
        break;
      }
      case 3: {
        pregnancy.isPlannedPregnancy = isChecked;
        props.assignModel({ pregnancy: pregnancy });
        break;
      }
      case 4: {
        pregnancy.isUsedLegalDrugsOrOtherMedication = isChecked;
        props.assignModel({ pregnancy: pregnancy });
        break;
      }
      case 5: {
        pregnancy.isAnemia = isChecked;
        props.assignModel({ pregnancy: pregnancy });
        break;
      }
      case 6: {
        pregnancy.isKidneyProblem = isChecked;
        props.assignModel({ pregnancy: pregnancy });
        break;
      }
      case 7: {
        pregnancy.isGermanMeasles = isChecked;
        props.assignModel({ pregnancy: pregnancy });
        break;
      }
      case 8: {
        pregnancy.isDiabetes = isChecked;
        props.assignModel({ pregnancy: pregnancy });
        break;
      }
      case 9: {
        pregnancy.isSevereEmotionalProblems = isChecked;
        props.assignModel({ pregnancy: pregnancy });
        break;
      }
      case 10: {
        pregnancy.isHighBloodPressure = isChecked;
        props.assignModel({ pregnancy: pregnancy });
        break;
      }
      case 11: {
        pregnancy.isExperienceMiscarriagePriorToPregnancy = isChecked;
        props.assignModel({ pregnancy: pregnancy });
        break;
      }
      case 12: {
        pregnancy.isHighFever = isChecked;
        props.assignModel({ pregnancy: pregnancy });
        break;
      }
      case 13: {
        pregnancy.isExperienceMiscarriageAfterToPregnancy = isChecked;
        props.assignModel({ pregnancy: pregnancy });
        break;
      }
      case 14: {
        pregnancy.isVaginalInfection = isChecked;
        props.assignModel({ pregnancy: pregnancy });
        break;
      }
      default: {
        break;
      }
    }
    saveDataOnTabChange();
  };
  const handleKeyUp = (event) => {
    pregnancy.comments = event.target.value;
    props.assignModel({ pregnancy: pregnancy });
    saveDataOnTabChange();
  };
  const handleOnBlur = () => {
    saveDataOnTabChange();
  };
  const saveDataOnTabChange = () => {
    if (!props.isSaving) {
      props.onSubmit({ pregnancy: pregnancy, isAutoSave: true });
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(formSubmit)}>
        <div>
          <Heading
            heading="Pregnancy (Respond to all items)"
            subHeading="The question in this section pertain to the birth mother's pregnancy with this child:"
            styleName=""
          />
          <div className="form">
            <div className="row">
              <div className="medical-group btn-select col-12 col-md-4 mt-3">
                <label className="text-center">Trouble getting pregnant</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(1, true)}
                    className={
                      pregnancy.isTroubleGettingPregnant == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(1, false)}
                    className={
                      pregnancy.isTroubleGettingPregnant == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {pregnancy.isTroubleGettingPregnantError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {pregnancy.isTroubleGettingPregnantError}
                    </div>
                  )}
              </div>
              <div className="medical-group btn-select col-12 col-md-4 mt-3 ">
                <label className="text-center">Used contraception prior to pregnancy</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(2, true)}
                    className={
                      pregnancy.isUsedContraceptionPriorToPregnancy == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(2, false)}
                    className={
                      pregnancy.isUsedContraceptionPriorToPregnancy == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                 
                </div>
                {pregnancy.isUsedContraceptionPriorToPregnancyError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {pregnancy.isUsedContraceptionPriorToPregnancyError}
                    </div>
                  )}
              </div>
              <div className="medical-group btn-select col-12 col-md-4 mt-3">
                <label className="text-center">Planned pregnancy</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(3, true)}
                    className={
                      pregnancy.isPlannedPregnancy == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(3, false)}
                    className={
                      pregnancy.isPlannedPregnancy == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {pregnancy.isPlannedPregnancyError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {pregnancy.isPlannedPregnancyError}
                    </div>
                  )}
              </div>
            </div>

            <div className="row">
              
              <div className="medical-group btn-select col-12 col-md-4 mt-3">
                <label className="text-center">Used legal drugs or other medication</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(4, true)}
                    className={
                      pregnancy.isUsedLegalDrugsOrOtherMedication == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(4, false)}
                    className={
                      pregnancy.isUsedLegalDrugsOrOtherMedication == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {pregnancy.isUsedLegalDrugsOrOtherMedicationError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {pregnancy.isUsedLegalDrugsOrOtherMedicationError}
                    </div>
                  )}
              </div>
              <div className="medical-group btn-select col-12 col-md-4 mt-3">
                <label className="text-center">Anemia (low iron)</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(5, true)}
                    className={
                      pregnancy.isAnemia == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(5, false)}
                    className={
                      pregnancy.isAnemia == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {pregnancy.isAnemiaError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {pregnancy.isAnemiaError}
                    </div>
                  )}
              </div>
              <div className="medical-group btn-select col-12 col-md-4 mt-3">
                <label className="text-center">Kidney problems</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(6, true)}
                    className={
                      pregnancy.isKidneyProblem == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(6, false)}
                    className={
                      pregnancy.isKidneyProblem == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {pregnancy.isKidneyProblemError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {pregnancy.isKidneyProblemError}
                    </div>
                  )}
              </div>
            </div>
            <div className="row">
            <div className="medical-group btn-select col-12 col-md-4 mt-3">
                <label className="text-center">German measles</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(7, true)}
                    className={
                      pregnancy.isGermanMeasles == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(7, false)}
                    className={
                      pregnancy.isGermanMeasles == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {pregnancy.isGermanMeaslesError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {pregnancy.isGermanMeaslesError}
                    </div>
                  )}
              </div>
              <div className="medical-group btn-select col-12 col-md-4 mt-3">
                <label className="text-center">Diabetes</label>
                <div className="btn-group btnGender">
                  <button
                    type="button"
                    onClick={() => isSelected(8, true)}
                    className={
                      pregnancy.isDiabetes == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(8, false)}
                    className={
                      pregnancy.isDiabetes == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {pregnancy.isDiabetesError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {pregnancy.isDiabetesError}
                    </div>
                  )}
              </div>
              <div className="medical-group btn-select col-12 col-md-4 mt-3">
                <label className="text-center">Several emotional problems</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(9, true)}
                    className={
                      pregnancy.isSevereEmotionalProblems == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(9, false)}
                    className={
                      pregnancy.isSevereEmotionalProblems == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {pregnancy.isSevereEmotionalProblemsError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {pregnancy.isSevereEmotionalProblemsError}
                    </div>
                  )}
              </div>
            </div>
            <div className="row">
              <div className="medical-group btn-select col-12 col-md-4 mt-3">
                <label className="text-center">High blood pressure</label>
                <div className="btn-group btnGender">
                  <button
                    type="button"
                    onClick={() => isSelected(10, true)}
                    className={
                      pregnancy.isHighBloodPressure == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(10, false)}
                    className={
                      pregnancy.isHighBloodPressure == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {pregnancy.isHighBloodPressureError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {pregnancy.isHighBloodPressureError}
                    </div>
                  )}
              </div>
              <div className="medical-group btn-select col-12 col-md-4 mt-3">
                <label className="text-center">Experienced miscarriages prior to pregnancy</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(11, true)}
                    className={
                      pregnancy.isExperienceMiscarriagePriorToPregnancy == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(11, false)}
                    className={
                      pregnancy.isExperienceMiscarriagePriorToPregnancy == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {pregnancy.isExperienceMiscarriagePriorToPregnancyError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {pregnancy.isExperienceMiscarriagePriorToPregnancyError}
                    </div>
                  )}
              </div>
              <div className="medical-group btn-select col-12 col-md-4 mt-3">
                <label className="text-center">High fever (103 or higher for three days)</label>
                <div className="btn-group btnGender">
                  <button
                    type="button"
                    onClick={() => isSelected(12, true)}
                    className={
                      pregnancy.isHighFever == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(12, false)}
                    className={
                      pregnancy.isHighFever == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {pregnancy.isHighFeverError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {pregnancy.isHighFeverError}
                    </div>
                  )}
              </div>
            </div>
            <div className="row">
              <div className="medical-group btn-select col-12 col-md-4 mt-3">
                <label className="text-center">Experienced miscarriages after this pregnancy</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(13, true)}
                    className={
                      pregnancy.isExperienceMiscarriageAfterToPregnancy == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(13, false)}
                    className={
                      pregnancy.isExperienceMiscarriageAfterToPregnancy == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {pregnancy.isExperienceMiscarriageAfterToPregnancyError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {pregnancy.isExperienceMiscarriageAfterToPregnancyError}
                    </div>
                  )}
              </div>
              <div className="medical-group btn-select col-12 col-md-4 mt-3">
                <label className="text-center">Vaginal infection/bleeding</label>
                <div className="btn-group btnGender">
                  <button
                    type="button"
                    onClick={() => isSelected(14, true)}
                    className={
                      pregnancy.isVaginalInfection == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(14, false)}
                    className={
                      pregnancy.isVaginalInfection == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {pregnancy.isVaginalInfectionError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {pregnancy.isVaginalInfectionError}
                    </div>
                  )}
              </div>
            </div>

            <div className="medical__birth_formRow5">
              <div className="medical-group optional-field textarea_group">
                <label className="form-label">Comments</label>
                <span className="optional">Optional</span>
                <textarea
                  className="form-control textarea"
                  defaultValue={pregnancy.comments}
                  onBlur={handleKeyUp}
                  placeholder="Please provide any additional information."
                ></textarea>
              </div>
            </div>
          </div>

          <div className="btnContainer">
            {!props.isSaving && (
              <Button
                type="default"
                size="large"
                className="back_btn"
                onClick={() => props.parentTabChange(1)}
              >
                <i className="fa fa-long-arrow-left"></i> Back
              </Button>
            )}
            {!props.isSaving && (
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="btn_add"
                
              >
                Save <i className="fa fa-long-arrow-right"></i>
              </Button>
            )}
            {props.isSaving && <div style={{ height: "60px" }}>
            <Loader
              loading={props.isSaving}
              marginBottom="0px"
              marginTop="8px"
              width="368px"
            ></Loader>
              </div>}
          </div>
        </div>
      </form>
      {/* <Loader loading={props.isSaving} marginBottom="0px" marginTop="8px" width="368px"></Loader> */}
    </div>
  );
}
