import React, { Fragment } from "react";
import { HttpResponse } from "../../../../core";
import { ShowErrorMessage, ShowSuccessMessage } from "../../../../shared/helpers";
import { FamilyBackgroundService } from "../../../../services/familyBackground";
import { AddFamilyTransitionModel } from "../../../../models/familyBackground/familyTransition/add-family-transition.model";
import { AddFamilyTransitionForm } from "./addFamilyTransitionForm";
import { GetFamilyBackgroundStepStatus } from "../../../patient/models/get-family-background-step-status.model";

interface IState {
  isLoading: boolean;
  tab: number;
  isReadonly: boolean;
  reRenderForm: boolean;
  familyTransitionModel: AddFamilyTransitionModel;
  patientId: string;
  isFormSave?: Function;
  getFamilyBackgroundStepStatus?: GetFamilyBackgroundStepStatus;
}
export class FamilyTransition extends React.Component<any, IState> {
  private familyBackgroundService: FamilyBackgroundService;
  isComponentMounted: boolean = false;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.familyBackgroundService = new FamilyBackgroundService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      tab: 1,
      isReadonly: false,
      familyTransitionModel: {} as AddFamilyTransitionModel,
      reRenderForm: false,
      patientId: this.props.patientId,
    };
    return initialState;
  }

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
      //buttonHide:loading
    });
  }
  onEditClick = () => {
    this.setState({ isReadonly: false });
  };
  componentDidMount() {
    this.loadData();
    window.scroll(0, 0);
  }

  loadData(isAutoSave?: any) {
    if (this.state.patientId) {
      this.familyBackgroundService
        .getFamilyTransition(this.state.patientId)
        .then((res: HttpResponse<AddFamilyTransitionModel>) => {
          if (res && res.result) {
            this.props.familyBackGroundStepUpdate(6,res.result.id);
            if (isAutoSave) {
              let ft = this.state.familyTransitionModel;
              ft.id = res.result.id;
              ft.patientId = res.result.patientId;
              this.setState({
                familyTransitionModel: ft,
              });
            } else {
              let familyTransitionModel: AddFamilyTransitionModel = {
                id: res.result.id,
                patientId: res.result.patientId,
                parentDivorced: res.result.parentDivorced,
                deathOfFamilyMember: res.result.deathOfFamilyMember,
                childChangedSchool: res.result.childChangedSchool,
                childHadProlongedIllness: res.result.childHadProlongedIllness,
                deathOfClosedFriend: res.result.deathOfClosedFriend,
                familyChangedResidence: res.result.familyChangedResidence,
                familyChangedResidenceNo: res.result.familyChangedResidenceNo,
                familyMemberExpIllness: res.result.familyMemberExpIllness,
                childExpSeparationFromParents: res.result.childExpSeparationFromParents,
                parentExpSeparationMartialConflict: res.result.parentExpSeparationMartialConflict,
                childExpSeparationFromCloseFrnds: res.result.childExpSeparationFromCloseFrnds,
                parentChangedJob: res.result.parentChangedJob,
                parentChangedJobNo: res.result.parentChangedJobNo,
                siblingLeftHome: res.result.siblingLeftHome,
                comments: res.result.comments,
              };
              this.setState({
                familyTransitionModel: familyTransitionModel,
              });
            }
          }
        })
        .catch((ex) => {});
    }
  }

  postData = (data: any) => {
    let familyTransitionModel = data.familyTransitionModel;
    if (this.state.isLoading) {
      return;
      }
      if (data.isAutoSave) {
          this.setLoading(false);
      }
      else {
          this.setLoading(true);
      }
    this.familyBackgroundService
      .postFamilyTransition(familyTransitionModel)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
          this.state.familyTransitionModel.id=res.result;
          if (!data.isAutoSave) {
            ShowSuccessMessage("Data Saved Successfully.");
            this.props.nextTabChange();
          }
        }
        this.setLoading(false);
        this.props.isFormSave();
        if (!data.isAutoSave) {
        this.loadData(data.isAutoSave);
        }
      })
      .catch((ex) => {
        this.setLoading(false);
        ShowErrorMessage("Error in creating primary Care Taker.");
      });
  };

  previousTabChange = () => {
    this.props.previousTabChange();
  };
  assignModel = (data: any) => {
    this.setState(
      {
        familyTransitionModel: data.familyTransitionModel,
        reRenderForm: true,
      },
      () => this.setState({ reRenderForm: false })
    );
  };

  render() {
    return (
      <Fragment>
        {!this.state.reRenderForm && (
          <AddFamilyTransitionForm
            onSubmit={this.postData}
            isSaving={this.state.isLoading}
            familyTransitionModel={this.state.familyTransitionModel}
            patientId={this.props.patientId}
            assignModel={this.assignModel}
            isReadOnly={this.state.isReadonly}
            previousTabChange={this.previousTabChange}
          />
        )}
      </Fragment>
    );
  }
}

//export default PrimaryCareTaker;
