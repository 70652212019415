import React, { Fragment } from "react";
import { HttpResponse } from "../../../../core";
import { ShowErrorMessage, ShowSuccessMessage } from "../../../../shared/helpers";
import { BirthHistoryService } from "../../../../services/birthHistory/birth-history.service";
import { PerinatalPeriodModel } from "../../../../models/birthHistory/perinatalPeriod/perinatal-period.model";
import { PerinatalPeriodForm } from "./perinatalPeriod";
import { AddPerinatalPeriodModel } from "../../../../models/birthHistory/perinatalPeriod/add-perinatal-period.model";

interface IState {
  isLoading: boolean;
  tab: number;
  isReadonly: boolean;
  reRenderForm: boolean;
  perinatalPeriodModel: AddPerinatalPeriodModel;
  patientId: string;
  isFormSave?: Function;
  birthHistoryStepUpdate?: Function;
}
export class PerinatalPeriod extends React.Component<any, IState> {
  private birthHistoryService: BirthHistoryService;
  isComponentMounted: boolean = false;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.birthHistoryService = new BirthHistoryService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      tab: 1,
      isReadonly: false,
      perinatalPeriodModel: {} as AddPerinatalPeriodModel,
      reRenderForm: false,
      patientId: this.props.patientId,
    };
    return initialState;
  }

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }

  componentDidMount() {
    this.loadData();
    window.scroll(0, 0);
  }

  loadData(isAutoSave?: any) {
      if (this.state.patientId) {
          if (isAutoSave) {
              this.setLoading(false);
          }
          else {
              this.setLoading(true);
          }
      this.birthHistoryService
        .getPerinatalPeriod(this.state.patientId)
        .then((res: HttpResponse<PerinatalPeriodModel>) => {
          this.setLoading(false);
          if (res && res.result) {
            this.props.birthHistoryStepUpdate(3,res.result.id);
            if (isAutoSave) {
              let pm = this.state.perinatalPeriodModel;
              pm.id = res.result.id;
              pm.patientId = res.result.patientId;
              this.setState({
                perinatalPeriodModel: pm,
              });
            } else {
              let perinatalPeriod: AddPerinatalPeriodModel = {
                id: res.result.id,
                patientId: res.result.patientId,
                isCordAroundNeck: res.result.isCordAroundNeck,
                isInfantNeedBloodAtBirth: res.result.isInfantNeedBloodAtBirth,
                isHemorrhage: res.result.isHemorrhage,
                isEvidenceOfFetalDistress: res.result.isEvidenceOfFetalDistress,
                isInfantPlacedInIncubator: res.result.isInfantPlacedInIncubator,
                isInfantNeedOtherSpecialTreatement: res.result.isInfantNeedOtherSpecialTreatement,
                durationOfLabour: res.result.durationOfLabour,
                typeOfLabour: res.result.typeOfLabour,
                typeOfDelivery: res.result.typeOfDelivery,
                isInfactInjuredDuringDelivery: res.result.isInfactInjuredDuringDelivery,
                injury: res.result.injury,
                specifySpecialTreatement: res.result.specifySpecialTreatement,
                comments: res.result.comments,
              };
              this.setState({
                perinatalPeriodModel: perinatalPeriod,
              });
            }
          }
        })
        .catch((ex) => {
          this.setLoading(false);
        });
    }
  }

  postData = (data: any) => {
    let perinatalPeriod = data.perinatalPeriodModel;
    if (this.state.isLoading) {
      return;
      }
      if (data.isAutoSave) {
          this.setLoading(false);
      }
      else {
          this.setLoading(true);
      }
    this.birthHistoryService
      .postPerinatalPeriod(perinatalPeriod)
      .then((res: HttpResponse<any>) => {
        this.setLoading(false);
        if (res && res.result) {
          this.state.perinatalPeriodModel.id=res.result;
        }
        if (!data.isAutoSave) {
          ShowSuccessMessage("Data Saved Successfully.");
          this.props.nextTabChange();
        }
        this.props.isFormSave();
        if (!data.isAutoSave) {
          this.loadData(data.isAutoSave);
        }
      })
      .catch((ex) => {
        ShowErrorMessage("Error in creating perinatal period.");
        this.setLoading(false);
      });
  };
  previousTabChange = () => {
    this.props.previousTabChange();
  };

  assignModel = (data: any) => {
    this.setState(
      {
        perinatalPeriodModel: data.perinatalPeriodModel,
        reRenderForm: true,
      },
      () => this.setState({ reRenderForm: false })
    );
  };

  render() {
    return (
      <Fragment>
        {!this.state.reRenderForm && (
          <PerinatalPeriodForm
            onSubmit={this.postData}
            isSaving={this.state.isLoading}
            perinatalPeriodModel={this.state.perinatalPeriodModel}
            patientId={this.props.patientId}
            assignModel={this.assignModel}
            isReadOnly={this.state.isReadonly}
            previousTabChange={this.previousTabChange}
            isBirthHistoryOptional={this.props.isBirthHistoryOptional}
          />
        )}
      </Fragment>
    );
  }
}
