import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AddPatientModel } from "../models/add-patient";
import { DatePicker, Tooltip } from "antd";
import { Gender } from "../../../shared/enums/gender.enum";
import { Relationship } from "../../../shared/enums/relationship.enum";
import { Loader } from "../../../shared/loaders";
import moment from "moment";
import "../patient.scss";
import "./index.scss";
import {  PatternFormat } from "react-number-format";
import { CheckEmailExistsModel } from "../../../models/patient/check-email.model";
import dayjs from "dayjs";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Tabs from "../../tabs";
import searchGray from "../../../assets/images/svg-icons/search-list.svg";
import searchBlue from "../../../assets/images/svg-icons/search-b.svg"
import { PatientService } from "../services";
import { HttpResponse, HttpWrapper } from "../../../core";
import { PatientDetailModel } from "../models/patient-detail.model";

interface IAddPatientFormValues{
  contactNo: string;
  mr_No: string;
  firstName: string;
  lastName: string;
  male: number;
  female: number;
  others: number;
  relationshipId: number | string;
  guardianFirstName: string;
  guardianLastName: string;
  emailId: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  formIsSubmitted: number;
  formIsNotSubmitted: number;
}
interface IProps {
  patient: AddPatientModel;
  isReadOnly: boolean;
  onSubmit: Function;
  onEditClick: Function;
  isSaving: boolean;
  tab: number;
  isMrn_No_Exists:Function;
  mrnError:boolean;
  isEmailExists:Function;
  isEmailError:boolean;
  onPatientSubmit:Function;
  onGuardianSubmit:Function;
  isEmailErrorMessage:string;
  getPatientFromEhrByMrn:Function;
  resetForm: Function;
}

export function AddPatientForm(props: IProps) {
  const {
    register,
    formState: {errors},
    handleSubmit,
    control,
    getValues,
    setValue,
    clearErrors,
    formState,
  } = useForm<IAddPatientFormValues>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    //shouldUnregister: true,
  });
  const [dateOfBirth, setDateOfBirth] = useState(dayjs(props.patient.dateOfBirth));
  useEffect(() => {
    setValuesFromProps();
  }, [props.patient])
  
  const contactNo = register('contactNo', {
    required: "Please fill contact no",
    validate: {
      isTenDigit: v => v.length >= 10
    }
  });
  const setValuesFromProps = () => {
    setValue("address1", props.patient.addressModel.address1 ?? "");
    setValue("address2", props.patient.addressModel.address2 ?? "");
    setValue("city", props.patient.addressModel.city ?? "");
    setValue("contactNo", props.patient.contactNo ?? "");
    setValue("emailId", props.patient.emailId ?? "");
    setValue("firstName", props.patient.firstName ?? "");
    setValue("guardianFirstName", props.patient.guardianFirstName ?? "");
    setValue("guardianLastName", props.patient.guardianLastName ?? "");
    setValue("lastName", props.patient.lastName ?? "");
    setValue("mr_No", props.patient.mr_No ?? "");
    setValue("relationshipId", props.patient.relationshipId ?? "");
    setValue("state", props.patient.addressModel.state ?? "");
    setValue("zipcode", props.patient.addressModel.zipcode ?? "");
    
  }
  //const ref = useRef(props.patient.contactNo)
  const patientService = new PatientService();
  let patientModel = props.patient;
  const [gender, setGender] = useState(patientModel.gender);
  const [isMedSocHisFormSubmitted, setIsMedSocHisFormSubmitted] = useState(patientModel.isMedSocHisFormSubmitted);
  const [dateOfBirthError, setDateOfBirthError] = useState("");
  const formSubmit = (data) => {
    patientModel.id = props.patient.id;
    patientModel.emailId = data.emailId;
    patientModel.mr_No = data.mr_No;
    patientModel.firstName = data.firstName;
    patientModel.lastName = data.lastName;
    patientModel.guardianFirstName = data.guardianFirstName;
    patientModel.guardianLastName = data.guardianLastName;
    patientModel.contactNo = data.contactNo.replace(/\D/g, "");
    patientModel.emailId = data.emailId;
    patientModel.addressModel.state = data.state;
    patientModel.addressModel.city = data.city;
    patientModel.addressModel.zipcode = data.zipcode;
    patientModel.addressModel.address1 = data.address1;
    patientModel.addressModel.address2 = data.address2;
    patientModel.guardianId = props.patient.guardianId;
    if (!checkForDateOfBirthError(patientModel.dateOfBirth)) {
      props.onSubmit({ model: patientModel, isAutoSave: false });
    }
  };

  const handleSaveAndInviteClick = () => {
    patientModel.isInviteSend = true;
  };
  const handleSaveClick = () => {
    patientModel.isInviteSend = false;
  };

  const dateChange = (date) => {
    setValuesInModel();
    if(date != null){
      setDateOfBirth(dayjs(date))
    }
        patientModel.dateOfBirth = date != null ? dayjs(date).format("MM/DD/YYYY") : null;
        //Turned off save on date change of dob with the addition of MRN search
        //savePatientDataOnTabChange(); 
  };

  const relationChange = (data) => {
    setValuesInModel();
    patientModel.relationshipId = parseInt(data.target.value);
    //saveGuardianDataOnTabChange();
  };
  const changeToEdit = () => {
    props.onEditClick();
  };
  const genderChange = (data) => {
    setValuesInModel();
    patientModel.gender = parseInt(data.target.value);
    setGender(patientModel.gender);
    //savePatientDataOnTabChange();
  };
  const medSocFormChange = (data) => {
    setValuesInModel();
    patientModel.isMedSocHisFormSubmitted = 1 === parseInt(data.target.value);
    setIsMedSocHisFormSubmitted(patientModel.isMedSocHisFormSubmitted);
    //savePatientDataOnTabChange();
  };
  // const handleKeyUp = (event) => {
  //   if (!props.isReadOnly) {
  //     setValuesInModel();

  //     if (
  //       event.key == "Tab" &&
  //       patientModel.mr_No &&
  //       String(patientModel.relationshipId) != "" &&
  //       patientModel.guardianFirstName != null &&
  //       patientModel.guardianFirstName != "" &&
  //       patientModel.guardianLastName != null &&
  //       patientModel.guardianLastName != ""
  //     ) {
  //       saveDataOnTabChange();
  //       }

  //   }
  // };
  const handleOnBlur = (type: number) => {
    if (!props.isReadOnly) {
      setValuesInModel();
           if(type==1){
        //savePatientDataOnTabChange();
           }else if (type==2){
       // saveGuardianDataOnTabChange();
      }
    }
  };
  const handleOnBlur_Mr_No = () => {
    if (!props.isReadOnly) {
      setValuesInModel();
      patientModel.id = props.patient.id;
     patientModel.guardianId =  props.patient.guardianId;
      props.isMrn_No_Exists({ model: patientModel, isAutoSave: true });
      //savePatientDataOnTabChange();
    }
  };
  const handleOnBlur_Email = (emailId) => {
    if (!props.isReadOnly) {
      setValuesInModel();
      let  checkIsEmailExists ={} as CheckEmailExistsModel;
    checkIsEmailExists.id =  props.patient.guardianId;
    checkIsEmailExists.emailId=emailId;
      props.isEmailExists({ model: checkIsEmailExists, isAutoSave: true });
      //saveGuardianDataOnTabChange();
    }
  };

  // const saveDataOnTabChange = () => {
  //     if (!props.isSaving) {
  //         if (
  //             patientModel.mr_No &&
  //             String(patientModel.relationshipId) != "" &&
  //             patientModel.guardianFirstName != null &&
  //             patientModel.guardianFirstName != "" &&
  //             patientModel.guardianLastName != null &&
  //             patientModel.guardianLastName != ""
  //         ) {
  //           props.onSubmit({ model: patientModel, isAutoSave: true });
  //         }
  //   }
  // };

  const savePatientDataOnTabChange = () => {
    if (!props.isSaving) {
      if (
        patientModel.mr_No &&
        patientModel.firstName != null &&
        patientModel.firstName != "" &&
        patientModel.lastName != null &&
        patientModel.lastName != "" &&
        patientModel.dateOfBirth != null &&
            patientModel.gender !=null
      ) {
        props.onPatientSubmit({ model: patientModel, isAutoSave: true });
      }
    }
  };

  const saveGuardianDataOnTabChange = () => {
    if (!props.isSaving) {
      if (
        String(patientModel.relationshipId) != "" &&
        patientModel.guardianFirstName != null &&
        patientModel.guardianFirstName != "" &&
        patientModel.guardianLastName != null &&
        patientModel.guardianLastName != "" &&
        patientModel.contactNo != null &&
        patientModel.contactNo != "" &&
        patientModel.emailId != null &&
        patientModel.emailId != "" &&
        patientModel.addressModel.address1 != null &&
        patientModel.addressModel.address1 != "" &&
        patientModel.addressModel.city != null &&
        patientModel.addressModel.city != "" &&
        patientModel.addressModel.state != null &&
        patientModel.addressModel.state != "" &&
        patientModel.addressModel.zipcode != null &&
        patientModel.addressModel.zipcode != ""
      ) {
        props.onGuardianSubmit({ model: patientModel, isAutoSave: true });
      }
    }
  };
  const isPhnDigit = (event) => {
    if (event.replace(/\D/g, "").length < 10) {
      return false;
    }
  };
  function setValuesInModel() {
    let model = getValues();

    patientModel.mr_No = model.mr_No;
    patientModel.firstName = model.firstName;
    patientModel.lastName = model.lastName;
    patientModel.guardianFirstName = model.guardianFirstName;
    patientModel.guardianLastName = model.guardianLastName;
    patientModel.contactNo = model.contactNo;
    patientModel.emailId = model.emailId;
    patientModel.addressModel.address1 = model.address1;
    patientModel.addressModel.address2 = model.address2;
    patientModel.addressModel.city = model.city;
    patientModel.addressModel.state = model.state;
    patientModel.addressModel.zipcode = model.zipcode;
  };
    const handleValidateMrn=(e:any) => {
        if (e.which < 48 ||
      (e.which > 57 && e.which < 65) ||
      (e.which > 90 && e.which < 97) ||
            e.which > 122) {
      e.preventDefault();
    }
    };
  const isNumber = (evt) => {
    if (evt.which > 31 && (evt.which < 48 || evt.which > 57)) {
      evt.preventDefault();
    }
       
    };
    const checkForDateOfBirthError = (date:any) => {
    let hasError = false;

                if (date == null ) {
      setDateOfBirthError("Please fill  date of birth.");
      hasError = true;
    }
    return hasError;
  };

  const getPatientFromEhrByMrn = () => {
    let mrn = getValues("mr_No");
    if (!props.isReadOnly && mrn && mrn !== "" && !props.patient.id){
      props.getPatientFromEhrByMrn(mrn);
    }
    
  };
  const resetForm = () => {
    props.resetForm();
  };
  
  return (
    <form onSubmit={handleSubmit(formSubmit)}>
      {!props.isSaving && (
      <div className="row">
        <div className="col-md-12">
          <div>
            
            <section className="child-info">
              <header className="info-header">
                <h3>Patient Information</h3>
              </header>
              <div className="form">
                <div className="row">
                  <div className="col-md-3 col-12">
                    <div className="input-group form-group form-field">
                    
                    <input
                        type="text"
                        defaultValue={props.patient?.mr_No}
                        className={
                          !errors.mr_No
                            ? "form-control mb-1"
                            : "form-control mb-1 is-invalid"
                        }
                        {...register("mr_No", {
                          required: "Please fill MRN.",
                          maxLength: 128,
                          pattern: {
                            value: /^[a-zA-Z0-9]*$/,
                            message: "MRN should be alphanumeric.",
                          },
                        })}
                        onBlur={handleOnBlur_Mr_No}
                        readOnly={props.isReadOnly ? true : false}
                        placeholder="MRN"
                        onKeyPress={e => handleValidateMrn(e)}
                      />
                     
                      <div className="input-group-append">
                        <Tooltip title="Search Epic">
                        <span onClick={getPatientFromEhrByMrn} className="input-group-text form-control mb-1" id="basic-addon2">
                        <img id="search-epic-btn" src={searchBlue} alt="search-epic" />
                        </span>
                        </Tooltip>
                       
                      </div>
                      {!props.mrnError && errors.mr_No && errors.mr_No.type === "required" && (
                          <div className="invalid-feedback">
                            {errors.mr_No?.message?.toString()}
                          </div>
                        )}
                      {!props.mrnError && errors.mr_No && errors.mr_No.type === "pattern" && (
                          <div className="invalid-feedback">
                            {errors.mr_No?.message?.toString()}
                          </div>
                        )}
                      {props.mrnError && (
                  <div className="invalid-feedback" style={{ display: "block", textAlign: "left" }}>
                          MRN already exists.
                        </div>
                      )}
                    </div>

                    {/* <div className="form-group form-field">
                      <label className="form-label">MRN </label>
                      <input
                        type="text"
                        defaultValue={props.patient?.mr_No}
                        className={
                          !errors.mr_No
                            ? "form-control mb-1"
                            : "form-control mb-1  is-invalid"
                        }
                        {...register("mr_No", {
                          required: "Please fill MRN.",
                          maxLength: 128,
                          pattern: {
                            value: /^[a-zA-Z0-9]*$/,
                            message: "MRN should be alphanumeric.",
                          },
                        })}
                        onBlur={handleOnBlur_Mr_No}
                        readOnly={props.isReadOnly ? true : false}
                        placeholder="MRN"
                        onKeyPress={e => handleValidateMrn(e)}
                      />
                      {!props.mrnError && errors.mr_No && errors.mr_No.type === "required" && (
                          <div className="invalid-feedback">
                            {errors.mr_No?.message?.toString()}
                          </div>
                        )}
                      {!props.mrnError && errors.mr_No && errors.mr_No.type === "pattern" && (
                          <div className="invalid-feedback">
                            {errors.mr_No?.message?.toString()}
                          </div>
                        )}
                      {props.mrnError && (
                  <div className="invalid-feedback" style={{ display: "block", textAlign: "left" }}>
                          MRN already exists.
                        </div>
                      )}
                    </div> */}
                  </div>

                  <div className="col-md-4">
                    <div className="form-group form-field">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        defaultValue={props.patient.firstName}
                        className={
                          !errors.firstName
                            ? "form-control mb-1"
                            : "form-control mb-1  is-invalid"
                        }
                        {...register("firstName", {
                          required: "Please fill first name.",
                          maxLength: 128,
                        })}
                        onFocus={() => handleOnBlur(1)}
                        readOnly={props.isReadOnly ? true : false}
                        placeholder="First Name"
                      />
                      {errors.firstName && (
                        <div className="invalid-feedback">
                          {errors.firstName?.message?.toString()}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group form-field">
                      <label className="form-label">Last Name</label>

                      <input
                        type="text"
                        defaultValue={props.patient.lastName}
                        className={
                          !errors.lastName
                            ? "form-control mb-1"
                            : "form-control mb-1  is-invalid"
                        }
                        {...register("lastName", {
                          required: "Please fill last name.",
                          maxLength: 128,
                        })}
                        onFocus={() => handleOnBlur(1)}
                        readOnly={props.isReadOnly ? true : false}
                        placeholder="Last Name"
                      />
                      {errors.lastName && (
                        <div className="invalid-feedback">
                          {errors.lastName?.message?.toString()}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group form-field">
                      <Tooltip
                        placement="right"
                        title="Date should be in MM/DD/YYYY format"
                      >
                        <label className="form-label">Date of Birth</label>

                        <DatePicker
                          onChange={dateChange}
                          className="datepicker"
                          placeholder="MM/DD/YYYY"
                          format="MM/DD/YYYY"
                          inputReadOnly={false}
                          disabled={props.isReadOnly ? true : false}
                          value={dateOfBirth}
                        />
                        {dateOfBirthError && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block", textAlign: "left" }}
                          >
                            {dateOfBirthError}
                          </div>
                        )}
                      </Tooltip>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div
                      className="medical-group btn-select"
                      style={{ top: "-10px" }}
                    >
                      <label className="form-label">Gender</label>
                      <div className="btn-addon btnGender">
                        <button
                          type="button"
                          className={
                            patientModel.gender == Gender.Male
                              ? "btn btn-sm btnGender_selected"
                              : "btn btn-sm btnGender_unselected"
                          }
                          value={Gender.Male}
                          onClick={genderChange}
                          {...register("male")}
                          disabled={props.isReadOnly ? true : false}
                        >
                          Male
                        </button>
                        <button
                          type="button"
                          className={
                            patientModel.gender == Gender.Female
                              ? "btn btn-sm btnGender_selected"
                              : "btn btn-sm btnGender_unselected"
                          }
                          onClick={genderChange}
                          disabled={props.isReadOnly ? true : false}
                          value={Gender.Female}
                          {...register("female")}
                        >
                          Female
                        </button>
                        <button
                          type="button"
                          className={
                            patientModel.gender == Gender.Others
                              ? "btn btn-sm btnGender_selected"
                              : "btn btn-sm btnGender_unselected"
                          }
                          value={Gender.Others}
                          onClick={genderChange}
                          disabled={props.isReadOnly ? true : false}
                          {...register("others")}
                        >
                          Other
                        </button>
                      </div>
                    </div>
                    {errors.others && (
                      <div className="invalid-feedback">
                        {errors.others?.message?.toString()}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>

            <section className="child-info">
              <header className="info-header">
                <h3>Guardian/Parent Information</h3>
              </header>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group form-field">
                    <label htmlFor="relationshipId" className="form-label">
                      Relationship
                    </label>
                    <select
                      defaultValue={props.patient.relationshipId}
                      className={
                        !errors.relationshipId
                          ? "form-control mb-1"
                          : "form-control mb-1  is-invalid"
                      }
                      disabled={props.isReadOnly ? true : false}
                      {...register("relationshipId", {
                        onChange: (e) => relationChange(e),
                        required: "Please select relationship.",
                      })}
                    >
                      <option value="">Select</option>
                      <option value={Relationship.Father}>Father</option>
                      <option value={Relationship.Mother}>Mother</option>
                      <option value={Relationship.LegalGuardian}>Legal Guardian</option>
                      <option value={Relationship.StepFather}>Step Father</option>
                      <option value={Relationship.StepMother}>Step Mother</option>
                      <option value={Relationship.Grandfather}>Grandfather</option>
                      <option value={Relationship.Grandmother}>Grandmother</option>
                      <option value={Relationship.Other}>Other</option>
                    </select>
                    {!errors.relationshipId && (
                      <i className="fa fa-angle-down relation_arrow"></i>
                    )}
                    {errors.relationshipId && (
                      <i
                        className="fa fa-angle-down relation_arrow"
                        style={{ paddingRight: "15px" }}
                      ></i>
                    )}
                    {errors.relationshipId && (
                      <div className="invalid-feedback">
                        {errors.relationshipId?.message?.toString()}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group form-field">
                    <label className="form-label">First Name</label>
                    <input
                      type="text"
                      defaultValue={props.patient.guardianFirstName}
                      placeholder="First Name"
                      className={
                        !errors.guardianFirstName
                          ? "form-control mb-1"
                          : "form-control mb-1 is-invalid"
                      }
                      {...register("guardianFirstName", {
                        required: "Please fill guardian first name.",
                        maxLength: 128,
                      })}
                      onFocus={() => handleOnBlur(2)}
                      readOnly={props.isReadOnly ? true : false}
                    />
                    {errors.guardianFirstName && (
                      <div className="invalid-feedback">
                        {errors.guardianFirstName?.message?.toString()}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group form-field">
                    <label className="form-label">Last Name</label>

                    <input
                      type="text"
                      defaultValue={props.patient.guardianLastName}
                      onFocus={() => handleOnBlur(2)}
                      readOnly={props.isReadOnly ? true : false}
                      placeholder="Last Name"
                      className={
                        !errors.guardianLastName
                          ? "form-control mb-1"
                          : "form-control mb-1  is-invalid"
                      }
                      {...register("guardianLastName", {
                        required: "Please fill guardian last name.",
                        maxLength: 128,
                      })}
                    />
                    {errors.guardianLastName && (
                      <div className="invalid-feedback">
                        {errors.guardianLastName?.message?.toString()}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group form-field">
                    <label className="form-label">Contact Number</label>
                    {/* <PhoneInput
                      country={'us'}
                      value={props.patient.contactNo || ''}
                      placeholder={"Contact Number"}
                      inputProps={
                        {...register("contactNo", {
                          required: "Please fill contact no",
                          validate: isPhnDigit},
                        )}
                    }
                    /> */}
                    <PatternFormat
                      format="(###)-###-####"
                      value={props.patient.contactNo || ""}
                      className={
                        !errors.contactNo
                          ? "form-control mb-1"
                          : "form-control mb-1  is-invalid"
                      }
                      disabled={props.isReadOnly ? true : false}
                      onFocus={() => handleOnBlur(2)}
                      placeholder={"Contact Number"}
                      getInputRef={contactNo.ref}
                      {...register("contactNo", {
                        required: "Please fill contact no",
                        minLength: {
                          value: 10,
                          message: "Phone number must be 10 digits",
                        },
                      })}
                    />

                    {errors.contactNo && (
                      <div className="invalid-feedback">
                        {errors.contactNo?.message?.toString()}
                      </div>
                    )}
                    {errors.contactNo &&
                      errors.contactNo.type === "validate" && (
                        <div className="invalid-feedback">
                          Contact number should be 10 digit.
                        </div>
                      )}
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="form-group form-field">
                    <label className="form-label">Email</label>
                    <input
                      type="text"
                      defaultValue={props.patient.emailId}
                      readOnly={
                        props.isReadOnly || props.patient.identityId
                          ? true
                          : false
                      }
                      placeholder="Email"
                      className={
                        !errors.emailId
                          ? "form-control mb-1"
                          : "form-control mb-1  is-invalid"
                      }
                      {...register("emailId", {
                        onChange: (e) => handleOnBlur_Email(e.target.value),
                        required: "Please fill email",
                        maxLength: 128,
                        pattern:
                          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      })}
                    />
                    {!props.isEmailError && errors.emailId && (
                      <div className="invalid-feedback">
                        {errors.emailId?.message?.toString()}
                      </div>
                    )}
                    {!props.isEmailError &&
                      errors.emailId &&
                      errors.emailId.type === "pattern" && (
                        <div className="invalid-feedback" role="alert">
                          Email is not valid.
                        </div>
                      )}
                    {props.isEmailError && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block", textAlign: "left" }}
                      >
                        {props.isEmailErrorMessage}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-11">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group form-field">
                        <label className="form-label">Address 1</label>
                        <input
                          type="text"
                          defaultValue={props.patient.addressModel.address1}
                          placeholder={"Address 1"}
                          className={
                            !errors.address1
                              ? "form-control mb-1"
                              : "form-control mb-1  is-invalid"
                          }
                          onFocus={() => handleOnBlur(2)}
                          readOnly={props.isReadOnly ? true : false}
                          {...register("address1", {
                            required: "Please fill address",
                            maxLength: 128,
                          })}
                        />
                        {errors.address1 && (
                          <div className="invalid-feedback">
                            {errors.address1?.message?.toString()}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group form-field optional-field">
                        <label className="form-label">Address 2</label>
                        <span className="optional">Optional</span>
                        <input
                          type="text"
                          className="form-control mb-1"
                          maxLength={128}
                          defaultValue={props.patient.addressModel.address2}
                          placeholder={"Address 2"}
                          onFocus={() => handleOnBlur(2)}
                          readOnly={props.isReadOnly ? true : false}
                          {...register("address2")}
                        />
                      </div>
                    </div>

                    <div className="col-md-4 addPatient__col4">
                      <div className="form-group form-field">
                        <label className="form-label">City</label>
                        <input
                          type="text"
                          defaultValue={props.patient.addressModel.city}
                          onFocus={() => handleOnBlur(2)}
                          readOnly={props.isReadOnly ? true : false}
                          placeholder="City"
                          className={
                            !errors.city
                              ? "form-control mb-1"
                              : "form-control mb-1  is-invalid"
                          }
                          {...register("city", {
                            required: "Please fill city name",
                            maxLength: 128,
                          })}
                        />
                        {errors.city && (
                          <div className="invalid-feedback">
                            {errors.city?.message?.toString()}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4 addPatient__col4">
                      <div className="form-group form-field">
                        <label className="form-label">State</label>
                        <input
                          type="text"
                          defaultValue={props.patient.addressModel.state}
                          onFocus={() => handleOnBlur(2)}
                          readOnly={props.isReadOnly ? true : false}
                          placeholder="State"
                          className={
                            !errors.state
                              ? "form-control mb-1"
                              : "form-control mb-1  is-invalid"
                          }
                          //   onKeyUp={handleKeyUp}
                          {...register("state", {
                            required: "Please fill state",
                            maxLength: 128,
                          })}
                        />
                        {errors.state && (
                          <div className="invalid-feedback">
                            {errors.state?.message?.toString()}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4 addPatient__col4">
                      <div className="form-group form-field">
                        <label className="form-label">Zip Code</label>
                        <input
                          type="text"
                          defaultValue={props.patient.addressModel.zipcode}
                          onFocus={() => handleOnBlur(2)}
                          readOnly={props.isReadOnly ? true : false}
                          className={
                            !errors.zipcode
                              ? "form-control mb-1"
                              : "form-control mb-1  is-invalid"
                          }
                          placeholder="Zip code"
                          {...register("zipcode", {
                            required: "Please fill zip code",
                            maxLength: 128,
                          })}
                          onKeyPress={(e) => isNumber(e)}
                        />
                        {errors.zipcode && (
                          <div className="invalid-feedback">
                            {errors.zipcode?.message?.toString()}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        className="medical-group btn-select"
                        style={{ top: "-10px" }}
                      >
                        <label className="form-label">
                          Has the Medical-Social-History Form already been
                          Submitted
                        </label>
                        <div className="btn-addon btnGender">
                          <button
                            type="button"
                            className={
                              patientModel.isMedSocHisFormSubmitted
                                ? "btn btn-sm btnGender_selected"
                                : "btn btn-sm btnGender_unselected"
                            }
                            value={1}
                            onClick={medSocFormChange}
                            {...register("formIsSubmitted")}
                            disabled={props.isReadOnly ? true : false}
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            className={
                              !patientModel.isMedSocHisFormSubmitted
                                ? "btn btn-sm btnGender_selected"
                                : "btn btn-sm btnGender_unselected"
                            }
                            onClick={medSocFormChange}
                            disabled={props.isReadOnly ? true : false}
                            value={0}
                            {...register("formIsNotSubmitted")}
                          >
                            No
                          </button>
                        </div>
                      </div>
                      {errors.others && (
                        <div className="invalid-feedback">
                          {errors.others?.message?.toString()}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="row">
              <div
                //   className={"col-md-11 " + (props.buttonHide ? "d-none" : "")}
                className="col-md-11 addPatient__btnCol"
              >
                
                  <div className="button-group margin_b30">
                    <div className="text-right">
                    {!props.patient.id && (
                        <button
                          type="button"
                          onClick={resetForm}
                          className="btn btn-primary btn-reset"
                        >
                          Reset Form
                        </button>
                      )}
                      {!props.isReadOnly && (
                        <button
                          type="submit"
                          onClick={handleSaveClick}
                          className="btn btn-primary btn-save"
                        >
                          Save
                        </button>
                      )}
                      {!props.isReadOnly && !props.patient.identityId && (
                        <button
                          type="submit"
                          className="btn btn-primary btn-save invite-button"
                          onClick={handleSaveAndInviteClick}
                        >
                          Save and Invite
                        </button>
                      )}

                      {props.isReadOnly && (
                        <button
                          type="button"
                          className="btn btn-primary btn-save"
                          onClick={changeToEdit}
                        >
                          Edit
                        </button>
                      )}
                    </div>
                  </div>
                
              </div>
            </div>
            
          </div>
        </div>
      </div>
      )}
                {props.isSaving && (
                  <div style={{ height: "60px" }}>
                    <Loader
                      loading={props.isSaving}
                      marginBottom="0px"
                      marginTop="8px"
                      width="368px"
                    ></Loader>
                  </div>
                )}
    </form>
  );
}
