import React, { Component } from "react";
import { AuthService } from "./services/auth.service";
import { HttpResponse } from "../../core";
import { AuthResponseModel } from "./models/auth.response.model";
import { AuthModel } from "./models/auth.model";
import { AppConfig } from "../../core";
import { Encrypt, setCookie } from "../../shared/helpers";
//import moment from "moment";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

export default class LoginCallback extends Component<any> {
  private service: AuthService;
  constructor(props: any) {
    super(props);
    this.service = new AuthService();
  }
  componentDidMount() {
    // this.props.location.search = "";
    // this.props.location.history= "";
    //
    if (this.getParameterByName("code")) {
      this.getResponse();
    }
    if (!this.getParameterByName("code")) {
      window.location.href =("/login");
    }
  }

  getParameterByName(name: string) {
    const queryParameters = new URLSearchParams(window.location.search)
    const value = queryParameters.get(name) ?? "";
    // if (!url) url = window.location.href;
    // name = name.replace(/[\[\]]/g, "\\$&");
    // var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    //   results = regex.exec(url);
    // if (!results) return null;
    // if (!results[2]) return "";
    return decodeURIComponent(value.replace(/\+/g, " "));
  }

  getResponse() {
     let code = this.getParameterByName("code");
    let authData: AuthModel = {
      client_id: AppConfig.clientId,
      client_secret: AppConfig.clientSecret,
      grant_type: AppConfig.grantType,
      redirect_uri: AppConfig.redirect_Uri,
      code: code,
      audience: AppConfig.audience,
    };
    this.service
      .getAccessToken(authData)
      .then((res: HttpResponse<AuthResponseModel>) => {
        if (res && res.result) {
          let expire_in = dayjs().add(
            parseInt(res.result.expires_in),
            "seconds"
          );
          localStorage.setItem("lg", "true");
          localStorage.setItem("expire_in", expire_in.toString());
          localStorage.setItem(
            AppConfig.tokenKey,
            Encrypt(res.result.access_token)
          );
          if (res.result.hasOwnProperty("refresh_token")) {
            setCookie(
              AppConfig.refreshTokenKey,
              Encrypt(res.result.refresh_token),
              AppConfig.refreshTokenExpiry
            );
          }
          window.location.href = "/";
        } else {
          localStorage.clear();
          window.location.href = "/login"
        }
      });
  }

  render() {
    return <div className="compLoader"></div>;
  }
}
