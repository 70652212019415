import React, { Component, Fragment } from "react";
import "./index.scss";
import { PatientService } from "../services";
import Tabs from "../../tabs";
import { AddPatientModel } from "../models/add-patient";
import { HttpResponse } from "../../../core/HttpResponse";
import { ShowErrorMessage, ShowSuccessMessage, ShowWarningMessage } from "../../../shared/helpers";
import { PatientDetailModel } from "../models/patient-detail.model";
import { AddPatientForm } from "./addPatientForm";
import { Gender } from "../../../shared/enums/gender.enum";
import { Relationship } from "../../../shared/enums/relationship.enum";
import medical from "../../../assets/images/svg-icons/smh_gray5.svg";
import medicalIcon from "../../../assets/images/svg-icons/smh_white5.svg";
import parentMissingHover from "../../../assets/images/svg-icons/smh_white1.svg";
import family from "../../../assets/images/svg-icons/family.svg";
import PhysicianMedication from "../addPhysicianMedication";
import { AddressModel } from "../../../shared/models/address.model";
import withRouter from "../../../shared/withRouter/withRouter";

interface IState {
  isLoading: boolean;
  tab: number;
  isReadonly: boolean;
  reRenderForm: boolean;
  patient: AddPatientModel;
  currentUserName: string;
  tabChangeFor: string;
  isBreadcrumChange: boolean;
  tabText: string;
  mrnError:boolean;
  isEmailError:boolean;
  isEmailErrorMessage:string;
}

export class AddPatient extends Component<any, IState> {
  private patientService: PatientService;
  id: string = "";
  isComponentMounted: boolean = false;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.patientService = new PatientService();
  }

  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      tab: 0,
      isReadonly: false,
      patient: {
        addressModel: {} as AddressModel,
      } as AddPatientModel,
      reRenderForm: false,
      currentUserName: "",
      tabChangeFor: this.props.params.tab,
      isBreadcrumChange: false,
      tabText: "",
      mrnError:false,
      isEmailError:false,
      isEmailErrorMessage:""
    };
    initialState.patient.gender = Gender.Male;
    initialState.patient.dateOfBirth = new Date();
    return initialState;
  }
  ChangeTabIfParentExist(tab: any) {
    if (this.state.patient.id) {
      this.changeTab(tab);
    } else return false;
  }
  changeTab(tab: any) {
    this.setState({
      tab,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.params.id != this.props.params.id) {
      this.setState(this.getInitialState());
      this.setState({ reRenderForm: true }, () =>
        this.setState({ reRenderForm: false })
      );
      if (this.props.params.id == null ||this.props.params.id == 0) {        
        this.setState(
          {
            isBreadcrumChange: false,
            tabText: "",
          },
          () =>
            this.props.OnCurrentUserName(
              "Add Patient",
              this.state.isBreadcrumChange,
              this.state.tabText
            )
        );
      }
    }
    if (this.isComponentMounted) {
      if(this.props.params.id !=0){
      this.loadData();
      }
      if(this.props.params.id ==0){
      this.id="0";
      }
    }
  }
  componentDidMount() {
    this.isComponentMounted = true;
    if(this.props.params.id !=0){
    this.loadData();
    }
    if (this.props.params.id == null ||this.props.params.id == 0) {
      
      this.setState(
        {
          isBreadcrumChange: false,
          tabText: "",
        },
        () =>
          this.props.OnCurrentUserName(
            "Add Patient",
            this.state.isBreadcrumChange,
            this.state.tabText
          )
      );
    }
    // window.addEventListener("load", () => {
    //   this.props.history.push("/patient/addPatient/");
    // });
    if (this.props.params.tab == 1) {
      this.state.patient.id = this.props.params.id;
      this.ChangeTabIfParentExist(1);
    }
  }

  loadData() {
    if (this.props.params.id && this.id != this.props.params.id) {
      this.id = this.props.params.id;
      this.patientService
        .getUserById(this.props.params.id)
        .then((res: HttpResponse<PatientDetailModel>) => {
          if (res && res.result) {
            let patient: AddPatientModel = {
              id: res.result.id,
              guardianId: res.result.guardianId,
              mr_No: res.result.mr_No,
              firstName: res.result.firstName,
              lastName: res.result.lastName,
              dateOfBirth: res.result.dateOfBirth,
              gender: res.result.gender,
              relationshipId: res.result.relationshipId,
              guardianFirstName: res.result.guardianFirstName,
              guardianLastName: res.result.guardianLastName,
              contactNo: res.result.contactNo,
              emailId: res.result.emailId,
              addressModel: {
                address1: res.result.addressModel.address1,
                address2: res.result.addressModel.address2,
                city: res.result.addressModel.city,
                state: res.result.addressModel.state,
                zipcode: res.result.addressModel.zipcode,
                //  addressId: res.result.addressModel.addressId,
              },
              physicianModel: {
                id: res.result.physicianId,
                pLastName: res.result.physicianLastName,
                speciality: res.result.speciality,
                hospitalName: res.result.hospitalName,
                pFirstName: res.result.physicianFirstName,
              },
              identityId: res.result.identityId,
            };
            this.setState(
              {
                patient: patient,
                isReadonly: true,
                reRenderForm: true,
                currentUserName:
                  res.result.lastName + ", " + res.result.firstName,
              },
              () => this.setState({ reRenderForm: false })
            );
            if (this.props.params.tab != 1) {
              this.changeTab(0);
            }
            this.props.OnCurrentUserName(
              this.state.currentUserName,
              this.state.isBreadcrumChange,
              this.state.tabText
            );
            if (this.state.tabChangeFor == "edit") {
              this.setState(
                {
                  isBreadcrumChange: true,
                  tabText: "",
                },
                () =>
                  this.props.OnCurrentUserName(
                    this.state.currentUserName,
                    this.state.isBreadcrumChange,
                    this.state.tabText
                  )
              );
            }
          }
        })
        .catch((ex) => {});
    }
  }
  isMrn_No_Exists=(data: any)=>{
    let patient = data.model;
    if (this.state.isLoading) {
      return;
    }
    if (data.isAutoSave) {
      this.setLoading(false);
    } else {
      this.setLoading(true);
    }
    this.patientService
    .checkIsMr_NoExists(patient)
    .then((res: HttpResponse<any>) => {
      if (res && res.result) {
        console.log(res.result.isMr_No);
       // this.state.patient.isMr_No_Error=res.result.isMr_No;
       if(res.result.isMr_No){
        this.setState({mrnError:true});
       }else{
        this.setState({mrnError:false});
       }
      
        //this.setState(patie)
        // this.setState(
        //   { reRenderForm: true
        //   },
        //   () => this.setState({ reRenderForm: false }))
   
      }else{
        this.setState({mrnError:false});
      }
    })
    .catch((ex) => {
      this.setLoading(false);
    });
  }
  isEmailExists=(data: any)=>{
    let patient = data.model;
    if (this.state.isLoading) {
      return;
    }
    if (data.isAutoSave) {
      this.setLoading(false);
    } else {
      this.setLoading(true);
    }
    this.patientService
    .checkIsEmailExists(patient)
    .then((res: HttpResponse<any>) => {
      if (res && res.result) {
       if(res.result.isEmail==true){
        this.setState({isEmailError:true,isEmailErrorMessage:'Email Already Exists.'});
       }else{
        this.setState({isEmailError:false});
       }   
      }else{
        this.setState({isEmailError:false});
      }
    })
    .catch((ex) => {
      this.setLoading(false);
    });
  }
  onEditClick = () => {
    this.setState({ isReadonly: false });
  };

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
      //buttonHide:loading
    });
  }

  postData = (data: any) => {
    let patient = data.model;
    let isUpdate = false;
    if (patient.id) {
      isUpdate = true;
    }
    patient.guardianId = this.state.patient.guardianId;
    if (this.state.isLoading || this.state.mrnError || this.state.isEmailError) {
      return;
    }
    if (data.isAutoSave) {
      this.setLoading(false);
    } else {
      this.setLoading(true);
    }
    this.patientService
      .postUser(patient)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
          let patientObj = this.state.patient;
          patientObj.id = res.result.patientId;
          patientObj.guardianId = res.result.guardianId;
          patientObj.addressModel.addressId = null;

          if (patient.isInviteSend) {
            ShowSuccessMessage("Invite Sent Successfully.");
          } else if (isUpdate && !data.isAutoSave) {
            ShowSuccessMessage("Data Updated Successfully.");
          } else if (!data.isAutoSave) {
            ShowSuccessMessage("Data Saved Successfully.");
          }
          this.setState({
            patient: patientObj,
          });

          this.setLoading(false);
          if (!data.isAutoSave) {
            if (patient.id != null && patient.id != "") {
              this.props.onPatientSave();
              this.setState(
                {
                  patient: patient,
                  isReadonly: true,
                  reRenderForm: true,
                },
                () => this.setState({ reRenderForm: false })
              );
              this.props.navigate(
                "/patient/addPatient/" + res.result.patientId
              ); //for now coz of autosav
            } else {
              this.props.navigate(
                "/patient/addPatient/" + res.result.patientId
              );
            }
          }
        }
      })
      .catch((ex) => {
        this.setLoading(false);
      });
  };
  postPatient = (data: any) => {
    console.log("postPatientData: ", data)
    let patient = data.model;
    patient.id=this.state.patient.id;
    patient.guardianId=this.state.patient.guardianId;
    if (this.state.isLoading || this.state.mrnError) {
      return;
    }
    console.log("Patient", patient)
    this.setLoading(true);
    this.patientService
      .postPatient(patient)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
          let patientObj = this.state.patient;
          patientObj.id = res.result.patientId;
          patientObj.guardianId = res.result.guardianId;
          patientObj.addressModel.addressId = null;
          this.setState({
            patient: patientObj,
          });
          this.setLoading(false);
        }
      })
      .catch((ex) => {
        this.setLoading(false);
      });
  };
  postGuardian = (data: any) => {
    var re = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    let patient = data.model;
    if(!re.test(patient.emailId)){
      this.setState({isEmailError:true,isEmailErrorMessage:"Email is not valid."});
      return;
    }
    patient.id=this.state.patient.id;
    patient.guardianId=this.state.patient.guardianId;
    if (this.state.isLoading  || this.state.isEmailError) {
      return;
    }
    this.setLoading(true);
    this.patientService
      .postGuardian(patient)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
          let patientObj = this.state.patient;
          patientObj.id = res.result.patientId;
          patientObj.guardianId = res.result.guardianId;
          this.setState({
            patient: patientObj,
          });
          this.setLoading(false);
        }
      })
      .catch((ex) => {
        this.setLoading(false);
      });
  };

  getPatientFromEhrByMrn = (mrn: string) => {
    this.setLoading(true);
    this.setState({ patient: {
      addressModel: {} as AddressModel,
    } as AddPatientModel,})
    this.patientService.getPatientFromEhrByMRN(mrn)
    .then((res: HttpResponse<PatientDetailModel>) => {
      if(res && res.result){
        if (res && res.result && res.result.mr_No ){
          ShowSuccessMessage("Patient Matched on MRN");
          let patient: AddPatientModel = {
            id: res.result.id,
            guardianId: res.result.guardianId,
            mr_No: res.result.mr_No,
            firstName: res.result.firstName,
            lastName: res.result.lastName,
            dateOfBirth: res.result.dateOfBirth,
            gender: res.result.gender,
            relationshipId: res.result.relationshipId,
            guardianFirstName: res.result.guardianFirstName,
            guardianLastName: res.result.guardianLastName,
            contactNo: res.result.contactNo,
            emailId: res.result.emailId,
            addressModel: {
              address1: res.result.addressModel.address1,
              address2: res.result.addressModel.address2,
              city: res.result.addressModel.city,
              state: res.result.addressModel.state,
              zipcode: res.result.addressModel.zipcode,
              //  addressId: res.result.addressModel.addressId,
            },
            physicianModel: {
              id: res.result.physicianId,
              pLastName: res.result.physicianLastName,
              speciality: res.result.speciality,
              hospitalName: res.result.hospitalName,
              pFirstName: res.result.physicianFirstName,
            },
            identityId: res.result.identityId,
          };
          this.setState(
            {
              patient: patient,
              isReadonly: true,
              reRenderForm: true,
              currentUserName:
                res.result.lastName + ", " + res.result.firstName,
            },
            () => this.setState({ reRenderForm: false })
          );
        } else {
          ShowWarningMessage("MRN not found");
          this.setState({ isReadonly: false });
        }
        this.setLoading(false);
      }
      else {
        ShowErrorMessage("MRN not found");
        this.setState({ isReadonly: false });
        this.setLoading(false);
      }
    })
    .catch((ex) => {
      this.setLoading(false);
    });
  };

  resetForm = () =>{
      this.setState(this.getInitialState());
      this.setState({ reRenderForm: true }, () =>
        this.setState({ reRenderForm: false })
      );
  };
  render() {
    const { tab } = this.state;
    return (
      <div>
        <div className="row">
          <div className="patient__tabs_wrap">
            <div className="patient__tabs">
              <Tabs
                styleName="patientTab"
                text="Child & Parent Information"
                steps="Step 1 of 2"
                stepsAct="Step 1 of 2"
                fullText="Child & Parent Information"
                familyTextName=""
                image={family}
                activeImage={parentMissingHover}
                tabChange={() => this.changeTab(0)}
                selected={tab === 0}
              />
              <Tabs
                styleName="patientTab"
                text="Physician & Medication Information"
                steps="Step 2 of 2"
                stepsAct="Step 2 of 2"
                fullText="Physician & Medication Information"
                image={medical}
                familyTextName=""
                activeImage={medicalIcon}
                tabChange={() => this.ChangeTabIfParentExist(1)}
                selected={tab === 1}
              />
            </div>
          </div>
        </div>
        {tab === 0 && (
          <Fragment>
            {!this.state.reRenderForm && (
              <AddPatientForm
                isReadOnly={this.state.isReadonly}
                isSaving={this.state.isLoading}
                onEditClick={this.onEditClick}
                onSubmit={this.postData}
                isMrn_No_Exists={this.isMrn_No_Exists}
                isEmailExists={this.isEmailExists}
                patient={this.state.patient}
                tab={this.state.tab}
                mrnError={this.state.mrnError}
                isEmailError={this.state.isEmailError}
                onPatientSubmit={this.postPatient}
                onGuardianSubmit={this.postGuardian}
                isEmailErrorMessage={this.state.isEmailErrorMessage}
                getPatientFromEhrByMrn={this.getPatientFromEhrByMrn}
                resetForm ={this.resetForm}
              />
            )}
          </Fragment>
        )}
        {tab === 1 && !this.state.reRenderForm && (
          <div>
            <PhysicianMedication
              isReadOnly={this.state.isReadonly}
              isSaving={this.state.isLoading}
              physician={this.state.patient.physicianModel}
              patientId={this.state.patient.id}
              tabChange={() => this.changeTab(0)}
            />
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(AddPatient);
