import React, { Fragment } from "react";
import { FamilyBackgroundDetailModel } from "../../../../../../models/familyBackgroundDetail/family-background-detail.model";
import { LearningBehaviorType } from "../../../../../../shared/enums/learningBehavior/learning-behavior-type";
import { MemberType } from "../../../../../../shared/enums/member-type.enum";
import { Loader } from "../../../../../../shared/loaders";
import ChildGuardianInfo from "../../../../../childGuardianInfo";
import Heading from "../../../../../heading";

interface IProps {
    patientId: string;
    isLoading: boolean;
    familyBackgroundDetail: FamilyBackgroundDetailModel;
  }
  export default function FamilyBackgroundForm(props: IProps) {
    return (
        <div className="region_table">
        {!props.isLoading &&    (<div>
        <Heading
          heading="Birth Parents"
          subHeading=""
          styleName="medicalGeneralDetail__style"
        />
        {/* mother */}
        <div className="SMH__accord__content">
          <ChildGuardianInfo
            designation="Birth Mother's Name"
            name={props.familyBackgroundDetail.birthParentDetail?.motherName}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Current Profession/Organisation"
            name={props.familyBackgroundDetail.birthParentDetail?.motherProfession}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Present Age"
            name={String(props.familyBackgroundDetail.birthParentDetail?.motherAge==null?"":props.familyBackgroundDetail.birthParentDetail?.motherAge)}
            email=""
            guardian="guardianStyle"
          />
        </div>
        <div className="SMH__accord__content">
          <ChildGuardianInfo
            designation="Live with child full time"
            name={(props.familyBackgroundDetail.birthParentDetail?.isMotherLiveWithChildFullTime ==null? "": (!props.familyBackgroundDetail.birthParentDetail?.isMotherLiveWithChildFullTime)? "No" : "Yes")}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Highest level of formal education completed and degrees/certificates earned"
            name={String(props.familyBackgroundDetail.birthParentDetail?.motherHighestLevelEducationText==null?"":props.familyBackgroundDetail.birthParentDetail?.motherHighestLevelEducationText)}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Marital Status"
            name={String(props.familyBackgroundDetail.birthParentDetail?.motherMaritalStatusText==null?"":props.familyBackgroundDetail.birthParentDetail?.motherMaritalStatusText)}
            email=""
            guardian="guardianStyle"
          />
        </div>
        {/* father */}
        <div className="SMH__accord__content">
          <ChildGuardianInfo
            designation="Birth Father's Name"
            name={props.familyBackgroundDetail.birthParentDetail?.fatherName}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Current Profession/Organisation"
            name={props.familyBackgroundDetail.birthParentDetail?.fatherProfession}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Present Age"
            name={String(props.familyBackgroundDetail.birthParentDetail?.fatherAge==null?"":props.familyBackgroundDetail.birthParentDetail?.fatherAge)}
            email=""
            guardian="guardianStyle"
          />
        </div>
        <div className="SMH__accord__content">
          <ChildGuardianInfo
            designation="Live with child full time"
            name={(props.familyBackgroundDetail.birthParentDetail?.isFatherLiveWithChildFullTime ==null?"":  (!props.familyBackgroundDetail.birthParentDetail?.isFatherLiveWithChildFullTime)? "No" : "Yes")}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Highest level of formal education completed and degrees/certificates earned"
            name={String(props.familyBackgroundDetail.birthParentDetail?.fatherHighestLevelEducationText==null?"":props.familyBackgroundDetail.birthParentDetail?.fatherHighestLevelEducationText)}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Marital Status"
            name={String(props.familyBackgroundDetail.birthParentDetail?.fatherMaritalStatusText==null?"":props.familyBackgroundDetail.birthParentDetail?.fatherMaritalStatusText )}
            email=""
            guardian="guardianStyle"
          />
        </div>
        {/* resides with */}
        <div className="SMH__accord__content">
          <ChildGuardianInfo
            designation="Child resides with"
            name={String(props.familyBackgroundDetail.birthParentDetail?.childResidesWithText==null?"":props.familyBackgroundDetail.birthParentDetail?.childResidesWithText)}
            email=""
            guardian="guardianStyle"
          />
           {
              (props.familyBackgroundDetail.birthParentDetail != null && props.familyBackgroundDetail.birthParentDetail?.specifyChildResidesWith != "" && props.familyBackgroundDetail.birthParentDetail?.childResidesWithText == "Other" ) &&
              <ChildGuardianInfo
                designation="If other, please specify"
                name={props.familyBackgroundDetail.birthParentDetail?.specifyChildResidesWith}
                email=""
                guardian="guardianStyle"
          />}
          <ChildGuardianInfo
            designation="Contact with birth parent is"
            name={String(props.familyBackgroundDetail.birthParentDetail?.contactWithBirthParentText==null?"":props.familyBackgroundDetail.birthParentDetail?.contactWithBirthParentText)}
            email=""
            guardian="guardianStyle"
          />
        </div>
        <div className="SMH__content_row">
          <ChildGuardianInfo
            designation="Comments"
            name={props.familyBackgroundDetail.birthParentDetail?.comments ==null ?"": props.familyBackgroundDetail.birthParentDetail?.comments}
            email=""
            guardian="guardianStyle"
          />
        </div>
        <hr />
        {/* primary caretaker */}
        <Heading
          heading="Primary Caretaker"
          subHeading=""
          styleName="medicalGeneralDetail__style"
        />
        {/* mother */}
        <div className="SMH__accord__content">
          <ChildGuardianInfo
            designation="Relationship"
            name={props.familyBackgroundDetail.primaryCareTakerDetail?.firstCareTakerRelationshipText}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Full Name"
            name={props.familyBackgroundDetail.primaryCareTakerDetail?.firstCareTakerName}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Current Profession/Organisation"
            name={props.familyBackgroundDetail.primaryCareTakerDetail?.firstCareTakerCurrentProfession}
            email=""
            guardian="guardianStyle"
          />
        </div>
        <div className="SMH__accord__content">
          <ChildGuardianInfo
            designation="Present Age"
            name={String(props.familyBackgroundDetail.primaryCareTakerDetail?.firstCareTakerAge==null?"":props.familyBackgroundDetail.primaryCareTakerDetail?.firstCareTakerAge)}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Highest level of formal education completed and degrees/certificates earned"
            name={props.familyBackgroundDetail.primaryCareTakerDetail?.firstCareTakerHighestLevelEducationText ==null?"":props.familyBackgroundDetail.primaryCareTakerDetail?.firstCareTakerHighestLevelEducationText}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Live with child full time"
            name={(props.familyBackgroundDetail.primaryCareTakerDetail?.isFirstCareTakerLiveWithChildFullTime ==null ? ""  : (!props.familyBackgroundDetail.primaryCareTakerDetail?.isFirstCareTakerLiveWithChildFullTime)? "No" : "Yes")}
            email=""
            guardian="guardianStyle"
          />
        </div>
        <div className="SMH__content_row">
          <ChildGuardianInfo
            designation="Marital Status"
            name={props.familyBackgroundDetail.primaryCareTakerDetail?.firstCareTakerMaritalStatusText}
            email=""
            guardian="guardianStyle"
          />
        </div>
        {/* father */}
        <div className="SMH__accord__content">
          <ChildGuardianInfo
            designation="Relationship"
            name={props.familyBackgroundDetail.primaryCareTakerDetail?.secondCareTakerRelationshipText}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Full Name"
            name={props.familyBackgroundDetail.primaryCareTakerDetail?.secondCareTakerName}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Current Profession/Organisation"
            name={props.familyBackgroundDetail.primaryCareTakerDetail?.secondCareTakerCurrentProfession}
            email=""
            guardian="guardianStyle"
          />
        </div>
        <div className="SMH__accord__content">
          <ChildGuardianInfo
            designation="Present Age"
            name={String(props.familyBackgroundDetail.primaryCareTakerDetail?.secondCareTakerAge==null?"":props.familyBackgroundDetail.primaryCareTakerDetail?.secondCareTakerAge)}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Highest level of formal education completed and degrees/certificates earned"
            name={props.familyBackgroundDetail.primaryCareTakerDetail?.secondCareTakerHighestLevelEducationText==null?"":props.familyBackgroundDetail.primaryCareTakerDetail?.secondCareTakerHighestLevelEducationText}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Live with child full time"
            name={(props.familyBackgroundDetail.primaryCareTakerDetail?.isSecondCareTakerLiveWithChildFullTime ==null ? ""  : (!props.familyBackgroundDetail.primaryCareTakerDetail?.isSecondCareTakerLiveWithChildFullTime)? "No" : "Yes")}
            email=""
            guardian="guardianStyle"
          />
        </div>
        <div className="SMH__content_row">
          <ChildGuardianInfo
            designation="Marital Status"
            name={props.familyBackgroundDetail.primaryCareTakerDetail?.secondCareTakerMaritalStatusText}
            email=""
            guardian="guardianStyle"
          />
        </div>
        {/* resides with */}
        <div className="SMH__accord__content">
          <ChildGuardianInfo
            designation="Name"
            name={props.familyBackgroundDetail.primaryCareTakerDetail?.name}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Relationship"
            name={props.familyBackgroundDetail.primaryCareTakerDetail?.relationship}
            email=""
            guardian="guardianStyle"
          />
        </div>
        <hr />
        <Heading
          heading="Siblings & Birth Orders"
          subHeading=""
          styleName="medicalGeneralDetail__style"
        />
        {(props.familyBackgroundDetail.siblingList == null || props.familyBackgroundDetail.siblingList.length==0) &&   <div className="SMH__accord__sibContent">
               <ChildGuardianInfo
               designation="Name"
               name=""
               email=""
               guardian="guardianStyle"
             />
             <ChildGuardianInfo 
             designation="Age" 
             name="" 
             email="" 
             guardian="guardianStyle" />
             <ChildGuardianInfo
               designation="Sister/Brother"
               name=""
               email=""
               guardian="guardianStyle"
             />
             <ChildGuardianInfo
               designation="Relationship"
               name=""
               email=""
               guardian="guardianStyle"
             />
             </div>
        }
           {(props.familyBackgroundDetail.siblingList != null && props.familyBackgroundDetail.siblingList.length>0) &&
              props.familyBackgroundDetail.siblingList.map((sibling, index) => {
                return (
        <div className="SMH__accord__sibContent" key={index}>
          <ChildGuardianInfo
            designation="Name"
            name={sibling.name}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo 
          designation="Age" 
          name={String(sibling.age)} 
          email="" 
          guardian="guardianStyle" />
          <ChildGuardianInfo
            designation="Sister/Brother"
            name={sibling.siblingTypeText}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Relationship"
            name={sibling.relationshipTypeText}
            email=""
            guardian="guardianStyle"
          />
        </div>
        )})}
        <hr />
         {/* home composition */}
        <Heading
          heading="Home Composition"
          subHeading=""
          styleName="medicalGeneralDetail__style"
        />
         
         {(props.familyBackgroundDetail.homeCompositionList == null || props.familyBackgroundDetail.homeCompositionList.length==0) &&
           (  <div>
             <p className="family__member">Family members</p> 
            <div className="SMH__accord__homeContent">
             <ChildGuardianInfo
               designation="Name"
               email=""
               guardian="guardianStyle"
             />
             <ChildGuardianInfo
               designation="Time in Home"
               email=""
               guardian="guardianStyle"
             />
             <ChildGuardianInfo
               designation="Relationship"
               email=""
               guardian="guardianStyle"
             />
           </div>
           <p className="family__member">Non Family members</p> 
            <div className="SMH__accord__homeContent">
             <ChildGuardianInfo
               designation="Name"
               email=""
               guardian="guardianStyle"
             />
             <ChildGuardianInfo
               designation="Time in Home"
               email=""
               guardian="guardianStyle"
             />
             <ChildGuardianInfo
               designation="Relationship"
               email=""
               guardian="guardianStyle"
             />
           </div>
           </div>
        )
         }
        {(props.familyBackgroundDetail.homeCompositionList != null && props.familyBackgroundDetail.homeCompositionList.filter(t=>t.memberType==MemberType.Family).length>0 &&  <p className="family__member">Family members</p> )}
        {(props.familyBackgroundDetail.homeCompositionList != null && props.familyBackgroundDetail.homeCompositionList.length>0) &&
              props.familyBackgroundDetail.homeCompositionList.filter(t=>t.memberType==MemberType.Family).map((homeComposition, index) => {
                return (        
        <div key={index} className="SMH__accord__homeContent">
          <ChildGuardianInfo
            designation="Name"
            name={homeComposition.name}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Time in Home"
            name={homeComposition.timeInHouse}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Relationship"
            name={homeComposition.relationship}
            email=""
            guardian="guardianStyle"
          />
        </div>
         );
        })}
        {(props.familyBackgroundDetail.homeCompositionList != null && props.familyBackgroundDetail.homeCompositionList.filter(t=>t.memberType==MemberType.NonFamily).length>0 &&  <p className="family__member">Non-family members</p> )}
         {props.familyBackgroundDetail.homeCompositionList != null &&
              props.familyBackgroundDetail.homeCompositionList.filter(t=>t.memberType==MemberType.NonFamily).map((homeComposition, index) => {
                return (   
        <div key={index} className="SMH__accord__homeContent">
                <ChildGuardianInfo
            designation="Name"
            name={homeComposition.name}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Time in Home"
            name={homeComposition.timeInHouse}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Relationship"
            name={homeComposition.relationship}
            email=""
            guardian="guardianStyle"
          />
        </div>
         );
        })}
        <hr />
        {/* learning */}
        <Heading
          heading="Learning, Behaviour, Mental"
          subHeading=""
          styleName="medicalGeneralDetail__style"
        />
          <div className="learning__table">
            <ChildGuardianInfo 
              designation="Attention" 
              name={props.familyBackgroundDetail.learningBehaviorList?.find(x => x.typeId == LearningBehaviorType.Attention)?.isSelected == true ? "Yes" : "No"} 
              email=""
              guardian="guardianStyle" />
            <ChildGuardianInfo designation="Relation to child" name={props.familyBackgroundDetail.learningBehaviorList?.find(x=>x.typeId==LearningBehaviorType.Attention)?.isSelected==true?props.familyBackgroundDetail.learningBehaviorList?.find(x=>x.typeId==LearningBehaviorType.Attention)?.relationship:""} email="" guardian="guardianStyle"/>
          </div>
          <div className="learning__table">
            <ChildGuardianInfo
              designation="Conduct/Behaviour"
              name={props.familyBackgroundDetail.learningBehaviorList?.find(x => x.typeId == LearningBehaviorType.ConductBehaviour)?.isSelected == true ? "Yes" : "No"}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Relation to child"
              name={props.familyBackgroundDetail.learningBehaviorList?.find(x=>x.typeId==LearningBehaviorType.ConductBehaviour)?.isSelected==true?props.familyBackgroundDetail.learningBehaviorList?.find(x=>x.typeId==LearningBehaviorType.ConductBehaviour)?.relationship:""}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="learning__table">
            <ChildGuardianInfo
              designation="Hyperactivity"
              name={props.familyBackgroundDetail.learningBehaviorList?.find(x => x.typeId == LearningBehaviorType.Hyperactivity)?.isSelected == true ? "Yes" : "No"}
              email=""
              guardian="guardianStyle"
            />
             <ChildGuardianInfo
              designation="Relation to child"
              name={props.familyBackgroundDetail.learningBehaviorList?.find(x=>x.typeId==LearningBehaviorType.Hyperactivity)?.isSelected==true?props.familyBackgroundDetail.learningBehaviorList?.find(x=>x.typeId==LearningBehaviorType.Hyperactivity)?.relationship:""}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="learning__table">
            <ChildGuardianInfo
              designation="Learning"
              name={props.familyBackgroundDetail.learningBehaviorList?.find(x => x.typeId == LearningBehaviorType.Learning)?.isSelected == true ? "Yes" : "No"}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Relation to child"
              name={props.familyBackgroundDetail.learningBehaviorList?.find(x=>x.typeId==LearningBehaviorType.Learning)?.isSelected==true?props.familyBackgroundDetail.learningBehaviorList?.find(x=>x.typeId==LearningBehaviorType.Learning)?.relationship:""}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="learning__table">
          <ChildGuardianInfo
            designation="Mental/Health"
            name={props.familyBackgroundDetail.learningBehaviorList?.find(x => x.typeId == LearningBehaviorType.MentalHealth)?.isSelected == true ? "Yes" : "No"}
            email=""
            guardian="guardianStyle"
          />
            <ChildGuardianInfo
            designation="Relation to child"
            name={props.familyBackgroundDetail.learningBehaviorList?.find(x=>x.typeId==LearningBehaviorType.MentalHealth)?.isSelected==true?props.familyBackgroundDetail.learningBehaviorList?.find(x=>x.typeId==LearningBehaviorType.MentalHealth)?.relationship:""}
            email=""
            guardian="guardianStyle"
          />
          </div>
          <div className="learning__table" style={{borderBottom: '1px solid rgba(0,0,0,0.1)'}}>
          <ChildGuardianInfo
            designation="Speech/Language"
            name={props.familyBackgroundDetail.learningBehaviorList?.find(x => x.typeId == LearningBehaviorType.SpeechLanguage)?.isSelected == true ? "Yes" : "No"}
            email=""
            guardian="guardianStyle"
          />
           <ChildGuardianInfo
            designation="Relation to child"
            name={props.familyBackgroundDetail.learningBehaviorList?.find(x=>x.typeId==LearningBehaviorType.SpeechLanguage)?.isSelected==true?props.familyBackgroundDetail.learningBehaviorList?.find(x=>x.typeId==LearningBehaviorType.SpeechLanguage)?.relationship:""}
            email=""
            guardian="guardianStyle"
          />
          </div>
        <hr />
        {/* family transition */}
        <Heading
          heading="Family Transition"
          subHeading=""
          styleName="medicalGeneralDetail__style"
        />
        <div className="SMH__accord__homeContent">
          <ChildGuardianInfo
            designation="Parents divorced/separated"
            name={(props.familyBackgroundDetail.familyTransitionDetail?.parentDivorced ==null ?"" : (!props.familyBackgroundDetail.familyTransitionDetail?.parentDivorced)? "No" : "Yes")}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Family member experienced prolonged illness"
            name={(props.familyBackgroundDetail.familyTransitionDetail?.familyMemberExpIllness ==null ?"" : (!props.familyBackgroundDetail.familyTransitionDetail?.familyMemberExpIllness)? "No" : "Yes")}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Death of family member"
            name={(props.familyBackgroundDetail.familyTransitionDetail?.deathOfFamilyMember ==null ?"" : (!props.familyBackgroundDetail.familyTransitionDetail?.deathOfFamilyMember)? "No" : "Yes")}
            email=""
            guardian="guardianStyle"
          />
        </div>
        <div className="SMH__accord__homeContent">
          <ChildGuardianInfo
            designation="Sibling left home"
            name={(props.familyBackgroundDetail.familyTransitionDetail?.siblingLeftHome ==null ?"" : (!props.familyBackgroundDetail.familyTransitionDetail?.siblingLeftHome)? "No" : "Yes")}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Child changed schools"
            name={(props.familyBackgroundDetail.familyTransitionDetail?.childChangedSchool ==null ?"" : (!props.familyBackgroundDetail.familyTransitionDetail?.childChangedSchool)? "No" : "Yes")}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Child experienced prolonged separation from parents"
            name={(props.familyBackgroundDetail.familyTransitionDetail?.childExpSeparationFromParents ==null ?"" : (!props.familyBackgroundDetail.familyTransitionDetail?.childExpSeparationFromParents)? "No" : "Yes")}
            email=""
            guardian="guardianStyle"
          />
        </div>
        <div className="SMH__accord__homeContent">
          <ChildGuardianInfo
            designation="Child had a prolonged illness"
            name={(props.familyBackgroundDetail.familyTransitionDetail?.childHadProlongedIllness ==null ?"" : (!props.familyBackgroundDetail.familyTransitionDetail?.childHadProlongedIllness)? "No" : "Yes")}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Parents experienced significant marital conflict"
            name={(props.familyBackgroundDetail.familyTransitionDetail?.parentExpSeparationMartialConflict ==null ?"" : (!props.familyBackgroundDetail.familyTransitionDetail?.parentExpSeparationMartialConflict)? "No" : "Yes")}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Death of a close friend"
            name={(props.familyBackgroundDetail.familyTransitionDetail?.deathOfClosedFriend ==null ?"" : (!props.familyBackgroundDetail.familyTransitionDetail?.deathOfClosedFriend)? "No" : "Yes")}
            email=""
            guardian="guardianStyle"
          />
        </div>
        <div className="SMH__accord__homeContent">
          <ChildGuardianInfo
            designation="Child experienced prolonged separation from close friends"
            name={(props.familyBackgroundDetail.familyTransitionDetail?.childHadProlongedIllness ==null ?"" : (!props.familyBackgroundDetail.familyTransitionDetail?.childHadProlongedIllness)? "No" : "Yes")}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Family changed residence - if so, number of times in the last 3 years?"
            name={String((props.familyBackgroundDetail.familyTransitionDetail?.familyChangedResidenceNo == null || props.familyBackgroundDetail.familyTransitionDetail?.familyChangedResidenceNo == 0)? "-" : props.familyBackgroundDetail.familyTransitionDetail?.familyChangedResidenceNo)}
            email=""
            guardian="guardianStyle"
          />
          <ChildGuardianInfo
            designation="Parent(s) changed job - if so, number of times in the last 3 years?"
                        name={String((props.familyBackgroundDetail.familyTransitionDetail?.parentChangedJobNo == null || props.familyBackgroundDetail.familyTransitionDetail?.parentChangedJobNo == 0) ? "-" : props.familyBackgroundDetail.familyTransitionDetail?.parentChangedJobNo)}
            email=""
            guardian="guardianStyle"
          />
        </div>
        <div className="SMH__content_row">
          <ChildGuardianInfo
            designation="Comments"
            name={(props.familyBackgroundDetail.familyTransitionDetail?.comments ==null)? "" : props.familyBackgroundDetail.familyTransitionDetail?.comments}
            email=""
            guardian="guardianStyle"
          />
        </div>
        </div>)}
        <Loader
        loading={props.isLoading}
        marginBottom="0px"
        marginTop="8px"
        width="368px"
      ></Loader>
        </div>
        );
  }


