import { AppConfig, HttpWrapper } from "../../core";
import { MedicationStatusModel } from "../../models/medication/medication-status.model";
import { TeacherDetailModel } from "../../models/teacher/teacher-detail.model";
import { TeacherListFilterModel } from "../../models/teacher/teacher-list-filter.model";
import { TeacherListResponseModel } from "../../models/teacher/teacher-list-response.model";
import { TeacherAdditionalQuestionWeeklyData } from "../../models/teacherWeeklyData/teacher-additional-question-weekly-data.model";
import { TeacherWeeklyDataModel } from "../../models/teacherWeeklyData/teacher-weekly-data.model";

export class TeacherWeeklyDataService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getTeacherWeekDayData(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint + "teacherWeeklyData/detail/" + patientId
    );
  }

  postTeacherTargetSymptomsData(request: TeacherWeeklyDataModel) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "teacherWeeklyData/create/target-symptoms",
      request
    );
  }
  getPatientListByTeacher(filterModel: TeacherListFilterModel) {
    return this.wrapper.post<TeacherListResponseModel>(
      AppConfig.apiEndpoint + "patient/teacher/list",
      filterModel
    );
  }
  getUserById(id?: string | null) {
    return this.wrapper.get<TeacherDetailModel>(
      AppConfig.apiEndpoint + "patient/detail/" + id
    );
  }
  getMedicationStatusByPatientId(id?: string | null) {
    return this.wrapper.get<MedicationStatusModel[]>(
      AppConfig.apiEndpoint + "medication/status/" + id
    );
  }
  
  postTeacherAdditionalQuestionWeeklyData(request: TeacherAdditionalQuestionWeeklyData) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "teacherWeeklyData/additional/ques",
      request
    );
  }
}
