import React, { Component } from "react";
import { Drawer } from "antd";
import Tabs from "../../../../tabs";
import "../styles.css";
import ParentTeacherListing from "./ParentTeacherListing";
import { ClinicianPatientTrialWeekNoteListModel } from "../../../../../models/notes/clinicianNotes/clinician-patient-trial-week-note-list.model";
import { PatientTrialNotesService } from "../../../../../services/notes/patient-trial-notes.service";
import moment from "moment";
import dayjs from "dayjs";
import { RespondentPatientTrialWeekNoteListModel } from "../../../../../models/notes/clinicianNotes/respondent-trial-week-note-list.model";

interface IProps {
  patientId: string;
  visible: boolean;
  hideForm: Function;
}
interface IState {
  respondentWeeklyNotes: RespondentPatientTrialWeekNoteListModel[]; 
  selectedWeekNumber: number,
  isSaving: boolean;
}
class ParentTeacherNote extends Component<any, IState> {
  private notesService: PatientTrialNotesService;
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedWeekNumber: 0,
      respondentWeeklyNotes: [],
      isSaving: false
    };
    this.notesService = new PatientTrialNotesService();
  }
  componentDidUpdate(prevProps: any) {
    if (prevProps && this.props && this.props.visible && prevProps.visible == false) {
      this.loadNotes();
    }
  }

  changeTab(weekNo: any, weekType: number) {
    if (weekType == 3)//future
    {
      return;
    }
    this.setState({
      selectedWeekNumber: weekNo
    });
  }
  componentDidMount() {
    this.loadNotes();
  }
  loadNotes() {
    if (this.props.patientId) {
      this.notesService.getAllRespondentWeeklyNotes(this.props.patientId).then(res => {
        if (res.result) {
          let selectedWeekNo = res.result.find(x => x.weekType == 1) != null ? 1 : 0;
          let currentWeek = res.result.find(x => x.weekType == 2); //current week
          selectedWeekNo = currentWeek ? currentWeek.weekNo : selectedWeekNo;
          this.setState({respondentWeeklyNotes: res.result, selectedWeekNumber: selectedWeekNo });
        }
      });
     
    }
  }
  render() {
    const { visible, hideForm } = this.props;
    const { selectedWeekNumber } = this.state;
    return (
      <div className="patientNote__drawer">
        <Drawer title="Notes of Parent/Teacher" width={920} closable={false} visible={visible}>
          <i className="fas fa-arrow-left hideSearch_form" onClick={() => hideForm()}></i>
          <div className="weekly__tabs_wrapper">

            <div className="weekly__tabs">
              {this.state.respondentWeeklyNotes?.map((week) => {
                return <Tabs key={"week_tab_" + week.weekNo}
                  styleName={week.weekType == 3 ? "weekly_tabsNote remove-cursor" : "weekly_tabsNote add-cursor"}
                  text={dayjs(week.patientTrialWeekStartDate).format("MM/DD/YYYY")}
                  steps={"Week " + week.weekNo}
                  stepsAct={"Week " + week.weekNo}
                  fullText={dayjs(week.patientTrialWeekStartDate).format("MM/DD/YYYY")}
                  familyTextName=""
                  image=""
                  activeImage=""
                  tabChange={() => this.changeTab(week.weekNo, week.weekType)}
                  selected={selectedWeekNumber === week.weekNo}
                />

              })}

            </div>
            <div className="weekly__note__content">
              {this.props.visible &&
                <ParentTeacherListing hideForm={hideForm} 
                selectedWeekNumber={this.state.selectedWeekNumber} 
                respondentWeeklyNotes={this.state.respondentWeeklyNotes}
                />
              }
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}
export default ParentTeacherNote;
