import { AppConfig, HttpWrapper } from "../../core";
import { ClinicianPatientTrialWeekNoteListModel } from "../../models/notes/clinicianNotes/clinician-patient-trial-week-note-list.model";
import { ClinicianPatientTrialWeekNoteModel } from "../../models/notes/clinicianNotes/clinician-patient-trial-week-note.model";
import { RespondentPatientTrialWeekNoteListModel } from "../../models/notes/clinicianNotes/respondent-trial-week-note-list.model";
import { GetPatientTrialNoteModel } from "../../models/notes/getPatientTrialNoteModel";
import { PatientTrialNoteModel } from "../../models/notes/patientTrialNote";

export class PatientTrialNotesService {
    private wrapper: HttpWrapper;
    constructor() {
        this.wrapper = new HttpWrapper();
    }

    saveClinicianWeeklyNote(note: ClinicianPatientTrialWeekNoteModel) {
        return this.wrapper.post<ClinicianPatientTrialWeekNoteModel>(
            AppConfig.apiEndpoint + "notes/patienttrials/clinician/add",
            note
        );
    }
    getClinicianWeeklyNotes(patientId: string) {
        return this.wrapper.get<ClinicianPatientTrialWeekNoteListModel[]>(
            AppConfig.apiEndpoint + "notes/patienttrials/clinician/list/" + patientId
        );
    }
    saveParentWeeklyNote(note: ClinicianPatientTrialWeekNoteModel) {
        return this.wrapper.post<ClinicianPatientTrialWeekNoteModel>(
            AppConfig.apiEndpoint + "notes/patienttrials/parent/add",
            note
        );
    }
    getParentWeeklyNotes(patientId: string) {
        return this.wrapper.get<ClinicianPatientTrialWeekNoteListModel[]>(
            AppConfig.apiEndpoint + "notes/patienttrials/parent/list/" + patientId
        );
    }
    saveTeacherWeeklyNote(note: ClinicianPatientTrialWeekNoteModel) {
        return this.wrapper.post<ClinicianPatientTrialWeekNoteModel>(
            AppConfig.apiEndpoint + "notes/patienttrials/teacher/add",
            note
        );
    }
    getTeacherWeeklyNotes(patientId: string) {
        return this.wrapper.get<ClinicianPatientTrialWeekNoteListModel[]>(
            AppConfig.apiEndpoint + "notes/patienttrials/teacher/list/" + patientId
        );
    }
    getAllRespondentWeeklyNotes(patientId: string){
        return this.wrapper.get<RespondentPatientTrialWeekNoteListModel[]>(
            AppConfig.apiEndpoint + "notes/patienttrials/respondent/list/" + patientId
        );
    }
    getPatientTrialNote(patientId: string){
        return this.wrapper.get<GetPatientTrialNoteModel>(
            AppConfig.apiEndpoint + "notes/patienttrials/patienttrialnote/" + patientId
        );
    }
    savePatientTrialNote(note: PatientTrialNoteModel) {
        return this.wrapper.post<PatientTrialNoteModel>(
            AppConfig.apiEndpoint + "notes/patienttrials/patienttrialnote/save/",
            note
        );
    }
}
