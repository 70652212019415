import * as React from "react";

export class PatientRegistration extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <h2>patient Registration</h2>
    );
  }
}

export default PatientRegistration;
