import { PatientDetailModel } from "../../components/patient/models/patient-detail.model";
import { AppConfig, HttpWrapper } from "../../core";
import { MedicationStatusModel } from "../../models/medication/medication-status.model";
import { ParentWeeklyDataModel } from "../../models/parentWeeklyData/parent-weekly-data.model";

export class PatientWeeklyDataService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getPatientWeeklyData(patientId: string) {
    return this.wrapper.get<any>(AppConfig.apiEndpoint + "parentweekdaydata/detail/" + patientId);
  }
  postParentTargetSymptomsData(request: ParentWeeklyDataModel) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "parentweekdaydata/create/target-symptoms",
      request
    );
  }
  getUserById(id?: string | null) {
    return this.wrapper.get<PatientDetailModel>(AppConfig.apiEndpoint + "patient/detail/" + id);
  }
  getMedicationStatusByPatientId(id?: string | null) {
    return this.wrapper.get<MedicationStatusModel[]>(
      AppConfig.apiEndpoint + "parentweekdaydata/medication/status/" + id
    );
  }

  postParentAdditionalQuestionWeeklyData(request: ParentWeeklyDataModel) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "parentweekdaydata/create/additional-question",
      request
    );
  }
}
