import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "../../styles.css";
import { Loader } from "../../../../shared/loaders";
import Heading from "../../../heading";
import { MaritalStatus } from "../../../../shared/enums/marital-status.enum";
import { Button } from "antd";
import { AddPostnatalPeriodModel } from "../../../../models/birthHistory/postNatal/add-postnatal-period.model";
import { DevTool } from "@hookform/devtools";

interface IProps {
  postnatalPeriodModel: AddPostnatalPeriodModel;
  onSubmit: Function;
  isSaving: boolean;
  patientId: string;
  assignModel: Function;
  isReadOnly: boolean;
  previousTabChange: Function;
  isBirthHistoryOptional: boolean;
}
type AddPostnatalFormValues = {
  breathingDelayed: string | undefined;
  cryDelayed: string | undefined;
  motherInfantReleasedDays: string | undefined;
  babyWeightAtBirth: string | undefined;
  comments: string | undefined;
}
export function AddPostnatalForm(props: IProps) {
  const { register, handleSubmit, control, getValues, formState, setValue } = useForm<AddPostnatalFormValues>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  function setInitialValues(){
    setValue("babyWeightAtBirth", props.postnatalPeriodModel.babyWeightAtBirth);
    setValue("breathingDelayed", props.postnatalPeriodModel.breathingDelayed);
    setValue("comments", props.postnatalPeriodModel.comments);
    setValue("cryDelayed", props.postnatalPeriodModel.cryDelayed);
    setValue("motherInfantReleasedDays", props.postnatalPeriodModel.motherInfantReleasedDays);
  }
  useEffect(() => {
    setInitialValues();
  }, [props]);
  
  let postnatalPeriodModel = props.postnatalPeriodModel;
  const formSubmit = (data) => {
    postnatalPeriodModel.patientId = props.patientId;
    postnatalPeriodModel.breathingDelayed = data.breathingDelayed;
    postnatalPeriodModel.cryDelayed = data.cryDelayed;
    postnatalPeriodModel.babyWeightAtBirth = data.babyWeightAtBirth;
    postnatalPeriodModel.motherInfantReleasedDays = data.motherInfantReleasedDays;
    postnatalPeriodModel.comments = data.comments;
    props.assignModel({ postnatalPeriodModel: postnatalPeriodModel });
    if (checkForError()) {
      return false;
    }
    props.onSubmit({ postnatalPeriodModel: postnatalPeriodModel, isAutoSave: false });
  };
  const checkForError = () => {
    let hasError = false;
    postnatalPeriodModel.breathingDelayedError = "";
    postnatalPeriodModel.cryDelayedError = "";
    postnatalPeriodModel.jaundiceError = "";
    postnatalPeriodModel.cyanosisError = "";
    postnatalPeriodModel.seizuresConvulsionsError = "";
    postnatalPeriodModel.antibioticTreatmentError = "";
    postnatalPeriodModel.specialLightForJaundiceError = "";
    postnatalPeriodModel.oxygenError = "";
    postnatalPeriodModel.resuscitationError = "";
    postnatalPeriodModel.transfusionsError = "";
    postnatalPeriodModel.incubatorCareError = "";
    postnatalPeriodModel.birthDefectsError = "";
    postnatalPeriodModel.breathingError = "";
    postnatalPeriodModel.cryError = "";
    postnatalPeriodModel.babyWeightAtBirthError = "";
    postnatalPeriodModel.motherInfantReleasedDaysError = "";
    if (
      postnatalPeriodModel.breathing == false &&
      (postnatalPeriodModel.breathingDelayed == "" || postnatalPeriodModel.breathingDelayed == null)
    ) {
      postnatalPeriodModel.breathingDelayedError = "Please fill breathing delayed how long.";
      hasError = true;
    }
    if (
      postnatalPeriodModel.cry == false &&
      (postnatalPeriodModel.cryDelayed == "" || postnatalPeriodModel.cryDelayed == null)
    ) {
      postnatalPeriodModel.cryDelayedError = "Please fill cry delayed how long.";
      hasError = true;
    }
    if (postnatalPeriodModel.jaundice == undefined && !props.isBirthHistoryOptional) {
      postnatalPeriodModel.jaundiceError = "Please select an option.";
      hasError = true;
    }
    if (postnatalPeriodModel.breathing == undefined && !props.isBirthHistoryOptional) {
      postnatalPeriodModel.breathingError = "Please select an option.";
      hasError = true;
    }
    if (postnatalPeriodModel.cry == undefined && !props.isBirthHistoryOptional) {
      postnatalPeriodModel.cryError = "Please select an option.";
      hasError = true;
    }
    if (postnatalPeriodModel.cyanosis == undefined && !props.isBirthHistoryOptional) {
      postnatalPeriodModel.cyanosisError = "Please select an option.";
      hasError = true;
    }
    if (postnatalPeriodModel.seizuresConvulsions == undefined && !props.isBirthHistoryOptional) {
      postnatalPeriodModel.seizuresConvulsionsError = "Please select an option.";
      hasError = true;
    }
    if (postnatalPeriodModel.antibioticTreatment == undefined && !props.isBirthHistoryOptional) {
      postnatalPeriodModel.antibioticTreatmentError = "Please select an option.";
      hasError = true;
    }
    if (postnatalPeriodModel.specialLightForJaundice == undefined && !props.isBirthHistoryOptional) {
      postnatalPeriodModel.specialLightForJaundiceError = "Please select an option.";
      hasError = true;
    }
    if (postnatalPeriodModel.oxygen == undefined && !props.isBirthHistoryOptional) {
      postnatalPeriodModel.oxygenError = "Please select an option.";
      hasError = true;
    }
    if (postnatalPeriodModel.resuscitation == undefined && !props.isBirthHistoryOptional) {
      postnatalPeriodModel.resuscitationError = "Please select an option.";
      hasError = true;
    }
    if (postnatalPeriodModel.transfusions == undefined && !props.isBirthHistoryOptional) {
      postnatalPeriodModel.transfusionsError = "Please select an option.";
      hasError = true;
    }
    if (postnatalPeriodModel.incubatorCare == undefined && !props.isBirthHistoryOptional) {
      postnatalPeriodModel.incubatorCareError = "Please select an option.";
      hasError = true;
    }
    if (postnatalPeriodModel.birthDefects == undefined && !props.isBirthHistoryOptional) {
      postnatalPeriodModel.birthDefectsError = "Please select an option.";
      hasError = true;
    }
    if (postnatalPeriodModel.transfusions == undefined && !props.isBirthHistoryOptional) {
      postnatalPeriodModel.transfusionsError = "Please select an option.";
      hasError = true;
    }
    if (postnatalPeriodModel.babyWeightAtBirth == ""  && !props.isBirthHistoryOptional) {
      postnatalPeriodModel.babyWeightAtBirthError = "Please fill baby's weight at birth.";
      hasError = true;
    }
    if (postnatalPeriodModel.motherInfantReleasedDays == ""  && !props.isBirthHistoryOptional) {
      postnatalPeriodModel.motherInfantReleasedDaysError =
        "Please fill days after birth mother and infant were released from the hospital.";
      hasError = true;
    }
    props.assignModel({ postnatalPeriodModel: postnatalPeriodModel });
    return hasError;
  };
  const handleKeyUp = (event) => {
    setValuesInModel();
    if (event.key === "Tab") {
      saveDataOnTabChange();
    }
  };
  const handleOnFocus = (event) => {
    setValuesInModel();
    saveDataOnTabChange();
  };
  const saveDataOnTabChange = () => {
    if (!props.isSaving) {
      postnatalPeriodModel.patientId = props.patientId;
      props.onSubmit({ postnatalPeriodModel: postnatalPeriodModel, isAutoSave: true });
    }
  };

  function setValuesInModel() {
    let model = getValues();
    setDataInModel(model);
  }
  const setDataInModel = (data) => {
    postnatalPeriodModel.breathingDelayed = data.breathingDelayed;
    postnatalPeriodModel.cryDelayed = data.cryDelayed;
    postnatalPeriodModel.motherInfantReleasedDays = data.motherInfantReleasedDays;
    postnatalPeriodModel.babyWeightAtBirth = data.babyWeightAtBirth;
    postnatalPeriodModel.comments = data.comments;
  };
  const isSelected = (type: number, isChecked) => {
    setValuesInModel();
    switch (type) {
      case 1: {
        postnatalPeriodModel.jaundice = isChecked;
        postnatalPeriodModel.jaundiceError = "";
        break;
      }
      case 2: {
        postnatalPeriodModel.oxygen = isChecked;
        postnatalPeriodModel.oxygenError = "";
        break;
      }
      case 3: {
        postnatalPeriodModel.cyanosis = isChecked;
        postnatalPeriodModel.cyanosisError = "";
        break;
      }
      case 4: {
        postnatalPeriodModel.resuscitation = isChecked;
        postnatalPeriodModel.resuscitationError = "";
        break;
      }
      case 5: {
        postnatalPeriodModel.seizuresConvulsions = isChecked;
        postnatalPeriodModel.seizuresConvulsionsError = "";
        break;
      }
      case 6: {
        postnatalPeriodModel.transfusions = isChecked;
        postnatalPeriodModel.transfusionsError = "";
        break;
      }
      case 7: {
        postnatalPeriodModel.antibioticTreatment = isChecked;
        postnatalPeriodModel.antibioticTreatmentError = "";
        break;
      }
      case 8: {
        postnatalPeriodModel.incubatorCare = isChecked;
        postnatalPeriodModel.incubatorCareError = "";
        break;
      }
      case 9: {
        postnatalPeriodModel.specialLightForJaundice = isChecked;
        postnatalPeriodModel.specialLightForJaundiceError = "";
        break;
      }
      case 10: {
        postnatalPeriodModel.birthDefects = isChecked;
        postnatalPeriodModel.birthDefectsError = "";
        break;
      }
      case 11: {
        if (isChecked) {
          postnatalPeriodModel.breathing = isChecked;
          postnatalPeriodModel.breathingDelayed = "";
          postnatalPeriodModel.breathingDelayedError = "";
        } else if (!isChecked) {
          postnatalPeriodModel.breathing = isChecked;
        }
        postnatalPeriodModel.breathingError = "";
        break;
      }
      case 12: {
        if (isChecked) {
          postnatalPeriodModel.cry = isChecked;
          postnatalPeriodModel.cryDelayed = "";
          postnatalPeriodModel.cryDelayedError = "";
        } else if (!isChecked) {
          postnatalPeriodModel.cry = isChecked;
        }
        postnatalPeriodModel.cryError = "";
        break;
      }
      default: {
        break;
      }
    }
    props.assignModel({ postnatalPeriodModel: postnatalPeriodModel });
    saveDataOnTabChange();
  };
  return (
    <div>
      <form onSubmit={handleSubmit(formSubmit)}>
        <div>
          <Heading heading="Postnatal Period" subHeading="" styleName="" />
          <div className="form">
            <div className="row">
              <div className="medical-group btn-select col-12 col-md-3 mt-3">
                <label className="text-center">Jaundice</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(1, true)}
                    className={
                      postnatalPeriodModel.jaundice == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(1, false)}
                    className={
                      postnatalPeriodModel.jaundice == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {postnatalPeriodModel.jaundiceError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {postnatalPeriodModel.jaundiceError}
                    </div>
                  )}
              </div>
              <div className="medical-group btn-select col-12 col-md-3 mt-3">
                <label className="text-center">Oxygen</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(2, true)}
                    className={
                      postnatalPeriodModel.oxygen == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(2, false)}
                    className={
                      postnatalPeriodModel.oxygen == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {postnatalPeriodModel.oxygenError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {postnatalPeriodModel.oxygenError}
                    </div>
                  )}
              </div>
              <div className="medical-group btn-select col-12 col-md-3 mt-3">
                <label className="text-center">Cyanosis</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(3, true)}
                    className={
                      postnatalPeriodModel.cyanosis == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(3, false)}
                    className={
                      postnatalPeriodModel.cyanosis == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {postnatalPeriodModel.cyanosisError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {postnatalPeriodModel.cyanosisError}
                    </div>
                  )}
              </div>
              <div className="medical-group btn-select col-12 col-md-3 mt-3">
                <label className="text-center">Resuscitation</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(4, true)}
                    className={
                      postnatalPeriodModel.resuscitation == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(4, false)}
                    className={
                      postnatalPeriodModel.resuscitation == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {postnatalPeriodModel.resuscitationError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {postnatalPeriodModel.resuscitationError}
                    </div>
                  )}
              </div>
            </div>
            <div className="row">
              <div className="medical-group btn-select col-12 col-md-3 mt-3">
                <label className="text-center">Seizures/Convulsions</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(5, true)}
                    className={
                      postnatalPeriodModel.seizuresConvulsions == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(5, false)}
                    className={
                      postnatalPeriodModel.seizuresConvulsions == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {postnatalPeriodModel.seizuresConvulsionsError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {postnatalPeriodModel.seizuresConvulsionsError}
                    </div>
                  )}
              </div>
              <div className="medical-group btn-select col-12 col-md-3 mt-3">
                <label className="text-center">Transfusions</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(6, true)}
                    className={
                      postnatalPeriodModel.transfusions == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(6, false)}
                    className={
                      postnatalPeriodModel.transfusions == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {postnatalPeriodModel.transfusionsError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {postnatalPeriodModel.transfusionsError}
                    </div>
                  )}
              </div>
              <div className="medical-group btn-select col-12 col-md-3 mt-3">
                <label className="text-center">Antibiotic treatment</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(7, true)}
                    className={
                      postnatalPeriodModel.antibioticTreatment == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(7, false)}
                    className={
                      postnatalPeriodModel.antibioticTreatment == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {postnatalPeriodModel.antibioticTreatmentError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {postnatalPeriodModel.antibioticTreatmentError}
                    </div>
                  )}
              </div>
              <div className="medical-group btn-select col-12 col-md-3 mt-3">
                <label className="text-center">Incubator care</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(8, true)}
                    className={
                      postnatalPeriodModel.incubatorCare == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(8, false)}
                    className={
                      postnatalPeriodModel.incubatorCare == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {postnatalPeriodModel.incubatorCareError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {postnatalPeriodModel.incubatorCareError}
                    </div>
                  )}
              </div>
            </div>
            <div className="row">
              <div className="medical-group btn-select col-12 col-md-3 mt-3">
                <label className="text-center">Special light for jaundice</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(9, true)}
                    className={
                      postnatalPeriodModel.specialLightForJaundice == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(9, false)}
                    className={
                      postnatalPeriodModel.specialLightForJaundice == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {postnatalPeriodModel.specialLightForJaundiceError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {postnatalPeriodModel.specialLightForJaundiceError}
                    </div>
                  )}
              </div>
              <div className="medical-group btn-select col-12 col-md-3 mt-3">
                <label className="text-center">Birth defects (Explain below)</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(10, true)}
                    className={
                      postnatalPeriodModel.birthDefects == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(10, false)}
                    className={
                      postnatalPeriodModel.birthDefects == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {postnatalPeriodModel.birthDefectsError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {postnatalPeriodModel.birthDefectsError}
                    </div>
                  )}
              </div>
            </div>
            <div className="row">
              <div className="medical-group btn-select col-12 col-md-3 mt-3">
                <label className="text-center">Breathing</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(11, true)}
                    className={
                      postnatalPeriodModel.breathing == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Immediate
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(11, false)}
                    className={
                      postnatalPeriodModel.breathing == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Delayed
                  </button>
                  
                </div>
                {postnatalPeriodModel.breathingError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {postnatalPeriodModel.breathingError}
                    </div>
                  )}
              </div>
              <div className="medical-group col-12 col-md-3 mt-md-4">
                <label className="form-label">Delayed how long</label>
                <input
                  type="text"
                  className="form-control mb-1"
                  defaultValue={postnatalPeriodModel.breathingDelayed}
                  disabled={
                    postnatalPeriodModel.breathing || postnatalPeriodModel.breathing == undefined
                  }
                  placeholder="Breathing delayed how long"
                  {...register("breathingDelayed")}
                  onFocus={handleOnFocus}
                />
                {postnatalPeriodModel.breathingDelayedError && (
                  <div className="invalid-feedback" style={{ display: "block" }}>
                    {postnatalPeriodModel.breathingDelayedError}
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="medical-group btn-select col-12 col-md-3 mt-3">
                <label className="text-center">Cry</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => isSelected(12, true)}
                    className={
                      postnatalPeriodModel.cry == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Immediate
                  </button>
                  <button
                    type="button"
                    onClick={() => isSelected(12, false)}
                    className={
                      postnatalPeriodModel.cry == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Delayed
                  </button>
                  
                </div>
                {postnatalPeriodModel.cryError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {postnatalPeriodModel.cryError}
                    </div>
                  )}
              </div>
              <div className="medical-group col-12 col-md-3 mt-md-4" >
                <label className="form-label">Delayed how long</label>
                <input
                  type="text"
                  className="form-control mb-1"
                  defaultValue={postnatalPeriodModel.cryDelayed}
                  disabled={postnatalPeriodModel.cry || postnatalPeriodModel.cry == undefined}
                  placeholder="Cry delayed how long"
                  {...register("cryDelayed")}
                  onFocus={handleOnFocus}
                />
                {postnatalPeriodModel.cryDelayedError && (
                  <div className="invalid-feedback" style={{ display: "block" }}>
                    {postnatalPeriodModel.cryDelayedError}
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-3 mt-3">
                <label className="form-label">Baby's weight at birth</label>
                <input
                  type="number"
                  className="form-control mb-1"
                  defaultValue={postnatalPeriodModel.babyWeightAtBirth}
                  placeholder="Baby weight at birth"
                  {...register("babyWeightAtBirth")}
                  onFocus={handleOnFocus}
                />
                {postnatalPeriodModel.babyWeightAtBirthError && (
                  <div className="invalid-feedback" style={{ display: "block" }}>
                    {postnatalPeriodModel.babyWeightAtBirthError}
                  </div>
                )}
              </div>
            </div>
            <div className="medical__birth_formRow5">
              <div className="medical-group">
                <label className="form-label">
                  How many days after birth were mother and infant released from the hospital?
                </label>
                <input
                  type="text"
                  className="form-control mb-1"
                  defaultValue={postnatalPeriodModel.motherInfantReleasedDays}
                  placeholder=""
                  onFocus={handleOnFocus}
                  {...register("motherInfantReleasedDays")}
                />
                {postnatalPeriodModel.motherInfantReleasedDaysError && (
                  <div className="invalid-feedback" style={{ display: "block" }}>
                    {postnatalPeriodModel.motherInfantReleasedDaysError}
                  </div>
                )}
              </div>
            </div>

            <div className="medical__birth_formRow5">
              <div className="medical-group optional-field textarea_group">
                <label className="form-label">Comments</label>
                <span className="optional">Optional</span>
                <textarea
                  className="form-control textarea"
                  defaultValue={postnatalPeriodModel.comments}
                  {...register("comments")}
                  onFocus={handleOnFocus}
                  placeholder="Please provide any additional information."
                ></textarea>
              </div>
            </div>
          </div>
          <div className="btnContainer">
            {!props.isSaving && (
              <Button
                type="default"
                size="large"
                className="back_btn"
                onClick={() => props.previousTabChange(2)}
              >
                <i className="fa fa-long-arrow-left"></i> Back
              </Button>
            )}
            {!props.isSaving && (
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="btn_add"
              >
                Save <i className="fa fa-long-arrow-right"></i>
              </Button>
            )}
            {props.isSaving && (
              <div style={{ height: "60px" }}>
                <Loader
                  loading={props.isSaving}
                  marginBottom="0px"
                  marginTop="8px"
                  width="368px"
                ></Loader>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
