import React, { Component, Fragment } from "react";
import { PatientDetailModel } from "../../../components/patient/models/patient-detail.model";
import { HttpResponse } from "../../../core";
import { MedicationStatusModel } from "../../../models/medication/medication-status.model";
import { TeacherDetailModel } from "../../../models/teacher/teacher-detail.model";
import { TeacherWeeklyDataService } from "../../../services/teacherWeeklyData/teacher-weekly-data.service";
import "../../styles.css";
import { TeacherDetailHeaderForm } from "./teacherDetailHeaderForm";

interface IState {
  isLoading: boolean;
  reRenderForm: boolean;
  teacherDetailModel: TeacherDetailModel;
  patientId: string;
  medicationStatus: MedicationStatusModel[];
  isBaseline: boolean;
  patientName: string;
  trialStatus:number;
}
class TeacherDetailHeader extends React.Component<any, IState> {
  private teacherWeeklyDataService: TeacherWeeklyDataService;
  isComponentMounted: boolean = false;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.teacherWeeklyDataService = new TeacherWeeklyDataService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      teacherDetailModel: {} as TeacherDetailModel,
      reRenderForm: false,
      patientId: this.props.patientId,
      medicationStatus: [],
      isBaseline: this.props.isBaseline,
      trialStatus:this.props.trialStatus,
      patientName: "",
    };
    return initialState;
  }

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }

  componentDidMount() {
    this.loadData();
    this.getMedicationStatusByPatientId();
  }
  loadData() {
    if (this.state.patientId != null) {
      this.setLoading(true);
      this.teacherWeeklyDataService
        .getUserById(this.state.patientId)
        .then((res: HttpResponse<TeacherDetailModel>) => {
          this.setLoading(false);
          if (res && res.result) {
            this.setState(
              {
                teacherDetailModel: res.result,
                reRenderForm: true,
                patientName: res.result.lastName + ","+ " " + res.result.firstName,
              },
              () => {
                this.props.selectedPatientName(this.state.patientName);
                this.setState({ reRenderForm: false });
              }
            );
          }
        })
        .catch(() => {
          this.setLoading(false);
        });
    }
  }

  getMedicationStatusByPatientId = () => {
    if (this.state.patientId != null) {
      this.teacherWeeklyDataService
        .getMedicationStatusByPatientId(this.state.patientId)
        .then((res: HttpResponse<MedicationStatusModel[]>) => {
          if (res && res.result) {
            this.setState({
              medicationStatus: res.result,
            });
          }
        })
        .catch(() => {});
    }
  };
  parentTabChange = (tab: any) => {
    this.props.parentTabChange(tab);
  };
  render() {
    return (
      <Fragment>
        {/* <TeacherDetailHeaderForm
          isLoading={this.state.isLoading}
          teacherDetailModel={this.state.teacherDetailModel}
          bcColor={this.props.bcColor}
          medicationStatus={this.state.medicationStatus}
          isBaseline={this.state.isBaseline}
          trialStatus={this.state.trialStatus}
        /> */}
      </Fragment>
    );
  }
}
export default TeacherDetailHeader;
