import React, { Fragment } from "react";
import { HttpResponse } from "../../../../../../core";
import { DevelopmentalProfileDetailModel } from "../../../../../../models/developmentProfile/developmentProfileDetail/development-profile-detail.model";
import { DevelopmentProfileDetailService } from "../../../../../../services/developmentProfile/developmentProfileDetail/development-profile.service";
import DevelopmentProfileViewForm from "./developmentProfileViewForm";
import { AddChildTemperamentModel } from "../../../../../../models/developmentProfile/childTemperament/add-child-temperament.model";
import { AddDevelopmentalMilestonesModel } from "../../../../../../models/developmentProfile/add-developmental-milestones.model";
interface IState {
  patientId: string;
  isLoading: boolean;
  developmentalProfileDetailModel: DevelopmentalProfileDetailModel;
}
export class DevelopmentProfileTab extends React.Component<any, IState> {
  private developmentProfileDetailService: DevelopmentProfileDetailService;

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.developmentProfileDetailService = new DevelopmentProfileDetailService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      patientId: this.props.patientId,
      developmentalProfileDetailModel: {
        childTemperamentDetail: {} as AddChildTemperamentModel,
        developmentalMilestonesDetail: [] as AddDevelopmentalMilestonesModel[],
      } as DevelopmentalProfileDetailModel,
    };
    return initialState;
  }

  componentDidMount() {
    if (this.props.patientId != null) {
      this.loadData();
    }
  }
  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }

  loadData = () => {
    this.setLoading(true);
    this.developmentProfileDetailService
      .getDevelopmentProfileDetail(this.props.patientId)
      .then((res: HttpResponse<DevelopmentalProfileDetailModel>) => {
        if (res && res.result) {
          this.setState({
            developmentalProfileDetailModel: res.result,
          });
          this.setLoading(false);
        }
      })
      .catch(() => {
        this.setLoading(false);
      });
  };

  render() {
    return (
      <Fragment>
        <DevelopmentProfileViewForm
          patientId={this.props.patientId}
          isLoading={this.state.isLoading}
          developmentalProfileDetailModel={
            this.state.developmentalProfileDetailModel
          }
        />
      </Fragment>
    );
  }
}
