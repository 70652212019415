import { AppConfig, HttpWrapper } from "../../core";
import { PatientStatsFilterModel } from "../../models/patientManagement/patient-stats.model";

export class PatientManagementService {
    private wrapper: HttpWrapper;
    constructor() {
      this.wrapper = new HttpWrapper();
    }
    getPatientManagementList(filterModel:PatientStatsFilterModel) {
        return this.wrapper.post<any>(
          AppConfig.apiEndpoint +
            "patientmanagement/list/",
            filterModel
        );
      }
      
}
    