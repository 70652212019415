import React, { Fragment } from "react";
import { HttpResponse } from "../../../../core";
import { ShowErrorMessage, ShowSuccessMessage } from "../../../../shared/helpers";
import { BirthHistoryService } from "../../../../services/birthHistory/birth-history.service";
import { AddPostnatalForm } from "./addPostNatalForm";
import { AddPostnatalPeriodModel } from "../../../../models/birthHistory/postNatal/add-postnatal-period.model";

interface IState {
  isLoading: boolean;
  tab: number;
  isReadonly: boolean;
  reRenderForm: boolean;
  postnatalPeriodModel: AddPostnatalPeriodModel;
  patientId: string;
  isFormSave?: Function;
  birthHistoryStepUpdate?: Function;
}
export class PostnatalPeriod extends React.Component<any, IState> {
  private birthHistoryService: BirthHistoryService;
  isComponentMounted: boolean = false;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.birthHistoryService = new BirthHistoryService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      tab: 1,
      isReadonly: false,
      postnatalPeriodModel: {} as AddPostnatalPeriodModel,
      reRenderForm: false,
      patientId: this.props.patientId,
    };
    return initialState;
  }

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }

  componentDidMount() {
    this.loadData();
    window.scroll(0, 0);
  }

  loadData(isAutoSave?: any) {
    if (this.state.patientId) {
      this.birthHistoryService
        .getPostnatalPeriod(this.state.patientId)
        .then((res: HttpResponse<AddPostnatalPeriodModel>) => {
          if (res && res.result) {
            this.props.birthHistoryStepUpdate(4,res.result.id);
            if (isAutoSave) {
              let pm = this.state.postnatalPeriodModel;
              pm.id = res.result.id;
              pm.patientId = res.result.patientId;
              this.setState({
                postnatalPeriodModel: pm,
              });
            } else {
              let postnatalPeriod: AddPostnatalPeriodModel = {
                id: res.result.id,
                patientId: res.result.patientId,
                jaundice: res.result.jaundice,
                cyanosis: res.result.cyanosis,
                seizuresConvulsions: res.result.seizuresConvulsions,
                antibioticTreatment: res.result.antibioticTreatment,
                specialLightForJaundice: res.result.specialLightForJaundice,
                breathing: res.result.breathing,
                cry: res.result.cry,
                oxygen: res.result.oxygen,
                resuscitation: res.result.resuscitation,
                transfusions: res.result.transfusions,
                incubatorCare: res.result.incubatorCare,
                birthDefects: res.result.birthDefects,
                breathingDelayed: res.result.breathingDelayed,
                cryDelayed: res.result.cryDelayed,
                babyWeightAtBirth: res.result.babyWeightAtBirth,
                motherInfantReleasedDays: res.result.motherInfantReleasedDays,
                comments: res.result.comments,
              };
              this.setState({
                postnatalPeriodModel: postnatalPeriod,
              });
            }
          }
        })
        .catch((ex) => {});
    }
  }

  postData = (data: any) => {
    let postnatalPeriod = data.postnatalPeriodModel;
    if (this.state.isLoading) {
      return;
      }
      if (data.isAutoSave) {
          this.setLoading(false);
      }
      else {
          this.setLoading(true);
      }
    this.birthHistoryService
      .postPostnatalPeriod(postnatalPeriod)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
          this.state.postnatalPeriodModel.id=res.result;
          if (!data.isAutoSave) {
            ShowSuccessMessage("Data Saved Successfully.");
            this.props.nextTabChange();
          }
        }
        this.setLoading(false);
        this.props.isFormSave();
        if (!data.isAutoSave) {
          this.loadData(data.isAutoSave);
        }
      })
      .catch((ex) => {
        ShowErrorMessage("Error in creating postnatal period.");
        this.setLoading(false);
      });
  };
  previousTabChange = () => {
    this.props.previousTabChange();
  };

  assignModel = (data: any) => {
    
    this.setState(
      {
        postnatalPeriodModel: data.postnatalPeriodModel,
        reRenderForm: true,
      },
      () => this.setState({
         reRenderForm: false 
        })
    );
  };

  render() {
    return (
      <Fragment>
        {!this.state.reRenderForm && (
          <AddPostnatalForm
            onSubmit={this.postData}
            isSaving={this.state.isLoading}
            postnatalPeriodModel={this.state.postnatalPeriodModel}
            patientId={this.props.patientId}
            assignModel={this.assignModel}
            isReadOnly={this.state.isReadonly}
            previousTabChange={this.previousTabChange}
            isBirthHistoryOptional={this.props.isBirthHistoryOptional}
          />
        )}
      </Fragment>
    );
  }
}
