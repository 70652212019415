import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Tooltip } from "antd";
import { Link, NavLink } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { HttpResponse } from "../../../core";
import {  UserListModel } from "../../../models/users";
import { UserService } from "../../../services/user";
//import Breadcrumbs from "../../../shared/Breadcrumbs";
import { Loader } from "../../../shared/loaders";
import Heading from "../../heading";
import Search from "../../search";
import oops from "../../../assets/images/svg-icons/no-result-found.svg";
import { UserResponseModel } from "../../../models/users/user-list-response.model";
import { UserListFilterModel } from "../../../models/users/user-list-filter.model";

interface IState {
  result: UserResponseModel | any;
  initials?: string;
  searchText: string;
  visible: boolean;
  isLoading?: boolean;
  pageSize: number;
  totalItems: number;
  currentPage: number;
  pageCount: number;
  hasMore: boolean;
  scrolling?: boolean;
  userId: string;
}
interface IProps {
  isRefresh: boolean;
  counter?: any,
  id: any;
}
export class UserList extends Component<IProps, IState> {
  private userService: UserService;
  constructor(props) {
    super(props);
    this.userService = new UserService();
    this.state = {
      result: {
        collection: [],
        paging: {
          currentPage: 1,
          pageSize: 0,
          totalItems: 0,
          pageCount: 0,
        },
      },
      searchText: "",
      initials: "",
      visible: false,
      isLoading: false,
      pageSize: 12,
      currentPage: 1,
      totalItems: 0,
      pageCount: 0,
      hasMore: true,
      scrolling: false,
      userId: this.props.id,
    };
  }
  componentDidMount() {
    this.setState({ searchText: "" }, () => {
      this.loadData();
    })
  }
  componentWillReceiveProps() {
    if (window.location.pathname == "/user/adduser/0/ut" || window.location.pathname == "/user/adduser/0"  || window.location.pathname =="/user/adduser/") {
      this.setState({ searchText: "" }, () => {
        this.loadData();
      })
    }
  }


  loadData = () => {
    if (!this.state.scrolling) {
      this.setLoading(true);
    }
    let filterModel: UserListFilterModel = {
      searchText: this.state.searchText,
      pageSize: this.state.pageSize,
      currentPage: this.state.currentPage,
      totalItems: this.state.totalItems,
      pageCount: this.state.pageCount,
    };
    this.userService
      .getUserList(filterModel)
      .then((res: HttpResponse<UserResponseModel>) => {
        if (!this.state.scrolling) {
          this.setLoading(false);
        }
        if (res && res.result) {
              let patientList = this.state.scrolling ? this.state.result?.collection || [] : [];
              patientList = patientList.concat(res.result.collection);
              let result = res.result;
              result.collection = patientList;

               if (this.state.scrolling) {
                this.setState({
                  result: result,
                  scrolling: false,
                });
              }
                else {
                  // if (
                  //   (this.state.userId == "" ||
                  //     this.state.userId == undefined) &&
                  //   result.collection.length > 0
                  // ) {
                  //   this.props.history.push(
                  //     `/user/adduser/${result.collection[0].id}`
                  //   );
                  // }
                  this.setState(
                    {
                      result: result,
                      scrolling: false,
                      userId:
                        (this.state.userId == undefined ||
                          this.state.userId == "") &&
                        result.collection.length > 0
                          ? res.result.collection[0].id
                          : this.state.userId,
                        }
                    )
            }
            this.setState(
              {
                result: result,
                scrolling: false,
              },
              () => {}
            );
          }
          })
      //     let patientList = this.state.scrolling ? this.state.result?.collection || [] : [];
      //     patientList = patientList.concat(res.result.collection);
      //     let result = res.result;
      //     result.collection = patientList;
      //     this.setState(
      //       {
      //         result: result,
      //         scrolling: false,
      //       },
      //       () => {}
      //     );
      //   }
      // })
      .catch((ex) => {
        this.setLoading(false);
      });
  };
  handleSearchChange = (event: any) => {
    this.setState(
      {
        currentPage: 1,
        scrolling: false,
        searchText: event.target.value,
      },
      () => {
        this.loadData();
      }
    );
  };

  private setLoading(loading: boolean) {
    this.setState({ isLoading: loading });
  }
  loadMore = () => {
    this.setState(
      (prevState) => ({
        currentPage: prevState.currentPage + 1,
        scrolling: true,
      }),
      () => {
        this.loadData();
      }
    );
  };
  render() {
    return (
      <section className="list-container">
        <div className="list__heading__search">
          <Heading heading="Users" subHeading="" styleName="medicalData__style" />
          <NavLink to="/user/adduser/0">
            <Tooltip placement="top" title="Add New User">
              <PlusOutlined className="add__icon" />
            </Tooltip>
          </NavLink>
        </div>
        <div className="list__heading__search">
          <Search
            placeholder="Search by Name"
            type="text"
            onChange={this.handleSearchChange}
            value={this.state.searchText}
            variants="search__list"
            name="searchText"
            searchIcon=""
          />
        </div>
        <div id="targetDiv" className="scrollBar">
          <InfiniteScroll
            dataLength={this.state.result.collection.length}
            next={this.loadMore}
            hasMore={true}
            loader={false}
            scrollableTarget="targetDiv"
          >
            <ul className="user-list" style={{ marginTop: 5 }}>
              {!this.state.isLoading &&
                this.state.result.paging.totalItems > 0 &&
                this.state.result.collection.map((item: UserListModel, index) => {
                  return (
                    <li className="user-list-item" key={index}>
                      <NavLink to={"/user/adduser/" + item.id} className="user-link user_height">
                        <div className="user-icon">
                          <span>{item.initials}</span>
                        </div>
                        <div className="name-role">
                          <span className="name">
                            {item.lastName + ","} {item.firstName}
                          </span>
                          <span className="role">Role: {item.roles}</span>
                        </div>
                      </NavLink>
                    </li>
                  );
                })}
              {!this.state.isLoading && this.state.result.paging.totalItems === 0 && (
                <div className="text-danger text-center">
                  <div className="noRecord__table">
                    <img src={oops} alt="oops" />
                    <h2>
                      No result found
                    </h2>
                    <p>
                      There are no matching records for your search,
                      please try again.
                    </p>
                  </div>
                </div>
              )}
              {this.state.isLoading && <Loader loading={this.state.isLoading} />}
            </ul>
          </InfiniteScroll>
        </div>
      </section>
    );
  }
}

export default UserList;
