import React, { Fragment } from "react";
import { HttpResponse } from "../../../../../../core";
import { MedicationHistoryDetailModel } from "../../../../../../models/medicalHistory/medication-history-detail.model";
import { MedicalHistoryService } from "../../../../../../services/medicalHistory";
import MedicationHistoryTabForm from "./medicationHistoryTabForm";

interface IState {
  patientId: string;
  isLoading: boolean;
  medicationHistoryDetailModel: MedicationHistoryDetailModel;
}
export class MedicationHistoryTab extends React.Component<any, IState> {
  private medicalHistoryService: MedicalHistoryService;

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.medicalHistoryService = new MedicalHistoryService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      patientId: this.props.patientId,
      medicationHistoryDetailModel: {} as MedicationHistoryDetailModel,
    };
    return initialState;
  }
  componentDidMount() {
    if (this.props.patientId != null) {
      this.loadData();
    }
  }

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }
  loadData = () => {
    this.setLoading(true);
    this.medicalHistoryService
      .getMedicationHistoryDetail(this.props.patientId)
      .then((res: HttpResponse<MedicationHistoryDetailModel>) => {
        if (res && res.result) {
          this.setLoading(false);
          this.setState({
            medicationHistoryDetailModel: res.result,
          });
        }
      });
  };

  render() {
    return (
      <Fragment>
        <MedicationHistoryTabForm
          patientId={this.props.patientId}
          isLoading={this.state.isLoading}
          medicationHistoryDetailModel={this.state.medicationHistoryDetailModel}
        />
      </Fragment>
    );
  }
}
