import Select from "react-select";
import React, { useState, Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import Heading from "../../../../heading";
import "../../../../patient/patient.scss";
import DiagnosisSelect from "./diagnosisSelect";
import ParticipantSelect from "./participantSelect";
import logo from "../../../../../assets/images/CCFLogoFullColor.png";
import downloading from "../../../../../assets/images/svg-icons/pdf.svg";
import { AddPatientMedicationModel } from "../../../../patient/models/add-patient-medication.model";
import { ClinicianWeeklyData } from "../../../../../models/clinicianWeeklyData/clinician-weekly-data.model";
import WeekwiseSideEffectInfo from "../../finalReport/reportSideEffects";
import { MidPointVisitReportSaveDataModel } from "../../../../../models/report/mid-week-report-save-data";
import moment from "moment";
import { Button, Modal, Radio } from "antd";
import {
  AddendumData,
  severitySelectedData,
} from "../../../../../models/report/final-report-save-data";
import { PatientDetailModel } from "../../../../patient/models/patient-detail.model";
import dayjs from "dayjs";
import {
  Editor,
  EditorState,
  RichUtils,
  convertFromHTML,
  ContentState,
  KeyBindingUtil,
  getDefaultKeyBinding,
} from "draft-js";
import "draft-js/dist/Draft.css";
import { DevTool } from "@hookform/devtools";
import { stateToHTML } from "draft-js-export-html";

interface IProps {
  patientId: string;
  medication: AddPatientMedicationModel;
  visitDate: string;
  clinicianWeeklyData: ClinicianWeeklyData;
  midWeekVisitReportModel: MidPointVisitReportSaveDataModel;
  yesNoSelectionChanged: Function;
  addendumList: AddendumData[];
  addAddendumClick: Function;
  addWeekDaysMedication: Function;
  saveClick: Function;
  downloadReport: Function;
  patientDetail: PatientDetailModel;
  medicationOptions: severitySelectedData[];
  selectedMedicationOptions: severitySelectedData;
  handleMedicationChange: Function;
  isDownloading: boolean;
  assignModel: Function;
}
const participantsOptions = [
  { value: "0", label: "Primary caregiver" },
  { value: "1", label: "Mother" },
  { value: "2", label: "Father" },
  { value: "3", label: "Patient" },
  { value: "4", label: "Sibling" },
  { value: "5", label: "Grand Parent" },
];

const diagnosisCodesOptions = [
  { value: "0", label: "314.01" },
  { value: "1", label: "314.00" },
  { value: "2", label: "F90.2" },
  { value: "3", label: "F90.0" },
  { value: "4", label: "F90.1" },
  { value: "5", label: "F90.8" },
  { value: "6", label: "F90.9" },
];
export default function MidPointVisit(props: IProps) {
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );
  const getDaySelectOptions = (daysOfWeek: string[]) => {
    let options: severitySelectedData[] = [];
    daysOfWeek?.map((date) => {
      let newOption: severitySelectedData = { value: date, label: date };
      options.push(newOption);
    });
    console.log("Options: ", options);
    return options;
  };
  const getStringArrayFromSelectOptions = (options: severitySelectedData[]) => {
    let result: string[] = [];
    options.map((option) => {
      let newValue = option.value;
      result.push(newValue);
    });
    return result;
  };
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
    clearErrors,
    control,
  } = useForm();
  const {hasCommandModifier} = KeyBindingUtil;
  const RadioGroup = Radio.Group;
  const [participants, setParticipants] = useState(
    props.midWeekVisitReportModel.participants
  );
  const [diagnosisCodes, setDiagnosis] = useState(
    props.midWeekVisitReportModel.diagnosis
  );
  let [addendum, setAddendum] = useState("");
  let [showSavePopup, setshowSavePopup] = useState(false);
  let [showDownloadPopup, setshowDownloadPopup] = useState(false);
  let reportSaveModel = {} as MidPointVisitReportSaveDataModel;
  let [downloadFormat, setDownloadFormat] = useState(1);
  let [isAddendumError, setAddendumError] = useState(false);
  const [noteText, setNoteText] = useState<string>("");
  const [daysOfWeek1, setDaysOfWeek1] = useState<string[]>([]);
  const [daysOfWeek2, setDaysOfWeek2] = useState<string[]>([]);
  const [daysOfWeek3, setDaysOfWeek3] = useState<string[]>([]);
  const [missedWeek1, setMissedWeek1] = useState(
    getDaySelectOptions(props.midWeekVisitReportModel.week1DaysMedicationMissed)
  );
  const [missedWeek2, setMissedWeek2] = useState(
    getDaySelectOptions(props.midWeekVisitReportModel.week2DaysMedicationMissed)
  );
  const [missedWeek3, setMissedWeek3] = useState(
    getDaySelectOptions(props.midWeekVisitReportModel.week3DaysMedicationMissed)
  );
  const scrollTop = () => {
    window.document
      .getElementById("scrollWrap")!
      .scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    if (props.midWeekVisitReportModel.notes) {
      const blocksFromHTML = convertFromHTML(
        props.midWeekVisitReportModel.notes
      );
      const initialNote = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const initialEditorState = EditorState.createWithContent(initialNote);
      setEditorState(initialEditorState);
    }
    setDaysOfWeek1(getDaysOfWeek(1));
    setDaysOfWeek2(getDaysOfWeek(2));
    setDaysOfWeek3(getDaysOfWeek(3));
  }, []);
  useEffect(() => {
    let html = stateToHTML(editorState.getCurrentContent());
    setNoteText(html);
  }, [editorState]);

  const onSubmit = (data, e) => {
    let reportSaveModel = {} as MidPointVisitReportSaveDataModel;

    reportSaveModel.diagnosis = diagnosisCodes;
    reportSaveModel.facetoFaceTime = data.facetoFaceTime;
    reportSaveModel.monitoringPhysician = data.monitoringPhysician;
    reportSaveModel.notes = noteText;
    reportSaveModel.otherText = data.otherText;
    reportSaveModel.participants = participants;
    reportSaveModel.patientId = props.patientId;
    reportSaveModel.procedures = data.procedures;
    reportSaveModel.savedOn = data.savedOn;
    reportSaveModel.serviceProvided = data.serviceProvided;
    reportSaveModel.isTeacherDataReceived =
      props.midWeekVisitReportModel.isTeacherDataReceived;
    reportSaveModel.trialLength = data.trialLength;
    reportSaveModel.trialStyle = data.trialStyle;
    reportSaveModel.medicationResponse = data.medicationResponse;
    reportSaveModel.placeboPresent =
      props.midWeekVisitReportModel.placeboPresent;
    reportSaveModel.week1DaysMedicationMissed =
      getStringArrayFromSelectOptions(missedWeek1);
    reportSaveModel.week2DaysMedicationMissed =
      getStringArrayFromSelectOptions(missedWeek2);
    reportSaveModel.week3DaysMedicationMissed =
      getStringArrayFromSelectOptions(missedWeek3);
    if (e.nativeEvent.submitter.innerText == "Submit") {
      props.assignModel(reportSaveModel);
      setshowSavePopup((showSavePopup = true));
    } else {
      props.saveClick(reportSaveModel, false);
    }
  };
  let [isToggled, setIsToggled] = useState(false);

  const handleChange = (e) => {
    console.log(e.target.value);
    if (e.target.value == "other") {
      setIsToggled((isToggled = true));
    } else {
      setIsToggled((isToggled = false));
    }
  };

  const yesNoSelection = (type, isChecked) => {
    props.yesNoSelectionChanged(type, isChecked);
  };

  const addWeekDaysMedication = (type, day, event) => {
    props.addWeekDaysMedication(type, day, event.target.checked);
  };

  const handleMedicationChange = (selectedOption) => {
    props.handleMedicationChange(selectedOption);
  };
  const getSeverityText = (value?: number) => {
    let severityText = "";
    switch (value) {
      case 1:
        severityText = "Normal; Not at all 1";
        break;
      case 2:
        severityText = "Borderline Problem 2";
        break;
      case 4:
        severityText = "Moderate Problem 4";
        break;
      case 3:
        severityText = "Mild Problem 3";
        break;
      case 5:
        severityText = "Marked Problem 5";
        break;
      case 6:
        severityText = "Severe Problem 6";
        break;
      case 7:
        severityText = "Most Extreme Problem 7";
        break;
      default:
        severityText = " -None Reported- ";
        break;
    }
    return severityText;
  };
  const getBorderStyle = (value?: number) => {
    let borderStyle = "final__cgiSevr";
    if (value != null) {
      if (value == 5) {
        borderStyle = "final__cgiSevr finalServr__orange";
      } else if (value == 6) {
        borderStyle = "final__cgiSevr finalServr__extreme";
      } else if (value == 7) {
        borderStyle = "final__cgiSevr finalServr__extreme";
      }
    }
    return borderStyle;
  };
  const weekDotStyle = (value?: number) => {
    let dotStyle = "dot";
    if (value != null) {
      if (value == 5) {
        dotStyle = "dot weekOrange";
      } else if (value == 6) {
        dotStyle = "dot weekExtremeDot";
      } else if (value == 7) {
        dotStyle = "dot weekExtremeDot";
      }
    }
    return dotStyle;
  };
  const getImprovementText = (value?: number) => {
    let improvementText = "";
    switch (value) {
      case 1:
        improvementText = "Very Much Improved (1)";
        break;
      case 2:
        improvementText = "Improved (2)";
        break;
      case 3:
        improvementText = "Minimally Improved (3)";
        break;
      case 4:
        improvementText = "Unchanged (4)";
        break;
      case 5:
        improvementText = "Minimally Worse (5)";
        break;
      case 6:
        improvementText = "Worse (6)";
        break;
      case 7:
        improvementText = "Very Much Worse (7)";
        break;
      default:
        improvementText = " -None Reported- ";
        break;
    }
    return improvementText;
  };
  const downloadReport = () => {
    setshowDownloadPopup((showDownloadPopup = true));
  };
  const downloadconfirmClick = () => {
    setshowDownloadPopup((showDownloadPopup = false));
    props.downloadReport(downloadFormat);
    setDownloadFormat((downloadFormat = 1));
  };

  const canceldownloadPopup = () => {
    setshowDownloadPopup((showDownloadPopup = false));
  };
  const addAddendumClick = () => {
    if (addendum == "") {
      const addendumErrors = [
        {
          type: "custom",
          name: "addendum",
          message: "please fill value",
        },
      ];
      setAddendumError((isAddendumError = true));
      addendumErrors.forEach(({ name, type, message }) =>
        setError(name, { type, message })
      );
    } else {
      if (isAddendumError) {
        clearErrors("addendum");
      }
      setAddendumError((isAddendumError = false));
      props.addAddendumClick(addendum);

      setAddendum((addendum = ""));
    }
  };
  const SaveClick = () => {
    setshowSavePopup((showSavePopup = true));
  };
  const saveconfirmClick = () => {
    props.saveClick(reportSaveModel, true);
    setshowSavePopup((showSavePopup = false));
  };

  const cancelsavePopup = () => {
    setshowSavePopup((showSavePopup = false));
  };

  function handleKeyBindingFn(e): string | null {
    if (e.keyCode === 49 && hasCommandModifier(e)) {
      return 'unordered-list-item';
    }
    return getDefaultKeyBinding(e);
  }

  const handleKeyCommand = (command, editorState) => {
    if (command === 'unordered-list-item'){
      setEditorState(RichUtils.toggleBlockType(editorState, command));
      return "handled";
    }
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return dayjs(result).format("MM/DD/YYYY");
  }
  const getDaysOfWeek = (weekNo: number) => {
    const daysofWeek: string[] = [];
    if (props.medication && props.medication.createPatientTrialWeekModels) {
      const trialweek = props.medication.createPatientTrialWeekModels.filter(
        (x) => {
          return x.weekNo === weekNo;
        }
      );
      if(trialweek[0]?.startDate && trialweek[0]?.endDate){
        const weekStart = dayjs(trialweek[0].startDate);
      console.log("weekStart of week: ", weekStart, weekNo);
      const weekEnd = dayjs(trialweek[0].endDate);
      console.log("weekEnd of week: ", weekEnd, weekNo);
      const daysofWeek: string[] = [];
      let dateToAdd = weekStart.format("MM/DD/YYYY");
      if (weekStart && weekEnd) {
        let dateToAdd = weekStart.format("MM/DD/YYYY");
        while (dateToAdd <= weekEnd.format("MM/DD/YYYY")) {
          daysofWeek.push(dateToAdd);
          dateToAdd = addDays(dateToAdd, 1);
        }
      }
      console.log("DaysOfWeek: ", daysofWeek);
      getDaySelectOptions(daysofWeek);
      return daysofWeek;
      }
      
    }

    return daysofWeek;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="scroll-box finalReport__container" id="scrollWrap">
        <div className="row pl-2 pr-2">
          <div className="col-md-12 p-2">
            <div className="row pb-3">
              <div className="col-4">
                <img className="img-fluid" width="200" src={logo} alt="logo" />
                {/* <p className="logo-titel">Cleveland Clinic Children’s<br/>Center for Pediatric Behavioral Health</p> */}
              </div>
              <div className="col-4 text-center">
                <p className="mb-1 top-main-text">
                  <small>
                    <strong>Medication Monitoring</strong>
                  </small>
                </p>
                <h5 className="mt-1">
                  <strong>MIDPOINT</strong>
                </h5>
              </div>
              <div className="col-4 text-end">
                <p className="uppercase mb-0">
                  <i>ADHD center for Evaluation & Treatment (ACET)</i>
                </p>
                <p>
                  <small>
                    Date - <strong>{props.visitDate}</strong>
                  </small>
                </p>
              </div>
            </div>
          </div>
          <p className="final__medTrial">
            Should you need further information, <strong>contact the clinical staff at 216-448-6310</strong>.
          </p>
          <div className="col-md-6 pl-2 pr-2">
            <div className="form-group form-field">
              <label className="form-label">Monitoring Physician </label>
              <input
                type="text"
                disabled={props.midWeekVisitReportModel.isSaved}
                defaultValue={props.midWeekVisitReportModel.monitoringPhysician}
                {...register("monitoringPhysician")}
                className={
                  !errors.montoringPhysician
                    ? "form-control"
                    : "form-control  is-invalid"
                }
              />
            </div>
          </div>
          <div className="col-md-6 pl-2 pr-2">
            <div className="form-group form-field">
              <label className="form-label">Participant</label>
              <div className="multi-select">
                <Select
                  className={`reactSelect multi-roles`}
                  // name="finalSevr"
                  styles={{ menu: (provided) => ({ ...provided, zIndex: 99 }) }}
                  placeholder="Select Participants"
                  isDisabled={props.midWeekVisitReportModel.isSaved}
                  value={participants || []}
                  onChange={(val) => {
                    setParticipants(val as severitySelectedData[]);
                  }}
                  isMulti
                  options={participantsOptions}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 pl-2 pr-2">
            <div className="form-group form-field">
              <label className="form-label">Service Provided</label>
              <select
                disabled={props.midWeekVisitReportModel.isSaved}
                defaultValue={props.midWeekVisitReportModel.serviceProvided}
                {...register("serviceProvided")}
                className={
                  !errors.serviceProvided
                    ? "form-control"
                    : "form-control  is-invalid"
                }
                onChange={(e) => handleChange(e)}
              >
                <option>Select</option>
                <option>
                  90847 - Family psych with patient present (26-50+mins)
                </option>
                <option>
                  90846 Family Psych with out patient present (26-50+mins)
                </option>
                <option>90832 psychotherapy with patient (16-37mins)</option>
                <option>90834 psychotherapy with patient (38-52mins)</option>
                <option>90837 psychotherapy with patient (53+mins)</option>
                <option>
                  96167 H&B Intervention, Family with patient Initial 30mins
                </option>
                <option>
                  96168 H&B Intervention Family with patient each additional
                  15mins
                </option>
                <option>
                  96170 H&B Intervention with out patient Initial 30mins
                </option>
                <option>
                  9800595 Virtual and or phone visit family psychotherapy with
                  out patient
                </option>
                <option>
                  9800463 Virtual and or phone visit family psychotherapy with
                  patient
                </option>
                <option value="other">Other</option>
              </select>
              <i className="fa fa-angle-down relation_arrow"></i>
            </div>
          </div>
          <div className="col-md-6 pl-2 pr-2">
            <div className="form-group form-field">
              <label className="form-label">If other, please specify</label>
              <input
                {...register("otherText")}
                type="text"
                defaultValue={props.midWeekVisitReportModel.otherText}
                className={
                  !errors.otherText
                    ? "form-control"
                    : "form-control  is-invalid"
                }
                disabled={!isToggled || props.midWeekVisitReportModel.isSaved}
              />
            </div>
          </div>

          <div className="col-md-6 pl-2 pr-2">
            <div className="form-group form-field">
              <label className="form-label">
                Face to face time with parent/family (in minutes)
              </label>
              <input
                {...register("facetoFaceTime")}
                disabled={props.midWeekVisitReportModel.isSaved}
                defaultValue={props.midWeekVisitReportModel.facetoFaceTime}
                type="text"
                className={
                  !errors.facetoFaceTime
                    ? "form-control"
                    : "form-control  is-invalid"
                }
              />
            </div>
          </div>
          <div className="col-md-6 pl-2 pr-2">
            <div className="form-group form-field">
              <label className="form-label">Diagnosis Code</label>
              <div className="multi-select">
                <Select
                  className={`reactSelect multi-roles`}
                  // name="finalSevr"
                  styles={{ menu: (provided) => ({ ...provided, zIndex: 99 }) }}
                  isDisabled={props.midWeekVisitReportModel.isSaved}
                  placeholder="Select Code"
                  value={diagnosisCodes || []}
                  onChange={(val) => {
                    setDiagnosis(val as severitySelectedData[]);
                  }}
                  isMulti
                  options={diagnosisCodesOptions}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 pl-2 pr-2">
            <div className="form-group form-field">
              <label className="form-label">Trial Length</label>
              <select
                {...register("trialLength")}
                disabled={props.midWeekVisitReportModel.isSaved}
                defaultValue={props.midWeekVisitReportModel.trialLength}
                className={
                  !errors.trialLength
                    ? "form-control"
                    : "form-control  is-invalid"
                }
              >
                <option disabled={true} value="3">
                  3 weeks
                </option>
                <option disabled={true} value="4">
                  4 weeks
                </option>
                <option value="5">5 weeks</option>
              </select>
              <i className="fa fa-angle-down relation_arrow"></i>
            </div>
          </div>
          <div className="col-md-6 pl-2 pr-2">
            <div className="form-group form-field">
              <label className="form-label">Trial Style</label>
              <select
                {...register("trialStyle")}
                disabled={props.midWeekVisitReportModel.isSaved}
                defaultValue={props.midWeekVisitReportModel.trialStyle}
                className={
                  !errors.trialStyle
                    ? "form-control"
                    : "form-control  is-invalid"
                }
              >
                <option value="">Select</option>
                <option value="Blinded/Placebo">Blinded/Placebo</option>
                <option value="Blinded only">Blinded only</option>
                <option value="Unblinded">Unblinded</option>
              </select>
              <i className="fa fa-angle-down relation_arrow"></i>
            </div>
          </div>
          <div className="col-md-12 p-0">
            <div className="d-block p-2">
              <h3 className="section-titel">MEDICATION</h3>
            </div>
            <div className="row m-0">
              {props.medication?.createPatientTrialWeekModels?.map(
                (item, index) => (
                  <div className="col p-2">
                    <div className="titel-box">
                      {"Week " + item.weekNo} (
                      {dayjs(item.startDate).format("MM/DD/YYYY")})
                    </div>
                    <div className="gray-box-item">
                      <p className="no-data">Medication Family</p>
                      <h6>
                        {
                          item.createPatientTrailWeeklyMedicationModel
                            .medicationFamily
                        }
                      </h6>
                      <p className="no-data">Brand Name</p>
                      <h6>
                        {
                          item.createPatientTrailWeeklyMedicationModel
                            .medicationBrandName
                        }
                      </h6>
                      <p className="no-data">Generic Name</p>
                      <h6>
                        {
                          item.createPatientTrailWeeklyMedicationModel
                            .medicationGenericName
                        }
                      </h6>
                      <p className="no-data">Dosage Form</p>
                      <h6>
                        {
                          item.createPatientTrailWeeklyMedicationModel
                            .medicationFormat
                        }
                      </h6>
                      <p className="no-data">Dosage Strength</p>
                      <h6>
                        {
                          item.createPatientTrailWeeklyMedicationModel
                            .medicationDosage
                        }
                      </h6>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="d-block p-2">
              <h3 className="section-titel">SIDE EFFECTS</h3>
            </div>
            <div className="finalReport__medication">
              {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData &&
                props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData
                  .filter((u) => u.weekNo != 4 && u.weekNo != 5)
                  .map((row, i) => (
                    <WeekwiseSideEffectInfo
                      weekCount={row.weekNo}
                      weekDate={row.weekStartDate}
                      insomniaOrTroubleSleeping={row.insomniaOrTroubleSleeping}
                      nightmares={row.nightmares}
                      staresALotOrDayDreams={row.staresALotOrDayDreams}
                      decreasedAppetite={row.decreasedAppetite}
                      talkLessWithOthers={row.talkLessWithOthers}
                      unInterestedWithOthers={row.unInterestedWithOthers}
                      sideEffectsData={row}
                    ></WeekwiseSideEffectInfo>
                  ))}
            </div>
            <div className="d-block p-2">
              <div className="weeklyTable__container weekRatingTable finalReport__table">
                <table>
                  <tbody>
                    <tr>
                      <td rowSpan={2} className="final__mainCGLSevr">
                        <h3 className="heading rating__heading">
                          CGI - Side effects
                          <br /> (Severity)
                        </h3>
                      </td>
                      {props.clinicianWeeklyData
                        .lstClinicianSideEffectsWeeklyData &&
                        props.clinicianWeeklyData?.lstClinicianSideEffectsWeeklyData?.map(
                          (row, i) =>
                            !row.isBaseLine ? (
                              <td>
                                <Heading
                                  heading={"Week " + row.weekNo}
                                  subHeading={dayjs(row.weekStartDate).format(
                                    "MM/DD/YYYY"
                                  )}
                                  styleName="heading rating__heading"
                                  ccfSubHeading="finalReport__subHead"
                                />
                              </td>
                            ) : null
                        )}
                    </tr>
                    <tr>
                      {props.clinicianWeeklyData
                        .lstClinicianSideEffectsWeeklyData &&
                        props.clinicianWeeklyData?.lstClinicianAddImprovementsWeeklyData.map(
                          (improvements, index) => {
                            return !improvements.isBaseLine ? (
                              <Fragment>
                                {!improvements.isBaseLine && (
                                  <td>
                                    <div
                                      className={getBorderStyle(
                                        improvements.cgiSeverityOfSideEffects
                                      )}
                                    >
                                      <h5 className="final__sevrName ">
                                        <span
                                          className={weekDotStyle(
                                            improvements.cgiSeverityOfSideEffects
                                          )}
                                        ></span>
                                        {getSeverityText(
                                          improvements.cgiSeverityOfSideEffects
                                        )}
                                      </h5>
                                    </div>
                                  </td>
                                )}
                                {improvements.weekType != 3 &&
                                  improvements.isBaseLine && (
                                    <td className="empty"></td>
                                  )}
                              </Fragment>
                            ) : null;
                          }
                        )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="weeklyTable__container weekRatingTable finalReport__table">
              <table>
                <tbody>
                  <tr>
                    <td rowSpan={2} className="final__mainCGLSevr">
                      <Heading
                        heading="CGI - Behavior (Improvement)"
                        styleName="heading rating__heading"
                      />
                    </td>
                    {props.clinicianWeeklyData
                      .lstClinicianSideEffectsWeeklyData &&
                      props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
                        (row, i) =>
                          !row.isBaseLine ? (
                            <td>
                              <Heading
                                heading={"Week " + row.weekNo}
                                subHeading={dayjs(row.weekStartDate).format(
                                  "MM/DD/YYYY"
                                )}
                                styleName="heading rating__heading"
                                ccfSubHeading="finalReport__subHead"
                              />
                            </td>
                          ) : null
                      )}
                  </tr>
                  <tr>
                    {props.clinicianWeeklyData
                      .lstClinicianAddImprovementsWeeklyData &&
                      props.clinicianWeeklyData.lstClinicianAddImprovementsWeeklyData.map(
                        (improvements, index) => {
                          return !improvements.isBaseLine ? (
                            <Fragment>
                              {!improvements.isBaseLine && (
                                <td key={index + 1}>
                                  <div
                                    className={getBorderStyle(
                                      improvements.cgiImprovementOfbehavior
                                    )}
                                  >
                                    <h5 className="final__sevrName ">
                                      <span
                                        className={weekDotStyle(
                                          improvements.cgiImprovementOfbehavior
                                        )}
                                      ></span>
                                      {getImprovementText(
                                        improvements.cgiImprovementOfbehavior
                                      )}
                                    </h5>
                                  </div>
                                </td>
                              )}
                              {improvements.weekType != 3 &&
                                improvements.isBaseLine && (
                                  <td className="empty"></td>
                                )}
                            </Fragment>
                          ) : null;
                        }
                      )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 p-2">
            <div className="form-group">
              <div className="d-block p-2">
                <h3 className="section-titel">Procedures</h3>
              </div>
              <div className="d-block p-3 border border-radius-2">
                Daily Side Effects Behavioral Monitoring Scale-SEBMS
                <br />
                ADHD Rating Scale-IV
                <br />
                Connors Global Index-Parent Version
              </div>
              {/* <textarea name="Procedures" className={
                          !errors.faceToFace
                              ? "form-control"
                              : "form-control  is-invalid"
                      } style={{ height: "78px" , overflow: "hidden"}}
                          defaultValue="Daily Side Effects Behavioral Monitoring Scale-SEBMS                             
                               ADHD Rating Scale-IV
                               Connors Global Index-Parent Version"
                          readOnly={true}></textarea> */}
            </div>
          </div>
          <div className="col-12 p-2">
            <p>Dosing - Once Daily</p>
          </div>
          <div className="col-12 p-0">
            <div className="row">
              <div className="col-4 p-2">
                <div className="form-group form-field">
                  <label className="form-label mt-4">Placebo Present</label>
                </div>
              </div>
              <div className="col-8 p-2">
                <div className="btn-group btn-group-toggle check-radios">
                  <label
                    className={
                      props.midWeekVisitReportModel.placeboPresent == "Yes"
                        ? "btn btn-light active"
                        : "btn btn-light"
                    }
                  >
                    <input
                      type="radio"
                      disabled={props.midWeekVisitReportModel.isSaved}
                      onClick={(e) => yesNoSelection(1, "Yes")}
                      {...register("placeboPresent")}
                      value="Yes"
                      checked
                    />{" "}
                    Yes
                  </label>
                  <label
                    className={
                      props.midWeekVisitReportModel.placeboPresent == "No"
                        ? "btn btn-light active"
                        : "btn btn-light"
                    }
                  >
                    <input
                      type="radio"
                      disabled={props.midWeekVisitReportModel.isSaved}
                      onClick={(e) => yesNoSelection(1, "No")}
                      {...register("placeboPresent")}
                      value="No"
                    />{" "}
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 p-0">
            <div className="row">
              <div className="col-4 p-2">
                <div className="form-group form-field">
                  <label className="form-label mt-4">
                    Teacher Data Received
                  </label>
                </div>
              </div>
              <div className="col-8 p-2">
                <div className="btn-group btn-group-toggle check-radios">
                  <label
                    className={
                      props.midWeekVisitReportModel.isTeacherDataReceived ==
                      "Yes"
                        ? "btn btn-light active"
                        : "btn btn-light"
                    }
                  >
                    <input
                      type="radio"
                      disabled={props.midWeekVisitReportModel.isSaved}
                      onClick={(e) => yesNoSelection(2, "Yes")}
                      {...register("isTeacherDataReceived")}
                      value="Yes"
                      checked
                    />{" "}
                    Yes
                  </label>
                  <label
                    className={
                      props.midWeekVisitReportModel.isTeacherDataReceived ==
                      "No"
                        ? "btn btn-light active"
                        : "btn btn-light"
                    }
                  >
                    <input
                      type="radio"
                      disabled={props.midWeekVisitReportModel.isSaved}
                      onClick={(e) => yesNoSelection(2, "No")}
                      {...register("isTeacherDataReceived")}
                      value="No"
                    />{" "}
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 p-2">
            <h3 className="line-titel">
              <span>days of medication missed</span>
            </h3>
          </div>
          <div className="col-12 p-0">
            <div className="row">
              <div className="col-4">
                <div className="form-group form-field mt-4">
                  <label className="form-label">
                    Week 1 (Days Missed - {missedWeek1.length} /{" "}
                    {daysOfWeek1.length})
                  </label>
                </div>
              </div>
              <div className="col-md-6 pl-2 pr-2">
                <div className="form-group form-field">
                  <label className="form-label">Missed Dosages</label>
                  <div className="multi-select">
                    <Select
                      className={`reactSelect multi-roles`}
                      // name="finalSevr"
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 99 }),
                      }}
                      isDisabled={props.midWeekVisitReportModel.isSaved}
                      placeholder="Select Dates Missed"
                      value={missedWeek1 || []}
                      onChange={(val) => {
                        setMissedWeek1(val as severitySelectedData[]);
                      }}
                      isMulti
                      options={getDaySelectOptions(daysOfWeek1)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <div className="form-group form-field mt-4">
                  <label className="form-label">
                    Week 2 (Days Missed - {missedWeek2.length} /{" "}
                    {daysOfWeek2.length})
                  </label>
                </div>
              </div>
              <div className="col-md-6 pl-2 pr-2">
                <div className="form-group form-field">
                  <label className="form-label">Missed Dosages</label>
                  <div className="multi-select">
                    <Select
                      className={`reactSelect multi-roles`}
                      // name="finalSevr"
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 99 }),
                      }}
                      isDisabled={props.midWeekVisitReportModel.isSaved}
                      placeholder="Select Dates Missed"
                      value={missedWeek2 || []}
                      onChange={(val) => {
                        setMissedWeek2(val as severitySelectedData[]);
                      }}
                      isMulti
                      options={getDaySelectOptions(daysOfWeek2)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <div className="form-group form-field mt-4">
                  <label className="form-label">
                    Week 3 (Days Missed - {missedWeek3.length} /{" "}
                    {daysOfWeek3.length})
                  </label>
                </div>
              </div>
              <div className="col-md-6 pl-2 pr-2">
                <div className="form-group form-field">
                  <label className="form-label">Missed Dosages</label>
                  <div className="multi-select">
                    <Select
                      className={`reactSelect multi-roles`}
                      // name="finalSevr"
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 99 }),
                      }}
                      isDisabled={props.midWeekVisitReportModel.isSaved}
                      placeholder="Select Dates Missed"
                      value={missedWeek3 || []}
                      onChange={(val) => {
                        setMissedWeek3(val as severitySelectedData[]);
                      }}
                      isMulti
                      options={getDaySelectOptions(daysOfWeek3)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 p-2">
            <p>There were no vitals taken for this visit.</p>
          </div>
          <div className="col-12 p-2">
            <div className="form-group form-field">
              <h3 className="section-titel">Notes</h3>
              <div className="d-block border">
                {/* <textarea {...register("notes")} disabled={props.midWeekVisitReportModel.isSaved} defaultValue={props.midWeekVisitReportModel.notes} className={
                          !errors.notes
                              ? "form-control text-area-box"
                              : "form-control text-area-box is-invalid"
                      }
                          placeholder="Please provide any additional information not captured above. You can also specify the medications taken by the patient prior to the trial. Please include as much detail as possible."></textarea> */}
                <Editor
                  placeholder="Please provide any additional information not captured above. You can also specify the medications taken by the patient prior to the trial.Please include as much detail as possible."
                  editorState={editorState}
                  onChange={setEditorState}
                  handleKeyCommand={handleKeyCommand}
                  readOnly={props.midWeekVisitReportModel.isSaved}
                  keyBindingFn={handleKeyBindingFn}
                />
              </div>
            </div>
          </div>
          {props.midWeekVisitReportModel.isSaved && (
            <div className="col-12 p-2">
              <div className="form-group form-field">
                <h3 className="section-titel">Addendum</h3>
                <div className="d-block">
                  {props.addendumList?.map((addendum, index) => {
                    let noteDate = addendum.addedOn
                      ? dayjs(addendum.addedOn)
                      : dayjs();
                    return (
                      <Fragment key={"addendum_" + index}>
                        <div>
                          <h3 className="heading weekNote__heading">
                            {noteDate.format("dddd") +
                              " (" +
                              noteDate.format("MM/DD/YYYY") +
                              ")"}
                            <span className="timestamp">
                              {noteDate.format("HH:mm")}
                            </span>
                          </h3>
                          <pre style={{ whiteSpace: "break-spaces" }}>
                            {addendum.addendum}
                          </pre>
                        </div>

                        <hr />
                      </Fragment>
                    );
                  })}
                </div>
                <div className="report__summary w-100 p-0">
                  <textarea
                    {...register("addendum")}
                    value={addendum}
                    placeholder="Please provide any additional information"
                    className={
                      !isAddendumError
                        ? "form-control text-area-box"
                        : "form-control text-area-box is-invalid"
                    }
                    onChange={(e) => {
                      setAddendum((addendum = e.target.value));
                    }}
                  ></textarea>
                  {isAddendumError && (
                    <span className={"text-danger"}>
                      Please enter the additional information
                    </span>
                  )}
                  <div className="d-block additional-box">
                    <Button
                      type="primary"
                      size="large"
                      onClick={addAddendumClick}
                      className="btn_reset"
                    >
                      Add
                    </Button>
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => {
                        setAddendum("");
                      }}
                      className="btn-link"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="row border-top">
          <div className="col-12 p-3 text-end">
            <button
              type="button"
              disabled={props.isDownloading}
              onClick={downloadReport}
              className="ant-btn ant-btn-text ant-btn-lg finalReport_dwnload"
            >
              <img width="16" alt="download" src={downloading} />{" "}
              {props.isDownloading ? "DOWNLOADING" : "DOWNLOAD"}
            </button>
            {!props.midWeekVisitReportModel.isSaved && (
              <span>
                <button
                  type="submit"
                  className="ant-btn ant-btn-primary ant-btn-lg btn_reset"
                >
                  <span>Save as Draft</span>
                </button>
                <button
                  type="submit"
                  onClick={SaveClick}
                  className="ant-btn ant-btn-primary ant-btn-lg btn_add"
                >
                  <span>Submit</span>
                </button>
              </span>
            )}
          </div>
        </div>
        <Modal
          title=""
          open={showSavePopup}
          onOk={saveconfirmClick}
          onCancel={cancelsavePopup}
          okText="Yes"
          cancelText="No"
          closable={false}
          maskClosable={false}
        >
          {" "}
          <p>
            Are you ready to submit the MidPoint Visit Report? You will not be
            allowed to make any changes after submitting the Midpoint Visit
            Report.
          </p>
        </Modal>

        <Modal
          key="downloadPopup"
          title="Download"
          open={showDownloadPopup}
          onOk={downloadconfirmClick}
          onCancel={canceldownloadPopup}
          okText="Download"
          cancelText="Cancel"
          closable={false}
          maskClosable={false}
        >
          <p>Which format do you want to use?</p>
          <RadioGroup value={downloadFormat}>
            <Radio
              value={1}
              onClick={() => {
                setDownloadFormat((downloadFormat = 1));
              }}
            >
              Adobe PDF(.pdf)
            </Radio>
            <Radio
              value={2}
              onClick={() => {
                setDownloadFormat((downloadFormat = 2));
              }}
            >
              MS word document(.docx)
            </Radio>
          </RadioGroup>
        </Modal>
      </div>

      <div className="d-block pt-2 pb-2 text-end">
        <div onClick={scrollTop} className="down-arrow">
          <i className="fa fa-angle-up" aria-hidden="true"></i>
        </div>
      </div>
    </form>
  );
}

function getBorderStyle(cgiImprovementOfbehavior: any): string | undefined {
  throw new Error("Function not implemented.");
}

function weekDotStyle(cgiImprovementOfbehavior: any): string | undefined {
  throw new Error("Function not implemented.");
}

function getImprovementText(
  cgiImprovementOfbehavior: any
):
  | string
  | number
  | boolean
  | {}
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | React.ReactNodeArray
  | React.ReactPortal
  | null
  | undefined {
  throw new Error("Function not implemented.");
}
