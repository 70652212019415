import React, { Fragment } from "react";
import { HttpResponse } from "../../../../core";
import { AddDiagnosisPostModel } from "../../../../models/diagnosis/add-diagnosis-post.model";
import { PreviousDiagnosisModel } from "../../../../models/diagnosis/diagnosis.model";
import { DiagnosisService } from "../../../../services/diagnosis/diagnosis.service";
import { ShowSuccessMessage } from "../../../../shared/helpers";
import AddPreviousDiagnosisForm from "./previousDiagnosis";

interface IState {
  patientId: string;
  isLoading: boolean;
  diagnosisModel: AddDiagnosisPostModel;
  reRenderForm: boolean;
  isEnabled: boolean;
  formSaved: boolean;
  showModel: boolean;
  isFormSave?: Function;
}
export class PreviousDiagnosis extends React.Component<any, IState> {
  private diagnosisService: DiagnosisService;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.diagnosisService = new DiagnosisService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      patientId: this.props.patientId,
      reRenderForm: false,
      diagnosisModel: {} as AddDiagnosisPostModel,
      isEnabled: false,
      formSaved: false,
      showModel: false,
    };
    return initialState;
  }
  componentDidMount() {
    this.loadData();
    window.scroll(0, 0);
  }
  handleSubmittedOk = (e) => {
    this.setState({
      showModel: false,
    });
    this.diagnosisService
      .updateParentIsFormSubmitted(this.props.patientId)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
          this.props.nextTabChange();
          window.scrollTo(0, 0);
        }
      })
      .catch(() => {});
  };
  handleSubmittedCancel = (e) => {
    this.setState({
      showModel: false,
    });
  };
  handleSubmitClick = (e) => {
    this.setState({
      showModel: true,
    });
  };
  postData = (data) => {
    let diagnosisPostModel = data.diagnosisModel;
    if (this.state.isLoading) {
      return;
    }
      if (data.isAutoSave) {
          this.setLoading(false);
      }
      else {
          this.setLoading(true);
      }
    this.diagnosisService
      .postDiagnosis(diagnosisPostModel)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
          if (!data.isAutoSave) {
            this.setState({
              formSaved: true,
            });
            ShowSuccessMessage(
              "Data Saved Successfully.Please click on the Submit button to complete your form."
            );
          }
        }
        this.setLoading(false);
        this.props.isFormSave();
        this.loadData(data.isAutoSave);
      })
      .catch(() => {
        this.setLoading(false);
      });
  };
  parentTabChange = (tab: any) => {
    this.props.parentTabChange(tab);
  };

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }
  loadData = (isAutoSave?: any) => {
    if (this.state.patientId) {
        if (isAutoSave) {
            this.setLoading(false);
        }
        else {
            this.setLoading(true);
        }
      this.diagnosisService
        .getDiagnosis(this.props.patientId)
        .then((res: HttpResponse<PreviousDiagnosisModel[]>) => {
          this.setLoading(false);
          if (res && res.result && res.result.length > 0) {
            if (isAutoSave) {
              let pm = this.state.diagnosisModel;
              res.result.forEach((item, index) => {
                pm.listPreviousDiagnosisModel[index].id = item.id;
              });
              this.setState({
                diagnosisModel: {
                  patientId: this.props.patientId,
                  listPreviousDiagnosisModel: pm.listPreviousDiagnosisModel,
                }
              });
              
            }else{
            this.setState({
              diagnosisModel: {
                patientId: this.props.patientId,
                listPreviousDiagnosisModel: res.result,
              },
              isEnabled: true,
              reRenderForm: true,
            });
            this.setState({ reRenderForm: false });
          }
          } else {
            this.setState({
              diagnosisModel: {
                patientId: this.props.patientId,
                listPreviousDiagnosisModel: [
                  {
                    id: "",
                    professionalName: "",
                    diagnosisGiven: "",
                    isReportAvailable: undefined,
                    diagnosisDate: undefined,
                  },
                ],
              } as AddDiagnosisPostModel,
              isEnabled: false,
              reRenderForm: true,
            });
            this.setState({ reRenderForm: false });
          }
        })
        .catch((ex) => {
          this.setLoading(false);
        });
    }
  };
  deleteDiagnosis = (siblingId) => {
    this.diagnosisService.deleteDiagnosis(siblingId).then((res: HttpResponse<boolean>) => {
      if (res && res.result) {
        //this.loadData();
      }
    });
  };
  assignModel = (data: any) => {
    this.setState({
      diagnosisModel: data.diagnosisModel,
    });
  };

  render() {
    return (
      <Fragment>
        {!this.state.reRenderForm && (
          <AddPreviousDiagnosisForm
            onSubmit={this.postData}
            isSaving={this.state.isLoading}
            patientId={this.props.patientId}
            assignModel={this.assignModel}
            diagnosisModel={this.state.diagnosisModel}
            parentTabChange={this.parentTabChange}
            isEnabled={this.state.isEnabled}
            deleteDiagnosis={this.deleteDiagnosis}
            formSaved={this.state.formSaved}
            isShowModel={this.state.showModel}
            submitClick={this.handleSubmitClick}
            submitForm={this.handleSubmittedOk}
            cancelSubmitForm={this.handleSubmittedCancel}
          />
        )}
      </Fragment>
    );
  }
}
