import React from "react";
import { PsychologicalTestingModel } from "../../../../../../models/psychologicalTesting/psychological-testing.model";
import { Loader } from "../../../../../../shared/loaders";
import ChildGuardianInfo from "../../../../../childGuardianInfo";
import Heading from "../../../../../heading";
import moment from "moment";
import dayjs from "dayjs";

interface IProps {
  patientId: string;
  isLoading: boolean;
  psychologicalTestingModel: PsychologicalTestingModel[];
}
const checkIfNullOrEmpty = (val: any) => {
  let data = "-";
  if (val == null || val == undefined || val == "" || val == "Invalid Date") {
    data = "-";
  } else {
    data = val;
  }
  return data;
};
const getDataForNull = (val: any) => {
  let data = "-";
  if (val == null || val == undefined) {
    data = "-";
  } else if (val) {
    data = "Yes";
  } else if (!val) {
    data = "No";
  }
  return data;
};
export default function PsychologicalTestingViewForm(props: IProps) {
  let psychological = props.psychologicalTestingModel;
  return (
    <div className="region_table">
      {!props.isLoading && (
        <div>
          <Heading
            heading="Psychological Testing"
            subHeading=""
            styleName="medicalGeneralDetail__style"
          />
          <ChildGuardianInfo
            designation="Has your child participated in psychological testing through
                  school or other agency?"
            name={psychological.length > 0 ? "Yes" : "No"}
            email=""
            guardian="guardianStyle"
          />
          {psychological != null &&
            psychological.map((psychology, index) => {
              return (
                <div className="SMH__accord__sibContent" key={index}>
                  <ChildGuardianInfo
                    designation="Name of Professional/Organization"
                    name={checkIfNullOrEmpty(
                      psychology.nameOfProfessionalOrOrganization
                    )}
                    email=""
                    guardian="guardianStyle"
                  />
                  <ChildGuardianInfo
                    designation="Purpose"
                    name={checkIfNullOrEmpty(psychology.purpose)}
                    email=""
                    guardian="guardianStyle"
                  />
                  <ChildGuardianInfo
                    designation="Report Available?"
                    name={getDataForNull(psychology.reportAvailable)}
                    email=""
                    guardian="guardianStyle"
                  />
                  <ChildGuardianInfo
                    designation="Date of Testing"
                    name={checkIfNullOrEmpty(
                      dayjs(new Date(
                          psychology.dateOfTesting ?? ""
                      ).toLocaleDateString("fr-cA")).format("MM/DD/YYYY")
                    )}
                    email=""
                    guardian="guardianStyle"
                  />
                </div>
              );
            })}
        </div>
      )}
        <Loader
            loading={props.isLoading}
            marginBottom="0px"
            marginTop="8px"
            width="368px"
          ></Loader>
    </div>
  );
}
