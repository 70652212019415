import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "../../styles.css";
import { Loader } from "../../../../shared/loaders";
import Heading from "../../../heading";
import { Button } from "antd";
import { BehavioralProblemPostModel } from "../../../../models/behaviourProfile/behaviour-problem-post.model";
import { BeahaviourProblemOption } from "./beahaviourProblemOption";

type AddBehavioralProblemFormValues = {
  comments: string | undefined
}
interface IProps {
  behavioralProblemPostModel: BehavioralProblemPostModel;
  onSubmit: Function;
  isSaving: boolean;
  patientId: string;
  assignModel: Function;
  isReadOnly: boolean;
  parentTabChange: Function;
}
export function AddBehavioralProblemForm(props: IProps) {
  const { register, handleSubmit, control, getValues, formState, setValue } = useForm<AddBehavioralProblemFormValues>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  function setInitialValues(){
    setValue("comments", props.behavioralProblemPostModel.comments);
  }
  useEffect(() => {
    setInitialValues();
  }, [props]);

  let behavioralProblemPostModel = props.behavioralProblemPostModel;
  const formSubmit = (data) => {
    behavioralProblemPostModel.patientId = props.patientId;
    behavioralProblemPostModel.comments = data.comments;
    props.assignModel({ behavioralProblemPostModel: behavioralProblemPostModel });
    if (checkForError()) {
      return false;
    }
    props.onSubmit({ behavioralProblemPostModel: behavioralProblemPostModel, isAutoSave: false });
  };
  const checkForError = () => {
    let hasError = false;
    for (const objProp in behavioralProblemPostModel) {
      if (
        objProp != "id" &&
        objProp != "patientId" &&
        objProp != "comments" &&
        objProp.indexOf("Error") < 0
      ) {
        if (
          behavioralProblemPostModel[objProp] == null ||
          behavioralProblemPostModel[objProp] == 0
        ) {
          behavioralProblemPostModel[objProp + "Error"] = "Please select an option.";
          hasError = true;
        } else {
          behavioralProblemPostModel[objProp + "Error"] = "";
        }
      }
    }
    props.assignModel({ behavioralProblemPostModel: behavioralProblemPostModel });
    return hasError;
  };
  const handleKeyUp = (event) => {
    setValuesInModel();
    if (event.key === "Tab") {
      saveDataOnTabChange();
    }
  };
  const saveDataOnTabChange = () => {
    if (!props.isSaving) {
      behavioralProblemPostModel.patientId = props.patientId;
      props.onSubmit({ behavioralProblemPostModel: behavioralProblemPostModel, isAutoSave: true });
    }
  };
  function setValuesInModel() {
    let model = getValues();
    behavioralProblemPostModel = Object.assign(behavioralProblemPostModel, model);
  }
  const handleOnSelect = (propName: string, value: number) => {
    behavioralProblemPostModel[propName] = value;
    behavioralProblemPostModel[propName + "Error"] = "";
    props.assignModel({ behavioralProblemPostModel: behavioralProblemPostModel });
    setValuesInModel();
    saveDataOnTabChange();
  };
  const handleOnFocus = (event) => {
    setValuesInModel();
    saveDataOnTabChange();
  };
  return (
    <div>
      <form onSubmit={handleSubmit(formSubmit)}>
        <div>
          <Heading
            heading="Behavioral Problems"
            subHeading="Using the 5-point scale, evaluate the child on the following characteristics:"
            styleName=""
          />
          <div className="form form-box">
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Is easily persuaded</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="isEasilyPersuaded"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Plays alone most of the time</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="playsAloneMostOfTheTime"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Has few or no friends</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="hasFewOrNoFriends"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Has difficulty keeping friends</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="hasDifficultyKeepingFriends"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Is picked on or rejected by other children</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="isPickedOnOrRejectedByOtherChildren"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Associated with the "bad" crowd</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="associatedWithBadCrowd"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>

            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Has sex play with other children</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="hasSexPlayWithOtherChildren"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Picks on or disrupts other children</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="picksOnOrDisruptsOtherChildren"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Gets into fights</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="getsIntoFights"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Strikes at or hit others</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="strikesAtOrHitOthers"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Body is in constant motion</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="bodyIsInConstantMotion"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Fidgets; is restless</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="fidgetsOrIsRestless"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Does things without thinking</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="doesThingsWithoutThinking"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Demands must be met immediately</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="demandsMustBeMetImmediately"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Easily frustrated</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="easilyFrustrated"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Has difficulty falling asleep</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="hasDifficultyFallingAsleep"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Is excitable or overreacts to situations</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="isExcitableOrOverreactsToSituations"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Has temper outbursts</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="hasTemperOutbursts"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Demands attention</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="demandsAttention"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Demands to have his/her own way</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="demandsToHaveHisOrHerOwnWay"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Is usually tired</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="isUsuallyTired"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Awakens very early</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="awakensVeryEarly"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Says she/he is worthless or no good</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="saysSheOrHeIsWorthlessOrNoGood"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Is overly sad</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="isOverlySad"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Cries often and easily</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="criesOftenAndEasily"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Clings to parents or other adults</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="clingsToParentsOrOtherAdults"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Feelings are easily hurt</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="feelingsAreEasilyHurt"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Is overly shy</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="isOverlyShy"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Has frequently/drastic mood changes</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="hasFrequentlyOrDrasticMoodChanges"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Is destructive toward self</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="isDestructiveTowardSelf"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Is overly anxious</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="isOverlyAnxious"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Has physical complaints</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="hasPhysicalComplaints"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Has unusual fears</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="hasUnusualFears"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Has frequent nightmares</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="hasFrequentNightmares"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Angers easily</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="angersEasily"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Is argumentative or quarrelsome</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="isArgumentativeOrQuarrelsome"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Acts more like an adult than a child</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="actsMoreLikeAnAdultThanAChild"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Tells lies</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="tellsLies"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Destroys property</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="destroysProperty"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Steals</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="steals"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Stares and daydreams</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="staresAndDaydreams"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Is overly dependent on others for help</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="isOverlyDependentOnOthersForHelp"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Has difficulty following multiple directives</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="hasDifficultyFollowingMultipleDirectives"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Is easily distracted</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="isEasilyDistracted"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Seems disorganized</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="seemsDisorganized"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <label className="text-start">Has trouble doing things in the right order</label>
                <BeahaviourProblemOption
                  model={behavioralProblemPostModel}
                  propName="hasTroubleDoingThingsInTheRightOrder"
                  onSelect={handleOnSelect}
                />
              </div>
            </div>
            <div className="medical__birth_formRow5">
              <div className="medical-group optional-field textarea_group">
                <label className="form-label">Comments</label>
                <span className="optional">Optional</span>
                <textarea
                  className="form-control textarea"
                  defaultValue={behavioralProblemPostModel.comments}
                  {...register("comments")}
                  onFocus={handleOnFocus}
                  placeholder="Please provide any additional information."
                ></textarea>
              </div>
            </div>
          </div>
          <div className="btnContainer">
            {!props.isSaving && (
              <Button
                type="default"
                size="large"
                className="back_btn"
                onClick={() => props.parentTabChange(5)}
              >
                <i className="fa fa-long-arrow-left"></i> Back
              </Button>
            )}
            {!props.isSaving && (
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="btn_add"
              >
                Save <i className="fa fa-long-arrow-right"></i>
              </Button>
            )}
            {props.isSaving && (
              <div style={{ height: "60px" }}>
                <Loader
                  loading={props.isSaving}
                  marginBottom="0px"
                  marginTop="8px"
                  width="368px"
                ></Loader>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
