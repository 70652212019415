import { AppConfig, HttpWrapper } from "../../core";
import { MedicationPostModel } from "../../models/medicalHistory/medication-post.model";
import { MedicationProblemPostModel } from "../../models/medicalHistory/medication-problem-post.model";

export class MedicalHistoryService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  /* Medication Start */
  postMedication(model: MedicationPostModel) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "medicalHistory/medication/create",
      model
    );
  }
  getMedicalHistoryDetail(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint + "medicalHistory/medication/detail/" + patientId
    );
  }
  /* Medication End */

   /* Medical Problem Start */
   getMedicationProblem(patientId: string) {
    return this.wrapper.get<any>(AppConfig.apiEndpoint + 'medicalHistory/medication-problem/detail/' +patientId);
  }
  
  postMedicationProblem(model: MedicationProblemPostModel) {
    return this.wrapper.post<any>(AppConfig.apiEndpoint + 'medicalHistory/medication-problem/create', model);
  }
  /* Medical Problem End */
  getMedicationHistoryDetail(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint + "patientDetail/medication-history-detail/" + patientId
    );
  }
  getMedicalHistoryStepStatus(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint + "medicalHistory/getMedicalHistoryStepStatus/" + patientId
    );
  }
}
