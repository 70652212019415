import React, { Fragment } from "react";
import { HttpResponse } from "../../../../../../core";
import { ReferralConcernPostModel } from "../../../../../../models/referralConcern/referral-concern-post.model";
import { ReferralConcernService } from "../../../../../../services/referralConcern/referral-concern.service";
import ReferralConcernViewForm from "./referralConcernViewForm";

interface IState {
    patientId: string;
    isLoading: boolean;
    referralConcernPostModel: ReferralConcernPostModel;
  }
  export class ReferralConcernTab extends React.Component<any, IState> {
    private referralConcernService: ReferralConcernService;
  
    constructor(props) {
      super(props);
      this.state = this.getInitialState();
      this.referralConcernService = new ReferralConcernService();
    }
    private getInitialState() {
      let initialState: IState = {
        isLoading: false,
        patientId: this.props.patientId,
        referralConcernPostModel: {} as ReferralConcernPostModel
      };
      return initialState;
    }
    componentDidMount() {
      if(this.props.patientId !=null ){
        this.loadData();
     }  
    }
    private setLoading(loading: boolean) {
      this.setState({
        isLoading: loading,
      });
    }
    loadData = () => {
      this.setLoading(true);
      this.referralConcernService
        .getReferralConcern(this.props.patientId)
        .then((res: HttpResponse<ReferralConcernPostModel>) => {
          if (res && res.result) {
            this.setLoading(false);
            this.setState({
              referralConcernPostModel: 
              res.result
            });
          }
        })
        .catch(() => {
          this.setLoading(false);
        });
    };

    render() {
      return (
        <Fragment>
          <ReferralConcernViewForm
            patientId={this.props.patientId}
            isLoading={this.state.isLoading}
            referralConcernDetail={this.state.referralConcernPostModel}
          />
        </Fragment>
      );
    }
  }
  