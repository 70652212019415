import React, { Component } from "react";
import { Drawer } from "antd";
import Tabs from "../../../../tabs";
import "../styles.css";
import WeeklyNoteForm from "./weeklyNoteForm";
import { ClinicianPatientTrialWeekNoteListModel } from "../../../../../models/notes/clinicianNotes/clinician-patient-trial-week-note-list.model";
import { PatientTrialNotesService } from "../../../../../services/notes/patient-trial-notes.service";
import moment from "moment";
import { ClinicianPatientTrialWeekNoteModel } from "../../../../../models/notes/clinicianNotes/clinician-patient-trial-week-note.model";
import { ShowSuccessMessage } from "../../../../../shared/helpers";
import { NoteModel } from "../../../../../models/notes/note.model";
import dayjs from "dayjs";

interface IProps {
  patientId: string;
  visible: boolean;
  hideForm: Function;
}
interface IState {
  weeklyNotes: ClinicianPatientTrialWeekNoteListModel[];
  selectedWeekNumber: number,
  isSaving: boolean;
}
class PatientNote extends Component<IProps, IState> {
  private notesService: PatientTrialNotesService;
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedWeekNumber: 0,
      weeklyNotes: [],
      isSaving: false
    };
    this.notesService = new PatientTrialNotesService();
  }
  componentDidUpdate(prevProps: any) {
    if (prevProps && this.props && this.props.visible && prevProps.visible == false) {
      this.loadNotes();
    }
  }
  changeTab(weekNo: any, weekType: number) {
    if (weekType == 3)//future
    {
      return;
    }
    this.setState({
      selectedWeekNumber: weekNo
    });
  }
  componentDidMount() {
    this.loadNotes();
  }
  loadNotes() {
    if (this.props.patientId) {
      this.notesService.getClinicianWeeklyNotes(this.props.patientId).then(res => {
        if (res.result) {
          let selectedWeekNo = res.result.find(x => x.weekType == 1) != null ? 1 : 0;
          let currentWeek = res.result.find(x => x.weekType == 2); //current week
          selectedWeekNo = currentWeek ? currentWeek.weekNo : selectedWeekNo;
          this.setState({ weeklyNotes: res.result, selectedWeekNumber: selectedWeekNo });
        }
      })
    }
  }
  addNoteHandler = (note: string) => {
    this.setState({ isSaving: true });
    let model = {} as ClinicianPatientTrialWeekNoteModel;
    model.note = note;
    model.patientTrialWeekId = this.state.weeklyNotes.find(x => x.weekNo == this.state.selectedWeekNumber)?.patientTrialWeekId;
    this.notesService.saveClinicianWeeklyNote(model).then(res => {
      if (res.result) {
        let noteModel = {
          id: res.result.id,
          note: model.note,
          createdOn: res.result.addedOn
        } as NoteModel;
        let weeklyNotes = this.state.weeklyNotes;
        weeklyNotes.find(x => x.patientTrialWeekId == model.patientTrialWeekId)?.notes.splice(0, 0, noteModel);
        this.setState({ weeklyNotes: weeklyNotes });
        this.setState({ isSaving: false });
          ShowSuccessMessage("Note added successfully.");
        //  this.loadNotes();
      }
    }, () => {
      this.setState({ isSaving: false });
    })
  }
  render() {
    const { visible, hideForm } = this.props;
    const { selectedWeekNumber } = this.state;
    return (
      <div className="patientNote__drawer">
        <Drawer title="Patient Weekly Note" width={710} closable={false} visible={visible}>
          <i className="fas fa-arrow-left hideSearch_form" onClick={() => hideForm()}></i>
          <div className="weekly__tabs_wrapper">

            <div className="weekly__tabs">
                  {this.state.weeklyNotes?.map((week) => {
                  return <Tabs key={"week_tab_" + week.weekNo}
                  styleName={week.weekType == 3 ? "weekly_tabsNote remove-cursor" : "weekly_tabsNote add-cursor"
                  }
                  text={dayjs(week.patientTrialWeekStartDate).format("MM/DD/YYYY")}
                  steps={"Week " + week.weekNo}
                  stepsAct={"Week " + week.weekNo}
                  fullText={dayjs(week.patientTrialWeekStartDate).format("MM/DD/YYYY")}
                  familyTextName=""
                  image=""
                  activeImage=""
                  tabChange={() => this.changeTab(week.weekNo, week.weekType)}
                  selected={selectedWeekNumber === week.weekNo}
                />

              })}

            </div>
            <div className="weekly__note__content">
              {this.props.visible &&
                <WeeklyNoteForm hideForm={hideForm} weeklyNotes={this.state.weeklyNotes}
                  selectedWeekNumber={this.state.selectedWeekNumber} addNote={this.addNoteHandler}
                  isSaving={this.state.isSaving} />
              }
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}
export default PatientNote;
