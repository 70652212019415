import React from "react";
import "./styles.css";

export interface GuardianProps {
  designation?: string;
  name?: string;
  email?: string;
  guardianStyle?: string;
  guardian?: string;
}
const ChildGuardianInfo: React.FC<GuardianProps> = ({
  designation,
  name,
  email,
  guardianStyle,
  guardian,
}) => {
  return (
    <div className={`guardian_detail ${guardian}`}>
      <p>{designation}</p>
      <h5 className={`${guardianStyle}`}>{name}</h5>
      <p className="guardianP2">{email}</p>
    </div>
  );
};
export default ChildGuardianInfo;
