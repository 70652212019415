import React, { useState } from "react";
import { Form, Button, DatePicker, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "../../styles.css";
import { PsychologicalTestingPostModel } from "../../../../models/psychologicalTesting/psychological-testing-post.model";
import Heading from "../../../heading";
import { PsychologicalTestingModel } from "../../../../models/psychologicalTesting/psychological-testing.model";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { Loader } from "../../../../shared/loaders";
import dayjs from "dayjs";

interface IProps {
  psychologicalTestingModel: PsychologicalTestingPostModel;
  parentTabChange: Function;
  onSubmit: Function;
  isSaving: boolean;
  patientId: string;
  assignModel: Function;
  deletePsychologicalTesting: Function;
  isEnabled: boolean;
}
export default function PsychologocialTestingForm(props: any) {
  //need to change
  let psychologicalTestingModel = props.psychologicalTestingModel;
  let initailModel = {
    id: "",
    nameOfProfessionalOrOrganization: "",
    purpose: "",
    reportAvailable: null,
  } as PsychologicalTestingModel;
  const { register, formState: {errors}, handleSubmit, control, getValues, formState, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
  });
  const [isEnabled, setIsEnabled] = useState(props.isEnabled);
  const formSubmit = () => {
    if (isEnabled) {
      if (!checkForError()) {
        props.onSubmit({
          psychologicalTestingModel: psychologicalTestingModel,
          isAutoSave: false,
        });
      }
    } else {
      psychologicalTestingModel.psychologicalDetailModel = [];
      props.onSubmit({
        psychologicalTestingModel: psychologicalTestingModel,
        isAutoSave: false,
      });
    }
  };

  const handleOrgNameChange = (event, i) => {
    psychologicalTestingModel.psychologicalDetailModel[i].nameOfProfessionalOrOrganization =
      event.target.value;
    props.assignModel({ psychologicalTestingModel: psychologicalTestingModel });  
  };

  const handlePurposeChange = (event, i) => {
    psychologicalTestingModel.psychologicalDetailModel[i].purpose = event.target.value;
    props.assignModel({ psychologicalTestingModel: psychologicalTestingModel });
  };

  const reportAvailableChange = (isSelected, i) => {
    psychologicalTestingModel.psychologicalDetailModel[i].reportAvailable = isSelected;
    psychologicalTestingModel.psychologicalDetailModel[i].reportAvailableError = "";
    props.assignModel({ psychologicalTestingModel: psychologicalTestingModel });
    saveDataOnTabChange();
  };
  const handleDateChange = (date, i) => {
    if (date == null) {
      psychologicalTestingModel.psychologicalDetailModel[i].dateOfTesting = null;
    } else {
        psychologicalTestingModel.psychologicalDetailModel[i].dateOfTesting = dayjs(date).format("MM/DD/YYYY");
    }
    setValue(
      "dateOfTesting" + i,
      dayjs(
        psychologicalTestingModel.psychologicalDetailModel[i].dateOfTesting
          ? new Date(psychologicalTestingModel.psychologicalDetailModel[i].dateOfTesting)
          : new Date()
      )
    );
    props.assignModel({ psychologicalTestingModel: psychologicalTestingModel });
    saveDataOnTabChange();
  };

  const checkForError = () => {
    let hasError = false;
    if (
      psychologicalTestingModel.psychologicalDetailModel != null &&
      psychologicalTestingModel.psychologicalDetailModel.length > 0
    ) {
      psychologicalTestingModel.psychologicalDetailModel.forEach((x) => {
        x.nameOfProfessionalOrOrganizationError = "";
        x.purposeError = "";
        x.reportAvailableError = "";
        x.dateOfTestingError = "";
        if (x.nameOfProfessionalOrOrganization == "") {
          x.nameOfProfessionalOrOrganizationError =
            "Please fill name of professional/organization.";
          hasError = true;
        }
        if (x.reportAvailable == null) {
          x.reportAvailableError = "Please select an option.";
          hasError = true;
        }
        if (x.purpose == null || x.purpose == "") {
          x.purposeError = "Please fill purpose.";
          hasError = true;
        }
        if (x.dateOfTesting == null) {
          x.dateOfTestingError = "Please select date of testing.";
          hasError = true;
        }
      });
    }
    return hasError;
  };

  const handleOnFocus = (event, i) => {
    saveDataOnTabChange();
  };

  const saveDataOnTabChange = () => {
    if (!props.isSaving) {
      props.onSubmit({
        psychologicalTestingModel: psychologicalTestingModel,
        isAutoSave: true,
      });
    }
  };

  const handleRemoveClick = (index) => {
    let idToDelete = psychologicalTestingModel.psychologicalDetailModel[index].id;
    if (idToDelete != "") {
      props.deletePsychologicalTesting(idToDelete);
    }
    psychologicalTestingModel.psychologicalDetailModel.splice(index, 1);
    props.assignModel({ psychologicalTestingModel: psychologicalTestingModel });
  };

  const handleAddClick = () => {
    psychologicalTestingModel.psychologicalDetailModel.push(initailModel);
    props.assignModel({ psychologicalTestingModel: psychologicalTestingModel });
  };
  const handleIsEnabledClick = (isEnabled: boolean) => {
    setIsEnabled(isEnabled);
    if (!isEnabled) {
      psychologicalTestingModel.psychologicalDetailModel = [];
      psychologicalTestingModel.psychologicalDetailModel.push(initailModel);
      props.assignModel({
        psychologicalTestingModel: psychologicalTestingModel,
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(formSubmit)}>
        <div>
          <Heading heading="Psychological Testing" subHeading="" styleName="" />
          <div className="form">
            <div className="row">
                <label className="form-label col-11 col-md-5">
                  Has your child participated in psychological testing through school or other
                  agency? <br /> If yes, please complete the following:
                </label>
                <div className="btn-group btnGender col-6 col-md-3">
                  <button
                    type="button"
                    onClick={() => handleIsEnabledClick(true)}
                    className={
                      isEnabled == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => handleIsEnabledClick(false)}
                    className={
                      isEnabled == false 
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
              </div>
            </div>
            {psychologicalTestingModel.psychologicalDetailModel != null &&
              psychologicalTestingModel.psychologicalDetailModel.map((psychology, index) => {
                return (
                  <div className="row mt-3" key={index}>
                    <div className="medical-group col-11 col-md-3">
                      <label className="form-label">Name of Professional/Organization</label>
                      <input
                        type="text"
                        name={"nameOfProfessionalOrOrganization" + index}
                        className={
                          !psychology.nameOfProfessionalOrOrganizationError
                            ? "form-control mb-1"
                            : "form-control mb-1  is-invalid"
                        }
                        value={psychology.nameOfProfessionalOrOrganization}
                        onChange={(e) => handleOrgNameChange(e, index)}
                        onFocus={(e) => handleOnFocus(e, index)}
                        disabled={!isEnabled}
                      />
                      {psychology.nameOfProfessionalOrOrganizationError && (
                        <div className="invalid-feedback" style={{ display: "block" }}>
                          {psychology.nameOfProfessionalOrOrganizationError}
                        </div>
                      )}
                    </div>
                    <div className="medical-group col-11 col-md-3">
                      <label className="form-label">Purpose</label>
                      <input
                        type="text"
                        name={"purpose" + index}
                        className={
                          !psychology.purposeError ? "form-control mb-1" : "form-control mb-1  is-invalid"
                        }
                        value={psychology.purpose}
                        onChange={(e) => handlePurposeChange(e, index)}
                        onFocus={(e) => handleOnFocus(e, index)}
                        disabled={!isEnabled}
                      />
                      {psychology.purposeError && (
                        <div className="invalid-feedback" style={{ display: "block" }}>
                          {psychology.purposeError}
                        </div>
                      )}
                    </div>
                    <div className="medical-group col-11 col-md-2  mb-3 btn-select">
                      <label className="form-label">Report available?</label>
                      <div
                        className={!isEnabled ? "col-6 col-md-11 disabled btn-group btnAdopted " : "col-6 col-md-12 btn-group btnAdopted "}
                      >
                        <button
                          type="button"
                          onClick={() => reportAvailableChange(true, index)}
                          className={
                            psychology.reportAvailable == true
                            ? "btn btn-primary btn-sm btnAdopted_yes"
                            : "btn btn-outline-secondary btn-sm btnAdopted_others"
                          }
                          disabled={!isEnabled}
                        >
                          Yes
                        </button>
                        <button
                          type="button"
                          className={
                            psychology.reportAvailable == false
                            ? "btn btn-primary btn-sm btnAdopted_yes"
                            : "btn btn-outline-secondary btn-sm btnAdopted_others"
                          }
                          onClick={() => reportAvailableChange(false, index)}
                          disabled={!isEnabled}
                        >
                          No
                        </button>
                        
                      </div>
                      {psychology.reportAvailableError && (
                          <div className="invalid-feedback" style={{ display: "block" }}>
                            {psychology.reportAvailableError}
                          </div>
                        )}
                    </div>
                    <div className="medical-group col-11 col-md-3 mb-3 dob">
                      <label className="form-label">Date of testing</label>
                      <Controller
                                render={({field: { onChange, onBlur, value, name, ref }}) => (
                          <Tooltip placement="right" title="Date should be in MM/DD/YYYY format">
                         <DatePicker
                            onChange={(e) => {
                              handleDateChange(e, index);
                            }}
                            className="datepicker"
                            inputReadOnly={false}
                            name={name}
                            value={
                              psychology.dateOfTesting == null
                                ? undefined
                                : dayjs(psychology.dateOfTesting)
                            }
                            placeholder="MM/DD/YYYY"
                            format="MM/DD/YYYY"
                            disabled={!isEnabled}
                                        />
                           </Tooltip>
                        )}
                        name={"dateOfTesting" + index}
                        defaultValue={dayjs(psychology.dateOfTesting)}
                        control={control}
                      />
                      {psychology.dateOfTestingError && (
                        <div className="invalid-feedback" style={{ display: "block" }}>
                          {psychology.dateOfTestingError}
                        </div>
                      )}
                    </div>
                    {psychologicalTestingModel.psychologicalDetailModel.length > 1 && (
                      <i
                      className="far fa-trash-alt delete medical-group col-12 col-md-1 align-self-center text-center"
                      onClick={() => handleRemoveClick(index)}
                  ></i>
                    )}
                    <hr className="mt-3"/>
                  </div>
                );
              })}

            <div className="medical__medChild_formRow">
              <Button
                type="primary"
                size="large"
                disabled={!isEnabled}
                onClick={handleAddClick}
                block
                icon={<PlusOutlined />}
                className="addNew__row"
              >
                Add More
              </Button>
            </div>
          </div>
          <div className="btnContainer">
            <div>
              <Loader
                loading={props.isSaving}
                marginBottom="0px"
                marginTop="8px"
                width="368px"
              ></Loader>
            </div>
            {!props.isSaving && (
              <Button
                type="default"
                size="large"
                className="back_btn"
                onClick={() => props.parentTabChange(7)}
              >
                <i className="fa fa-long-arrow-left"></i> Back
              </Button>
            )}
            {!props.isSaving && (
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="btn_add"
              >
                Save <i className="fa fa-long-arrow-right"></i>
              </Button>
            )}
            {props.isSaving && <div style={{ height: "60px" }}></div>}
          </div>
        </div>
      </form>
    </div>
  );
}
