import React, { Component } from "react";
import { Button } from "antd";
import PatientNote from "./patientNote";
import notes from "../../../../assets/images/svg-icons/notes.svg";
import "./styles.css";
import WeeklyTable from "./weeklyTable";
import { ClinicianWeeklyData } from "../../../../models/clinicianWeeklyData/clinician-weekly-data.model";
import { ClinicianWeeklyDataService } from "../../../../services/clinicianWeeklyData/clinician-weekly-data.service";
import { HttpResponse } from "../../../../core";
import { ClinicianWeeklyDataType } from "../../../../shared/enums/clinicianWeeklyData/clinician-weekly-data-type";
import { Loader } from "../../../../shared/loaders";
import PatientVisit from "./patientVisit";
import { ClinicianAddTargetOneAlias } from "../../../../models/clinicianWeeklyData/clinician-add-target-one-alias.model";


interface IState {
  formVisible: boolean;
  patientId: string;
  isLoading: boolean;
  clinicianWeeklyData: ClinicianWeeklyData;
  isFormSave?: Function;
  reRenderForm: boolean;
  isFormVisible: boolean;
  isPageLoading: boolean;
  autoSave: boolean;
  visitPageLoading?: boolean;
  handleClickLink?: Function;
  loadPatientVisitStatus?:Function;
}
class PatientWeeklyUpdate extends Component<any, IState> {
  private clinicianWeeklyDataService: ClinicianWeeklyDataService;
  constructor(props) {
    super(props);
    // this.state = {
    //   formVisible: false,
    // };
    this.state = this.getInitialState();
    this.clinicianWeeklyDataService = new ClinicianWeeklyDataService();
    this.showForm = this.showForm.bind(this);
      this.hideForm = this.hideForm.bind(this);
     // this.handleLinkClick = this.handleLinkClick.bind(this);
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      isPageLoading: false,
      autoSave: false,
      patientId: this.props.patientId,
      formVisible: false,
      reRenderForm: false,
      isFormVisible: true,
      clinicianWeeklyData: {
        lstClinicianSideEffectsWeeklyData: [],
        lstClinicianTargetSymptomsWeeklyData: [],
        lstClinicianRankingWeeklyData:[],
        lstClinicianAddImprovementsWeeklyData:[],
        lstPatientVisitDetails:[]
        },
        
    };
    return initialState;
  }
  componentDidMount() {
    if (this.state.patientId) {
      this.loadData();
    }
   
  }
  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }
  postSideEffectsData = (data) => {
    this.setState({ autoSave: true });
    let sideEffectsData = data;
    if (this.state.isLoading) {
      return;
    }
    this.setLoading(true);
    this.clinicianWeeklyDataService
      .postClinicianSideEffectsData(sideEffectsData)
      .then((res: HttpResponse<boolean>) => {
        this.setLoading(false);
        if (res && res.result) {
          // ShowSuccessMessage("Data Saved Successfully.");
        }
        this.loadData();
      })
      .catch(() => {
        this.setLoading(false);
      });
  };
  postTargetSymptomsData = (data) => {
    let targetSymptomsData = data;
    this.setState({ autoSave: true });
    if (this.state.isLoading) {
      return;
    }
    this.setLoading(true);
    this.clinicianWeeklyDataService
      .postClinicianTargetSymptomsData(targetSymptomsData)
      .then((res: HttpResponse<boolean>) => {
        this.setLoading(false);
        if (res && res.result) {
          // ShowSuccessMessage("Data Saved Successfully.");
        }
        this.loadData();
      })
      .catch(() => {
        this.setLoading(false);
      });
  };
  postRankingData = (data) => {
    this.setState({ autoSave: true });
    let ranking = data;
    // if (this.state.isLoading) {
    //   return;
    // }
    this.setLoading(true);
    this.clinicianWeeklyDataService
      .postClinicianRankingData(ranking)
      .then((res: HttpResponse<boolean>) => {
        this.setLoading(false);
        if (res && res.result) {
          // ShowSuccessMessage("Data Saved Successfully.");
        }
        //this.loadData();
      })
      .catch(() => {
        this.setLoading(false);
      });
  };
  postAddImprovementsData = (data) => {
    let addImprovementsData = data;
    this.setState({ autoSave: true });
    // if (this.state.isLoading) {
    //   return;
    // }
    this.setLoading(true);
    this.clinicianWeeklyDataService
      .postClinicianAddImprovementsData(addImprovementsData)
      .then((res: HttpResponse<boolean>) => {
        this.setLoading(false);
        if (res && res.result) {
          // ShowSuccessMessage("Data Saved Successfully.");
        }
        //this.loadData();
      })
      .catch(() => {
        this.setLoading(false);
      });
  };
  postAddVisitData = (data) => {
    let addImprovementsData = data;
    this.setState({autoSave:true});
     let hasError = false;
     if (addImprovementsData.lstPatientVisitDetails.length > 0) {
      addImprovementsData.lstPatientVisitDetails.forEach((x) => {
       x.visitError = "";
       if(x.visitDate == null && x.visitNo==1 ){
        x.visitError = "Please select visit date.";
        hasError = true;
       } 
       else if (x.visitDate != null && x.visitNo==1 && addImprovementsData.lstClinicianSideEffectsWeeklyData.find(u=>u.weekNo==1).weekStartDate !=null  && this.getDateWithoutTimeStamp(x.visitDate) > this.getDateWithoutTimeStamp(addImprovementsData.lstClinicianSideEffectsWeeklyData.find(u=>u.weekNo==1).weekStartDate)) {
           x.visitError = "Visit date 1 should be less than or equal to medication start date.";
         hasError = true;
       } else {
         if (x.visitDate!=null && addImprovementsData.lstPatientVisitDetails!=null) {
          addImprovementsData.lstPatientVisitDetails.filter(u=>u.visitNo<x.visitNo && u.visitDate!=null).forEach(data => {
            if(this.getDateWithoutTimeStamp(data.visitDate) >= this.getDateWithoutTimeStamp(x.visitDate)){
              x.visitError =
              "Visit " +
              x.visitNo +
              " should be greater than visit " +
              (parseInt(data.visitNo.toFixed()));
              hasError = true;
            }
          });
         }
       }
     });
    if (this.state.isLoading || hasError) {
      return;
    }
    this.setLoading(true);
    this.clinicianWeeklyDataService
      .postClinicianAddVisitData(addImprovementsData)
      .then((res: HttpResponse<boolean>) => {
        this.setLoading(false);
        if (res && res.result) {
          // ShowSuccessMessage("Data Saved Successfully.");
        }
        this.loadData();
        if( this.props.loadPatientVisitStatus){
          this.props.loadPatientVisitStatus();
        }
      })
      .catch(() => {
        this.setLoading(false);
      });
  };
  }
  postTargetOneAliasData = (data) => {
    let clinicianAddTargetOneAlias: ClinicianAddTargetOneAlias={};
    let target1 = data;
   
    this.setState({autoSave:true});
     if (target1.targetOneAlias!=null) {
      clinicianAddTargetOneAlias.targetOneAlias=data.targetOneAlias;
      clinicianAddTargetOneAlias.patientId=this.state.patientId;
    this.setLoading(true);
    this.clinicianWeeklyDataService
      .postTargetOneAliasData(clinicianAddTargetOneAlias)
      .then((res: HttpResponse<boolean>) => {
        this.setLoading(false);
        if (res && res.result) {
          // ShowSuccessMessage("Data Saved Successfully.");
        }
        this.loadData();
      })
      .catch(() => {
        this.setLoading(false);
      });
  };
  }
  getDateWithoutTimeStamp=(val)=>{
    return new Date(new Date(val).toLocaleDateString());
  }
  assignWeeklyDataModel = (data: any) => {
    this.setState(
      {
        clinicianWeeklyData: data.clinicianWeeklyData,
        reRenderForm: true,
      },
      () => this.setState({ reRenderForm: false })
    );
    if (data != null && data.clinicianWeeklyDataType != null) {
      if (data.clinicianWeeklyDataType == ClinicianWeeklyDataType.SideEffects) {
        this.postSideEffectsData(this.state.clinicianWeeklyData);
      } else if (data.clinicianWeeklyDataType == ClinicianWeeklyDataType.TargetSymptoms) {
        this.postTargetSymptomsData(this.state.clinicianWeeklyData);
      }
      else if (data.clinicianWeeklyDataType == ClinicianWeeklyDataType.Ranking) {
        this.postRankingData(this.state.clinicianWeeklyData);
      }
      else if (data.clinicianWeeklyDataType == ClinicianWeeklyDataType.AddImprovements) {
        this.postAddImprovementsData(this.state.clinicianWeeklyData);
      }
      else if(data.clinicianWeeklyDataType == ClinicianWeeklyDataType.AddVisitDate) {
        this.postAddVisitData(this.state.clinicianWeeklyData);
      }
      else if(data.clinicianWeeklyDataType == ClinicianWeeklyDataType.TargetOneAlias) {
        this.postTargetOneAliasData(this.state.clinicianWeeklyData);
      }
    }
  };
  loadData = () => {
    this.setLoading(true);
    if (!this.state.autoSave) {
      this.setState({ isPageLoading: true });
    }
    this.clinicianWeeklyDataService
      .getClinicianWeeklyData(this.props.patientId)
      .then((res: HttpResponse<ClinicianWeeklyData>) => {
        if (!this.state.autoSave) {
          this.setState({ isPageLoading: false });
        }
        this.setLoading(false);
        this.setState({ autoSave: false });
        if (
          res &&
          res.result &&
          res.result.lstClinicianSideEffectsWeeklyData != null
        ) {
          this.setState({
            clinicianWeeklyData: res.result,
          });
          this.setState({ isFormVisible: true });
          if(this.props.openNote){
            this.showForm();
          }
        } else {
          this.setState({ isFormVisible: false });
        }
      })
      .catch(() => {
        this.setLoading(false);
      });
  };
  showForm() {
    this.setState({
      formVisible: true,
    });
  }
  hideForm() {
    this.setState({
      formVisible: false,
    });
    }
    handleClickLink = () => {
        this.props.handleClickLink(this.state.patientId);
    }
        
  render() {
    const { formVisible } = this.state;
    return (
      <div className="weekly__container">
        <div className="weekly__visit_datepicker">
        {!this.state.visitPageLoading && this.state.isFormVisible && (<PatientVisit clinicianWeeklyData={this.state.clinicianWeeklyData}
              assignWeeklyDataModel={this.assignWeeklyDataModel}
              styleName=""/>)}
           {!this.state.isPageLoading && this.state.isFormVisible && this.state.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData!=null && this.state.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.length>0  && <div className="form-group form-field">
            <div className="week__noteBtn">
              <Button
                type="primary"
                size="large"
                className="week__note"
                icon={<img src={notes} alt="notes" className="week__note_img" />}
                onClick={this.showForm}
              >
                Add/View Note
              </Button>
            </div>
          </div>
          }
        </div>
       
        <div>
          {this.state.isFormVisible && !this.state.isPageLoading && (
            <WeeklyTable
              clinicianWeeklyData={this.state.clinicianWeeklyData}
              assignWeeklyDataModel={this.assignWeeklyDataModel}
              styleName=""
            />
          )}
          {!this.state.isFormVisible && !this.state.isPageLoading && (
            <div className="text-center">
              <h4 className="weekly__msg">You can start filling the weekly update for this patient only after the medication information is added for him. <br />
                            Click on <a className="link-text" onClick={() => this.handleClickLink()} href={void (0)}><u>link</u></a> to complete the medication information.
                            </h4>
            </div>
          )}
          {<Loader loading={this.state.isPageLoading} />}
        </div>
        <PatientNote
          visible={formVisible}
          patientId={this.state.patientId}
          hideForm={this.hideForm}
        />
      </div>
    );
  }
}
export default PatientWeeklyUpdate;
