import React from "react";
import { BirthHistoryDetailModel } from "../../../../../../models/birthHistory/birth-history-detail.model";
import { TypeOfDelivery } from "../../../../../../shared/enums/type-of-delivery.enum";
import { TypeOfLabour } from "../../../../../../shared/enums/type-of-labour.enum";
import { Loader } from "../../../../../../shared/loaders";
import ChildGuardianInfo from "../../../../../childGuardianInfo";
import Heading from "../../../../../heading";

interface IProps {
  patientId: string;
  isLoading: boolean;
  birthHistoryDetail: BirthHistoryDetailModel;
}
export default function BirthHistoryTabForm(props: IProps) {
  let pregnancy = props.birthHistoryDetail.birthHistoryPregnancyDetail;
  let perinatalPeriod = props.birthHistoryDetail.perinatalPeriodDetail;
  let postnatalPeriod = props.birthHistoryDetail.postnatalPeriodDetail;
  let prenatalPeriod = props.birthHistoryDetail.prenatalPeriodDetail;
  const getData = (val: any) => {
    let data = "-";
    if (val == null || val == undefined) {
      data = "-";
    } else if (val) {
      data = "Yes";
    } else if (!val) {
      data = "No";
    }
    return data;
  };
  const setTypeOfLabour = (val: any) => {
    let data = "-";
    if (val == TypeOfLabour.Induced) {
      data = "Induced";
    } else if (val == TypeOfLabour.Spontaneous) {
      data = "Spontaneous";
    }
    return data;
  };
  const setTypeOfDelivery = (val: any) => {
    let data = "-";
    if (val == TypeOfDelivery.Normal) {
      data = "Normal";
    } else if (val == TypeOfDelivery.Cesarean) {
      data = "Cesarean";
    } else if (val == TypeOfDelivery.Forceps) {
      data = "Forceps";
    } else if (val == TypeOfDelivery.AnestheticUsed) {
      data = "AnestheticUsed";
    }
    return data;
  };
  const checkIfNullOrEmpty = (val: any) => {
    let data = "-";
    if (val == null || val == undefined || val == "") {
      data = "-";
    } else {
      data = val;
    }
    return data;
  };
  const getFrequency = (val: any) => {
    let data = "";
    if (val != null && val != undefined && val != "") {
      data = " (" + val + ")";
    }
    return data;
  };
  const getDelayed = (val: any) => {
    let data = "-";
    if (val == null || val == undefined) {
      data = "-";
    } else if (val) {
      data = "Immediate";
    } else if (!val) {
      data = "Delayed";
    }
    return data;
  };
  return (
    <div className="region_table">
      {!props.isLoading && (
        <div>
          <Heading heading="Pregnancy" subHeading="" styleName="medicalGeneralDetail__style" />
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Trouble getting pregnant"
              name={getData(pregnancy?.isTroubleGettingPregnant)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Used contraception prior to pregnancy"
              name={getData(pregnancy?.isUsedContraceptionPriorToPregnancy)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Planned pregnancy"
              name={getData(pregnancy?.isPlannedPregnancy)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Used legal drugs or other medication"
              name={getData(pregnancy?.isUsedLegalDrugsOrOtherMedication)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Anemia (low iron)"
              name={getData(pregnancy?.isAnemia)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Kidney problems"
              name={getData(pregnancy?.isKidneyProblem)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="German measles"
              name={getData(pregnancy?.isGermanMeasles)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Diabetes"
              name={getData(pregnancy?.isDiabetes)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Several emotional problems"
              name={getData(pregnancy?.isSevereEmotionalProblems)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="High blood pressure"
              name={getData(pregnancy?.isHighBloodPressure)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Experienced miscarriages prior to pregnancy"
              name={getData(pregnancy?.isExperienceMiscarriagePriorToPregnancy)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="High fever (103 or higher for three days)"
              name={getData(pregnancy?.isHighFever)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Experienced miscarriages after this pregnancy"
              name={getData(pregnancy?.isExperienceMiscarriageAfterToPregnancy)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Vaginal infection/bleeding"
              name={getData(pregnancy?.isVaginalInfection)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__content_row">
            <ChildGuardianInfo
              designation="Comments"
              name={checkIfNullOrEmpty(pregnancy?.comments)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <hr />
          {/* prenatal */}
          <Heading
            heading="Prenatal Period"
            subHeading=""
            styleName="medicalGeneralDetail__style"
          />
          <div className="SMH__accord__homeContent">
            <ChildGuardianInfo
              designation="Smoked during pregnancy"
              name={getData(prenatalPeriod?.smoked) + getFrequency(prenatalPeriod?.smokedFrequency)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Consumed alcohol during pregnancy"
              name={
                getData(prenatalPeriod?.consumedAlcohol) +
                getFrequency(prenatalPeriod?.consumedAlcoholFrequency)
              }
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Used unprescribed or illegal drugs during pregnancy"
              name={
                getData(prenatalPeriod?.illegalDrugs) +
                getFrequency(prenatalPeriod?.illegalDrugsFrequency)
              }
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="learning__table">
            <ChildGuardianInfo
              designation="Took other medications"
              name={getData(prenatalPeriod?.tookOtherMedicine)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Specify type and reason for taking medicine"
              name={checkIfNullOrEmpty(prenatalPeriod?.specifyTookOtherMedicine)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="learning__table">
            <ChildGuardianInfo
              designation="Complications / medical concerns during pregnancy"
              name={getData(prenatalPeriod?.complications)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Specify"
              name={checkIfNullOrEmpty(prenatalPeriod?.specifyComplications)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="learning__table" style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}>
            <ChildGuardianInfo
              designation="Presence of additional stress during pregnancy"
              name={getData(prenatalPeriod?.stress)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Specify"
              name={checkIfNullOrEmpty(prenatalPeriod?.specifyStress)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__content_row">
            <ChildGuardianInfo
              designation="Comments"
              name={checkIfNullOrEmpty(prenatalPeriod?.comments)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <hr />
          {/* perinatal */}
          <Heading
            heading="Perinatal Period"
            subHeading=""
            styleName="medicalGeneralDetail__style"
          />
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Cord around neck"
              name={getData(perinatalPeriod?.isCordAroundNeck)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Infant needed blood at birth"
              name={getData(perinatalPeriod?.isInfantNeedBloodAtBirth)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Hemorrhage"
              name={getData(perinatalPeriod?.isHemorrhage)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Evidence of fetal distress"
              name={getData(perinatalPeriod?.isEvidenceOfFetalDistress)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Infant placed in incubator"
              name={getData(perinatalPeriod?.isInfantPlacedInIncubator)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Type of delivery"
              name={setTypeOfDelivery(perinatalPeriod?.typeOfDelivery)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="learning__table">
            <ChildGuardianInfo
              designation="Infant needed other special treatment"
              name={getData(perinatalPeriod?.isInfantNeedOtherSpecialTreatement)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Specify"
              name={checkIfNullOrEmpty(perinatalPeriod?.specifySpecialTreatement)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="learning__table">
            <ChildGuardianInfo
              designation="Type of labor"
              name={setTypeOfLabour(perinatalPeriod?.typeOfLabour)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Duration of labor"
              name={checkIfNullOrEmpty(perinatalPeriod?.durationOfLabour)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="learning__table" style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}>
            <ChildGuardianInfo
              designation="Infant injured during delivery?"
              name={getData(perinatalPeriod?.isInfactInjuredDuringDelivery)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Specify"
              name={checkIfNullOrEmpty(perinatalPeriod?.injury)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__content_row">
            <ChildGuardianInfo
              designation="Comments"
              name={checkIfNullOrEmpty(perinatalPeriod?.comments)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <hr />
          {/* postnatal */}
          <Heading
            heading="Postnatal Period"
            subHeading=""
            styleName="medicalGeneralDetail__style"
          />
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Jaundice"
              name={getData(postnatalPeriod?.jaundice)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Oxygen"
              name={getData(postnatalPeriod?.oxygen)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Cyanosis"
              name={getData(postnatalPeriod?.cyanosis)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Resuscitation"
              name={getData(postnatalPeriod?.resuscitation)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Seizures/Convulsions"
              name={getData(postnatalPeriod?.seizuresConvulsions)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Transfusions"
              name={getData(postnatalPeriod?.transfusions)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Antibiotic treatment"
              name={getData(postnatalPeriod?.antibioticTreatment)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Incubator care"
              name={getData(postnatalPeriod?.incubatorCare)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Special light for jaundice"
              name={getData(postnatalPeriod?.specialLightForJaundice)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Birth defects"
              name={getData(postnatalPeriod?.birthDefects)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Baby's weight at birth"
              name={checkIfNullOrEmpty(postnatalPeriod?.babyWeightAtBirth)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="How many days after birth were mother and infant released from the hospital?"
              name={checkIfNullOrEmpty(postnatalPeriod?.motherInfantReleasedDays)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="learning__table">
            <ChildGuardianInfo
              designation="Breathing"
              name={getDelayed(postnatalPeriod?.breathing)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Delayed how long"
              name={checkIfNullOrEmpty(postnatalPeriod?.breathingDelayed)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="learning__table" style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}>
            <ChildGuardianInfo
              designation="Cry"
              name={getDelayed(postnatalPeriod?.cry)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Delayed how long"
              name={checkIfNullOrEmpty(postnatalPeriod?.cryDelayed)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__content_row">
            <ChildGuardianInfo
              designation="Comments"
              name={checkIfNullOrEmpty(postnatalPeriod?.comments)}
              email=""
              guardian="guardianStyle"
            />
          </div>
        </div>
      )}
      <Loader loading={props.isLoading} marginBottom="0px" marginTop="8px" width="368px"></Loader>
    </div>
  );
}
