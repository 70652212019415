import React, { Fragment } from "react";
import { HttpResponse } from "../../../../core";
import { AddDevelopmentalMilestonesModel } from "../../../../models/developmentProfile/add-developmental-milestones.model";
import { DevelopmentalMilestonesPostModel } from "../../../../models/developmentProfile/developmental-milestones-post.model";
import { DevelopmentProfileService } from "../../../../services/developmentProfile/development-profile.service";
import { ShowSuccessMessage } from "../../../../shared/helpers";
import DevelopmentalMilestonesForm from "./developmentalMilestonesForm";
interface IState {
  patientId: string;
  isLoading: boolean;
  developmentalMilestonesPostModel: DevelopmentalMilestonesPostModel;
  developmentalMilestonesModel: AddDevelopmentalMilestonesModel[];
  isFormSave?: Function;
  developmentProfileStepUpdate?: Function;
}
export class DevelopmentalMilestones extends React.Component<any, IState> {
  private developmentProfileService: DevelopmentProfileService;

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.developmentProfileService = new DevelopmentProfileService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      developmentalMilestonesModel: [],
      developmentalMilestonesPostModel: {
        patientId: this.props.patientId,
        createDevelopmentalMilestonesModel: [],
      },
      patientId: this.props.patientId,
    };
    return initialState;
  }
  componentDidMount() {
    this.loadData();
    window.scroll(0, 0);
  }
  postData = (data) => {
    this.setState(
      {
        developmentalMilestonesPostModel: {
          ...this.state.developmentalMilestonesPostModel,
          patientId: this.props.patientId,
          createDevelopmentalMilestonesModel: data.developmentalMilestonesList,
        },
      },
      () => {
        if (this.state.isLoading) {
          return;
        }
          if (data.isAutoSave) {
              this.setLoading(false);
          }
          else {
              this.setLoading(true);
          }
        this.developmentProfileService
          .postDevelopmentalMilestones(this.state.developmentalMilestonesPostModel)
          .then((res: HttpResponse<boolean>) => {
            if (res && res.result) {
            }
            if (!data.isAutoSave) {
              ShowSuccessMessage("Data Saved Successfully.");
              this.props.nextTabChange();
            }
            this.setLoading(false);
            this.props.isFormSave();
            this.loadData(data.isAutoSave);
          })
          .catch((ex) => {
            this.setLoading(false);
          });
      }
    );
  };
  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }

  loadData = (isAutoSave?: any) => {
      if (isAutoSave) {
          this.setLoading(false);
      }
      else {
          this.setLoading(true);
      }
    this.developmentProfileService
      .getDevelopmentalMilestonesDetail(this.props.patientId)
      .then((res: HttpResponse<AddDevelopmentalMilestonesModel[]>) => {
        if (res && res.result) {
          this.props.developmentProfileStepUpdate(2,res.result.filter(u=>u.id!=null).length>0?true: null);
          this.setLoading(false);
          if (isAutoSave) {
            let pm = this.state.developmentalMilestonesModel;
            res.result.forEach((item, index) => {
              pm[index].id = item.id;
            });
            this.setState({
              developmentalMilestonesModel: pm,
            });
          } else {
            this.setState({
              developmentalMilestonesModel: res.result,
            });
          }
        }
      });
  };

  assignModel = (data: any) => {
    this.setState({
      developmentalMilestonesModel: data.developmentalMilestonesList,
    });
  };

  previousTabChange = () => {
    this.props.previousTabChange();
  };

  render() {
    return (
      <Fragment>
        <DevelopmentalMilestonesForm
          onSubmit={this.postData}
          isSaving={this.state.isLoading}
          patientId={this.props.patientId}
          assignModel={this.assignModel}
          developmentalMilestonesList={this.state.developmentalMilestonesModel}
          previousTabChange={this.previousTabChange}
          isBirthHistoryOptional={this.props.isBirthHistoryOptional}
        />
      </Fragment>
    );
  }
}
