import React, { Component, Fragment } from "react";
import LayoutPage from "../layout/LayoutPage";
import {
  AuthResponseModel,
  UserDetailResponseModel,
} from "../components/auth/models";
import { HttpResponse, AppConfig } from "../core";
import NavigationBar from "../layout/navigation/navigation";
import {
  Decrypt,
  Encrypt,
  getCookie,
  GetUserInfo,
  setCookie,
  SetUserInfo,
} from "../shared/helpers";
import { AuthService } from "../components/auth/services/auth.service";
import { Role } from "../shared/enums/role.enum";
import { Layout } from "antd";
import withRouter from "../shared/withRouter/withRouter";
import { Routes, Route } from "react-router-dom";
import PatientDashboard from "../components/clinician/patientDashboard";
import PatientManagement from "../components/clinician/patientManagement";
import PatientRegistration from "../components/clinician/patientRegistration/PatientRegistration";
import { DashboardPage } from "./DashboardPage";
import { LearnMorePage } from "./LearnMorePage";
import Patient from "../components/patient/patient";
import AddRespondent from "../layout/addRespondent";
import ChildWeeklyUpdate from "../layout/childWeeklyUpdate";
import MedicalSocialHistory from "../layout/medicalHistory/medicalSocialHistory";
import WelcomeScreen from "../layout/medicalHistory/welcomeScreen";
import dayjs from "dayjs";
import ParentPage from "./ParentPage";
import TeacherWeeklyUpdate from "../layout/teacherWeeklyUpdate";
import User from "../components/users";
import Medication from "../components/medication";
import RespondentWeeklyUpdate from "../layout/respondentWeeklyUpdate";

const { Content, Sider } = Layout;

interface IState {
  tokenInterval?: any | null;
  userName: string;
  roles: string[];
  loader: boolean;
  isPassExpire: boolean;
  email: string;
  initials: string;
  userId?: string | null;
  isLoginFirstTime: boolean;
  collapsed: boolean;
}

export class SecurePage extends Component<any, IState> {
  private service: AuthService;
  constructor(props) {
    super({ props });
    this.state = {
      userName: "",
      roles: [],
      loader: false,
      isPassExpire: false,
      email: "",
      initials: "",
      userId: null,
      isLoginFirstTime: false,
      collapsed: false,
    };
    this.service = new AuthService();
  }
  getUserDetail() {
    this.setState({
      loader: true,
    });
    this.service
      .getGetUserInfo()
      .then((res: HttpResponse<UserDetailResponseModel>) => {
        if (res && res.result) {
          SetUserInfo(res.result);
          this.setState({}, () => {
            var user = GetUserInfo();
            this.setState({
              userName: user.name,
              isPassExpire: user.isLastPasswordExpiring,
              email: user.email,
              initials: user.initials,
              userId: user.userId,
              isLoginFirstTime: user.isLoginFirstTime,
            });
            let roleList: string[] = [];
            user.roles?.map((x) => {
              roleList?.push(x);
            });
            this.setState({
              roles: roleList,
            });

            this.setState(
              {
                loader: false,
              }
              // () => {
              //   if (this.state.roles.includes(Role.Parent)) {
              //     // if (this.state.isLoginFirstTime) {
              //     //   window.location.href = ("/medical/welcome-screen/");
              //     // } else {
              //     //   window.location.href = ("/medical/medicalhistory/");
              //     // }
              //   } else if (this.state.roles.includes(Role.Teacher)) {
              //     let id;
              //     if (
              //       window.location.pathname.indexOf(
              //         "teacher-weekly-severity-update/"
              //       ) >= 0
              //     ) {
              //       let location = window.location.pathname.split("/");
              //       id = location[location.length - 1];
              //     }
              //     if (id != null) {
              //       window.location.href = (
              //         "/medical/teacher-weekly-severity-update/" + id
              //       );
              //     } else {
              //       window.location.href = (
              //         "/medical/teacher-weekly-severity-update/"
              //       );
              //     }
              //   }
              // }
            );
          });
        }
      })
      .catch((ex) => {
        if (ex.status == 401) {
          //ShowException(ex.response.result ? ex.response.result : ex.response);
          localStorage.clear();
          window.location.href = window.location.origin + "/login";
        }
      });
  }

  getAccessTokenByRefreshToken = () => {
    let expire_in = localStorage.getItem("expire_in") || "";
    if (expire_in) {
      let dateDiffInMillSeconds = dayjs(expire_in).diff(dayjs());
      if (dateDiffInMillSeconds < 300000) {
        //Access token is about to expire
        let authData = {
          grant_type: AppConfig.refreshTokenGrantType,
          client_id: AppConfig.clientId,
          client_secret: AppConfig.clientSecret,
          refresh_token: Decrypt<string>(getCookie(AppConfig.refreshTokenKey)),
          redirect_uri: "",
          code: null,
          audience: AppConfig.audience,
        };
        this.service
          .getAccessTokenByRefreshToken(authData)
          .then((res: HttpResponse<AuthResponseModel>) => {
            if (res && res.result) {
              let expire_in = dayjs().add(
                parseInt(res.result.expires_in),
                "seconds"
              );
              localStorage.setItem("lg", "true");
              localStorage.setItem("expire_in", expire_in.toString());
              localStorage.setItem(
                AppConfig.tokenKey,
                Encrypt(res.result.id_token)
              );
              let refresh_token = getCookie(AppConfig.refreshTokenKey);
              setCookie(
                AppConfig.refreshTokenKey,
                refresh_token,
                AppConfig.refreshTokenExpiry
              );
            }
          });
      }
    }
  };

  componentDidMount() {
    this.setState({
      tokenInterval: null,
    });
    this.getUserDetail();

    let tokenInterval = setInterval(this.getAccessTokenByRefreshToken, 60000);
    this.setState({
      tokenInterval: tokenInterval,
    });
    this.getAccessTokenByRefreshToken();
  }

  componentWillUnmount() {
    // clear interval
    if (this.state.tokenInterval) {
      clearInterval(this.state.tokenInterval);
    }
  }
  handleUserDetail = (firstName: string, lastName: string) => {
    this.setState({
      userName: firstName + " " + lastName,
      initials:
        firstName.substring(0, 1).toUpperCase() +
        lastName.substring(0, 1).toUpperCase(),
    });
  };
  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };
  onSiderClick = () => {
    if (!this.state.collapsed) {
      this.onCollapse(true);
    }
  };

  render() {
    const {
      userName,
      roles,
      loader,
      isPassExpire,
      email,
      initials,
      userId,
      isLoginFirstTime,
      collapsed,
    } = this.state;
    return (
      <div className="main-wrapper">
        {!loader && (
          <div className="main-content">
            {roles?.includes(Role.Parent) ? (
              <div>
                <Layout style={{ minHeight: "100vh" }}>
                  <Sider
                    className="d-md-none"
                    breakpoint="md"
                    collapsedWidth="0"
                    onCollapse={this.onCollapse}
                    onClick={this.onSiderClick}
                    collapsible
                    collapsed={this.state.collapsed}
                  >
                    {roles?.includes(Role.Parent) && <NavigationBar />}
                  </Sider>
                  <Sider className="d-none d-md-block">
                    {roles?.includes(Role.Parent) && <NavigationBar />}
                  </Sider>
                  <Layout className="site-layout">
                    <Content>
                      <LayoutPage
                        userName={userName}
                        designation={roles != null ? roles[0] : ""}
                        isPassExpire={isPassExpire}
                        email={email}
                        initials={initials}
                        handleUserDetail={this.handleUserDetail}
                        id={this.state.userId}
                      />
                      <Routes>
                        <Route path="/" element={<ParentPage />} />
                        <Route
                          path="/medical/medicalhistory/:id?"
                          element={<MedicalSocialHistory />}
                        />
                        <Route
                          path="/medical/childWeeklySeverityUpdate/:id"
                          element={<ChildWeeklyUpdate />}
                        />
                        <Route
                          path="/medical/addRespondent/"
                          element={<AddRespondent />}
                        />
                        <Route
                          path="/medical/welcome-screen"
                          element={<WelcomeScreen />}
                        />
                      </Routes>
                    </Content>
                  </Layout>
                </Layout>
              </div>
            ) : roles?.includes(Role.Teacher) ? (
              <div>
                <LayoutPage
                  userName={userName}
                  designation={roles != null ? roles[0] : ""}
                  isPassExpire={isPassExpire}
                  email={email}
                  initials={initials}
                  id={this.state.userId}
                  handleUserDetail={this.handleUserDetail}
                />
                <Routes>
                  <Route path="/" element={<ParentPage />} />
                  <Route
                    path="/medical/teacher-weekly-severity-update/:id?"
                    element={
                      <TeacherWeeklyUpdate path="/medical/teacher-weekly-severity-update/:id?" />
                    }
                  />
                </Routes>
              </div>
            ) : roles?.includes(Role.Respondent) ? (
              <div>
                <LayoutPage
                  userName={userName}
                  designation={roles != null ? roles[0] : ""}
                  isPassExpire={isPassExpire}
                  email={email}
                  initials={initials}
                  id={this.state.userId}
                  handleUserDetail={this.handleUserDetail}
                />
                <Routes>
                  <Route path="/" element={<ParentPage />} />
                  <Route
                    path="/medical/respondent-weekly-severity-update/:id?"
                    element={<RespondentWeeklyUpdate />}
                  />
                </Routes>
              </div>
            ) : roles.includes(Role.Clinician) ||
              roles?.includes(Role.SuperAdmin) ||
              roles?.includes(Role.Admin) ? (
              <div>
                <Layout style={{ minHeight: "100vh" }}>
                  <Sider
                    breakpoint="lg"
                    collapsedWidth="0"
                    onBreakpoint={(broken) => {
                      //console.log(broken);
                    }}
                    onCollapse={this.onCollapse}
                  >
                    {(roles?.includes(Role.Clinician) ||
                      roles?.includes(Role.SuperAdmin) ||
                      roles?.includes(Role.Admin)) && <NavigationBar />}
                  </Sider>
                  <Layout className="site-layout">
                    <Content>
                      <LayoutPage
                        userName={userName}
                        designation={roles != null ? roles.join(", ") : ""}
                        isPassExpire={isPassExpire}
                        email={email}
                        initials={initials}
                        id={this.state.userId}
                        handleUserDetail={this.handleUserDetail}
                      />
                      <Routes>
                        <Route path="/" element={<DashboardPage />} />
                        <Route
                          path="/clinician/patientmanagement"
                          element={<PatientManagement />}
                        />
                        {/* <Route path="/clinician/patientdashboard" exact component={PatientDashboard} /> */}
                        <Route
                          path="/clinician/patientdashboard/:id?/:tab?"
                          element={<PatientDashboard />}
                        />
                        <Route
                          path="/clinician/patientregistration"
                          element={<PatientRegistration />}
                        />
                        <Route path="/learnmore" element={<LearnMorePage />} />
                        <Route
                          path="/patient/addPatient/:id?/:tab?"
                          element={
                            <Patient path="/patient/addPatient/:id?/:tab?" />
                          }
                        />
                        <Route
                          path="/user/adduser/:id?/:tab?"
                          element={<User />}
                        />
                        <Route
                          path="/medication/setting"
                          element={<Medication />}
                        />
                      </Routes>
                    </Content>
                  </Layout>
                </Layout>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(SecurePage);
