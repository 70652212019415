import React, { useEffect, useState } from "react";
import Heading from "../heading";
import { DatePicker, Button, Modal, AutoComplete, Tooltip } from "antd";
import "../../layout/medicalHistory/styles.scss";
import "./styles.css";
import { PatientGeneralInfoModel } from "../../models/patient/patient-general-info.model";
import moment from "moment";
import { Gender } from "../../shared/enums/gender.enum";
import { Options } from "../../shared/enums/option.enum";
import { Controller, useForm } from "react-hook-form";
import { HttpResponse } from "../../core";
import { PatientService } from "../patient/services";
import { PatientDetailModel } from "../patient/models/patient-detail.model";
import { ShowSuccessMessage } from "../../shared/helpers";
import { Loader } from "../../shared/loaders";
import { PatientMedicalGeneralInfoService } from "../../services/patientMedicalGeneralInfo/patient-medical-general-info.service";
import { CurrentPlacementModel } from "../../models/patient/current-placement.model";
import { SpecialEducationModel } from "../../models/patient/special-education.model";
import { ChildsEthnicityModel } from "../../models/patient/childs-ethnicity.model";
import NumberFormat, { PatternFormat } from "react-number-format";
import { AddParentModel } from "../patient/models/add-parent.model";
import { AddressModel } from "../../shared/models/address.model";
import { ExistingTeacherListViewModel } from "../../models/teacher/existing-teacher-list.model";
import { AddTeacherModel } from "../../models/teacher/add-teacher.model";
import editImg from "../../assets/images/svg-icons/edit.svg";
import { PlusOutlined } from "@ant-design/icons";
import { CheckEmailExistsModel } from "../../models/patient/check-email.model";
import dayjs from "dayjs";
interface IProps {
  id: string;
  isFormSave: Function;
  nextTabChange: Function;
}

export default function GeneralForm(props: IProps) {
  let id = "";
  const {
    register,
    setError,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    formState,
    setValue,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
  });
  const motherHomePhoneNo = register("motherHomePhoneNo", {
    required: "Please fill contact no",
    minLength: {
      value: 10,
      message: "Phone number must be 10 digits",
    },
  });
  const motherWorkPhoneNo = register("motherWorkPhoneNo", {
    minLength: {
      value: 10,
      message: "Phone number must be 10 digits",
    },
  });
  const fatherHomePhoneNo = register("fatherHomePhoneNo", {
    required: "Please fill contact no",
    minLength: {
      value: 10,
      message: "Phone number must be 10 digits",
    },
  });
  const fatherWorkPhoneNo = register("fatherWorkPhoneNo", {
    minLength: {
      value: 10,
      message: "Phone number must be 10 digits",
    },
  });
  const schoolContactNo = register("schoolContactNo", {
    minLength: {
      value: 10,
      message: "Phone number must be 10 digits",
    },
  });
  const pPhoneNo = register("pPhoneNo", {
    minLength: {
      value: 10,
      message: "Phone number must be 10 digits",
    },
  });
  const { Option } = AutoComplete;
  useEffect(() => {
    if (id != props.id) {
      id = props.id;
      loadData();
      setShowTeacherDetail(false);
    }
  }, [props.id]);

  let initialModel = {
    patientInfoModel: {
      // addressModel: {},
      parentModel: {
        motherAddressModel: {},
        fatherAddressModel: {},
      },
      physicianModel: {},
      specialEducationModel: {},
      currentPlacementModel: {},
      childsEthnicityModel: {},
    },
  } as PatientGeneralInfoModel;
  let checkEmailExistsInitialModel = {} as CheckEmailExistsModel;
  let initialTeacherInfoModel = {} as AddTeacherModel;
  const [patientInfo, setPatintInfo] = useState(initialModel);
  const [teacherDefaultvalue, setTeacherValue] = useState("");
  const [adopted, setAdopted] = useState(patientInfo.patientInfoModel.adopted);
  const [birthParentInfo, setBirthParentInfo] = useState(patientInfo.patientInfoModel.birthParentInfo);
  const [adoptedAge, setAdoptedAge] = useState(
    patientInfo.patientInfoModel.adoptedAge
  );
  const [isSaving, setIsSaving] = useState(false);
  const [showTeacherDetail, setShowTeacherDetail] = useState(false);
  const [teacherInfo, setTeacherInfo] = useState(initialTeacherInfoModel);
  const [checkEmailExists, setCheckEmailExists] = useState(
    checkEmailExistsInitialModel
  );
  const [tEmailIdError, setTEmailIdError] = useState("");
  const [disableInviteButton, setDisableInviteButton] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  //  const [res, setIsSaving] = useState(false);
  const handleInviteClick = () => {
    let model = getValues();
    teacherInfo.isInviteSend = true;
    teacherInfo.tFirstName = model.tFirstName;
    teacherInfo.tLastName = model.tLastName;
    teacherInfo.tEmailId = model.tEmailId;
    teacherInfo.schoolContactNo = model.schoolContactNo;
    teacherInfo.schoolDistrict = model.schoolDistrict;
    teacherInfo.schoolName = model.schoolName;
    teacherInfo.guardianFirstName =
      patientInfo.patientInfoModel.guardianFirstName;
    teacherInfo.guardianLastName =
      patientInfo.patientInfoModel.guardianLastName;
    teacherInfo.firstName = model.firstName;
    teacherInfo.lastName = model.lastName;
    setTeacherInfo(teacherInfo);
    if (checkForError(teacherInfo.isInviteSend)) {
      return false;
    }
    if (
      teacherInfo.tFirstName &&
      teacherInfo.tLastName &&
      teacherInfo.tEmailId &&
      (tEmailIdError == null || tEmailIdError == "")
    ) {
      postTeacherData(teacherInfo);
    }
  };

  const [gender, setGender] = useState(patientInfo.patientInfoModel?.gender);
  const dateChange = (date) => {
    patientInfo.patientInfoModel.dateOfBirth = dayjs(date).format("MM/DD/YYYY");
    setValue(
      "dob",
      dayjs(
        patientInfo.patientInfoModel.dateOfBirth
          ? new Date(patientInfo.patientInfoModel.dateOfBirth)
          : new Date()
      )
    );
  };
  const genderChange = (data) => {
    patientInfo.patientInfoModel.gender = parseInt(data.target.value);
    setGender(patientInfo.patientInfoModel.gender);
  };
  const adoptedChange = (data) => {
    patientInfo.patientInfoModel.adopted = parseInt(data.target.value);
    setAdopted(patientInfo.patientInfoModel.adopted);
    if (patientInfo.patientInfoModel.adopted == 2) {
      patientInfo.patientInfoModel.adoptedAgeError = "";
      patientInfo.patientInfoModel.adoptedAge = "";
      setAdoptedAge(patientInfo.patientInfoModel.adoptedAge);
    }
  };
  const birthParentInfoChange = (data) => {
    patientInfo.patientInfoModel.birthParentInfo = parseInt(data.target.value);
    setBirthParentInfo(patientInfo.patientInfoModel.birthParentInfo);
  };
  const adoptedAgeChange = (data) => {
    patientInfo.patientInfoModel.adoptedAge = data.target.value;
    setAdoptedAge(patientInfo.patientInfoModel.adoptedAge);
  };
  const setFormValues = (data: PatientDetailModel) => {
    setValue("firstName", data.firstName);
    setValue("lastName", data.lastName);
    setValue("grade", data.grade);
    setValue("birthPlace", data.birthPlace);
    setValue("personCompleteForm", data.personCompletingForm);
    setValue(
      "relationshipWithPersonCompletingForm",
      data.relationshipWithPersonCompletingForm
    );
    setValue("motherName", data.parentModel.motherName);
    setValue("motherHomePhoneNo", data.parentModel.motherHomePhoneNo);
    setValue("fatherName", data.parentModel.fatherName);
    setValue("fatherHomePhoneNo", data.parentModel.fatherHomePhoneNo);
    setValue("fatherWorkPhoneNo", data.parentModel.fatherWorkPhoneNo);
    setValue("pFirstName", data.monitoringPhysicianFirstName);
    setValue("pLastName", data.monitoringPhysicianLastName);
    setValue("pEmailId", data.monitoringPhysicianEmailId);
    setValue("pPhoneNo", data.monitoringPhysicianPhoneNo);
  };

  const setDataInModel = (data) => {
    console.log("BirthParentInfo: ", birthParentInfo)
    console.log("Patient.BirthParentInfo: ", patientInfo.patientInfoModel.birthParentInfo)
    patientInfo.patientInfoModel.birthPlace = data.birthPlace;
    patientInfo.patientInfoModel.firstName = data.firstName;
    patientInfo.patientInfoModel.lastName = data.lastName;
    patientInfo.patientInfoModel.grade = data.grade;
    patientInfo.patientInfoModel.adoptedAge = data.adoptedAge;
    //  patientInfo.patientInfoModel.contactNo = data.contactNo.replace(/\D/g, "");
    //  patientInfo.patientInfoModel.workPhoneNo = data.workPhoneNo.replace(

    patientInfo.patientInfoModel.personCompletingForm = data.personCompleteForm;
    patientInfo.patientInfoModel.relationshipWithPersonCompletingForm =
      data.relationshipWithPersonCompletingForm;
    // patientInfo.patientInfoModel.addressModel.state = data.state;
    // patientInfo.patientInfoModel.addressModel.city = data.city;
    // patientInfo.patientInfoModel.addressModel.zipcode = data.zipcode;
    // patientInfo.patientInfoModel.addressModel.address1 = data.address1;
    // patientInfo.patientInfoModel.addressModel.address2 = data.address2;
    //  patientInfo.tFirstName = data.tFirstName!=null?data.tFirstName:"";
    //  patientInfo.tLastName = data.tLastName !=null?data.tLastName:"";
    //patientInfo.schoolContactNo =
    //  data.schoolContactNo != null || data.schoolContactNo != undefined
    //    ? data.schoolContactNo.replace(/\D/g, "")
    //    : "";
    //patientInfo.schoolDistrict = data.schoolDistrict;
    //patientInfo.schoolName = data.schoolName;
    //patientInfo.tEmailId = data.tEmailId;
    patientInfo.patientInfoModel.physicianModel.pFirstName = data.pFirstName;
    patientInfo.patientInfoModel.physicianModel.pLastName = data.pLastName;
    patientInfo.patientInfoModel.physicianModel.pEmailId = data.pEmailId;
    patientInfo.patientInfoModel.physicianModel.pPhoneNo =
      data.pPhoneNo?.replace(/\D/g, "");
    patientInfo.patientInfoModel.currentPlacementModel.currentPlacementDescription =
      data.currentPlacementDescription;
    patientInfo.patientInfoModel.specialEducationModel.specialEducationDescription =
      data.specialEducationDescription;
    patientInfo.patientInfoModel.childsEthnicityModel.childsEthnicityDescription =
      data.childsEthnicityDescription;
    patientInfo.patientInfoModel.parentModel.motherName = data.motherName;
    patientInfo.patientInfoModel.parentModel.fatherName = data.fatherName;
    patientInfo.patientInfoModel.parentModel.fatherHomePhoneNo =
      data.fatherHomePhoneNo?.replace(/\D/g, "");
    patientInfo.patientInfoModel.parentModel.fatherWorkPhoneNo =
      data.fatherWorkPhoneNo?.replace(/\D/g, "");
    patientInfo.patientInfoModel.parentModel.motherHomePhoneNo =
      data.motherHomePhoneNo?.replace(/\D/g, "");
    patientInfo.patientInfoModel.parentModel.motherWorkPhoneNo =
      data.motherWorkPhoneNo?.replace(/\D/g, "");
    patientInfo.patientInfoModel.parentModel.fatherAddressModel.state =
      data.fState;
    patientInfo.patientInfoModel.parentModel.fatherAddressModel.city =
      data.fCity;
    patientInfo.patientInfoModel.parentModel.fatherAddressModel.zipcode =
      data.fZipcode;
    patientInfo.patientInfoModel.parentModel.fatherAddressModel.address1 =
      data.fAddress1;
    patientInfo.patientInfoModel.parentModel.fatherAddressModel.address2 =
      data.fAddress2;
    patientInfo.patientInfoModel.parentModel.motherAddressModel.state =
      data.mState;
    patientInfo.patientInfoModel.parentModel.motherAddressModel.city =
      data.mCity;
    patientInfo.patientInfoModel.parentModel.motherAddressModel.zipcode =
      data.mZipcode;
    patientInfo.patientInfoModel.parentModel.motherAddressModel.address1 =
      data.mAddress1;
    patientInfo.patientInfoModel.parentModel.motherAddressModel.address2 =
      data.mAddress2;
    setPatintInfo(patientInfo);
  };

  const formSubmit = (data) => {
    setDataInModel(data);
    console.log(errors);
    saveData({ model: patientInfo, isAutoSave: false });
  };
  const loadData = (isAutoSave?: any) => {
    if (isAutoSave) {
      setIsSaving(false);
    } else {
      setIsSaving(true);
    }

    new PatientService()
      .getUserById(props.id)
      .then((res: HttpResponse<PatientDetailModel>) => {
        if (res && res.result) {
          let currentPlacementModel = {} as CurrentPlacementModel;
          let specialEducationModel = {} as SpecialEducationModel;
          let childsEthnicityModel = {} as ChildsEthnicityModel;
          let fatherAddressModel = {} as AddressModel;
          let motherAddressModel = {} as AddressModel;
          if (res.result.parentModel.fatherAddressModel != null) {
            fatherAddressModel.address1 =
              res.result.parentModel.fatherAddressModel.address1;
            setValue(
              "fAddress1",
              res.result.parentModel.fatherAddressModel.address1
            );
            fatherAddressModel.address2 =
              res.result.parentModel.fatherAddressModel.address2;
            setValue(
              "fAddress2",
              res.result.parentModel.fatherAddressModel.address2
            );
            fatherAddressModel.city =
              res.result.parentModel.fatherAddressModel.city;
            setValue("fCity", res.result.parentModel.fatherAddressModel.city);
            fatherAddressModel.state =
              res.result.parentModel.fatherAddressModel.state;
            setValue("fState", res.result.parentModel.fatherAddressModel.state);
            fatherAddressModel.zipcode =
              res.result.parentModel.fatherAddressModel.zipcode;
            setValue(
              "fZipcode",
              res.result.parentModel.fatherAddressModel.zipcode
            );
            fatherAddressModel.addressId =
              res.result.parentModel.fatherAddressModel.addressId;
          }
          if (res.result.parentModel.motherAddressModel != null) {
            motherAddressModel.address1 =
              res.result.parentModel.motherAddressModel.address1;
            setValue(
              "mAddress1",
              res.result.parentModel.motherAddressModel.address1
            );
            motherAddressModel.address2 =
              res.result.parentModel.motherAddressModel.address2;
            setValue(
              "mAddress2",
              res.result.parentModel.motherAddressModel.address2
            );
            motherAddressModel.city =
              res.result.parentModel.motherAddressModel.city;
            setValue("mCity", res.result.parentModel.motherAddressModel.city);
            motherAddressModel.state =
              res.result.parentModel.motherAddressModel.state;
            setValue("mState", res.result.parentModel.motherAddressModel.state);
            motherAddressModel.zipcode =
              res.result.parentModel.motherAddressModel.zipcode;
            setValue(
              "mZipcode",
              res.result.parentModel.motherAddressModel.zipcode
            );
            motherAddressModel.addressId =
              res.result.parentModel.motherAddressModel.addressId;
          }
          if (res.result.currentPlacementModel != null) {
            currentPlacementModel.regularEducation =
              res.result.currentPlacementModel.regularEducation;
            currentPlacementModel.resourceRoom =
              res.result.currentPlacementModel.resourceRoom;
            currentPlacementModel.specialEducation =
              res.result.currentPlacementModel.specialEducation;
            currentPlacementModel.currentPlacementDescription =
              res.result.currentPlacementModel.currentPlacementDescription;
          }
          if (res.result.specialEducationModel != null) {
            specialEducationModel.learningDisablity =
              res.result.specialEducationModel.learningDisablity;
            specialEducationModel.behaviorDisorder =
              res.result.specialEducationModel.behaviorDisorder;
            specialEducationModel.developmentalDelay =
              res.result.specialEducationModel.developmentalDelay;
            specialEducationModel.specialEducationDescription =
              res.result.specialEducationModel.specialEducationDescription;
          }
          if (res.result.childsEthnicityModel != null) {
            childsEthnicityModel.africanAmerican =
              res.result.childsEthnicityModel.africanAmerican;
            childsEthnicityModel.hispanic =
              res.result.childsEthnicityModel.hispanic;
            childsEthnicityModel.americianIndian =
              res.result.childsEthnicityModel.americianIndian;
            childsEthnicityModel.asian = res.result.childsEthnicityModel.asian;
            childsEthnicityModel.fillipino =
              res.result.childsEthnicityModel.fillipino;
            childsEthnicityModel.indianSubcontinent =
              res.result.childsEthnicityModel.indianSubcontinent;
            childsEthnicityModel.seAsian =
              res.result.childsEthnicityModel.seAsian;
            childsEthnicityModel.hawaliain =
              res.result.childsEthnicityModel.hawaliain;
            childsEthnicityModel.caucasian =
              res.result.childsEthnicityModel.caucasian;
            childsEthnicityModel.otherPacificIslander =
              res.result.childsEthnicityModel.otherPacificIslander;
            childsEthnicityModel.childsEthnicityDescription =
              res.result.childsEthnicityModel.childsEthnicityDescription;
          }

          let patient: PatientGeneralInfoModel = {
            teacherId: res.result.teacherId,
            tFirstName:
              res.result.teacherFirstName != null
                ? res.result.teacherFirstName
                : "",
            tLastName: res.result.teacherLastName
              ? res.result.teacherLastName
              : "",
            tEmailId: res.result.teacherEmailId,
            schoolDistrict: res.result.schoolDistrict,
            schoolContactNo: res.result.schoolContactNo,
            schoolName: res.result.schoolName,
            isInviteSend: false,
            isTeacherAccountActivate: res.result.isTeacherAccountActivate,
            teacherIdentityId: res.result.teacherIdentityId,
            isPatientTeacher: res.result.isPatientTeacher,
            patientInfoModel: {
              id: res.result.id,
              guardianId: res.result.guardianId,
              mr_No: res.result.mr_No,
              firstName: res.result.firstName,
              lastName: res.result.lastName,
              dateOfBirth: res.result.dateOfBirth,
              gender: res.result.gender,
              relationshipId: res.result.relationshipId,
              guardianFirstName: res.result.guardianFirstName,
              guardianLastName: res.result.guardianLastName,
              contactNo: res.result.contactNo || "",
              emailId: res.result.emailId,
              adopted: res.result.adopted
                ? res.result.adopted
                : (patientInfo.patientInfoModel.adopted = Options.No),
              adoptedAge: res.result.adoptedAge,
              birthParentInfo: res.result.birthParentInfo
              ? res.result.birthParentInfo
              : (patientInfo.patientInfoModel.birthParentInfo = Options.yes),
              grade: res.result.grade,
              birthPlace: res.result.birthPlace,
              personCompletingForm: res.result.personCompletingForm,
              relationshipWithPersonCompletingForm:
                res.result.relationshipWithPersonCompletingForm,
              workPhoneNo: res.result.workPhoneNo,
              // addressModel: {
              //   address1: res.result.addressModel.address1,
              //   address2: res.result.addressModel.address2,
              //   city: res.result.addressModel.city,
              //   state: res.result.addressModel.state,
              //   zipcode: res.result.addressModel.zipcode,
              //   addressId: res.result.addressModel.addressId,
              // },
              physicianModel: {
                id: res.result.monitoringPhysicianId,
                pLastName: res.result.monitoringPhysicianLastName,
                pFirstName: res.result.monitoringPhysicianFirstName,
                pEmailId: res.result.monitoringPhysicianEmailId,
                pPhoneNo: res.result.monitoringPhysicianPhoneNo,
                patientId: res.result.id,
              },
              parentModel: {
                id: res.result.parentModel.id,
                motherName: res.result.parentModel.motherName,
                fatherName: res.result.parentModel.fatherName,
                motherWorkPhoneNo: res.result.parentModel.motherWorkPhoneNo,
                motherHomePhoneNo: res.result.parentModel.motherHomePhoneNo,
                fatherWorkPhoneNo: res.result.parentModel.fatherWorkPhoneNo,
                fatherHomePhoneNo: res.result.parentModel.fatherHomePhoneNo,
                patientId: res.result.id,
                fatherAddressModel:
                  fatherAddressModel != null
                    ? fatherAddressModel
                    : ({} as AddressModel),
                motherAddressModel:
                  motherAddressModel != null
                    ? motherAddressModel
                    : ({} as AddressModel),
              },
              currentPlacementModel:
                currentPlacementModel != null
                  ? currentPlacementModel
                  : ({} as CurrentPlacementModel),

              specialEducationModel:
                specialEducationModel != null
                  ? specialEducationModel
                  : ({} as SpecialEducationModel),
              childsEthnicityModel:
                childsEthnicityModel != null
                  ? childsEthnicityModel
                  : ({} as ChildsEthnicityModel),
            },
          };

          setPatintInfo(patient);
          patientInfo.tFirstName = res.result.teacherFirstName;
          patientInfo.teacherId = res.result.teacherId;
          setTeacherValue(res.result.teacherFirstName);
          setFormValues(res.result);
          setValue(
            "dob",
            dayjs(
              patient.patientInfoModel.dateOfBirth
                ? new Date(patient.patientInfoModel.dateOfBirth)
                : new Date()
            )
          );
          setIsSaving(false);
          //loadExistTeacherList();
        }
      })
      .catch((ex) => {
        setIsSaving(false);
      });
  };
  const saveData = (data: any) => {
    console.log("saveData: ", data)
    let patient = data.model;
    if (isSaving) {
      return;
    }
    if (data.isAutoSave) {
      setIsSaving(false);
    } else {
      setIsSaving(true);
    }
    new PatientMedicalGeneralInfoService()
      .postUser(patient)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
          let patientObj = patientInfo;
          patientObj.patientInfoModel.guardianId = res.result.guardianId;
          patientObj.patientInfoModel.id = res.result.patientId;
          patientObj.teacherId = res.result.teacherId;
          patientObj.patientInfoModel.physicianModel.id =
            res.result.physicianId;
          patientObj.patientInfoModel.parentModel.id = res.result.parentId;
          patientObj.patientInfoModel.parentModel.fatherAddressModel.addressId =
            res.result.fatherAddressId;
          patientObj.patientInfoModel.parentModel.motherAddressModel.addressId =
            res.result.motherAddressId;
          setPatintInfo(patientObj);

          if (patient.isInviteSend) {
            ShowSuccessMessage("Invite Sent Successfully.");
            props.isFormSave();
            patient.isInviteSend = false; //for not repeating same message
          } else {
            if (!data.isAutoSave) {
              ShowSuccessMessage("Data Saved Successfully.");
              props.nextTabChange();
            }
          }

          props.isFormSave();

          setIsSaving(false);
          if (data.isTeacherSave) {
            loadData(data.isAutoSave);
          }
        }
      })

      .catch((ex) => {
        setIsSaving(false);
      });
  };
  const handleKeyUp = (event) => {
    setValuesInModel();
    saveDataOnTabChange();
  };

  const saveDataOnTabChange = () => {
    if (!isSaving) {
      saveData({ model: patientInfo, isAutoSave: true });
    }
  };

  function setValuesInModel() {
    let model = getValues();
    console.log(model);
    setDataInModel(model);
  }

  const isCurrentPlacementSelected = (type: number, event) => {
    switch (type) {
      case 1: {
        patientInfo.patientInfoModel.currentPlacementModel.regularEducation =
          event.target.checked;
        break;
      }
      case 2: {
        patientInfo.patientInfoModel.currentPlacementModel.resourceRoom =
          event.target.checked;
        break;
      }
      case 3: {
        patientInfo.patientInfoModel.currentPlacementModel.specialEducation =
          event.target.checked;
        break;
      }

      default: {
        break;
      }
    }
  };
  const isSpecialEducationSelected = (type: number, event) => {
    switch (type) {
      case 1: {
        patientInfo.patientInfoModel.specialEducationModel.learningDisablity =
          event.target.checked;
        break;
      }
      case 2: {
        patientInfo.patientInfoModel.specialEducationModel.behaviorDisorder =
          event.target.checked;
        break;
      }
      case 3: {
        patientInfo.patientInfoModel.specialEducationModel.developmentalDelay =
          event.target.checked;
        break;
      }

      default: {
        break;
      }
    }
  };

  const isChildsEthnicitySelected = (type: number, event) => {
    switch (type) {
      case 1: {
        patientInfo.patientInfoModel.childsEthnicityModel.africanAmerican =
          event.target.checked;
        break;
      }
      case 2: {
        patientInfo.patientInfoModel.childsEthnicityModel.hispanic =
          event.target.checked;
        break;
      }
      case 3: {
        patientInfo.patientInfoModel.childsEthnicityModel.americianIndian =
          event.target.checked;
        break;
      }
      case 4: {
        patientInfo.patientInfoModel.childsEthnicityModel.asian =
          event.target.checked;
        break;
      }
      case 5: {
        patientInfo.patientInfoModel.childsEthnicityModel.fillipino =
          event.target.checked;
        break;
      }

      case 6: {
        patientInfo.patientInfoModel.childsEthnicityModel.indianSubcontinent =
          event.target.checked;
        break;
      }
      case 7: {
        patientInfo.patientInfoModel.childsEthnicityModel.seAsian =
          event.target.checked;
        break;
      }

      case 8: {
        patientInfo.patientInfoModel.childsEthnicityModel.hawaliain =
          event.target.checked;
        break;
      }
      case 9: {
        patientInfo.patientInfoModel.childsEthnicityModel.caucasian =
          event.target.checked;
        break;
      }
      case 10: {
        patientInfo.patientInfoModel.childsEthnicityModel.otherPacificIslander =
          event.target.checked;
        break;
      }
      default: {
        break;
      }
    }
  };
  const isFHomePhnDigit = (event) => {
    if (event.replace(/\D/g, "").length < 10) {
      return false;
    }
  };
  const isFWorkPhnDigit = (event) => {
    if (
      event.replace(/\D/g, "").length > 0 &&
      event.replace(/\D/g, "").length < 10
    ) {
      return false;
    }
  };
  const isMHomePhnDigit = (event) => {
    if (event.replace(/\D/g, "").length < 10) {
      return false;
    }
  };
  const isMWorkPhnDigit = (event) => {
    if (
      event.replace(/\D/g, "").length > 0 &&
      event.replace(/\D/g, "").length < 10
    ) {
      return false;
    }
  };
  const isTeacherPhnDigit = (event) => {
    if (event.replace(/\D/g, "").length < 10) {
      return false;
    }
    return true;
  };
  const isPhysicianPhnDigit = (event) => {
    if (event.replace(/\D/g, "").length < 10) {
      return false;
    }
  };
  const postTeacherData = (data: any) => {
    new PatientService()
      .postTeacher(data)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
          setShowTeacherDetail(false);
          setIsSaving(false);
          if (data.isInviteSend) {
            cancelAddTeacher();
            ShowSuccessMessage("Invite Sent Successfully.");
            props.isFormSave();
            data.isInviteSend = false; //for not repeating same message
            setTeacherValue(data.tFirstName);
            teacherInfo.teacherId = res.result;
            patientInfo.teacherId = res.result;
          } else {
            cancelAddTeacher();
            if (patientInfo.teacherId != null && patientInfo.teacherId != "") {
              ShowSuccessMessage("Data Updated Successfully.");
              teacherInfo.teacherId = res.result;
              patientInfo.teacherId = res.result;
            } else {
              ShowSuccessMessage("Data Saved Successfully.");
              teacherInfo.teacherId = res.result;
              patientInfo.teacherId = res.result;
            }
            props.isFormSave();
            setTeacherValue(data.tFirstName);
          }
          saveData({
            model: patientInfo,
            isTeacherSave: true,
            isAutoSave: true,
          });
        }
      })

      .catch((ex) => {
        setIsSaving(false);
      });
  };
  const editTeacher = () => {
    teacherInfo.teacherId = patientInfo.teacherId;
    teacherInfo.tFirstName = patientInfo.tFirstName;
    teacherInfo.tLastName = patientInfo.tLastName;
    teacherInfo.tEmailId = patientInfo.tEmailId;
    teacherInfo.schoolContactNo = patientInfo.schoolContactNo;
    teacherInfo.schoolDistrict = patientInfo.schoolDistrict;
    teacherInfo.schoolName = patientInfo.schoolName;
    teacherInfo.identityId = patientInfo.teacherIdentityId;
    teacherInfo.tFirstNameError = "";
    teacherInfo.tLastNameError = "";
    teacherInfo.tEmailIdError = "";
    teacherInfo.schoolContactNoError = "";
    teacherInfo.schoolDistrictError = "";
    teacherInfo.schoolNameError = "";
    setTEmailIdError("");
    setTeacherInfo(teacherInfo);
    setShowTeacherDetail(true);
  };
  const addTeacher = () => {
    setShowTeacherDetail(true);
    teacherInfo.teacherId = "";
    teacherInfo.tFirstName = "";
    teacherInfo.tLastName = "";
    teacherInfo.tEmailId = "";
    teacherInfo.schoolContactNo = "";
    teacherInfo.schoolDistrict = "";
    teacherInfo.schoolName = "";
    teacherInfo.identityId = "";
    teacherInfo.tFirstNameError = "";
    teacherInfo.tLastNameError = "";
    teacherInfo.tEmailIdError = "";
    teacherInfo.schoolContactNoError = "";
    teacherInfo.schoolDistrictError = "";
    teacherInfo.schoolNameError = "";
    setTEmailIdError("");
    setTeacherInfo(teacherInfo);
  };

  const checkForError = (isInviteSend?: boolean) => {
    let hasError = false;
    teacherInfo.tFirstNameError = "";
    teacherInfo.tLastNameError = "";
    teacherInfo.tEmailIdError = "";
    teacherInfo.schoolNameError = "";
    teacherInfo.schoolDistrictError = "";
    teacherInfo.schoolContactNoError = "";

    if (teacherInfo.tFirstName == "" || teacherInfo.tFirstName == null) {
      teacherInfo.tFirstNameError = "Please fill  first name.";
      hasError = true;
    }
    if (
      (teacherInfo.tFirstName != null || teacherInfo.tFirstName != undefined) &&
      teacherInfo.tFirstName.length > 128
    ) {
      teacherInfo.tFirstNameError = "First name too long.";
      hasError = true;
    }
    if (teacherInfo.tLastName == "" || teacherInfo.tLastName == null) {
      teacherInfo.tLastNameError = "Please fill  last name.";
      hasError = true;
    }
    if (
      (teacherInfo.tLastName != null || teacherInfo.tLastName != undefined) &&
      teacherInfo.tLastName!.length > 128
    ) {
      teacherInfo.tFirstNameError = "Last name too long.";
      hasError = true;
    }
    if (teacherInfo.tEmailId == "" || teacherInfo.tEmailId == null) {
      teacherInfo.tEmailIdError = "Please fill  email.";
      setTEmailIdError("Please fill  email.");
      hasError = true;
    }
    let emailval = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ).test(teacherInfo.tEmailId);
    if (teacherInfo.tEmailId !== "" && !emailval) {
      teacherInfo.tEmailIdError = "Email is not valid.";
      setTEmailIdError("Email is not valid.");
      hasError = true;
    }
    checkEmailExists.emailId = teacherInfo.tEmailId;
    checkEmailExists.id = teacherInfo.teacherId;
    setCheckEmailExists(checkEmailExists);
    if (
      teacherInfo.tEmailId != null &&
      teacherInfo.tEmailId != "" &&
      emailval
    ) {
      isEmailExists(checkEmailExists, false, isInviteSend);
    }
    if (tEmailIdError) {
      hasError = true;
    }

    if (
      (teacherInfo.tEmailId != null || teacherInfo.tEmailId != undefined) &&
      teacherInfo.tEmailId.length > 128
    ) {
      teacherInfo.tEmailIdError = "Email too long.";
      setTEmailIdError("Email too long.");
      hasError = true;
    }

    if (
      (teacherInfo.schoolContactNo != null ||
        teacherInfo.schoolContactNo != undefined) &&
      teacherInfo.schoolContactNo.length > 128
    ) {
      teacherInfo.schoolContactNoError = "School phone number too long.";
      hasError = true;
    }
    let isTeacherPhnValidate = isTeacherPhnDigit(teacherInfo.schoolContactNo);
    if (teacherInfo.schoolContactNo != "" && !isTeacherPhnValidate) {
      teacherInfo.schoolContactNoError =
        "School phone number should be 10 digit.";
      hasError = true;
    }
    if (
      (teacherInfo.schoolDistrict != null ||
        teacherInfo.schoolDistrict != undefined) &&
      teacherInfo.schoolDistrict.length > 128
    ) {
      teacherInfo.schoolDistrictError = "School district too long";
      hasError = true;
    }
    if (
      (teacherInfo.schoolName != null || teacherInfo.schoolName != undefined) &&
      teacherInfo.schoolName.length > 128
    ) {
      teacherInfo.schoolNameError = "School too long";
      hasError = true;
    }

    setShowTeacherDetail(true);
    setTeacherInfo(teacherInfo);
    loadData();
    return hasError;
  };
  const cancelAddTeacher = () => {
    setShowTeacherDetail(false);
  };
  const openAddTeacher = () => {
    setShowTeacherDetail(false);
  };
  const handleSaveClick = () => {
    let model = getValues();
    teacherInfo.isInviteSend = false;
    teacherInfo.tFirstName = model.tFirstName;
    teacherInfo.tLastName = model.tLastName;
    teacherInfo.tEmailId = model.tEmailId;
    teacherInfo.schoolContactNo = model.schoolContactNo;
    teacherInfo.schoolDistrict = model.schoolDistrict;
    teacherInfo.schoolName = model.schoolName;
    teacherInfo.guardianFirstName =
      patientInfo.patientInfoModel.guardianFirstName;
    teacherInfo.guardianLastName =
      patientInfo.patientInfoModel.guardianLastName;
    teacherInfo.firstName = model.firstName;
    teacherInfo.lastName = model.lastName;
    setTeacherInfo(teacherInfo);
    if (checkForError()) {
      return false;
    }
    if (
      teacherInfo.tFirstName &&
      teacherInfo.tLastName &&
      teacherInfo.tEmailId
    ) {
      postTeacherData(teacherInfo);
    }
  };
  const isEmailExists = (data: any, onBlur: any, isInviteSend?: any) => {
    let checkEmailExists = data;
    if (isInviteSend) {
      setDisableInviteButton(true);
    } else if (onBlur) {
      setDisableInviteButton(true);
      setDisableSaveButton(true);
    } else {
      setDisableSaveButton(true);
    }
    new PatientService()
      .checkIsEmailExists(checkEmailExists)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
          if (res.result.isEmail == true) {
            setTEmailIdError("Email already exists.");
            if (isInviteSend) {
              setDisableInviteButton(false);
            } else if (onBlur) {
              setDisableInviteButton(false);
              setDisableSaveButton(false);
            } else {
              setDisableSaveButton(false);
            }
          } else {
            setTEmailIdError("");
            if (isInviteSend) {
              setDisableInviteButton(false);
            } else if (onBlur) {
              setDisableInviteButton(false);
              setDisableSaveButton(false);
            } else {
              setDisableSaveButton(false);
            }
          }
        }
      })

      .catch((ex) => {
        if (isInviteSend) {
          setDisableInviteButton(false);
        } else if (onBlur) {
          setDisableInviteButton(false);
          setDisableSaveButton(false);
        } else {
          setDisableSaveButton(false);
        }
      });
  };
  const handleOnBlur_Email = (emailId) => {
    checkEmailExists.emailId = emailId;
    checkEmailExists.id = teacherInfo.teacherId;
    setCheckEmailExists(checkEmailExists);
    isEmailExists(checkEmailExists, true);
  };
  const isNumber = (evt) => {
    if (evt.which > 31 && (evt.which < 48 || evt.which > 57)) {
      evt.preventDefault();
    }
  };
  return (
    <form onSubmit={handleSubmit(formSubmit)} className="general__form">
      <section className="">
        <Heading
          heading="Child Information"
          subHeading=""
          styleName="general__heading"
        />
        <div className="form">
          <div className="row">
            <div className="medical-group col-12 col-md-3">
              <label className="form-label">First Name</label>
              <input
                type="text"
                className={
                  !errors.firstName
                    ? "form-control mb-1"
                    : "form-control mb-1  is-invalid"
                }
                defaultValue={patientInfo.patientInfoModel.firstName}
                placeholder="First Name"
                onFocus={handleKeyUp}
                {...register("firstName", {
                  required: "Please fill first name.",
                  maxLength: {
                    value: 128,
                    message: "First name is too long",
                  },
                })}
              />
              {errors.firstName && (
                <div className="invalid-feedback">
                  {errors.firstName.message?.toString()}
                </div>
              )}
            </div>

            <div className="medical-group col-12 col-md-3">
              <label className="form-label">Last Name</label>

              <input
                type="text"
                className={
                  !errors.lastName ? "form-control mb-1" : "form-control mb-1  is-invalid"
                }
                defaultValue={patientInfo.patientInfoModel.lastName}
                placeholder="Last Name"
                onFocus={handleKeyUp}
                {...register("lastName", {
                  required: "Please fill last name.",
                  maxLength: {
                    value: 128,
                    message: "Last name is too long",
                  },
                })}
              />
              {errors.lastName && (
                <div className="invalid-feedback">
                  {errors.lastName.message?.toString()}
                </div>
              )}
            </div>

            <div className="medical-group col-12 col-md-3">
              <label className="form-label">Grade</label>

              <input
                type="text"
                className={
                  !errors.grade ? "form-control mb-1" : "form-control mb-1  is-invalid"
                }
                defaultValue={patientInfo.patientInfoModel.grade}
                placeholder="Grade"
                onFocus={handleKeyUp}
                {...register("grade", {
                  required: "Please fill grade.",
                  maxLength: {
                    value: 128,
                    message: "Grade is too long",
                  },
                })}
              />
              {errors.grade && (
                <div className="invalid-feedback">
                  {errors.grade.message?.toString()}
                </div>
              )}
            </div>
            <div className="medical-group dob col-12 col-md-3">
              <label className="form-label">Date of Birth</label>
              <Controller
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Tooltip
                    placement="right"
                    title="Date should be in MM/DD/YYYY format"
                  >
                    <DatePicker
                      clearIcon={false}
                      onChange={(e) => {
                        dateChange(e);
                      }}
                      className="datepicker"
                      inputReadOnly={false}
                      name={name}
                      value={value}
                      placeholder="MM/DD/YYYY"
                      format="MM/DD/YYYY"
                    />
                  </Tooltip>
                )}
                name="dob"
                defaultValue={dayjs(patientInfo.patientInfoModel.dateOfBirth)}
                control={control}
              />
            </div>
          </div>
          <div className="row">
            <div className="medical-group btn-select col-12 col-md-3 my-md-0 my-3">
              <label className="form-label">Gender</label>
              <div className="btn-group btnGender">
                <button
                  type="button"
                  className={
                    patientInfo.patientInfoModel.gender == Gender.Male
                      ? "btn btn-primary btn-sm btnGender_selected"
                      : "btn btn-outline-secondary btn-sm btnGender_status"
                  }
                  value={Gender.Male}
                  onClick={genderChange}
                  {...register("male")}
                >
                  Male
                </button>
                <button
                  type="button"
                  className={
                    patientInfo.patientInfoModel.gender == Gender.Female
                      ? "btn btn-primary btn-sm btnGender_selected"
                      : "btn btn-outline-secondary btn-sm btnGender_status"
                  }
                  value={Gender.Female}
                  onClick={genderChange}
                  {...register("female")}
                >
                  Female
                </button>
                <button
                  type="button"
                  name="others"
                  className={
                    patientInfo.patientInfoModel.gender == Gender.Others
                      ? "btn btn-primary btn-sm btnGender_selected"
                      : "btn btn-outline-secondary btn-sm btnGender_status"
                  }
                  value={Gender.Others}
                  onClick={genderChange}
                  {...register}
                >
                  Other
                </button>
              </div>
            </div>
            <div className="medical-group col-12 col-md-3">
              <label className="form-label">Birthplace</label>

              <input
                type="text"
                className={
                  !errors.birthPlace
                    ? "form-control mb-1"
                    : "form-control mb-1  is-invalid"
                }
                defaultValue={patientInfo.patientInfoModel.birthPlace}
                placeholder="Birthplace"
                onFocus={handleKeyUp}
                {...register("birthPlace", {
                  required: "Please fill birth place.",
                  maxLength: {
                    value: 128,
                    message: "Birthplace is too long",
                  },
                })}
              />
              {errors.birthPlace && (
                <div className="invalid-feedback">
                  {errors.birthPlace.message?.toString()}
                </div>
              )}
            </div>

            <div className="medical-group btn-select col-12 col-md-3">
              <label className="form-label" style={{ display: "block" }}>
                Adopted
              </label>
              <div className="btn-group btnAdopted">
                <button
                  type="button"
                  className={
                    patientInfo.patientInfoModel.adopted == Options.yes
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  value={Options.yes}
                  onClick={adoptedChange}
                  {...register("yes")}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className={
                    patientInfo.patientInfoModel.adopted == Options.No
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  value={Options.No}
                  onClick={adoptedChange}
                  {...register("no")}
                >
                  No
                </button>
              </div>
            </div>
            <div className="medical-group col-12 col-md-3">
              <label className="form-label">Adopted Age</label>
              <input
                type="text"
                className={
                  !errors.adoptedAge
                    ? "form-control mb-1"
                    : "form-control mb-1 is-invalid"
                }
                value={patientInfo.patientInfoModel.adoptedAge}
                placeholder="Adopted Age"
                onFocus={handleKeyUp}
                {...register("adoptedAge", {
                  required: {
                    value:
                      patientInfo.patientInfoModel.adopted == 1 ? true : false,
                    message: "Please fill adopted age.",
                  },
                })}
                onChange={adoptedAgeChange}
                disabled={
                  patientInfo.patientInfoModel.adopted == 1 ? false : true
                }
              />
              {errors.adoptedAge && (
                <div className="invalid-feedback">
                  {errors.adoptedAge?.message?.toString()}
                </div>
              )}
            </div>
          </div>
          <div className="row">
          <div className="medical-group btn-select col-12 col-md-3">
              <label className="form-label" style={{ display: "block" }}>
                Is Birth History Known?
              </label>
              <div className="btn-group btnAdopted">
                <button
                  type="button"
                  className={
                    patientInfo.patientInfoModel.birthParentInfo == Options.yes
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  value={Options.yes}
                  onClick={birthParentInfoChange}
                  {...register("birthKnownYes")}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className={
                    patientInfo.patientInfoModel.birthParentInfo == Options.No
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  value={Options.No}
                  onClick={birthParentInfoChange}
                  {...register("birthKnownNo")}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <Heading
          heading="Parent Information"
          subHeading=""
          styleName="general__heading"
        />
        <div className="row">
          <div className="medical-group col-12 col-md-3">
            <label className="form-label">Person Completing Form</label>
            <input
              type="text"
              className={
                !errors.personCompleteForm
                  ? "form-control mb-1"
                  : "form-control mb-1  is-invalid"
              }
              defaultValue={patientInfo.patientInfoModel.personCompletingForm}
              placeholder="Person Completing Form"
              onFocus={handleKeyUp}
              {...register("personCompleteForm", {
                required: "Please fill person completing form.",
                maxLength: {
                  value: 128,
                  message: "Person Completing Form is too long",
                },
              })}
            />
            {errors.personCompleteForm && (
              <div className="invalid-feedback">
                {errors.personCompleteForm?.message?.toString()}
              </div>
            )}
          </div>

          <div className="medical-group col-12 col-md-3">
            <label className="form-label">Relationship</label>
            <input
              type="text"
              className={
                !errors.relationshipWithPersonCompletingForm
                  ? "form-control mb-1"
                  : "form-control mb-1  is-invalid"
              }
              defaultValue={
                patientInfo.patientInfoModel
                  .relationshipWithPersonCompletingForm
              }
              placeholder="Relationship"
              onFocus={handleKeyUp}
              {...register("relationshipWithPersonCompletingForm", {
                required: "Please fill relationship.",
                maxLength: {
                  value: 128,
                  message: "Relationship is too long",
                },
              })}
            />
            {errors.relationshipWithPersonCompletingForm && (
              <div className="invalid-feedback">
                {errors.relationshipWithPersonCompletingForm?.message?.toString()}
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="medical-group col-12 col-md-3">
            <label className="form-label">Parent 1</label>
            <input
              type="text"
              className={
                !errors.motherName ? "form-control mb-1" : "form-control mb-1  is-invalid"
              }
              defaultValue={patientInfo.patientInfoModel.parentModel.motherName}
              placeholder="Parent 1 Name"
              onFocus={handleKeyUp}
              {...register("motherName", {
                required: "Please enter name.",
                maxLength: {
                  value: 128,
                  message: "Name is too long",
                },
              })}
            />
            {errors.motherName && (
              <div className="invalid-feedback">
                {errors.motherName?.message?.toString()}
              </div>
            )}
          </div>
          <div className="medical-group col-12 col-md-3">
            <label className="form-label">Home Phone Number</label>
            <PatternFormat
              format="(###)-###-####"
              value={
                patientInfo.patientInfoModel.parentModel.motherHomePhoneNo || ""
              }
              className={
                !errors.motherHomePhoneNo
                  ? "form-control mb-1"
                  : "form-control mb-1  is-invalid"
              }
              onFocus={handleKeyUp}
              placeholder="Home Phone Number"
              //getInputRef={motherHomePhoneNo.ref}
              {...register("motherHomePhoneNo", {
                required: "Please fill contact no",
                minLength: {
                  value: 10,
                  message: "Phone number must be 10 digits",
                },
                onBlur: (e) => setValue("motherHomePhoneNo", e.target.value),
              })}
            />
            {errors.motherHomePhoneNo && (
              <div className="invalid-feedback">
                {errors.motherHomePhoneNo?.message?.toString()}
              </div>
            )}
            {errors.motherHomePhoneNo &&
              errors.motherHomePhoneNo.type === "validate" && (
                <div className="invalid-feedback">
                  Home phone number should be 10 digit.
                </div>
              )}
          </div>
          <div className=" col-12 col-md-3">
            <div className="medical-group optional-field">
              <label className="form-label">Work Phone Number</label>
              <span className="optional">Optional</span>
              <PatternFormat
                format="(###)-###-####"
                value={
                  patientInfo.patientInfoModel.parentModel.motherWorkPhoneNo ||
                  ""
                }
                className={
                  !errors.motherWorkPhoneNo
                    ? "form-control mb-1"
                    : "form-control mb-1  is-invalid"
                }
                placeholder="Work Phone Number"
                onFocus={handleKeyUp}
                getInputRef={motherWorkPhoneNo.ref}
                {...register(motherWorkPhoneNo.name)}
              />
              {errors.motherWorkPhoneNo && (
                <div className="invalid-feedback">
                  {errors.motherWorkPhoneNo.message?.toString()}
                </div>
              )}
              {errors.motherWorkPhoneNo &&
                errors.motherWorkPhoneNo.type === "validate" && (
                  <div className="invalid-feedback">
                    Work phone number should be 10 digit.
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className=" col-12 col-md-3">
            <div className="medical-group">
              <label className="form-label">Address 1</label>
              <input
                type="text"
                className={
                  !errors.mAddress1
                    ? "form-control mb-1"
                    : "form-control mb-1  is-invalid"
                }
                defaultValue={
                  patientInfo.patientInfoModel.parentModel.motherAddressModel
                    .address1
                }
                placeholder="Address 1"
                onFocus={handleKeyUp}
                {...register("mAddress1", {
                  required: "Please fill address.",
                })}
              />
              {errors.mAddress1 && (
                <div className="invalid-feedback">
                  {errors.mAddress1?.message?.toString()}
                </div>
              )}
            </div>
          </div>
          <div className=" col-12 col-md-3">
            <div className="medical-group optional-field">
              <label className="form-label">Address 2</label>
              <span className="optional">Optional</span>
              <input
                type="text"
                className="form-control mb-1"
                defaultValue={
                  patientInfo.patientInfoModel.parentModel.motherAddressModel
                    .address2
                }
                placeholder="Address 2"
                onFocus={handleKeyUp}
                {...register("mAddress2")}
              />
            </div>
          </div>
          <div className=" col-12 col-md-3">
            <div className="medical-group">
              <label className="form-label">City</label>
              <input
                type="text"
                className={
                  !errors.mCity ? "form-control mb-1" : "form-control mb-1  is-invalid"
                }
                defaultValue={
                  patientInfo.patientInfoModel.parentModel.motherAddressModel
                    .city
                }
                placeholder="City"
                onFocus={handleKeyUp}
                {...register("mCity", {
                  required: "Please fill city.",
                  maxLength: {
                    value: 128,
                    message: "City is too long",
                  },
                })}
              />
              {errors.mCity && (
                <div className="invalid-feedback">
                  {errors.mCity?.message?.toString()}
                </div>
              )}
            </div>
          </div>
          <div className=" col-12 col-md-3">
            <div className="medical-group">
              <label className="form-label">State</label>
              <input
                type="text"
                className={
                  !errors.mState ? "form-control mb-1" : "form-control mb-1  is-invalid"
                }
                defaultValue={
                  patientInfo.patientInfoModel.parentModel.motherAddressModel
                    .state
                }
                placeholder="State"
                onFocus={handleKeyUp}
                {...register("mState", {
                  required: "Please fill state.",
                  maxLength: {
                    value: 128,
                    message: "State is too long",
                  },
                })}
              />
              {errors.mState && (
                <div className="invalid-feedback">
                  {errors.mState?.message?.toString()}
                </div>
              )}
            </div>
          </div>
          <div className=" col-12 col-md-3">
            <div className="medical-group">
              <label className="form-label">Zip Code</label>
              <input
                type="text"
                className={
                  !errors.mZipcode ? "form-control mb-1" : "form-control mb-1  is-invalid"
                }
                defaultValue={
                  patientInfo.patientInfoModel.parentModel.motherAddressModel
                    .zipcode
                }
                placeholder="Zip Code"
                onFocus={handleKeyUp}
                {...register("mZipcode", {
                  required: "Please fill zipcode.",
                  maxLength: {
                    value: 128,
                    message: "Zip code is too long",
                  },
                })}
                onKeyPress={(e) => isNumber(e)}
              />
              {errors.mZipcode && (
                <div className="invalid-feedback">
                  {errors.mZipcode?.message?.toString()}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* father's row */}
        <div className="row">
          <div className="medical-group col-12 col-md-3">
            <label className="form-label">Parent 2</label>
            <input
              type="text"
              className={
                !errors.fatherName ? "form-control mb-1" : "form-control mb-1  is-invalid"
              }
              defaultValue={patientInfo.patientInfoModel.parentModel.fatherName}
              placeholder="Parent 2 Name"
              onFocus={handleKeyUp}
              {...register("fatherName", {
                required: "Please enter name.",
                maxLength: {
                  value: 128,
                  message: "Name is too long",
                },
              })}
            />
            {errors.fatherName && (
              <div className="invalid-feedback">
                {errors.fatherName.message?.toString()}
              </div>
            )}
          </div>

          <div className="medical-group col-12 col-md-3">
            <label className="form-label">Home Phone Number</label>

            <PatternFormat
              format="(###)-###-####"
              value={
                patientInfo.patientInfoModel.parentModel.fatherHomePhoneNo || ""
              }
              className={
                !errors.fatherHomePhoneNo
                  ? "form-control mb-1"
                  : "form-control mb-1  is-invalid"
              }
              onFocus={handleKeyUp}
              placeholder="Home Phone Number"
              getInputRef={fatherHomePhoneNo.ref}
              {...register(fatherHomePhoneNo.name)}
            />
            {errors.fatherHomePhoneNo && (
              <div className="invalid-feedback">
                {errors.fatherHomePhoneNo?.message?.toString()}
              </div>
            )}
            {errors.fatherHomePhoneNo &&
              errors.fatherHomePhoneNo.type === "validate" && (
                <div className="invalid-feedback">
                  Home phone number should be 10 digit.
                </div>
              )}
          </div>
          <div className="col-12 col-md-3">
            <div className="medical-group optional-field">
              <label className="form-label">Work Phone Number</label>
              <span className="optional">Optional</span>
              <PatternFormat
                format="(###)-###-####"
                value={
                  patientInfo.patientInfoModel.parentModel.fatherWorkPhoneNo ||
                  ""
                }
                className={
                  !errors.fatherWorkPhoneNo
                    ? "form-control mb-1"
                    : "form-control mb-1  is-invalid"
                }
                placeholder="Work Phone Number"
                onFocus={handleKeyUp}
                getInputRef={fatherWorkPhoneNo.ref}
                {...register(fatherWorkPhoneNo.name)}
              />
              {errors.fatherWorkPhoneNo && (
                <div className="invalid-feedback">
                  {errors.fatherWorkPhoneNo?.message?.toString()}
                </div>
              )}
              {errors.fatherWorkPhoneNo &&
                errors.fatherWorkPhoneNo.type === "validate" && (
                  <div className="invalid-feedback">
                    Work phone number should be 10 digit.
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-3">
            <div className="medical-group">
              <label className="form-label">Address 1</label>
              <input
                type="text"
                className={
                  !errors.fAddress1
                    ? "form-control mb-1"
                    : "form-control mb-1  is-invalid"
                }
                defaultValue={
                  patientInfo.patientInfoModel.parentModel.fatherAddressModel
                    .address1
                }
                placeholder="Address 1"
                onFocus={handleKeyUp}
                {...register("fAddress1", {
                  required: "Please fill address.",
                })}
              />
              {errors.fAddress1 && (
                <div className="invalid-feedback">
                  {errors.fAddress1?.message?.toString()}
                </div>
              )}
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="medical-group optional-field">
              <label className="form-label">Address 2</label>
              <span className="optional">Optional</span>
              <input
                type="text"
                className="form-control mb-1"
                defaultValue={
                  patientInfo.patientInfoModel.parentModel.fatherAddressModel
                    .address2
                }
                placeholder="Address 2"
                onFocus={handleKeyUp}
                {...register("fAddress2")}
                onBlur={() => console.log(patientInfo)} //todo: remove
              />
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="medical-group">
              <label className="form-label">City</label>
              <input
                type="text"
                className={
                  !errors.fCity ? "form-control mb-1" : "form-control mb-1  is-invalid"
                }
                defaultValue={
                  patientInfo.patientInfoModel.parentModel.fatherAddressModel
                    .city
                }
                placeholder="City"
                onFocus={handleKeyUp}
                {...register("fCity", {
                  required: "Please fill city.",
                  maxLength: {
                    value: 128,
                    message: "City is too long",
                  },
                })}
              />
              {errors.fCity && (
                <div className="invalid-feedback">
                  {errors.fCity?.message?.toString()}
                </div>
              )}
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="medical-group">
              <label className="form-label">State</label>
              <input
                type="text"
                className={
                  !errors.fState ? "form-control mb-1" : "form-control mb-1  is-invalid"
                }
                defaultValue={
                  patientInfo.patientInfoModel.parentModel.fatherAddressModel
                    .state
                }
                placeholder="State"
                onFocus={handleKeyUp}
                {...register("fState", {
                  required: "Please fill state.",
                  maxLength: {
                    value: 128,
                    message: "State is too long",
                  },
                })}
              />
              {errors.fState && (
                <div className="invalid-feedback">
                  {errors.fState?.message?.toString()}
                </div>
              )}
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="medical-group">
              <label className="form-label">Zip Code</label>
              <input
                type="text"
                className={
                  !errors.fZipcode ? "form-control mb-1" : "form-control mb-1  is-invalid"
                }
                defaultValue={
                  patientInfo.patientInfoModel.parentModel.fatherAddressModel
                    .zipcode
                }
                placeholder="Zip Code"
                onFocus={handleKeyUp}
                {...register("fZipcode", {
                  required: "Please fill zipcode.",
                  maxLength: {
                    value: 128,
                    message: "Zip code is too long",
                  },
                })}
                onKeyPress={(e) => isNumber(e)}
              />
              {errors.fZipcode && (
                <div className="invalid-feedback">
                  {errors.fZipcode?.message?.toString()}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* father's row end */}
      </section>

      <section className="">
        <Heading
          heading="Physician Information"
          subHeading=""
          styleName="general__heading"
        />
        <div className="form">
          <div className="row">
            <div className="medical-group col-12 col-md-3">
              <label className="form-label">First Name</label>
              <input
                type="text"
                className={
                  !errors.pFirstName
                    ? "form-control mb-1"
                    : "form-control mb-1  is-invalid"
                }
                defaultValue={
                  patientInfo.patientInfoModel.physicianModel.pFirstName
                }
                onFocus={handleKeyUp}
                placeholder="First Name"
                {...register("pFirstName", {
                  required: "Please fill first name.",
                  maxLength: {
                    value: 128,
                    message: "First Name is too long",
                  },
                })}
              />
              {errors.pFirstName && (
                <div className="invalid-feedback">
                  {errors.pFirstName?.message?.toString()}
                </div>
              )}
            </div>

            <div className="medical-group col-12 col-md-3">
              <label className="form-label">Last Name</label>
              <input
                type="text"
                className={
                  !errors.pLastName
                    ? "form-control mb-1"
                    : "form-control mb-1  is-invalid"
                }
                defaultValue={
                  patientInfo.patientInfoModel.physicianModel.pLastName
                }
                placeholder="Last Name"
                onFocus={handleKeyUp}
                {...register("pLastName", {
                  required: "Please fill last name.",
                  maxLength: {
                    value: 128,
                    message: "Last Name is too long",
                  },
                })}
              />
              {errors.pLastName && (
                <div className="invalid-feedback">
                  {errors.pLastName?.message?.toString()}
                </div>
              )}
            </div>

            <div className="medical-group optional-field col-12 col-md-3">
              <label className="form-label">Email</label>
              <span className="optional">Optional</span>
              <input
                type="text"
                className={
                  !errors.pEmailId ? "form-control mb-1" : "form-control mb-1  is-invalid"
                }
                defaultValue={
                  patientInfo.patientInfoModel.physicianModel.pEmailId
                }
                placeholder="Email"
                onFocus={handleKeyUp}
                {...register("pEmailId", {
                  maxLength: {
                    value: 128,
                    message: "email is too long",
                  },
                  pattern:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
              {errors.pEmailId && (
                <div className="invalid-feedback">
                  {errors.pEmailId?.message
                    ? errors.pEmailId?.message?.toString()
                    : "Email is not valid."}
                </div>
              )}
            </div>

            <div className="medical-group optional-field col-12 col-md-3">
              <label className="form-label">Phone</label>
              <span className="optional">Optional</span>
              <PatternFormat
                format="(###)-###-####"
                value={
                  patientInfo.patientInfoModel?.physicianModel.pPhoneNo || ""
                }
                className={
                  !errors.pPhoneNo ? "form-control mb-1" : "form-control mb-1  is-invalid"
                }
                placeholder={"Phone Number"}
                onFocus={handleKeyUp}
                getInputRef={pPhoneNo.ref}
                {...register(pPhoneNo.name)}
                onBlur={() => console.log(errors)} //todo: remove
              />
              {errors.pPhoneNo && (
                <div className="invalid-feedback">
                  {errors.pPhoneNo?.message?.toString()}
                </div>
              )}
              {errors.pPhoneNo && errors.pPhoneNo.type === "validate" && (
                <div className="invalid-feedback">
                  Phone number should be 10 digit.
                </div>
              )}
            </div>
          </div>
        </div>
        <Heading
          heading="Child's Ethnicity (Providing this information is voluntary. Check all that apply.)"
          subHeading=""
          styleName="general__heading"
        />
        <div className="form">
          <div className="row">
            <div className="medical-group col-12 col-md-3 mt-md-2">
              <input
                type="checkbox"
                className="btn-check"
                id="africanAmerican"
                defaultChecked={
                  patientInfo.patientInfoModel.childsEthnicityModel
                    .africanAmerican
                }
                {...register("africanAmerican")}
                onFocus={handleKeyUp}
                onClick={(e) => isChildsEthnicitySelected(1, e)}
              />
              <label
                className={
                  patientInfo.patientInfoModel.childsEthnicityModel
                    .africanAmerican === true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="africanAmerican"
              >
                African-American
              </label>
            </div>
            <div className="medical-group col-12 col-md-3 mt-md-2">
              <input
                type="checkbox"
                className="btn-check"
                id="hispanic"
                defaultChecked={
                  patientInfo.patientInfoModel.childsEthnicityModel.hispanic
                }
                {...register("hispanic")}
                onFocus={handleKeyUp}
                onClick={(e) => isChildsEthnicitySelected(2, e)}
              />
              <label
                className={
                  patientInfo.patientInfoModel.childsEthnicityModel.hispanic ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="hispanic"
              >
                Hispanic
              </label>
            </div>
            <div className="medical-group col-12 col-md-3 mt-md-2">
              <input
                type="checkbox"
                className="btn-check"
                id="americianIndian"
                defaultChecked={
                  patientInfo.patientInfoModel.childsEthnicityModel
                    .americianIndian
                }
                {...register("americianIndian")}
                onFocus={handleKeyUp}
                onClick={(e) => isChildsEthnicitySelected(3, e)}
              />
              <label
                className={
                  patientInfo.patientInfoModel.childsEthnicityModel
                    .americianIndian === true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="americianIndian"
              >
                American Indian
              </label>
            </div>
            <div className="medical-group col-12 col-md-3 mt-md-2">
              <input
                type="checkbox"
                className="btn-check"
                id="asian"
                defaultChecked={
                  patientInfo.patientInfoModel.childsEthnicityModel.asian
                }
                {...register("asian")}
                onFocus={handleKeyUp}
                onClick={(e) => isChildsEthnicitySelected(4, e)}
              />
              <label
                className={
                  patientInfo.patientInfoModel.childsEthnicityModel.asian ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="asian"
              >
                Asian(Chinese, Japanese, Korean)
              </label>
            </div>
          </div>
        </div>

        <div className="form">
          <div className="row">
            <div className="medical-group col-12 col-md-3 mt-md-2">
              <input
                type="checkbox"
                className="btn-check"
                id="fillipino"
                defaultChecked={
                  patientInfo.patientInfoModel.childsEthnicityModel.fillipino
                }
                {...register("fillipino")}
                onFocus={handleKeyUp}
                onClick={(e) => isChildsEthnicitySelected(5, e)}
              />
              <label
                className={
                  patientInfo.patientInfoModel.childsEthnicityModel
                    .fillipino === true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="fillipino"
              >
                Filipino
              </label>
            </div>
            <div className="medical-group col-12 col-md-3 mt-md-2">
              <input
                type="checkbox"
                className="btn-check"
                id="indianSubcontinent"
                defaultChecked={
                  patientInfo.patientInfoModel.childsEthnicityModel
                    .indianSubcontinent
                }
                {...register("indianSubcontinent")}
                onFocus={handleKeyUp}
                onClick={(e) => isChildsEthnicitySelected(6, e)}
              />
              <label
                className={
                  patientInfo.patientInfoModel.childsEthnicityModel
                    .indianSubcontinent === true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="indianSubcontinent"
              >
                Indian Subcontinent
              </label>
            </div>
            <div className="medical-group col-12 col-md-3 mt-md-2">
              <input
                type="checkbox"
                className="btn-check"
                id="seAsian"
                defaultChecked={
                  patientInfo.patientInfoModel.childsEthnicityModel.seAsian
                }
                {...register("seAsian")}
                onFocus={handleKeyUp}
                onClick={(e) => isChildsEthnicitySelected(7, e)}
              />
              <label
                className={
                  patientInfo.patientInfoModel.childsEthnicityModel.seAsian ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="seAsian"
              >
                S.E. Asian(Vietnamese, Cambodian)
              </label>
            </div>
            <div className="medical-group col-12 col-md-3 mt-md-2">
              <input
                type="checkbox"
                className="btn-check"
                id="hawaliain"
                defaultChecked={
                  patientInfo.patientInfoModel.childsEthnicityModel.hawaliain
                }
                {...register("hawaliain")}
                onFocus={handleKeyUp}
                onClick={(e) => isChildsEthnicitySelected(8, e)}
              />
              <label
                className={
                  patientInfo.patientInfoModel.childsEthnicityModel
                    .hawaliain === true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="hawaliain"
              >
                Hawailian/Part Hawailian
              </label>
            </div>
          </div>
        </div>

        <div className="form">
          <div className="row">
            <div className="medical-group col-12 col-md-3 mt-md-2">
              <input
                type="checkbox"
                className="btn-check"
                id="caucasian"
                defaultChecked={
                  patientInfo.patientInfoModel.childsEthnicityModel.caucasian
                }
                {...register("caucasian")}
                onFocus={handleKeyUp}
                onClick={(e) => isChildsEthnicitySelected(9, e)}
              />
              <label
                className={
                  patientInfo.patientInfoModel.childsEthnicityModel
                    .caucasian === true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="caucasian"
              >
                Caucasian
              </label>
            </div>

            <div className="medical-group col-12 col-md-3 mt-md-2">
              <input
                type="checkbox"
                className="btn-check"
                id="otherPacificIslander"
                defaultChecked={
                  patientInfo.patientInfoModel.childsEthnicityModel
                    .otherPacificIslander
                }
                {...register("otherPacificIslander")}
                onFocus={handleKeyUp}
                onClick={(e) => isChildsEthnicitySelected(10, e)}
              />
              <label
                className={
                  patientInfo.patientInfoModel.childsEthnicityModel
                    .otherPacificIslander === true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="otherPacificIslander"
              >
                Other Pacific Islander
              </label>
            </ div>
              <div className="medical-group col-12 col-md-6 optional-field">
                <label className="form-label">Other</label>
                <span className="optional">Optional</span>
                <input
                  defaultValue={
                    patientInfo.patientInfoModel.childsEthnicityModel
                      .childsEthnicityDescription
                  }
                  type="text"
                  className={
                    !errors.childsEthnicityDescription
                      ? "form-control mb-1"
                      : "form-control mb-1  is-invalid"
                  }
                  {...register("childsEthnicityDescription")}
                  onFocus={handleKeyUp}
                />
              </div>
            </div>
          </div>
      </section>

      <div className="btnContainer">
        <Loader
          loading={isSaving}
          marginBottom="0px"
          marginTop="8px"
          width="368px"
        ></Loader>
        {!isSaving && (
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            className="btn_add"
            style={{ marginBottom: "40px" }}
          >
            Save <i className="fa fa-long-arrow-right"></i>
          </Button>
        )}
      </div>
    </form>
  );
}
