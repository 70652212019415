import { AppConfig, HttpWrapper } from "../../core";
import { PsychologicalTestingPostModel } from "../../models/psychologicalTesting/psychological-testing-post.model";
import { PsychologicalTestingModel } from "../../models/psychologicalTesting/psychological-testing.model";

export class PsychologicalTestingService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  postPsychologicalTesting(model: PsychologicalTestingPostModel) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "psychologicaltesting/create",
      model
    );
  }
  getPsychologicalTestingDetail(patientId: string) {
    return this.wrapper.get<PsychologicalTestingModel[]>(
      AppConfig.apiEndpoint + "psychologicaltesting/detail/" + patientId
    );
  }
  deletePsychologicalTesting(id: string) {
    return this.wrapper.delete<boolean>(
      AppConfig.apiEndpoint + "psychologicaltesting/delete/" + id
    );
  }
}
