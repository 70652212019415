import React from "react";
import { useForm } from "react-hook-form";
import "../../styles.css";
import Heading from "../../../heading";
import { DropdownItemModel } from "../../../../shared/models/dropdown.model";
import { MaritalStatus } from "../../../../shared/enums/marital-status.enum";
import { AddBirthParentModel } from "../../../../models/familyBackground/add-birth-parent.model";
import { Button } from "antd";
import { Loader } from "../../../../shared/loaders";

interface IProps {
  addBirthParentModel: AddBirthParentModel;
  onSubmit: Function;
  tab: number;
  isSaving: boolean;
  patientId: string;
  assignModel: Function;
  isReadOnly: boolean;
  contactWithBirthParentModel: DropdownItemModel[];
  educationLevelModel: DropdownItemModel[];
  childResideWithModel: DropdownItemModel[];
  parentTabChange: Function;
  isBirthHistoryOptional: boolean;
}
export function AddBirthParentForm(props: IProps) {
  const { register, formState: {errors}, handleSubmit, getValues } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
  });
  let addBirthParentModel = { ...props.addBirthParentModel };
  let educationLevelModel = props.educationLevelModel;
  let childResideWithModel = props.childResideWithModel;
  let contactWithBirthParentModel = props.contactWithBirthParentModel;
  const formSubmit = (data) => {
    addBirthParentModel.patientId = props.patientId;
    addBirthParentModel.motherAge = data.motherAge;
    addBirthParentModel.motherHighestLevelEducation = data.motherHighestLevelEducation;
    addBirthParentModel.motherProfession = data.motherProfession;
    addBirthParentModel.motherName = data.motherName;
    addBirthParentModel.fatherAge = data.fatherAge;
    addBirthParentModel.fatherHighestLevelEducation = data.fatherHighestLevelEducation;
    addBirthParentModel.fatherProfession = data.fatherProfession;
    addBirthParentModel.fatherName = data.fatherName;
    addBirthParentModel.contactWithBirthParent = data.contactWithBirthParent;
    addBirthParentModel.specifyChildResidesWith = data.specifyChildResidesWith;
    addBirthParentModel.comments = data.comments;
    props.assignModel({ addBirthParent: addBirthParentModel });
    if (checkForError()) {
      return false;
    }
    props.onSubmit({ addBirthParent: addBirthParentModel, isAutoSave: false });
  };
  const checkForError = () => {
    let hasError = false;
    addBirthParentModel.fatherAgeError = "";
    addBirthParentModel.motherAgeError = "";
    addBirthParentModel.motherNameError = "";
    addBirthParentModel.fatherNameError = "";
    addBirthParentModel.fatherProfessionError = "";
    addBirthParentModel.motherProfessionError = "";
    addBirthParentModel.fatherMaritalStatusError = "";
    addBirthParentModel.motherMaritalStatusError = "";
    addBirthParentModel.childResidesWithError = "";
    addBirthParentModel.specifyChildResidesWithError = "";

    if (addBirthParentModel.fatherAge == null || addBirthParentModel.fatherAge <= 0 && !props.isBirthHistoryOptional) {
      addBirthParentModel.fatherAgeError = "Please fill age.";
      hasError = true;
    }
    if (addBirthParentModel.motherAge == null || addBirthParentModel.motherAge <= 0 && !props.isBirthHistoryOptional) {
      addBirthParentModel.motherAgeError = "Please fill age.";
      hasError = true;
    }
    if (addBirthParentModel.motherName == null || addBirthParentModel.motherName == "" && !props.isBirthHistoryOptional) {
      addBirthParentModel.motherNameError = "Please fill mother's name.";
      hasError = true;
    }
    if (addBirthParentModel.fatherName == null || addBirthParentModel.fatherName == "" && !props.isBirthHistoryOptional) {
      addBirthParentModel.fatherNameError = "Please fill father's name.";
      hasError = true;
    }
    if (
      addBirthParentModel.fatherProfession == null ||
      addBirthParentModel.fatherProfession == "" && !props.isBirthHistoryOptional
    ) {
      addBirthParentModel.fatherProfessionError = "Please fill current profession.";
      hasError = true;
    }
    if (
      addBirthParentModel.motherProfession == null ||
      addBirthParentModel.motherProfession == "" && !props.isBirthHistoryOptional
    ) {
      addBirthParentModel.motherProfessionError = "Please fill current profession.";
      hasError = true;
    }

    if (!props.isBirthHistoryOptional && 
      (addBirthParentModel.fatherMaritalStatus == null ||
      addBirthParentModel.fatherMaritalStatus <= 0)
    ) {
      addBirthParentModel.fatherMaritalStatusError = "Please select marital status.";
      hasError = true;
    }
    if (!props.isBirthHistoryOptional && 
      (addBirthParentModel.motherMaritalStatus == null ||
      addBirthParentModel.motherMaritalStatus <= 0)
    ) {
      addBirthParentModel.motherMaritalStatusError = "Please select marital status.";
      hasError = true;
    }
    if (addBirthParentModel.childResidesWith == null || addBirthParentModel.childResidesWith <= 0 && !props.isBirthHistoryOptional) {
      addBirthParentModel.childResidesWithError = "Please select child resides with.";
      hasError = true;
    }
    if (
      addBirthParentModel.childResidesWith != null &&
      addBirthParentModel.childResidesWith == 7 &&
      (addBirthParentModel.specifyChildResidesWith == null ||
        addBirthParentModel.specifyChildResidesWith == "")
    ) {
      addBirthParentModel.specifyChildResidesWithError = "Please specify.";
      hasError = true;
    }
    props.assignModel({ addBirthParent: addBirthParentModel });
    return hasError;
  };
  const handleKeyUp = (event) => {
    setValuesInModel();
    if (event.key === "Tab") {
      saveDataOnTabChange();
    }
  };
  const handleOnBlur = () => {
    setValuesInModel();
    saveDataOnTabChange();
  };

  const saveDataOnTabChange = () => {
    if (!props.isSaving) {
      addBirthParentModel.patientId = props.patientId;
      props.onSubmit({ addBirthParent: addBirthParentModel, isAutoSave: true });
    }
  };

  function setValuesInModel() {
    let model = getValues();
    console.log("setValuesInModel: ", model);
    addBirthParentModel = Object.assign(addBirthParentModel, model);
  }
  const motherMaritalStatus = (data) => {
    setValuesInModel();
    addBirthParentModel.motherMaritalStatusError = "";
    addBirthParentModel.motherMaritalStatus = parseInt(data.target.value);
    props.assignModel({ addBirthParent: addBirthParentModel });
    saveDataOnTabChange();
  };
  const fatherMaritalStatus = (data) => {
    setValuesInModel();
    addBirthParentModel.fatherMaritalStatusError = "";
    addBirthParentModel.fatherMaritalStatus = parseInt(data.target.value);
    props.assignModel({ addBirthParent: addBirthParentModel });
    saveDataOnTabChange();
  };
  const handleContactWithBirthParent = (data) => {
    addBirthParentModel.contactWithBirthParent = parseInt(data.target.value);
    props.assignModel({ addBirthParent: addBirthParentModel });
  };
  const handleChildResidesWith = (data) => {
    setValuesInModel();
    addBirthParentModel.childResidesWith = parseInt(data.target.value);
    if (addBirthParentModel.childResidesWith != 7) {
      addBirthParentModel.specifyChildResidesWithError = "";
      addBirthParentModel.specifyChildResidesWith = "";
    }
    props.assignModel({ addBirthParent: addBirthParentModel });
    saveDataOnTabChange();
  };
  const motherHighestLevelEducationChange = (data) => {
    addBirthParentModel.motherHighestLevelEducation = parseInt(data.target.value);
    props.assignModel({ addBirthParent: addBirthParentModel });
  };
  const fatherHighestLevelEducationChange = (data) => {
    addBirthParentModel.fatherHighestLevelEducation = parseInt(data.target.value);
    props.assignModel({ addBirthParent: addBirthParentModel });
  };

  const liveWithChildFullTime = (type, isChecked) => {
    setValuesInModel();
    if (type == 1) {
      addBirthParentModel.isMotherLiveWithChildFullTime = isChecked;
    } else {
      addBirthParentModel.isFatherLiveWithChildFullTime = isChecked;
    }
    props.assignModel({ addBirthParent: addBirthParentModel });
    saveDataOnTabChange();
    };
    const maxLengthCheck = (age) => {
        if (age.target.value.length > age.target.maxLength) {
            age.target.value = age.target.value.slice(0, age.target.maxLength)
        }
    }
  return (
    <form onSubmit={handleSubmit(formSubmit)} className="">
      <div className="">
        <Heading heading="Birth Parents" subHeading="" styleName="" />
        <p>
          The following section pertains to the child’s birth or biological parents only. Even if
          you are not the child’s parent, please complete this section as accurately as possible.
        </p>

        <div className="form">
          <div className="row">
            <div className="medical-group col-12 col-md-3 ">
              <label className="form-label">Birth Mother's Name</label>
              <input
                type="text"
                className={!errors.motherName ? "form-control mb-1" : "form-control mb-1  is-invalid"}
                placeholder="Mother's Name"
                defaultValue={addBirthParentModel.motherName}
                onFocus={handleOnBlur}
                {...register("motherName")}
              />
              {addBirthParentModel.motherNameError && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {addBirthParentModel.motherNameError}
                </div>
              )}
            </div>
            <div className="medical-group col-12 col-md-3">
              <label className="form-label">Current Profession/Occupation</label>
              <input
                type="text"
                className={!errors.motherProfession ? "form-control mb-1" : "form-control mb-1  is-invalid"}
                defaultValue={addBirthParentModel.motherProfession}
                {...register("motherProfession")}
                onFocus={handleOnBlur}
              />
              {addBirthParentModel.motherProfessionError && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {addBirthParentModel.motherProfessionError}
                </div>
              )}
            </div>
            <div className="medical-group col-12 col-md-3">
              <label className="form-label">Present Age</label>
              <input
                              type="number"
                              {...register("motherAge")}
                              className={!errors.motherAge ? "form-control mb-1" : "form-control mb-1  is-invalid"}
                              defaultValue={addBirthParentModel.motherAge}
                              placeholder=""
                              onFocus={handleOnBlur}
                              maxLength={3}
                              onInput={maxLengthCheck}
                              onKeyDown={e => (e.keyCode === 69) && e.preventDefault()}
              />
              {addBirthParentModel.motherAgeError && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {addBirthParentModel.motherAgeError}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="medical-group col-12 col-md-6">
              <label className="form-label">
              Highest level of formal education completed
              </label>
              <select
                {...register("motherHighestLevelEducation")}
                className={
                  !errors.motherHighestLevelEducation
                    ? "form-control mb-1 phone_border"
                    : "form-control mb-1 phone_border  is-invalid"
                }
                defaultValue={addBirthParentModel.motherHighestLevelEducation}
                onChange={handleOnBlur}
              >
                <option value="0">None</option>

                {educationLevelModel.length > 0 &&
                  educationLevelModel.map((item, index) => (
                    <option
                      key={index}
                      selected={
                        item.value ==
                        (addBirthParentModel.motherHighestLevelEducation == null
                          ? 0
                          : addBirthParentModel.motherHighestLevelEducation.toString())
                      }
                      value={item.value}
                    >
                      {item.text}
                    </option>
                  ))}
              </select>
              <i className="fa fa-angle-down arrow"></i>
            </div>

            <div className="medical-group col-12 col-md-3 btn-select">
              <label className="form-label">Live with child full time?</label>
              <div className="btn-group btnAdopted">
                <button
                  type="button"
                  onClick={() => liveWithChildFullTime(1, true)}
                  className={
                    addBirthParentModel.isMotherLiveWithChildFullTime == true ||
                    addBirthParentModel.isMotherLiveWithChildFullTime == null
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                >
                  Yes
                </button>
                <button
                  type="button"
                  className={
                    addBirthParentModel.isMotherLiveWithChildFullTime == false
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  onClick={() => liveWithChildFullTime(1, false)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
          <div className="row my-3">
            <div className="medical-group col-12 col-md-5 btn-select">
              <label className="form-label">Marital Status</label>
              <div className="btn-group btnGender">
                <button
                  type="button"
                  value={MaritalStatus.Married}
                  {...register("married")}
                  onClick={motherMaritalStatus}
                  className={
                    addBirthParentModel.motherMaritalStatus == MaritalStatus.Married
                    ? "btn btn-primary btn-sm btnGender_selected"
                    : "btn btn-outline-secondary btn-sm btnGender_status"
                  }
                >
                  Married
                </button>
                <button
                  type="button"
                  {...register("separeted")}
                  className={
                    addBirthParentModel.motherMaritalStatus == MaritalStatus.Separeted
                    ? "btn btn-primary btn-sm btnGender_selected"
                    : "btn btn-outline-secondary btn-sm btnGender_status"
                  }
                  value={MaritalStatus.Separeted}
                  onClick={motherMaritalStatus}
                >
                  Separated
                </button>
                <button
                  type="button"
                  {...register("widowed")}
                  className={
                    addBirthParentModel.motherMaritalStatus == MaritalStatus.Widowed
                    ? "btn btn-primary btn-sm btnGender_selected"
                    : "btn btn-outline-secondary btn-sm btnGender_status"
                  }
                  value={MaritalStatus.Widowed}
                  onClick={motherMaritalStatus}
                >
                  Widowed
                </button>
                <button
                  type="button"
                  {...register("divorced")}
                  className={
                    addBirthParentModel.motherMaritalStatus == MaritalStatus.Divorced
                    ? "btn btn-primary btn-sm btnGender_selected"
                    : "btn btn-outline-secondary btn-sm btnGender_status"
                  }
                  value={MaritalStatus.Divorced}
                  onClick={motherMaritalStatus}
                >
                  Divorced
                </button>

                <button
                  type="button"
                  {...register("singleOrNeverMarried")}
                  className={
                    addBirthParentModel.motherMaritalStatus == MaritalStatus.SingleOrNeverMarried
                    ? "btn btn-primary btn-sm btnGender_selected"
                    : "btn btn-outline-secondary btn-sm btnGender_status"
                  }
                  value={MaritalStatus.SingleOrNeverMarried}
                  onClick={motherMaritalStatus}
                >
                  Single/Never Married
                </button>
                {addBirthParentModel.motherMaritalStatusError && (
                  <div className="invalid-feedback" style={{ display: "block", textAlign: "left" }}>
                    {addBirthParentModel.motherMaritalStatusError}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="medical-group col-12 col-md-3">
              <label className="form-label">Birth Father's Name</label>
              <input
                type="text"
                {...register("fatherName")}
                className={!errors.fatherName ? "form-control mb-1" : "form-control mb-1  is-invalid"}
                defaultValue={addBirthParentModel.fatherName}
                placeholder="Father's Name"
                onFocus={handleOnBlur}
              />
              {addBirthParentModel.fatherNameError && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {addBirthParentModel.fatherNameError}
                </div>
              )}
            </div>

            <div className="medical-group col-12 col-md-3">
              <label className="form-label">Current Profession/Occupation</label>
              <input
                type="text"
                {...register("fatherProfession")}
                className={!errors.fatherProfession ? "form-control mb-1" : "form-control mb-1  is-invalid"}
                defaultValue={addBirthParentModel.fatherProfession}
                onFocus={handleOnBlur}
              />
              {addBirthParentModel.fatherProfessionError && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {addBirthParentModel.fatherProfessionError}
                </div>
              )}
            </div>
            <div className="medical-group col-12 col-md-3">
              <label className="form-label">Present Age</label>
              <input
                type="number"
                {...register("fatherAge")}
                className={!errors.fatherAge ? "form-control mb-1" : "form-control mb-1  is-invalid"}
                defaultValue={addBirthParentModel.fatherAge}
                placeholder=""
                onFocus={handleOnBlur}
                maxLength={3}
                onInput={maxLengthCheck} 
                onKeyDown={e => (e.keyCode === 69) && e.preventDefault()}
              />
              {addBirthParentModel.fatherAgeError && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {addBirthParentModel.fatherAgeError}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="medical-group col-12 col-md-6">
              <label className="form-label">
                Highest level of formal education completed
              </label>
              <select
                {...register("fatherHighestLevelEducation")}
                className={
                  !errors.fatherHighestLevelEducation
                    ? "form-control mb-1 phone_border"
                    : "form-control mb-1 phone_border  is-invalid"
                }
                defaultValue={addBirthParentModel.fatherHighestLevelEducation}
                onChange={handleOnBlur}
              >
                <option value="0">None</option>
                {educationLevelModel.length > 0 &&
                  educationLevelModel.map((item, index) => (
                    <option
                      key={index}
                      selected={
                        item.value ==
                        (addBirthParentModel.fatherHighestLevelEducation == null
                          ? 0
                          : addBirthParentModel.fatherHighestLevelEducation.toString())
                      }
                      value={item.value}
                    >
                      {item.text}
                    </option>
                  ))}
              </select>
              <i className="fa fa-angle-down arrow"></i>
            </div>
            <div className="medical-group col-12 col-md-3 btn-select">
              <label className="form-label">Live with child full time?</label>
              <div className="btn-group btnAdopted">
                <button
                  type="button"
                  className={
                    addBirthParentModel.isFatherLiveWithChildFullTime == true ||
                    addBirthParentModel.isFatherLiveWithChildFullTime == null
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  onClick={() => liveWithChildFullTime(2, true)}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className={
                    addBirthParentModel.isFatherLiveWithChildFullTime == false
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                  }
                  onClick={() => liveWithChildFullTime(2, false)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
          <div className="row my-3">
            <div className="medical-group col-12 col-md-5 btn-select">
              <label className="form-label">Marital Status</label>
              <div className="btn-group btnGender">
                <button
                  type="button"
                  value={MaritalStatus.Married}
                  onClick={fatherMaritalStatus}
                  className={
                    addBirthParentModel.fatherMaritalStatus == MaritalStatus.Married
                    ? "btn btn-primary btn-sm btnGender_selected"
                    : "btn btn-outline-secondary btn-sm btnGender_status"
                  }
                >
                  Married
                </button>
                <button
                  type="button"
                  className={
                    addBirthParentModel.fatherMaritalStatus == MaritalStatus.Separeted
                    ? "btn btn-primary btn-sm btnGender_selected"
                    : "btn btn-outline-secondary btn-sm btnGender_status"
                  }
                  value={MaritalStatus.Separeted}
                  onClick={fatherMaritalStatus}
                >
                  Separated
                </button>
                <button
                  type="button"
                  className={
                    addBirthParentModel.fatherMaritalStatus == MaritalStatus.Widowed
                    ? "btn btn-primary btn-sm btnGender_selected"
                    : "btn btn-outline-secondary btn-sm btnGender_status"
                  }
                  value={MaritalStatus.Widowed}
                  onClick={fatherMaritalStatus}
                >
                  Widowed
                </button>
                <button
                  type="button"
                  className={
                    addBirthParentModel.fatherMaritalStatus == MaritalStatus.Divorced
                    ? "btn btn-primary btn-sm btnGender_selected"
                    : "btn btn-outline-secondary btn-sm btnGender_status"
                  }
                  value={MaritalStatus.Divorced}
                  onClick={fatherMaritalStatus}
                >
                  Divorced
                </button>

                <button
                  type="button"
                  className={
                    addBirthParentModel.fatherMaritalStatus == MaritalStatus.SingleOrNeverMarried
                    ? "btn btn-primary btn-sm btnGender_selected"
                    : "btn btn-outline-secondary btn-sm btnGender_status"
                  }
                  value={MaritalStatus.SingleOrNeverMarried}
                  onClick={fatherMaritalStatus}
                >
                  Single/Never Married
                </button>
                {addBirthParentModel.fatherMaritalStatusError && (
                  <div className="invalid-feedback" style={{ display: "block", textAlign: "left" }}>
                    {addBirthParentModel.fatherMaritalStatusError}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="medical-group col-6 col-md-3">
              <label className="form-label">Child Resides with</label>
              <select
                className={
                  !errors.childResidesWith
                    ? "form-control mb-1 phone_border"
                    : "form-control mb-1 phone_border  is-invalid"
                }
                // defaultValue={addBirthParentModel.childResidesWith}
                {...register("childResidesWith")}
                onChange={handleChildResidesWith}
              >
                <option value="0">Select</option>
                {childResideWithModel.length > 0 &&
                  childResideWithModel.map((item, index) => (
                    <option
                      key={index}
                      selected={
                        item.value ==
                        (addBirthParentModel.childResidesWith == null
                          ? 0
                          : addBirthParentModel.childResidesWith.toString())
                      }
                      value={item.value}
                    >
                      {item.text}
                    </option>
                  ))}
              </select>
              <i className="fa fa-angle-down arrow"></i>
              {addBirthParentModel.childResidesWithError && (
                <div className="invalid-feedback" style={{ display: "block", textAlign: "left" }}>
                  {addBirthParentModel.childResidesWithError}
                </div>
              )}
            </div>
            <div className="medical-group col-6 col-md-3">
              <label className="form-label">If other, please specify</label>
              <input
                type="text"
                className="form-control mb-1"
                defaultValue={addBirthParentModel.specifyChildResidesWith}
                placeholder=""
                {...register("specifyChildResidesWith")}
                onFocus={handleOnBlur}
                disabled={
                  addBirthParentModel.childResidesWith == null ||
                  addBirthParentModel.childResidesWith != 7
                }
              />{" "}
              {addBirthParentModel.specifyChildResidesWithError && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {addBirthParentModel.specifyChildResidesWithError}
                </div>
              )}
            </div>
            <div className="medical-group col-12 col-md-3 ">
              <label className="form-label">Contact with birth parent is</label>
              <select
                className={
                  !errors.contactWithBirthParent
                    ? "form-control mb-1 phone_border"
                    : "form-control mb-1 phone_border  is-invalid"
                }
                defaultValue={addBirthParentModel.contactWithBirthParent}
                {...register("contactWithBirthParent", {
                  required: "Please select contact with birth parent.",
                })}
                onChange={handleOnBlur}
              >
                <option value="0">None</option>
                {contactWithBirthParentModel.length > 0 &&
                  contactWithBirthParentModel.map((item, index) => (
                    <option
                      key={index}
                      selected={
                        item.value ==
                        (addBirthParentModel.contactWithBirthParent == null
                          ? 0
                          : addBirthParentModel.contactWithBirthParent.toString())
                      }
                      value={item.value}
                    >
                      {item.text}
                    </option>
                  ))}
              </select>
              {!errors.contactWithBirthParent && (
                <i className="fa fa-angle-down arrow"></i>
              )}
              
              {errors.contactWithBirthParent && (
                <div className="invalid-feedback">{errors.contactWithBirthParent?.message?.toString()}</div>
              )}
              {/* </Form.Item> */}
            </div>
          </div>
          <div className="medical__birth_formRow5">
            <div className="medical-group optional-field textarea_group">
              <label className="form-label">Comments</label>
              <span className="optional">Optional</span>
              <textarea
                {...register("comments")}
                className="form-control mb-1 textarea"
                defaultValue={addBirthParentModel.comments}
                onFocus={handleOnBlur}
                placeholder="Please provide any additional information."
              ></textarea>
            </div>
          </div>
        </div>

        <div className="btnContainer">
          {!props.isSaving && (
            <Button
              type="default"
              size="large"
              className="back_btn"
              onClick={() => props.parentTabChange(0)}
            >
              <i className="fa fa-long-arrow-left"></i> Back
            </Button>
          )}
          {!props.isSaving && (
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className="btn_add"
            >
              Save <i className="fa fa-long-arrow-right"></i>
            </Button>
          )}
          {props.isSaving && (
            <div style={{ height: "60px" }}>
              <Loader
                loading={props.isSaving}
                marginBottom="0px"
                marginTop="8px"
                width="368px"
              ></Loader>
            </div>
          )}
        </div>
      </div>
    </form>
  );
}
