import React, { Fragment } from "react";
import { HttpResponse } from "../../../../../../core";
import { BirthHistoryDetailModel } from "../../../../../../models/birthHistory/birth-history-detail.model";
import { FamilyBackgroundDetailModel } from "../../../../../../models/familyBackgroundDetail/family-background-detail.model";
import { BirthHistoryService } from "../../../../../../services/birthHistory/birth-history.service";
import BirthHistoryTabForm from "./birthHistoryTabForm";

interface IState {
  patientId: string;
  isLoading: boolean;
  birthHistoryDetailModel: BirthHistoryDetailModel;
}
export class BirthHistoryTab extends React.Component<any, IState> {
  private birthHistoryService: BirthHistoryService;

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.birthHistoryService = new BirthHistoryService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      patientId: this.props.patientId,
      birthHistoryDetailModel: {} as BirthHistoryDetailModel,
    };
    return initialState;
  }
  componentDidMount() {
    if (this.props.patientId != null) {
      this.loadData();
    }
  }

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }
  loadData = () => {
    this.setLoading(true);
    this.birthHistoryService
      .getBirthHistoryDetail(this.props.patientId)
      .then((res: HttpResponse<BirthHistoryDetailModel>) => {
        if (res && res.result) {
          this.setLoading(false);
          this.setState({
            birthHistoryDetailModel: res.result,
          });
        }
      }) 
      .catch(() => {
        this.setLoading(false);
      });
  };

  render() {
    return (
      <Fragment>
        <BirthHistoryTabForm
          patientId={this.props.patientId}
          isLoading={this.state.isLoading}
          birthHistoryDetail={this.state.birthHistoryDetailModel}
        />
      </Fragment>
    );
  }
}
