import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Heading from "../../../heading";
import { Button } from "antd";
import { ReferralConcernPostModel } from "../../../../models/referralConcern/referral-concern-post.model";
import { Loader } from "../../../../shared/loaders";
import { DevTool } from "@hookform/devtools";

interface IProps {
  referralConcernPostModel: ReferralConcernPostModel;
  onSubmit: Function;
  isSaving: boolean;
  patientId: string;
  assignModel: Function;
  isReadOnly: boolean;
  previousTabChange: Function;
}

export function ReferralConcernForm(props: IProps) {
  let referralConcernPostModel: ReferralConcernPostModel =
    props.referralConcernPostModel;
  useEffect(() => {
    setInitialValues();
  }, [props]);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    formState,
    setValue,
  } = useForm<ReferralConcernPostModel>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    // defaultValues: {
    //   reading: props.referralConcernPostModel.reading,
    //   oppositionalOrDefiant: props.referralConcernPostModel.oppositionalOrDefiant,
    //   poorPeerRelations: props.referralConcernPostModel.poorPeerRelations,
    //   spelling: props.referralConcernPostModel.spelling,
    //   hyperactive: props.referralConcernPostModel.hyperactive,
    //   anxiousness: props.referralConcernPostModel.anxiousness,
    //   math: props.referralConcernPostModel.math,
    //   aggressiveOrActingout: props.referralConcernPostModel.aggressiveOrActingout,
    //   withdrawnOrDepressed: props.referralConcernPostModel.withdrawnOrDepressed,
    //   handwriting: props.referralConcernPostModel.handwriting,
    //   impulsive: props.referralConcernPostModel.impulsive,
    //   inattentionOrDistractiability: props.referralConcernPostModel.inattentionOrDistractiability,
    //   speechOrLanguage: props.referralConcernPostModel.speechOrLanguage,
    //   disruptiveInGroups: props.referralConcernPostModel.disruptiveInGroups,
    //   lowSelfEsteem: props.referralConcernPostModel.lowSelfEsteem,
    //   visualPerceptualSkills: props.referralConcernPostModel.visualPerceptualSkills,
    //   grossMotorSkills: props.referralConcernPostModel.grossMotorSkills,
    //   awkwardOrClumsy: props.referralConcernPostModel.awkwardOrClumsy,
    //   writtenExpression: props.referralConcernPostModel.writtenExpression,
    //   fineMotorSkills: props.referralConcernPostModel.fineMotorSkills,
    //   lowFrustrationTolerance: props.referralConcernPostModel.lowFrustrationTolerance,
    //   comments: props.referralConcernPostModel.comments
    // },
  });
  //let referralConcernPostModel: ReferralConcernPostModel = props.referralConcernPostModel;
  console.log("Props: ", props); //todo:remove
  console.log("Component: ", referralConcernPostModel);
  const formSubmit = (data) => {
    console.log("FormSubmit: ", data);
    referralConcernPostModel.patientId = props.patientId;
    referralConcernPostModel.comments = data.comments;
    props.assignModel({ referralConcernPostModel: referralConcernPostModel });
    props.onSubmit({
      referralConcernPostModel: referralConcernPostModel,
      isAutoSave: false,
    });
  };

  const handleKeyUp = (event) => {
    setValuesInModel();
    if (event.key === "Tab") {
      saveDataOnTabChange();
    }
  };
  const handleOnFocus = (event) => {
    setValuesInModel();
    saveDataOnTabChange();
  };
  const saveDataOnTabChange = () => {
    if (!props.isSaving) {
      referralConcernPostModel.patientId = props.patientId;
      props.onSubmit({
        referralConcernPostModel: referralConcernPostModel,
        isAutoSave: true,
      });
    }
  };

  function setValuesInModel() {
    let model = getValues();
    console.log("Model: ", model);
    console.log(referralConcernPostModel);
    console.log("setValues Props: ", props);
    //referralConcernPostModel = Object.assign(referralConcernPostModel, model);
  }
  const isSelected = (type: number, event) => {
    console.log("isSelectedModel: ", referralConcernPostModel);
    console.log("isSelectedProps: ", props);
    switch (type) {
      case 1: {
        console.log("Event information: ", event.target.checked);
        console.log("type: ", type);
        referralConcernPostModel.reading = event.target.checked;
        console.log("updated: ", referralConcernPostModel.reading);
        break;
      }
      case 2: {
        referralConcernPostModel.oppositionalOrDefiant = event.target.checked;
        break;
      }
      case 3: {
        referralConcernPostModel.poorPeerRelations = event.target.checked;
        break;
      }
      case 4: {
        referralConcernPostModel.spelling = event.target.checked;
        break;
      }
      case 5: {
        referralConcernPostModel.hyperactive = event.target.checked;
        break;
      }
      case 6: {
        referralConcernPostModel.anxiousness = event.target.checked;
        break;
      }
      case 7: {
        referralConcernPostModel.math = event.target.checked;
        break;
      }
      case 8: {
        referralConcernPostModel.aggressiveOrActingout = event.target.checked;
        break;
      }
      case 9: {
        referralConcernPostModel.withdrawnOrDepressed = event.target.checked;
        break;
      }
      case 10: {
        referralConcernPostModel.handwriting = event.target.checked;
        break;
      }
      case 11: {
        referralConcernPostModel.impulsive = event.target.checked;
        break;
      }
      case 12: {
        referralConcernPostModel.inattentionOrDistractiability =
          event.target.checked;
        break;
      }
      case 13: {
        referralConcernPostModel.speechOrLanguage = event.target.checked;
        break;
      }
      case 14: {
        referralConcernPostModel.disruptiveInGroups = event.target.checked;
        break;
      }
      case 15: {
        referralConcernPostModel.lowSelfEsteem = event.target.checked;
        break;
      }
      case 16: {
        referralConcernPostModel.visualPerceptualSkills = event.target.checked;
        break;
      }
      case 17: {
        referralConcernPostModel.grossMotorSkills = event.target.checked;
        break;
      }
      case 18: {
        referralConcernPostModel.awkwardOrClumsy = event.target.checked;
        break;
      }
      case 19: {
        referralConcernPostModel.writtenExpression = event.target.checked;
        break;
      }
      case 20: {
        referralConcernPostModel.fineMotorSkills = event.target.checked;
        break;
      }
      case 21: {
        referralConcernPostModel.lowFrustrationTolerance = event.target.checked;
        break;
      }
      default: {
        break;
      }
    }
    setValuesInModel();
    props.assignModel({ referralConcernPostModel: referralConcernPostModel });
    saveDataOnTabChange();
  };
  function setInitialValues() {
    setValue("reading", props.referralConcernPostModel.reading);
    setValue(
      "oppositionalOrDefiant",
      props.referralConcernPostModel.oppositionalOrDefiant
    );
    setValue(
      "poorPeerRelations",
      props.referralConcernPostModel.poorPeerRelations
    );
    setValue("spelling", props.referralConcernPostModel.spelling);
    setValue("hyperactive", props.referralConcernPostModel.hyperactive);
    setValue("anxiousness", props.referralConcernPostModel.anxiousness);
    setValue("math", props.referralConcernPostModel.math);
    setValue(
      "aggressiveOrActingout",
      props.referralConcernPostModel.aggressiveOrActingout
    );
    setValue(
      "withdrawnOrDepressed",
      props.referralConcernPostModel.withdrawnOrDepressed
    );
    setValue("handwriting", props.referralConcernPostModel.handwriting);
    setValue("impulsive", props.referralConcernPostModel.impulsive);
    setValue(
      "inattentionOrDistractiability",
      props.referralConcernPostModel.inattentionOrDistractiability
    );
    setValue(
      "speechOrLanguage",
      props.referralConcernPostModel.speechOrLanguage
    );
    setValue(
      "disruptiveInGroups",
      props.referralConcernPostModel.disruptiveInGroups
    );
    setValue("lowSelfEsteem", props.referralConcernPostModel.lowSelfEsteem);
    setValue(
      "visualPerceptualSkills",
      props.referralConcernPostModel.visualPerceptualSkills
    );
    setValue(
      "grossMotorSkills",
      props.referralConcernPostModel.grossMotorSkills
    );
    setValue("awkwardOrClumsy", props.referralConcernPostModel.awkwardOrClumsy);
    setValue(
      "writtenExpression",
      props.referralConcernPostModel.writtenExpression
    );
    setValue("fineMotorSkills", props.referralConcernPostModel.fineMotorSkills);
    setValue(
      "lowFrustrationTolerance",
      props.referralConcernPostModel.lowFrustrationTolerance
    );
    setValue("comments", props.referralConcernPostModel.comments);
  }

  return (
    <div>
      <form onSubmit={handleSubmit(formSubmit)}>
        <div>
          <Heading heading="Referral Concerns" subHeading="" styleName="" />
          <p>
            Place a check next to each of the following that are areas of
            concern for your child.
          </p>

          <div className="form">
            <div className="row">
              <div className="medical-group col-12 col-md-3">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="reading"
                  //defaultChecked={referralConcernPostModel.reading}
                  {...register("reading")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(1, e)}
                />

                <label
                className={
                  referralConcernPostModel.reading ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="reading"
              >
                Reading</label>
              </div>
              <div className="medical-group col-12 col-md-3">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="oppositionalOrDefiant"
                  //defaultChecked={referralConcernPostModel.oppositionalOrDefiant}
                  {...register("oppositionalOrDefiant")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(2, e)}
                />
                <label
                className={
                  referralConcernPostModel.oppositionalOrDefiant ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="oppositionalOrDefiant"
              >
                Oppositional/defiant</label>
              </div>
              <div className="medical-group col-12 col-md-3">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="poorPeerRelations"
                  //defaultChecked={referralConcernPostModel.poorPeerRelations}
                  {...register("poorPeerRelations")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(3, e)}
                />
                <label
                className={
                  referralConcernPostModel.poorPeerRelations ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="poorPeerRelations"
              >
                Poor peer relations</label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-3">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="spelling"
                  //defaultChecked={referralConcernPostModel.spelling}
                  {...register("spelling")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(4, e)}
                />
                <label
                className={
                  referralConcernPostModel.spelling ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="spelling"
              >
                Spelling</label>
              </div>
              <div className="medical-group col-12 col-md-3">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="hyperactive"
                  //defaultChecked={referralConcernPostModel.hyperactive}
                  {...register("hyperactive")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(5, e)}
                />
                <label
                className={
                  referralConcernPostModel.hyperactive ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="hyperactive"
              >
                Hyperactive</label>
              </div>
              <div className="medical-group col-12 col-md-3">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="anxiousness"
                  //defaultChecked={referralConcernPostModel.anxiousness}
                  {...register("anxiousness")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(6, e)}
                />
                <label
                className={
                  referralConcernPostModel.anxiousness ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="anxiousness"
              >
                Anxiousness</label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-3">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="math"
                  //defaultChecked={referralConcernPostModel.math}
                  {...register("math")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(7, e)}
                />
                <label
                className={
                  referralConcernPostModel.math ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="math"
              >
                Math</label>
              </div>
              <div className="medical-group col-12 col-md-3">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="aggressiveOrActingout"
                  //defaultChecked={referralConcernPostModel.aggressiveOrActingout}
                  {...register("aggressiveOrActingout")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(8, e)}
                />
                <label
                className={
                  referralConcernPostModel.aggressiveOrActingout ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="aggressiveOrActingout"
              >
                Aggressive/Acting out</label>
              </div>
              <div className="medical-group col-12 col-md-3">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="withdrawnOrDepressed"
                  //defaultChecked={referralConcernPostModel.withdrawnOrDepressed}
                  {...register("withdrawnOrDepressed")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(9, e)}
                />
                <label
                className={
                  referralConcernPostModel.withdrawnOrDepressed ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="withdrawnOrDepressed"
              >
                Withdrawn/depressed</label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-3">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="handwriting"
                  //defaultChecked={referralConcernPostModel.handwriting}
                  {...register("handwriting")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(10, e)}
                />
                <label
                className={
                  referralConcernPostModel.handwriting ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="handwriting"
              >
                Handwriting</label>
              </div>
              <div className="medical-group col-12 col-md-3">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="impulsive"
                  //defaultChecked={referralConcernPostModel.impulsive}
                  {...register("impulsive")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(11, e)}
                />
                <label
                className={
                  referralConcernPostModel.impulsive ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="impulsive"
              >
                Impulsive</label>
              </div>
              <div className="medical-group col-12 col-md-3">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="inattentionOrDistractiability"
                  //defaultChecked={referralConcernPostModel.inattentionOrDistractiability}
                  {...register("inattentionOrDistractiability")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(12, e)}
                />
                <label
                className={
                  referralConcernPostModel.inattentionOrDistractiability ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="inattentionOrDistractiability"
              >
                Inattention/distractiability</label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-3">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="speechOrLanguage"
                  //defaultChecked={referralConcernPostModel.speechOrLanguage}
                  {...register("speechOrLanguage")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(13, e)}
                />
                <label
                className={
                  referralConcernPostModel.speechOrLanguage ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="speechOrLanguage"
              >
                Speech/language</label>
              </div>
              <div className="medical-group col-12 col-md-3">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="disruptiveInGroups"
                  //defaultChecked={referralConcernPostModel.disruptiveInGroups}
                  {...register("disruptiveInGroups")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(14, e)}
                />
                <label
                className={
                  referralConcernPostModel.disruptiveInGroups ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="disruptiveInGroups"
              >
                Disruptive in groups</label>
              </div>
              <div className="medical-group col-12 col-md-3">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="lowSelfEsteem"
                  //defaultChecked={referralConcernPostModel.lowSelfEsteem}
                  {...register("lowSelfEsteem")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(15, e)}
                />
                <label
                className={
                  referralConcernPostModel.lowSelfEsteem ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="lowSelfEsteem"
              >
                Low self-esteem</label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-3">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="visualPerceptualSkills"
                  //defaultChecked={referralConcernPostModel.visualPerceptualSkills}
                  {...register("visualPerceptualSkills")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(16, e)}
                />
                <label
                className={
                  referralConcernPostModel.visualPerceptualSkills ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="visualPerceptualSkills"
              >
                Visual perceptual skills</label>
              </div>
              <div className="medical-group col-12 col-md-3">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="grossMotorSkills"
                  //defaultChecked={referralConcernPostModel.grossMotorSkills}
                  {...register("grossMotorSkills")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(17, e)}
                />
                <label
                className={
                  referralConcernPostModel.grossMotorSkills ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="grossMotorSkills"
              >
                Gross motor skills</label>
              </div>
              <div className="medical-group col-12 col-md-3">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="awkwardOrClumsy"
                  //defaultChecked={referralConcernPostModel.awkwardOrClumsy}
                  {...register("awkwardOrClumsy")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(18, e)}
                />
                <label
                className={
                  referralConcernPostModel.awkwardOrClumsy ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="awkwardOrClumsy"
              >
                Awkward/clumsy</label>
              </div>
            </div>
            <div className="row">
              <div className="medical-group col-12 col-md-3">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="writtenExpression"
                  //defaultChecked={referralConcernPostModel.writtenExpression}
                  {...register("writtenExpression")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(19, e)}
                />
                <label
                className={
                  referralConcernPostModel.writtenExpression ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="writtenExpression"
              >
                Written expression</label>
              </div>
              <div className="medical-group col-12 col-md-3">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="fineMotorSkills"
                  //defaultChecked={referralConcernPostModel.fineMotorSkills}
                  {...register("fineMotorSkills")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(20, e)}
                />
                <label
                className={
                 referralConcernPostModel.fineMotorSkills ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="fineMotorSkills"
              >
                Fine motor skills</label>
              </div>
              <div className="medical-group col-12 col-md-3">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="lowFrustrationTolerance"
                  //defaultChecked={referralConcernPostModel.lowFrustrationTolerance}
                  {...register("lowFrustrationTolerance")}
                  onKeyUp={handleKeyUp}
                  onClick={(e) => isSelected(21, e)}
                />
                <label
                className={
                  referralConcernPostModel.lowFrustrationTolerance ===
                    true
                    ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                }
                htmlFor="lowFrustrationTolerance"
              >
                Low frustration tolerance</label>
              </div>
            </div>
            <div className="medical__birth_formRow5" style={{ width: "85%" }}>
              <div className="medical-group optional-field textarea_group">
                <label className="form-label">Comments</label>
                <span className="optional">Optional</span>
                <textarea
                  className="form-control textarea"
                  //defaultValue={referralConcernPostModel.comments}
                  {...register("comments")}
                  onFocus={handleOnFocus}
                  placeholder="Please provide any additional information."
                ></textarea>
              </div>
            </div>
          </div>
          <div className="btnContainer">
            {!props.isSaving && (
              <Button
                type="default"
                size="large"
                className="back_btn"
                onClick={() => props.previousTabChange(2)}
              >
                <i className="fa fa-long-arrow-left"></i> Back
              </Button>
            )}
            {!props.isSaving && (
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="btn_add"
              >
                Save <i className="fa fa-long-arrow-right"></i>
              </Button>
            )}
            {props.isSaving && (
              <div style={{ height: "60px" }}>
                <Loader
                  loading={props.isSaving}
                  marginBottom="0px"
                  marginTop="8px"
                  width="368px"
                ></Loader>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
