import React, { Fragment } from "react";
import { HttpResponse } from "../../../../core";
import { ShowSuccessMessage } from "../../../../shared/helpers";
import { MedicalHistoryService } from "../../../../services/medicalHistory";
import { MedicationPostModel } from "../../../../models/medicalHistory/medication-post.model";
import MedicationHistoryForm from "./medicationHistoryForm";
import { MedicationDetailModel } from "../../../../models/medicalHistory/medication-detail.model";

interface IState {
  patientId: string;
  isLoading: boolean;
  medicationModel: MedicationPostModel;
  reRenderForm: boolean;
  isFormSave?: Function;
  medicalHistoryStepUpdate?: Function;
}
export class MedicationHistory extends React.Component<any, IState> {
  private medicalHistoryService: MedicalHistoryService;

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.medicalHistoryService = new MedicalHistoryService();
  }

  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      medicationModel: {} as MedicationPostModel,
      patientId: this.props.patientId,
      reRenderForm: false,
    };
    return initialState;
  }
  componentDidMount() {
    this.loadData();
    window.scroll(0, 0);
  }

  postData = (data) => {
    let medicationPostData = data.medicationPostModel;
    if (this.state.isLoading) {
      return;
      }
      if (data.isAutoSave) {
          this.setLoading(false);
      }
      else {
          this.setLoading(true);
          
      }
    this.medicalHistoryService
      .postMedication(medicationPostData)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
          this.state.medicationModel.id=res.result;
        }
        if (!data.isAutoSave) {
          ShowSuccessMessage("Data Saved Successfully.");
          this.props.nextTabChange();
        }
        this.setLoading(false);
        this.props.isFormSave();
        if (!data.isAutoSave) {
          this.loadData(data.isAutoSave);
        }
      })
      .catch((ex) => {
        this.setLoading(false);
      });
  };
    loadData = (isAutoSave?: any) => {
        if (isAutoSave) {
            this.setLoading(false);
        }
        else {
            this.setLoading(true);
        }
    this.medicalHistoryService
      .getMedicalHistoryDetail(this.props.patientId)
      .then((res: HttpResponse<MedicationDetailModel>) => {
        this.setLoading(false);
        if (res && res.result) {
          this.props.medicalHistoryStepUpdate(1,res.result.id);
          if (isAutoSave) {
            let m = this.state.medicationModel;
            m.id = res.result.id;
            m.patientId = res.result.patientId;
            this.setState({
              medicationModel: m,
            });
          } else {
            this.setState({
              medicationModel: res.result,
            });
          }
        }
      });
  };
  assignModel = (data: any) => {
    this.setState(
      {
        medicationModel: data.medicationPostModel,
        reRenderForm: true,
      },
      () => this.setState({ reRenderForm: false })
    );
  };

  parentTabChange = (tab: any) => {
    this.props.parentTabChange(tab);
  };
  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }

  render() {
    return (
      <Fragment>
        {!this.state.reRenderForm && (
          <MedicationHistoryForm
            onSubmit={this.postData}
            isSaving={this.state.isLoading}
            patientId={this.props.patientId}
            assignModel={this.assignModel}
            medicationPostModel={this.state.medicationModel}
            parentTabChange={this.parentTabChange}
          />
        )}
      </Fragment>
    );
  }
}
