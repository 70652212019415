import React, { Component, Fragment } from "react";
import { UserDetailResponseModel } from "../components/auth/models";
import { AuthService } from "../components/auth/services/auth.service";
import DashboardTab from "../components/clinician/dashboard/DashboardTab";
import { HttpResponse } from "../core";

interface IState {
  username: string;
  loader: boolean;
  roles: string[];
}
export class DashboardPage extends Component<any, IState> {
  private service: AuthService;
  constructor(props) {
    super(props);
    this.service = new AuthService();
    this.state = {
      username: "",
      loader: false,
      roles: [],
    };
  }
  componentDidMount() {
    this.getUserDetail();
  }
  getUserDetail() {
    this.setState({
      loader: true,
    });
    this.service
      .getGetUserInfo()
      .then((res: HttpResponse<UserDetailResponseModel>) => {
        if (res && res.result) {
          this.setState({
            username: res.result.firstName + " " + res.result.lastName,
            roles: res.result.roles,
          });
        }
        this.setState({
          loader: false,
        });
      })
      .catch((ex) => {
        if (ex.status == 401) {
          //ShowException(ex.response.result ? ex.response.result : ex.response);
          localStorage.clear();
          window.location.href = window.location.origin + "/login";
        }
      });
  }
  render() {
    return (
      <Fragment>
        {!this.state.loader && (
          <DashboardTab
            username={this.state.username}
            roles={this.state.roles}
          />
        )}
      </Fragment>
    );
  }
}
