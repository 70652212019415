import React, { Component } from "react";
import { HttpResponse } from "../../../core";
import { TherapyService } from "../../../services/therapy/therapy.service";
import { GetTherapyStepStatus } from "../../patient/models/get-therapy-step-status.model";
import Tabs from "../../tabs";
import "../styles.css";
import { ChildTherapy } from "./childTherapy";
import { FamilyTherapy } from "./familyTherapy";

interface State {
  tab: number;
  patientId: string;
  isFormSave?: Function;
  getTherapyStepStatus?: GetTherapyStepStatus;
}
class MedicalTherapy extends Component<any, State> {
  private therapyService: TherapyService;
  state: State = { tab: 0, patientId: "" };
  changeTab(tab: any) {
    this.setState({
      tab,
    });
  }
  constructor(props) {
    super(props);
    this.therapyService = new TherapyService();
  }
  componentDidMount() {
    this.loadData();
  }
  loadData() {
    if (this.props.patientId) {
      this.therapyService
        .getTherapyStepStatus(this.props.patientId)
        .then((res: HttpResponse<GetTherapyStepStatus>) => {
          if (res && res.result) {
            console.log(res.result);
            this.setState({ getTherapyStepStatus: res.result });
            //this.getLastStep();
          }
        })
        .catch((ex) => {});
    }
  }
  therapyStepUpdateHandler = (key, value) => {
    if (this.state.getTherapyStepStatus) {
      if (key == 1) {
        this.state.getTherapyStepStatus.childTherapyId = value;
      } else if (key == 2) {
        this.state.getTherapyStepStatus.familyTherapyId = value;
      }
    }
  };
  render() {
    const { tab } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="medical__family_tabs d-none d-xl-block col-3">
            <Tabs
              styleName="familyTab"
              text="Child Therapy"
              steps=""
              stepsAct=""
              fullText="Child Therapy"
              familyTextName="A"
              image=""
              activeImage=""
              tabChange={() => this.changeTab(0)}
              selected={tab === 0}
              isData={this.state.getTherapyStepStatus?.childTherapyId != null}
            />
            <Tabs
              styleName="familyTab"
              text="Family Therapy"
              steps=""
              stepsAct=""
              fullText="Family Therapy"
              familyTextName="B"
              image=""
              activeImage=""
              tabChange={() => this.changeTab(1)}
              selected={tab === 1}
              isData={this.state.getTherapyStepStatus?.familyTherapyId != null}
            />
          </div>
          <div className="medical__family_content col">
            {tab === 0 && (
              <ChildTherapy
                parentTabChange={this.props.parentTab}
                patientId={this.props.patientId}
                nextTabChange={() => this.changeTab(1)}
                isFormSave={this.props.isFormSave}
                therapyStepUpdate={this.therapyStepUpdateHandler}
              />
            )}
            {tab === 1 && (
              <FamilyTherapy
                previousTabChange={() => this.changeTab(0)}
                patientId={this.props.patientId}
                nextTabChange={this.props.nextTabChange}
                isFormSave={this.props.isFormSave}
                therapyStepUpdate={this.therapyStepUpdateHandler}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default MedicalTherapy;
