import React, { Component } from 'react';
import { SummeryTab } from './components/summeryTabs';
import dataImage from "../../../../assets/images/color_icon/calendar-1.png";
import "../vsitSummary/index.scss";
import BaselineVisit from './components/baselineVisit';
import MidPointVisit from './components/midPointVisit';
import { PatientDetailModel } from '../../../patient/models/patient-detail.model';
import { ClinicianWeeklyData } from '../../../../models/clinicianWeeklyData/clinician-weekly-data.model';
import { AddPatientMedicationModel } from '../../../patient/models/add-patient-medication.model';
import { AppConfig, HttpResponse, HttpWrapper } from '../../../../core';
import { ClinicianWeeklyDataService } from '../../../../services/clinicianWeeklyData/clinician-weekly-data.service';
import moment from 'moment';
import { FinalReportDataService } from '../../../../services/report/report-data-service';
import { BaselineVisitReportSaveDataModel, DownloadBaselineVisitReportModel } from '../../../../models/report/visit-report-save-data';
import { PatientMedicationDetail } from '../../../patient/models/patient-medication-detail';
import { PatientMedicationService } from '../../../patient/services/patient.medication.service';
import { ShowSuccessMessage } from '../../../../shared/helpers';
import { AddendumData, severitySelectedData } from '../../../../models/report/final-report-save-data';
import { asBlob } from 'html-docx-js-typescript';
import { saveAs } from 'file-saver'
import { MidPointVisitReportSaveDataModel, DownloadMidPointVisitReportModel } from '../../../../models/report/mid-week-report-save-data';
import { PatientService } from '../../../patient/services';
import FinalReportVisit from './components/finalReportVisit';
import { FinalVisitReportSaveDataModel, DownloadFinalVisitReportModel } from '../../../../models/report/final-visit-report-save-data';
import { Loader } from '../../../../shared/loaders';
import dayjs from 'dayjs';

interface IState {
    active:number,
    patientId: string;
  patientDetail: PatientDetailModel;
  isReadOnly: boolean;
  clinicianWeeklyData: ClinicianWeeklyData;
  medication: AddPatientMedicationModel;
  baselineVisitreportModel:BaselineVisitReportSaveDataModel;
  baselineAddendumList:AddendumData[];
  isDownloading:boolean;
  isLoading: boolean;
  midWeekVisitReportModel: MidPointVisitReportSaveDataModel;
  week1DaysMedicationMissed: string[];
  week2DaysMedicationMissed: string[];
  week3DaysMedicationMissed: string[];
  midPointAddendumList: AddendumData[];
  medicationOptions: severitySelectedData[];
  selectedMedicationOptions: severitySelectedData;
  isMidPointReportDownloading: boolean;
  finalVisitReportModel: FinalVisitReportSaveDataModel;
  finalAddendumList: AddendumData[];
  isFinalReportDownloading: boolean;
  selectedOption: severitySelectedData[];
  isFormVisible: boolean;
  handleClickLink?: Function;
  baselineVisitDate?:string|Date|null;
  midPointVisitDate?:string|Date|null;
  finalVisitDate?:string|Date|null;
}
const rows = [{
    name: "",
    uv: null, pv: null
}];

export class VisitSummary extends Component<any, IState> {
    activeTab = 0;
    private clinicianWeeklyDataService: ClinicianWeeklyDataService;
    private finalReportDataService: FinalReportDataService;
    private patientMedicationService: PatientMedicationService;
    private patientService: PatientService;
    private wrapper: HttpWrapper;
    tabList = [
        {
            tabKey: 1,
            image:dataImage,
            title:'Baseline Visit',
            date:'10/10/2021'
        },
        {
            tabKey: 2,
            image:dataImage,
            title:'Mid Point Visit',
            date:'10/10/2021'
        },
        {
            tabKey: 3,
            image:dataImage,
            title:'Final Visit',
            date:'10/10/2021'
        }
    ]

    constructor(props) {
        super(props);
        this.clinicianWeeklyDataService = new ClinicianWeeklyDataService();
        this.finalReportDataService = new FinalReportDataService();
        this.patientMedicationService = new PatientMedicationService();
        this.wrapper = new HttpWrapper();
        this.patientService = new PatientService();
        this.state = {
            active:0,
            patientId: this.props.patientId,
            patientDetail: {} as PatientDetailModel,
            isReadOnly: false,
            clinicianWeeklyData: {} as ClinicianWeeklyData,
            medication: {} as AddPatientMedicationModel,
            baselineVisitreportModel:{} as BaselineVisitReportSaveDataModel,
            baselineAddendumList:[],
            isDownloading: false,
            isLoading: false,
            midWeekVisitReportModel: {} as MidPointVisitReportSaveDataModel,
            week1DaysMedicationMissed: [],
            week2DaysMedicationMissed: [],
            week3DaysMedicationMissed: [],
            midPointAddendumList: [],
            medicationOptions: [] as severitySelectedData[],
            selectedMedicationOptions: {} as severitySelectedData,
            isMidPointReportDownloading: false,
            finalVisitReportModel: {} as FinalVisitReportSaveDataModel,
            finalAddendumList: [],
            isFinalReportDownloading: false,
            selectedOption: [] as severitySelectedData[],
            isFormVisible: false,
        }
    }

    activeTabBox(item, event) {
        //alert(event);
        this.setState({
            active: item,
        });
     
        console.log(item);
    };
    componentWillMount() {
        //this.tick();
    };
    componentDidMount() {
        this.loadClinitianData();
        this.loadMedicationData();
        this.loadPatientBaselineReport();
        this.loadPatientMidPointReport();
        this.loadPatientFinalVisitReport();
        this.loadPatient();
        //setInterval(() => this.tick(), 1000);
    };

    downloadBaselineReportPDF = () => {
    
      this.setState({ isDownloading: true });
      let downloadModel: DownloadBaselineVisitReportModel = {
        
        patientId: this.state.patientId,
        
      };
  
      this.wrapper
        .getFileByPost(AppConfig.apiEndpoint + "report/visitreport/download", downloadModel)
          .then((res) => {
              this.downloadFileByStream(res, "BaselineVisitReport_" + this.state.patientDetail.lastName + "_" + this.state.patientDetail.firstName + ".pdf");
          this.setState({ isDownloading: false });
        });
    };
    downloadBaselineReport=(format:number)=>{
      if(format==1){
this.downloadBaselineReportPDF();
      }
      else{
this.downloadBaselineWord();
      }
    }
    downloadBaselineWord=()=>{
      this.setState({ isDownloading: true });
      let downloadModel: DownloadBaselineVisitReportModel = {
        
        patientId: this.state.patientId,
        
      };
      this.finalReportDataService
      .getBaslineReportHtml(downloadModel)
      .then((res: HttpResponse<string>) => {
        if (res && res.result) {
          
          this.setState({ isDownloading: false });
          asBlob(res.result,{margins: {top: 720,bottom:720,left:720,right:720}}).then(data => {
                saveAs(data, "BaselineVisitReport_" + this.state.patientDetail.lastName + "_" + this.state.patientDetail.firstName + ".docx") // save as docx file
          }) 
        }
      })
      .catch(() => {
        
      });
    }
    downloadFileByStream(result: any, filename: string): void {
      var file = new Blob([result], {
        type: "application/octet-binary",
      });
      var fileUrl = URL.createObjectURL(file);
      // for IE 10+
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file, filename);
      } else {
        var element = document.createElement("a");
        element.href = fileUrl;
        element.setAttribute("download", filename);
        element.setAttribute("target", "_blank");
        document.body.appendChild(element); //Append the element to work in firefox
        element.click();
      }
    }
    baselinYesNoSelectionHandler = (type,selection) => {
      
      switch (type) {
        case 1: {
        this.state.baselineVisitreportModel.placeboPresent=selection;
        break;
        }
        case 2: {
        this.state.baselineVisitreportModel.teacherPacketGiven=selection;
        break;
        }
        case 3: {
        this.state.baselineVisitreportModel.receivedPotentialSideeffects=selection;
        break;
        }
        case 4: {
        this.state.baselineVisitreportModel.receivedTrialProcedures=selection;
        break;
        }
        case 5: {
        this.state.baselineVisitreportModel.parentInstructed=selection;
        break;
        }
        case 6: {
        this.state.baselineVisitreportModel.trialStyle=selection;
        break;
        }
        }
      this.setState({baselineVisitreportModel:this.state.baselineVisitreportModel});
    };
    assignBaselineModel = (data:BaselineVisitReportSaveDataModel) => {
      this.setState({baselineVisitreportModel:data});
    }
    saveBaselineReport = (data:BaselineVisitReportSaveDataModel,isSaved:boolean) => {
      
      if(isSaved)
      {
      this.state.baselineVisitreportModel.isSaved=true;  
      this.state.baselineVisitreportModel.pateintId=this.state.patientId;
      this.setState({baselineVisitreportModel:this.state.baselineVisitreportModel});
        this.finalReportDataService
        .postBaselineVisitReportData(this.state.baselineVisitreportModel)
        .then((res: HttpResponse<string>) => {
          if (res && res.result) {
              this.setLoading(true);
              this.setLoading(false);
            //ShowSuccessMessage("Data Saved Successfully.");
          }
        })
        .catch(() => {
          
        });
      }else{
        this.setState({baselineVisitreportModel:data}); 
        this.finalReportDataService
        .postBaselineVisitReportData(data)
        .then((res: HttpResponse<string>) => {
          if (res && res.result) {
            
            
            ShowSuccessMessage("Data Saved Successfully.");
          }
        })
        .catch(() => {
          
        });
      }
    };

    loadPatientBaselineReport() {
        this.setLoading(true);
      if (this.state.patientId != undefined) {
        this.finalReportDataService
          .getBaselineVisitReportData(this.state.patientId)
          .then((res: HttpResponse<BaselineVisitReportSaveDataModel>) => {
            if (res && res.result) {
              
              this.setState({
                baselineVisitreportModel: res.result,
                baselineAddendumList:res.result.addendums,
              });
              
            }
            this.setLoading(false);
          })
          .catch(() => {
            this.setLoading(false);
          });
      }
    }

    loadClinitianData = () => {
        
        if (this.state.patientId != undefined) {
          this.clinicianWeeklyDataService
            .getClinicianWeeklyData(this.state.patientId)
            .then((res: HttpResponse<ClinicianWeeklyData>) => {
                if (res && res.result && res.result.lstClinicianSideEffectsWeeklyData != null) {
                    this.setState({
                        clinicianWeeklyData: res.result,
                        midPointVisitDate:res.result.lstPatientVisitDetails?.filter(c=>c.visitNo==2)[0].visitDate,
                        baselineVisitDate:res.result.lstPatientVisitDetails?.filter(c => c.visitNo == 1)[0].visitDate,
                        finalVisitDate:res.result.lstPatientVisitDetails?.filter(c => c.visitNo == 3)[0].visitDate,
                    });
                    this.setState({
                        isFormVisible: true
                    });

                }
                else {
                    this.setState({
                        isFormVisible: false
                    });
                }
                
            })
            .catch(() => {
                
            });
        }
    };

    loadMedicationData() {
        if (this.state.patientId != undefined) {
          this.patientMedicationService
            .getmedicationByPatientId(this.state.patientId)
            .then((res: HttpResponse<PatientMedicationDetail>) => {
              if (res && res.result) {
                let medication: AddPatientMedicationModel = {
                  isActive: res.result.isActive,
                  patientId: res.result.patientId,
                  patientTrialId: res.result.patientTrialId,
                  trialNoOfWeeks: res.result.trialNoOfWeeks,
                  trialStartDate: res.result.trialStartDate,
                  trialEndDate: res.result.trialEndDate,
                  createPatientVisitModels: res.result.patientVisitDetails,
                  createPatientTrialWeekModels: res.result.patientTrialWeekDetails,
                  diagnosisCodes: res.result.patientTrialDiagnosisCodes,
                  patientTrialStyle: res.result.patientTrialStyle,
                };
                  let medOptions = [] as severitySelectedData[];
                  medication.createPatientTrialWeekModels.map((c) => {
                      let value = "";
                      if (c.createPatientTrailWeeklyMedicationModel?.medicationFamily != ""
                          && c.createPatientTrailWeeklyMedicationModel?.medicationFamily != "Placebo") {
                          if (c.createPatientTrailWeeklyMedicationModel.medicationFamily == "Placebo") {
                              value = c.createPatientTrailWeeklyMedicationModel.medicationFamily;
                          } else {
                              if (c.createPatientTrailWeeklyMedicationModel.medicationType == "Generic") {
                                  value =
                                      c.createPatientTrailWeeklyMedicationModel.medicationFamily +
                                      " (" +
                                      c.createPatientTrailWeeklyMedicationModel.medicationGenericName +
                                      " - " + c.createPatientTrailWeeklyMedicationModel.medicationDosage +
                                      ")";
                              }
                              else {
                                  value =
                                      c.createPatientTrailWeeklyMedicationModel.medicationFamily +
                                      " (" +
                                      c.createPatientTrailWeeklyMedicationModel.medicationBrandName +
                                      " - " + c.createPatientTrailWeeklyMedicationModel.medicationDosage +
                                      ")";
                              }

                          }
                          if (!medOptions.some((c) => c.label == value)) {
                              medOptions.push({
                                  value: value,
                                  label: value,
                              });
                          }
                      }
                  });
                  medOptions.push({
                    value: "Medication Failure",
                    label: "no benefit was observed"
                  });
                  this.setState({ medicationOptions: medOptions });
                
                this.setState({
                  medication: medication,
                });
              }
            })
            .catch((ex) => {
              
            });
        }
      }
      addBaselineAddendumClick=(addendum)=>{
        var addendumData: AddendumData = {
          addendum : addendum,
          pateintId : this.state.patientId,
          addedOn : null
        };
        this.finalReportDataService
          .postBaselineVisitReportAddendumData(addendumData)
          .then((res: HttpResponse<AddendumData>) => {
            if (res && res.result) {
               this.state.baselineAddendumList.unshift(res.result);
              // this.setState({ isShowModel: false });
              this.setState({ 
                baselineAddendumList: this.state.baselineAddendumList,
                
               });
              ShowSuccessMessage("Data Saved Successfully.");
            }
          })
          .catch(() => {
            //this.setLoading(false);
          });
      }
    private setLoading(loading: boolean) {
        this.setState({
            isLoading: loading,
        });
    }
 
    midWeekYesNoSelectionHandler = (type, selection) => {
        
        switch (type) {
            case 1: {
                this.state.midWeekVisitReportModel.placeboPresent = selection;
                break;
            }
            case 2: {
                this.state.midWeekVisitReportModel.isTeacherDataReceived = selection;
                break;
            }
            case 3: {
                this.state.midWeekVisitReportModel.trialStyle = selection;
                break;
            }
        }
        this.setState({ midWeekVisitReportModel: this.state.midWeekVisitReportModel });
    };
    addWeekDaysMedication = (type, day,check) => {
        if (type == "week1DaysMissed") {
            if (check) {
                this.state.week1DaysMedicationMissed.push(day);
            }
            else {
                for (var index = this.state.week1DaysMedicationMissed.length - 1; index >= 0; index--) {
                    if (this.state.week1DaysMedicationMissed[index] === day) {
                        this.state.week1DaysMedicationMissed.splice(index, 1);
                    }
                }
            }
            this.state.midWeekVisitReportModel.week1DaysMedicationMissed = this.state.week1DaysMedicationMissed;
            this.setState({ midWeekVisitReportModel: this.state.midWeekVisitReportModel });
        }
        else if (type == "week2DaysMissed") {
            if (check) {
                this.state.week2DaysMedicationMissed.push(day);
            }
            else {
                for (var index = this.state.week2DaysMedicationMissed.length - 1; index >= 0; index--) {
                    if (this.state.week2DaysMedicationMissed[index] === day) {
                        this.state.week2DaysMedicationMissed.splice(index, 1);
                    }
                }
            }
            this.state.midWeekVisitReportModel.week2DaysMedicationMissed = this.state.week2DaysMedicationMissed;
            this.setState({ midWeekVisitReportModel: this.state.midWeekVisitReportModel });
        }
        else if (type == "week3DaysMissed") {
            if (check) {
                this.state.week3DaysMedicationMissed.push(day);
            }
            else {
                for (var index = this.state.week3DaysMedicationMissed.length - 1; index >= 0; index--) {
                    if (this.state.week3DaysMedicationMissed[index] === day) {
                        this.state.week3DaysMedicationMissed.splice(index, 1);
                    }
                }
            }
            this.state.midWeekVisitReportModel.week3DaysMedicationMissed = this.state.week3DaysMedicationMissed;
            this.setState({ midWeekVisitReportModel: this.state.midWeekVisitReportModel });
        }
    }
    assignMidPointModel = (data: MidPointVisitReportSaveDataModel) => {
        this.setState({ midWeekVisitReportModel: data });
    }
    saveMidPointReport = (data: MidPointVisitReportSaveDataModel, isSaved: boolean) => {
      
        if (isSaved) {
            this.state.midWeekVisitReportModel.isSaved = true; 
            this.state.midWeekVisitReportModel.patientId=this.state.patientId;
            //this.state.midWeekVisitReportModel.week1DaysMedicationMissed = this.state.week1DaysMedicationMissed;
            //this.state.midWeekVisitReportModel.week2DaysMedicationMissed = this.state.week2DaysMedicationMissed;
            //this.state.midWeekVisitReportModel.week3DaysMedicationMissed = this.state.week3DaysMedicationMissed;
            this.state.midWeekVisitReportModel.medicationResponse = this.state.selectedMedicationOptions;
            this.setState({ midWeekVisitReportModel: this.state.midWeekVisitReportModel });
            this.finalReportDataService
                .postMidPointVisitReportData(this.state.midWeekVisitReportModel)
                .then((res: HttpResponse<string>) => {
                    if (res && res.result) {
                        this.setLoading(true);
                        this.setLoading(false);
                    }
                })
                .catch(() => {

                });
        } else {
            this.setState({
                midWeekVisitReportModel: data
            });
            //data.week1DaysMedicationMissed = this.state.week1DaysMedicationMissed;
            //data.week2DaysMedicationMissed = this.state.week2DaysMedicationMissed;
            //data.week3DaysMedicationMissed = this.state.week3DaysMedicationMissed;
            data.medicationResponse = this.state.selectedMedicationOptions;
            this.finalReportDataService
                .postMidPointVisitReportData(data)
                .then((res: HttpResponse<string>) => {
                    if (res && res.result) {


                        ShowSuccessMessage("Data Saved Successfully.");
                    }
                })
                .catch(() => {

                });
        }
    };
    addMidPointAddendumClick = (addendum) => {
        var addendumData: AddendumData = {
            addendum: addendum,
            pateintId: this.state.patientId,
            addedOn: null
        };
        this.finalReportDataService
            .postMidPointVisitReportAddendumData(addendumData)
            .then((res: HttpResponse<AddendumData>) => {
                if (res && res.result) {
                    this.state.midPointAddendumList.unshift(res.result);
                    // this.setState({ isShowModel: false });
                    this.setState({
                        midPointAddendumList: this.state.midPointAddendumList,

                    });
                    ShowSuccessMessage("Data Saved Successfully.");
                }
            })
            .catch(() => {
                //this.setLoading(false);
            });
    }
    loadPatientMidPointReport() {
        if (this.state.patientId != undefined) {
            this.finalReportDataService
                .getMidPointVisitReportData(this.state.patientId)
                .then((res: HttpResponse<MidPointVisitReportSaveDataModel>) => {
                    if (res && res.result) {

                        this.setState({
                            midWeekVisitReportModel: res.result,
                            midPointAddendumList: res.result.addendums,
                            selectedMedicationOptions: res.result.medicationResponse,
                            week1DaysMedicationMissed: res.result.week1DaysMedicationMissed,
                            week2DaysMedicationMissed: res.result.week2DaysMedicationMissed,
                            week3DaysMedicationMissed: res.result.week3DaysMedicationMissed
                            
                        });
                        console.log(this.state.selectedMedicationOptions)
                    }
                })
                .catch(() => {

                });
        }
    }
    downloadMidPointReport = (format: number) => {
        if (format == 1) {
            this.downloadMidPointReportPDF();
        }
        else {
            this.downloadMidPointWord();
        }
    }
    downloadMidPointReportPDF = () => {

        this.setState({ isMidPointReportDownloading: true });
        let downloadModel: DownloadMidPointVisitReportModel = {

            patientId: this.state.patientId,

        };

        this.wrapper
            .getFileByPost(AppConfig.apiEndpoint + "report/visitreport/midpoint/download", downloadModel)
            .then((res) => {
                this.downloadFileByStream(res, "MidPointVisitReport_" + this.state.patientDetail.lastName + "_" + this.state.patientDetail.firstName +".pdf");
                this.setState({ isMidPointReportDownloading: false });
            });
    };
    downloadMidPointWord = () => {
        this.setState({ isMidPointReportDownloading: true });
        let downloadModel: DownloadMidPointVisitReportModel = {

            patientId: this.state.patientId,

        };
        this.finalReportDataService
            .getMidPointReportHtml(downloadModel)
            .then((res: HttpResponse<string>) => {
                if (res && res.result) {

                    this.setState({ isMidPointReportDownloading: false });
                    asBlob(res.result,{margins: {top: 720,bottom:720,left:720,right:720}}).then(data => {
                        saveAs(data, "MidPointVisitReport_" + this.state.patientDetail.lastName + "_" + this.state.patientDetail.firstName +".docx") // save as docx file
                    })
                }
            })
            .catch(() => {

            });
    }
    loadPatient() {
        if (this.state.patientId != undefined) {
            this.patientService
                .getUserById(this.state.patientId)
                .then((res: HttpResponse<PatientDetailModel>) => {
                    if (res && res.result) {

                        this.setState({
                            patientDetail: res.result,
                        });
                    }
                })
                .catch(() => {
                    this.setLoading(false);
                });
        }
    }
    handleMedicationChange = (selectedOption) => {
        this.setState({ selectedMedicationOptions: selectedOption });
    };
    assignFinalModel = (data: FinalVisitReportSaveDataModel) => {
        this.setState({ finalVisitReportModel: data });
    }
    saveFinalVisitReport = (data: FinalVisitReportSaveDataModel, isSaved: boolean) => {
        
        if (isSaved) {
            this.state.finalVisitReportModel.isSaved = true;
            this.state.finalVisitReportModel.patientId = this.state.patientId;
            this.state.finalVisitReportModel.medicationResponse = this.state.selectedMedicationOptions;
            if (this.state.selectedOption?.length > 0) {
                this.state.finalVisitReportModel.severity = this.state.selectedOption;
            }
            this.setState({ finalVisitReportModel: this.state.finalVisitReportModel });
            this.finalReportDataService
                .postFinalVisitReportData(this.state.finalVisitReportModel)
                .then((res: HttpResponse<string>) => {
                    if (res && res.result) {
                        this.setLoading(true);
                        this.setLoading(false);
                        //ShowSuccessMessage("Data Saved Successfully.");
                    }
                })
                .catch(() => {

                });
        } else {

            data.medicationResponse = this.state.selectedMedicationOptions;
            if (this.state.selectedOption?.length > 0) {
                data.severity = this.state.selectedOption;
            }
            this.setState({ finalVisitReportModel: data });
            this.finalReportDataService
                .postFinalVisitReportData(data)
                .then((res: HttpResponse<string>) => {
                    if (res && res.result) {


                        ShowSuccessMessage("Data Saved Successfully.");
                    }
                })
                .catch(() => {

                });
        }
    };
    addFinalAddendumClick = (addendum) => {
        var addendumData: AddendumData = {
            addendum: addendum,
            pateintId: this.state.patientId,
            addedOn: null
        };
        this.finalReportDataService
            .postFinalVisitReportAddendumData(addendumData)
            .then((res: HttpResponse<AddendumData>) => {
                if (res && res.result) {
                    this.state.finalAddendumList.unshift(res.result);
                    // this.setState({ isShowModel: false });
                    this.setState({
                        finalAddendumList: this.state.finalAddendumList,

                    });
                    ShowSuccessMessage("Data Saved Successfully.");
                }
            })
            .catch(() => {
                //this.setLoading(false);
            });
    }
    loadPatientFinalVisitReport() {
        if (this.state.patientId != undefined) {
            this.finalReportDataService
                .getFinalVisitReportData(this.state.patientId)
                .then((res: HttpResponse<FinalVisitReportSaveDataModel>) => {
                    if (res && res.result) {

                        this.setState({
                            finalVisitReportModel: res.result,
                            finalAddendumList: res.result.addendums,
                            selectedMedicationOptions: res.result.medicationResponse,
                            selectedOption: res.result?.severity

                        });
                        console.log(this.state.selectedMedicationOptions)
                    }
                })
                .catch(() => {

                });
        }
    }
    downloadFinalReport = (format: number) => {
        if (format == 1) {
            this.downloadFinalVisitReportPDF();
        }
        else {
            this.downloadFinalVisitWord();
        }
    }
    downloadFinalVisitReportPDF = () => {

        this.setState({ isFinalReportDownloading: true });
        let downloadModel: DownloadFinalVisitReportModel = {

            patientId: this.state.patientId,

        };

        this.wrapper
            .getFileByPost(AppConfig.apiEndpoint + "report/visitreport/final/download", downloadModel)
            .then((res) => {
                this.downloadFileByStream(res, "FinalVisitReport_" + this.state.patientDetail.lastName + "_" + this.state.patientDetail.firstName + ".pdf");
                this.setState({ isFinalReportDownloading: false });
            });
    };
    downloadFinalVisitWord = () => {
        this.setState({ isFinalReportDownloading: true });
        let downloadModel: DownloadFinalVisitReportModel = {

            patientId: this.state.patientId,

        };
        this.finalReportDataService
            .getFinalVisitReportHtml(downloadModel)
            .then((res: HttpResponse<string>) => {
                if (res && res.result) {

                    this.setState({ isFinalReportDownloading: false });
                    asBlob(res.result,{margins: {top: 720,bottom:720,left:720,right:720}}).then(data => {
                        saveAs(data, "FinalVisitReport_" + this.state.patientDetail.lastName + "_" + this.state.patientDetail.firstName +".docx") // save as docx file
                    })
                }
            })
            .catch(() => {

            });
    }
   finalYesNoSelectionHandler = (type, selection) => {
        
        switch (type) {
            case 1: {
                this.state.finalVisitReportModel.placeboPresent = selection;
                break;
            }
            case 2: {
                this.state.finalVisitReportModel.isTeacherDataReceived = selection;
                break;
            }
            case 3: {
                this.state.finalVisitReportModel.trialStyle = selection;
                break;
            }
            case 4: {
                this.state.finalVisitReportModel.receivedMedicationPlan = selection;
                break;
            }
        }
       this.setState({ finalVisitReportModel: this.state.finalVisitReportModel });
    };

    handleSeverityChange = (selectedOption) => {
        this.setState({ selectedOption: selectedOption });
    };
    handleClickLink = () => {
        this.props.handleClickLink(this.state.patientId);
    }
    render() {
        return (
            <div>

                {!this.state.isLoading && (
                    <div className="text-center">
                        {!this.state.isFormVisible && (
                            <h4 className="weekly__msg">
                                You will see the visit summary for this patient only after the medication information is added for him.
                                <br />
                            Click on <a className="link-text" onClick={() => this.handleClickLink()} href={void (0)}><u>link</u></a> to complete the medication information.
                            </h4>
                        )}
                    </div>
                )}

                {!this.state.isLoading && this.state.isFormVisible && (
                    <div className="row">
                        <div className="col-2 pr-0">
                            {this.state.clinicianWeeklyData?.lstPatientVisitDetails?.map((item, index) => (
                                <div className={index === this.state.active ? 'card-view active' : 'card-view'} onClick={e => this.activeTabBox(index, e)} key={index}>
                                    <img alt="img" width="18" src={dataImage} />
                                    {item.visitNo == 1 && <h4>Baseline Visit</h4>}
                                    {item.visitNo == 2 && <h4>Mid Point Visit</h4>}
                                    {item.visitNo == 3 && <h4>Final Visit</h4>}
                                    <p>{item.visitDate == null ? "" : dayjs(item.visitDate).format("MM/DD/YYYY")}</p>
                                </div>
                                // <SummeryTab handleClickLink={this.tick} key={index} tabKey={item.tabKey} activeClass={this.activeTab} image={item.image} title={item.title} date={item.date} />
                            ))}
                        </div>
                        <div className="col-10 pt-3 border">
                            {this.state.active === 0 && !this.state.isLoading &&
                                <BaselineVisit patientId={this.state.patientId}
                                    visitDate={(this.state.baselineVisitDate == null) ? "" : dayjs(this.state.baselineVisitDate).format("MM/DD/YYYY")}
                                    baselineVisitreportModel={this.state.baselineVisitreportModel}
                                    saveClick={this.saveBaselineReport}
                                    medication={this.state.medication}
                                    yesNoSelectionChanged={this.baselinYesNoSelectionHandler}
                                    downloadReport={this.downloadBaselineReport}
                                    addAddendumClick={this.addBaselineAddendumClick}
                                    addendumList={this.state.baselineAddendumList}
                                    assignModel={this.assignBaselineModel}
                                    isDownloading={this.state.isDownloading}
                                    clinicianWeeklyData={this.state.clinicianWeeklyData}
                                />
                            }
                            {this.state.active === 1 &&
                                <MidPointVisit patientId={this.state.patientId}
                                    visitDate={(this.state.midPointVisitDate) == null?"":dayjs(this.state.midPointVisitDate).format("MM/DD/YYYY")}
                                    midWeekVisitReportModel={this.state.midWeekVisitReportModel}
                                    medication={this.state.medication}
                                    saveClick={this.saveMidPointReport}
                                    clinicianWeeklyData={this.state.clinicianWeeklyData}
                                    yesNoSelectionChanged={this.midWeekYesNoSelectionHandler}
                                    addAddendumClick={this.addMidPointAddendumClick}
                                    addendumList={this.state.midPointAddendumList}
                                    addWeekDaysMedication={this.addWeekDaysMedication}
                                    downloadReport={this.downloadMidPointReport}
                                    patientDetail={this.state.patientDetail}
                                    medicationOptions={this.state.medicationOptions}
                                    selectedMedicationOptions={this.state.selectedMedicationOptions}
                                    handleMedicationChange={this.handleMedicationChange}
                                    isDownloading={this.state.isMidPointReportDownloading}
                                    assignModel={this.assignMidPointModel}
                                />
                            }
                            {this.state.active === 2 &&
                                <FinalReportVisit patientId={this.state.patientId}
                                    visitDate={this.state.finalVisitDate == null ? "" : dayjs(this.state.finalVisitDate).format("MM/DD/YYYY")}
                                    finalVisitReportModel={this.state.finalVisitReportModel}
                                    medication={this.state.medication}
                                    saveClick={this.saveFinalVisitReport}
                                    clinicianWeeklyData={this.state.clinicianWeeklyData}
                                    yesNoSelectionChanged={this.finalYesNoSelectionHandler}
                                    addAddendumClick={this.addFinalAddendumClick}
                                    addendumList={this.state.finalAddendumList}
                                    downloadReport={this.downloadFinalReport}
                                    patientDetail={this.state.patientDetail}
                                    medicationOptions={this.state.medicationOptions}
                                    selectedMedicationOptions={this.state.selectedMedicationOptions}
                                    handleMedicationChange={this.handleMedicationChange}
                                    isDownloading={this.state.isFinalReportDownloading}
                                    assignModel={this.assignFinalModel}
                                    selectedOption={this.state.selectedOption}
                                    handleSeverityChange={this.handleSeverityChange}
                                />
                            }


                        </div>
                    </div>)}
                {<Loader loading={this.state.isLoading} />}
            </div>
        );
    }
}