import React from "react";
import { DevelopmentalProfileDetailModel } from "../../../../../../models/developmentProfile/developmentProfileDetail/development-profile-detail.model";
import ChildGuardianInfo from "../../../../../childGuardianInfo";
import Heading from "../../../../../heading";
import { Loader } from "../../../../../../shared/loaders";
import { ActivityLevel } from "../../../../../../shared/enums/childTemperament/activity-level.enum";
import { ApproachOrWithDrawl } from "../../../../../../shared/enums/childTemperament/approach.enum";
import { AttentionSpanAndPersistence } from "../../../../../../shared/enums/childTemperament/attention-span-and-persistence.enum";
import { Distractibility } from "../../../../../../shared/enums/childTemperament/distractibility.enum";
import { QualityOfMood } from "../../../../../../shared/enums/childTemperament/quality-of-mood.enum";
import { IntensityOfReaction } from "../../../../../../shared/enums/childTemperament/Intensity-of-reaction.enum";
import { Adaptability } from "../../../../../../shared/enums/childTemperament/adaptability.enum";
import { Rhythmicity } from "../../../../../../shared/enums/childTemperament/rhythmicity.enum";
import { ThresholdOfResponsiveness } from "../../../../../../shared/enums/childTemperament/threshold-of-responsiveness.enum";
interface IProps {
  patientId: string;
  isLoading: boolean;
  developmentalProfileDetailModel: DevelopmentalProfileDetailModel;
}
const checkIfNullOrEmpty = (val: any) => {
  let data = "-";
  if (val == null || val == undefined || val == "") {
    data = "-";
  } else {
    data = val;
  }
  return data;
};
const checkIfNullOrEmptyForMonth = (val: any) => {
  let data = "";
  if (val == null || val == undefined || val == "") {
    data = "";
  } else {
    data = val;
  }
  return data;
};

const getData = (val1: any, val2: any) => {
  let data = "-";
  if (val1 == null || val1 == undefined) {
    data = "-";
  } else {
    data = val1 + " " + val2;
  }
  return data;
};

export default function DevelopmentProfileViewForm(props: IProps) {
  let developmentalProfileDetailModel = props.developmentalProfileDetailModel;
  return (
    <div className="region_table">
      {!props.isLoading && (
        <div>
          <Heading
            heading="Child Temperament"
            subHeading=""
            styleName="medicalGeneralDetail__style"
          />
          {/* additional design */}
          <div className="learning__table childDev__table">
            <ChildGuardianInfo
              designation="Activity level"
              name="Infant's/toddler's general level of activity was:"
              email=""
              guardian="guardianStyle"
            />
            <div className="medical-group btn-select dev_table__btn">
              <div className="btn-addon btnGender">
                <button
                  type="button"
                  
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.activityLevel == ActivityLevel.HighActivity
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_status"
                  }
                >
                  1 High activity
                </button>
                <button
                  type="button"
                  
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.activityLevel == 2
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_unselected"
                  }
                >
                  2
                </button>
                <button
                  type="button"
                  
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.activityLevel == ActivityLevel.LowActivity
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_others"
                  }
                >
                  3 Low activity
                </button>
              </div>
            </div>
          </div>
          <div className="learning__table childDev__table">
            <ChildGuardianInfo
              designation="Rhythmicity (regularity)"
              name="Infant's/toddler's established routines for eating, sleeping and toileting were:"
              email=""
              guardian="guardianStyle"
            />
            <div className="medical-group btn-select dev_table__btn">
              <div className="btn-addon btnGender">
                <button
                  type="button"
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.rhythmicity == Rhythmicity.RegularRoutines
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_status"
                  }
                >
                  1 Regular routines
                </button>
                <button
                  type="button"
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.rhythmicity == 2
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_unselected"
                  }
                >
                  2
                </button>
                <button
                  type="button"
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.rhythmicity == Rhythmicity.IrregularRoutines
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_others"
                  }
                >
                  3 Irregular routines
                </button>
              </div>
            </div>
          </div>
          <div className="learning__table childDev__table">
            <ChildGuardianInfo
              designation="Approach/Withdrawl"
              name="Infant's/toddler's way of responding to new people and new situations was generally."
              email=""
              guardian="guardianStyle"
            />
            <div className="medical-group btn-select dev_table__btn">
              <div className="btn-addon btnGender">
                <button
                  type="button"
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.approachOrWithdrawl == ApproachOrWithDrawl.Approach
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_status"
                  }
                >
                  1 Approach
                </button>
                <button
                  type="button"
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.approachOrWithdrawl == 2
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_unselected"
                  }
                >
                  2
                </button>
                <button
                  type="button"
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.approachOrWithdrawl == ApproachOrWithDrawl.Withdraw
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_others"
                  }
                >
                  3 Withdraw
                </button>
              </div>
            </div>
          </div>
          <div className="learning__table childDev__table">
            <ChildGuardianInfo
              designation="Adaptability"
              name="Infant's/toddler's ability to adapt to changing situations was generally."
              email=""
              guardian="guardianStyle"
            />
            <div className="medical-group btn-select dev_table__btn">
              <div className="btn-addon btnGender">
                <button
                  type="button"
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.adaptability == Adaptability.HighAdaptability
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_status"
                  }
                >
                  1 High adaptability
                </button>
                <button
                  type="button"
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.adaptability == 2
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_unselected"
                  }
                >
                  2
                </button>
                <button
                  type="button"
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.adaptability == Adaptability.LowAdaptability
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_others"
                  }
                >
                  3 Low activity
                </button>
              </div>
            </div>
          </div>
          <div className="learning__table childDev__table">
            <ChildGuardianInfo
              designation="Threshold of responsiveness"
              name="Amount of stimulation needed for the infant/toddler to respond to people or thing was:"
              email=""
              guardian="guardianStyle"
            />
            <div className="medical-group btn-select dev_table__btn">
              <div className="btn-addon btnGender">
                <button
                  type="button"
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.thresholdofResponsiveness ==
                    ThresholdOfResponsiveness.QuiteReponsive
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_status"
                  }
                >
                  1 Quite responsive
                </button>
                <button
                  type="button"
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.thresholdofResponsiveness == 2
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_unselected"
                  }
                >
                  2
                </button>
                <button
                  type="button"
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.thresholdofResponsiveness ==
                    ThresholdOfResponsiveness.LowResponsive
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_others"
                  }
                >
                  3 Low responsive
                </button>
              </div>
            </div>
          </div>
          <div className="learning__table childDev__table">
            <ChildGuardianInfo
              designation="Intensity of reaction"
              name="The energy level that is expressed across most situations."
              email=""
              guardian="guardianStyle"
            />
            <div className="medical-group btn-select dev_table__btn">
              <div className="btn-addon btnGender">
                <button
                  type="button"
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.intensityOfReaction == IntensityOfReaction.HighIntensity
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_status"
                  }
                >
                  1 High intensity
                </button>
                <button
                  type="button"
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.intensityOfReaction == 2
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_unselected"
                  }
                >
                  2
                </button>
                <button
                  type="button"
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.intensityOfReaction == IntensityOfReaction.LowIntensity
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_others"
                  }
                >
                  3 Low intensity
                </button>
              </div>
            </div>
          </div>
          <div className="learning__table childDev__table">
            <ChildGuardianInfo
              designation="Quality of mood"
              name="Infant's/toddler's generally quality of mood was:"
              email=""
              guardian="guardianStyle"
            />
            <div className="medical-group btn-select dev_table__btn">
              <div className="btn-addon btnGender">
                <button
                  type="button"
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.qualityOfMood == QualityOfMood.Positive
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_status"
                  }
                >
                  1 Positive
                </button>
                <button
                  type="button"
                  
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.qualityOfMood == 2
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_unselected"
                  }
                >
                  2
                </button>
                <button
                  type="button"
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.qualityOfMood == QualityOfMood.Negative
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_others"
                  }
                >
                  3 Negative
                </button>
              </div>
            </div>
          </div>
          <div className="learning__table childDev__table">
            <ChildGuardianInfo
              designation="Distractibility"
              name="How sensitive is the infant/toddler to extraneous sights or sound that change the direction of his/her activity."
              email=""
              guardian="guardianStyle"
            />
            <div className="medical-group btn-select dev_table__btn">
              <div className="btn-addon btnGender">
                <button
                  type="button"
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.distractibility == Distractibility.QuiteSensitiveDistractible
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_status"
                  }
                >
                  1 Quite sensitive distractible
                </button>
                <button
                  type="button"
                  
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.distractibility == 2
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_unselected"
                  }
                >
                  2
                </button>
                <button
                  type="button"
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.distractibility == Distractibility.LowSensitiveOverFocussed
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_others"
                  }
                >
                  3 Low sensitive overfocused
                </button>
              </div>
            </div>
          </div>
          <div className="learning__table childDev__table" style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}>
            <ChildGuardianInfo
              designation="Attention span and persistence"
              name="The infant's/toddler's attention span (the length of time doing one thing) and persistence (staying with an activity even though it might get frustrating) in activities was generally."
              email=""
              guardian="guardianStyle"
            />
            <div className="medical-group btn-select dev_table__btn">
              <div className="btn-addon btnGender">
                <button
                  type="button"
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.attentionSpanAndPersistence ==
                    AttentionSpanAndPersistence.HighAttentionQuitePersistent
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_status"
                  }
                >
                  1 High attention quite persistent
                </button>
                <button
                  type="button"
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.attentionSpanAndPersistence == 2
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_unselected"
                  }
                >
                  2
                </button>
                <button
                  type="button"
                  className={
                    developmentalProfileDetailModel.childTemperamentDetail.attentionSpanAndPersistence ==
                    AttentionSpanAndPersistence.LowAttentionQuitePersistent
                      ? "btn btn-sm btnGender_selected"
                      : "btn btn-sm btnGender_others"
                  }
                >
                  3 Low attention quite persistence
                </button>
              </div>
            </div>
          </div>
           <hr />
          <Heading
            heading="Developmental Milestones"
            subHeading=""
            styleName="medicalGeneralDetail__style"
          />
          <div className="developmental__table">
            <ChildGuardianInfo designation="" name="Milestones" email="" guardian="guardianStyle" />
            <ChildGuardianInfo designation="" name="Years" email="" guardian="guardianStyle" />
            <ChildGuardianInfo designation="" name="Months" email="" guardian="guardianStyle" />
          </div>
          {developmentalProfileDetailModel.developmentalMilestonesDetail.map((item, index) => {
            let year = checkIfNullOrEmpty(item.years);
            let month = checkIfNullOrEmptyForMonth(item.months);
            let data = year + " " + month;
            return (
                <div className="developmental__table" key={index}>
                <ChildGuardianInfo designation={item.name} name="" email="" guardian="guardianStyle" />
                <ChildGuardianInfo designation={item.years} name="" email="" guardian="guardianStyle" />
                <ChildGuardianInfo designation={item.months} name="" email="" guardian="guardianStyle" />
              </div>
            );
          })}
        </div>
      )}
      <Loader loading={props.isLoading} marginBottom="0px" marginTop="8px" width="368px"></Loader>
    </div>
  );
}
