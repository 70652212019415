import { AppConfig, HttpWrapper } from "../../core";

export class PatientVisitStatusService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }
  
  getPatientVisitStatus(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint +
        "patientVisitStatus/" +
        patientId
    );
  }
}
