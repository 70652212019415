import Select from "react-select";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BaselineVisitReportSaveDataModel } from "../../../../../models/report/visit-report-save-data";
import { AddPatientMedicationModel } from "../../../../patient/models/add-patient-medication.model";
import "../../../../patient/patient.scss";
import logo from "../../../../../assets/images/CCFLogoFullColor.png";
import downloading from "../../../../../assets/images/svg-icons/pdf.svg";
import DiagnosisSelect from "./diagnosisSelect";
import ParticipantSelect from "./participantSelect";
import { Button, Modal, Radio } from "antd";
import moment from "moment";
import {
  AddendumData,
  severitySelectedData,
} from "../../../../../models/report/final-report-save-data";
import Heading from "../../../../heading";
import { ClinicianWeeklyData } from "../../../../../models/clinicianWeeklyData/clinician-weekly-data.model";
import WeekwiseSideEffectInfo from "../../finalReport/reportSideEffects";
import dayjs from "dayjs";
import {
  Editor,
  EditorState,
  RichUtils,
  convertFromHTML,
  ContentState,
  KeyBindingUtil,
  getDefaultKeyBinding,
} from "draft-js";
import "draft-js/dist/Draft.css";
import { stateToHTML } from "draft-js-export-html";

const participantsOptions = [
  { value: "0", label: "Primary caregiver" },
  { value: "1", label: "Mother" },
  { value: "2", label: "Father" },
  { value: "3", label: "Patient" },
  { value: "4", label: "Sibling" },
  { value: "5", label: "Grand Parent" },
];

const diagnosisCodesOptions = [
  { value: "0", label: "314.01" },
  { value: "1", label: "314.00" },
  { value: "2", label: "F90.2" },
  { value: "3", label: "F90.0" },
  { value: "4", label: "F90.1" },
  { value: "5", label: "F90.8" },
  { value: "6", label: "F90.9" },
];
interface IProps {
  patientId: string;
  downloadReport: Function;
  // saveDraftClick:Function;
  saveClick: Function;
  addAddendumClick: Function;
  // cancelAddendumClick:Function;
  visitDate: string;
  baselineVisitreportModel: BaselineVisitReportSaveDataModel;
  medication: AddPatientMedicationModel;
  yesNoSelectionChanged: Function;
  addendumList: AddendumData[];
  assignModel: Function;
  isDownloading: boolean;
  clinicianWeeklyData: ClinicianWeeklyData;
}

export default function BaselineVisit(props: IProps) {
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );
  const RadioGroup = Radio.Group;
  const scrollTop = () => {
    window.document
      .getElementById("scrollWrap")!
      .scrollTo({ top: 0, behavior: "smooth" });
  };
  const [participants, setParticipants] = useState(
    props.baselineVisitreportModel.participants
  );
  const [diagnosisCodes, setDiagnosis] = useState(
    props.baselineVisitreportModel.diagnosis
  );
  let [addendum, setAddendum] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();
  const {hasCommandModifier} = KeyBindingUtil;
  let [showSavePopup, setshowSavePopup] = useState(false);
  let [showDownloadPopup, setshowDownloadPopup] = useState(false);
  let reportSaveModel = {} as BaselineVisitReportSaveDataModel;
  let [downloadFormat, setDownloadFormat] = useState(1);
  let [othertext, setOtherText] = useState(
    props.baselineVisitreportModel.otherText
  );
  let [isAddendumError, setAddendumError] = useState(false);
  let [isToggled, setIsToggled] = useState(
    props.baselineVisitreportModel.serviceProvided == "other" &&
      !props.baselineVisitreportModel.isSaved
  );
  const [noteText, setNoteText] = useState<string>("");

  useEffect(() => {
    if (props.baselineVisitreportModel.notes) {
      const blocksFromHTML = convertFromHTML(
        props.baselineVisitreportModel.notes
      );
      const initialNote = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const initialEditorState = EditorState.createWithContent(initialNote);
      setEditorState(initialEditorState);
    }
  }, []);
  useEffect(() => {
    let html = stateToHTML(editorState.getCurrentContent());
    setNoteText(html);
  }, [editorState]);

  const onSubmit = (data, e) => {
    console.log("onSubmit: ", data);

    reportSaveModel.diagnosis = diagnosisCodes;
    reportSaveModel.facetoFaceTime = data.facetoFaceTime;
    reportSaveModel.montoringPhysician = data.montoringPhysician;
    reportSaveModel.notes = noteText;
    reportSaveModel.otherText = data.otherText;
    reportSaveModel.parentInstructed =
      props.baselineVisitreportModel.parentInstructed;
    reportSaveModel.parentSubmittedPrescription =
      data.parentSubmittedPrescription;
    reportSaveModel.participants = participants;
    reportSaveModel.pateintId = data.pateintId;
    reportSaveModel.placeboPresent =
      props.baselineVisitreportModel.placeboPresent;
    reportSaveModel.procedures = data.procedures;
    reportSaveModel.receivedPotentialSideeffects =
      props.baselineVisitreportModel.receivedPotentialSideeffects;
    reportSaveModel.savedOn = data.savedOn;
    reportSaveModel.serviceProvided = data.serviceProvided;
    reportSaveModel.teacherPacketGiven =
      props.baselineVisitreportModel.teacherPacketGiven;
    reportSaveModel.trialLength = data.trialLength;
    reportSaveModel.trialStyle = data.trialStyle;
    reportSaveModel.pateintId = props.patientId;
    reportSaveModel.receivedTrialProcedures =
      props.baselineVisitreportModel.receivedTrialProcedures;

    if (e.nativeEvent.submitter.innerText == "Submit") {
      props.assignModel(reportSaveModel);
      setshowSavePopup((showSavePopup = true));
    } else {
      props.saveClick(reportSaveModel, false);
    }
  };

  const handleChange = (e) => {
    console.log(e.target.value);
    if (e.target.value == "other") {
      setIsToggled((isToggled = true));
    } else {
      setOtherText((othertext = ""));
      setIsToggled((isToggled = false));
    }
  };
  const SaveClick = () => {
    setshowSavePopup((showSavePopup = true));
  };

  const saveconfirmClick = () => {
    props.saveClick(reportSaveModel, true);
    setshowSavePopup((showSavePopup = false));
  };

  const cancelsavePopup = () => {
    setshowSavePopup((showSavePopup = false));
  };

  const yesNoSelection = (type, isChecked) => {
    props.yesNoSelectionChanged(type, isChecked);
  };
  const downloadReport = () => {
    setshowDownloadPopup((showDownloadPopup = true));
  };
  const downloadconfirmClick = () => {
    setshowDownloadPopup((showDownloadPopup = false));
    props.downloadReport(downloadFormat);
    setDownloadFormat((downloadFormat = 1));
  };

  const canceldownloadPopup = () => {
    setshowDownloadPopup((showDownloadPopup = false));
  };
  const addAddendumClick = () => {
    if (addendum == "") {
      const addendumErrors = [
        {
          type: "custom",
          name: "addendum",
          message: "please fill value",
        },
      ];
      setAddendumError((isAddendumError = true));
      addendumErrors.forEach(({ name, type, message }) =>
        setError(name, { type, message })
      );
    } else {
      if (isAddendumError) {
        clearErrors("addendum");
      }
      setAddendumError((isAddendumError = false));
      props.addAddendumClick(addendum);

      setAddendum((addendum = ""));
    }
  };
  function handleKeyBindingFn(e): string | null {
    if (e.keyCode === 49 && hasCommandModifier(e)) {
      return 'unordered-list-item';
    }
    return getDefaultKeyBinding(e);
  }
  
  const handleKeyCommand = (command, editorState) => {
    if (command === 'unordered-list-item'){
      setEditorState(RichUtils.toggleBlockType(editorState, command));
      return "handled";
    }
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="scroll-box finalReport__container" id="scrollWrap">
        <div className="row pl-2 pr-2">
          <div className="col-md-12 p-2">
            <div className="row pb-3">
              <div className="col-4">
                <img className="img-fluid" width="200" src={logo} alt="logo" />
                {/* <p className="logo-titel">Cleveland Clinic Children’s<br/>Center for Pediatric Behavioral Health</p> */}
              </div>
              <div className="col-4 text-center">
                <p className="mb-1 top-main-text">
                  <small>
                    <strong>Medication Monitoring</strong>
                  </small>{" "}
                </p>
                <h5 className="mt-1">
                  <strong>BASELINE</strong>
                </h5>
              </div>
              <div className="col-4 text-end">
                <p className="uppercase mb-0">
                  <i>ADHD center for Evaluation & Treatment (ACET)</i>
                </p>
                <p>
                  <small>
                    Date - <strong>{props.visitDate}</strong>
                  </small>
                </p>
              </div>
            </div>
          </div>
          <p className="final__medTrial">
            Should you need further information, <strong>contact the clinical staff at 216-448-6310</strong>.
          </p>
          <div className="col-md-6 pl-2 pr-2">
            <div className="form-group form-field">
              <label className="form-label">Monitoring Physician </label>
              <input
                type="text"
                disabled={props.baselineVisitreportModel.isSaved}
                defaultValue={props.baselineVisitreportModel.montoringPhysician}
                {...register("montoringPhysician")}
                className={
                  !errors.montoringPhysician
                    ? "form-control"
                    : "form-control  is-invalid"
                }
              />
            </div>
          </div>
          <div className="col-md-6 pl-2 pr-2">
            <div className="form-group form-field">
              <label className="form-label">Participant</label>
              <div className="multi-select">
                <Select
                  isDisabled={props.baselineVisitreportModel.isSaved}
                  className="basic-multi-select"
                  // name="finalSevr"
                  styles={{ menu: (provided) => ({ ...provided, zIndex: 99 }) }}
                  placeholder="Select Participants"
                  value={participants}
                  onChange={(val) => {
                    setParticipants(val as severitySelectedData[]);
                  }}
                  isMulti
                  options={participantsOptions}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 pl-2 pr-2">
            <div className="form-group form-field">
              <label className="form-label">Service Provided</label>
              <select
                disabled={props.baselineVisitreportModel.isSaved}
                defaultValue={props.baselineVisitreportModel.serviceProvided}
                {...register("serviceProvided")}
                className={
                  !errors.serviceProvided
                    ? "form-control"
                    : "form-control  is-invalid"
                }
                onChange={(e) => handleChange(e)}
              >
                <option>Select</option>
                <option>
                  90847 - Family psych with patient present (26-50+mins)
                </option>
                <option>
                  90846 Family Psych with out patient present (26-50+mins)
                </option>
                <option>90832 psychotherapy with patient (16-37mins)</option>
                <option>90834 psychotherapy with patient (38-52mins)</option>
                <option>90837 psychotherapy with patient (53+mins)</option>
                <option>
                  96167 H&B Intervention, Family with patient Initial 30mins
                </option>
                <option>
                  96168 H&B Intervention Family with patient each additional
                  15mins
                </option>
                <option>
                  96170 H&B Intervention with out patient Initial 30mins
                </option>
                <option>
                  9800595 Virtual and or phone visit family psychotherapy with
                  out patient
                </option>
                <option>
                  9800463 Virtual and or phone visit family psychotherapy with
                  patient
                </option>
                <option value="other">Other</option>
              </select>
              <i className="fa fa-angle-down relation_arrow"></i>
            </div>
          </div>
          <div className="col-md-6 pl-2 pr-2">
            <div className="form-group form-field">
              <label className="form-label">If other, please specify</label>
              <input
                {...register("otherText")}
                type="text"
                onChange={(e) => {
                  setOtherText((othertext = e.target.value));
                }}
                value={othertext}
                className={
                  !errors.otherText
                    ? "form-control"
                    : "form-control  is-invalid"
                }
                disabled={!isToggled || props.baselineVisitreportModel.isSaved}
              />
            </div>
          </div>
          <div className="col-md-6 pl-2 pr-2">
            <div className="form-group form-field">
              <label className="form-label">
                Face to face time with parent/family (in minutes)
              </label>
              <input
                {...register("facetoFaceTime")}
                disabled={props.baselineVisitreportModel.isSaved}
                defaultValue={props.baselineVisitreportModel.facetoFaceTime}
                type="text"
                className={
                  !errors.facetoFaceTime
                    ? "form-control"
                    : "form-control  is-invalid"
                }
              />
            </div>
          </div>
          <div className="col-md-6 pl-2 pr-2">
            <div className="form-group form-field">
              <label className="form-label">Diagnosis Code</label>
              <div className="multi-select">
                <Select
                  isDisabled={props.baselineVisitreportModel.isSaved}
                  className={`reactSelect multi-roles`}
                  // name="finalSevr"
                  styles={{ menu: (provided) => ({ ...provided, zIndex: 99 }) }}
                  placeholder="Select Code"
                  value={diagnosisCodes || []}
                  onChange={(val) => {
                    setDiagnosis(val as severitySelectedData[]);
                  }}
                  isMulti
                  options={diagnosisCodesOptions}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 pl-2 pr-2">
            <div className="form-group form-field">
              <label className="form-label">Trial Length</label>
              <select
                {...register("trialLength")}
                disabled={props.baselineVisitreportModel.isSaved}
                defaultValue={props.baselineVisitreportModel.trialLength}
                className={
                  !errors.trialLength
                    ? "form-control"
                    : "form-control  is-invalid"
                }
              >
                <option disabled={true} value="3">
                  3 weeks
                </option>
                <option disabled={true} value="4">
                  4 weeks
                </option>
                <option value="5">5 weeks</option>
              </select>
              <i className="fa fa-angle-down relation_arrow"></i>
            </div>
          </div>
          <div className="col-md-6 pl-2 pr-2">
            <div className="form-group form-field">
              <label className="form-label">Trial Style</label>
              <select
                {...register("trialStyle")}
                defaultValue={props.baselineVisitreportModel.trialStyle}
                disabled={props.baselineVisitreportModel.isSaved}
                className={
                  !errors.trialStyle
                    ? "form-control"
                    : "form-control  is-invalid"
                }
              >
                <option value="">Select</option>
                <option>Blinded/Placebo</option>
                <option>Blinded Only</option>
                <option>Unblinded</option>
              </select>
              <i className="fa fa-angle-down relation_arrow"></i>
              {/* <div className="btn-group btn-group-toggle check-radios">
                            <label className={props.baselineVisitreportModel.trialStyle == "Blinded"
                            ? "btn btn-light active"
                            : "btn btn-light"}>
                                <input type="radio" disabled={props.baselineVisitreportModel.isSaved} ref={register} name="trialStyle" onClick={(e)=>yesNoSelection(6,"Blinded")} value="Blinded" checked /> Blinded
                            </label>
                            <label className={props.baselineVisitreportModel.trialStyle == "Non Blinded"
                            ? "btn btn-light active"
                            : "btn btn-light"}>
                                <input type="radio" disabled={props.baselineVisitreportModel.isSaved} onClick={(e)=>yesNoSelection(6,"Non Blinded")} ref={register} name="trialStyle" value="Non Blinded" /> Non Blinded
                            </label>
                        </div> */}
            </div>
          </div>
          <div className="col-md-12 p-0">
            <div className="d-block p-2">
              <h3 className="section-titel">MEDICATION</h3>
            </div>
            <div className="row m-0">
              {props.medication?.createPatientTrialWeekModels?.map(
                (item, index) => (
                  <div className="col p-2">
                    <div className="titel-box">
                      {"Week " + item.weekNo} (
                      {dayjs(item.startDate).format("MM/DD/YYYY")})
                    </div>
                    <div className="gray-box-item">
                      <p className="no-data">Medication Family</p>
                      <h6>
                        {
                          item.createPatientTrailWeeklyMedicationModel
                            .medicationFamily
                        }
                      </h6>
                      <p className="no-data">Brand Name</p>
                      {item.createPatientTrailWeeklyMedicationModel
                        .medicationType == "Generic" && <h6>NA</h6>}

                      <h6>
                        {
                          item.createPatientTrailWeeklyMedicationModel
                            .medicationBrandName
                        }
                      </h6>
                      <p className="no-data">Generic Name</p>
                      <h6>
                        {
                          item.createPatientTrailWeeklyMedicationModel
                            .medicationGenericName
                        }
                      </h6>
                      <p className="no-data">Dosage Form</p>
                      <h6>
                        {
                          item.createPatientTrailWeeklyMedicationModel
                            .medicationFormat
                        }
                      </h6>
                      <p className="no-data">Dosage Strength</p>
                      <h6>
                        {
                          item.createPatientTrailWeeklyMedicationModel
                            .medicationDosage
                        }
                      </h6>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>

          <div className="col-12 p-2">
            <div className="d-block p-2">
              <h3 className="section-titel">SIDE EFFECTS</h3>
            </div>
            <div className="finalReport__medication">
              {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData &&
                props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData
                  .filter((u) => u.isBaseLine == true)
                  .map((row, i) => (
                    <WeekwiseSideEffectInfo
                      weekCount={row.weekNo}
                      weekDate={row.weekStartDate}
                      insomniaOrTroubleSleeping={row.insomniaOrTroubleSleeping}
                      nightmares={row.nightmares}
                      staresALotOrDayDreams={row.staresALotOrDayDreams}
                      decreasedAppetite={row.decreasedAppetite}
                      talkLessWithOthers={row.talkLessWithOthers}
                      unInterestedWithOthers={row.unInterestedWithOthers}
                      sideEffectsData={row}
                    ></WeekwiseSideEffectInfo>
                  ))}
            </div>
          </div>
          <div className="col-12 p-2">
            <div className="form-group form-field">
              <h3 className="section-titel">Procedures</h3>

              <div className="d-block p-3 border border-radius-2">
                Daily Side Effects Behavioral Monitoring Scale-SEBMS
                <br />
                ADHD Rating Scale-IV
                <br />
                Connors Global Index-Parent Version
              </div>
            </div>
          </div>

          <div className="col-12 p-2">
            <p>
              Physician submitted the prescriptions to CCF Home Pharmacy.
              Medication to be delivered to the patient’s home by CCF Home
              Pharmacy.
            </p>
            <p>Dosing - Once Daily</p>
          </div>

          <div className="col-md-12 p-0">
            {/* <div className="row m-0">
                        <div className="col-4 p-2">
                            <div className="form-group form-field">
                                <label className="form-label mt-4">Parent submitted prescription (hardcopy) and was instructed to pick up filled prescription following initial monitoring appointment</label> 
                            </div>
                        </div>
                        <div className="col-8 p-2">
                            <div className="form-group form-field">
                                <input ref={register} type="text"  defaultValue={props.baselineVisitreportModel.parentSubmittedPrescription} name="parentSubmittedPrescription" className={
                                    !errors.parentSubmittedPrescription
                                    ? "form-control"
                                    : "form-control  is-invalid"
                                }/>
                            </div>
                        </div>
                    </div> */}
            <div className="row">
              <div className="col-4 p-2">
                <div className="form-group form-field">
                  <label className="form-label mt-4">Placebo Present</label>
                </div>
              </div>
              <div className="col-8 p-2">
                <div className="form-group form-field mb-0">
                  <div className="btn-group btn-group-toggle check-radios">
                    <label
                      className={
                        props.baselineVisitreportModel.placeboPresent == "Yes"
                          ? "btn btn-light active"
                          : "btn btn-light"
                      }
                    >
                      <input
                        type="radio"
                        disabled={props.baselineVisitreportModel.isSaved}
                        {...register("placeboPresent")}
                        onClick={(e) => yesNoSelection(1, "Yes")}
                        value="Yes"
                        //checked
                      />{" "}
                      Yes
                    </label>
                    <label
                      className={
                        props.baselineVisitreportModel.placeboPresent == "No"
                          ? "btn btn-light active"
                          : "btn btn-light"
                      }
                    >
                      <input
                        type="radio"
                        disabled={props.baselineVisitreportModel.isSaved}
                        onClick={(e) => yesNoSelection(1, "No")}
                        {...register("placeboPresent")}
                        value="No"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4 p-2">
                <div className="form-group form-field">
                  <label className="form-label mt-4">Gave Teacher Packet</label>
                </div>
              </div>
              <div className="col-8 p-2">
                <div className="form-group form-field mb-0">
                  <div className="btn-group btn-group-toggle check-radios">
                    <label
                      className={
                        props.baselineVisitreportModel.teacherPacketGiven ==
                        "Yes"
                          ? "btn btn-light active"
                          : "btn btn-light"
                      }
                    >
                      <input
                        disabled={props.baselineVisitreportModel.isSaved}
                        type="radio"
                        onClick={(e) => yesNoSelection(2, "Yes")}
                        {...register("teacherPacketGiven")}
                        value="Yes"
                        //checked
                      />{" "}
                      Yes
                    </label>
                    <label
                      className={
                        props.baselineVisitreportModel.teacherPacketGiven ==
                        "No"
                          ? "btn btn-light active"
                          : "btn btn-light"
                      }
                    >
                      <input
                        disabled={props.baselineVisitreportModel.isSaved}
                        type="radio"
                        onClick={(e) => yesNoSelection(2, "No")}
                        {...register("teacherPacketGiven")}
                        value="No"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4 p-2">
                <div className="form-group form-field">
                  <label className="form-label mt-4">
                    Reviewed potential side effects
                  </label>
                </div>
              </div>
              <div className="col-8 p-2">
                <div className="form-group form-field mb-0">
                  <div className="btn-group btn-group-toggle check-radios">
                    <label
                      className={
                        props.baselineVisitreportModel
                          .receivedPotentialSideeffects == "Yes"
                          ? "btn btn-light active"
                          : "btn btn-light"
                      }
                    >
                      <input
                        {...register("receivedPotentialSideeffects")}
                        disabled={props.baselineVisitreportModel.isSaved}
                        onClick={(e) => yesNoSelection(3, "Yes")}
                        type="radio"
                        value="Yes"
                        //checked
                      />{" "}
                      Yes
                    </label>
                    <label
                      className={
                        props.baselineVisitreportModel
                          .receivedPotentialSideeffects == "No"
                          ? "btn btn-light active"
                          : "btn btn-light"
                      }
                    >
                      <input
                        {...register("receivedPotentialSideeffects")}
                        disabled={props.baselineVisitreportModel.isSaved}
                        onClick={(e) => yesNoSelection(3, "No")}
                        type="radio"
                        value="No"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4 p-2">
                <div className="form-group form-field">
                  <label className="form-label mt-4">
                    Reviewed med trial procedures with parent and child
                  </label>
                </div>
              </div>
              <div className="col-8 p-2">
                <div className="form-group form-field mb-0">
                  <div className="btn-group btn-group-toggle check-radios">
                    <label
                      className={
                        props.baselineVisitreportModel
                          .receivedTrialProcedures == "Yes"
                          ? "btn btn-light active"
                          : "btn btn-light"
                      }
                    >
                      <input
                        type="radio"
                        disabled={props.baselineVisitreportModel.isSaved}
                        onClick={(e) => yesNoSelection(4, "Yes")}
                        {...register("reviewedmed")}
                        value="Yes"
                        //checked
                      />{" "}
                      Yes
                    </label>
                    <label
                      className={
                        props.baselineVisitreportModel
                          .receivedTrialProcedures == "No"
                          ? "btn btn-light active"
                          : "btn btn-light"
                      }
                    >
                      <input
                        type="radio"
                        disabled={props.baselineVisitreportModel.isSaved}
                        onClick={(e) => yesNoSelection(4, "No")}
                        {...register("reviewedmed")}
                        value="No"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4 p-2">
                <div className="form-group form-field">
                  <label className="form-label mt-4">
                    Parent/Guardian instructed to report severe side effects to
                    pediatrician first
                  </label>
                </div>
              </div>
              <div className="col-8 p-2">
                <div className="form-group form-field mb-0">
                  <div className="btn-group btn-group-toggle check-radios">
                    <label
                      className={
                        props.baselineVisitreportModel.parentInstructed == "Yes"
                          ? "btn btn-light active"
                          : "btn btn-light"
                      }
                    >
                      <input
                        type="radio"
                        disabled={props.baselineVisitreportModel.isSaved}
                        onClick={(e) => yesNoSelection(5, "Yes")}
                        {...register("parentInstructed")}
                        value="Yes"
                        //checked
                      />{" "}
                      Yes
                    </label>
                    <label
                      className={
                        props.baselineVisitreportModel.parentInstructed == "No"
                          ? "btn btn-light active"
                          : "btn btn-light"
                      }
                    >
                      <input
                        type="radio"
                        disabled={props.baselineVisitreportModel.isSaved}
                        onClick={(e) => yesNoSelection(5, "No")}
                        {...register("parentInstructed")}
                        value="No"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 p-2">
            <p>There were no vitals taken for this visit.</p>
          </div>
          <div className="col-12 p-2">
            <div className="form-group form-field">
              <h3 className="section-titel">Notes</h3>
              {/* <label className="form-label pl-0" style={{left:'0px'}}><strong></strong></label>  */}
              <div className="d-block border">
                {/* <textarea disabled={props.baselineVisitreportModel.isSaved} {...register("notes")}  defaultValue={props.baselineVisitreportModel.notes} className={
                            !errors.notes
                            ? "form-control text-area-box"
                            : "form-control text-area-box is-invalid"
                            } placeholder="Please provide any additional information not captured above. You can also specify the medications taken by the patient prior to the trial. Please include as much detail as possible."></textarea> */}
                <Editor
                  placeholder="Please provide any additional information not captured above. You can also specify the medications taken by the patient prior to the trial.Please include as much detail as possible."
                  editorState={editorState}
                  onChange={setEditorState}
                  handleKeyCommand={handleKeyCommand}
                  readOnly={props.baselineVisitreportModel.isSaved}
                  keyBindingFn={handleKeyBindingFn}
                />
              </div>
            </div>
          </div>
          {props.baselineVisitreportModel.isSaved && (
            <div className="col-12 p-2">
              <div className="form-group form-field">
                <h3 className="section-titel">Addendum</h3>
                <div className="d-block">
                  {props.addendumList?.map((addendum, index) => {
                    let noteDate = addendum.addedOn
                      ? dayjs(addendum.addedOn)
                      : dayjs();
                    return (
                      <Fragment key={"addendum_" + index}>
                        <div>
                          <h3 className="heading weekNote__heading">
                            {noteDate.format("dddd") +
                              " (" +
                              noteDate.format("MM/DD/YYYY") +
                              ")"}
                            <span className="timestamp">
                              {noteDate.format("HH:mm")}
                            </span>
                          </h3>
                          <pre style={{ whiteSpace: "break-spaces" }}>
                            {addendum.addendum}
                          </pre>
                        </div>

                        <hr />
                      </Fragment>
                    );
                  })}
                </div>
                <div className="report__summary w-100 p-0">
                  <textarea
                    id="txtAddendum"
                    name="addendum"
                    value={addendum}
                    placeholder="Please provide any additional information"
                    className={
                      !isAddendumError
                        ? "form-control text-area-box"
                        : "form-control text-area-box is-invalid"
                    }
                    onChange={(e) => {
                      setAddendum((addendum = e.target.value));
                    }}
                  ></textarea>
                  {isAddendumError && (
                    <span className={"text-danger"}>
                      Please enter the additional information
                    </span>
                  )}

                  <div className="d-block additional-box">
                    <Button
                      type="primary"
                      size="large"
                      onClick={addAddendumClick}
                      className="btn_reset"
                    >
                      Add
                    </Button>
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => {
                        setAddendum("");
                      }}
                      className="btn-link"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
                {/* <Button
                    type="primary"
                    size="large"
                    onClick={addAddendumClick}
                    className="btn_reset"
                    
                >
                    Add
                </Button>
                <Button
                    type="primary"
                    size="large"
                    onClick={()=>{setAddendum("")}}
                    
                    className="btn_reset"
                    
                >
                    Cancel
                </Button> */}
              </div>
            </div>
          )}
        </div>
        {/* <input name="example" defaultValue="test" ref={register} />
                <input name="exampleRequired" ref={register({ required: true })} />
                {errors.exampleRequired && <span>This field is required</span>} */}

        <div className="row border-top">
          <div className="col-12 p-3 text-end">
            <button
              type="button"
              disabled={props.isDownloading}
              onClick={downloadReport}
              className="ant-btn ant-btn-text ant-btn-lg finalReport_dwnload"
            >
              <img width="16" alt="download" src={downloading} />{" "}
              {props.isDownloading ? "DOWNLOADING" : "DOWNLOAD"}
            </button>
            {!props.baselineVisitreportModel.isSaved && (
              <span>
                <button
                  type="submit"
                  className="ant-btn ant-btn-primary ant-btn-lg btn_reset"
                >
                  <span>Save as Draft</span>
                </button>
                <button
                  type="submit"
                  onClick={SaveClick}
                  className="ant-btn ant-btn-primary ant-btn-lg btn_add"
                >
                  <span>Submit</span>
                </button>
              </span>
            )}
          </div>
        </div>
      </div>

      <Modal
        title=""
        open={showSavePopup}
        onOk={saveconfirmClick}
        onCancel={cancelsavePopup}
        okText="Yes"
        cancelText="No"
        closable={false}
        maskClosable={false}
      >
        {" "}
        <p>
          Are you ready to submit the Baseline Visit Report? You will not be
          allowed to make any changes after submitting the Baseline Visit
          Report.
        </p>
      </Modal>

      <Modal
        key="downloadPopup"
        title="Download"
        open={showDownloadPopup}
        onOk={downloadconfirmClick}
        onCancel={canceldownloadPopup}
        okText="Download"
        cancelText="Cancel"
        closable={false}
        maskClosable={false}
      >
        <p>Which format do you want to use?</p>
        <RadioGroup value={downloadFormat}>
          <Radio
            value={1}
            onClick={() => {
              setDownloadFormat((downloadFormat = 1));
            }}
          >
            Adobe PDF(.pdf)
          </Radio>
          <Radio
            value={2}
            onClick={() => {
              setDownloadFormat((downloadFormat = 2));
            }}
          >
            MS word document(.docx)
          </Radio>
        </RadioGroup>
      </Modal>
      <div className="d-block pt-2 pb-2 text-end">
        <div onClick={scrollTop} className="down-arrow">
          <i className="fa fa-angle-up" aria-hidden="true"></i>
        </div>
      </div>
    </form>
  );
}
