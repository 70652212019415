import React, { Component, Fragment } from "react";
import { HttpResponse } from "../../../../core";
import { PsychologicalTestingPostModel } from "../../../../models/psychologicalTesting/psychological-testing-post.model";
import { PsychologicalTestingModel } from "../../../../models/psychologicalTesting/psychological-testing.model";
import { PsychologicalTestingService } from "../../../../services/psychologicalTesting";
import { ShowSuccessMessage } from "../../../../shared/helpers";
import PsychologocialTestingForm from "./psychologocialTestingForm";

interface IState {
  patientId: string;
  isLoading: boolean;
  psychologicalTestingModel: PsychologicalTestingPostModel;
  reRenderForm: boolean;
  isEnabled: boolean;
  isFormSave?: Function;
}
export default class PsychologocialTesting extends Component<any, IState> {
  private psychologicalTestingService: PsychologicalTestingService;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.psychologicalTestingService = new PsychologicalTestingService();
  }

  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      patientId: this.props.patientId,
      reRenderForm: false,
      psychologicalTestingModel: {} as PsychologicalTestingPostModel,
      isEnabled: false,
    };
    return initialState;
  }
  componentDidMount() {
    this.loadData();
    window.scroll(0, 0);
  }

  postData = (data) => {
    let psychologyPostData = data.psychologicalTestingModel;
    if (this.state.isLoading) {
      return;
    }
      if (data.isAutoSave) {
          this.setLoading(false);
      }
      else {
          this.setLoading(true);
      }
    this.psychologicalTestingService
      .postPsychologicalTesting(psychologyPostData)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
        }
        if (!data.isAutoSave) {
          ShowSuccessMessage("Data Saved Successfully.");
          this.props.nextTabChange();
        }
        this.setLoading(false);
        this.props.isFormSave();
        this.loadData(data.isAutoSave);
      })
      .catch(() => {
        this.setLoading(false);
      });
  };
  parentTabChange = (tab: any) => {
    this.props.parentTabChange(tab);
  };

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }
  deletePsychologicalTesting = (psychologicalId) => {
    //this.setLoading(true);
    this.psychologicalTestingService
      .deletePsychologicalTesting(psychologicalId)
      .then((res: HttpResponse<boolean>) => {
        if (res && res.result) {
          //  this.setLoading(false);
        }
      });
  };
  assignModel = (data: any) => {
    this.setState({
      psychologicalTestingModel: data.psychologicalTestingModel,
    });
  };

  loadData = (isAutoSave?: any) => {
    if (this.state.patientId) {
        if (isAutoSave) {
            this.setLoading(false);
        }
        else {
            this.setLoading(true);
        }
      this.psychologicalTestingService
        .getPsychologicalTestingDetail(this.props.patientId)
        .then((res: HttpResponse<PsychologicalTestingModel[]>) => {
          this.setLoading(false);
          if (res && res.result && res.result.length > 0) {
            if (isAutoSave) {
              let pm = this.state.psychologicalTestingModel;
              res.result.forEach((item, index) => {
                pm.psychologicalDetailModel[index].id = item.id;
              });
              this.setState({
                psychologicalTestingModel: {
                  patientId: this.props.patientId,
                  psychologicalDetailModel: pm.psychologicalDetailModel,
                },
              });
            } else {
              this.setState({
                psychologicalTestingModel: {
                  patientId: this.props.patientId,
                  psychologicalDetailModel: res.result,
                },
                isEnabled: true,
                reRenderForm: true,
              });
              this.setState({ reRenderForm: false });
            }
          } else {
            this.setState({
              psychologicalTestingModel: {
                patientId: this.props.patientId,
                psychologicalDetailModel: [
                  {
                    id: "",
                    nameOfProfessionalOrOrganization: "",
                    purpose: "",
                    reportAvailable: null,
                  },
                ],
              } as PsychologicalTestingPostModel,
              isEnabled: false,
              reRenderForm: true,
            });
            this.setState({ reRenderForm: false });
          }
        })
        .catch((ex) => {
          this.setLoading(false);
        });
    }
  };
  render() {
    return (
      <Fragment>
        {!this.state.reRenderForm && (
          <PsychologocialTestingForm
            patientId={this.props.patientId}
            onSubmit={this.postData}
            isSaving={this.state.isLoading}
            assignModel={this.assignModel}
            psychologicalTestingModel={this.state.psychologicalTestingModel}
            parentTabChange={this.parentTabChange}
            isEnabled={this.state.isEnabled}
            deletePsychologicalTesting={this.deletePsychologicalTesting}
          />
        )}
      </Fragment>
    );
  }
}
