import React, { Fragment } from "react";
import { HttpResponse } from "../../../../core";
import { LearningBehaviorPostModel } from "../../../../models/familyBackground/learningAndBehavior/learning-behavior-post.model";
import { LearningBehaviorModel } from "../../../../models/familyBackground/learningAndBehavior/learning-behavior.model";
import { FamilyBackgroundService } from "../../../../services/familyBackground";
import { ShowSuccessMessage } from "../../../../shared/helpers";
import { DropdownItemModel } from "../../../../shared/models/dropdown.model";
import MedicalLearningBehaviourForm from "./medicalLearningBehaviour";

interface IState {
  patientId: string;
  isLoading: boolean;
  learningBehaviorList: LearningBehaviorModel[];
  learningBehaviorPostModel: LearningBehaviorPostModel;
  relationshipList: DropdownItemModel[];
  isFormSave?: Function;
  familyBackGroundStepUpdate?: Function;
}
export class MedicalLearningBehavior extends React.Component<any, IState> {
  private familyBackgroundService: FamilyBackgroundService;

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.familyBackgroundService = new FamilyBackgroundService();
  }

  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      patientId: this.props.patientId,
      learningBehaviorList: [],
      relationshipList: [],
      learningBehaviorPostModel: {
        patientId: this.props.patientId,
        learningBehaviorModel: [],
      },
    };
    return initialState;
  }
  componentDidMount() {
    this.familyBackgroundService
      .getRelationship()
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res && res.result) {
          this.setState({
            relationshipList: res.result,
          });
        }
      });
    this.loadData();
    window.scroll(0, 0);
  }

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }

    loadData = (isAutoSave?: any) => {
        if (isAutoSave) {
            this.setLoading(false);
        }
        else {
            this.setLoading(true);
        }
    this.familyBackgroundService
      .getLearningBehaviorDetail(this.props.patientId)
      .then((res: HttpResponse<LearningBehaviorModel[]>) => {
        if (res && res.result) {
          if(res.result.length>0 && res.result.find(u=>u.id!=null)){
            this.props.familyBackGroundStepUpdate(5,res.result.length>0);
          }
          this.setLoading(false);
          if (isAutoSave) {
            let lb = this.state.learningBehaviorList;
            res.result.forEach((item, index) => {
              lb[index].id = item.id;
              lb[index].patientId = item.patientId;
            });  
            this.setState({
              learningBehaviorList: lb,
            });
          }
          else{
          this.setState({
            learningBehaviorList: res.result,
          });
        }
      }
      });
  };
  assignModel = (data: any) => {
    this.setState({
      learningBehaviorList: data.learningBehaviorList,
    });
  };
  previousTabChange = () => {
    this.props.previousTabChange();
  };

  postData = (data) => {
    this.setState(
      {
        learningBehaviorPostModel: {
          ...this.state.learningBehaviorPostModel,
          patientId: this.props.patientId,
          learningBehaviorModel: data.learningBehaviorList,
        },
      },
      () => {
        if (this.state.isLoading) {
          return;
        }
          if (data.isAutoSave) {
              this.setLoading(false);
          }
          else {
              this.setLoading(true);
          }
        this.familyBackgroundService
          .postLearningBehavior(this.state.learningBehaviorPostModel)
          .then((res: HttpResponse<boolean>) => {
            if (res && res.result) {
             
            }
            if (!data.isAutoSave) {
              ShowSuccessMessage("Data Saved Successfully.");
              this.props.nextTabChange();
            }
            this.setLoading(false);
            this.props.isFormSave();
            this.loadData(data.isAutoSave);
          })
          .catch((ex) => {
            this.setLoading(false);
          });
      }
    );
  };
  render() {
    return (
      <Fragment>
        <MedicalLearningBehaviourForm
          onSubmit={this.postData}
          isSaving={this.state.isLoading}
          patientId={this.props.patientId}
          assignModel={this.assignModel}
          learningBehaviorList={this.state.learningBehaviorList}
          previousTabChange={this.previousTabChange}
          relationshipList={this.state.relationshipList}
        />
      </Fragment>
    );
  }
}
