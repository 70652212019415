import moment from "moment";
import React from "react";
import ChildGuardianInfo from "../../../../childGuardianInfo";
import ContentCard from "../../../../contentCard";
import Heading from "../../../../heading";
import dayjs from "dayjs";

function WeekwiseMedicationInfo({ weekCount, weekDate, medFamily, brandName,genericName,dosage,format }) {
  return (
    <div className="viewMed__weekContentWrap">
              <Heading subHeading={"Week "+weekCount+ " ("+dayjs(weekDate).format("MM/DD/YYYY")+")"} />
              <ContentCard
                styleName="viewMed__weekContent"
                contents={[
                  <div>
                    <div className="viewMed__weekDosage">
                      <ChildGuardianInfo
                        designation="Medication Family"
                        name={medFamily}
                        email=""
                        guardian="weekMed__style"
                      />
                    </div>
                    <div className="viewMed__weekDosage">
                      <ChildGuardianInfo
                        designation="Brand Name"
                        name={brandName==""?"NA":brandName}
                        email=""
                        guardian="weekMed__style"
                      />
                    </div>
                    <div className="viewMed__weekDosage">
                      <ChildGuardianInfo
                        designation="Generic Name"
                        name={genericName==""?"-":genericName}
                        email=""
                        guardian="weekMed__style"
                      />
                    </div>
                    <div className="viewMed__weekDosage">
                      <ChildGuardianInfo
                        designation="Dosage Form"
                        name={format==""?"-":format}
                        email=""
                        guardian="weekMed__style"
                      />
                    </div>
                    <div className="viewMed__weekDosage">
                      <ChildGuardianInfo
                        designation="Dosage Strength"
                        name={dosage==""?"-":dosage}
                        email=""
                        guardian="weekMed__style"
                      />
                    </div>
                  </div>,
                ]}
              />
            </div>
            
  );
}
export default WeekwiseMedicationInfo;