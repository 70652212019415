import React, { Fragment } from "react";
import "./styles.css";

// export interface WeekwiseProps {
//   problemName: string;
//   marked: string;
//   border: string;
//   statusStyle: string;
//   padding: string;
//   weekDot: string;
//   missed: string;
//   missedOut: boolean;
//   addVisible: boolean;
//   color: string;
//   onClick: () => void;
// }
function WeekwiseColumn({
  marked,
  problemName,
  border,
  statusStyle,
  padding,
  weekDot,
  missed,
  missedOut,
  contents,
  popover__align,
}) {
  return (
    <div className={`weekwise__block ${statusStyle}`}>
      {missedOut && <span className="week__missed">{missed}</span>}
      <p>{problemName}</p>
      <h5 style={{ border: border, padding: padding }}>
        <span className={`dot ${weekDot}`}></span>
        {marked}
      </h5>
      <Fragment>
        {contents.map((content, index) => (
          <div key={index} className={`week__popover ${popover__align}`}>
            {content}
          </div>
        ))}
      </Fragment>
    </div>
  );
}
export default WeekwiseColumn;
