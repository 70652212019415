import React, { createContext, useState } from "react";

interface ContextObject {
  title: string;
  changeTitle: Function;
  crumItem1: string;
  crumItem2: string;
  crumItem3: string;
  crumType: string;
  crumIcon: string;
  changeBreadcrumb: Function;
}
export const GlobalContext = createContext({} as ContextObject);

const GlobalContextProvider = ({ children }: any) => {
  const [title, setTitle] = useState("");
  const changeTitle = (newTitle: string) => {
    setTimeout(() => {
      setTitle(newTitle);
    }, 0);
  };
  const [crumItem1, setBreadcrumbItem1] = useState("");
  const [crumItem2, setBreadcrumbItem2] = useState("");
  const [crumItem3, setBreadcrumbItem3] = useState("");
  const [crumType, setBreadcrumbType] = useState("");
  const [crumIcon, setBreadcrumIcon] = useState("");
  const changeBreadcrumb = (
    crumItem1: string,
    crumItem2: string,
    crumItem3: string,
    crumType: string,
    crumIcon: string
  ) => {
    setTimeout(() => {
      if(crumItem1=="Dashboard" || crumItem1=="Medication Settings")
      {
        setBreadcrumbItem1("");
      }
      else{
        setBreadcrumbItem1(crumItem1);
      }
      
    }, 0);
    setTimeout(() => {
      setBreadcrumbItem2(crumItem2);
    }, 0);
    setTimeout(() => {
      setBreadcrumbItem3(crumItem3);
    }, 0);
    setTimeout(() => {
      setBreadcrumbType(crumType);
    }, 0);
    setTimeout(() => {
      setBreadcrumIcon(crumIcon);
    }, 0);
  };
  return (
    <GlobalContext.Provider
      value={{
        crumItem1,
        crumItem2,
        crumItem3,
        crumType,
        crumIcon,
        changeBreadcrumb,
        title,
        changeTitle,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
