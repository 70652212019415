import { AppConfig, HttpWrapper } from "../../core";
import { AddBirthParentModel } from "../../models/familyBackground/add-birth-parent.model";
import { AddPrimaryCareTakerModel } from "../../models/familyBackground/add-primary-care-taker";
import { DropdownItemModel } from "../../shared/models/dropdown.model";

import { AddSiblingPostModel } from "../../models/familyBackground/sibling/add-sibling-post.model";
import { AddSiblingModel } from "../../models/familyBackground/sibling/add-sibling.model";
import { AddHomeCompositionModel } from "../../models/familyBackground/homeComposition/add-home-composition.model";
import { AddHomeCompositionPostModel } from "../../models/familyBackground/homeComposition/add-home-composition-post.model";
import { LearningBehaviorModel } from "../../models/familyBackground/learningAndBehavior/learning-behavior.model";
import { LearningBehaviorPostModel } from "../../models/familyBackground/learningAndBehavior/learning-behavior-post.model";
import { AddFamilyTransitionModel } from "../../models/familyBackground/familyTransition/add-family-transition.model";
import { SiblingDetailInfoModel } from "../../models/familyBackground/sibling/sibling-detail-info.model";
import { GetFamilyBackgroundStepStatus } from "../../components/patient/models/get-family-background-step-status.model";


export class FamilyBackgroundService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }
  getEducationLevel() {
    return this.wrapper.get<DropdownItemModel[]>(
      AppConfig.apiEndpoint + "master/education-level"
    );
  }
  getChildResidesWith() {
    return this.wrapper.get<DropdownItemModel[]>(
      AppConfig.apiEndpoint + "master/child-reside-with"
    );
  }
  getContactWithBirthParent() {
    return this.wrapper.get<DropdownItemModel[]>(
      AppConfig.apiEndpoint + "master/contact-with-birth-parent"
    );
  }
  getProfession() {
    return this.wrapper.get<DropdownItemModel[]>(
      AppConfig.apiEndpoint + "master/contact-with-birth-parent"
    );
  }
  postAddBirthParent(birthParent: AddBirthParentModel) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "familybackground/birth-parent/create",
      birthParent
    );
  }
  getBirthParent(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint +
        "familybackground/birth-parent/detail/" +
        patientId
    );
  }
  // **Sibling Section Start**
  postSibling(sibling: AddSiblingPostModel) {
    return this.wrapper.post<boolean>(
      AppConfig.apiEndpoint + "familybackground/sibling/create",
      sibling
    );
  }
  getSiblingDetail(patientId: string | null) {
    return this.wrapper.get<SiblingDetailInfoModel>(
      AppConfig.apiEndpoint + "familybackground/sibling/detail/" + patientId
    );
  }
  deleteSibling(id: string) {
    return this.wrapper.delete<boolean>(
      AppConfig.apiEndpoint + "familybackground/sibling/delete/" + id 
    );
  }
  // **Sibling Section End**

   // **Home Composition Section Start**
    postHomeComposition(sibling: AddHomeCompositionPostModel) {
      return this.wrapper.post<any>(
        AppConfig.apiEndpoint + "familybackground/HomeComposition/create",
        sibling
      );
    }
    getHomeCompositionDetail(patientId: string | null) {
      return this.wrapper.get<AddHomeCompositionPostModel>(
        AppConfig.apiEndpoint + "familybackground/HomeComposition/detail/" + patientId
      );
    }
    deleteHomeComposition(id: string) {
      return this.wrapper.delete<boolean>(
        AppConfig.apiEndpoint + "familybackground/HomeComposition/delete/" + id
      );
    }
    // **Home Composition Section End**
  // ** Primary Care Take Start **
  getPrimaryCareTaker(patientId: string) {
    return this.wrapper.get<any>(AppConfig.apiEndpoint + 'familybackground/primary-caretaker/detail/' +patientId);
  }
  getRelationShip() {
    return this.wrapper.get<DropdownItemModel[]>(AppConfig.apiEndpoint + 'master/relationship');
  }
  postAddPrimaryCareTaker(primaryCarTaker: AddPrimaryCareTakerModel) {
    return this.wrapper.post<any>(AppConfig.apiEndpoint + 'familybackground/primary-caretaker/create', primaryCarTaker);
  }
  // ** Primary Care Take End **


    //**Learning and Behaviour Start **

    getLearningBehaviorDetail(patientId: string | null) {
        return this.wrapper.get<LearningBehaviorModel[]>(
            AppConfig.apiEndpoint +
            "familybackground/learning-behavior/detail/" +
            patientId
        );
    }

    getRelationship() {
        return this.wrapper.get<DropdownItemModel[]>(
            AppConfig.apiEndpoint + "master/relationship"
        );
    }
    postLearningBehavior(model: LearningBehaviorPostModel) {
        return this.wrapper.post<boolean>(
            AppConfig.apiEndpoint + "familybackground/learning-behavior/create",
            model
        );
    }
  //**Learning and Behaviour End **
  
   // ** family-transition Take Start **
   getFamilyTransition(patientId: string) {
    return this.wrapper.get<any>(AppConfig.apiEndpoint + 'familybackground/family-transition/detail/' +patientId);
  }

  postFamilyTransition(familyTransition: AddFamilyTransitionModel) {
    return this.wrapper.post<any>(AppConfig.apiEndpoint + 'familybackground/family-transition/create', familyTransition);
  }
  // ** family-transition Take End **
  getFamilyBackgroundStepStatus(patientId: string | null) {
    return this.wrapper.get<GetFamilyBackgroundStepStatus>(
      AppConfig.apiEndpoint + "familybackground/getFamilyBackGroundStepStatus/" + patientId
    );
  }
}
