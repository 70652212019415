import React, { Component, Fragment } from 'react'
import withRouter from '../shared/withRouter/withRouter'
import dayjs from 'dayjs';
import { UserDetailResponseModel, AuthResponseModel } from '../components/auth/models';
import { AuthService } from '../components/auth/services/auth.service';
import { HttpResponse, AppConfig } from '../core';
import { Role } from '../shared/enums/role.enum';
import { SetUserInfo, GetUserInfo, Decrypt, getCookie, Encrypt, setCookie } from '../shared/helpers';

interface IState {
    tokenInterval?: any | null;
    userName: string;
    roles: string[];
    loader: boolean;
    isPassExpire: boolean;
    email: string;
    initials: string;
    userId?: string | null;
    isLoginFirstTime: boolean;
    collapsed: boolean;
  }
class ParentPage extends Component<any, IState>{
    private service: AuthService;
    constructor(props) {
      super({props});
      this.state = {
        userName: "",
        roles: [],
        loader: false,
        isPassExpire: false,
        email: "",
        initials: "",
        userId: null,
        isLoginFirstTime: false,
        collapsed: false,
      };
      this.service = new AuthService();
    }
    getUserDetail() {
      this.setState({
        loader: true,
      });
      this.service
        .getGetUserInfo()
        .then((res: HttpResponse<UserDetailResponseModel>) => {
          if (res && res.result) {
            SetUserInfo(res.result);
            this.setState({}, () => {
              var user = GetUserInfo();
              this.setState({
                userName: user.name,
                isPassExpire: user.isLastPasswordExpiring,
                email: user.email,
                initials: user.initials,
                userId: user.userId,
                isLoginFirstTime: user.isLoginFirstTime,
              });
              let roleList: string[] = [];
              user.roles?.map((x) => {
                roleList?.push(x);
              });
              this.setState({
                roles: roleList,
              });
  
              this.setState(
                {
                  loader: false,
                },
                () => {
                  if (this.state.roles.includes(Role.Parent)) {
                    if (this.state.isLoginFirstTime) {
                      this.props.navigate("/medical/welcome-screen/");
                    } else {
                      this.props.navigate("/medical/medicalhistory/");
                    }
                  } else if (this.state.roles.includes(Role.Teacher)) {
                    let id = null;
                    if (
                      this.props.location.pathname.indexOf(
                        "teacher-weekly-severity-update/"
                      ) >= 0
                    ) {
                      let location = this.props.location.pathname.split("/");
                      id = location[location.length - 1];
                    }
                    if (id != null) {
                      this.props.navigate(
                        "/medical/teacher-weekly-severity-update/" + id
                      );
                    } else {
                      this.props.navigate(
                        "/medical/teacher-weekly-severity-update/"
                      );
                    }
                  } else if (this.state.roles.includes(Role.Respondent)) {
                    let id = null;
                    if (
                      this.props.location.pathname.indexOf(
                        "respondent-weekly-severity-update/"
                      ) >= 0
                    ) {
                      let location = this.props.location.pathname.split("/");
                      id = location[location.length - 1];
                    }
                    if (id != null) {
                      this.props.navigate(
                        "/medical/respondent-weekly-severity-update/" + id
                      );
                    } else {
                      this.props.navigate(
                        "/medical/respondent-weekly-severity-update/"
                      );
                    }
                  } 
                }
              );
            });
          }
        })
        .catch((ex) => {
          if (ex.status == 401) {
            //ShowException(ex.response.result ? ex.response.result : ex.response);
            localStorage.clear();
            window.location.href = window.location.origin + "/login";
          }
        });
    }
  
    getAccessTokenByRefreshToken = () => {
      let expire_in = localStorage.getItem("expire_in") || "";
      if (expire_in) {
        let dateDiffInMillSeconds = dayjs(expire_in).diff(dayjs());
        if (dateDiffInMillSeconds < 300000) {
          //Access token is about to expire
          let authData = {
            grant_type: AppConfig.refreshTokenGrantType,
            client_id: AppConfig.clientId,
            client_secret: AppConfig.clientSecret,
            refresh_token: Decrypt<string>(getCookie(AppConfig.refreshTokenKey)),
            redirect_uri: "",
            code: null,
            audience: AppConfig.audience,
          };
          this.service
            .getAccessTokenByRefreshToken(authData)
            .then((res: HttpResponse<AuthResponseModel>) => {
              if (res && res.result) {
                let expire_in = dayjs().add(
                  parseInt(res.result.expires_in),
                  "seconds"
                );
                localStorage.setItem("lg", "true");
                localStorage.setItem("expire_in", expire_in.toString());
                localStorage.setItem(
                  AppConfig.tokenKey,
                  Encrypt(res.result.id_token)
                );
                let refresh_token = getCookie(AppConfig.refreshTokenKey);
                setCookie(
                  AppConfig.refreshTokenKey,
                  refresh_token,
                  AppConfig.refreshTokenExpiry
                );
              }
            });
        }
      }
    };
  
    componentDidMount() {
      this.setState({
        tokenInterval: null,
      });
      this.getUserDetail();
  
      let tokenInterval = setInterval(this.getAccessTokenByRefreshToken, 60000);
      this.setState({
        tokenInterval: tokenInterval,
      });
      this.getAccessTokenByRefreshToken();
    }
  
    componentWillUnmount() {
      // clear interval
      if (this.state.tokenInterval) {
        clearInterval(this.state.tokenInterval);
      }
    }
    handleUserDetail = (firstName: string, lastName: string) => {
      this.setState({
        userName: firstName + " " + lastName,
        initials:
          firstName.substring(0, 1).toUpperCase() +
          lastName.substring(0, 1).toUpperCase(),
      });
    };
    onCollapse = (collapsed) => {
          this.setState({ collapsed });
    };
    render() {
        return (
            <div>This is the parent landing page</div>
        )
    }
}

export default withRouter(ParentPage)