import { DatePicker, Tooltip } from "antd";
import moment from "moment";
import React, { Fragment } from "react";
import { ClinicianWeeklyData } from "../../../../../models/clinicianWeeklyData/clinician-weekly-data.model";
import { ClinicianWeeklyDataType } from "../../../../../shared/enums/clinicianWeeklyData/clinician-weekly-data-type";
import "./../styles.css";
import dayjs from "dayjs";

interface IProps {
    clinicianWeeklyData: ClinicianWeeklyData;
    styleName: any;
    assignWeeklyDataModel: Function;
  }
  
  export default function PatientVisit(props: IProps) {
     const visitDateChange=(visitDate: any ,i:any)=>{
      // alert(visitDate);
      //  alert(new Date(visitDate.format("MM/DD/YYYY")));
      if (visitDate == null) {
        props.clinicianWeeklyData.lstPatientVisitDetails[i]["visitDate"]=null;
      } else {
        props.clinicianWeeklyData.lstPatientVisitDetails[i]["visitDate"]= dayjs(visitDate).format("MM/DD/YYYY");
      }
      props.assignWeeklyDataModel({
        clinicianWeeklyData: props.clinicianWeeklyData,
        clinicianWeeklyDataType: ClinicianWeeklyDataType.AddVisitDate
        }); 
    }
    return (
        <Fragment>
        {props.clinicianWeeklyData.lstPatientVisitDetails &&  props.clinicianWeeklyData.lstPatientVisitDetails.map((row, i) =>  
            <div className="form-group form-field" style={{ marginBottom:"50px" }} key={"visitDate"+(i+1)}>
              <Tooltip placement="right" title="Date should be in MM/DD/YYYY format">
            <label className="form-label">Visit Date {row.visitNo}</label>
            <DatePicker
              onChange={(e) => visitDateChange(e, i)}
              className="datepicker"
              inputReadOnly={false}
              placeholder="MM/DD/YYYY"
              format="MM/DD/YYYY"
              key={"visitDatePicker"+(i+1)}
              value={row.visitDate == null ? undefined : dayjs(row.visitDate)}
            />
                    {row.visitError && (
                        <div className="invalid-feedback" style={{ display: "block", whiteSpace: "pre-line", top: "49px", letterSpacing: "0.5px", wordBreak: "keep-all" }}>
                            {row.visitError}
                          </div>
                        )}
                        </Tooltip>
          </div>
          )}
        </Fragment>
        )
}
    