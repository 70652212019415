import React, { Fragment, useEffect, useState } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import notes from "../../../assets/images/svg-icons/notes.svg";
import WeeklyStatus from "../../../components/weeklyStatus";
import Heading from "../../../components/heading";
import WeekwiseColumn from "../../../components/weekwiseColumn";
import { Button, DatePicker, Modal, Popover, TimePicker } from "antd";
import { UpOutlined } from "@ant-design/icons";
import editGray from "../../../assets/images/svg-icons/edit-gray.svg";
import WeekSliderBtn from "../../../components/weekSliderBtn";
import $ from "jquery";
import { ParentWeeklyDataModel } from "../../../models/parentWeeklyData/parent-weekly-data.model";
import moment from "moment";
import SeverityModal from "../../../components/severityModal";
import { WeeklyDataUpdateByType } from "../../../shared/enums/clinicianWeeklyData/weekly-data-update-by-type";
import ParentNote from "../parentNote";
import { Console } from "console";
import dayjs from 'dayjs'
import { ParentTargetSymptomWeeklyDataDto, ParentTargetSymptomsWeekDayData } from "../../../models/parentWeeklyData/parent-target-symptoms.model";
import { RangePickerProps } from "antd/es/date-picker";
import { ParentAdditionalQuestionWeeklyData } from "../../../models/parentWeeklyData/parent-additional-question-weekly-data";

interface IProps {
  patientId: string;
  parentWeeklyDataModel: ParentWeeklyDataModel;
  styleName: any;
  assignWeeklyDataModel?: Function;
  assignWeeklyQuestionModel: Function;
  selectedPatientName: string;
  weekNo: number;
  dayNum: number;
  startDate: dayjs.Dayjs;
}
function ChildWeekMobile(props: IProps) {
  const [formVisible, setFormVisible] = useState(false);
  //const [weekNum, setWeekNum] = useState({ start: 1, end: 4 });
  //const [prevStyleUpdate, setPrevStyleUpdate] = useState("disabledbutton");
  //const [nextStyleUpdate, setNextStyleUpdate] = useState("");

  const showForm = () => {
    setFormVisible(true);
  };
  const hideForm = () => {
    setFormVisible(false);
  };
  const [patientId, setPatientId] = useState(props.patientId);
  const [parentWeeklyDataModel, setparentWeeklyDataModel] = useState(props.parentWeeklyDataModel);
  const [dayNum, setDayNum] = useState(0);
  const [weekIndex, setweekIndex] = useState(props.weekNo);
  const [displayedDate, setDisplayedDate] = useState(dayjs());
  const [weekId, setWeekId] = useState("");
  const [dayData, setdayData] = useState<ParentTargetSymptomsWeekDayData>();
  const [dayId, setDayId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [parentWeekDayList, setParentWeekDayList] = useState<ParentTargetSymptomWeeklyDataDto[]>();
  const [parentAdditionalQuestionList, setParentAdditionalQuestionList] = useState<ParentAdditionalQuestionWeeklyData[]>();
  useEffect(() => {
    getDayData(displayedDate);
  }, []);
  let isPreviousWeek;
  
  
  const getDayData = (date : dayjs.Dayjs) => {
    let newWeekId : string | undefined;
    setIsLoading(true);
    props.parentWeeklyDataModel != null
    && props.parentWeeklyDataModel.parentTargetSymptomWeeklyDataDetail.map(
    (x) => x.parentTargetSymptomWeekDayDataList.map(
      (y) => {
        if (dayjs(y.weekDayDate).format("YYYYMMDD") == date.format("YYYYMMDD") && !y.isBaseLine){
          setWeekId(y.weekId ?? "")
          newWeekId = y.weekId
          setdayData(y);
          setDayNum(y.weekDayNo);
          console.log(y, x)
          setDayId(y.weekDayId ?? "");
          isPreviousWeek = x.isPreviousWeek;
          const newParentWeekDayList = 
          props.parentWeeklyDataModel != null
          ? props.parentWeeklyDataModel.parentTargetSymptomWeeklyDataDetail.filter(
            (item) => item.weekNo == x.weekNo
          )
        : [];
        newParentWeekDayList.forEach(item => {
          item.parentTargetSymptomWeekDayDataList.forEach(subItem => {
            subItem.ModifiedAfterWeeksEnd = isPreviousWeek;
          });
        });
        setParentWeekDayList(newParentWeekDayList);
        }
      })
    );
    const newAdditionalQuestionsList =
      props.parentWeeklyDataModel != null
        ? props.parentWeeklyDataModel.parentAdditionalQuestionDataDetail.filter(
            (x) => x.weekId == newWeekId
          )
        : [];

    newAdditionalQuestionsList.forEach(item => {
      item.ModifiedAfterWeeksEnd = isPreviousWeek
    });
    setParentAdditionalQuestionList(newAdditionalQuestionsList);
    setIsLoading(false);
  }; 
  function timeChange(time, timeString, i) {
    if(parentAdditionalQuestionList){
      parentAdditionalQuestionList[i].medicationWoreOffTime = timeString;
      props.assignWeeklyQuestionModel({
        parentAdditionalQuestionList: parentAdditionalQuestionList,
      }); 
    }
  }
  const isSelected = (type: number, isChecked, index: number) => {
    if(parentAdditionalQuestionList)
    switch (type) {
      case 1: {
        parentAdditionalQuestionList[index].isPlacebo = isChecked;
        break;
      }
      case 2: {
        parentAdditionalQuestionList[index].isPillContinued = isChecked;
        break;
      }
      case 3: {
        parentAdditionalQuestionList[index].isSideEffectObserved = isChecked;
        break;
      }
      case 4: {
        parentAdditionalQuestionList[index].isDosageMissed = isChecked;
        break;
      }
      default: {
        break;
      }
    }
    props.assignWeeklyQuestionModel({
      parentAdditionalQuestionList: parentAdditionalQuestionList,
    });
  };
  const getSeverityText = (value?: number) => {
    let severityText = "";
    switch (value) {
      case 1:
        severityText = "Normal; Not at all 1";
        break;
      case 2:
        severityText = "Borderline Problem 2";
        break;
      case 4:
        severityText = "Moderate Problem 4";
        break;
      case 3:
        severityText = "Mild Problem 3";
        break;
      case 5:
        severityText = "Marked Problem 5";
        break;
      case 6:
        severityText = "Severe Problem 6";
        break;
      case 7:
        severityText = "Most Extreme Problem 7";
        break;
      default:
        severityText = "";
        break;
    }
    return severityText;
  };
  const getStatusStyle = (value?: number, weekDay?: number) => {
    let statusStyle = "";
    if (value != null) {
      if (value == 5) {
        statusStyle = "weekModerate";
      } else if (value > 5) {
        statusStyle = "weekExtreme";
      }
    } else if (value == null && weekDay == 0) {
      statusStyle = "weekMissedOut";
    }
    return statusStyle;
  };
  const getBorderStyle = (value?: number) => {
    let borderStyle = "";
    if (value != null) {
      if (value == 5) {
        borderStyle = "1px solid var(--color-fadeLightOrange)";
      } else if (value > 5) {
        borderStyle = "1px solid var(--color-reddish)";
      }
    }
    return borderStyle;
  };
  const weekDotStyle = (value?: number) => {
    let dotStyle = "";
    if (value != null) {
      if (value == 5) {
        dotStyle = "weekOrange";
      } else if (value > 5) {
        dotStyle = "weekExtremeDot";
      }
    }
    return dotStyle;
  };
  const scrollTop = () => {
    window.document
      .getElementById("scrollWrap")!
      .scrollTo({ top: 0, behavior: "smooth" });
  };
  const changeDate = (data) => {
    console.log(data)
    setDisplayedDate(data);
    getDayData(data);
  }

  const disabledDates: RangePickerProps['disabledDate'] = (day) => {
    let earliestStart = dayjs(props.startDate);
    let latestStart = dayjs().endOf('day');
    return earliestStart > day || latestStart < day;
  };
    return (
      <Fragment>
        <ParentNote
          visible={formVisible}
          patientId={props.patientId}
          showForm={showForm}
          hideForm={hideForm}
        />

        <div className="weekly__statusWrap">
          {/* <WeeklyStatus
          weeklyHeading={
            parentWeekDayList &&
            parentWeekDayList[0] &&
            "Fill the severity level of " +
              props.selectedPatientName +
              " " +
              "for " +
              dayjs(new Date().toLocaleString("en-US", { timeZone: "America/New_York" })).format("dddd") +
              " of Week " +
              parentWeekDayList[0].weekNo +
              "."
          }
          statusStyle=""
        /> */}
          <DatePicker 
            value={displayedDate} 
            onChange={changeDate}
            allowClear={false}
            disabledDate={disabledDates}
          />
          <div className="week__noteBtn">
            <Button
              type="primary"
              size="large"
              className="week__note"
              icon={<img src={notes} alt="notes" className="week__note_img" />}
              onClick={showForm}
            >
              Add Note
            </Button>
          </div>
        </div>
        <div id="scrollWrap" className="weeklyChildTable__container">
          <table>
            <thead>
              <tr className="side__effects">
                <th>
                  <Heading heading="Target Symptoms" styleName="weekHeading" />
                </th>
                {parentWeekDayList &&
                  parentWeekDayList[0].parentTargetSymptomWeekDayDataList.map(
                    (item, index) => {
                      return (
                        <Fragment>
                          <th
                            style={{
                              backgroundColor:
                                item.weekDayType == 2 ? "#CCE4CB" : "",
                              display:
                                item.weekDayNo == dayNum
                                  ? "table-cell"
                                  : "none",
                            }}
                            key={"week_day_header" + index}
                          >
                            {item.weekDayType === 2 && (
                              <span className="selected__dot"></span>
                            )}
                            <Heading
                              heading={item.weekDayName}
                              subHeading={dayjs(item.weekDayDate).format(
                                "MM/DD/YYYY"
                              )}
                              styleName="weekwise__head"
                              ccfSubHeading="week__subHead"
                            />
                          </th>
                        </Fragment>
                      );
                    }
                  )}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Heading
                    heading=""
                    subHeading="Overactivity; motor restlessness"
                    ccfSubHeading="week__subCol"
                  />
                </td>
                {parentWeekDayList &&
                  parentWeekDayList[0].parentTargetSymptomWeekDayDataList.map(
                    (targetSymptoms, index) => {
                      return (
                        <Fragment>
                          {targetSymptoms.weekDayNo == dayNum &&
                            targetSymptoms.weekDayType != 3 && (
                              <td key={"overActivity" + index}>
                                <WeekwiseColumn
                                  problemName={
                                    targetSymptoms.overActivity == null
                                      ? "Severity Rating"
                                      : ""
                                  }
                                  marked={getSeverityText(
                                    targetSymptoms.overActivity
                                  )}
                                  statusStyle={getStatusStyle(
                                    targetSymptoms.overActivity,
                                    targetSymptoms.weekDayType
                                  )}
                                  border={getBorderStyle(
                                    targetSymptoms.overActivity
                                  )}
                                  padding={
                                    targetSymptoms.overActivity != null &&
                                    targetSymptoms.overActivity > 4
                                      ? "8px 5px"
                                      : ""
                                  }
                                  weekDot={weekDotStyle(
                                    targetSymptoms.overActivity
                                  )}
                                  missedOut={targetSymptoms.weekDayType == 0}
                                  missed={
                                    targetSymptoms.weekDayType == 0 &&
                                    targetSymptoms.overActivity == null
                                      ? "Missed Out"
                                      : ""
                                  }
                                  popover__align="popover__align"
                                  contents={[
                                    <Popover
                                      placement="rightTop"
                                      getPopupContainer={() =>
                                        document.getElementById("scrollWrap")!
                                      }
                                      content={
                                        <SeverityModal
                                          weeklyDataUpdateByType={
                                            WeeklyDataUpdateByType.Parent
                                          }
                                          weekIndex={index}
                                          parentWeeklyData={
                                            parentWeekDayList[0]
                                              .parentTargetSymptomWeekDayDataList
                                          }
                                          assignWeeklyDataModel={
                                            props.assignWeeklyDataModel
                                          }
                                          propName="overActivity"
                                          popOverId={
                                            "divOverActivity" + (index + 1)
                                          }
                                          key={"divOverActivity" + (index + 1)}
                                        />
                                      }
                                      trigger="click"
                                    >
                                      <div id={"divOverActivity" + (index + 1)}>
                                        {targetSymptoms.overActivity ==
                                          null && (
                                          <PlusCircleOutlined className="addSeverity" />
                                        )}
                                        {targetSymptoms.overActivity !=
                                          null && (
                                          <span className="weekSev__edit">
                                            <img
                                              src={editGray}
                                              alt="line"
                                              className="addSeverity"
                                            />
                                          </span>
                                        )}
                                      </div>
                                    </Popover>,
                                  ]}
                                />
                              </td>
                            )}
                        </Fragment>
                      );
                    }
                  )}

                {parentWeekDayList &&
                  parentWeekDayList[0].parentTargetSymptomWeekDayDataList.map(
                    (targetSymptoms, index) => {
                      return (
                        <Fragment>
                          {targetSymptoms.weekDayNo == dayNum &&
                            targetSymptoms.weekDayType == 3 && (
                              <td key={"future__weeks" + index} rowSpan={10}>
                                <div className="future__weeks">
                                  {" "}
                                  This Patient Severity columm will be available
                                  on
                                  <br />
                                  <span className="month__name">
                                    {" "}
                                    {dayjs(targetSymptoms.weekDayDate).format(
                                      "MMM"
                                    )}
                                  </span>
                                  <br />
                                  <span className="month__date">
                                    {" "}
                                    {dayjs(targetSymptoms.weekDayDate).format(
                                      "DD"
                                    )}
                                  </span>
                                </div>
                              </td>
                            )}{" "}
                        </Fragment>
                      );
                    }
                  )}
              </tr>
              <tr>
                <td>
                  <Heading
                    heading=""
                    subHeading="Impulsiveness; acting w/o thinking"
                    ccfSubHeading="week__subCol"
                  />
                </td>
                {parentWeekDayList &&
                  parentWeekDayList[0].parentTargetSymptomWeekDayDataList.map(
                    (targetSymptoms, index) => {
                      return (
                        <Fragment>
                          {targetSymptoms.weekDayNo == dayNum &&
                            targetSymptoms.weekDayType != 3 && (
                              <td key={"impulsiveness" + index}>
                                <WeekwiseColumn
                                  problemName={
                                    targetSymptoms.impulsiveness == null
                                      ? "Severity Rating"
                                      : ""
                                  }
                                  marked={getSeverityText(
                                    targetSymptoms.impulsiveness
                                  )}
                                  statusStyle={getStatusStyle(
                                    targetSymptoms.impulsiveness,
                                    targetSymptoms.weekDayType
                                  )}
                                  border={getBorderStyle(
                                    targetSymptoms.impulsiveness
                                  )}
                                  padding={
                                    targetSymptoms.impulsiveness != null &&
                                    targetSymptoms.impulsiveness > 4
                                      ? "8px 5px"
                                      : ""
                                  }
                                  weekDot={weekDotStyle(
                                    targetSymptoms.impulsiveness
                                  )}
                                  missedOut={targetSymptoms.weekDayType == 0}
                                  missed={
                                    targetSymptoms.weekDayType == 0 &&
                                    targetSymptoms.impulsiveness == null
                                      ? "Missed Out"
                                      : ""
                                  }
                                  popover__align="popover__align"
                                  contents={[
                                    <Popover
                                      placement="rightTop"
                                      getPopupContainer={() =>
                                        document.getElementById("scrollWrap")!
                                      }
                                      content={
                                        <SeverityModal
                                          weeklyDataUpdateByType={
                                            WeeklyDataUpdateByType.Parent
                                          }
                                          weekIndex={index}
                                          parentWeeklyData={
                                            parentWeekDayList[0]
                                              .parentTargetSymptomWeekDayDataList
                                          }
                                          assignWeeklyDataModel={
                                            props.assignWeeklyDataModel
                                          }
                                          propName="impulsiveness"
                                          popOverId={
                                            "divImpulsiveness" + (index + 1)
                                          }
                                          key={"divImpulsiveness" + (index + 1)}
                                        />
                                      }
                                      trigger="click"
                                    >
                                      <div
                                        id={"divImpulsiveness" + (index + 1)}
                                      >
                                        {targetSymptoms.weekDayType != 0 &&
                                          targetSymptoms.impulsiveness ==
                                            null && (
                                            <PlusCircleOutlined className="addSeverity" />
                                          )}
                                        {targetSymptoms.impulsiveness !=
                                            null && (
                                            <span className="weekSev__edit">
                                              <img
                                                src={editGray}
                                                alt="line"
                                                className="addSeverity"
                                              />
                                            </span>
                                          )}
                                      </div>
                                    </Popover>,
                                  ]}
                                />
                              </td>
                            )}
                        </Fragment>
                      );
                    }
                  )}
              </tr>
              <tr>
                <td>
                  <Heading
                    heading=""
                    subHeading="Distractability; sustaining attention to tasks"
                    ccfSubHeading="week__subCol"
                  />
                </td>
                {parentWeekDayList &&
                  parentWeekDayList[0].parentTargetSymptomWeekDayDataList.map(
                    (targetSymptoms, index) => {
                      return (
                        <Fragment>
                          {targetSymptoms.weekDayNo == dayNum &&
                            targetSymptoms.weekDayType != 3 && (
                              <td key={"distractability" + index}>
                                <WeekwiseColumn
                                  problemName={
                                    targetSymptoms.distractability == null
                                      ? "Severity Rating"
                                      : ""
                                  }
                                  marked={getSeverityText(
                                    targetSymptoms.distractability
                                  )}
                                  statusStyle={getStatusStyle(
                                    targetSymptoms.distractability,
                                    targetSymptoms.weekDayType
                                  )}
                                  border={getBorderStyle(
                                    targetSymptoms.distractability
                                  )}
                                  padding={
                                    targetSymptoms.distractability != null &&
                                    targetSymptoms.distractability > 4
                                      ? "8px 5px"
                                      : ""
                                  }
                                  weekDot={weekDotStyle(
                                    targetSymptoms.distractability
                                  )}
                                  missedOut={targetSymptoms.weekDayType == 0}
                                  missed={
                                    targetSymptoms.weekDayType == 0 &&
                                    targetSymptoms.distractability == null
                                      ? "Missed Out"
                                      : ""
                                  }
                                  popover__align="popover__align"
                                  contents={[
                                    <Popover
                                      placement="rightTop"
                                      getPopupContainer={() =>
                                        document.getElementById("scrollWrap")!
                                      }
                                      content={
                                        <SeverityModal
                                          weeklyDataUpdateByType={
                                            WeeklyDataUpdateByType.Parent
                                          }
                                          weekIndex={index}
                                          parentWeeklyData={
                                            parentWeekDayList[0]
                                              .parentTargetSymptomWeekDayDataList
                                          }
                                          assignWeeklyDataModel={
                                            props.assignWeeklyDataModel
                                          }
                                          propName="distractability"
                                          popOverId={
                                            "divDistractability" + (index + 1)
                                          }
                                          key={
                                            "divDistractability" + (index + 1)
                                          }
                                        />
                                      }
                                      trigger="click"
                                    >
                                      <div
                                        id={"divDistractability" + (index + 1)}
                                      >
                                        {targetSymptoms.weekDayType != 0 &&
                                          targetSymptoms.distractability ==
                                            null && (
                                            <PlusCircleOutlined className="addSeverity" />
                                          )}
                                        {targetSymptoms.distractability !=
                                            null && (
                                            <span className="weekSev__edit">
                                              <img
                                                src={editGray}
                                                alt="line"
                                                className="addSeverity"
                                              />
                                            </span>
                                          )}
                                      </div>
                                    </Popover>,
                                  ]}
                                />
                              </td>
                            )}
                        </Fragment>
                      );
                    }
                  )}
              </tr>
              <tr>
                <td>
                  <Heading
                    heading=""
                    subHeading="Task completion; finishing tasks"
                    ccfSubHeading="week__subCol"
                  />
                </td>
                {parentWeekDayList &&
                  parentWeekDayList[0].parentTargetSymptomWeekDayDataList.map(
                    (targetSymptoms, index) => {
                      return (
                        <Fragment>
                          {targetSymptoms.weekDayNo == dayNum &&
                            targetSymptoms.weekDayType != 3 && (
                              <td key={"taskCompletion" + index}>
                                <WeekwiseColumn
                                  problemName={
                                    targetSymptoms.taskCompletion == null
                                      ? "Severity Rating"
                                      : ""
                                  }
                                  marked={getSeverityText(
                                    targetSymptoms.taskCompletion
                                  )}
                                  statusStyle={getStatusStyle(
                                    targetSymptoms.taskCompletion,
                                    targetSymptoms.weekDayType
                                  )}
                                  border={getBorderStyle(
                                    targetSymptoms.taskCompletion
                                  )}
                                  padding={
                                    targetSymptoms.taskCompletion != null &&
                                    targetSymptoms.taskCompletion > 4
                                      ? "8px 5px"
                                      : ""
                                  }
                                  weekDot={weekDotStyle(
                                    targetSymptoms.taskCompletion
                                  )}
                                  missedOut={targetSymptoms.weekDayType == 0}
                                  missed={
                                    targetSymptoms.weekDayType == 0 &&
                                    targetSymptoms.taskCompletion == null
                                      ? "Missed Out"
                                      : ""
                                  }
                                  popover__align="popover__align"
                                  contents={[
                                    <Popover
                                      placement="rightTop"
                                      getPopupContainer={() =>
                                        document.getElementById("scrollWrap")!
                                      }
                                      content={
                                        <SeverityModal
                                          weeklyDataUpdateByType={
                                            WeeklyDataUpdateByType.Parent
                                          }
                                          weekIndex={index}
                                          parentWeeklyData={
                                            parentWeekDayList[0]
                                              .parentTargetSymptomWeekDayDataList
                                          }
                                          assignWeeklyDataModel={
                                            props.assignWeeklyDataModel
                                          }
                                          propName="taskCompletion"
                                          popOverId={
                                            "divTaskCompletion" + (index + 1)
                                          }
                                          key={
                                            "divTaskCompletion" + (index + 1)
                                          }
                                        />
                                      }
                                      trigger="click"
                                    >
                                      <div
                                        id={"divTaskCompletion" + (index + 1)}
                                      >
                                        {targetSymptoms.weekDayType != 0 &&
                                          targetSymptoms.taskCompletion ==
                                            null && (
                                            <PlusCircleOutlined className="addSeverity" />
                                          )}
                                        {targetSymptoms.taskCompletion !=
                                            null && (
                                            <span className="weekSev__edit">
                                              <img
                                                src={editGray}
                                                alt="line"
                                                className="addSeverity"
                                              />
                                            </span>
                                          )}
                                      </div>
                                    </Popover>,
                                  ]}
                                />
                              </td>
                            )}
                        </Fragment>
                      );
                    }
                  )}
              </tr>
              <tr>
                <td>
                  <Heading
                    heading=""
                    subHeading="Being on time; accepting limits"
                    ccfSubHeading="week__subCol"
                  />
                </td>
                {parentWeekDayList &&
                  parentWeekDayList[0].parentTargetSymptomWeekDayDataList.map(
                    (targetSymptoms, index) => {
                      return (
                        <Fragment>
                          {targetSymptoms.weekDayNo == dayNum &&
                            targetSymptoms.weekDayType != 3 && (
                              <td key={"beingOnTime" + index}>
                                <WeekwiseColumn
                                  problemName={
                                    targetSymptoms.beingOnTime == null
                                      ? "Severity Rating"
                                      : ""
                                  }
                                  marked={getSeverityText(
                                    targetSymptoms.beingOnTime
                                  )}
                                  statusStyle={getStatusStyle(
                                    targetSymptoms.beingOnTime,
                                    targetSymptoms.weekDayType
                                  )}
                                  border={getBorderStyle(
                                    targetSymptoms.beingOnTime
                                  )}
                                  padding={
                                    targetSymptoms.beingOnTime != null &&
                                    targetSymptoms.beingOnTime > 4
                                      ? "8px 5px"
                                      : ""
                                  }
                                  weekDot={weekDotStyle(
                                    targetSymptoms.beingOnTime
                                  )}
                                  missedOut={targetSymptoms.weekDayType == 0}
                                  missed={
                                    targetSymptoms.weekDayType == 0 &&
                                    targetSymptoms.beingOnTime == null
                                      ? "Missed Out"
                                      : ""
                                  }
                                  popover__align="popover__align"
                                  contents={[
                                    <Popover
                                      placement="rightTop"
                                      getPopupContainer={() =>
                                        document.getElementById("scrollWrap")!
                                      }
                                      content={
                                        <SeverityModal
                                          weeklyDataUpdateByType={
                                            WeeklyDataUpdateByType.Parent
                                          }
                                          weekIndex={index}
                                          parentWeeklyData={
                                            parentWeekDayList[0]
                                              .parentTargetSymptomWeekDayDataList
                                          }
                                          assignWeeklyDataModel={
                                            props.assignWeeklyDataModel
                                          }
                                          propName="beingOnTime"
                                          popOverId={
                                            "divBeingOnTime" + (index + 1)
                                          }
                                          key={"divBeingOnTime" + (index + 1)}
                                        />
                                      }
                                      trigger="click"
                                    >
                                      <div id={"divBeingOnTime" + (index + 1)}>
                                        {targetSymptoms.weekDayType != 0 &&
                                          targetSymptoms.beingOnTime ==
                                            null && (
                                            <PlusCircleOutlined className="addSeverity" />
                                          )}
                                        {targetSymptoms.beingOnTime !=
                                            null && (
                                            <span className="weekSev__edit">
                                              <img
                                                src={editGray}
                                                alt="line"
                                                className="addSeverity"
                                              />
                                            </span>
                                          )}
                                      </div>
                                    </Popover>,
                                  ]}
                                />
                              </td>
                            )}
                        </Fragment>
                      );
                    }
                  )}
              </tr>
              <tr>
                <td>
                  <Heading
                    heading=""
                    subHeading="Following instructions"
                    ccfSubHeading="week__subCol"
                  />
                </td>
                {parentWeekDayList &&
                  parentWeekDayList[0].parentTargetSymptomWeekDayDataList.map(
                    (targetSymptoms, index) => {
                      return (
                        <Fragment>
                          {targetSymptoms.weekDayNo == dayNum &&
                            targetSymptoms.weekDayType != 3 && (
                              <td key={"followingInstructions" + index}>
                                <WeekwiseColumn
                                  problemName={
                                    targetSymptoms.followingInstructions == null
                                      ? "Severity Rating"
                                      : ""
                                  }
                                  marked={getSeverityText(
                                    targetSymptoms.followingInstructions
                                  )}
                                  statusStyle={getStatusStyle(
                                    targetSymptoms.followingInstructions,
                                    targetSymptoms.weekDayType
                                  )}
                                  border={getBorderStyle(
                                    targetSymptoms.followingInstructions
                                  )}
                                  padding={
                                    targetSymptoms.followingInstructions !=
                                      null &&
                                    targetSymptoms.followingInstructions > 4
                                      ? "8px 5px"
                                      : ""
                                  }
                                  weekDot={weekDotStyle(
                                    targetSymptoms.followingInstructions
                                  )}
                                  missedOut={targetSymptoms.weekDayType == 0}
                                  missed={
                                    targetSymptoms.weekDayType == 0 &&
                                    targetSymptoms.followingInstructions == null
                                      ? "Missed Out"
                                      : ""
                                  }
                                  popover__align="popover__align"
                                  contents={[
                                    <Popover
                                      placement="rightTop"
                                      getPopupContainer={() =>
                                        document.getElementById("scrollWrap")!
                                      }
                                      content={
                                        <SeverityModal
                                          weeklyDataUpdateByType={
                                            WeeklyDataUpdateByType.Parent
                                          }
                                          weekIndex={index}
                                          parentWeeklyData={
                                            parentWeekDayList[0]
                                              .parentTargetSymptomWeekDayDataList
                                          }
                                          assignWeeklyDataModel={
                                            props.assignWeeklyDataModel
                                          }
                                          propName="followingInstructions"
                                          popOverId={
                                            "divFollowingInstructions" +
                                            (index + 1)
                                          }
                                          key={
                                            "divFollowingInstructions" +
                                            (index + 1)
                                          }
                                        />
                                      }
                                      trigger="click"
                                    >
                                      <div
                                        id={
                                          "divFollowingInstructions" +
                                          (index + 1)
                                        }
                                      >
                                        {targetSymptoms.weekDayType != 0 &&
                                          targetSymptoms.followingInstructions ==
                                            null && (
                                            <PlusCircleOutlined className="addSeverity" />
                                          )}
                                        {targetSymptoms.followingInstructions !=
                                            null && (
                                            <span className="weekSev__edit">
                                              <img
                                                src={editGray}
                                                alt="line"
                                                className="addSeverity"
                                              />
                                            </span>
                                          )}
                                      </div>
                                    </Popover>,
                                  ]}
                                />
                              </td>
                            )}
                        </Fragment>
                      );
                    }
                  )}
              </tr>
              <tr>
                <td>
                  <Heading
                    heading=""
                    subHeading="Frustration tolerance; appropriately expressing frustration"
                    ccfSubHeading="week__subCol"
                  />
                </td>
                {parentWeekDayList &&
                  parentWeekDayList[0].parentTargetSymptomWeekDayDataList.map(
                    (targetSymptoms, index) => {
                      return (
                        <Fragment>
                          {targetSymptoms.weekDayNo == dayNum &&
                            targetSymptoms.weekDayType != 3 && (
                              <td key={"frustrationTolerance" + index}>
                                <WeekwiseColumn
                                  problemName={
                                    targetSymptoms.frustrationTolerance == null
                                      ? "Severity Rating"
                                      : ""
                                  }
                                  marked={getSeverityText(
                                    targetSymptoms.frustrationTolerance
                                  )}
                                  statusStyle={getStatusStyle(
                                    targetSymptoms.frustrationTolerance,
                                    targetSymptoms.weekDayType
                                  )}
                                  border={getBorderStyle(
                                    targetSymptoms.frustrationTolerance
                                  )}
                                  padding={
                                    targetSymptoms.frustrationTolerance !=
                                      null &&
                                    targetSymptoms.frustrationTolerance > 4
                                      ? "8px 5px"
                                      : ""
                                  }
                                  weekDot={weekDotStyle(
                                    targetSymptoms.frustrationTolerance
                                  )}
                                  missedOut={targetSymptoms.weekDayType == 0}
                                  missed={
                                    targetSymptoms.weekDayType == 0 &&
                                    targetSymptoms.frustrationTolerance == null
                                      ? "Missed Out"
                                      : ""
                                  }
                                  popover__align="popover__align"
                                  contents={[
                                    <Popover
                                      placement="rightTop"
                                      getPopupContainer={() =>
                                        document.getElementById("scrollWrap")!
                                      }
                                      content={
                                        <SeverityModal
                                          weeklyDataUpdateByType={
                                            WeeklyDataUpdateByType.Parent
                                          }
                                          weekIndex={index}
                                          parentWeeklyData={
                                            parentWeekDayList[0]
                                              .parentTargetSymptomWeekDayDataList
                                          }
                                          assignWeeklyDataModel={
                                            props.assignWeeklyDataModel
                                          }
                                          propName="frustrationTolerance"
                                          popOverId={
                                            "divFrustrationTolerance" +
                                            (index + 1)
                                          }
                                          key={
                                            "divFrustrationTolerance" +
                                            (index + 1)
                                          }
                                        />
                                      }
                                      trigger="click"
                                    >
                                      <div
                                        id={
                                          "divFrustrationTolerance" +
                                          (index + 1)
                                        }
                                      >
                                        {targetSymptoms.weekDayType != 0 &&
                                          targetSymptoms.frustrationTolerance ==
                                            null && (
                                            <PlusCircleOutlined className="addSeverity" />
                                          )}
                                        {targetSymptoms.frustrationTolerance !=
                                            null && (
                                            <span className="weekSev__edit">
                                              <img
                                                src={editGray}
                                                alt="line"
                                                className="addSeverity"
                                              />
                                            </span>
                                          )}
                                      </div>
                                    </Popover>,
                                  ]}
                                />
                              </td>
                            )}
                        </Fragment>
                      );
                    }
                  )}
              </tr>
              <tr>
                <td>
                  <Heading
                    heading=""
                    subHeading="Ability to calm self when excited"
                    ccfSubHeading="week__subCol"
                  />
                </td>
                {parentWeekDayList &&
                  parentWeekDayList[0].parentTargetSymptomWeekDayDataList.map(
                    (targetSymptoms, index) => {
                      return (
                        <Fragment>
                          {targetSymptoms.weekDayNo == dayNum &&
                            targetSymptoms.weekDayType != 3 && (
                              <td key={"abilitytoCalmSelfWhenExcited" + index}>
                                <WeekwiseColumn
                                  problemName={
                                    targetSymptoms.abilitytoCalmSelfWhenExcited ==
                                    null
                                      ? "Severity Rating"
                                      : ""
                                  }
                                  marked={getSeverityText(
                                    targetSymptoms.abilitytoCalmSelfWhenExcited
                                  )}
                                  statusStyle={getStatusStyle(
                                    targetSymptoms.abilitytoCalmSelfWhenExcited,
                                    targetSymptoms.weekDayType
                                  )}
                                  border={getBorderStyle(
                                    targetSymptoms.abilitytoCalmSelfWhenExcited
                                  )}
                                  padding={
                                    targetSymptoms.abilitytoCalmSelfWhenExcited !=
                                      null &&
                                    targetSymptoms.abilitytoCalmSelfWhenExcited >
                                      4
                                      ? "8px 5px"
                                      : ""
                                  }
                                  weekDot={weekDotStyle(
                                    targetSymptoms.abilitytoCalmSelfWhenExcited
                                  )}
                                  missedOut={targetSymptoms.weekDayType == 0}
                                  missed={
                                    targetSymptoms.weekDayType == 0 &&
                                    targetSymptoms.abilitytoCalmSelfWhenExcited ==
                                      null
                                      ? "Missed Out"
                                      : ""
                                  }
                                  popover__align="popover__align"
                                  contents={[
                                    <Popover
                                      placement="rightTop"
                                      getPopupContainer={() =>
                                        document.getElementById("scrollWrap")!
                                      }
                                      content={
                                        <SeverityModal
                                          weeklyDataUpdateByType={
                                            WeeklyDataUpdateByType.Parent
                                          }
                                          weekIndex={index}
                                          parentWeeklyData={
                                            parentWeekDayList[0]
                                              .parentTargetSymptomWeekDayDataList
                                          }
                                          assignWeeklyDataModel={
                                            props.assignWeeklyDataModel
                                          }
                                          propName="abilitytoCalmSelfWhenExcited"
                                          popOverId={
                                            "divAbilitytoCalmSelfWhenExcited" +
                                            (index + 1)
                                          }
                                          key={
                                            "divAbilitytoCalmSelfWhenExcited" +
                                            (index + 1)
                                          }
                                        />
                                      }
                                      trigger="click"
                                    >
                                      <div
                                        id={
                                          "divAbilitytoCalmSelfWhenExcited" +
                                          (index + 1)
                                        }
                                      >
                                        {targetSymptoms.weekDayType != 0 &&
                                          targetSymptoms.abilitytoCalmSelfWhenExcited ==
                                            null && (
                                            <PlusCircleOutlined className="addSeverity" />
                                          )}
                                        {targetSymptoms.abilitytoCalmSelfWhenExcited !=
                                            null && (
                                            <span className="weekSev__edit">
                                              <img
                                                src={editGray}
                                                alt="line"
                                                className="addSeverity"
                                              />
                                            </span>
                                          )}
                                      </div>
                                    </Popover>,
                                  ]}
                                />
                              </td>
                            )}
                        </Fragment>
                      );
                    }
                  )}
              </tr>
              <tr>
                <td>
                  <Heading
                    heading=""
                    subHeading="Peer relations"
                    ccfSubHeading="week__subCol"
                  />
                </td>
                {parentWeekDayList &&
                  parentWeekDayList[0].parentTargetSymptomWeekDayDataList.map(
                    (targetSymptoms, index) => {
                      return (
                        <Fragment>
                          {targetSymptoms.weekDayNo == dayNum &&
                            targetSymptoms.weekDayType != 3 && (
                              <td key={"peerRelations" + index}>
                                <WeekwiseColumn
                                  problemName={
                                    targetSymptoms.peerRelations == null
                                      ? "Severity Rating"
                                      : ""
                                  }
                                  marked={getSeverityText(
                                    targetSymptoms.peerRelations
                                  )}
                                  statusStyle={getStatusStyle(
                                    targetSymptoms.peerRelations,
                                    targetSymptoms.weekDayType
                                  )}
                                  border={getBorderStyle(
                                    targetSymptoms.peerRelations
                                  )}
                                  padding={
                                    targetSymptoms.peerRelations != null &&
                                    targetSymptoms.peerRelations > 4
                                      ? "8px 5px"
                                      : ""
                                  }
                                  weekDot={weekDotStyle(
                                    targetSymptoms.peerRelations
                                  )}
                                  missedOut={targetSymptoms.weekDayType == 0}
                                  missed={
                                    targetSymptoms.weekDayType == 0 &&
                                    targetSymptoms.peerRelations == null
                                      ? "Missed Out"
                                      : ""
                                  }
                                  popover__align="popover__align"
                                  contents={[
                                    <Popover
                                      placement="rightTop"
                                      getPopupContainer={() =>
                                        document.getElementById("scrollWrap")!
                                      }
                                      content={
                                        <SeverityModal
                                          weeklyDataUpdateByType={
                                            WeeklyDataUpdateByType.Parent
                                          }
                                          weekIndex={index}
                                          parentWeeklyData={
                                            parentWeekDayList[0]
                                              .parentTargetSymptomWeekDayDataList
                                          }
                                          assignWeeklyDataModel={
                                            props.assignWeeklyDataModel
                                          }
                                          propName="peerRelations"
                                          popOverId={
                                            "divPeerRelations" + (index + 1)
                                          }
                                          key={"divPeerRelations" + (index + 1)}
                                        />
                                      }
                                      trigger="click"
                                    >
                                      <div
                                        id={"divPeerRelations" + (index + 1)}
                                      >
                                        {targetSymptoms.weekDayType != 0 &&
                                          targetSymptoms.peerRelations ==
                                            null && (
                                            <PlusCircleOutlined className="addSeverity" />
                                          )}
                                        {targetSymptoms.peerRelations !=
                                            null && (
                                            <span className="weekSev__edit">
                                              <img
                                                src={editGray}
                                                alt="line"
                                                className="addSeverity"
                                              />
                                            </span>
                                          )}
                                      </div>
                                    </Popover>,
                                  ]}
                                />
                              </td>
                            )}
                        </Fragment>
                      );
                    }
                  )}
              </tr>
              <tr>
                <td>
                  <Heading
                    heading=""
                    subHeading="Adults relations"
                    ccfSubHeading="week__subCol"
                  />
                </td>
                {parentWeekDayList &&
                  parentWeekDayList[0].parentTargetSymptomWeekDayDataList.map(
                    (targetSymptoms, index) => {
                      return (
                        <Fragment>
                          {targetSymptoms.weekDayNo == dayNum &&
                            targetSymptoms.weekDayType != 3 && (
                              <td key={"adultsRelations" + index}>
                                <WeekwiseColumn
                                  problemName={
                                    targetSymptoms.adultsRelations == null
                                      ? "Severity Rating"
                                      : ""
                                  }
                                  marked={getSeverityText(
                                    targetSymptoms.adultsRelations
                                  )}
                                  statusStyle={getStatusStyle(
                                    targetSymptoms.adultsRelations,
                                    targetSymptoms.weekDayType
                                  )}
                                  border={getBorderStyle(
                                    targetSymptoms.adultsRelations
                                  )}
                                  padding={
                                    targetSymptoms.adultsRelations != null &&
                                    targetSymptoms.adultsRelations > 4
                                      ? "8px 5px"
                                      : ""
                                  }
                                  weekDot={weekDotStyle(
                                    targetSymptoms.adultsRelations
                                  )}
                                  missedOut={targetSymptoms.weekDayType == 0}
                                  missed={
                                    targetSymptoms.weekDayType == 0 &&
                                    targetSymptoms.adultsRelations == null
                                      ? "Missed Out"
                                      : ""
                                  }
                                  popover__align="popover__align"
                                  contents={[
                                    <Popover
                                      placement="rightTop"
                                      getPopupContainer={() =>
                                        document.getElementById("scrollWrap")!
                                      }
                                      content={
                                        <SeverityModal
                                          weeklyDataUpdateByType={
                                            WeeklyDataUpdateByType.Parent
                                          }
                                          weekIndex={index}
                                          parentWeeklyData={
                                            parentWeekDayList[0]
                                              .parentTargetSymptomWeekDayDataList
                                          }
                                          assignWeeklyDataModel={
                                            props.assignWeeklyDataModel
                                          }
                                          propName="adultsRelations"
                                          popOverId={
                                            "divAdultsRelations" + (index + 1)
                                          }
                                          key={
                                            "divAdultsRelations" + (index + 1)
                                          }
                                        />
                                      }
                                      trigger="click"
                                    >
                                      <div
                                        id={"divAdultsRelations" + (index + 1)}
                                      >
                                        {targetSymptoms.weekDayType != 0 &&
                                          targetSymptoms.adultsRelations ==
                                            null && (
                                            <PlusCircleOutlined className="addSeverity" />
                                          )}
                                        {targetSymptoms.adultsRelations !=
                                            null && (
                                            <span className="weekSev__edit">
                                              <img
                                                src={editGray}
                                                alt="line"
                                                className="addSeverity"
                                              />
                                            </span>
                                          )}
                                      </div>
                                    </Popover>,
                                  ]}
                                />
                              </td>
                            )}
                        </Fragment>
                      );
                    }
                  )}
              </tr>
              {/* added target behavior ---add functionality */}
              {parentWeeklyDataModel.targetOneAlias && (
                <tr>
                  <td>
                    <Heading
                      heading=""
                      subHeading={parentWeeklyDataModel.targetOneAlias}
                      ccfSubHeading="week__subCol"
                    />
                  </td>
                  {parentWeekDayList &&
                    parentWeekDayList[0].parentTargetSymptomWeekDayDataList.map(
                      (targetSymptoms, index) => {
                        return (
                          <Fragment>
                            {targetSymptoms.weekDayNo == dayNum &&
                              targetSymptoms.weekDayType != 3 && (
                                <td key={"targetOneAlias" + index}>
                                  <WeekwiseColumn
                                    problemName={
                                      targetSymptoms.targetOneAlias == null
                                        ? "Severity Rating"
                                        : ""
                                    }
                                    marked={getSeverityText(
                                      targetSymptoms.targetOneAlias
                                    )}
                                    statusStyle={getStatusStyle(
                                      targetSymptoms.targetOneAlias,
                                      targetSymptoms.weekDayType
                                    )}
                                    border={getBorderStyle(
                                      targetSymptoms.targetOneAlias
                                    )}
                                    padding={
                                      targetSymptoms.targetOneAlias != null &&
                                      targetSymptoms.targetOneAlias > 4
                                        ? "8px 5px"
                                        : ""
                                    }
                                    weekDot={weekDotStyle(
                                      targetSymptoms.targetOneAlias
                                    )}
                                    missedOut={targetSymptoms.weekDayType == 0}
                                    missed={
                                      targetSymptoms.weekDayType == 0 &&
                                      targetSymptoms.targetOneAlias == null
                                        ? "Missed Out"
                                        : ""
                                    }
                                    popover__align="popover__align"
                                    contents={[(props.parentWeeklyDataModel.targetOneAlias == null || props.parentWeeklyDataModel.targetOneAlias == "")? <div></div>:
                                      <Popover
                                        placement="rightTop"
                                        getPopupContainer={() =>
                                          document.getElementById("scrollWrap")!
                                        }
                                        content={
                                          <SeverityModal
                                            weeklyDataUpdateByType={
                                              WeeklyDataUpdateByType.Parent
                                            }
                                            weekIndex={index}
                                            parentWeeklyData={
                                              parentWeekDayList[0]
                                                .parentTargetSymptomWeekDayDataList
                                            }
                                            assignWeeklyDataModel={
                                              props.assignWeeklyDataModel
                                            }
                                            propName="targetOneAlias"
                                            popOverId={
                                              "divTargetOneAlias" + (index + 1)
                                            }
                                            key={
                                              "divTargetOneAlias" + (index + 1)
                                            }
                                          />
                                        }
                                        trigger={
                                          parentWeeklyDataModel.targetOneAlias ==
                                            null ||
                                          parentWeeklyDataModel.targetOneAlias ==
                                            ""
                                            ? "click"
                                            : "click"
                                        }
                                      >
                                        <div
                                          id={"divTargetOneAlias" + (index + 1)}
                                        >
                                          {targetSymptoms.weekDayType != 0 &&
                                            targetSymptoms.targetOneAlias ==
                                              null &&
                                            parentWeeklyDataModel.targetOneAlias !=
                                              null &&
                                            parentWeeklyDataModel.targetOneAlias !=
                                              "" && (
                                              <PlusCircleOutlined className="addSeverity" />
                                            )}
                                          {targetSymptoms.weekDayType != 0 &&
                                            targetSymptoms.targetOneAlias ==
                                              null &&
                                            (parentWeeklyDataModel.targetOneAlias ==
                                              null ||
                                              parentWeeklyDataModel.targetOneAlias ==
                                                "") && (
                                              <PlusCircleOutlined className="addSeverityDisabled" />
                                            )}
                                          {targetSymptoms.targetOneAlias !=
                                              null && (
                                              <span className="weekSev__edit">
                                                <img
                                                  src={editGray}
                                                  alt="line"
                                                  className="addSeverity"
                                                />
                                              </span>
                                            )}
                                        </div>
                                      </Popover>,
                                    ]}
                                  />
                                </td>
                              )}
                          </Fragment>
                        );
                      }
                    )}
                </tr>
              )}

              {/* ..Additional Information */}
              <tr>
                <td>
                  <Heading
                    heading="Do you believe your child received active medication (M) or placebo (P) today?"
                    styleName="weekMedHeading"
                  />
                </td>
                {parentAdditionalQuestionList &&
                  parentAdditionalQuestionList.map(
                    (additionalQuestion, index) => {
                      return (
                        <Fragment>
                          {additionalQuestion.weekDayId == dayId &&
                            additionalQuestion.weekDayType != 3 && (
                              <td key={"isPlacebo" + index}>
                                <div className="teacherRFI_btn">
                                  <div className="medical-group btn-select">
                                    <div className="btn-addon btnAdopted">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          isSelected(1, false, index)
                                        }
                                        // disabled={
                                        //   additionalQuestion.weekDayType == 1 &&
                                        //   additionalQuestion.isPlacebo != null
                                        //}
                                        className={
                                          additionalQuestion.isPlacebo == false
                                            ? "btn btn-sm btnAdopted_yes"
                                            : "btn btn-sm btnAdopted_others"
                                        }
                                      >
                                        Medication
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          isSelected(1, true, index)
                                        }
                                        // disabled={
                                        //   additionalQuestion.weekDayType == 1 &&
                                        //   additionalQuestion.isPlacebo != null
                                        // }
                                        className={
                                          additionalQuestion.isPlacebo == true
                                            ? "btn btn-sm btnAdopted_yes"
                                            : "btn btn-sm btnAdopted_no"
                                        }
                                      >
                                        Placebo
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            )}
                        </Fragment>
                      );
                    }
                  )}
              </tr>
              <tr>
                <td>
                  <Heading
                    heading="Would you like your child to continue to receive the pill he or she received today?"
                    styleName="weekMedHeading"
                  />
                </td>
                {parentAdditionalQuestionList &&
                  parentAdditionalQuestionList.map(
                    (additionalQuestion, index) => {
                      return (
                        <Fragment>
                          {additionalQuestion.weekDayId == dayId &&
                            additionalQuestion.weekDayType != 3 && (
                              <td key={"isPillContinued" + index}>
                                <div className="teacherRFI_btn">
                                  <div className="medical-group btn-select">
                                    <div className="btn-addon btnAdopted">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          isSelected(2, true, index)
                                        }
                                        // disabled={
                                        //   additionalQuestion.weekDayType == 1 &&
                                        //   additionalQuestion.isPillContinued !=
                                        //     null
                                        // }
                                        className={
                                          additionalQuestion.isPillContinued ==
                                          true
                                            ? "btn btn-sm btnAdopted_yes"
                                            : "btn btn-sm btnAdopted_others"
                                        }
                                      >
                                        Yes
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          isSelected(2, false, index)
                                        }
                                        // disabled={
                                        //   additionalQuestion.weekDayType == 1 &&
                                        //   additionalQuestion.isPillContinued !=
                                        //     null
                                        // }
                                        className={
                                          additionalQuestion.isPillContinued ==
                                          false
                                            ? "btn btn-sm btnAdopted_yes"
                                            : "btn btn-sm btnAdopted_no"
                                        }
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            )}
                        </Fragment>
                      );
                    }
                  )}
              </tr>
              <tr>
                <td>
                  <Heading
                    heading="Were side effects observed?"
                    styleName="weekMedHeading"
                  />
                </td>
                {parentAdditionalQuestionList &&
                  parentAdditionalQuestionList.map(
                    (additionalQuestion, index) => {
                      return (
                        <Fragment>
                          {additionalQuestion.weekDayId == dayId &&
                            additionalQuestion.weekDayType != 3 && (
                              <td key={"isSideEffectObserved" + index}>
                                <div className="teacherRFI_btn">
                                  <div className="medical-group btn-select">
                                    <div className="btn-addon btnAdopted">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          isSelected(3, true, index)
                                        }
                                        // disabled={
                                        //   additionalQuestion.weekDayType == 1 &&
                                        //   additionalQuestion.isSideEffectObserved !=
                                        //     null
                                        // }
                                        className={
                                          additionalQuestion.isSideEffectObserved ==
                                          true
                                            ? "btn btn-sm btnAdopted_yes"
                                            : "btn btn-sm btnAdopted_others"
                                        }
                                      >
                                        Yes
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          isSelected(3, false, index)
                                        }
                                        // disabled={
                                        //   additionalQuestion.weekDayType == 1 &&
                                        //   additionalQuestion.isSideEffectObserved !=
                                        //     null
                                        // }
                                        className={
                                          additionalQuestion.isSideEffectObserved ==
                                          false
                                            ? "btn btn-sm btnAdopted_yes"
                                            : "btn btn-sm btnAdopted_no"
                                        }
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            )}
                        </Fragment>
                      );
                    }
                  )}
              </tr>
              <tr>
                <td>
                  <Heading heading="Dosage Missed" styleName="weekMedHeading" />
                </td>
                {parentAdditionalQuestionList &&
                  parentAdditionalQuestionList.map(
                    (additionalQuestion, index) => {
                      return (
                        <Fragment>
                          {additionalQuestion.weekDayId == dayId &&
                            additionalQuestion.weekDayType != 3 && (
                              <td key={"isDosageMissed" + index}>
                                <div className="teacherRFI_btn">
                                  <div className="medical-group btn-select">
                                    <div className="btn-addon btnAdopted">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          isSelected(4, true, index)
                                        }
                                        // disabled={
                                        //   additionalQuestion.weekDayType == 1 &&
                                        //   additionalQuestion.isDosageMissed !=
                                        //     null
                                        // }
                                        className={
                                          additionalQuestion.isDosageMissed ==
                                          true
                                            ? "btn btn-sm btnAdopted_yes"
                                            : "btn btn-sm btnAdopted_others"
                                        }
                                      >
                                        Yes
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          isSelected(4, false, index)
                                        }
                                        // disabled={
                                        //   additionalQuestion.weekDayType == 1 &&
                                        //   additionalQuestion.isDosageMissed !=
                                        //     null
                                        // }
                                        className={
                                          additionalQuestion.isDosageMissed ==
                                          false
                                            ? "btn btn-sm btnAdopted_yes"
                                            : "btn btn-sm btnAdopted_no"
                                        }
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            )}
                        </Fragment>
                      );
                    }
                  )}
              </tr>
              <tr>
                <td>
                  <Heading
                    heading="What time (if any) did you believe the medication wore off?"
                    styleName="weekMedHeading"
                  />
                </td>
                {parentAdditionalQuestionList &&
                  parentAdditionalQuestionList.map(
                    (additionalQuestion, index) => {
                      return (
                        <Fragment>
                          {additionalQuestion.weekDayId == dayId &&
                            additionalQuestion.weekDayType != 3 && (
                              <td key={"medicationWoreOffTime" + index}>
                                <div
                                  className="medical-group RFI_btn"
                                  style={{ display: "flex" }}
                                >
                                  <TimePicker
                                    use12Hours
                                    format="h:mm a"
                                    // disabled={
                                    //   additionalQuestion.weekDayType == 1 &&
                                    //   additionalQuestion.medicationWoreOffTime !=
                                    //     null
                                    // }
                                    onChange={(time, timeString) =>
                                      timeChange(time, timeString, index)
                                    }
                                    className="form-control"
                                    value={
                                      additionalQuestion.medicationWoreOffTime ==
                                        null ||
                                      additionalQuestion.medicationWoreOffTime ==
                                        ""
                                        ? undefined
                                        : dayjs(
                                            additionalQuestion.medicationWoreOffTime,
                                            "h:mm a"
                                          )
                                    }
                                    placeholder="not set"
                                  />
                                </div>
                              </td>
                            )}
                        </Fragment>
                      );
                    }
                  )}
              </tr>
            </tbody>
          </table>
          {/* <Button
          id="btn"
          type="primary"
          size="middle"
          icon={<UpOutlined className="arrow_btn" />}
          onClick={scrollTop}
          className="scrollTop"
          // style={{ height: 40, display: showScroll ? "flex" : "none" }}
        /> */}
        </div>
      </Fragment>
    );
}
export default ChildWeekMobile;
