import React from "react";
import { BehaviourProfileDetailModel } from "../../../../../../models/behaviourProfile/behaviour-profile-detail.model";
import { Loader } from "../../../../../../shared/loaders";
import ChildGuardianInfo from "../../../../../childGuardianInfo";
import Heading from "../../../../../heading";
import { BehaviouralAssets } from "../../../../../medical/behaviour/behaviouralAssets";

interface IProps {
  patientId: string;
  isLoading: boolean;
  behaviourProfileDetailModel: BehaviourProfileDetailModel;
}
export default function BehaviourProfileTabForm(props: IProps) {
  let behavioralAssets = props.behaviourProfileDetailModel.behavioralAssetsDetail;
  let behavioralProblem = props.behaviourProfileDetailModel.behavioralProblemDetail;
  const getData = (val: any) => {
    let data = "-";
    if (val == null || val == undefined) {
      data = "-";
    } else if (val == 1) {
      data = "Never";
    } else if (val == 2) {
      data = "Seldom";
    } else if (val == 3) {
      data = "Sometimes";
    } else if (val == 4) {
      data = "Often";
    } else if (val == 5) {
      data = "Always";
    }

    return data;
  };

  const checkIfNullOrEmpty = (val: any) => {
    let data = "-";
    if (val == null || val == undefined || val == "") {
      data = "-";
    } else {
      data = val;
    }
    return data;
  };

  return (
    <div className="region_table">
      {!props.isLoading && (
        <div>
          <Heading
            heading="Behavioral Problems"
            subHeading=""
            styleName="medicalGeneralDetail__style"
          />
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Is easily persuaded"
              name={getData(behavioralProblem?.isEasilyPersuaded)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Plays alone most of the time"
              name={getData(behavioralProblem?.playsAloneMostOfTheTime)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Has few or no friends"
              name={getData(behavioralProblem?.hasFewOrNoFriends)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Has difficulty keeping friends"
              name={getData(behavioralProblem?.hasDifficultyKeepingFriends)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Is picked on or rejected by other children"
              name={getData(behavioralProblem?.isPickedOnOrRejectedByOtherChildren)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Associated with the bad crowd"
              name={getData(behavioralProblem?.associatedWithBadCrowd)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Has sex play with other children"
              name={getData(behavioralProblem?.hasSexPlayWithOtherChildren)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Picks on or disrupts other children"
              name={getData(behavioralProblem?.picksOnOrDisruptsOtherChildren)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Gets into fights"
              name={getData(behavioralProblem?.getsIntoFights)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Strikes at or hit others"
              name={getData(behavioralProblem?.strikesAtOrHitOthers)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Body is in constant motion"
              name={getData(behavioralProblem?.bodyIsInConstantMotion)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Fidgets; is restless"
              name={getData(behavioralProblem?.fidgetsOrIsRestless)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Does things without thinking"
              name={getData(behavioralProblem?.doesThingsWithoutThinking)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Demands must be met immediately"
              name={getData(behavioralProblem?.demandsMustBeMetImmediately)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Easily frustrated"
              name={getData(behavioralProblem?.easilyFrustrated)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Has difficulty falling asleep"
              name={getData(behavioralProblem?.hasDifficultyFallingAsleep)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Is excitable or overreacts to situations"
              name={getData(behavioralProblem?.isExcitableOrOverreactsToSituations)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Has temper outbursts"
              name={getData(behavioralProblem?.hasTemperOutbursts)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Demands attention"
              name={getData(behavioralProblem?.demandsAttention)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Demands to have his/her own way"
              name={getData(behavioralProblem?.demandsToHaveHisOrHerOwnWay)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Is usually tired"
              name={getData(behavioralProblem?.isUsuallyTired)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Awakens very early"
              name={getData(behavioralProblem?.awakensVeryEarly)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Says she/he is worthless or no good"
              name={getData(behavioralProblem?.saysSheOrHeIsWorthlessOrNoGood)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Is overly sad"
              name={getData(behavioralProblem?.isOverlySad)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Cries often and easily"
              name={getData(behavioralProblem?.criesOftenAndEasily)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Clings to parents or other adults"
              name={getData(behavioralProblem?.clingsToParentsOrOtherAdults)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Feelings are easily hurt"
              name={getData(behavioralProblem?.feelingsAreEasilyHurt)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Is overly shy"
              name={getData(behavioralProblem?.isOverlyShy)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Has frequently/drastic mood changes"
              name={getData(behavioralProblem?.hasFrequentlyOrDrasticMoodChanges)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Is destructive toward self"
              name={getData(behavioralProblem?.isDestructiveTowardSelf)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Is overly anxious"
              name={getData(behavioralProblem?.isOverlyAnxious)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Has physical complaints"
              name={getData(behavioralProblem?.hasPhysicalComplaints)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Has unusual fears"
              name={getData(behavioralProblem?.hasUnusualFears)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Has frequent nightmares"
              name={getData(behavioralProblem?.hasFrequentNightmares)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Angers easily"
              name={getData(behavioralProblem?.angersEasily)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Is argumentative or quarrelsome"
              name={getData(behavioralProblem?.isArgumentativeOrQuarrelsome)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Acts more like an adult than a child"
              name={getData(behavioralProblem?.actsMoreLikeAnAdultThanAChild)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Tells lies"
              name={getData(behavioralProblem?.tellsLies)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Destroys property"
              name={getData(behavioralProblem?.destroysProperty)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Steals"
              name={getData(behavioralProblem?.steals)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Stares and daydreams"
              name={getData(behavioralProblem?.staresAndDaydreams)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Is overly dependent on others for help"
              name={getData(behavioralProblem?.isOverlyDependentOnOthersForHelp)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Has difficulty following multiple directives"
              name={getData(behavioralProblem?.hasDifficultyFollowingMultipleDirectives)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Is easily distracted"
              name={getData(behavioralProblem?.isEasilyDistracted)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Seems disorganized"
              name={getData(behavioralProblem?.seemsDisorganized)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Has trouble doing things in the right order"
              name={getData(behavioralProblem?.hasTroubleDoingThingsInTheRightOrder)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__content_row">
            <ChildGuardianInfo
              designation="Comments"
              name={checkIfNullOrEmpty(behavioralProblem?.comments)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <hr />
          <Heading
            heading="Behavioral Assets"
            subHeading=""
            styleName="medicalGeneralDetail__style"
          />
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Makes friends easily"
              name={getData(behavioralAssets?.makesFriendsEasily)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Is liked by other children"
              name={getData(behavioralAssets?.isLikedByOtherChildren)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Has at least two close friends"
              name={getData(behavioralAssets?.hasAtLeastTwoCloseFriends)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Likes to be part of a group"
              name={getData(behavioralAssets?.likesToBePartOfAGroup)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Willingly participates in activities with family members"
              name={getData(behavioralAssets?.willinglyParticipatesInActivitiesWithFamilyMembers)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Wants to be accepted by others"
              name={getData(behavioralAssets?.wantsToBeAcceptedByOthers)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Demonstrates leadership in group"
              name={getData(behavioralAssets?.demonstratesLeadershipInGroup)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Plays/shares activities with friends at least once per week outside of school"
              name={getData(behavioralAssets?.playsSharesActivitiesWithFriendsAtLeastOncePerWeek)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Participate cooperatively in a group"
              name={getData(behavioralAssets?.participateCooperativelyInAGroup)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Willingly accepts help from family members"
              name={getData(behavioralAssets?.willinglyAcceptsHelpFromFamilyMembers)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Generally likes school"
              name={getData(behavioralAssets?.generallyLikesSchool)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Attends school willingly"
              name={getData(behavioralAssets?.attendsSchoolWillingly)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Works well in small groups"
              name={getData(behavioralAssets?.worksWellInLargeGroups)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Talks about school activities"
              name={getData(behavioralAssets?.talksAboutSchoolActivities)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Follow rules at school"
              name={getData(behavioralAssets?.followRulesAtSchool)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Usually completes homework"
              name={getData(behavioralAssets?.usuallyCompletesHomework)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Works well in large groups"
              name={getData(behavioralAssets?.worksWellInLargeGroups)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Responds appropriately to authority in school"
              name={getData(behavioralAssets?.respondsAppropriatelyToAuthorityInSchool)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Engages in creative projects"
              name={getData(behavioralAssets?.engagesInCreativeProjects)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Initiates activities by him/herself"
              name={getData(behavioralAssets?.initiatesActivitiesByHimHerself)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Easily tolerates changes in routine"
              name={getData(behavioralAssets?.easilyToleratesChangesInRoutine)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Waits his/her turn"
              name={getData(behavioralAssets?.waitsHisHerTurn)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Has a good sense of humor"
              name={getData(behavioralAssets?.hasAGoodSenseOfHumor)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Doesn't give up early"
              name={getData(behavioralAssets?.doesntGiveupEarly)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Is a good sport"
              name={getData(behavioralAssets?.isAGoodSport)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Easily manages transitions from one activity to another"
              name={getData(behavioralAssets?.easilyManagesTransitionsFromOneActivityToAnother)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Is generally enthusiastic"
              name={getData(behavioralAssets?.isGenerallyEnthusiastic)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Is friendly and outgoing"
              name={getData(behavioralAssets?.isFriendlyAndOutgoing)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Easily expresses feelings"
              name={getData(behavioralAssets?.easilyExpressesFeelings)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Is able to identify feelings"
              name={getData(behavioralAssets?.isAbleToIdentifyFeelings)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Likes to be helpful"
              name={getData(behavioralAssets?.likesToBeHelpful)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Handles challenges well"
              name={getData(behavioralAssets?.handlesChallengesWell)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Responds to acknowledgment"
              name={getData(behavioralAssets?.respondsToAcknowledgement)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Shows self confidence"
              name={getData(behavioralAssets?.showsSelfConfidence)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Easily acknowledges others"
              name={getData(behavioralAssets?.easilyAcknowledgesOthers)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Follows rules at home"
              name={getData(behavioralAssets?.followsRulesAtHome)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__accord__sibContent">
            <ChildGuardianInfo
              designation="Takes proper care of property"
              name={getData(behavioralAssets?.takesProperCareOfProperty)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Admits wrongdoing"
              name={getData(behavioralAssets?.admitsWrongdoing)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Lists the child's hobbies and interests"
              name={checkIfNullOrEmpty(behavioralAssets?.listsTheChildsHobbiesAndInterests)}
              email=""
              guardian="guardianStyle"
            />
            <ChildGuardianInfo
              designation="Describe any special talents or skills"
              name={checkIfNullOrEmpty(behavioralAssets?.describeAnySpecialTalentsOrSkills)}
              email=""
              guardian="guardianStyle"
            />
          </div>
          <div className="SMH__content_row">
            <ChildGuardianInfo
              designation="Comments"
              name={checkIfNullOrEmpty(behavioralAssets?.comments)}
              email=""
              guardian="guardianStyle"
            />
          </div>
        </div>
      )}
      <Loader loading={props.isLoading} marginBottom="0px" marginTop="8px" width="368px"></Loader>
    </div>
  );
}
