import React, { Component, Fragment } from "react";
import MedicationForm from "./medicationForm";
import MedicationTable from "./medicationTable";
import Heading from "../heading";
import PageTitleSetter from "../../shared/pageTitleSetter";
import "./styles.css";
import { MedicationService } from "../../services/medication";
import { HttpResponse } from "../../core";
import { AddMedicationModel } from "../../models/medication/add-medication.model";
import { ShowSuccessMessage } from "../../shared/helpers";
import { DropdownItemModel } from "../../shared/models/dropdown.model";
import BreadcrumSetter from "../../shared/breadcrumSetter";
import imgAddUser from "../../assets/images/svg-icons/add-user.svg";
import { MedicationDosageAndFormatModel } from "../../models/medication/medication-dosage-and-format.model";
interface IState {
  isLoading: boolean;
  //formatModel: DropdownItemModel[];
  //durationModel: DropdownItemModel[];
  medicationTypeModel: DropdownItemModel[];
  medicationModel: AddMedicationModel;
  medicationDosageAndFormatModel: MedicationDosageAndFormatModel[];
  counter: number;
  isEditMode: boolean;
  reRenderForm: boolean;
  currentUserName: string;
}
class Medication extends Component<any, IState> {
  private medicationService: MedicationService;
  constructor(props) {
    super(props);
    this.medicationService = new MedicationService();
    this.state = this.initialState;
  }
  initialState: IState = {
    counter: 1,
    medicationModel: {
      id: null,
      family: "",
      brandName: "",
      genericName: "",
      medicationType: "",
    } as AddMedicationModel,
    // durationModel: [] as DropdownItemModel[],
    //formatModel: [] as DropdownItemModel[],
    medicationTypeModel: [] as DropdownItemModel[],
    isLoading: false,
    isEditMode: false,
    reRenderForm: false,
    currentUserName: "",
    medicationDosageAndFormatModel: [],
  };
  componentDidMount() {
    if (this.state.medicationTypeModel.length <= 0) {
      this.loadInitialgetMedicationTypeData();
    }
  }

  onEditClick = (id) => {
    this.medicationService
      .getMedicationDetail(id)
      .then((res: HttpResponse<AddMedicationModel>) => {
        if (res && res.result) {
          let medication: AddMedicationModel = {
            id: res.result.id,
            family: res.result.family,
            brandName: res.result.brandName,
            genericName: res.result.genericName,
            medicationType: res.result.medicationType,
            medicationFormats: res.result.medicationFormats,
          };
          this.setState(
            {
              medicationModel: medication,
              medicationDosageAndFormatModel: medication.medicationFormats,
              isEditMode: true,
              reRenderForm: true,
            },
            () => this.setState({ reRenderForm: false })
          );
        }
      });
  };

  loadInitialgetMedicationTypeData = () => {
    this.medicationService
      .getMedicationType()
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res && res.result) {
          this.setState({
            medicationTypeModel: res.result,
          });
        }
      });
  };

  private setLoading(loading: boolean) {
    this.setState({ isLoading: loading });
  }

  saveData = (model: AddMedicationModel) => {
    this.setLoading(true);
    this.medicationService
      .addMedication(model)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
          if (model.id != null && model.id != "") {
            ShowSuccessMessage("Data Updated Successfully.");
          } else {
            ShowSuccessMessage("Data Saved Successfully.");
          }
          this.setLoading(false);
          this.clearData();
          this.setState(
            {
              counter: this.state.counter + 1,
              isEditMode: false,
              reRenderForm: true,
            },
            () => this.setState({ reRenderForm: false })
          );
        }
      })
      .catch((ex) => {
        this.setLoading(false);
        this.clearData();
        this.setState({
          isEditMode: false,
        });
      });
  };
  clearData = () => {
    this.setState({
      medicationModel: {
        id: "",
        family: "",
        brandName: "",
        genericName: "",
        medicationType: "",
        medicationFormats: [],
      },
      medicationDosageAndFormatModel: [],
    });
  };

  reset = () => {
    if (this.state.isEditMode) {
      this.setState(
        {
          isEditMode: false,
          reRenderForm: true,
        },
        () => {
          this.setState({ reRenderForm: false });
          this.clearData();
        }
      );
    }
    this.clearData();
  };
  assignModel = (data: any) => {
    this.setState({
      medicationDosageAndFormatModel: data.medicationDosageAndFormatModel,
    });
  };
  handleMedicationTypeChange = (value: string) => {
    this.setState({
      medicationModel: {
        ...this.state.medicationModel,
        medicationType: value,
      },
    });
  };
  render() {
    return (
      <Fragment>
        <PageTitleSetter title="Medication Settings" />
        <BreadcrumSetter
          crumType="static"
          crumItem1="Medication Settings"
          crumItem2=""
          crumItem3=""
          crumIcon=""
        />
        <div className="medication">
          <div className="medication__form medSet__form">
            <Heading
              heading="Add/Update Medication"
              subHeading=""
              styleName="medicationHeading__style"
            />
            {!this.state.reRenderForm && (
              <MedicationForm
                medicationModel={this.state.medicationModel}
                isSaving={this.state.isLoading}
                saveData={this.saveData}
                medicationTypeModel={this.state.medicationTypeModel}
                isEditMode={this.state.isEditMode}
                reset={this.reset}
                assignModel={this.assignModel}
                medicationDosageAndFormatModel={
                  this.state.medicationDosageAndFormatModel
                }
                onMedicationTypeChange={this.handleMedicationTypeChange}
              />
            )}
          </div>
          <div className="medication__table">
            <MedicationTable
              {...this.props}
              counter={this.state.counter}
              onEditClick={this.onEditClick}
              reset={this.reset}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
export default Medication;
