import React, { useState } from "react";
import Heading from "../../../heading";
import { Button } from "antd";
import "../../styles.css";
import { LearningBehaviorModel } from "../../../../models/familyBackground/learningAndBehavior/learning-behavior.model";
import { useForm } from "react-hook-form";
import { DropdownItemModel } from "../../../../shared/models/dropdown.model";
import { Loader } from "../../../../shared/loaders";
interface IProps {
  learningBehaviorList: LearningBehaviorModel[];
  onSubmit: Function;
  isSaving: boolean;
  patientId: string;
  assignModel: Function;
  previousTabChange: Function;
  relationshipList: DropdownItemModel[];
}
export default function MedicalLearningBehaviourForm(props: IProps) {
  const { register, formState: {errors}, handleSubmit, control, getValues, formState, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
  });
  let learningBehaviorModel = props.learningBehaviorList;
  const handleCheckbox = (isSelected, index) => {
    learningBehaviorModel[index].isSelected = isSelected ? false : true;
    if (!learningBehaviorModel[index].isSelected) {
      learningBehaviorModel[index].relationship = "";
    }
    props.assignModel({ learningBehaviorList: learningBehaviorModel });
    saveDataOnTabChange();
  };

  const handleRelation = (event, i) => {
    learningBehaviorModel[i].relationship = event.target.value;
    props.assignModel({ learningBehaviorList: learningBehaviorModel });
  };

  const formSubmit = () => {
    var ss = checkForError();
    if (!ss) {
      props.onSubmit({
        learningBehaviorList: learningBehaviorModel,
        isAutoSave: false,
      });
    }
  };
  const checkForError = () => {
    if (learningBehaviorModel.length > 0) {
      let hasError = false;
      learningBehaviorModel.forEach((x) => {
        x.isRelationshipSelectedError = "";
        if (x.isSelected && (x.relationship == "" || x.relationship == null)) {
          x.isRelationshipSelectedError = "Please fill relationship.";
          hasError = true;
        }
      });
      return hasError;
    }
  };

  const handleKeyUp = (event, i) => {
    if (
      event.key === "Tab" &&
      (learningBehaviorModel[i].name || learningBehaviorModel[i].relationship)
    ) {
      saveDataOnTabChange();
    }
  };
  const handleOnFocus = (event, i) => {
    saveDataOnTabChange();
  };
  const saveDataOnTabChange = () => {
    if (!props.isSaving) {
      props.onSubmit({
        learningBehaviorList: learningBehaviorModel,
        isAutoSave: true,
      });
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(formSubmit)}>
        <div>
          <Heading
            heading="Familial History of Learning, Behavior and/or Mental Health Problems"
            subHeading=""
            styleName=""
          />
          <p>
            Indicate whether any member of the child's birth family experienced difficulties with
            one or more of the following during their childhood and/or adolescent years.
          </p>

          <div className="form">
            {learningBehaviorModel.map((item, index) => {
              return (
                <div className="row justify-content-center" key={index}>
                  <div className="medical-group col-12 col-md-3 mb-1 mb-md-0">
                      <input
                        type="checkbox"
                        className="btn-check"
                        id={item.name}
                        defaultChecked={item.isSelected}
                        onClick={(e) => handleCheckbox(item.isSelected, index)}
                        onKeyUp={(e) => handleKeyUp(e, index)}
                      />
                        <label 
                          className={item.isSelected == true
                          ? "btn btn-primary btn-sm btnAdopted_yes"
                          : "btn btn-outline-secondary btn-sm btnAdopted_others"
                          }
                          htmlFor={item.name} 
                          >{item.name}</label>
                      
                    
                  </div>
                    <div className="medical-group col-12 col-md-3">
                      <label className="med-soc-label">Relation to child</label>
                      <input
                        type="text"
                        className={
                          !item.isRelationshipSelectedError
                            ? "form-control mt-0"
                            : "form-control is-invalid mt-0 mb-1"
                        }
                        defaultValue={item.relationship}
                        placeholder="Relationship"
                        disabled={!item.isSelected}
                        name="relationship"
                        onChange={(e) => handleRelation(e, index)}
                        onFocus={(e) => handleOnFocus(e, index)}
                      />
                      {item.isRelationshipSelectedError && (
                        <div className="invalid-feedback mt-0 mb-3" style={{ display: "block" }}>
                          {item.isRelationshipSelectedError}
                        </div>
                      )}
                    </div>
                    <hr className="d-md-none"/>
                </div>
                
              );
            })}
          </div>
          <div className="btnContainer">
            {!props.isSaving && (
              <Button
                type="default"
                htmlType="submit"
                size="large"
                className="back_btn"
                onClick={() => props.previousTabChange()}
              >
                <i className="fa fa-long-arrow-left"></i> Back
              </Button>
            )}
            {!props.isSaving && (
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="btn_add"
              >
                Save <i className="fa fa-long-arrow-right"></i>
              </Button>
            )}
            {props.isSaving && (
              <div style={{ height: "60px" }}>
                <Loader
                  loading={props.isSaving}
                  marginBottom="0px"
                  marginTop="8px"
                  width="368px"
                ></Loader>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
