import React, { useEffect, useState } from "react";
import Accordian from "../../../accordian/Accordian";
import family from "../../../../assets/images/svg-icons/smh_1.svg";
import birth from "../../../../assets/images/svg-icons/smh_2.svg";
import referral from "../../../../assets/images/svg-icons/smh_3.svg";
import medicine from "../../../../assets/images/svg-icons/smh_4.svg";
import development from "../../../../assets/images/svg-icons/smh_5.svg";
import behaviour from "../../../../assets/images/svg-icons/smh_6.svg";
import therapy from "../../../../assets/images/svg-icons/smh_7.svg";
import physoTest from "../../../../assets/images/svg-icons/smh_8.svg";
import diagnosis from "../../../../assets/images/svg-icons/smh_9.svg";
import "./styles.css";
import { GeneralDetail } from "./generalDetail";
import { FamilyBackgroundTab } from "./patientMedicalSocialHistoryTab/familyBackgroundTab";
import { BirthHistoryTab } from "./patientMedicalSocialHistoryTab/birthHistoryTab";
import { ReferralConcernTab } from "./patientMedicalSocialHistoryTab/referralConcernTab";
import { MedicationHistoryTab } from "./patientMedicalSocialHistoryTab/medicalHistoryTab";
import { MedicalTherapyTab } from "./patientMedicalSocialHistoryTab/medicalTherapyTab";
import { DevelopmentProfileTab } from "./patientMedicalSocialHistoryTab/developmentProfile";
import { BehaviourProfileTab } from "./patientMedicalSocialHistoryTab/behaviourProfileTab";
import { PsychologicalTestingTab } from "./patientMedicalSocialHistoryTab/psychologicalTestingTab";
import { DiagnosisTab } from "./patientMedicalSocialHistoryTab/diagnosisTab";
interface IProps {
  patientId: string;
  isExpandAll: boolean | undefined | null;
  reRenderSocialMedicalHistoryForm: boolean;
  bcColor: string;
  setExpandAll: Function;
  isMedicalSocialHistoryFormSubmitted: boolean | undefined | null;
}
interface IExpandAll {
  isFamilyBackGround?: boolean;
  isBirthHistory?: boolean;
  isReferralConcern?: boolean;
  isMedicalHistory?: boolean;
  isDevelopmentProfile?: boolean;
  isBehaviuorProfile?: boolean;
  isTherapy?: boolean;
  isPsychologicalTesting?: boolean;
  isDiagnosis?: boolean;
}
function PatientSocialMedicalHistory(props: IProps) {
  let initialModel = {} as IExpandAll;
  const [patientAccordianState, setActivePanel] = useState(initialModel);
  useEffect(() => {
    if (
      !props.isExpandAll &&
      window.document.getElementById("scrollUp") != null
    ) {
      window.document
        .getElementById("scrollUp")!
        .scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [props.isExpandAll]);

  const checkExpandAll = (data) => {
    switch (parseInt(data.index)) {
      case 1: {
        patientAccordianState.isFamilyBackGround = data.status;
        break;
      }
      case 2: {
        patientAccordianState.isBirthHistory = data.status;
        break;
      }
      case 3: {
        patientAccordianState.isReferralConcern = data.status;
        break;
      }
      case 4: {
        patientAccordianState.isMedicalHistory = data.status;
        break;
      }
      case 5: {
        patientAccordianState.isDevelopmentProfile = data.status;
        break;
      }
      case 6: {
        patientAccordianState.isBehaviuorProfile = data.status;
        break;
      }
      case 7: {
        patientAccordianState.isTherapy = data.status;
        break;
      }
      case 8: {
        patientAccordianState.isPsychologicalTesting = data.status;
        break;
      }
      case 9: {
        patientAccordianState.isDiagnosis = data.status;
        break;
      }
    }
    setActivePanel(patientAccordianState);
    checkExpandAllOrCollapseAll(patientAccordianState);
  };

  const checkExpandAllOrCollapseAll = (patientAccordianState: IExpandAll) => {
    let expandAllCount: number = 0;
    let collapseAllCount: number = 0;

    for (let key in patientAccordianState) {
      if (patientAccordianState[key] == true) {
        expandAllCount++;
      } else if (patientAccordianState[key] == false) {
        collapseAllCount++;
      }
    }
    if (expandAllCount == 9) {
      props.setExpandAll(true);
    } else if (collapseAllCount == 9) {
      props.setExpandAll(false);
    }
  };

  return (
        <div id="scrollUp" className="SMH__container SMH__ScrollBar">
          <GeneralDetail patientId={props.patientId} bcColor={props.bcColor} />
          {!props.reRenderSocialMedicalHistoryForm && (
            <div className="SMH__right">
              <Accordian
                title="Family Background"
                index="1"
                medicalImg={family}
                checkExpandAll={checkExpandAll}
                defaultActiveKey={
                  props.isExpandAll == null ? 0 : props.isExpandAll ? 1 : 0
                }
                contents={[
                  <FamilyBackgroundTab
                    patientId={props.patientId}
                  ></FamilyBackgroundTab>,
                ]}
              />
              <Accordian
                title="Birth History"
                index="2"
                defaultActiveKey={
                  props.isExpandAll == null ? 0 : props.isExpandAll ? 2 : 0
                }
                checkExpandAll={checkExpandAll}
                medicalImg={birth}
                contents={[<BirthHistoryTab patientId={props.patientId} />]}
              />
              <Accordian
                title="Referral Concerns"
                index="3"
                medicalImg={referral}
                defaultActiveKey={
                  props.isExpandAll == null ? 0 : props.isExpandAll ? 3 : 0
                }
                checkExpandAll={checkExpandAll}
                contents={[<ReferralConcernTab patientId={props.patientId} />]}
              />
              <Accordian
                title="Medical History"
                index="4"
                medicalImg={medicine}
                defaultActiveKey={
                  props.isExpandAll == null ? 0 : props.isExpandAll ? 4 : 0
                }
                checkExpandAll={checkExpandAll}
                contents={[
                  <MedicationHistoryTab patientId={props.patientId} />,
                ]}
              />
              <Accordian
                title="Development Profile"
                index="5"
                medicalImg={development}
                defaultActiveKey={
                  props.isExpandAll == null ? 0 : props.isExpandAll ? 5 : 0
                }
                checkExpandAll={checkExpandAll}
                contents={[
                  <DevelopmentProfileTab patientId={props.patientId} />,
                ]}
              />
              <Accordian
                title="Behavior Profile"
                index="6"
                defaultActiveKey={
                  props.isExpandAll == null ? 0 : props.isExpandAll ? 6 : 0
                }
                checkExpandAll={checkExpandAll}
                medicalImg={behaviour}
                contents={[<BehaviourProfileTab patientId={props.patientId} />]}
              />
              <Accordian
                title="Therapy/Counseling"
                index="7"
                defaultActiveKey={
                  props.isExpandAll == null ? 0 : props.isExpandAll ? 7 : 0
                }
                checkExpandAll={checkExpandAll}
                medicalImg={therapy}
                contents={[<MedicalTherapyTab patientId={props.patientId} />]}
              />
              <Accordian
                title="Psychological Testing"
                index="8"
                defaultActiveKey={
                  props.isExpandAll == null ? 0 : props.isExpandAll ? 8 : 0
                }
                checkExpandAll={checkExpandAll}
                medicalImg={physoTest}
                contents={[
                  <PsychologicalTestingTab patientId={props.patientId} />,
                ]}
              />
              <Accordian
                title="Previous Diagnosis"
                index="9"
                defaultActiveKey={
                  props.isExpandAll == null ? 0 : props.isExpandAll ? 9 : 0
                }
                checkExpandAll={checkExpandAll}
                medicalImg={diagnosis}
                contents={[<DiagnosisTab patientId={props.patientId} />]}
              />
            </div>
          )}
       
    </div>
  );
}

export default PatientSocialMedicalHistory;
