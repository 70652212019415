import React, { Fragment } from "react";
import { BehaviouralAssetsModel } from "../../../../models/behaviourProfile/behavioural-assets.model";
import "../../styles.css";
interface IProps {
  model: BehaviouralAssetsModel;
  propName: string;
  onSelect: Function;
}

export default function BehaviourAssetsOption(props: IProps) {
  return (
    <Fragment key={props.propName}>
      <div className="btn-group btnGender">
        <button
          type="button"
          onClick={() => props.onSelect(props.propName, 1)}
          className={
            props.model[props.propName] == 1
            ? "btn btn-primary btn-sm btnAdopted_yes"
            : "btn btn-outline-secondary btn-sm btnAdopted_others"
          }
        >
          1. Never
        </button>
        <button
          type="button"
          onClick={() => props.onSelect(props.propName, 2)}
          className={
            props.model[props.propName] == 2
            ? "btn btn-primary btn-sm btnAdopted_yes"
            : "btn btn-outline-secondary btn-sm btnAdopted_others"
          }
        >
          2. Seldom
        </button>
        <button
          type="button"
          onClick={() => props.onSelect(props.propName, 3)}
          className={
            props.model[props.propName] == 3
            ? "btn btn-primary btn-sm btnAdopted_yes"
            : "btn btn-outline-secondary btn-sm btnAdopted_others"
          }
        >
          3. Sometimes
        </button>
        <button
          type="button"
          onClick={() => props.onSelect(props.propName, 4)}
          className={
            props.model[props.propName] == 4
            ? "btn btn-primary btn-sm btnAdopted_yes"
            : "btn btn-outline-secondary btn-sm btnAdopted_others"
          }
        >
          4. Often
        </button>
        <button
          type="button"
          onClick={() => props.onSelect(props.propName, 5)}
          className={
            props.model[props.propName] == 5
            ? "btn btn-primary btn-sm btnAdopted_yes"
            : "btn btn-outline-secondary btn-sm btnAdopted_others"
          }
        >
          5. Always
        </button>
      </div>
      {props.model[props.propName + "Error"] && (
        <div className="invalid-feedback" style={{ display: "block" }}>
          {props.model[props.propName + "Error"]}
        </div>
      )}
    </Fragment>
  );
}
