import React, { Fragment } from "react";
import { HttpResponse } from "../../../../core";
import { ShowErrorMessage, ShowSuccessMessage } from "../../../../shared/helpers";
import { MedicationProblemPostModel } from "../../../../models/medicalHistory/medication-problem-post.model";
import { MedicationProblemForm } from "./medicationProblemForm";
import { MedicalHistoryService } from "../../../../services/medicalHistory";

interface IState {
  isLoading: boolean;
  isReadonly: boolean;
  reRenderForm: boolean;
  medicationProblemPostModel: MedicationProblemPostModel;
  patientId: string;
  isFormSave?: Function;
  medicalHistoryStepUpdate?: Function;
}
export class MedicationProblem extends React.Component<any, IState> {
  private medicalHistoryService: MedicalHistoryService;
  isComponentMounted: boolean = false;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.medicalHistoryService = new MedicalHistoryService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      isReadonly: false,
      medicationProblemPostModel: {} as MedicationProblemPostModel,
      reRenderForm: false,
      patientId: this.props.patientId,
    };
    return initialState;
  }

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }

  componentDidMount() {
    this.loadData();
    window.scroll(0, 0);
  }

  loadData(isAutoSave?: any) {
    if (this.state.patientId) {
      this.medicalHistoryService
        .getMedicationProblem(this.state.patientId)
        .then((res: HttpResponse<MedicationProblemPostModel>) => {
          if (res && res.result) {
            this.props.medicalHistoryStepUpdate(2,res.result.id);
            if (isAutoSave) {
              let mp = this.state.medicationProblemPostModel;
              mp.id = res.result.id;
              mp.patientId = res.result.patientId;
              this.setState({
                medicationProblemPostModel: mp
              });
            }
            else {
            let medicationProblem = res.result;
            this.setState({
              medicationProblemPostModel: medicationProblem,
            });
          }
        }
        })
        .catch((ex) => {});
    }
  }

  postData = (data: any) => {
    let medicationProblem = data.medicationProblemPostModel;
    if (this.state.isLoading) {
      return;
      }
      if (data.isAutoSave) {
          this.setLoading(false);
      }
      else {
          this.setLoading(true);
      }
    this.medicalHistoryService
      .postMedicationProblem(medicationProblem)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
          this.state.medicationProblemPostModel.id=res.result;
          if (!data.isAutoSave) {
            ShowSuccessMessage("Data Saved Successfully.");
            this.props.nextTabChange();
          }
        }
        this.setLoading(false);
        this.props.isFormSave();
        if (!data.isAutoSave) {
          this.loadData(data.isAutoSave);
        }
      })
      .catch((ex) => {
        this.setLoading(false);
        ShowErrorMessage("Error in creating medication problem.");
      });
  };
  previousTabChange = () => {
    this.props.previousTabChange();
  };

  assignModel = (data: any) => {
    this.setState(
      {
        medicationProblemPostModel: data.medicationProblemPostModel,
        reRenderForm: true,
      },
      () => this.setState({ reRenderForm: false })
    );
  };

  render() {
    return (
      <Fragment>
        {!this.state.reRenderForm && (
          <MedicationProblemForm
            onSubmit={this.postData}
            isSaving={this.state.isLoading}
            medicationProblemPostModel={this.state.medicationProblemPostModel}
            patientId={this.props.patientId}
            assignModel={this.assignModel}
            isReadOnly={this.state.isReadonly}
            previousTabChange={this.previousTabChange}
          />
        )}
      </Fragment>
    );
  }
}
