import React, { Component } from "react";
import { HttpResponse } from "../../../core";
import { DevelopmentProfileService } from "../../../services/developmentProfile/development-profile.service";
import { GetDevelopmentProfileStepStatus } from "../../patient/models/get-development-profile-step-status.model";
import Tabs from "../../tabs";
import "../styles.css";
import { ChildTemperament } from "./childTemperament";
import { DevelopmentalMilestones } from "./developmentalMilestones";


interface State {
  tab: number;
  patientId: string;
  isFormSave?: Function;
  getDevelopmentProfileStatus?: GetDevelopmentProfileStepStatus
}
class MedicalDevelopment extends Component<any, State> {
  private developmentProfileService: DevelopmentProfileService;
  state: State = { tab: 0 , patientId: ""};
  changeTab(tab: any) {
    this.setState({
      tab,
    });
  }
  constructor(props) {
    super(props);
    this.developmentProfileService = new DevelopmentProfileService();
  }
  componentDidMount() {
    this.loadData();
  }
  loadData() {
    if (this.props.patientId) {
      this.developmentProfileService
        .getDevelopmentProfileStepStatus(this.props.patientId)
        .then((res: HttpResponse<GetDevelopmentProfileStepStatus>) => {
          if (res && res.result) {
            console.log(res.result);
            this.setState({ getDevelopmentProfileStatus: res.result });
            //this.getLastStep();
          }
        })
        .catch((ex) => {});
    }
  }
  developmentProfileStepUpdateHandler = (key, value) => {
    if (this.state.getDevelopmentProfileStatus) {
      if (key == 1) {
        this.state.getDevelopmentProfileStatus.childTemperamentId = value;
      } else if (key == 2) {
        this.state.getDevelopmentProfileStatus.developmentMileStonesId = value;
      }
    }
  };
  render() {
    const { tab } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="medical__family_tabs d-none d-xl-block col-3">
            <Tabs
              styleName="familyTab"
              text="Child Temperament"
              steps=""
              stepsAct=""
              fullText="Child Temperament"
              familyTextName="A"
              image=""
              activeImage=""
              tabChange={() => this.changeTab(0)}
              selected={tab === 0}
              isData={
                this.state.getDevelopmentProfileStatus?.childTemperamentId !=
                null
              }
            />
            <Tabs
              styleName="familyTab"
              text="Developmental Milestones"
              steps=""
              stepsAct=""
              fullText="Developmental Milestones"
              familyTextName="B"
              image=""
              activeImage=""
              tabChange={() => this.changeTab(1)}
              selected={tab === 1}
              isData={
                this.state.getDevelopmentProfileStatus
                  ?.developmentMileStonesId != null
              }
            />
          </div>
          <div className="medical__family_content col">
            {tab === 0 && (
              <ChildTemperament
                parentTabChange={this.props.parentTab}
                isFormSave={this.props.isFormSave}
                patientId={this.props.patientId}
                nextTabChange={() => this.changeTab(1)}
                developmentProfileStepUpdate={
                  this.developmentProfileStepUpdateHandler
                }
                isBirthHistoryOptional={this.state.getDevelopmentProfileStatus?.isBirthHistoryOptional}
              />
            )}
            {tab === 1 && (
              <DevelopmentalMilestones
                previousTabChange={() => this.changeTab(0)}
                isFormSave={this.props.isFormSave}
                patientId={this.props.patientId}
                nextTabChange={this.props.nextTabChange}
                developmentProfileStepUpdate={
                  this.developmentProfileStepUpdateHandler
                }
                isBirthHistoryOptional={this.state.getDevelopmentProfileStatus?.isBirthHistoryOptional}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default MedicalDevelopment;
