import React, { Component } from "react";
import { Table, Divider, Modal, Button, Tooltip } from "antd";
import oops from "../../../assets/images/svg-icons/no-result-found.svg";
//import "antd/dist/antd.css";
import "../styles.css";
import { HttpResponse } from "../../../core";
import { MedicationListViewModel } from "../../../models/medication/medication-list-view.model";
import { MedicationService } from "../../../services/medication";
import { MedicationListResponseModel } from "../../../models/medication/medication-list-response.model";
import { MedicationListFilterModel } from "../../../models/medication/medication-list-filter.model";
import { Pagination } from "../../../shared/pagination/Pagination";
import { ShowSuccessMessage } from "../../../shared/helpers";
import editImg from "../../../assets/images/svg-icons/edit.svg";
import { Loader } from "../../../shared/loaders";
import Search from "../../search";
import search from "../../../assets/images/svg-icons/search.svg";

interface IState {
  list: MedicationListViewModel[];
  result: MedicationListResponseModel | any;
  initials?: string;
  searchText: string;
  isLoading?: boolean;
  // pageNumber: number;
  pageSize: number;
  totalItems: number;
  currentPage: number;
  pageCount: number;
  sortName: string;
  sortOrder: string;
  isModalVisible: boolean;
    id: string | null;
    activeRow: string;
}
class MedicationTable extends Component<any, IState> {
  private medicationService: MedicationService;
  constructor(props: any) {
    super(props);
    this.medicationService = new MedicationService();
    this.state = {
      result: {
        collection: [],
        paging: {
          currentPage: 1,
          pageSize: 0,
          totalItems: 0,
          pageCount: 0,
        },
      },
      currentPage: 1,
      searchText: "",
      isLoading: false,
      pageSize: 10,
      totalItems: 0,
      pageCount: 0,
      sortName: "",
      sortOrder: "",
      list: [],
      isModalVisible: false,
        id: null,
        activeRow:""
    };
  }
  toggleLoading = () => this.setState({ isLoading: !this.state.isLoading });
  componentWillUpdate(prevProps) {
    if (prevProps.counter != this.props.counter) {
      this.setState(
        {
          currentPage: 1,
        },
        () => {
          this.loadData();
        }
      );
    }
  }

  private setLoading(loading: boolean) {
    this.setState({ isLoading: loading });
  }
  componentDidMount() {
    this.loadData();
  }
  loadData = () => {
    // this.setLoading(true);
    this.setState({ isLoading: true });
    let filterModel: MedicationListFilterModel = {
      searchText: this.state.searchText,
      pageSize: this.state.pageSize,
      currentPage: this.state.currentPage,
      totalItems: this.state.totalItems,
      pageCount: this.state.pageCount,
      sortOrder: this.state.sortOrder,
      sortName: this.state.sortName
    };
    this.medicationService
      .getMedicationList(filterModel)
      .then((res: HttpResponse<MedicationListResponseModel>) => {
        if (res && res.result) {
          // this.setLoading(false);
          this.setState(
            {
              result: res.result,
              list: res.result.collection,
              isLoading: false,
            },
            () => {}
          );
        }
      
      })
      .catch((ex) => {
        this.setLoading(false);
      });
  };

  pageChange = (page: number) => {
    if (this.state.currentPage === page) return;
    this.setState(
      {
        currentPage: page,
      },
      () => {
        this.loadData();
      }
    );
  };

  handleDelete = () => {
    this.setLoading(true);
    this.medicationService.deleteMedication(this.state.id).then((res: HttpResponse<boolean>) => {
      if (res && res.result) {
        ShowSuccessMessage("Data Deleted Successfully.");
        this.loadData();
        this.handleCancel();
        this.props.reset();
      }
    });
  };

  handleEdit = (id) => {
      this.props.onEditClick(id);
      this.setState({
          activeRow: id
      });
  };
  showDeleteConfirm = (id) => {
    this.setState({
      isModalVisible: true,
      id: id,
    });
  };
  handleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };
  onClickRow = (record) => {
    return {
      onClick: () => {
        this.setState({
          id: record.id,
        });
      },
    };
  };
  setRowClassName = (record) => {
    return record.id === this.state.id ? "clickRowStyl" : "";
  };
  handleSearchChange = (event: any) => {
    this.setState(
      {
        searchText: event.target.value,
        currentPage:1
      },
      () => {
        this.loadData();
      }
    );
    };
    handleSorting = (name: any, sortorder: any) => {
        this.setState({
            sortName: name,
            sortOrder: sortorder,
        },
            () => {
                this.loadData();
            });
    };



  render() {
    const { isLoading, isModalVisible, searchText, list, currentPage, result } = this.state;

    return (
        <div>
        <div className="mgmt__filters">
                <div className="mgmt__leftFilters mgmtSet__filters">
            <Search
              placeholder="Search by Medication Family, Brand Name or Generic Name"
              type="text"
              onChange={this.handleSearchChange}
              value={searchText}
              variants="search__filter"
              name="searchText"
              searchIcon={search}
            />
          </div>
        </div>
            <div className="med__table">
            <table className="table table-hover">
                <thead>
                        <tr key={"patientmed_"}>
                        <th>
                                <div className="medTable__sorters">
                                <span> Medication Family</span>
                                <span className="sorter">
                                    <span
                                        className={`${
                                            this.state.sortName === "Family" && this.state.sortOrder === "asc"
                                                ? "fas fa-caret-up caretActive"
                                                : "fas fa-caret-up med__caret"
                                            }`}
                                        onClick={(e) => this.handleSorting("Family", "asc")}
                                    ></span>
                                    <span
                                        className={`${
                                            this.state.sortName === "Family" && this.state.sortOrder === "desc"
                                                ? "fas fa-caret-down caretActive"
                                                : "fas fa-caret-down med__caret"
                                            }`}
                                        onClick={(e) => this.handleSorting("Family", "desc")}
                                    ></span>
                                </span>
                            </div>
                        </th>
                        <th>
                                <div className="medTable__sorters">
                                <span>Medication Type</span>
                                <span className="sorter">
                                    <span
                                        className={`${
                                            this.state.sortName === "MedicationType" && this.state.sortOrder === "asc"
                                                ? "fas fa-caret-up caretActive"
                                                : "fas fa-caret-up med__caret"
                                            }`}
                                        onClick={(e) => this.handleSorting("MedicationType", "asc")}
                                    ></span>
                                    <span
                                        className={`${
                                            this.state.sortName === "MedicationType" && this.state.sortOrder === "desc"
                                                ? "fas fa-caret-down caretActive"
                                                : "fas fa-caret-down med__caret"
                                            }`}
                                        onClick={(e) => this.handleSorting("MedicationType", "desc")}
                                    ></span>
                                </span>
                            </div>
                        </th>
                        <th>
                                <div className="medTable__sorters">
                                <span>Brand Name</span>
                                <span className="sorter">
                                    <span
                                        className={`${
                                            this.state.sortName === "BrandName" && this.state.sortOrder === "asc"
                                                ? "fas fa-caret-up caretActive"
                                                : "fas fa-caret-up med__caret"
                                            }`}
                                        onClick={(e) => this.handleSorting("BrandName", "asc")}
                                    ></span>
                                    <span
                                        className={`${
                                            this.state.sortName === "BrandName" && this.state.sortOrder === "desc"
                                                ? "fas fa-caret-down caretActive"
                                                : "fas fa-caret-down med__caret"
                                            }`}
                                        onClick={(e) => this.handleSorting("BrandName", "desc")}
                                    ></span>
                                </span>
                            </div>
                        </th>
                        <th>
                                <div className="medTable__sorters">
                                <span>Generic Name</span>
                                <span className="sorter">
                                    <span
                                        className={`${
                                            this.state.sortName === "GenericName" && this.state.sortOrder === "asc"
                                                ? "fas fa-caret-up caretActive"
                                                : "fas fa-caret-up med__caret"
                                            }`}
                                        onClick={(e) => this.handleSorting("GenericName", "asc")}
                                    ></span>
                                    <span
                                        className={`${
                                            this.state.sortName === "GenericName" && this.state.sortOrder === "desc"
                                                ? "fas fa-caret-down caretActive"
                                                : "fas fa-caret-down med__caret"
                                            }`}
                                        onClick={(e) => this.handleSorting("GenericName", "desc")}
                                    ></span>
                                </span>
                            </div>
                        </th>
                        <th>
                                <div className="medTable__sorters">
                                <span>Action</span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {!this.props.isLoading && list &&
                        list.map((item, index) => {
                            return (
                                <tr key={"patientmgmt_" + index} className={`${item.id == this.state.activeRow ? "row_active" : ""}`}>
                                    <td>
                                        <div className="medName-role">               
                                            <span className="med__line1">{item.family}</span>
                                            </div>
                                    </td>
                                    <td>
                                        <span className="med__address">{item.medicationType}</span>
                                    </td>
                                    <td>
                                        <div className="medName-role">
                                            <span className="med__line1">{item.brandName}</span>
  
                                        </div>
                                    </td>
                                    <td>
                                        <div className="medName-role">
                                            <span className="med__line1">{item.genericName}</span>
                                        </div>
                                    </td>
                                    <td className="no-wrap">
                                        <Tooltip placement="right" title="Delete">
                                        <span>
                                            <i
                                                className="far fa-trash-alt delete"
                                                onClick={() => this.showDeleteConfirm(item.id)}
                                            ></i>
                                            </span>
                                         </Tooltip>
                                        <Divider type="vertical" />
                                        <Tooltip placement="right" title="Edit">
                                        <img
                                            className="edit-icon"
                                            src={editImg}
                                            alt="editImage"
                                            onClick={() => this.handleEdit(item.id)}
                                            />
                                        </Tooltip>
                                    </td>
                                  
                                </tr>
                            );
                        })}
                    </tbody>
             
                </table>
                {(!this.props.isLoading && list.length == 0) &&
                    <div className="text-center">
                        <div className="noRecord__table">
                            <img src={oops} alt="oops" />
                            <h2>
                                No result found
                    </h2>
                            <p>
                                There are no matching records for your search,
                                please try again.
                    </p>
                        </div>
                    </div>}  
              </div>
        {isLoading && (
          <div className="med_loader">
            <Loader loading={isLoading} marginBottom="0px" marginTop="8px" width="368px"></Loader>
          </div>
        )}

        <div className="pull-right page-list">
          {!isLoading && (
            <Pagination
              currentPage={currentPage}
              pageCount={result?.paging.pageCount}
              onChangePage={this.pageChange}
            />
          )}
        </div>
        <Modal
          title="Delete"
          open={isModalVisible}
          onOk={this.handleDelete}
          okType="primary"
          onCancel={this.handleCancel}
          okText="Yes"
          cancelText="No"
          //className="modal__row"
          closable={false}
        >
          <p>Are you sure you want to delete this medication?</p>
        </Modal>
      </div>
    );
  }
}
export default MedicationTable;
