import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Loader } from "../../../shared/loaders";
import { AddPhysicianModel } from "../models/add-physician";
import { DatePicker, AutoComplete, Tooltip } from "antd";
import WeekwiseInfo from "../weekwiseInfo";
import "../patient.scss";
import { AddPatientMedicationModel } from "../models/add-patient-medication.model";
import moment from "moment";
import { MedicationListViewModel } from "../../../models/medication/medication-list-view.model";
import Select, { MultiValue } from "react-select";
import { DiagnosisCodeType } from "../models/get-diagnosis-code-type.model";
import { parseJsonText } from "typescript";
import { PatientTrialStyle } from "../../../shared/enums/patient-trial-style.enum";
import { DropdownItemModel } from "../../../shared/models/dropdown.model";
import { DiagnosisCodeModel } from "../models/diagnosis-code.model";
import { AddMedicationModel } from "../../../models/medication/add-medication.model";
import WeekwiseMedInfo from "../WeekWideMedInfo";
import dayjs from "dayjs";
import { DevTool } from "@hookform/devtools";
import { get } from "http";
import { getValue } from "@testing-library/user-event/dist/utils";

type AddPhysicianMedicationFormValues = {
  speciality: string | undefined;
  pFirstName: string | undefined;
  pLastName: string | undefined;
  hospitalName: string | undefined;
  patientTrialStyle: number | undefined;
  trialNoOfWeeks: number | undefined;
  trialStartDate: string | Date | null | undefined;
}
interface IProps {
  physician: AddPhysicianModel;
  medication: AddPatientMedicationModel;
  medicationListViewModel: MedicationListViewModel[];
  medicationInfoListViewModel: AddMedicationModel[];
  onSubmit: Function;
  onEditClick: Function;
  onDelete:Function;
  tab: number;
  isSaving: boolean;
  patientId: string;
  isReadOnly: boolean;
  assignModel: Function;
  medicationTypeModel: DropdownItemModel[];
  familyList: string[];
  brandList: string[];
  genericList: string[];
  assignMedicationTypeModel: Function;
  filterList: AddMedicationModel[];
  dosageList: string[];
  formatList: string[];
  selectedFamily: string;
  selectedBrandName: string;
  selectedFormat: string;
  selectedMedicationType: string;
  selectedDosage: string;
}
export function AddPhysicianMedicationForm(props: IProps) {
  const { Option } = AutoComplete;
  const {
    register,
    setError, 
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    formState,
    setValue
  } = useForm<AddPhysicianMedicationFormValues>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  function setInitialValues(){
    setValue("hospitalName", props.physician.hospitalName);
    setValue("pFirstName", props.physician.pFirstName);
    setValue("pLastName", props.physician.pLastName);
    setValue("patientTrialStyle", props.medication.patientTrialStyle);
    setValue("speciality", props.physician.speciality);
    setValue("trialNoOfWeeks", props.medication.trialNoOfWeeks);
    setValue("trialStartDate", props.medication.trialStartDate);
  }

  useEffect(() => {
    setInitialValues();
  }, []);
  let physicianModel= { ...props.physician };
  let medicationModel = { ...props.medication };
  let medicationListViewModel = props.medicationListViewModel;
  let medicationInfoListViewModel = props.medicationInfoListViewModel;
  let medicationTypeModel = props.medicationTypeModel;
  let familyList = props.familyList;
  let brandList = props.brandList;
  let selectedFamily = props.selectedFamily;
  let selectedBrandName = props.selectedBrandName;
  let filterList = props.filterList;
  let dosageList = props.dosageList;
  let formatList = props.formatList;
  let genericList = props.genericList;
  let selectedFormat = props.selectedFormat;
  let selectedMedicationType = props.selectedMedicationType;
  let selectedDosage = props.selectedDosage;

  const formSubmit = (data) => {
    physicianModel.speciality = data.speciality;
    physicianModel.hospitalName = data.hospitalName;
    physicianModel.pFirstName = data.pFirstName;
    physicianModel.pLastName = data.pLastName;
    physicianModel.patientId = props.patientId;
    medicationModel.trialNoOfWeeks =
      medicationModel.createPatientTrialWeekModels.length;
    medicationModel.createPatientTrialWeekModels.forEach((element) => {
      var medId = medicationInfoListViewModel.filter(
        (c) =>
          c.family ==
            element.createPatientTrailWeeklyMedicationModel.medicationFamily &&
          c.medicationType ==
            element.createPatientTrailWeeklyMedicationModel.medicationType
      )[0]?.id;
      element.createPatientTrailWeeklyMedicationModel.medicationId = medId;
    });
    var isError = checkForError();
    props.assignModel({
      medication: medicationModel,
      physician: physicianModel,
    });
    if (isError) {
      return false;
    }
    props.onSubmit({
      physicianModel: physicianModel,
      medicationModel: medicationModel,
      isAutoSave: false,
    });
  };
  const checkForError = () => {
    let hasError = false;
    medicationModel.trialStartDateError = "";
    physicianModel.pFirstNameError = "";
    physicianModel.pLastNameError = "";
    physicianModel.specialityError = "";
    medicationModel.patientTrialStyleError = "";
    medicationModel.diagnosisCodeError = "";
    if (physicianModel.speciality == "") {
      physicianModel.specialityError = "Please fill speciality.";
      hasError = true;
    }
    if (physicianModel.pFirstName == "") {
      physicianModel.pFirstNameError = "Please fill first name.";
      hasError = true;
    }
    if (physicianModel.pLastName == "") {
      physicianModel.pLastNameError = "Please fill last name.";
      hasError = true;
    }

    if (medicationModel.trialStartDate == null) {
      medicationModel.trialStartDateError = "Please select medication date.";
      hasError = true;
    }
    if (
      medicationModel.diagnosisCodes == null ||
      medicationModel.diagnosisCodes.length == 0
    ) {
      medicationModel.diagnosisCodeError = "Please select diagnosis code.";
      hasError = true;
    }
    if (
      medicationModel.patientTrialStyle == null ||
      medicationModel.patientTrialStyle == 0
    ) {
      medicationModel.patientTrialStyleError = "Please select trial style.";
      hasError = true;
    }
    //for now
    if (medicationModel.createPatientTrialWeekModels.length > 0) {
      medicationModel.createPatientTrialWeekModels.forEach((x) => {
        x.createPatientTrailWeeklyMedicationModel.medicationFamilyError = "";
        x.createPatientTrailWeeklyMedicationModel.medicationTypeError = "";
        x.createPatientTrailWeeklyMedicationModel.medicationBrandError = "";
        x.createPatientTrailWeeklyMedicationModel.medicationGenericError = "";
        x.createPatientTrailWeeklyMedicationModel.medicationFormatError = "";
        x.createPatientTrailWeeklyMedicationModel.medicationDosageError = "";
        if (
          x.createPatientTrailWeeklyMedicationModel.medicationFamily !=
          "Placebo"
        ) {
          if (
            x.createPatientTrailWeeklyMedicationModel.medicationFamily == "" ||
            x.createPatientTrailWeeklyMedicationModel.medicationFamily == null
          ) {
            x.createPatientTrailWeeklyMedicationModel.medicationFamilyError =
              "Please select medication family.";
            hasError = true;
          }
          if (
            x.createPatientTrailWeeklyMedicationModel.medicationType == "" ||
            x.createPatientTrailWeeklyMedicationModel.medicationType == null
          ) {
            x.createPatientTrailWeeklyMedicationModel.medicationTypeError =
              "Please select medication type.";
            hasError = true;
          }
          if (
            x.createPatientTrailWeeklyMedicationModel.medicationType !=
            "Generic"
          ) {
            if (
              x.createPatientTrailWeeklyMedicationModel.medicationBrandName ==
                "" ||
              x.createPatientTrailWeeklyMedicationModel.medicationBrandName ==
                null
            ) {
              x.createPatientTrailWeeklyMedicationModel.medicationBrandError =
                "Please select  brand name.";
              hasError = true;
            }
          }
          if (
            x.createPatientTrailWeeklyMedicationModel.medicationGenericName ==
              "" ||
            x.createPatientTrailWeeklyMedicationModel.medicationGenericName ==
              null
          ) {
            x.createPatientTrailWeeklyMedicationModel.medicationGenericError =
              "Please select generic name.";
            hasError = true;
          }
          if (
            x.createPatientTrailWeeklyMedicationModel.medicationFormat == "" ||
            x.createPatientTrailWeeklyMedicationModel.medicationFormat == null
          ) {
            x.createPatientTrailWeeklyMedicationModel.medicationFormatError =
              "Please select dosage form.";
            hasError = true;
          }
          if (
            (x.createPatientTrailWeeklyMedicationModel.medicationDosage == "" ||
                  x.createPatientTrailWeeklyMedicationModel.medicationDosage == null) && x.createPatientTrailWeeklyMedicationModel.medicationFormat == "oral liquid"
          ) {
            x.createPatientTrailWeeklyMedicationModel.medicationDosageError =
              "Please fill dosage strength.";
            hasError = true;
            }
            if (
                (x.createPatientTrailWeeklyMedicationModel.medicationDosage == "" ||
                    x.createPatientTrailWeeklyMedicationModel.medicationDosage == null) && x.createPatientTrailWeeklyMedicationModel.medicationFormat != "oral liquid"
            ) {
                x.createPatientTrailWeeklyMedicationModel.medicationDosageError =
                    "Please select dosage strength.";
                hasError = true;
            }
        }
      });
    }

    let predate: Date | null | undefined = null;
    if (medicationModel.createPatientVisitModels.length > 0) {
      medicationModel.createPatientVisitModels.forEach((x) => {
        x.visitError = "";
        if (x.visitDate == null && x.visitNo == 1) {
          x.visitError = "Please select visit date.";
          hasError = true;
        } else if (
          x.visitNo == 1 &&
          props.medication.trialStartDate != null &&
          getDateWithoutTimeStamp(x.visitDate) >
            getDateWithoutTimeStamp(props.medication.trialStartDate)
        ) {
            x.visitError = "Visit date 1 should be less than or equal to medication start date.";
          hasError = true;
        } else {
          if (
            x.visitDate != null &&
            medicationModel.createPatientVisitModels != null
          ) {
            medicationModel.createPatientVisitModels
              .filter((u) => u.visitNo < x.visitNo && u.visitDate != null)
              .forEach((data) => {
                if (
                  getDateWithoutTimeStamp(data.visitDate) >=
                  getDateWithoutTimeStamp(x.visitDate)
                ) {
                  x.visitError =
                    "Visit " +
                    x.visitNo +
                    " should be greater than visit " +
                    parseInt(data.visitNo.toFixed());
                  hasError = true;
                }
              });
          }
        }
      });
    }

    return hasError;
  };
  const getDateWithoutTimeStamp = (val) => {
    return new Date(new Date(val).toLocaleDateString());
  };
  const changeToEdit = () => {
    props.onEditClick();
  };
  const handleWeekChange = (medicineId, i) => {
    medicationModel.createPatientTrialWeekModels[
      i
    ].createPatientTrailWeeklyMedicationModel.medicationFamily = "";
    medicationModel.createPatientTrialWeekModels[
      i
    ].createPatientTrailWeeklyMedicationModel.medicationBrandName = "";
    medicationModel.createPatientTrialWeekModels[
      i
    ].createPatientTrailWeeklyMedicationModel.medicationDosage = "";
    medicationModel.createPatientTrialWeekModels[
      i
    ].createPatientTrailWeeklyMedicationModel.medicationDuration = "";
    medicationModel.createPatientTrialWeekModels[
      i
    ].createPatientTrailWeeklyMedicationModel.medicationFormat = "";

    medicationModel.createPatientTrialWeekModels[
      i
    ].createPatientTrailWeeklyMedicationModel.medicationRelease = "";
    medicationModel.createPatientTrialWeekModels[
      i
    ].createPatientTrailWeeklyMedicationModel.medicationGenericName = "";
    props.assignModel({ medication: medicationModel });
    medicationListViewModel.find((x) => {
      if (x.id == medicineId) {
        medicationModel.createPatientTrialWeekModels[
          i
        ].createPatientTrailWeeklyMedicationModel.medicationId = medicineId;
        medicationModel.createPatientTrialWeekModels[
          i
        ].createPatientTrailWeeklyMedicationModel.medicationFamily = x.family;
        medicationModel.createPatientTrialWeekModels[
          i
        ].createPatientTrailWeeklyMedicationModel.medicationBrandName =
          x.brandName;
        medicationModel.createPatientTrialWeekModels[
          i
        ].createPatientTrailWeeklyMedicationModel.medicationDosage = x.dosage;
        medicationModel.createPatientTrialWeekModels[
          i
        ].createPatientTrailWeeklyMedicationModel.medicationDuration =
          x.duration;
        medicationModel.createPatientTrialWeekModels[
          i
        ].createPatientTrailWeeklyMedicationModel.medicationFormat = x.format;
        medicationModel.createPatientTrialWeekModels[
          i
        ].createPatientTrailWeeklyMedicationModel.medicationRelease = x.release;
        medicationModel.createPatientTrialWeekModels[
          i
        ].createPatientTrailWeeklyMedicationModel.medicationGenericName =
          x.genericName;
        props.assignModel({ medication: medicationModel });
      }
    });
  };

  const handleKeyUp = (event) => {
    if (!props.isReadOnly) {
      setValuesInModel();
        saveDataOnTabChange();
    }
  };

  const saveDataOnTabChange = () => {
    console.log("saveDataOnTabChange: ", physicianModel)
    if (!props.isSaving) {
      props.onSubmit({ physicianModel: physicianModel, isAutoSave: true });
    }
  };
  const visitDateChange = (date, i) => {
    if (date == null) {
      medicationModel.createPatientVisitModels[i].visitDate = null;
    } else {
      medicationModel.createPatientVisitModels[i].visitDate = dayjs(
        date
      ).format("MM/DD/YYYY");
    }
    props.assignModel({ medication: medicationModel });
  };
  const weekDateChange = (date) => {
    if (date == null) {
      medicationModel.trialStartDate = null;
      medicationModel.trialEndDate = null;
    } else {
      medicationModel.trialStartDate = dayjs(date).format("MM/DD/YYYY");
      medicationModel.trialEndDate = addDays(
        medicationModel.trialStartDate,
        34
      );
    }
    setWeekDates(medicationModel.trialStartDate);
  };
  function setWeekDates(date) {
    medicationModel.createPatientTrialWeekModels?.map((row, i) =>
      date == null
        ? ((row.startDate = null), (row.endDate = null))
        : ((row.startDate = date),
          (row.endDate = addDays(date, 6)),
          (date = addDays(date, 7)))
    );
    props.assignModel({ medication: medicationModel });
  }
  function changeMedicationStartSchedule(date, weekIndex){
    let newStartDate =dayjs(date).format("MM/DD/YYYY");
    let newEndDate = addDays(newStartDate, 6);
    const previousWeekEnd = addDays(newStartDate, -1);
    medicationModel.createPatientTrialWeekModels[weekIndex-1].endDate = previousWeekEnd;
    for (let i = weekIndex; i < medicationModel.createPatientTrialWeekModels.length; i++){
      medicationModel.createPatientTrialWeekModels[i].startDate = newStartDate;
      medicationModel.createPatientTrialWeekModels[i].endDate = newEndDate;
      
      if (i === medicationModel.createPatientTrialWeekModels.length-1){
        medicationModel.trialEndDate = newEndDate
      }
      newStartDate = addDays(newEndDate, 1);
      newEndDate = addDays(newStartDate, 6);
    }
    
    // medicationModel.createPatientTrialWeekModels[weekIndex].startDate = newStartDate;
    // medicationModel.createPatientTrialWeekModels[weekIndex].endDate = newEndDate;
    props.assignModel({ medication: medicationModel });
  }
  function changeMedicationEndSchedule(date, weekIndex){
    let newEndDate =dayjs(date).format("MM/DD/YYYY");
    let nextStartDate = addDays(newEndDate, 1);
    for (let i = weekIndex; i < medicationModel.createPatientTrialWeekModels.length; i++){
      medicationModel.createPatientTrialWeekModels[i].endDate = newEndDate;
      let nextStartDate = addDays(newEndDate, 1);
      newEndDate = addDays(nextStartDate, 6);
      if (i < medicationModel.createPatientTrialWeekModels.length -1){
        medicationModel.createPatientTrialWeekModels[i +1].startDate = nextStartDate;
      }

    }
    props.assignModel({ medication: medicationModel });
  }
  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return dayjs(result).format("MM/DD/YYYY");
  }
  function setValuesInModel() {
    let model = getValues();
    console.log("setValuesInModel: ", model);
    let newPhysicianModel = physicianModel;
    newPhysicianModel.hospitalName = model.hospitalName;
    newPhysicianModel.pFirstName = model.pFirstName;
    newPhysicianModel.pLastName = model.pLastName;
    newPhysicianModel.speciality = model.speciality;
    
  }
  function formatDate(string) {
    var formDate = new Date(string).getFullYear();
    if (formDate == 1 || formDate == 1970) return "";
    else return new Date(string).toLocaleDateString();
  }
  const onSelect = (data: string, option: any, index: number) => {
    handleWeekChange(option.key, index);
  };
  const onChange = (data: string, option: any, i: number) => {
    if (option.key == undefined) {
      medicationModel.createPatientTrialWeekModels[
        i
      ].createPatientTrailWeeklyMedicationModel.medicationId = null;
      medicationModel.createPatientTrialWeekModels[
        i
      ].createPatientTrailWeeklyMedicationModel.medicationFamily = "";
      medicationModel.createPatientTrialWeekModels[
        i
      ].createPatientTrailWeeklyMedicationModel.medicationBrandName = "";
      medicationModel.createPatientTrialWeekModels[
        i
      ].createPatientTrailWeeklyMedicationModel.medicationDosage = "";
      medicationModel.createPatientTrialWeekModels[
        i
      ].createPatientTrailWeeklyMedicationModel.medicationDuration = "";
      medicationModel.createPatientTrialWeekModels[
        i
      ].createPatientTrailWeeklyMedicationModel.medicationFormat = "";
      medicationModel.createPatientTrialWeekModels[
        i
      ].createPatientTrailWeeklyMedicationModel.medicationRelease = "";
      medicationModel.createPatientTrialWeekModels[
        i
      ].createPatientTrailWeeklyMedicationModel.medicationGenericName = "";
      props.assignModel({ medication: medicationModel });
    }
  };

  const handleMultiChange = (selectedOption: MultiValue<DiagnosisCodeModel>) => {
    medicationModel.diagnosisCodes = [];
    if (selectedOption != null && selectedOption.length > 0) {
      selectedOption.forEach((x) => {
        medicationModel.diagnosisCodes?.push(x);
      });
    }
    props.assignModel({ medication: medicationModel });
    console.log("props: ",props)
  };
  const trialStyleChange = () => {
    let data = getValues()
    console.log("trialStylechange: ", data)
    medicationModel.patientTrialStyle = data.patientTrialStyle;
    props.assignModel({ medication: medicationModel });
  };
  
  return (
    <div>
    <form onSubmit={handleSubmit(formSubmit)}>
      <div className="row scrollBar__physician">
        <div className="col-md-12" id="scrollWrap">
          {/* physician section */}
          <section className="child-info">
            <header className="info-header">
              <h3>Physician Information</h3>
            </header>
            <div className="physician__form">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group form-field">
                    <label className="form-label">Speciality</label>
                    <input
                      type="text"
                      defaultValue={physicianModel.speciality}
                      className={"form-control"}
                      {...register('speciality', {
                        maxLength: 128,
                      })}
                      readOnly={props.isReadOnly ? true : false}
                      onFocus={handleKeyUp}
                      placeholder="Speciality"
                    />
                    {physicianModel.specialityError && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {physicianModel.specialityError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group form-field">
                    <label className="form-label">First Name</label>
                    <input
                      type="text"
                      defaultValue={physicianModel.pFirstName}
                      className={"form-control"}
                      {...register('pFirstName',
                        {maxLength: 128},
                      )}
                      readOnly={props.isReadOnly ? true : false}
                      onFocus={handleKeyUp}
                      placeholder="First Name"
                    />
                    {physicianModel.pFirstNameError && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {physicianModel.pFirstNameError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group form-field">
                    <label className="form-label">Last Name</label>
                    <input
                      type="text"
                      defaultValue={physicianModel.pLastName}
                      className={"form-control"}
                      {...register('pLastName', {
                        maxLength: 128,
                      })}
                      readOnly={props.isReadOnly ? true : false}
                      onFocus={handleKeyUp}
                      placeholder="Last Name"
                    />
                    {physicianModel.pLastNameError && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {physicianModel.pLastNameError}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group optional-field form-field">
                    <label className="form-label">Hospital Name</label>
                    <span className="optional">Optional</span>
                    <input
                      type="text"
                      defaultValue={physicianModel.hospitalName}
                      className={
                        !errors.hospitalName
                          ? "form-control"
                          : "form-control  is-invalid"
                      }
                      readOnly={props.isReadOnly ? true : false}
                      {...register('hospitalName')}
                      onFocus={handleKeyUp}
                      placeholder="Hospital Name"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12"></div>
              </div>
            </div>
          </section>
          {/* medication section */}
          <section className="child-info">
            <header className="info-header">
              <h3>Medication Information</h3>
            </header>
            <div className="medicatn__frm__cover">
              {/* additional fields */}
              <div className="row addn__field">
                <div className="col-md-3 visit_datepicker">
                  <div className="medical-group multi-roles">
                    <label className="form-label">Diagnosis Code</label>
                    <Select
                      isDisabled={props.isReadOnly}
                      className={`reactSelect multi-roles ${
                        props.isReadOnly ? "role-disable" : ""
                      }`}
                      name="filters"
                      //defaultValue={props.options[0]}
                      placeholder="Select code"
                      defaultValue={medicationModel.diagnosisCodes}
                      options={DiagnosisCodeType.getDiagnosisCodeType()}
                      onChange={ (e)=>handleMultiChange(e)}
                      isMulti
                    />
                  </div>
                  {props.medication.diagnosisCodeError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {props.medication.diagnosisCodeError}
                    </div>
                  )}
                </div>
                <div className="col-md-3 visit_datepicker">
                  <div className="medical-group">
                    <label htmlFor="patientTrialStyle" className="form-label">
                      Trial Style
                    </label>
                    <select
                      //defaultValue={medicationModel.patientTrialStyle}
                      //value={medicationModel.patientTrialStyle}
                      className={
                        !errors.patientTrialStyle
                          ? "form-control"
                          : "form-control  is-invalid"
                      }
                      disabled={props.isReadOnly ? true : false}
                      {...register('patientTrialStyle', {
                        onChange: ()=>trialStyleChange()
                      })}
                    >
                      <option value="0">Select</option>
                      <option value={PatientTrialStyle.BlindedorPlacebo}>
                        Blinded/Placebo
                      </option>
                      <option value={PatientTrialStyle.Blindedonly}>
                        Blinded only
                      </option>
                      <option value={PatientTrialStyle.unblinded}>
                        Unblinded
                      </option>
                    </select>
                    {!medicationModel.patientTrialStyleError && (
                      <i className="fa fa-angle-down arrow"></i>
                     )}
                     {medicationModel.patientTrialStyleError && (
                      <i className="fa fa-angle-down arrow" style={{ paddingRight: "15px"}}></i>
                    )}
                  </div>
                  {medicationModel.patientTrialStyleError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {medicationModel.patientTrialStyleError}
                    </div>
                  )}
                </div>
                <div className="col-md-3 visit_datepicker">
                  <div className="medical-group">
                    <label className="form-label">Trial Length</label>
                    <select
                      {...register('trialNoOfWeeks')}
                      className="form-control phone_border"
                      defaultValue={medicationModel.trialNoOfWeeks}
                      disabled={props.isReadOnly ? true : false}
                      // onChange={handleOnBlur}
                    >
                      <option disabled={true} value="3">
                        3 weeks
                      </option>
                      <option disabled={true} value="4">
                        4 weeks
                      </option>
                      <option value="5">5 weeks</option>
                    </select>
                    <i className="fa fa-angle-down arrow"></i>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-3 med__week__colums"
                  style={{ paddingRight: 0 }}
                >
                  <div className="form-group form-field">
                    <label className="form-label">Medication Start Date</label>

                    <Controller
                      render={({field: { onChange, onBlur, value, name, ref} }) => (
                        <Tooltip
                          placement="right"
                          title="Date should be in MM/DD/YYYY format"
                        >
                          <DatePicker
                            onChange={(e) => {
                              weekDateChange(e);
                            }}
                            getPopupContainer={() =>
                              document.getElementById("scrollWrap")!
                            }
                            className="datepicker"
                            placeholder="MM/DD/YYYY"
                            format="MM/DD/YYYY"
                            inputReadOnly={false}
                            disabled={
                              props.isReadOnly ||
                              props.medication.isPatientTrialStart
                                ? true
                                : false
                            }
                            name={name}
                            value={
                              props.medication.trialStartDate == null
                                ? undefined
                                : dayjs(props.medication.trialStartDate)
                            }
                          />
                        </Tooltip>
                      )}
                      name="trialStartDate"
                      defaultValue={(props.medication.trialStartDate)}
                      control={control}
                    />
                    {medicationModel.trialStartDateError && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {medicationModel.trialStartDateError}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                {/* Bind medication weeks details */}
                {medicationModel.createPatientTrialWeekModels &&
                  medicationModel.createPatientTrialWeekModels?.map((row, i) => (
                    <div key={i} className="col-md-3 med__week__colums">
                      <WeekwiseMedInfo
                        assignMedicationTypeModel={
                          props.assignMedicationTypeModel
                        }
                        assignModel={props.assignModel}
                        brandList={brandList}
                        dosageList={dosageList}
                        familyList={familyList}
                        filterList={filterList}
                        formatList={formatList}
                        genericList={genericList}
                        index={i}
                        isReadOnly={props.isReadOnly}
                        medication={props.medication}
                        medicationInfoListViewModel={
                          medicationInfoListViewModel
                        }
                        medicationTypeModel={medicationTypeModel}
                        patientId={props.patientId}
                        selectedBrandName={
                          row.createPatientTrailWeeklyMedicationModel
                            ? row.createPatientTrailWeeklyMedicationModel
                                .medicationBrandName
                            : ""
                        }
                        selectedDosage={
                          row.createPatientTrailWeeklyMedicationModel
                            ? row.createPatientTrailWeeklyMedicationModel
                                .medicationDosage
                            : ""
                        }
                        selectedFamily={
                          row.createPatientTrailWeeklyMedicationModel
                            ? row.createPatientTrailWeeklyMedicationModel
                                .medicationFamily
                            : ""
                        }
                        selectedFormat={
                          row.createPatientTrailWeeklyMedicationModel
                            ? row.createPatientTrailWeeklyMedicationModel
                                .medicationFormat
                            : ""
                        }
                        selectedGenericName={
                          row.createPatientTrailWeeklyMedicationModel
                            ? row.createPatientTrailWeeklyMedicationModel
                                .medicationGenericName
                            : ""
                        }
                        selectedMedicationType={
                          row.createPatientTrailWeeklyMedicationModel
                            ? row.createPatientTrailWeeklyMedicationModel
                                .medicationType
                            : ""
                        }
                        weekCount={"Trial " + row.weekNo}
                        weekDate={
                          row?.startDate == null
                            ? ""
                            : formatDate(row.startDate?.toString())
                        }
                        weekEnd={
                          row.endDate == null
                            ? ""
                            : formatDate(row.endDate?.toString())
                        }
                        medicationFamilyError={
                          row.createPatientTrailWeeklyMedicationModel
                            ? row.createPatientTrailWeeklyMedicationModel
                                .medicationFamilyError
                            : ""
                        }
                        medicationTypeError={
                          row.createPatientTrailWeeklyMedicationModel
                            ? row.createPatientTrailWeeklyMedicationModel
                                .medicationTypeError
                            : ""
                        }
                        medicationBrandError={
                          row.createPatientTrailWeeklyMedicationModel
                            ? row.createPatientTrailWeeklyMedicationModel
                                .medicationBrandError
                            : ""
                        }
                        medicationGenericError={
                          row.createPatientTrailWeeklyMedicationModel
                            ? row.createPatientTrailWeeklyMedicationModel
                                .medicationGenericError
                            : ""
                        }
                        medicationDosageError={
                          row.createPatientTrailWeeklyMedicationModel
                            ? row.createPatientTrailWeeklyMedicationModel
                                .medicationDosageError
                            : ""
                        }
                        medicationFormatError={
                          row.createPatientTrailWeeklyMedicationModel
                            ? row.createPatientTrailWeeklyMedicationModel
                                .medicationFormatError
                            : ""
                        }
                        previousWeekStart={(i=== 0)? "" : medicationModel.createPatientTrialWeekModels[i-1]?.startDate}
                        nextWeekStart={medicationModel.trialNoOfWeeks ? ((i === (medicationModel.trialNoOfWeeks-1))? medicationModel.trialEndDate : medicationModel.createPatientTrialWeekModels[i+1]?.startDate) : ""}
                        changeMedicationStartSchedule={changeMedicationStartSchedule}
                        changeMedicationEndSchedule={changeMedicationEndSchedule}
                      ></WeekwiseMedInfo>
                    </div>
                  ))}
              </div>
              {/* Bind Visit dates */}
              <div className="row">
                {medicationModel.createPatientVisitModels &&
                  medicationModel.createPatientVisitModels?.map((row, i) => (
                    <div className="col-md-3 visit_datepicker">
                      <div className="form-group form-field">
                        <Tooltip
                          placement="right"
                          title="Date should be in MM/DD/YYYY format"
                        >
                          <label className="form-label">
                            Visit Date {row.visitNo}
                          </label>
                          <DatePicker
                            onChange={(e) => visitDateChange(e, i)}
                            className="datepicker"
                            getPopupContainer={() =>
                              document.getElementById("scrollWrap")!
                            }
                            inputReadOnly={false}
                            placeholder="MM/DD/YYYY"
                            format="MM/DD/YYYY"
                            disabled={props.isReadOnly ? true : false}
                            // value={dayjs(row.visitDate)}
                            value={
                              row.visitDate == null
                                ? undefined
                                : dayjs(row.visitDate)
                            }
                          />
                          {row.visitError && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" , whiteSpace: "pre-line", top: "49px", letterSpacing: "0.5px", wordBreak: "keep-all" }}
                            >
                              {row.visitError}
                            </div>
                          )}
                        </Tooltip>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </section>
          <div className="medicatn__frm__cover">
            {!props.isSaving && (
              <div className="button-group margin_b30 margin_t30">
                <div className=" text-right">
                  {!props.isReadOnly && (
                    <button type="submit" className="btn btn-primary btn-save">
                      {" "}
                      Save
                    </button>
                  )}

                  {props.isReadOnly && (
                    <button
                      type="button"
                      className="btn btn-primary btn-save"
                      onClick={changeToEdit}
                    >
                      Edit
                    </button>
                  )}
                </div>
              </div>
            )}
            {props.isSaving && (
                <div style={{ height: "60px" }}>
                  <Loader
                    loading={props.isSaving}
                    marginBottom="0px"
                    marginTop="8px"
                    width="368px"
                  ></Loader>
                </div>
              )}

          </div>
        </div>
      </div>
    </form>
    </div>
  );
}
