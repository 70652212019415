import { AppConfig, HttpWrapper } from "../../core";
import { ReferralConcernPostModel } from "../../models/referralConcern/referral-concern-post.model";

export class ReferralConcernService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }
  getReferralConcern(patientId: string) {
    return this.wrapper.get<any>(AppConfig.apiEndpoint + "referralConcern/detail/" + patientId);
  }

  postReferralConcern(model: ReferralConcernPostModel) {
    return this.wrapper.post<any>(AppConfig.apiEndpoint + "referralConcern/create", model);
  }
}
