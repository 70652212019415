import React, { Fragment } from "react";
import { HttpResponse } from "../../../../../../core";
import { BehaviourProfileDetailModel } from "../../../../../../models/behaviourProfile/behaviour-profile-detail.model";
import { BehaviourProfileService } from "../../../../../../services/behaviourProfile/behaviour-profile.service";
import BehaviourProfileTabForm from "./behaviourProfileTabForm";

interface IState {
  patientId: string;
  isLoading: boolean;
  behaviourProfileDetailModel: BehaviourProfileDetailModel;
}
export class BehaviourProfileTab extends React.Component<any, IState> {
  private behaviourProfileService: BehaviourProfileService;

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.behaviourProfileService = new BehaviourProfileService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      patientId: this.props.patientId,
      behaviourProfileDetailModel: {} as BehaviourProfileDetailModel,
    };
    return initialState;
  }
  componentDidMount() {
    if (this.props.patientId != null) {
      this.loadData();
    }
  }

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }
  loadData = () => {
    this.setLoading(true);
    this.behaviourProfileService
      .getBehaviourProfileDetail(this.props.patientId)
      .then((res: HttpResponse<BehaviourProfileDetailModel>) => {
        if (res && res.result) {
          this.setState({
            behaviourProfileDetailModel: res.result,
          });
          this.setLoading(false);
        }
      })
      .catch(() => {
        this.setLoading(false);
      });
  };

  render() {
    return (
      <Fragment>
        <BehaviourProfileTabForm
          patientId={this.props.patientId}
          isLoading={this.state.isLoading}
          behaviourProfileDetailModel={this.state.behaviourProfileDetailModel}
        />
      </Fragment>
    );
  }
}
