import React, { Fragment } from "react";
import { HttpResponse } from "../../../../core";
import { BehaviouralAssetsModel } from "../../../../models/behaviourProfile/behavioural-assets.model";
import { BehaviourProfileService } from "../../../../services/behaviourProfile/behaviour-profile.service";
import { ShowErrorMessage, ShowSuccessMessage } from "../../../../shared/helpers";
import BehaviouralAssetsForm from "./behaviouralAssetsForm";

interface IState {
  patientId: string;
  isLoading: boolean;
  reRenderForm: boolean;
  behavioralAssetsPostModel: BehaviouralAssetsModel;
  isFormSave?: Function;
}
export class BehaviouralAssets extends React.Component<any, IState> {
  private behaviourProfileService: BehaviourProfileService;

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.behaviourProfileService = new BehaviourProfileService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      reRenderForm: false,
      behavioralAssetsPostModel: {} as BehaviouralAssetsModel,
      patientId: this.props.patientId,
    };
    return initialState;
  }
  componentDidMount() {
    this.loadData();
    window.scroll(0, 0);
  }
  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }

  loadData(isAutoSave?: any) {
    if (this.state.patientId) {
        if (isAutoSave) {
            this.setLoading(false);
        }
        else {
            this.setLoading(true);
        }
      this.behaviourProfileService
        .getBehavioralAssests(this.state.patientId)
        .then((res: HttpResponse<BehaviouralAssetsModel>) => {
          if (res && res.result) {
            this.props.behaviourProfileStepUpdate(2,res.result.id);
            if (isAutoSave) {
              let ba = this.state.behavioralAssetsPostModel;
              ba.id = res.result.id;
              ba.patientId = res.result.patientId;
              this.setState({
                behavioralAssetsPostModel: ba,
              });
            } else {
              this.setState({
                behavioralAssetsPostModel: res.result,
              });
            }
            this.setLoading(false);
          }
        })
        .catch((ex) => {});
    }
  }

  postData = (data: any) => {
    let behavioralAssets = data.behavioralAssetsPostModel;
      if (data.isAutoSave) {
          this.setLoading(false);
      }
      else {
          this.setLoading(true);
      }
    this.behaviourProfileService
      .postBehavioralAssests(behavioralAssets)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
          this.state.behavioralAssetsPostModel.id=res.result;
          this.setState({
            behavioralAssetsPostModel: {
              ...this.state.behavioralAssetsPostModel,
              id: res.result,
            },
          });

          if (!data.isAutoSave) {
            ShowSuccessMessage("Data Saved Successfully.");
            this.props.nextTabChange();
          }
        }
        this.props.isFormSave();
        this.setLoading(false);
        if (!data.isAutoSave) {
          this.props.nextTabChange();
        }
        if (!data.isAutoSave) {
          this.loadData(data.isAutoSave);
          }
      })
      .catch((ex) => {
        ShowErrorMessage("Error in creating behavioural Assets.");
        this.setLoading(false);
      });
  };
  previousTabChange = () => {
    this.props.previousTabChange();
  };

  assignModel = (data: any) => {
    this.setState({
      behavioralAssetsPostModel: data.behavioralAssetsPostModel,
    });
  };

  render() {
    return (
      <Fragment>
        <BehaviouralAssetsForm
          onSubmit={this.postData}
          isSaving={this.state.isLoading}
          behavioralAssetsPostModel={this.state.behavioralAssetsPostModel}
          patientId={this.props.patientId}
          assignModel={this.assignModel}
          previousTabChange={this.previousTabChange}
        />
      </Fragment>
    );
  }
}
