import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/css/font-awesome.min.css";
// import App from './App';
import * as serviceWorker from "./serviceWorker";
//import { createStore, applyMiddleware } from "redux";
//import thunk from "redux-thunk";
//import { Provider } from 'react-redux'
//import reducer from './redux/navigation/reducers/reducer'
import App from "./App";
import { BrowserRouter } from "react-router-dom";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
  //<React.StrictMode>
    
  
 // </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

