import { useContext } from "react";
import { GlobalContext } from "../../shared/globalContext/globalContextProvider";
interface IProps {
  title: string;
}
function PageTitleSetter(props: IProps) {
  const context = useContext(GlobalContext);
  context?.changeTitle(props.title);
  return null;
}
export default PageTitleSetter;
