import React, { Fragment } from "react";
import ContentCard from "../../../../contentCard";
import ChildGuardianInfo from "../../../../childGuardianInfo";
import Heading from "../../../../heading";
import "./styles.css";
import { MedicationOfTheTrialModel } from "../../../../../models/medication/medication-of-the-trial.model";
import moment from "moment";
import { PatientTrialStyle } from "../../../../../shared/enums/patient-trial-style.enum";
import dayjs from "dayjs";
interface IProps {
  medicationOfTheTrial: MedicationOfTheTrialModel;
}

function ViewMedication(props: IProps) {
  let medicationOfTheTrial = props.medicationOfTheTrial;
  const checkIfNullOrEmpty = (val: any) => {
    let data = "-";
    if (val == null || val == undefined || val == "") {
      data = "-";
    } else {
      data = val;
    }
    return data;
  };
  const checkIfNullOrEmptyForBrandName = (val: any) => {
    let data = "-";
    if (val == null || val == undefined || val == "") {
      data = "NA";
    } else {
      data = val;
    }
    return data;
  };

  const checkTrialStatus = (val: any) => {
    let data = "-";
    if (val == null || val == undefined || val == "") {
      data = "-";
    } else {
      if (val == PatientTrialStyle.BlindedorPlacebo.toString()) {
        data = "Blinded/Placebo";
      } else if (val == PatientTrialStyle.Blindedonly.toString()) {
        data = "Blinded only";
      } else {
        data = "unblinded";
      }
    }
    return data;
  };
  const checkTrialLength = (val: any) => {
    let data = "-";
    if (val == null || val == undefined || val == "") {
      data = "-";
    } else {
      data = val + " " + "Weeks";
    }
    return data;
  };
  return (
    <Fragment>
      <div className="viewMed">
        <ContentCard
          styleName="childTab__status"
          contents={[
            <div className="breck-box-details">
              <div className="viewMed__head">
                <Heading heading="Medication Given to Patient" styleName="viewMed__heading" />
                <div className="viewMed__rightContent">
                  <Heading
                    heading="Diagnosis Code:"
                    subHeading={checkIfNullOrEmpty(medicationOfTheTrial.diagnosisCode)}
                    styleName="viewMed__baseDesc"
                    ccfSubHeading="viewMed__subBaseDesc"
                  />
                  <Heading
                    heading="Trial Style:"
                    subHeading={checkTrialStatus(
                      medicationOfTheTrial.patientTrialStyle &&
                        medicationOfTheTrial.patientTrialStyle.toString()
                    )}
                    styleName="viewMed__baseDesc"
                    ccfSubHeading="viewMed__subBaseDesc"
                  />
                  <Heading
                    heading="Trial Length:"
                    subHeading={checkTrialLength(
                      medicationOfTheTrial.trialNoOfWeeks &&
                        medicationOfTheTrial.trialNoOfWeeks.toString()
                    )}
                    styleName="viewMed__baseDesc"
                    ccfSubHeading="viewMed__subBaseDesc"
                  />
                </div>
              </div>

              <div className="viewMed__weekDays">
                {medicationOfTheTrial.patientTrialWeekDetails &&
                  medicationOfTheTrial.patientTrialWeekDetails.map((item, index) => {
                    return (
                      <Fragment>
                        <div className="viewMed__weekContentWrap">
                          <Heading
                            key={"week" + index}
                            subHeading={
                              "Week" +
                              " " +
                              item.weekNo +
                              " " +
                              "(" +
                              dayjs(item.startDate).format("MM/DD/YYYY") +
                              ")"
                            }
                          />
                         
                            <ContentCard
                              styleName="viewMed__weekContent"
                              contents={[
                                <div>
                                  <div className="viewMed__weekDosage">
                                    <ChildGuardianInfo
                                      designation="Medication Family"
                                      name={checkIfNullOrEmpty(
                                        item.createPatientTrailWeeklyMedicationModel
                                          .medicationFamily
                                      )}
                                      email=""
                                      guardian="weekMed__style"
                                    />
                                  </div>
                                  <div className="viewMed__weekDosage">
                                    <ChildGuardianInfo
                                      designation="Brand Name"
                                      name={checkIfNullOrEmptyForBrandName(
                                        item.createPatientTrailWeeklyMedicationModel
                                          .medicationBrandName
                                      )}
                                      email=""
                                      guardian="weekMed__style"
                                    />
                                  </div>
                                  <div className="viewMed__weekDosage">
                                    <ChildGuardianInfo
                                      designation="Generic Name"
                                      name={checkIfNullOrEmpty(
                                        item.createPatientTrailWeeklyMedicationModel
                                          .medicationGenericName
                                      )}
                                      email=""
                                      guardian="weekMed__style"
                                    />
                                  </div>
                                  <div className="viewMed__weekDosage">
                                    <ChildGuardianInfo
                                      designation="Dosage Form"
                                      name={checkIfNullOrEmpty(
                                        item.createPatientTrailWeeklyMedicationModel
                                          .medicationFormat
                                      )}
                                      email=""
                                      guardian="weekMed__style"
                                    />
                                  </div>
                                  <div className="viewMed__weekDosage">
                                    <ChildGuardianInfo
                                      designation="Dosage Strength"
                                      name={checkIfNullOrEmpty(
                                        item.createPatientTrailWeeklyMedicationModel
                                          .medicationDosage
                                      )}
                                      email=""
                                      guardian="weekMed__style"
                                    />
                                  </div>
                                </div>,
                              ]}
                            />
                         
{/* 
                          {item.createPatientTrailWeeklyMedicationModel.medicationFamily.toLowerCase() ==
                            "placebo".toLowerCase() && (
                            <div className="viewMed__weekContentWrap">
                              <ContentCard
                                styleName="viewMed__weekContent"
                                contents={[
                                  <ChildGuardianInfo
                                    designation="No Medication, Only Placebo followed."
                                    name=""
                                    email=""
                                    guardian="baselineMed__style"
                                  />,
                                ]}
                              />
                            </div>
                          )} */}
                        </div>
                      </Fragment>
                    );
                  })}
              </div>
            </div>,
          ]}
        />
      </div>
    </Fragment>
  );
}
export default ViewMedication;
