import React, { Fragment } from "react";
import { HttpResponse } from "../../../../core";
import { ShowErrorMessage, ShowSuccessMessage } from "../../../../shared/helpers";
import { FamilyBackgroundService } from "../../../../services/familyBackground";
import { DropdownItemModel } from "../../../../shared/models/dropdown.model";
import { AddBirthParentModel } from "../../../../models/familyBackground/add-birth-parent.model";
import { AddBirthParentForm } from "./AddBirthParents";
import { GetFamilyBackgroundStepStatus } from "../../../patient/models/get-family-background-step-status.model";

interface IState {
  isLoading: boolean;
  tab: number;
  isReadonly: boolean;
  reRenderForm: boolean;
  addBirthParentModel: AddBirthParentModel;
  educationLevelModel: DropdownItemModel[];
  childResidesWithModel: DropdownItemModel[];
  contactWithBirthParent: DropdownItemModel[];
  patientId: string;
  isFormSave?: Function;
  familyBackGroundStepUpdate?: Function;
}
export class BirthParent extends React.Component<any, IState> {
  private familyBackgroundService: FamilyBackgroundService;
  isComponentMounted: boolean = false;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.familyBackgroundService = new FamilyBackgroundService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      tab: 1,
      isReadonly: false,
      addBirthParentModel: {} as AddBirthParentModel,
      educationLevelModel: {} as DropdownItemModel[],
      childResidesWithModel: {} as DropdownItemModel[],
      contactWithBirthParent: {} as DropdownItemModel[],
      reRenderForm: false,
      patientId: this.props.patientId,
    };
    return initialState;
  }

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }
  onEditClick = () => {
    this.setState({ isReadonly: false });
  };
  componentDidMount() {
    this.getEducationLevel();
    this.getChildResidesWith();
    this.getContactWithBirthParent();
    this.loadData();
   // window.scroll(0, 0);
  }

  loadData(isAutoSave?: any) {
    if (this.state.patientId) {
      this.familyBackgroundService
        .getBirthParent(this.state.patientId)
        .then((res: HttpResponse<AddBirthParentModel>) => {
          if (res && res.result) {
            this.props.familyBackGroundStepUpdate(1,res.result.id);
            if (isAutoSave) {
              let bp= this.state.addBirthParentModel;
              bp.id=res.result.id;
              bp.patientId= res.result.patientId;
              bp.guardianId= this.props.parentId;
              this.setState({
                addBirthParentModel: bp
              });
            } 
            else{
            let addBirthParents: AddBirthParentModel = {
              id: res.result.id,
              patientId: this.props.patientId,
              guardianId: this.props.parentId,
              fatherHighestLevelEducation: res.result.fatherHighestLevelEducation,
              motherHighestLevelEducation: res.result.motherHighestLevelEducation,
              fatherAge: res.result.fatherAge,
              motherAge: res.result.motherAge,
              motherName: res.result.motherName,
              fatherName: res.result.fatherName,
              fatherProfession: res.result.fatherProfession,
              motherProfession: res.result.motherProfession,
              isFatherLiveWithChildFullTime:
                res.result.isFatherLiveWithChildFullTime == undefined
                  ? true
                  : res.result.isFatherLiveWithChildFullTime,
              isMotherLiveWithChildFullTime:
                res.result.isMotherLiveWithChildFullTime == undefined
                  ? true
                  : res.result.isMotherLiveWithChildFullTime,
              fatherMaritalStatus: res.result.fatherMaritalStatus,
              motherMaritalStatus: res.result.motherMaritalStatus,
              childResidesWith: res.result.childResidesWith,
              contactWithBirthParent: res.result.contactWithBirthParent,
              comments: res.result.comments,
              specifyChildResidesWith: res.result.specifyChildResidesWith,
            };          
              this.setState(
                {
                  addBirthParentModel: addBirthParents,
                  reRenderForm: true,
                },
                () => this.setState({ reRenderForm: false })
              );
              }
          }
        })
        .catch((ex) => {});
    }
  }

  postData = (data: any) => {
    let addBirthParent = data.addBirthParent;
    if (this.state.isLoading) {
      return;
      }
      if (data.isAutoSave) {
          this.setLoading(false);
      }
      else {
          this.setLoading(true);
      }
    this.familyBackgroundService
      .postAddBirthParent(addBirthParent)
      .then((res: HttpResponse<any>) => {
        this.setLoading(false);
        if (res && res.result) {
        this.state.addBirthParentModel.id=res.result;
        }
        if (!data.isAutoSave) {
          ShowSuccessMessage("Data Saved Successfully.");
          this.props.nextTabChange();
        }
        this.props.isFormSave();
        if(!data.isAutoSave){
        this.loadData(data.isAutoSave);
        }
      })
      .catch((ex) => {
        ShowErrorMessage("Error in creating Birth Parent.");
        this.setLoading(false);
      });
  };
  getEducationLevel = () => {
    new FamilyBackgroundService()
      .getEducationLevel()
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res && res.result) {
          this.setState({
            educationLevelModel: res.result,
          });
        }
      });
  };
  getChildResidesWith = () => {
    new FamilyBackgroundService()
      .getChildResidesWith()
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res && res.result) {
          this.setState({
            childResidesWithModel: res.result,
          });
        }
      });
  };
  getContactWithBirthParent = () => {
    new FamilyBackgroundService()
      .getContactWithBirthParent()
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res && res.result) {
          this.setState({
            contactWithBirthParent: res.result,
          });
        }
      });
  };
  previousTabChange = () => {
    this.props.previousTabChange();
  };
  assignModel = (data: any) => {
    this.setState(
      {
        addBirthParentModel: data.addBirthParent,
        reRenderForm: true,
      },
      () => this.setState({ reRenderForm: false })
    );
  };
  parentTabChange = (tab: any) => {
    this.props.parentTabChange(tab);
  };
  render() {
    return (
      <Fragment>
        {!this.state.reRenderForm && (
          <AddBirthParentForm
            onSubmit={this.postData}
            isSaving={this.state.isLoading}
            addBirthParentModel={this.state.addBirthParentModel}
            tab={this.state.tab}
            patientId={this.props.patientId}
            assignModel={this.assignModel}
            isReadOnly={this.state.isReadonly}
            educationLevelModel={this.state.educationLevelModel}
            contactWithBirthParentModel={this.state.contactWithBirthParent}
            childResideWithModel={this.state.childResidesWithModel}
            parentTabChange={this.parentTabChange}
            isBirthHistoryOptional={this.props.isBirthHistoryOptional}
          />
        )}
      </Fragment>
    );
  }
}
