import React, { Fragment } from "react";
import { HttpResponse } from "../../../../core";
import { AddPrimaryCareTakerModel } from "../../../../models/familyBackground/add-primary-care-taker";
import { ShowErrorMessage, ShowSuccessMessage } from "../../../../shared/helpers";
import { FamilyBackgroundService } from "../../../../services/familyBackground";
import { DropdownItemModel } from "../../../../shared/models/dropdown.model";
import { AddPrimaryCareTakerForm } from "./addPrimaryCareTakerForm";

interface IState {
  isLoading: boolean;
  tab: number;
  isReadonly: boolean;
  reRenderForm: boolean;
  primaryCareTaker: AddPrimaryCareTakerModel;
  relationshipModel: DropdownItemModel[];
  educationLevelModel: DropdownItemModel[];
  patientId: string;
  isFormSave?: Function;
  familyBackGroundStepUpdate?: Function;
}
export class PrimaryCareTaker extends React.Component<any, IState> {
  private familyBackgroundService: FamilyBackgroundService;
  isComponentMounted: boolean = false;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.familyBackgroundService = new FamilyBackgroundService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      tab: 1,
      isReadonly: false,
      primaryCareTaker: {} as AddPrimaryCareTakerModel,
      educationLevelModel: {} as DropdownItemModel[],
      relationshipModel: {} as DropdownItemModel[],
      reRenderForm: false,
      patientId: this.props.patientId,
    };
    return initialState;
  }

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
      //buttonHide:loading
    });
  }
  onEditClick = () => {
    this.setState({ isReadonly: false });
  };
  componentDidMount() {
    this.getRelationship();
    this.getEducationLevel();
    this.loadData();
    window.scroll(0, 0);
  }

  loadData(isChecked?: any, isAutoSave?: any) {
    if (this.state.patientId) {
      this.familyBackgroundService
        .getPrimaryCareTaker(this.state.patientId)
        .then((res: HttpResponse<AddPrimaryCareTakerModel>) => {
          if (res && res.result) {
            this.props.familyBackGroundStepUpdate(2,res.result.id);
            if (isAutoSave) {
              let pc = this.state.primaryCareTaker;
              pc.id = res.result.id;
              pc.patientId = res.result.patientId;
              this.setState({
                primaryCareTaker: pc,
              });
            } else {
              let primaryCareTaker: AddPrimaryCareTakerModel = {
                id: res.result.id,
                patientId: res.result.patientId,
                firstCareTakerAge: res.result.firstCareTakerAge,
                firstCareTakerHighestLevelEducation: res.result.firstCareTakerHighestLevelEducation,
                firstCareTakerMaritalStatus: res.result.firstCareTakerMaritalStatus,
                firstCareTakerCurrentProfession: res.result.firstCareTakerCurrentProfession,
                firstCareTakerName: res.result.firstCareTakerName,
                secondCareTakerAge: res.result.secondCareTakerAge,
                secondCareTakerHighestLevelEducation:
                  res.result.secondCareTakerHighestLevelEducation,
                secondCareTakerMaritalStatus: res.result.secondCareTakerMaritalStatus,
                secondCareTakerCurrentProfession: res.result.secondCareTakerCurrentProfession,
                secondCareTakerName: res.result.secondCareTakerName,
                firstCareTakerRelationship: res.result.firstCareTakerRelationship,
                secondCareTakerRelationship: res.result.secondCareTakerRelationship,
                isFirstCareTakerLiveWithChildFullTime:
                  res.result.isFirstCareTakerLiveWithChildFullTime == undefined
                    ? true
                    : res.result.isFirstCareTakerLiveWithChildFullTime,
                isSecondCareTakerLiveWithChildFullTime:
                  res.result.isSecondCareTakerLiveWithChildFullTime == undefined
                    ? true
                    : res.result.isSecondCareTakerLiveWithChildFullTime,
                isSameAsBirthParent: res.result.isSameAsBirthParent,
                name: res.result.name,
                relationship: res.result.relationship,
              };
              if (isChecked == false) {
                this.setState({
                  isReadonly: false,
                });
                primaryCareTaker.isSameAsBirthParent = isChecked;
              }

              this.setState(
                {
                  primaryCareTaker: primaryCareTaker,
                  isReadonly: primaryCareTaker.isSameAsBirthParent,
                  reRenderForm: true,
                },
                () => this.setState({ reRenderForm: false })
              );
            }
          }
        })
        .catch((ex) => {});
    }
  }

  postData = (data: any) => {
    let primaryCareTaker = data.primaryCareTaker;
    if (this.state.isLoading) {
      return;
      }
      if (data.isAutoSave) {
          this.setLoading(false);
      }
      else {
          this.setLoading(true);
      }
    
    this.familyBackgroundService
      .postAddPrimaryCareTaker(primaryCareTaker)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
          this.state.primaryCareTaker.id=res.result;
          if (!data.isAutoSave) {
            ShowSuccessMessage("Data Saved Successfully.");
            this.props.nextTabChange();
          }
        }
        this.setLoading(false);
        this.props.isFormSave();
        // if(this.state.primaryCareTaker.id == undefined && data.isAutoSave){
          if(!data.isAutoSave){
        this.loadData(null, data.isAutoSave);
          }
      })
      .catch((ex) => {
        ShowErrorMessage("Error in creating primary Care Taker.");
        this.setLoading(false);
      });
  };
  getRelationship = () => {
    new FamilyBackgroundService()
      .getRelationShip()
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res && res.result) {
          this.setState({
            relationshipModel: res.result,
          });
        }
      });
  };
  getEducationLevel = () => {
    new FamilyBackgroundService()
      .getEducationLevel()
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res && res.result) {
          this.setState({
            educationLevelModel: res.result,
          });
        }
      });
  };
  previousTabChange = () => {
    this.props.previousTabChange();
  };
  assignModel = (data: any) => {
    this.setState(
      {
        primaryCareTaker: data.primaryCareTaker,
        reRenderForm: true,
      },
      () => this.setState({ reRenderForm: false })
    );
  };
  setReadOnly = (isReadonly: any) => {
    this.setState({
      isReadonly: isReadonly,
    });
  };
  loadDataByParentId = (patientId: any) => {
    this.setState({
      patientId: patientId,
    });
    this.loadData(false, null);
  };
  render() {
    return (
      <Fragment>
        {!this.state.reRenderForm && (
          <AddPrimaryCareTakerForm
            onSubmit={this.postData}
            isSaving={this.state.isLoading}
            primaryCareTaker={this.state.primaryCareTaker}
            tab={this.state.tab}
            patientId={this.props.patientId}
            assignModel={this.assignModel}
            isReadOnly={this.state.isReadonly}
            relationshipModel={this.state.relationshipModel}
            educationLevelModel={this.state.educationLevelModel}
            previousTabChange={this.previousTabChange}
            loadDataByParentId={this.loadDataByParentId}
            setReadOnly={this.setReadOnly}
          />
        )}
      </Fragment>
    );
  }
}

//export default PrimaryCareTaker;
