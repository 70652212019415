import React, { useState } from "react";
import Heading from "../../../heading";
import { Button, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "../../styles.css";
import { Sibling } from "../../../../shared/enums/sibling-enum";
import { SiblingRelationship } from "../../../../shared/enums/sibling-relationship.enum";
import { useForm } from "react-hook-form";
import { AddSiblingPostModel } from "../../../../models/familyBackground/sibling/add-sibling-post.model";
import { AddSiblingModel } from "../../../../models/familyBackground/sibling/add-sibling.model";
import { Loader } from "../../../../shared/loaders";
import ChildGuardianInfo from "../../../childGuardianInfo";

interface IProps {
    siblingList: AddSiblingPostModel;
    onSubmit: Function;
    isSaving: boolean;
    patientId: string;
    assignModel: Function;
    previousTabChange: Function;
    deleteSibling: Function;
}
export default function MedicalSiblingsAndBirthOrderForm(props: IProps) {
    let siblingListModel = props.siblingList;
    let noSibling = props.siblingList.noSibling;
 //   const [noSiblingR, setNoSibling] = useState(props.siblingList.noSibling);
    let initailModel = {
        id: "",
        name: "",
        age: 0,
        siblingType: 0,
        relationshipType: 0,
    } as AddSiblingModel;
    const {
        register,
        formState: {errors},
        handleSubmit,
        control,
        getValues,
        formState,
        setValue,
    } = useForm({
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
    });
   
    const formSubmit = () => {
        if (!noSibling) {
            if (!checkForError()) {
                if (
                    siblingListModel.siblings == null ||
                    siblingListModel.siblings.length <= 0
                ) {
                    siblingListModel.noSibling = true;
                    //setNoSibling(true);
                }
                props.onSubmit({ siblingListModel: siblingListModel, isAutoSave: false });
            }
        }
        else {
            siblingListModel.siblings = [];
            props.onSubmit({
                siblingListModel: siblingListModel,
                isAutoSave: false,
            });
        }
    };

    const handleNameChange = (event, i) => {
        siblingListModel.siblings[i].name = event.target.value;
        props.assignModel({ siblingListModel: siblingListModel });
    };

    const handleAgeInputChange = (event, i) => {
        if (event.target.value != "") {
            siblingListModel.siblings[i].age = parseInt(event.target.value);
        } else {
            siblingListModel.siblings[i].age = 0;
        }
        props.assignModel({ siblingListModel: siblingListModel });
    };

    const handleSiblingTypeChange = (event, i) => {
        siblingListModel.siblings[i].siblingType = parseInt(event.target.value);
        props.assignModel({ siblingListModel: siblingListModel });
        saveDataOnTabChange();
    };

    const handleRelationshipTypeChange = (event, i) => {
        siblingListModel.siblings[i].relationshipType = parseInt(
            event.target.value
        );
        props.assignModel({ siblingListModel: siblingListModel });
        saveDataOnTabChange();
    };
    const checkForError = () => {
        if (siblingListModel.siblings.length > 0) {
            let hasError = false;
            siblingListModel.siblings.forEach((x) => {
                x.nameError = "";
                if (x.name == "") {
                    x.nameError = "Please fill name.";
                    hasError = true;
                }
                x.ageError = "";
                if (x.age == null || x.age == 0) {
                    x.ageError = "Please fill age.";
                    hasError = true;
                }
                x.siblingTypeError = "";
                if (x.siblingType == null || x.siblingType <= 0) {
                    x.siblingTypeError = "Please select sibling.";
                    hasError = true;
                }
                if (x.relationshipType == parseInt("0")) {
                    x.relationshipTypeError = "Please select sibling.";
                    hasError = true;
                }
                x.relationshipTypeError = "";
                if (x.relationshipType == null || x.relationshipType <= 0) {
                    x.relationshipTypeError = "Please select relationship.";
                    hasError = true;
                }
                if (x.relationshipType == parseInt("0")) {
                    x.relationshipTypeError = "Please select relationship.";
                    hasError = true;
                }
            });
            return hasError;
        }
    };
    const handleKeyUp = (event, i) => {
        if (
            event.key === "Tab" &&
            (siblingListModel.siblings[i].name ||
                siblingListModel.siblings[i].age ||
                siblingListModel.siblings[i].siblingType ||
                siblingListModel.siblings[i].relationshipType)
        ) {
            saveDataOnTabChange();
        }
    };
    const handleOnFocus = (event, i) => {
        saveDataOnTabChange();
    };
    const saveDataOnTabChange = () => {
        if (!props.isSaving) {
            props.onSubmit({ siblingListModel: siblingListModel, isAutoSave: true });
        }
    };

    const handleRemoveClick = (index) => {
        let idToDelete = siblingListModel.siblings[index].id;
        if (idToDelete != "") {
            props.deleteSibling(idToDelete);
        }
        siblingListModel.siblings.splice(index, 1);
        //case for local
        if (
            siblingListModel.siblings == null ||
            siblingListModel.siblings.length <= 0
        ) {
           siblingListModel.noSibling = true;
            //setNoSibling(true);
        }
        props.assignModel({ siblingListModel: siblingListModel });
    };
    const handleAddClick = () => {
        siblingListModel.siblings.push(initailModel);
        props.assignModel({ siblingListModel: siblingListModel });
    };
    const maxLengthCheck = (age) => {
        if (age.target.value.length > age.target.maxLength) {
            age.target.value = age.target.value.slice(0, age.target.maxLength);
        }
    };
    const isSelected = (event) => {
       siblingListModel.noSibling = event;
        noSibling = event;
        //setNoSibling(event);
        if (event) {
            siblingListModel.siblings = [];
            siblingListModel.siblings.push(initailModel);
            props.assignModel({
                siblingListModel: siblingListModel,
            });
        }
        else {
            siblingListModel.siblings = [];
            siblingListModel.siblings.push(initailModel);
            props.assignModel({
                siblingListModel: siblingListModel,
            });
        }
        console.log(siblingListModel);
    };

    return (
        <div className="">
            <form onSubmit={handleSubmit(formSubmit)}>
                <div>
                    <Heading
                        heading="Siblings & Birth Orders"
                        subHeading=""
                        styleName=""
                    />
                    <p>
                        List the names and ages of each sibling of the child, by birth
                        order, oldest child listed first. Indicate all sibling relations to
                        the child (i.e brother or sister) and whether the sibling is a
                        natural, step or half-sibling.
          </p>

                    <div className="row">
                        <div className="medical-group col-12 col-md-3 btn-select">
                            <label className="form-label">
                                Do you have siblings?
                </label>
                            <div className="btn-group btnGender">
                                <button
                                    type="button"
                                    onClick={(e) => isSelected(false)}
                                    className={
                                        noSibling == false
                                        ? "btn btn-primary btn-sm btnAdopted_yes"
                                        : "btn btn-outline-secondary btn-sm btnAdopted_others"
                                    }
                                >
                                    Yes
                  </button>
                                <button
                                    type="button"
                                    onClick={(e) => isSelected(true)}
                                    className={
                                        noSibling == true 
                                        ? "btn btn-primary btn-sm btnAdopted_yes"
                    : "btn btn-outline-secondary btn-sm btnAdopted_others"
                                    }
                                >
                                    No
                  </button>
                            </div>
                        </div>
                    </div>

                    <div className="form mt-3">
                        {siblingListModel.siblings != null &&
                            siblingListModel.siblings.map((sibling, index) => {
                                return (
                                    <div className="row" key={index}>
                                        <div className="medical-group col-12 col-md-3">
                                            <label className="form-label">Name</label>
                                            <input
                                                type="text"
                                                name={"name" + index}
                                                className={
                                                    !sibling.nameError
                                                        ? "form-control mb-1"
                                                        : "form-control mb-1  is-invalid"
                                                }
                                                value={sibling.name}
                                                placeholder="Name"
                                                onChange={(e) => handleNameChange(e, index)}
                                                onFocus={(e) => handleOnFocus(e, index)}
                                                disabled={noSibling}
                                            />
                                            {sibling.nameError && (
                                                <div
                                                    className="invalid-feedback"
                                                    style={{ display: "block" }}
                                                >
                                                    {sibling.nameError}
                                                </div>
                                            )}
                                        </div>
                                        <div className="medical-group col-12 col-md-2">
                                            <label className="form-label">Age</label>
                                            <input
                                                type="number"
                                                name={"age" + index}
                                                className={
                                                    !sibling.ageError
                                                        ? "form-control mb-1"
                                                        : "form-control mb-1  is-invalid"
                                                }
                                                value={
                                                    sibling.age == null || sibling.age == 0
                                                        ? ""
                                                        : sibling.age
                                                }
                                                placeholder="Age"
                                                onChange={(e) => handleAgeInputChange(e, index)}
                                                onFocus={(e) => handleOnFocus(e, index)}
                                                maxLength={3}
                                                onInput={maxLengthCheck}
                                                onKeyDown={(e) =>
                                                    e.keyCode === 69 && e.preventDefault()
                                                }
                                                disabled={noSibling}
                                            />
                                            {sibling.ageError && (
                                                <div
                                                    className="invalid-feedback"
                                                    style={{ display: "block" }}
                                                >
                                                    {sibling.ageError}
                                                </div>
                                            )}
                                        </div>
                                        <div className="medical-group col-12 col-md-3">
                                            <label className="form-label">Sister/Brother</label>
                                            <select
                                                className={
                                                    !sibling.siblingTypeError
                                                        ? "form-control mb-1"
                                                        : "form-control mb-1  is-invalid"
                                                }
                                                name={"sibling" + index}
                                                value={sibling.siblingType}
                                                onChange={(e) => handleSiblingTypeChange(e, index)}
                                                onKeyUp={(e) => handleKeyUp(e, index)}
                                                disabled={noSibling}
                                            >
                                                <option value="0">Select</option>
                                                <option value={Sibling.Brother}>Brother</option>
                                                <option value={Sibling.Sister}>Sister</option>
                                            </select>
                                            {!sibling.siblingTypeError && (
                                                <i className="fa fa-angle-down arrow"></i>
                                            )}
                                            {sibling.siblingTypeError && (
                                                <i className="fa fa-angle-down arrow" style={{ paddingRight: "15px" }}></i>
                                            )}
                                            {sibling.siblingTypeError && (
                                                <div
                                                    className="invalid-feedback"
                                                    style={{ display: "block" }}
                                                >
                                                    {sibling.siblingTypeError}
                                                </div>
                                            )}
                                        </div>
                                        <div className="medical-group col-12 col-md-3">
                                            <label className="form-label">Relationship</label>
                                            <select
                                                className={
                                                    !sibling.relationshipTypeError
                                                        ? "form-control mb-1"
                                                        : "form-control mb-1  is-invalid"
                                                }
                                                name={"relationshipType" + index}
                                                value={sibling.relationshipType}
                                                onChange={(e) => handleRelationshipTypeChange(e, index)}
                                                onKeyUp={(e) => handleKeyUp(e, index)}
                                                disabled={noSibling}
                                            >
                                                <option value="0">Select</option>
                                                <option value={SiblingRelationship.HalfSibling}>
                                                    Half Sibling
                        </option>{" "}
                                                <option value={SiblingRelationship.Natural}>
                                                    Natural
                        </option>
                                                <option value={SiblingRelationship.Step}>Step</option>
                                            </select>
                                            {!sibling.relationshipTypeError && (
                                                <i className="fa fa-angle-down arrow"></i>
                                            )}
                                            {sibling.relationshipTypeError && (
                                                <i className="fa fa-angle-down arrow" style={{ paddingRight: "15px" }}></i>
                                            )}
                                            {sibling.relationshipTypeError && (
                                                <div
                                                    className="invalid-feedback"
                                                    style={{ display: "block" }}
                                                >
                                                    {sibling.relationshipTypeError}
                                                </div>
                                            )}
                                        </div>
                                        {siblingListModel.siblings.length > 1 && (<i
                                            className="far fa-trash-alt delete medical-group col-12 col-md-1 align-self-center text-center"
                                            onClick={() => handleRemoveClick(index)}
                                        ></i>)}
                                        <hr className="mt-3"/>
                                    </div>
                                );
                            })}

                        <div>
                            {noSibling && (
                                
                                    <div className="medical__medChild_formRow">
                                    <Button
                                        type="primary"
                                        size="large"
                                        onClick={handleAddClick}
                                        block
                                        icon={<PlusOutlined />}
                                        className="addNew__row"
                                            disabled={noSibling}
                                    >
                                            Add Siblings
                  </Button>
                                        </div>
                                
                            )}
                            {!noSibling && (
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={handleAddClick}
                                    block
                                    icon={<PlusOutlined />}
                                    className="addNew__row"
                                >
                                    Add Siblings
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className="btnContainer">
                        {!props.isSaving && (
                            <Button
                                type="default"
                                size="large"
                                className="back_btn"
                                onClick={() => props.previousTabChange(0)}
                            >
                                <i className="fa fa-long-arrow-left"></i> Back
                            </Button>
                        )}

                        {!props.isSaving && (
                            <Button
                                type="primary"
                                size="large"
                                htmlType="submit"
                                className="btn_add"
                            >
                                Save <i className="fa fa-long-arrow-right"></i>
                            </Button>
                        )}
                        {props.isSaving && (
                            <div style={{ height: "60px" }}>
                                <Loader
                                    loading={props.isSaving}
                                    marginBottom="0px"
                                    marginTop="8px"
                                    width="368px"
                                ></Loader>
                            </div>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
}
