import { useLocation, useNavigate, useParams } from "react-router-dom";

const withRouter = Component  => props => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();

    return (
      <Component
        {...props}
        {...{ navigate, location, params}}
      />
    );
  };
  
  export default withRouter;