import React from "react";
import { ReferralConcernPostModel } from "../../../../../../models/referralConcern/referral-concern-post.model";
import { TherapyModel } from "../../../../../../models/therapy/therapy.model";
import { Loader } from "../../../../../../shared/loaders";
import ChildGuardianInfo from "../../../../../childGuardianInfo";
import Heading from "../../../../../heading";
import moment from "moment";
import dayjs from "dayjs";

interface IProps {
  patientId: string;
  isLoading: boolean;
  childTherapylst: TherapyModel[];
  familyTherapylst: TherapyModel[];
}

export default function MedicalTherapyViewForm(props: IProps) {
  let childTherapylst = props.childTherapylst;
  let familyTherapylst = props.familyTherapylst;
  const checkIfNullOrEmpty = (val: any) => {
  
    let data = "-";
    if (val == null || val == undefined || val == "" || val=="Invalid Date") {
      data = "-";
    } else {
      data = val;
    }
    return data;
    };
    let options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    };
  return (
    <div className="region_table">
    {!props.isLoading && (
       <div>     <Heading
        heading="Child Therapy"
        subHeading=""
        styleName="medicalGeneralDetail__style"
      />
      <ChildGuardianInfo
        designation="Has your child received any formal psychotherapy/counseling services?"
        name={childTherapylst.length > 0 ? "Yes" : "No"}
        email=""
        guardian="guardianStyle"
      />
      {childTherapylst != null &&
        childTherapylst.map((cTherapy, index) => {
          return (
            <div className="SMH__accord__sibContent">
              <ChildGuardianInfo
                designation="Name of Professional/Organization"
                name={checkIfNullOrEmpty(
                  cTherapy.nameOfProfessionalOrOrganization
                )}
                email=""
                guardian="guardianStyle"
              />
              <ChildGuardianInfo
                designation="Purpose"
                name={checkIfNullOrEmpty(cTherapy.purpose)}
                email=""
                guardian="guardianStyle"
              />
              <ChildGuardianInfo
                designation="Begin Date"
                name={checkIfNullOrEmpty(
                 dayjs(new Date(cTherapy.beginDate ?? "").toLocaleDateString('fr-cA')).format("MM/DD/YYYY")
                )}
                email=""
                guardian="guardianStyle"
              />
              <ChildGuardianInfo
                designation="End Date"
                name={checkIfNullOrEmpty(
                 dayjs(new Date(cTherapy.endDate ?? "").toLocaleDateString('fr-cA')).format("MM/DD/YYYY")
                )}
                email=""
                guardian="guardianStyle"
              />
            </div>
          );
        })}
      <hr />
      <Heading
        heading="Family Therapy"
        subHeading=""
        styleName="medicalGeneralDetail__style"
      />
      <ChildGuardianInfo
        designation="Has your family received any formal psychotherapy/counseling services?"
        name={familyTherapylst.length > 0 ? "Yes" : "No"}
        email=""
        guardian="guardianStyle"
      />

      {familyTherapylst != null &&
        familyTherapylst.map((fTherapy, index) => {
          return (
            <div className="SMH__accord__sibContent">
              <ChildGuardianInfo
                designation="Name of Professional/Organization"
                name={checkIfNullOrEmpty(
                  fTherapy.nameOfProfessionalOrOrganization
                )}
                email=""
                guardian="guardianStyle"
              />
              <ChildGuardianInfo
                designation="Purpose"
                name={checkIfNullOrEmpty(fTherapy.purpose)}
                email=""
                guardian="guardianStyle"
              />
              <ChildGuardianInfo
                designation="Begin Date"
                name={checkIfNullOrEmpty(
                dayjs(new Date(fTherapy.beginDate ?? "").toLocaleDateString('fr-cA')).format("MM/DD/YYYY")
                )}
                email=""
                guardian="guardianStyle"
              />
              <ChildGuardianInfo
                designation="End Date"
                name={checkIfNullOrEmpty(
                dayjs(new Date(fTherapy.endDate ?? "").toLocaleDateString('fr-cA')).format("MM/DD/YYYY")
                )}
                email=""
                guardian="guardianStyle"
              />
            </div>
          );
        })}
       </div>
      )}
      <Loader loading={props.isLoading} marginBottom="0px" marginTop="8px" width="368px"></Loader>
    </div>
  );
}