import React, { Fragment, useState } from "react";
import { Button } from "antd";
import fillingForm from "../../../assets/images/svg-icons/filling-system.svg";
import "./styles.css";
import { GetUserInfo } from "../../../shared/helpers";
import PageTitleSetter from "../../../shared/pageTitleSetter";
import { NavLink } from "react-router-dom";
import { PatientService } from "../../../components/patient/services";
import { HttpResponse } from "../../../core/HttpResponse";
import { PatientsListByParentIdModel } from "../../../components/patient/models/patients-list-By-parentId.model";

function WelcomeScreen(props: any) {
  var userData = GetUserInfo();
  var patientService = new PatientService();
  const [isMedSocialFormSubmitted, setIsMedSocialFormSubmitted] = useState(false);
  patientService.getPatientsListByParentId(userData.userId)
    .then((res: HttpResponse<PatientsListByParentIdModel>) => {
      if (res && res.result) {
         setIsMedSocialFormSubmitted(res.result.patientDetails[0].isMedicalSocialHistoryFormSubmitted)
      }
    })
  const message = (isMedSocialFormSubmitted) ? "The Medical-Social Form has been completed. Please click the button to continue":
    "The Medical-Social History Form is required information about your child, please fill it out in its entirety. This may take up to 30-35 minutes, you can pause and return to complete the form at any time.";
  const buttonText= (isMedSocialFormSubmitted) ? "Continue":"Fill Medical-Social Form";

  return (
    <Fragment>
      <PageTitleSetter title="Welcome" />

      <div className="welcome__screen">
        {/* <div className="welcome__logo">
        <h3>
          M<sup>3</sup>P
        </h3>
        <p>
          Manos
          <br /> Medication <br /> Monitoring Platform
        </p>
      </div> */}
        <div className="welcome__content">
          {/* <div className="welcome__formImg">
            <img src={fillingForm} alt="filling-form" />
          </div> */}
          <div className="welcome__innerContent">
            <h3>
              <strong>Hello</strong> {userData.firstName + " "} { userData.lastName},
            </h3>
            <p>Welcome to the Manos Medication Monitoring Platform (M3P).</p>
            <h2>
              {message}
            </h2>
            <p>
              <strong>Note:</strong> Please complete the Medical-Social History Form prior to your
              first appointment.
            </p>
            <NavLink to={"/medical/medicalhistory/"} className="user-link">
              <Button type="primary" size="large" className="btn_add right">
                {buttonText}
              </Button>
            </NavLink>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default WelcomeScreen;
