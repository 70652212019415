import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "../../styles.css";
import { Loader } from "../../../../shared/loaders";
import moment from "moment";
import { AddPrimaryCareTakerModel } from "../../../../models/familyBackground/add-primary-care-taker";
import Heading from "../../../heading";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { DropdownItemModel } from "../../../../shared/models/dropdown.model";
import { FamilyBackgroundService } from "../../../../services/familyBackground";
import { HttpResponse } from "../../../../core";
import { MaritalStatus } from "../../../../shared/enums/marital-status.enum";
import { AddBirthParentModel } from "../../../../models/familyBackground/add-birth-parent.model";
import { Button } from "antd";

interface IProps {
  primaryCareTaker: AddPrimaryCareTakerModel;
  onSubmit: Function;
  tab: number;
  isSaving: boolean;
  patientId: string;
  assignModel: Function;
  isReadOnly: boolean;
  relationshipModel: DropdownItemModel[];
  educationLevelModel: DropdownItemModel[];
  previousTabChange: Function;
  loadDataByParentId: Function;
  setReadOnly: Function;
}
export function AddPrimaryCareTakerForm(props: IProps) {
  const { register, formState: {errors}, handleSubmit, control, getValues, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
  });
  let primaryCareTakerModel = { ...props.primaryCareTaker };
  let relationshipModel = props.relationshipModel;
  let educationLevelModel = props.educationLevelModel;
  const formSubmit = (data) => {
    primaryCareTakerModel.patientId = props.patientId;
    if (!data.isSameAsBirthParent) {
      primaryCareTakerModel.firstCareTakerAge = data.firstCareTakerAge;
      primaryCareTakerModel.firstCareTakerHighestLevelEducation =
        data.firstCareTakerHighestLevelEducation;
      primaryCareTakerModel.firstCareTakerCurrentProfession = data.firstCareTakerCurrentProfession;
      primaryCareTakerModel.firstCareTakerName = data.firstCareTakerName;
      primaryCareTakerModel.secondCareTakerAge = data.secondCareTakerAge;
      primaryCareTakerModel.secondCareTakerHighestLevelEducation =
        data.secondCareTakerHighestLevelEducation;
      primaryCareTakerModel.secondCareTakerCurrentProfession =
        data.secondCareTakerCurrentProfession;
      primaryCareTakerModel.secondCareTakerName = data.secondCareTakerName;
    }
    primaryCareTakerModel.name = data.name;
    primaryCareTakerModel.relationship = data.relationship;
    primaryCareTakerModel.firstCareTakerRelationship = data.firstCareTakerRelationship;
    primaryCareTakerModel.secondCareTakerRelationship = data.secondCareTakerRelationship;
    props.assignModel({ primaryCareTaker: primaryCareTakerModel });
    if (checkForError()) {
      return false;
    }
    props.onSubmit({ primaryCareTaker: primaryCareTakerModel, isAutoSave: false });
  };
  const checkForError = () => {
    let hasError = false;
    primaryCareTakerModel.firstCareTakerRelationshipError = "";
    primaryCareTakerModel.secondCareTakerRelationshipError = "";
    primaryCareTakerModel.firstCareTakerMaritalStatusError = "";
    primaryCareTakerModel.firstCareTakerAgeError = "";
    primaryCareTakerModel.firstCareTakerCurrentProfessionError = "";
    primaryCareTakerModel.firstCareTakerNameError = "";
    primaryCareTakerModel.firstCareTakerAgeError = "";
    primaryCareTakerModel.secondCareTakerMaritalStatusError = "";
    primaryCareTakerModel.secondCareTakerAgeError = "";
    primaryCareTakerModel.secondCareTakerCurrentProfessionError = "";
    primaryCareTakerModel.secondCareTakerNameError = "";
    primaryCareTakerModel.secondCareTakerCurrentProfessionError = "";

    if (primaryCareTakerModel.firstCareTakerMaritalStatus == null) {
      primaryCareTakerModel.firstCareTakerMaritalStatusError = "Please choose an option.";
      hasError = true;
    }
    if (primaryCareTakerModel.secondCareTakerMaritalStatus == null) {
      primaryCareTakerModel.secondCareTakerMaritalStatusError = "Please choose an option.";
      hasError = true;
    }
    if (
      primaryCareTakerModel.firstCareTakerAge == null ||
      primaryCareTakerModel.firstCareTakerAge <= 0
    ) {
      primaryCareTakerModel.firstCareTakerAgeError = "Please fill age.";
      hasError = true;
    }
    if (
      primaryCareTakerModel.secondCareTakerAge == null ||
      primaryCareTakerModel.secondCareTakerAge <= 0
    ) {
      primaryCareTakerModel.secondCareTakerAgeError = "Please fill age.";
      hasError = true;
    }
    if (primaryCareTakerModel.firstCareTakerRelationship == parseInt("0")) {
      primaryCareTakerModel.firstCareTakerRelationshipError = "Please select relationship.";
      hasError = true;
    }
    if (
      primaryCareTakerModel.secondCareTakerRelationship == null ||
      primaryCareTakerModel.secondCareTakerRelationship <= 0
    ) {
      primaryCareTakerModel.secondCareTakerRelationshipError = "Please select relationship.";
      hasError = true;
    }
    if (primaryCareTakerModel.firstCareTakerName == "") {
      primaryCareTakerModel.firstCareTakerNameError = "Please fill name.";
      hasError = true;
    }
    if (primaryCareTakerModel.secondCareTakerName == "") {
      primaryCareTakerModel.secondCareTakerNameError = "Please fill name.";
      hasError = true;
    }
    if (primaryCareTakerModel.firstCareTakerCurrentProfession == "") {
      primaryCareTakerModel.firstCareTakerCurrentProfessionError =
        "Please fill current profession.";
      hasError = true;
    }
    if (primaryCareTakerModel.secondCareTakerCurrentProfession == "") {
      primaryCareTakerModel.secondCareTakerCurrentProfessionError =
        "Please fill current profession.";
      hasError = true;
    }

    props.assignModel({ primaryCareTaker: primaryCareTakerModel });
    return hasError;
  };
  const handleKeyUp = (event) => {
    setValuesInModel();
    if (event.key === "Tab") {
      saveDataOnTabChange();
    }
  };
  const handleOnBlur = () => {
    setValuesInModel();
    saveDataOnTabChange();
  };
  const saveDataOnTabChange = () => {
    if (!props.isSaving) {
      primaryCareTakerModel.patientId = props.patientId;
      props.onSubmit({ primaryCareTaker: primaryCareTakerModel, isAutoSave: true });
    }
  };

  function setValuesInModel() {
    let model = getValues();
    primaryCareTakerModel = Object.assign(primaryCareTakerModel, model);
  }
  const SameAsBirthParent = (event) => {
    if (event.target.checked) {
      GetDataAsBirthParent();
    } else {
      props.loadDataByParentId(props.patientId);
    }
  };

  const GetDataAsBirthParent = () => {
    props.setReadOnly(true);
    if (props.patientId != null) {
      new FamilyBackgroundService()
        .getBirthParent(props.patientId)
        .then((res: HttpResponse<AddBirthParentModel>) => {
          if (res && res.result) {
            primaryCareTakerModel.isSameAsBirthParent = true;
            primaryCareTakerModel.firstCareTakerAge = res.result.motherAge;
            primaryCareTakerModel.firstCareTakerHighestLevelEducation =
              res.result.motherHighestLevelEducation;
            primaryCareTakerModel.firstCareTakerMaritalStatus = res.result.motherMaritalStatus;
            primaryCareTakerModel.firstCareTakerCurrentProfession = res.result.motherProfession;
            primaryCareTakerModel.firstCareTakerName = res.result.motherName;
            primaryCareTakerModel.secondCareTakerAge = res.result.fatherAge;
            primaryCareTakerModel.secondCareTakerHighestLevelEducation =
              res.result.fatherHighestLevelEducation;
            primaryCareTakerModel.secondCareTakerMaritalStatus = res.result.fatherMaritalStatus;
            primaryCareTakerModel.secondCareTakerCurrentProfession = res.result.fatherProfession;
            primaryCareTakerModel.secondCareTakerName = res.result.fatherName;
            primaryCareTakerModel.isFirstCareTakerLiveWithChildFullTime =
              res.result.isMotherLiveWithChildFullTime;
            primaryCareTakerModel.isSecondCareTakerLiveWithChildFullTime =
              res.result.isFatherLiveWithChildFullTime;
          }
          props.assignModel({ primaryCareTaker: primaryCareTakerModel });
        });
    }
  };

  const firstCareTakerMaritalStatus = (data) => {
    setValuesInModel();
    primaryCareTakerModel.firstCareTakerMaritalStatusError = "";
    primaryCareTakerModel.firstCareTakerMaritalStatus = parseInt(data.target.value);
    props.assignModel({ primaryCareTaker: primaryCareTakerModel });
    saveDataOnTabChange();
  };
  const secondCareTakerMaritalStatus = (data) => {
    setValuesInModel();
    primaryCareTakerModel.secondCareTakerMaritalStatusError = "";
    primaryCareTakerModel.secondCareTakerMaritalStatus = parseInt(data.target.value);
    props.assignModel({ primaryCareTaker: primaryCareTakerModel });
    saveDataOnTabChange();
  };
  const liveWithChildFullTime = (type, isChecked) => {
    setValuesInModel();
    if (type == 1) {
      primaryCareTakerModel.isFirstCareTakerLiveWithChildFullTime = isChecked;
    } else {
      primaryCareTakerModel.isSecondCareTakerLiveWithChildFullTime = isChecked;
    }
    props.assignModel({ primaryCareTaker: primaryCareTakerModel });
    saveDataOnTabChange();
    };
    const maxLengthCheck = (age) => {
        if (age.target.value.length > age.target.maxLength) {
            age.target.value = age.target.value.slice(0, age.target.maxLength)
        }
    }
  return (
    <div className="medical__birth">
      <form onSubmit={handleSubmit(formSubmit)}>
        <div>
          <Heading heading="Primary Caretaker" subHeading="" styleName="" />
          <p>
            Please list any other primary caretaker(s) or custodial parents who is someone other
            than the child's birth parents. This includes adoptive parents, step parents, and other
            legal guardians or adults who have been granted power of attorney of the child. If there
            is more than one primary caretaker who fits this description (e.g, the child has two
            adoptiove parents), please name each caretaker.
          </p>
          <div className="">
            <div className="medical__primary_formCheckbox">
              <div className="medical-group">
                
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="same-as-parents"
                    defaultChecked={primaryCareTakerModel.isSameAsBirthParent}
                    {...register("isSameAsBirthParent")}
                    onClick={SameAsBirthParent}
                  />
                
                    <label
                      htmlFor="same-as-parents"
                      className={primaryCareTakerModel.isSameAsBirthParent == true
                        ? "btn btn-primary btn-sm btnAdopted_yes"
                        : "btn btn-outline-secondary btn-sm btnAdopted_others"}  
                    > Same as birth parents</label>
                  
                
              </div>
            </div>
            <div className="form">
              <div className="row">
                <div className="medical-group col-12 col-md-3">
                  <label className="form-label">Relationship</label>
                  <select
                    {...register("firstCareTakerRelationship")}
                    className="form-control mb-1"
                    //defaultValue={primaryCareTakerModel.firstCareTakerRelationship}
                    onChange={handleOnBlur}
                  >
                    <option value="0">Select</option>
                    {relationshipModel.length > 0 &&
                      relationshipModel.map((item, index) => (
                        <option
                          key={item.value}
                          selected={
                            item.value ==
                            (primaryCareTakerModel.firstCareTakerRelationship == null
                              ? 0
                              : primaryCareTakerModel.firstCareTakerRelationship.toString())
                          }
                          value={item.value}
                        >
                          {item.text}
                        </option>
                      ))}
                  </select>
                  {!primaryCareTakerModel.firstCareTakerRelationshipError && (
                    <i className="fa fa-angle-down arrow"></i>
                  )}
                  {primaryCareTakerModel.firstCareTakerRelationshipError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {primaryCareTakerModel.firstCareTakerRelationshipError}
                    </div>
                  )}
                </div>
                <div className="medical-group col-12 col-md-3">
                  <label className="form-label">Full Name</label>
                  <input
                    type="text"
                    defaultValue={primaryCareTakerModel.firstCareTakerName}
                    disabled={props.isReadOnly ? true : false}
                    placeholder="Name"
                    onFocus={handleOnBlur}
                    {...register("firstCareTakerName")}
                    className="form-control mb-1"
                  />
                  {primaryCareTakerModel.firstCareTakerNameError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {primaryCareTakerModel.firstCareTakerNameError}
                    </div>
                  )}
                </div>
                <div className="medical-group col-12 col-md-3">
                  <label className="form-label">Profession/Occupation</label>
                  <input
                    type="text"
                    disabled={props.isReadOnly ? true : false}
                    {...register("firstCareTakerCurrentProfession")}
                    className="form-control mb-1"
                    placeholder="Current Profession"
                    onFocus={handleOnBlur}
                    defaultValue={primaryCareTakerModel.firstCareTakerCurrentProfession}
                  />
                  {primaryCareTakerModel.firstCareTakerCurrentProfessionError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {primaryCareTakerModel.firstCareTakerCurrentProfessionError}
                    </div>
                  )}
                </div>

                <div className="medical-group col-12 col-md-3">
                  <label className="form-label">Present Age</label>
                  <input
                    type="number"
                    placeholder="Age"
                    disabled={props.isReadOnly ? true : false}
                    defaultValue={primaryCareTakerModel.firstCareTakerAge}
                    onFocus={handleOnBlur}
                    {...register("firstCareTakerAge")}
                    className="form-control mb-1"
                    maxLength={3}
                    onInput={maxLengthCheck}
                    onKeyDown={e => (e.keyCode === 69) && e.preventDefault()}
                  />
                  {primaryCareTakerModel.firstCareTakerAgeError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {primaryCareTakerModel.firstCareTakerAgeError}
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="medical-group col-12 col-md-6">
                  <label className="form-label">
                    Highest level of education completed
                  </label>
                  <select
                    disabled={props.isReadOnly ? true : false}
                    {...register("firstCareTakerHighestLevelEducation")}
                    className={"form-control mb-1 phone_border"}
                    //defaultValue={primaryCareTakerModel.firstCareTakerHighestLevelEducation}
                    onChange={handleOnBlur}
                  >
                    <option value="">None</option>
                    {educationLevelModel.length > 0 &&
                      educationLevelModel.map((item, index) => (
                        <option
                          key={index}
                          selected={
                            item.value ==
                            (primaryCareTakerModel.firstCareTakerHighestLevelEducation == null
                              ? 0
                              : primaryCareTakerModel.firstCareTakerHighestLevelEducation.toString())
                          }
                          value={item.value}
                        >
                          {item.text}
                        </option>
                      ))}
                  </select>
                  <i className="fa fa-angle-down arrow"></i>
                </div>

                <div className="medical-group col-12 col-md-3 btn-select">
                  <label className="form-label">Live with child full time?</label>
                  <div className="btn-group btnAdopted">
                    <button
                      type="button"
                      disabled={props.isReadOnly ? true : false}
                      onClick={() => liveWithChildFullTime(1, true)}
                      className={
                        primaryCareTakerModel.isFirstCareTakerLiveWithChildFullTime == true ||
                        primaryCareTakerModel.isFirstCareTakerLiveWithChildFullTime == null
                        ? "btn btn-primary btn-sm btnAdopted_yes"
                        : "btn btn-outline-secondary btn-sm btnAdopted_others"
                      }
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      disabled={props.isReadOnly ? true : false}
                      className={
                        primaryCareTakerModel.isFirstCareTakerLiveWithChildFullTime == false
                        ? "btn btn-primary btn-sm btnAdopted_yes"
                        : "btn btn-outline-secondary btn-sm btnAdopted_others"
                      }
                      onClick={() => liveWithChildFullTime(1, false)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
              <div className="row my-3">
                <div className="medical-group col-12 col-md-5 btn-select">
                  <label className="form-label">Marital Status</label>
                  <div className="btn-group btnGender">
                    <button
                      type="button"
                      disabled={props.isReadOnly ? true : false}
                      value={MaritalStatus.Married}
                      {...register("married")}
                      onClick={firstCareTakerMaritalStatus}
                      className={
                        primaryCareTakerModel.firstCareTakerMaritalStatus == MaritalStatus.Married
                        ? "btn btn-primary btn-sm btnGender_selected"
                        : "btn btn-outline-secondary btn-sm btnGender_status"
                      }
                    >
                      Married
                    </button>
                    <button
                      disabled={props.isReadOnly ? true : false}
                      type="button"
                      {...register("separeted")}
                      className={
                        primaryCareTakerModel.firstCareTakerMaritalStatus == MaritalStatus.Separeted
                        ? "btn btn-primary btn-sm btnGender_selected"
                        : "btn btn-outline-secondary btn-sm btnGender_status"
                      }
                      value={MaritalStatus.Separeted}
                      onClick={firstCareTakerMaritalStatus}
                    >
                      Separated
                    </button>
                    <button
                      disabled={props.isReadOnly ? true : false}
                      type="button"
                      {...register("widowed")}
                      className={
                        primaryCareTakerModel.firstCareTakerMaritalStatus == MaritalStatus.Widowed
                        ? "btn btn-primary btn-sm btnGender_selected"
                        : "btn btn-outline-secondary btn-sm btnGender_status"
                      }
                      value={MaritalStatus.Widowed}
                      onClick={firstCareTakerMaritalStatus}
                    >
                      Widowed
                    </button>
                    <button
                      disabled={props.isReadOnly ? true : false}
                      type="button"
                      {...register("divorced")}
                      className={
                        primaryCareTakerModel.firstCareTakerMaritalStatus == MaritalStatus.Divorced
                        ? "btn btn-primary btn-sm btnGender_selected"
                        : "btn btn-outline-secondary btn-sm btnGender_status"
                      }
                      value={MaritalStatus.Divorced}
                      onClick={firstCareTakerMaritalStatus}
                    >
                      Divorced
                    </button>

                    <button
                      disabled={props.isReadOnly ? true : false}
                      type="button"
                      {...register("singleOrNeverMarried")}
                      className={
                        primaryCareTakerModel.firstCareTakerMaritalStatus ==
                        MaritalStatus.SingleOrNeverMarried
                        ? "btn btn-primary btn-sm btnGender_selected"
                        : "btn btn-outline-secondary btn-sm btnGender_status"
                      }
                      value={MaritalStatus.SingleOrNeverMarried}
                      onClick={firstCareTakerMaritalStatus}
                    >
                      Single/Never Married
                    </button>
                    {primaryCareTakerModel.firstCareTakerMaritalStatusError && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block", textAlign: "left" }}
                      >
                        {primaryCareTakerModel.firstCareTakerMaritalStatusError}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="medical-group col-12 col-md-3">
                  <label className="form-label">Relationship</label>
                  <select
                    {...register("secondCareTakerRelationship")}
                    className="form-control mb-1"
                    //defaultValue={primaryCareTakerModel.secondCareTakerRelationship}
                    onChange={handleOnBlur}
                  >
                    <option value="0">Select</option>
                    {relationshipModel.length > 0 &&
                      relationshipModel.map((item, index) => (
                        <option
                          key={item.value}
                          selected={
                            item.value ==
                            (primaryCareTakerModel.secondCareTakerRelationship == null
                              ? 0
                              : primaryCareTakerModel.secondCareTakerRelationship.toString())
                          }
                          value={item.value}
                        >
                          {item.text}
                        </option>
                      ))}
                  </select>
                  {!primaryCareTakerModel.secondCareTakerRelationshipError && (
                    <i className="fa fa-angle-down arrow"></i>
                  )}
                  
                  {primaryCareTakerModel.secondCareTakerRelationshipError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {primaryCareTakerModel.secondCareTakerRelationshipError}
                    </div>
                  )}
                </div>
                <div className="medical-group col-12 col-md-3">
                  <label className="form-label">Full Name</label>
                  <input
                    disabled={props.isReadOnly ? true : false}
                    type="text"
                    defaultValue={primaryCareTakerModel.secondCareTakerName}
                    placeholder="Name"
                    onFocus={handleOnBlur}
                    {...register("secondCareTakerName")}
                    className="form-control mb-1"
                    maxLength={3}
                    onInput={maxLengthCheck}
                    onKeyDown={e => (e.keyCode === 69) && e.preventDefault()}
                  />
                  {primaryCareTakerModel.secondCareTakerNameError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {primaryCareTakerModel.secondCareTakerNameError}
                    </div>
                  )}
                </div>
                <div className="medical-group col-12 col-md-3">
                  <label className="form-label">Profession/Occupation</label>
                  <input
                    disabled={props.isReadOnly ? true : false}
                    type="text"
                    placeholder="Current Profession"
                    onFocus={handleOnBlur}
                    {...register("secondCareTakerCurrentProfession")}
                    className="form-control mb-1"
                    defaultValue={primaryCareTakerModel.secondCareTakerCurrentProfession}
                  />
                  {primaryCareTakerModel.secondCareTakerCurrentProfessionError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {primaryCareTakerModel.secondCareTakerCurrentProfessionError}
                    </div>
                  )}
                </div>
                <div className="medical-group col-12 col-md-3">
                  <label className="form-label">Present Age</label>
                  <input
                    disabled={props.isReadOnly ? true : false}
                    type="number"
                    placeholder="Age"
                    defaultValue={primaryCareTakerModel.secondCareTakerAge}
                    onFocus={handleOnBlur}
                    {...register("secondCareTakerAge")}
                    className="form-control mb-1"
                    maxLength={3}
                    onInput={maxLengthCheck}
                    onKeyDown={e => (e.keyCode === 69) && e.preventDefault()}
                  />
                  {primaryCareTakerModel.secondCareTakerAgeError && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {primaryCareTakerModel.secondCareTakerAgeError}
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="medical-group col-12 col-md-6">
                  <label className="form-label">
                    Highest level of education completed
                  </label>
                  <select
                    disabled={props.isReadOnly ? true : false}
                    {...register("secondCareTakerHighestLevelEducation")}
                    className={"form-control mb-1 phone_border"}
                    //defaultValue={primaryCareTakerModel.secondCareTakerHighestLevelEducation}
                    onChange={handleOnBlur}
                  >
                    <option value="">None</option>
                    {educationLevelModel.length > 0 &&
                      educationLevelModel.map((item, index) => (
                        <option
                          key={index}
                          selected={
                            item.value ==
                            (primaryCareTakerModel.secondCareTakerHighestLevelEducation == null
                              ? 0
                              : primaryCareTakerModel.secondCareTakerHighestLevelEducation.toString())
                          }
                          value={item.value}
                        >
                          {item.text}
                        </option>
                      ))}
                  </select>
                  <i className="fa fa-angle-down arrow"></i>
                </div>

                <div className="medical-group col-12 col-md-3 btn-select">
                  <label className="form-label">Live with child full time?</label>
                  <div className="btn-group btnAdopted">
                    <button
                      disabled={props.isReadOnly ? true : false}
                      type="button"
                      onClick={() => liveWithChildFullTime(2, true)}
                      className={
                        primaryCareTakerModel.isSecondCareTakerLiveWithChildFullTime == true ||
                        primaryCareTakerModel.isSecondCareTakerLiveWithChildFullTime == null
                        ? "btn btn-primary btn-sm btnAdopted_yes"
                        : "btn btn-outline-secondary btn-sm btnAdopted_others"
                      }
                    >
                      Yes
                    </button>
                    <button
                      disabled={props.isReadOnly ? true : false}
                      type="button"
                      className={
                        primaryCareTakerModel.isSecondCareTakerLiveWithChildFullTime == false
                        ? "btn btn-primary btn-sm btnAdopted_yes"
                        : "btn btn-outline-secondary btn-sm btnAdopted_others"
                      }
                      onClick={() => liveWithChildFullTime(2, false)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
              <div className="row my-3">
                <div className="medical-group col-12 col-md-5 btn-select">
                  <label className="form-label">Marital Status</label>
                  <div className="btn-group btnGender">
                    <button
                      disabled={props.isReadOnly ? true : false}
                      type="button"
                      value={MaritalStatus.Married}
                      {...register("married2")}
                      onClick={secondCareTakerMaritalStatus}
                      className={
                        primaryCareTakerModel.secondCareTakerMaritalStatus == MaritalStatus.Married
                        ? "btn btn-primary btn-sm btnGender_selected"
                        : "btn btn-outline-secondary btn-sm btnGender_status"
                      }
                    >
                      Married
                    </button>
                    <button
                      disabled={props.isReadOnly ? true : false}
                      type="button"
                      {...register("separeted2")}
                      className={
                        primaryCareTakerModel.secondCareTakerMaritalStatus ==
                        MaritalStatus.Separeted
                        ? "btn btn-primary btn-sm btnGender_selected"
                        : "btn btn-outline-secondary btn-sm btnGender_status"
                      }
                      value={MaritalStatus.Separeted}
                      onClick={secondCareTakerMaritalStatus}
                    >
                      Separated
                    </button>
                    <button
                      disabled={props.isReadOnly ? true : false}
                      type="button"
                      {...register("widowed2")}
                      className={
                        primaryCareTakerModel.secondCareTakerMaritalStatus == MaritalStatus.Widowed
                        ? "btn btn-primary btn-sm btnGender_selected"
                        : "btn btn-outline-secondary btn-sm btnGender_status"
                      }
                      value={MaritalStatus.Widowed}
                      onClick={secondCareTakerMaritalStatus}
                    >
                      Widowed
                    </button>
                    <button
                      disabled={props.isReadOnly ? true : false}
                      type="button"
                      {...register("divorced2")}
                      className={
                        primaryCareTakerModel.secondCareTakerMaritalStatus == MaritalStatus.Divorced
                        ? "btn btn-primary btn-sm btnGender_selected"
                        : "btn btn-outline-secondary btn-sm btnGender_status"
                      }
                      value={MaritalStatus.Divorced}
                      onClick={secondCareTakerMaritalStatus}
                    >
                      Divorced
                    </button>

                    <button
                      disabled={props.isReadOnly ? true : false}
                      type="button"
                      {...register("singleOrNeverMarried2")}
                      className={
                        primaryCareTakerModel.secondCareTakerMaritalStatus ==
                        MaritalStatus.SingleOrNeverMarried
                        ? "btn btn-primary btn-sm btnGender_selected"
                        : "btn btn-outline-secondary btn-sm btnGender_status"
                      }
                      value={MaritalStatus.SingleOrNeverMarried}
                      onClick={secondCareTakerMaritalStatus}
                    >
                      Single/Never Married
                    </button>
                    {primaryCareTakerModel.secondCareTakerMaritalStatusError && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block", textAlign: "left" }}
                      >
                        {primaryCareTakerModel.secondCareTakerMaritalStatusError}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="medical__primary_formRow3">
                <p style={{ marginTop: "15px", marginBottom: "0px", color: "var(--color-slaty)" }}>
                  Please list any other caretakers not listed above.
                </p>
              </div>
              <div className="row">
                <div className="medical-group col-12 col-md-3 optional-field">
                  <label className="form-label">Name</label>
                  <span className="optional">Optional</span>
                  <input
                    type="text"
                    className={"form-control mb-1"}
                    defaultValue={primaryCareTakerModel.name}
                    placeholder="Name"
                    onFocus={handleOnBlur}
                    {...register("name")}
                  />
                </div>
                <div className="medical-group col-12 col-md-3 optional-field">
                  <label className="form-label">Relationship</label>
                  <span className="optional">Optional</span>
                  <input
                    type="text"
                    className={"form-control mb-1"}
                    //defaultValue={primaryCareTakerModel.relationship}
                    placeholder="Relationship"
                    onFocus={handleOnBlur}
                    {...register("relationship")}
                  />
                </div>
              </div>
            </div>
            <div className="btnContainer">
              {!props.isSaving && (
                <Button
                  type="default"
                  size="large"
                  className="back_btn"
                  onClick={() => props.previousTabChange(0)}
                >
                  <i className="fa fa-long-arrow-left"></i> Back
                </Button>
              )}
              {!props.isSaving && (
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className="btn_add"
                  
                >
                  Save <i className="fa fa-long-arrow-right"></i>
                </Button>
              )}
              {props.isSaving && (
                <div style={{ height: "60px" }}>
                  <Loader
                    loading={props.isSaving}
                    marginBottom="0px"
                    marginTop="8px"
                    width="368px"
                  ></Loader>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
