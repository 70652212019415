import React from "react";
import { NavLink } from "react-router-dom";
import "./styles.css";
import "../patient/patientList/index.scss";
import moment from "moment";
import { Gender } from "../../shared/enums/gender.enum";

export interface ListProps {
  initials?: string;
  mrNo?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  patientChange: (text: any) => void;
  isSelected: boolean;
  listIcon: string;
  navUrl: string;
  index: number;
  age?: number;
  dateOfBirth?: string;
  isListingTypeTeacher: boolean;
  linkStyle: string;
  gender?: number;
  border: string;
  accStatus: string;
}

const LeftListing: React.FC<ListProps> = ({
  index,
  initials,
  mrNo,
  email,
  firstName,
  lastName,
  patientChange,
  isSelected,
  listIcon,
  navUrl,
  age,
  dateOfBirth,
  isListingTypeTeacher,
  linkStyle,
  gender,
  border,
  accStatus,
}) => {
  let options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  return (
    <li
      className={isSelected ? "list-sel user-list-item" : "user-list-item"}
      onClick={patientChange}
      key={"list" + index}
    >
      <NavLink className={`user-link ${linkStyle}`} to={navUrl}>
        <div className={`user-icon ${listIcon}`}>
          <span>{initials}</span>
        </div>
        <div className="name-role">
          <span className="name">
            {lastName + ","} {firstName}
          </span>
          {!isListingTypeTeacher && <span className="mrNo"> MRN: {mrNo + ","}</span>}
          <span className="mrNo">
            Date of Birth:{" "}
            {dateOfBirth == null ? "" : new Date(dateOfBirth).toLocaleDateString("en", options)},{" "}
            {gender == null
              ? ""
              : gender == Gender.Male
              ? "Male"
              : gender == Gender.Female
              ? "Female"
              : "Other"}
          </span>
          {accStatus && (
            <span className="acc__status" style={{ border: border }}>
              {accStatus}
            </span>
          )}
        </div>
      </NavLink>
    </li>
  );
};

export default LeftListing;
