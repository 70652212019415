import { AppConfig, HttpWrapper } from "../../core";
import { RespondentDayData } from "../../models/Respondent/respondent-target-symptom-day-data.model";
import { RespondentWeeklyDataModel } from "../../models/Respondent/respondent-weekly-data.model";
export class ParentOrTeacherRatingDataService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getParentOrTeachingRatingData(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint + "parentorteacherrating/" + patientId
    );
  }
  getRespondentRatingData(patientId: string) {
    return this.wrapper.get<RespondentWeeklyDataModel>(
      AppConfig.apiEndpoint + "parentorteacherrating/respondent/" + patientId
    );
  }
  updateTargetDay(respondentDayData: RespondentDayData) {
    return this.wrapper.post<boolean>(
      AppConfig.apiEndpoint+ "parentorteacherrating/update", respondentDayData
    );
  }
}
