import React, { Fragment, useEffect, useState } from 'react'
import {
    Editor,
    EditorState,
    RichUtils,
    convertFromHTML,
    ContentState,
    KeyBindingUtil,
    getDefaultKeyBinding,
  } from "draft-js";
  import "draft-js/dist/Draft.css";
  import { stateToHTML } from "draft-js-export-html";

  interface IProps{
    onNoteSave: Function;        
    patientTrialNote: string;
  }

  export default function PatientTrialNote(props: IProps) {
    const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );
  const {hasCommandModifier} = KeyBindingUtil;
  const [noteText, setNoteText] = useState<string>("");
  useEffect(() => {
    console.log("PropsChanged")
    if (props.patientTrialNote && props.patientTrialNote.length > 0) {
      const blocksFromHTML = convertFromHTML(
        props.patientTrialNote
      );
      const initialNote = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const initialEditorState = EditorState.createWithContent(initialNote);
      setEditorState(initialEditorState);
    }
    else {
        setEditorState(EditorState.createEmpty())
    }
  }, [props]);
  useEffect(() => {
    let html = stateToHTML(editorState.getCurrentContent());
    setNoteText(html);
  }, [editorState]);
  function handleKeyBindingFn(e): string | null {
    if (e.keyCode === 49 && hasCommandModifier(e)) {
      return 'unordered-list-item';
    }
    return getDefaultKeyBinding(e);
  };
  
  const handleKeyCommand = (command, editorState) => {
    if (command === 'unordered-list-item'){
      setEditorState(RichUtils.toggleBlockType(editorState, command));
      return "handled";
    }
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };
  const handleNoteSave = () => {
    props.onNoteSave(noteText);
  }
  return (
    <Fragment>
      <div className="d-block border">
        <Editor
          placeholder="Use this note pad to record notes pertaining to this patient trial."
          editorState={editorState}
          onChange={setEditorState}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={handleKeyBindingFn}
        />
      </div>
      <div className="p-3 text-center">
      <button
                  type="submit"
                  onClick={handleNoteSave}
                  className="ant-btn ant-btn-primary ant-btn-lg btn_add"
                >
                  <span>Save and Close</span>
                </button>
      </div>
    </Fragment>
  );
}