import React from "react";
import { LeftOutlined } from "@ant-design/icons";
import { RightOutlined } from "@ant-design/icons"
import "./styles.css";

function WeekSliderBtn({ onClick, btnStyle }) {
  return (
    <div onClick={onClick} className={`week__btn ${btnStyle}`}>
      <LeftOutlined className="btn_icon" />
      {/* <p>{btnText}</p> */}
    </div>
  );
}
export default WeekSliderBtn;
