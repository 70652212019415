import React, { Component } from "react";
import { HttpResponse } from "../../../core";
import { BehaviourProfileService } from "../../../services/behaviourProfile/behaviour-profile.service";
import { GetBehaviourProfileStepStatus } from "../../patient/models/get-behaviour-profile.model";
import Tabs from "../../tabs";
import "../styles.css";
import { BehaviouralAssets } from "./behaviouralAssets";
import { BehavioralProblem } from "./behaviourProblem";

interface State {
  tab: number;
  patientId: string;
  isFormSave?: Function;
  getBehaviourProfileStatus?: GetBehaviourProfileStepStatus;
}
class MedicalBehaviour extends Component<any, State> {
  private behaviourProfileService: BehaviourProfileService;
  state: State = { tab: 0, patientId: "" };
  changeTab(tab: any) {
    this.setState({
      tab,
    });
  }
  constructor(props) {
    super(props);
    this.behaviourProfileService = new BehaviourProfileService();
  }
  componentDidMount() {
    this.loadData();
  }
  loadData() {
    if (this.props.patientId) {
      this.behaviourProfileService
        .getBehaviourProfileStepStatus(this.props.patientId)
        .then((res: HttpResponse<GetBehaviourProfileStepStatus>) => {
          if (res && res.result) {
            console.log(res.result);
            this.setState({ getBehaviourProfileStatus: res.result });
            //this.getLastStep();
          }
        })
        .catch((ex) => {});
    }
  }
  behaviourProfileStepUpdateHandler = (key, value) => {
    if (this.state.getBehaviourProfileStatus) {
      if (key == 1) {
        this.state.getBehaviourProfileStatus.behavioralProblemId = value;
      } else if (key == 2) {
        this.state.getBehaviourProfileStatus.behavioralAssetsId = value;
      }
    }
  };
  render() {
    const { tab } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="medical__family_tabs d-none d-xl-block col-3">
            <Tabs
              styleName="familyTab"
              text="Behavioral Problems"
              steps=""
              stepsAct=""
              fullText="Behavioral Problems"
              familyTextName="A"
              image=""
              activeImage=""
              tabChange={() => this.changeTab(0)}
              selected={tab === 0}
              isData={
                this.state.getBehaviourProfileStatus?.behavioralProblemId !=
                null
              }
            />
            <Tabs
              styleName="familyTab"
              text="Behavioral Assets"
              steps=""
              stepsAct=""
              fullText="Behavioral Assets"
              familyTextName="B"
              image=""
              activeImage=""
              tabChange={() => this.changeTab(1)}
              selected={tab === 1}
              isData={
                this.state.getBehaviourProfileStatus?.behavioralAssetsId != null
              }
            />
          </div>
          <div className="medical__family_content col">
            {tab === 0 && (
              <BehavioralProblem
                patientId={this.props.patientId}
                nextTabChange={() => this.changeTab(1)}
                parentTabChange={this.props.parentTab}
                isFormSave={this.props.isFormSave}
                behaviourProfileStepUpdate={
                  this.behaviourProfileStepUpdateHandler
                }
              />
            )}
            {tab === 1 && (
              <BehaviouralAssets
                previousTabChange={() => this.changeTab(0)}
                patientId={this.props.patientId}
                nextTabChange={this.props.nextTabChange}
                isFormSave={this.props.isFormSave}
                behaviourProfileStepUpdate={
                  this.behaviourProfileStepUpdateHandler
                }
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default MedicalBehaviour;
