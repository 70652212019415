import React, { Fragment } from "react";
import { HttpResponse } from "../../../../core";
import { AddTherapyPostModel } from "../../../../models/therapy/add-therapy-post.model";
import { TherapyModel } from "../../../../models/therapy/therapy.model";
import { TherapyService } from "../../../../services/therapy/therapy.service";
import { TherapyType } from "../../../../shared/enums/therapy/therapy-type";
import { ShowSuccessMessage } from "../../../../shared/helpers";
import AddFamilyTherapyForm from "./familyTherapy";

interface IState {
  patientId: string;
  isLoading: boolean;
  therapyModel: AddTherapyPostModel;
  reRenderForm: boolean;
  isEnabled: boolean;
  isFormSave?: Function;
  therapyStepUpdate?:Function;
}
export class FamilyTherapy extends React.Component<any, IState> {
  private therapyService: TherapyService;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.therapyService = new TherapyService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      patientId: this.props.patientId,
      reRenderForm: false,
      therapyModel: {} as AddTherapyPostModel,
      isEnabled: false,
    };
    return initialState;
  }
  componentDidMount() {
    this.loadData();
    window.scroll(0, 0);
  }
  previousTabChange = () => {
    this.props.previousTabChange();
  };

  postData = (data) => {
    let therapyPostData = data.therapyModel;
    if (this.state.isLoading) {
      return;
    }
      if (data.isAutoSave) {
          this.setLoading(false);
      }
      else {
          this.setLoading(true);
      }
    this.therapyService
      .postTherapy(TherapyType.Family, therapyPostData)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
        }
        this.setLoading(false);
        if (!data.isAutoSave) {
          ShowSuccessMessage("Data Saved Successfully.");
          this.props.nextTabChange();
        }
        this.props.isFormSave();
        this.loadData(data.isAutoSave);
      })
      .catch(() => {
        this.setLoading(false);
      });
  };
  parentTabChange = (tab: any) => {
    this.props.parentTabChange(tab);
  };

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }
  loadData = (isAutoSave?: any) => {
    if (this.state.patientId) {
        if (isAutoSave) {
            this.setLoading(false);
        }
        else {
            this.setLoading(true);
        }
      this.therapyService
        .getTherapyDetail(this.props.patientId, TherapyType.Family)
        .then((res: HttpResponse<AddTherapyPostModel>) => {
          this.setLoading(false);
          if(res && res.result){
            this.props.therapyStepUpdate(2, res.result.isFamilyTherapy);
          }
          if (res && res.result && res.result.lstTherapyModel.length > 0) {
            if (isAutoSave) {
              let pm = this.state.therapyModel;
              res.result.lstTherapyModel.forEach((item, index) => {
                pm.lstTherapyModel[index].id = item.id;
              });
              this.setState({
                therapyModel: {
                  patientId: this.props.patientId,
                  lstTherapyModel: pm.lstTherapyModel,
                },
              });
            } else {
              this.setState({
                therapyModel: {
                  patientId: this.props.patientId,
                  lstTherapyModel:  res.result.lstTherapyModel,
                },
                isEnabled: true,
                reRenderForm: true,
              });
              this.setState({ reRenderForm: false });
            }
          } else {
            this.setState({
              therapyModel: {
                patientId: this.props.patientId,
                lstTherapyModel: [
                  {
                    id: "",
                    nameOfProfessionalOrOrganization: "",
                    purpose: "",
                    type: TherapyType.Family,
                  },
                ],
              } as AddTherapyPostModel,
              isEnabled: false,
              reRenderForm: true,
            });
            this.setState({ reRenderForm: false });
          }
        })
        .catch((ex) => {
          this.setLoading(false);
        });
    }
  };
  deleteTherapy = (siblingId) => {
    this.therapyService.deleteTherapy(siblingId).then((res: HttpResponse<boolean>) => {
      if (res && res.result) {
        //this.loadData();
      }
    });
  };
  assignModel = (data: any) => {
    this.setState({
      therapyModel: data.therapyModel,
    });
  };

  render() {
    return (
      <Fragment>
        {!this.state.reRenderForm && (
          <AddFamilyTherapyForm
            onSubmit={this.postData}
            isSaving={this.state.isLoading}
            patientId={this.props.patientId}
            assignModel={this.assignModel}
            therapyModel={this.state.therapyModel}
            parentTabChange={this.parentTabChange}
            isEnabled={this.state.isEnabled}
            deleteTherapy={this.deleteTherapy}
            previousTabChange={this.previousTabChange}
          />
        )}
      </Fragment>
    );
  }
}
