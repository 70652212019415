import { useForm } from "react-hook-form";
import "../../styles.css";
import Heading from "../../../heading";
import { Button } from "antd";
import { AddPerinatalPeriodModel } from "../../../../models/birthHistory/perinatalPeriod/add-perinatal-period.model";
import React, { useEffect } from "react";
import { TypeOfLabour } from "../../../../shared/enums/type-of-labour.enum";
import { TypeOfDelivery } from "../../../../shared/enums/type-of-delivery.enum";
import { Loader } from "../../../../shared/loaders";
import { DevTool } from "@hookform/devtools";

type PerinatalPeriodFormValues = {
  specifySpecialTreatement: string | undefined;
  durationOfLabour: string | undefined;
  injury: string | undefined;
  comments: string | undefined;
}
interface IProps {
  perinatalPeriodModel: AddPerinatalPeriodModel;
  onSubmit: Function;
  isSaving: boolean;
  patientId: string;
  assignModel: Function;
  isReadOnly: boolean;
  previousTabChange: Function;
  isBirthHistoryOptional: boolean;
}
export function PerinatalPeriodForm(props: IProps) {
  const { register, handleSubmit, control, getValues, formState, setValue } = useForm<PerinatalPeriodFormValues>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  useEffect(() =>{
    setInitialValues()
  }, [props]);

  function setInitialValues() {
    setValue("comments", props.perinatalPeriodModel.comments);
    setValue("durationOfLabour", props.perinatalPeriodModel.durationOfLabour);
    setValue("injury", props.perinatalPeriodModel.injury);
    setValue("specifySpecialTreatement", props.perinatalPeriodModel.specifySpecialTreatement)
  }
  
  let perinatalPeriodModel = props.perinatalPeriodModel;
  const formSubmit = (data) => {
    if (checkForError()) {
      return false;
    }
    props.onSubmit({
      perinatalPeriodModel: perinatalPeriodModel,
      isAutoSave: false,
    });
  };
  const checkForError = () => {
    let hasError = false;
    perinatalPeriodModel.isCordAroundNeckError = "";
    perinatalPeriodModel.isInfantNeedBloodAtBirthError = "";
    perinatalPeriodModel.isHemorrhageError = "";
    perinatalPeriodModel.isEvidenceOfFetalDistressError = "";
    perinatalPeriodModel.isInfantPlacedInIncubatorError = "";
    //perinatalPeriodModel.durationOfLabourError = "";
    perinatalPeriodModel.typeOfLabourError = "";
    perinatalPeriodModel.typeOfDeliveryError = "";
    perinatalPeriodModel.isInfantNeedOtherSpecialTreatementError = "";
    perinatalPeriodModel.isInfactInjuredDuringDeliveryError = "";
    perinatalPeriodModel.injuryError = "";
    perinatalPeriodModel.specifySpecialTreatementError = "";

    if (perinatalPeriodModel.isCordAroundNeck == null && !props.isBirthHistoryOptional) {
      perinatalPeriodModel.isCordAroundNeckError = "Please select an option.";
      hasError = true;
    }
    if (perinatalPeriodModel.isInfantNeedBloodAtBirth == null && !props.isBirthHistoryOptional) {
      perinatalPeriodModel.isInfantNeedBloodAtBirthError = "Please select an option.";
      hasError = true;
    }
    if (perinatalPeriodModel.isHemorrhage == null && !props.isBirthHistoryOptional) {
      perinatalPeriodModel.isHemorrhageError = "Please select an option.";
      hasError = true;
    }
    if (perinatalPeriodModel.isEvidenceOfFetalDistress == null && !props.isBirthHistoryOptional) {
      perinatalPeriodModel.isEvidenceOfFetalDistressError = "Please select an option.";
      hasError = true;
    }
    if (perinatalPeriodModel.isInfantPlacedInIncubator == null && !props.isBirthHistoryOptional) {
      perinatalPeriodModel.isInfantPlacedInIncubatorError = "Please select an option.";
      hasError = true;
    }
    if (perinatalPeriodModel.isInfantNeedOtherSpecialTreatement == null && !props.isBirthHistoryOptional) {
      perinatalPeriodModel.isInfantNeedOtherSpecialTreatementError = "Please select an option.";
      hasError = true;
    }
    // if (perinatalPeriodModel.durationOfLabour == null) {
    //   perinatalPeriodModel.durationOfLabourError =
    //     "Please Enter Duration Of Labour.";
    //   hasError = true;
    // }
    if (perinatalPeriodModel.typeOfLabour == null && !props.isBirthHistoryOptional) {
      perinatalPeriodModel.typeOfLabourError = "Please select an option.";
      hasError = true;
    }
    if (perinatalPeriodModel.typeOfDelivery == null && !props.isBirthHistoryOptional) {
      perinatalPeriodModel.typeOfDeliveryError = "Please select an option.";
      hasError = true;
    }
    if (perinatalPeriodModel.isInfactInjuredDuringDelivery == null && !props.isBirthHistoryOptional) {
      perinatalPeriodModel.isInfactInjuredDuringDeliveryError = "Please select an option.";
      hasError = true;
    }
    if (
      perinatalPeriodModel.isInfactInjuredDuringDelivery == true &&
      (perinatalPeriodModel.injury == null || perinatalPeriodModel.injury == "")
    ) {
      perinatalPeriodModel.injuryError = "Please specify.";
      hasError = true;
    }
    if (
      perinatalPeriodModel.isInfantNeedOtherSpecialTreatement == true &&
      (perinatalPeriodModel.specifySpecialTreatement == null ||
        perinatalPeriodModel.specifySpecialTreatement == "")
    ) {
      perinatalPeriodModel.specifySpecialTreatementError = "Please specify.";
      hasError = true;
    }
    props.assignModel({ perinatalPeriodModel: perinatalPeriodModel });
    return hasError;
  };
  const handleKeyUp = (event) => {
    setValuesInModel();
    if (event.key == "Tab") {
      saveDataOnTabChange();
    }
  };
  const handleOnFocus = (event) => {
    setValuesInModel();
    saveDataOnTabChange();
  };
  function setValuesInModel() {
    let model = getValues();
    setDataInModel(model);
  }
  const setDataInModel = (data) => {
    perinatalPeriodModel.durationOfLabour = data.durationOfLabour;
    perinatalPeriodModel.injury = data.injury;
    perinatalPeriodModel.specifySpecialTreatement = data.specifySpecialTreatement;
    perinatalPeriodModel.comments = data.comments;
  };
  const saveDataOnTabChange = () => {
    if (!props.isSaving) {
      perinatalPeriodModel.patientId = props.patientId;
      props.onSubmit({
        perinatalPeriodModel: perinatalPeriodModel,
        isAutoSave: true,
      });
    }
  };

  const yesNoSelection = (type, isChecked) => {
    setValuesInModel();
    switch (type) {
      case 1: {
        perinatalPeriodModel.isCordAroundNeck = isChecked;
        props.assignModel({ perinatalPeriodModel: perinatalPeriodModel });
        break;
      }
      case 2: {
        perinatalPeriodModel.isInfantNeedBloodAtBirth = isChecked;
        props.assignModel({ perinatalPeriodModel: perinatalPeriodModel });
        break;
      }
      case 3: {
        perinatalPeriodModel.isHemorrhage = isChecked;
        props.assignModel({ perinatalPeriodModel: perinatalPeriodModel });
        break;
      }
      case 4: {
        perinatalPeriodModel.isEvidenceOfFetalDistress = isChecked;
        props.assignModel({ perinatalPeriodModel: perinatalPeriodModel });
        break;
      }
      case 5: {
        perinatalPeriodModel.isInfantPlacedInIncubator = isChecked;
        props.assignModel({ perinatalPeriodModel: perinatalPeriodModel });
        break;
      }
      case 6: {
        perinatalPeriodModel.isInfantNeedOtherSpecialTreatement = isChecked;
        if (!isChecked) {
          perinatalPeriodModel.specifySpecialTreatement = "";
        }
        props.assignModel({ perinatalPeriodModel: perinatalPeriodModel });
        break;
      }
      case 7: {
        perinatalPeriodModel.isInfactInjuredDuringDelivery = isChecked;
        if (!isChecked) {
          perinatalPeriodModel.injury = "";
        }
        props.assignModel({ perinatalPeriodModel: perinatalPeriodModel });
        break;
      }
    }

    props.assignModel({ perinatalPeriodModel: perinatalPeriodModel });
    saveDataOnTabChange();
  };

  const setTypeOfLabour = (typeOfLabour: number) => {
    setValuesInModel();
    perinatalPeriodModel.typeOfLabour = typeOfLabour;
    props.assignModel({ perinatalPeriodModel: perinatalPeriodModel });
    saveDataOnTabChange();
  };
  const setTypeOfDelivery = (TypeOfDelivery: number) => {
    setValuesInModel();
    perinatalPeriodModel.typeOfDelivery = TypeOfDelivery;
    props.assignModel({ perinatalPeriodModel: perinatalPeriodModel });
    saveDataOnTabChange();
  };
  return (
    <div>
      <form onSubmit={handleSubmit(formSubmit)}>
        <div>
          <Heading heading="Perinatal Period" subHeading="" styleName="" />
          <div className="form">
            <div className="row">
              <div className="medical-group btn-select col-12 col-md-4 mt-3">
                <label className="text-center">Cord around neck</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => yesNoSelection(1, true)}
                    className={
                      perinatalPeriodModel.isCordAroundNeck == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => yesNoSelection(1, false)}
                    className={
                      perinatalPeriodModel.isCordAroundNeck == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {perinatalPeriodModel.isCordAroundNeckError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {perinatalPeriodModel.isCordAroundNeckError}
                    </div>
                  )}
              </div>
              <div className="medical-group btn-select col-12 col-md-4 mt-3">
                <label className="text-center">Infant needed blood at birth</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => yesNoSelection(2, true)}
                    className={
                      perinatalPeriodModel.isInfantNeedBloodAtBirth == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => yesNoSelection(2, false)}
                    className={
                      perinatalPeriodModel.isInfantNeedBloodAtBirth == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {perinatalPeriodModel.isInfantNeedBloodAtBirthError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {perinatalPeriodModel.isInfantNeedBloodAtBirthError}
                    </div>
                  )}
              </div>
              <div className="medical-group btn-select col-12 col-md-4 mt-3">
                <label className="text-center">Hemorrhage</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => yesNoSelection(3, true)}
                    className={
                      perinatalPeriodModel.isHemorrhage == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => yesNoSelection(3, false)}
                    className={
                      perinatalPeriodModel.isHemorrhage == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {perinatalPeriodModel.isHemorrhageError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {perinatalPeriodModel.isHemorrhageError}
                    </div>
                  )}
              </div>
            </div>

            <div className="row">
              <div className="medical-group btn-select col-12 col-md-4 mt-3">
                <label className="text-center">Evidence of fetal distress</label>
                <div className="btn-group btnGender">
                  <button
                    type="button"
                    onClick={() => yesNoSelection(4, true)}
                    className={
                      perinatalPeriodModel.isEvidenceOfFetalDistress == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => yesNoSelection(4, false)}
                    className={
                      perinatalPeriodModel.isEvidenceOfFetalDistress == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {perinatalPeriodModel.isEvidenceOfFetalDistressError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {perinatalPeriodModel.isEvidenceOfFetalDistressError}
                    </div>
                  )}
              </div>
              <div className="medical-group btn-select col-12 col-md-4 mt-3">
                <label className="text-center">Infant placed in incubator</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => yesNoSelection(5, true)}
                    className={
                      perinatalPeriodModel.isInfantPlacedInIncubator == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => yesNoSelection(5, false)}
                    className={
                      perinatalPeriodModel.isInfantPlacedInIncubator == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {perinatalPeriodModel.isInfantPlacedInIncubatorError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {perinatalPeriodModel.isInfantPlacedInIncubatorError}
                    </div>
                  )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="medical-group btn-select col-12 col-md-4">
                <label className="text-center">Infant needed other special treatment</label>
                <div className="btn-group btnGender">
                  <button
                    type="button"
                    onClick={() => yesNoSelection(6, true)}
                    className={
                      perinatalPeriodModel.isInfantNeedOtherSpecialTreatement == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => yesNoSelection(6, false)}
                    className={
                      perinatalPeriodModel.isInfantNeedOtherSpecialTreatement == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {perinatalPeriodModel.isInfantNeedOtherSpecialTreatementError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {perinatalPeriodModel.isInfantNeedOtherSpecialTreatementError}
                    </div>
                  )}
              </div>
              <div className="medical-group btn-select col-12 col-md-8 mt-2 mt-md-0" >
                <label className="form-label">Specify</label>
                <input
                  type="text"
                  {...register("specifySpecialTreatement")}
                  className="form-control mb-1"
                  //defaultValue={perinatalPeriodModel.specifySpecialTreatement}
                  placeholder=""
                  disabled={
                    !perinatalPeriodModel.isInfantNeedOtherSpecialTreatement ||
                    perinatalPeriodModel.isInfantNeedOtherSpecialTreatement == null
                  }
                  onBlur={handleOnFocus}
                />
                {perinatalPeriodModel.specifySpecialTreatementError && (
                  <div className="invalid-feedback" style={{ display: "block", textAlign: "left" }}>
                    {perinatalPeriodModel.specifySpecialTreatementError}
                  </div>
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="medical-group btn-select col-12 col-md-4">
                <label className="text-center">Type of labor</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => setTypeOfLabour(TypeOfLabour.Induced)}
                    className={
                      perinatalPeriodModel.typeOfLabour == TypeOfLabour.Induced
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Induced
                  </button>
                  <button
                    type="button"
                    onClick={() => setTypeOfLabour(TypeOfLabour.Spontaneous)}
                    className={
                      perinatalPeriodModel.typeOfLabour == TypeOfLabour.Spontaneous
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Spontaneous
                  </button>
                  
                </div>
                {perinatalPeriodModel.typeOfLabourError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {perinatalPeriodModel.typeOfLabourError}
                    </div>
                  )}
              </div>
              <div className="medical-group btn-select col-12 col-md-8 mt-2 mt-md-0">
                <label className="form-label">Duration of labor</label>
                <input
                  type="text"
                  {...register("durationOfLabour")}
                  className="form-control mb-1"
                  //defaultValue={perinatalPeriodModel.durationOfLabour}
                  placeholder=""
                  onBlur={handleOnFocus}
                />
                {/* {perinatalPeriodModel.durationOfLabourError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {perinatalPeriodModel.durationOfLabourError}
                    </div>
                  )} */}
              </div>
            </div>
            <div className="row mt-3">
              <div className="medical-group btn-select col-12 col-md-6">
                <label className="text-center">Type of delivery</label>
                <div className="btn-group btnGender">
                  <button
                    type="button"
                    onClick={() => setTypeOfDelivery(TypeOfDelivery.Normal)}
                    className={
                      perinatalPeriodModel.typeOfDelivery == TypeOfDelivery.Normal
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Normal
                  </button>
                  <button
                    type="button"
                    onClick={() => setTypeOfDelivery(TypeOfDelivery.Cesarean)}
                    className={
                      perinatalPeriodModel.typeOfDelivery == TypeOfDelivery.Cesarean
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Cesarean
                  </button>
                  <button
                    type="button"
                    onClick={() => setTypeOfDelivery(TypeOfDelivery.Forceps)}
                    className={
                      perinatalPeriodModel.typeOfDelivery == TypeOfDelivery.Forceps
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Forceps
                  </button>
                  <button
                    type="button"
                    onClick={() => setTypeOfDelivery(TypeOfDelivery.AnestheticUsed)}
                    className={
                      perinatalPeriodModel.typeOfDelivery == TypeOfDelivery.AnestheticUsed
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Anesthetic used
                  </button>
                  
                </div>
                {perinatalPeriodModel.typeOfDeliveryError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {perinatalPeriodModel.typeOfDeliveryError}
                    </div>
                  )}
              </div>
            </div>

            <div className="row mt-3">
              <div className="medical-group btn-select col-12 col-md-4">
                <label className="text-center">Infant injured during delivery?</label>
                <div className="btn-group btnAdopted">
                  <button
                    type="button"
                    onClick={() => yesNoSelection(7, true)}
                    className={
                      perinatalPeriodModel.isInfactInjuredDuringDelivery == true
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => yesNoSelection(7, false)}
                    className={
                      perinatalPeriodModel.isInfactInjuredDuringDelivery == false
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    No
                  </button>
                  
                </div>
                {perinatalPeriodModel.isInfactInjuredDuringDeliveryError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {perinatalPeriodModel.isInfactInjuredDuringDeliveryError}
                    </div>
                  )}
              </div>
              <div className="medical-group btn-select col-12 col-md-8 mt-2 mt-md-0">
                <label className="form-label">Specify</label>
                <input
                  type="text"
                  {...register("injury")}
                  className="form-control mb-1"
                  //defaultValue={perinatalPeriodModel.injury}
                  placeholder=""
                  disabled={
                    !perinatalPeriodModel.isInfactInjuredDuringDelivery ||
                    perinatalPeriodModel.isInfactInjuredDuringDelivery == null
                  }
                  onBlur={handleOnFocus}
                />
                {perinatalPeriodModel.injuryError && (
                  <div className="invalid-feedback" style={{ display: "block", textAlign: "left" }}>
                    {perinatalPeriodModel.injuryError}
                  </div>
                )}
              </div>
            </div>

            <div className="medical__birth_formRow5">
              <div className="medical-group optional-field textarea_group">
                <label className="form-label">Comments</label>
                <span className="optional">Optional</span>
                <textarea
                  {...register("comments")}
                  className="form-control textarea"
                  //defaultValue={perinatalPeriodModel.comments}
                  onBlur={handleOnFocus}
                  placeholder="Please provide any additional information."
                ></textarea>
              </div>
            </div>
          </div>
          <div className="btnContainer">
            {!props.isSaving && (
              <Button
                type="default"
                size="large"
                className="back_btn"
                onClick={() => props.previousTabChange(1)}
              >
               <i className="fa fa-long-arrow-left"></i> Back
              </Button>
            )}
            {!props.isSaving && (
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="btn_add"
              >
                Save <i className="fa fa-long-arrow-right"></i>
              </Button>
            )}
            {props.isSaving && (
              <div style={{ height: "60px" }}>
                <Loader
                  loading={props.isSaving}
                  marginBottom="0px"
                  marginTop="8px"
                  width="368px"
                ></Loader>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
