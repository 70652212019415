import { HttpWrapper } from "../../../core";
import { AppConfig } from "../../../core/app.config";
import { AddPatientModel } from "../models/add-patient";
import { PatientDetailModel } from "../models/patient-detail.model";
import { PatientListFilterModel } from "../models/patient-list-filter.model";
import { PatientListResponseModel } from "../models/patient-list-response.model";
import { PatientsListByParentIdModel } from "../models/patients-list-By-parentId.model";
import { AddTeacherModel } from "../../../models/teacher/add-teacher.model";
import { CheckEmailExistsModel } from "../../../models/patient/check-email.model";
import { TeacherModel } from "../../../models/teacher/teacher.model";
import { DeactivatePatientModel } from "../../../models/patient/deactivate-patient.model";
import { RespondentInformation } from "../../../models/Respondent/RespondentInfo";

export class PatientService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }
  postUser(patient: AddPatientModel) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "patient/create/",
      patient
    );
  }

  getPatientList(filterModel: PatientListFilterModel) {
    return this.wrapper.post<PatientListResponseModel>(
      AppConfig.apiEndpoint + "patient/list",
      filterModel
    );
  }
  getUserById(id?: string | null) {
    return this.wrapper.get<PatientDetailModel>(
      AppConfig.apiEndpoint + "patient/detail/" + id
    );
  }
  getPatientsListByParentId(id?: string | null) {
    return this.wrapper.get<PatientsListByParentIdModel>(
      AppConfig.apiEndpoint + "patient/patients-list-by-parentid/" + id
    );
  }

  getActivePatientList(filterModel: PatientListFilterModel) {
    return this.wrapper.post<PatientListResponseModel>(
      AppConfig.apiEndpoint + "patient/active/list",
      filterModel
    );
    }

    getExistingTeacherList() {
        return this.wrapper.get<any>(AppConfig.apiEndpoint + 'patient/existing/teacherlist');
    }
    postTeacher(patient: AddTeacherModel) {
        return this.wrapper.post<any>(
            AppConfig.apiEndpoint + "patient/create/teacher",
            patient
        );
    }
    checkIsMr_NoExists(patient: AddPatientModel){
      return this.wrapper.post<any>(
        AppConfig.apiEndpoint + "patient/checkMr_No/",
        patient
      ); 
    }
    checkIsEmailExists(user: CheckEmailExistsModel){
      return this.wrapper.post<any>(
        AppConfig.apiEndpoint + "patient/checkEmailExists/",
        user
      ); 
    }
    postPatient(patient: AddPatientModel) {
      return this.wrapper.post<any>(
        AppConfig.apiEndpoint + "patient/create/patientOnly",
        patient
      );
    }
    postGuardian(patient: AddPatientModel) {
      return this.wrapper.post<any>(
        AppConfig.apiEndpoint + "patient/create/guardianOnly",
        patient
      );
    }
    getTeacherById(id?: string | null) {
        return this.wrapper.get<TeacherModel>(
            AppConfig.apiEndpoint + "patient/teacher/detail/" + id
        );
    }
    deactivatePatient(deactivatePatientModel: DeactivatePatientModel) {
      return this.wrapper.post<any>(
        AppConfig.apiEndpoint + "patient/deactivatePatient/",
        deactivatePatientModel
      );
    }

    getRespondentById(id?: string | null){
      return this.wrapper.get<RespondentInformation>(
        AppConfig.apiEndpoint + "patient/respondent/detail/" + id
      )
    }
    getPatientFromEhrByMRN(mrn : string){
      return this.wrapper.get<PatientDetailModel>(
        AppConfig.apiEndpoint + "patient/detail/" + mrn
      );
    }
}
