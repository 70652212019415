import { AppConfig, HttpWrapper } from "../../core";
import { ClinicianAddTargetOneAlias } from "../../models/clinicianWeeklyData/clinician-add-target-one-alias.model";
import { ClinicianWeeklyData } from "../../models/clinicianWeeklyData/clinician-weekly-data.model";

export class ClinicianWeeklyDataService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }
  
  getClinicianWeeklyData(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint +
        "clinicianWeeklyData/" +
        patientId
    );
  }
  postClinicianSideEffectsData(request: ClinicianWeeklyData) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "clinicianWeeklyData/create/side-effects",
      request);
  }
  postClinicianTargetSymptomsData(request: ClinicianWeeklyData) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "clinicianWeeklyData/create/target-symptoms",
      request);
  }
  postClinicianRankingData(request: ClinicianWeeklyData) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "clinicianWeeklyData/create/ranking",
      request);
  }
  postClinicianAddImprovementsData(request: ClinicianWeeklyData) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "clinicianWeeklyData/create/improvements",
      request);
  }
  postClinicianAddVisitData(request: ClinicianWeeklyData) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "clinicianWeeklyData/add/visit-date",
      request);
  }
  postTargetOneAliasData(request: ClinicianAddTargetOneAlias) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "clinicianWeeklyData/add/targetOneAlias",
      request);
  }
  
}
