import { AppConfig, HttpWrapper } from "../../core";

export class FamilyBackgroundDetailService {
    private wrapper: HttpWrapper;
    constructor() {
      this.wrapper = new HttpWrapper();
    }
    getFamilyBackgroundDetail(patientId: string) {
        return this.wrapper.get<any>(
          AppConfig.apiEndpoint +
            "patientDetail/family-background/" +
            patientId
        );
      }
}