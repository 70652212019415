import React, { Fragment } from "react";
import { HttpResponse } from "../../../../core";
import { AddChildTemperamentModel } from "../../../../models/developmentProfile/childTemperament/add-child-temperament.model";
import { DevelopmentProfileService } from "../../../../services/developmentProfile/development-profile.service";
import { ShowSuccessMessage } from "../../../../shared/helpers";
import AddChildTemperamentForm from "./childTemperamentForm";

interface IState {
  patientId: string;
  isLoading: boolean;
  childTemperamentModel: AddChildTemperamentModel;
  reRenderForm: boolean;
  isFormSave?: Function;
  developmentProfileStepUpdate?: Function;
}
export class ChildTemperament extends React.Component<any, IState> {
  private developmentProfileService: DevelopmentProfileService;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.developmentProfileService = new DevelopmentProfileService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      patientId: this.props.patientId,
      reRenderForm: false,
      childTemperamentModel: {} as AddChildTemperamentModel,
    };
    return initialState;
  }
  componentDidMount() {
    this.loadData();
    window.scroll(0, 0);
  }

  postData = (data) => {
    let childTemperamentPostData = data.childTemperament;
    if (this.state.isLoading) {
      return;
    }
      if (data.isAutoSave) {
          this.setLoading(false);
      }
      else {
          this.setLoading(true);
      }
    this.developmentProfileService
      .postChildTemperament(childTemperamentPostData)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
          this.state.childTemperamentModel.id=res.result;
        }
        this.setLoading(false);
        if (!data.isAutoSave) {
          ShowSuccessMessage("Data Saved Successfully.");
          this.props.nextTabChange();
        }
        this.props.isFormSave();
        if (!data.isAutoSave) {
          this.loadData(data.isAutoSave);   
        }
      })
      .catch(() => {
        this.setLoading(false);
      });
  };
  parentTabChange = (tab: any) => {
    this.props.parentTabChange(tab);
  };

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }
  loadData = (isAutoSave?: any) => {
    if (this.state.patientId) {
        if (isAutoSave) {
            this.setLoading(false);
        }
        else {
            this.setLoading(true);
        }
      this.developmentProfileService
        .getChildTemperamentDetail(this.props.patientId)
        .then((res: HttpResponse<AddChildTemperamentModel>) => {
          this.setLoading(false);
          if (res && res.result) {
            this.props.developmentProfileStepUpdate(1,res.result.id);
            if (isAutoSave) {
              let ct = this.state.childTemperamentModel;
              ct.id = res.result.id;
              ct.patientId = res.result.patientId;
              this.setState({
                childTemperamentModel: ct,
              });
            } else {
              this.setState({
                childTemperamentModel: res.result,
              });
            }
          }
        })
        .catch((ex) => {
          this.setLoading(false);
        });
    }
  };
  assignModel = (data: any) => {
    this.setState(
      {
        childTemperamentModel: data.childTemperament,
        reRenderForm: true,
      },
      () => this.setState({ reRenderForm: false })
    );
  };

  render() {
    return (
      <Fragment>
        {!this.state.reRenderForm && (
          <AddChildTemperamentForm
            onSubmit={this.postData}
            isSaving={this.state.isLoading}
            patientId={this.props.patientId}
            assignModel={this.assignModel}
            childTemperamentModel={this.state.childTemperamentModel}
            parentTabChange={this.parentTabChange}
            isBirthHistoryOptional={this.props.isBirthHistoryOptional}
          />
        )}
      </Fragment>
    );
  }
}
