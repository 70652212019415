import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "../../styles.css";
import Heading from "../../../heading";
import { MaritalStatus } from "../../../../shared/enums/marital-status.enum";
import { Button } from "antd";
import { AddPerenatalPeriodModel } from "../../../../models/birthHistory/perenatalPeriod/add-perenatal-period";
import { Loader } from "../../../../shared/loaders";

interface IProps {
  perenatalPeriodModel: AddPerenatalPeriodModel;
  onSubmit: Function;
  isSaving: boolean;
  patientId: string;
  assignModel: Function;
  isReadOnly: boolean;
  previousTabChange: Function;
  isBirthHistoryOptional: boolean;
}
export function AddPerenatalForm(props: IProps) {
  const { register, handleSubmit, control, getValues, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
  });
  let perenatalPeriodModel = props.perenatalPeriodModel;
  const formSubmit = (data) => {
    perenatalPeriodModel.patientId = props.patientId;
    perenatalPeriodModel.specifyComplications = data.specifyComplications;
    perenatalPeriodModel.specifyStress = data.specifyStress;
    perenatalPeriodModel.specifyTookOtherMedicine =
      data.specifyTookOtherMedicine;
    perenatalPeriodModel.comments = data.comments;
    props.assignModel({ perenatalPeriodModel: perenatalPeriodModel });
    if (checkForError()) {
      return false;
    }
    props.onSubmit({
      perenatalPeriodModel: perenatalPeriodModel,
      isAutoSave: false,
    });
  };
  const checkForError = () => {
    let hasError = false;
    perenatalPeriodModel.smokedError = "";
    perenatalPeriodModel.consumedAlcoholError = "";
    perenatalPeriodModel.illegalDrugsError = "";
    perenatalPeriodModel.tookOtherMedicineError = "";
    perenatalPeriodModel.specifyTookOtherMedicineError = "";
    perenatalPeriodModel.complicationsError = "";
    perenatalPeriodModel.specifyComplicationsError = "";
    perenatalPeriodModel.stressError = "";
    perenatalPeriodModel.specifyStressError = "";
    perenatalPeriodModel.smokedFrequencyError = "";
    perenatalPeriodModel.consumedAlcoholFrequencyError = "";
    perenatalPeriodModel.illegalDrugsFrequencyError = "";

    if (perenatalPeriodModel.smoked == undefined && !props.isBirthHistoryOptional) {
      perenatalPeriodModel.smokedError = "Please select an option.";
      hasError = true;
    } else if (
      perenatalPeriodModel.smoked == true &&
      (perenatalPeriodModel.smokedFrequency == "" ||
        perenatalPeriodModel.smokedFrequency == undefined)
    ) {
      perenatalPeriodModel.smokedFrequencyError = "Please select an option.";
      hasError = true;
    }
    if (perenatalPeriodModel.consumedAlcohol == undefined && !props.isBirthHistoryOptional) {
      perenatalPeriodModel.consumedAlcoholError = "Please select an option.";
      hasError = true;
    } else if (
      perenatalPeriodModel.consumedAlcohol == true &&
      (perenatalPeriodModel.consumedAlcoholFrequency == "" ||
        perenatalPeriodModel.consumedAlcoholFrequency == undefined)
    ) {
      perenatalPeriodModel.consumedAlcoholFrequencyError =
        "Please select an option.";
      hasError = true;
    }
    if (perenatalPeriodModel.illegalDrugs == undefined && !props.isBirthHistoryOptional) {
      perenatalPeriodModel.illegalDrugsError = "Please select an option.";
      hasError = true;
    } else if (
      perenatalPeriodModel.illegalDrugs == true &&
      (perenatalPeriodModel.illegalDrugsFrequency == "" ||
        perenatalPeriodModel.illegalDrugsFrequency == undefined)
    ) {
      perenatalPeriodModel.illegalDrugsFrequencyError =
        "Please select an option.";
      hasError = true;
    }
    if (perenatalPeriodModel.tookOtherMedicine == undefined && !props.isBirthHistoryOptional) {
      perenatalPeriodModel.tookOtherMedicineError = "Please select an option.";
      hasError = true;
    } else if (
      perenatalPeriodModel.tookOtherMedicine == true &&
      perenatalPeriodModel.specifyTookOtherMedicine == ""
    ) {
      perenatalPeriodModel.specifyTookOtherMedicineError = "Please specify.";
      hasError = true;
    }
    if (perenatalPeriodModel.complications == undefined && !props.isBirthHistoryOptional) {
      perenatalPeriodModel.complicationsError = "Please select an option.";
      hasError = true;
    } else if (
      perenatalPeriodModel.complications == true &&
      perenatalPeriodModel.specifyComplications == ""
    ) {
      perenatalPeriodModel.specifyComplicationsError = "Please specify.";
      hasError = true;
    }
    if (perenatalPeriodModel.stress == undefined && !props.isBirthHistoryOptional) {
      perenatalPeriodModel.stressError = "Please select an option.";
      hasError = true;
    } else if (
      perenatalPeriodModel.stress == true &&
      perenatalPeriodModel.specifyStress == ""
    ) {
      perenatalPeriodModel.specifyStressError = "Please specify.";
      hasError = true;
    }
    props.assignModel({ perenatalPeriodModel: perenatalPeriodModel });
    return hasError;
  };
  const handleKeyUp = (event) => {
    setValuesInModel();
    if (event.key === "Tab") {
      saveDataOnTabChange();
    }
  };
  const handleOnFocus = (event) => {
    setValuesInModel();
    saveDataOnTabChange();
  };
  const saveDataOnTabChange = () => {
    if (!props.isSaving) {
      perenatalPeriodModel.patientId = props.patientId;
      props.onSubmit({
        perenatalPeriodModel: perenatalPeriodModel,
        isAutoSave: true,
      });
    }
  };

  function setValuesInModel() {
    let model = getValues();
    perenatalPeriodModel = Object.assign(perenatalPeriodModel, model);
  }

  const SetFrequency = (type, event, text) => {
    setValuesInModel();
    if (type == 1 && event.target.checked && text == "Occasional") {
      perenatalPeriodModel.smokedFrequency = "Occasional";
      perenatalPeriodModel.smokedFrequencyError = "";
    } else if (type == 1 && event.target.checked && text == "Frequent") {
      perenatalPeriodModel.smokedFrequency = "Frequent";
      perenatalPeriodModel.smokedFrequencyError = "";
    }
    if (type == 2 && event.target.checked && text == "Occasional") {
      perenatalPeriodModel.consumedAlcoholFrequency = "Occasional";
      perenatalPeriodModel.consumedAlcoholFrequencyError = "";
    } else if (type == 2 && event.target.checked && text == "Frequent") {
      perenatalPeriodModel.consumedAlcoholFrequency = "Frequent";
      perenatalPeriodModel.consumedAlcoholFrequencyError = "";
    }
    if (type == 3 && event.target.checked && text == "Occasional") {
      perenatalPeriodModel.illegalDrugsFrequency = "Occasional";
      perenatalPeriodModel.illegalDrugsFrequencyError = "";
    } else if (type == 3 && event.target.checked && text == "Frequent") {
      perenatalPeriodModel.illegalDrugsFrequency = "Frequent";
      perenatalPeriodModel.illegalDrugsFrequencyError = "";
    }
    props.assignModel({ perenatalPeriodModel: perenatalPeriodModel });
    saveDataOnTabChange();
  };
  const YesNoSelection = (type, isChecked) => {
    setValuesInModel();
    switch (type) {
      case 1: {
        perenatalPeriodModel.smokedError = "";
        if (isChecked) {
          perenatalPeriodModel.smoked = isChecked;
        } else if (!isChecked) {
          perenatalPeriodModel.smoked = isChecked;
          perenatalPeriodModel.smokedFrequency = "";
          perenatalPeriodModel.smokedFrequencyError = "";
        }
        break;
      }
      case 2: {
        perenatalPeriodModel.consumedAlcoholError = "";
        if (isChecked) {
          perenatalPeriodModel.consumedAlcohol = isChecked;
        } else if (!isChecked) {
          perenatalPeriodModel.consumedAlcohol = isChecked;
          perenatalPeriodModel.consumedAlcoholFrequency = "";
          perenatalPeriodModel.consumedAlcoholFrequencyError = "";
        }
        break;
      }
      case 3: {
        perenatalPeriodModel.illegalDrugsError = "";
        if (isChecked) {
          perenatalPeriodModel.illegalDrugs = isChecked;
        } else if (!isChecked) {
          perenatalPeriodModel.illegalDrugs = isChecked;
          perenatalPeriodModel.illegalDrugsFrequency = "";
          perenatalPeriodModel.illegalDrugsFrequencyError = "";
        }
        break;
      }
      case 4: {
        perenatalPeriodModel.tookOtherMedicineError = "";
        if (isChecked) {
          perenatalPeriodModel.tookOtherMedicine = isChecked;
        } else if (!isChecked) {
          perenatalPeriodModel.tookOtherMedicine = isChecked;
          perenatalPeriodModel.specifyTookOtherMedicine = "";
          perenatalPeriodModel.specifyTookOtherMedicineError = "";
        }
        break;
      }
      case 5: {
        perenatalPeriodModel.complicationsError = "";
        if (isChecked) {
          perenatalPeriodModel.complications = isChecked;
        } else if (!isChecked) {
          perenatalPeriodModel.complications = isChecked;
          perenatalPeriodModel.specifyComplications = "";
          perenatalPeriodModel.specifyComplicationsError = "";
        }
        break;
      }
      case 6: {
        perenatalPeriodModel.stressError = "";
        if (isChecked) {
          perenatalPeriodModel.stress = isChecked;
        } else if (!isChecked) {
          perenatalPeriodModel.stress = isChecked;
          perenatalPeriodModel.specifyStress = "";
          perenatalPeriodModel.specifyStressError = "";
        }
        break;
      }
    }
    props.assignModel({ perenatalPeriodModel: perenatalPeriodModel });
    saveDataOnTabChange();
  };
  return (
    <div>
      <form onSubmit={handleSubmit(formSubmit)}>
        <div>
          <Heading heading="Prenatal Period" subHeading="" styleName="" />
          <div className="form">
            <div className="row mt-3">
              <div className="medical-group btn-select col-12 col-md-6">
                <label className="text-start">Smoked during pregnancy</label>

                <div className="row">
                  <div className="col">
                    <div className="btn-group btnAdopted w-100">
                      <button
                        type="button"
                        onClick={() => YesNoSelection(1, true)}
                        className={
                          perenatalPeriodModel.smoked == true
                            ? "btn btn-primary btn-sm btnAdopted_yes"
                            : "btn btn-outline-secondary btn-sm btnAdopted_others"
                        }
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        onClick={() => YesNoSelection(1, false)}
                        className={
                          perenatalPeriodModel.smoked == false
                            ? "btn btn-primary btn-sm btnAdopted_yes"
                            : "btn btn-outline-secondary btn-sm btnAdopted_others"
                        }
                      >
                        No
                      </button>
                    </div>
                    {perenatalPeriodModel.smokedError && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block", textAlign: "left" }}
                      >
                        {perenatalPeriodModel.smokedError}
                      </div>
                    )}
                  </div>

                  <div className="col text-center">
                    <div className="pretty p-default p-curve p-thick">
                      <input
                        type="radio"
                        disabled={!perenatalPeriodModel.smoked}
                        checked={
                          "Occasional" == perenatalPeriodModel.smokedFrequency
                        }
                        name="smoke"
                        defaultValue="Occasional"
                        onChange={(e) => SetFrequency(1, e, "Occasional")}
                      />
                      <div className="state p-primary-o">
                        <label>Occasional</label>
                      </div>
                      {perenatalPeriodModel.smokedFrequencyError && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block", textAlign: "left" }}
                        >
                          {perenatalPeriodModel.smokedFrequencyError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col text-center">
                    <div className="pretty p-default p-curve p-thick">
                      <input
                        type="radio"
                        disabled={!perenatalPeriodModel.smoked}
                        checked={
                          "Frequent" == perenatalPeriodModel.smokedFrequency
                        }
                        defaultValue="Frequent"
                        name="smoke"
                        onChange={(e) => SetFrequency(1, e, "Frequent")}
                      />
                      <div className="state p-primary-o">
                        <label>Frequent</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            <div className="row mt-3">
              <div className="medical-group btn-select col-12 col-md-6">
                <label className="text-start">
                  Consumed alcohol during pregnancy
                </label>
                <div className="row">
                  <div className="col">
                    <div className="btn-group btnAdopted w-100">
                      <button
                        type="button"
                        onClick={() => YesNoSelection(2, true)}
                        className={
                          perenatalPeriodModel.consumedAlcohol == true
                            ? "btn btn-primary btn-sm btnAdopted_yes"
                            : "btn btn-outline-secondary btn-sm btnAdopted_others"
                        }
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        onClick={() => YesNoSelection(2, false)}
                        className={
                          perenatalPeriodModel.consumedAlcohol == false
                            ? "btn btn-primary btn-sm btnAdopted_yes"
                            : "btn btn-outline-secondary btn-sm btnAdopted_others"
                        }
                      >
                        No
                      </button>
                    </div>
                    {perenatalPeriodModel.consumedAlcoholError && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block", textAlign: "left" }}
                      >
                        {perenatalPeriodModel.consumedAlcoholError}
                      </div>
                    )}
                  </div>

                  <div className="col text-center">
                    <div className="pretty p-default p-curve p-thick">
                      <input
                        type="radio"
                        disabled={!perenatalPeriodModel.consumedAlcohol}
                        value="Occasional"
                        name="alcohol"
                        checked={
                          "Occasional" ==
                          perenatalPeriodModel.consumedAlcoholFrequency
                        }
                        defaultValue="Occasional"
                        onChange={(e) => SetFrequency(2, e, "Occasional")}
                      />
                      <div className="state p-primary-o">
                        <label>Occasional</label>
                      </div>
                      {perenatalPeriodModel.consumedAlcoholFrequencyError && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block", textAlign: "left" }}
                        >
                          {perenatalPeriodModel.consumedAlcoholFrequencyError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col text-center">
                    <div className="pretty p-default p-curve p-thick">
                      <input
                        type="radio"
                        disabled={!perenatalPeriodModel.consumedAlcohol}
                        value="Frequent"
                        name="alcohol"
                        checked={
                          "Frequent" ==
                          perenatalPeriodModel.consumedAlcoholFrequency
                        }
                        defaultValue="Frequent"
                        onChange={(e) => SetFrequency(2, e, "Frequent")}
                      />
                      <div className="state p-primary-o">
                        <label>Frequent</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            <div className="row mt-3">
              <div className="medical-group btn-select col-12 col-md-6">
                <label className="text-start">
                  Used unprescribed or illegal drugs during pregnancy
                </label>
                <div className="row">
                  <div className="col">
                    <div className="btn-group btnAdopted w-100">
                      <button
                        type="button"
                        onClick={() => YesNoSelection(3, true)}
                        className={
                          perenatalPeriodModel.illegalDrugs == true
                            ? "btn btn-primary btn-sm btnAdopted_yes"
                            : "btn btn-outline-secondary btn-sm btnAdopted_others"
                        }
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        onClick={() => YesNoSelection(3, false)}
                        className={
                          perenatalPeriodModel.illegalDrugs == false
                            ? "btn btn-primary btn-sm btnAdopted_yes"
                            : "btn btn-outline-secondary btn-sm btnAdopted_others"
                        }
                      >
                        No
                      </button>
                    </div>
                    {perenatalPeriodModel.illegalDrugsError && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block", textAlign: "left" }}
                      >
                        {perenatalPeriodModel.illegalDrugsError}
                      </div>
                    )}
                  </div>

                  <div className="col text-center">
                    <div className="pretty p-default p-curve p-thick">
                      <input
                        type="radio"
                        disabled={!perenatalPeriodModel.illegalDrugs}
                        value="Occasional"
                        name="drugs"
                        checked={
                          "Occasional" ==
                          perenatalPeriodModel.illegalDrugsFrequency
                        }
                        defaultValue="Occasional"
                        onChange={(e) => SetFrequency(3, e, "Occasional")}
                      />
                      <div className="state p-primary-o">
                        <label>Occasional</label>
                      </div>
                      {perenatalPeriodModel.illegalDrugsFrequencyError && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block", textAlign: "left" }}
                        >
                          {perenatalPeriodModel.illegalDrugsFrequencyError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col text-center">
                    <div className="pretty p-default p-curve p-thick">
                      <input
                        type="radio"
                        disabled={!perenatalPeriodModel.illegalDrugs}
                        value="Frequent"
                        name="drugs"
                        checked={
                          "Frequent" ==
                          perenatalPeriodModel.illegalDrugsFrequency
                        }
                        defaultValue="Frequent"
                        onChange={(e) => SetFrequency(3, e, "Frequent")}
                      />
                      <div className="state p-primary-o">
                        <label>Frequent</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            <div className="row mt-3">
              <div className="medical-group btn-select col-12 col-md-6">
                <label className="text-start">Took other medications</label>
                <div className="row">
                  <div className="col-4">
                    <div className="btn-group btnAdopted w-100">
                      <button
                        type="button"
                        onClick={() => YesNoSelection(4, true)}
                        className={
                          perenatalPeriodModel.tookOtherMedicine == true
                            ? "btn btn-primary btn-sm btnAdopted_yes"
                            : "btn btn-outline-secondary btn-sm btnAdopted_others"
                        }
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        onClick={() => YesNoSelection(4, false)}
                        className={
                          perenatalPeriodModel.tookOtherMedicine == false
                            ? "btn btn-primary btn-sm btnAdopted_yes"
                            : "btn btn-outline-secondary btn-sm btnAdopted_others"
                        }
                      >
                        No
                      </button>
                    </div>
                    {perenatalPeriodModel.tookOtherMedicineError && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {perenatalPeriodModel.tookOtherMedicineError}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="medical-group col-12 col-md-6 mt-md-3">
                <label className="form-label">
                  Specify type and reason for taking medicine
                </label>
                <input
                  type="text"
                  className="form-control mb-1"
                  defaultValue={perenatalPeriodModel.specifyTookOtherMedicine}
                  disabled={!perenatalPeriodModel.tookOtherMedicine}
                  placeholder="Add something"
                  {...register("specifyTookOtherMedicine")}
                  onFocus={handleOnFocus}
                  //  onChange={(e) => setSpecifyMedicatn(e.target.defaultValue)}
                />
                {perenatalPeriodModel.specifyTookOtherMedicineError && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block", textAlign: "left" }}
                  >
                    {perenatalPeriodModel.specifyTookOtherMedicineError}
                  </div>
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="medical-group btn-select col-12 col-md-6">
                <label className="text-start">
                  Complications / medical concerns during pregnancy
                </label>
                <div className="row">
                  <div className="col-4">
                    <div className="btn-group btnAdopted w-100">
                      <button
                        type="button"
                        onClick={() => YesNoSelection(5, true)}
                        className={
                          perenatalPeriodModel.complications == true
                            ? "btn btn-primary btn-sm btnAdopted_yes"
                            : "btn btn-outline-secondary btn-sm btnAdopted_others"
                        }
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        onClick={() => YesNoSelection(5, false)}
                        className={
                          perenatalPeriodModel.complications == false
                            ? "btn btn-primary btn-sm btnAdopted_yes"
                            : "btn btn-outline-secondary btn-sm btnAdopted_others"
                        }
                      >
                        No
                      </button>
                    </div>
                    {perenatalPeriodModel.complicationsError && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {perenatalPeriodModel.complicationsError}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="medical-group col-12 col-md-6 mt-md-3">
                <label className="form-label">Specify</label>
                <input
                  type="text"
                  className="form-control mb-1"
                  defaultValue={perenatalPeriodModel.specifyComplications}
                  placeholder="Add something"
                  disabled={!perenatalPeriodModel.complications}
                  {...register("specifyComplications")}
                  onFocus={handleOnFocus}
                />
                {perenatalPeriodModel.specifyComplicationsError && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block", textAlign: "left" }}
                  >
                    {perenatalPeriodModel.specifyComplicationsError}
                  </div>
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="medical-group btn-select col-12 col-md-6">
                <label className="test-start">
                  Presence of additional stress during pregnancy
                </label>
                <div className="row">
                  <div className="col-4">
                    <div className="btn-group btnAdopted w-100">
                      <button
                        type="button"
                        onClick={() => YesNoSelection(6, true)}
                        className={
                          perenatalPeriodModel.stress == true
                            ? "btn btn-primary btn-sm btnAdopted_yes"
                            : "btn btn-outline-secondary btn-sm btnAdopted_others"
                        }
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        onClick={() => YesNoSelection(6, false)}
                        className={
                          perenatalPeriodModel.stress == false
                            ? "btn btn-primary btn-sm btnAdopted_yes"
                            : "btn btn-outline-secondary btn-sm btnAdopted_others"
                        }
                      >
                        No
                      </button>
                    </div>
                    {perenatalPeriodModel.stressError && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {perenatalPeriodModel.stressError}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="medical-group col-12 col-md-6 mt-md-3">
                <label className="form-label">Specify</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={perenatalPeriodModel.specifyStress}
                  disabled={!perenatalPeriodModel.stress}
                  placeholder="Add something"
                  {...register("specifyStress")}
                  onFocus={handleOnFocus}
                />
                {perenatalPeriodModel.specifyStressError && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block", textAlign: "left" }}
                  >
                    {perenatalPeriodModel.specifyStressError}
                  </div>
                )}
              </div>
            </div>

            <div className="medical__birth_formRow5">
              <div className="medical-group optional-field textarea_group">
                <label className="form-label">Comments</label>
                <span className="optional">Optional</span>
                <textarea
                  className="form-control textarea"
                  defaultValue={perenatalPeriodModel.comments}
                  {...register("comments")}
                  onFocus={handleOnFocus}
                  placeholder="Please provide any additional information."
                  //  onChange={(e) => setComment(e.target.defaultValue)}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="btnContainer">
            {!props.isSaving && (
              <Button
                type="default"
                size="large"
                className="back_btn"
                onClick={() => props.previousTabChange(2)}
              >
                <i className="fa fa-long-arrow-left"></i> Back
              </Button>
            )}
            {!props.isSaving && (
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="btn_add"
              >
                Save <i className="fa fa-long-arrow-right"></i>
              </Button>
            )}
            {props.isSaving && (
              <div style={{ height: "60px" }}>
                <Loader
                  loading={props.isSaving}
                  marginBottom="0px"
                  marginTop="8px"
                  width="368px"
                ></Loader>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
