import React, { Component, Fragment } from "react";
import Tabs from "../../components/tabs";
import ContentCard from "../../components/contentCard";
import ChildGuardianInfo from "../../components/childGuardianInfo";
import MedicationStatus from "../../components/medicationStatus";
import PageTitleSetter from "../../shared/pageTitleSetter";
import { Link } from "react-router-dom";
import TeacherWeek1 from "./teacherWeek";
import Heading from "../../components/heading";
import Search from "../../components/search";
// import InfiniteScroll from "react-infinite-scroll-component";
import LeftListing from "../../components/leftListing";
import { Loader } from "../../shared/loaders";
import "./styles.css";
import { TeacherWeeklyDataModel } from "../../models/teacherWeeklyData/teacher-weekly-data.model";
import { TeacherWeeklyDataService } from "../../services/teacherWeeklyData/teacher-weekly-data.service";
import { HttpResponse } from "../../core";
import moment from "moment";
import TeacherWeek from "./teacherWeek";
import { TeacherListFilterModel } from "../../models/teacher/teacher-list-filter.model";
import { TeacherListResponseModel } from "../../models/teacher/teacher-list-response.model";
import { TeacherListModel } from "../../models/teacher/teacher-list.model.model";
import { GetUserInfo } from "../../shared/helpers";
import InfiniteScroll from "react-infinite-scroll-component";
import TeacherDetailHeader from "./teacherDetailHeader";
import oops from "../../assets/images/svg-icons/no-result-found.svg";
import { MedicationStatusModel } from "../../models/medication/medication-status.model";
import BreadcrumSetter from "../../shared/breadcrumSetter";
import imgAddUser from "../../assets/images/svg-icons/add-user.svg";
import TeacherWeekMobile from "./teacherWeekMobile";
import dayjs from "dayjs";
import withRouter from "../../shared/withRouter/withRouter";
import { Nav } from "react-bootstrap";
interface IState {
  formVisible: boolean;
  patientId: string;
  isLoading: boolean;
  teacherWeeklyData: TeacherWeeklyDataModel;
  isFormSave?: Function;
  reRenderForm: boolean;
  isFormVisible: boolean;
  isPageLoading: boolean;
  autoSave: boolean;
  isCurrentWeek: boolean;
  result: TeacherListResponseModel | any;
  results: TeacherListModel[];
  initials?: string;
  searchText: string;
  totalItems: number;
  scrolling?: boolean;
  pageSize: number;
  currentPage: number;
  pageCount: number;
  bcColor: string;
  isListingTypeTeacher: boolean;
  isBaseline: boolean;
  trialStatus: number;
  selectedPatientName: string;
  trailStartDate: Date | null;
  isMobileView: boolean;
  currentWeekNo: number;
  tab: number;
  dayNum: number;

}
class TeacherWeeklyUpdate extends Component<any, IState> {
  private teacherWeeklyDataService: TeacherWeeklyDataService;

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.teacherWeeklyDataService = new TeacherWeeklyDataService();
    this.changeTab = this.changeTab.bind(this);
    this.changeDayNum = this.changeDayNum.bind(this);
  }
  changeTab(tab: number) {
    console.log("Tab Clicked changeTab from week: " + this.state.tab + " to : " + tab)
    if((this.state.currentWeekNo >= tab) == true){
      this.setState({
      tab,
    });
    }
  }
  changeDayNum(dayNum: number) {
    console.log("Day Clicked changeDayNum from week: " + this.state.dayNum + " to : " + dayNum)
    this.setState({
      dayNum,
    });
    this.loadDataWeekly(false);
  }

  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      isPageLoading: false,
      autoSave: false,
      formVisible: false,
      reRenderForm: false,
      isFormVisible: false,
      isMobileView: false,
      teacherWeeklyData: {
        isBaseline: false,
        trialStatus: 0,
        trialStartDate: null,
        teacherTargetSymptomWeeklyList: [],
        teacherAdditionalQuestionList: [],
      },
      isCurrentWeek: false,
      result: {
        collection: [],
        paging: {
          currentPage: 1,
          pageSize: 0,
          totalItems: 0,
          pageCount: 0,
        },
      },
      searchText: "",
      initials: "",
      scrolling: false,
      results: [],
      pageSize: 10,
      currentPage: 1,
      totalItems: 0,
      pageCount: 0,
      bcColor: "",
      patientId: this.props.params.id,
      isListingTypeTeacher: true,
      isBaseline: false,
      trialStatus: 0,
      trailStartDate: null,
      selectedPatientName: "",
      currentWeekNo: 0,
      tab: 0,
      dayNum: 0,
    };
    return initialState;
  }
  private setMobileView(mobileView: boolean) {
    this.setState({
      isMobileView: mobileView,
    });
  }
  componentDidMount() {
    //console.log("ComponentDidMount, MobileView: " + this.state.isMobileView);
    let windowSize = window.screen.width;
    let pageHeight = document.documentElement.scrollHeight;
    if (windowSize <= 1024) {
      //$(".scrollBar").height(pageHeight - 200);
      //console.log("window size restraint hit");
      this.setMobileView(true);
      //console.log("MobileView: " + this.state.isMobileView);
    }
    this.loadTeacherListData();
    if (this.state.patientId != null) {
      this.loadDataWeekly();
    }
  }
  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }
  loadTeacherListData = () => {
    if (!this.state.scrolling) {
      this.setLoading(true);
    }
    var user = GetUserInfo();
    let filterModel: TeacherListFilterModel = {
      searchText: this.state.searchText,
      pageSize: this.state.pageSize,
      currentPage: this.state.currentPage,
      totalItems: this.state.totalItems,
      teacherId: user.userId,
    };

    this.teacherWeeklyDataService
      .getPatientListByTeacher(filterModel)
      .then((res: HttpResponse<TeacherListResponseModel>) => {
        if (!this.state.scrolling) {
          this.setLoading(false);
        }

        if (res && res.result) {
          let patientList = this.state.scrolling
            ? this.state.result?.collection || []
            : [];
          patientList = patientList.concat(res.result.collection);
          let result = res.result;
          result.collection = patientList;
          if (this.state.scrolling) {
            this.setState({
              result: result,
              scrolling: false,
            });
            if (this.props.params.id) {
              result.collection.filter((x, i) => {
                if (x.id == this.props.params.id) {
                  this.setState({
                    bcColor: i % 2 == 0 ? "#d3dee8" : "#dedab7",
                  });
                }
              });
            }
          } else {
            if (
              (this.state.patientId == "" ||
                this.state.patientId == undefined) &&
              result.collection.length > 0
            ) {
              this.setState(
                {
                  patientId: result.collection[0].id,
                },
                () => {
                  if (this.state.patientId != null) {
                    this.loadDataWeekly();
                  }
                  this.props.navigate(
                    `/medical/teacher-weekly-severity-update/${result.collection[0].id}`
                  );
                }
              );
            }
            this.setState(
              {
                result: result,
                scrolling: false,
                patientId:
                  (this.state.patientId == undefined ||
                    this.state.patientId == "") &&
                  result.collection.length > 0
                    ? res.result.collection[0].id
                    : this.state.patientId,

                reRenderForm: true,
              },
              () => this.setState({ reRenderForm: false })
            );
            if (this.props.params.id) {
              res.result.collection.filter((x, i) => {
                if (x.id == this.props.params.id) {
                  this.setState(
                    {
                      reRenderForm: true,
                      bcColor: i % 2 == 0 ? "#d3dee8" : "#dedab7",
                    },
                    () => this.setState({ reRenderForm: false })
                  );
                }
              });
            }
          }
        }
      })
      .catch(() => {
        this.setLoading(false);
      });
  };
  selectedPatientName = (data) => {
    this.setState({
      selectedPatientName: data,
    });
  };
  postTargetSymptomsData = (data: any) => {
    let targetSymptomsData = data;
    this.setState({ autoSave: true });

    if (this.state.isLoading) {
      return;
    }
    this.teacherWeeklyDataService
      .postTeacherTargetSymptomsData(targetSymptomsData)
      .then((res: HttpResponse<boolean>) => {
        if (res && res.result) {
        }
        this.loadDataWeekly(false);
      })
      .catch((ex) => {});
  };

  postTeacherAdditionalQuestionWeeklyData = (data: any) => {
    let additionalQuestion = data;
    this.setState({ autoSave: true });

    this.teacherWeeklyDataService
      .postTeacherAdditionalQuestionWeeklyData(additionalQuestion)
      .then((res: HttpResponse<boolean>) => {
        if (res && res.result) {
        }
        this.loadDataWeekly(false);
      })
      .catch((ex) => {});
  };

  handleSearchChange = (event: any) => {
    this.setState(
      {
        currentPage: 1,
        scrolling: false,
        searchText: event.target.value,
      },
      () => {
        this.loadTeacherListData();
      }
    );
  };
  loadMore = () => {
    this.setState(
      (prevState) => ({
        currentPage: prevState.currentPage + 1,
        scrolling: true,
      }),
      () => {
        this.loadTeacherListData();
      }
    );
  };
  patientChange = (id, index?: any) => {
    this.setState(
      {
        patientId: id,
        reRenderForm: true,
        bcColor: index == undefined || index % 2 == 0 ? "#dedab7" : "#d3dee8",
      },
      () => {
        this.loadDataWeekly();
        this.setState({ reRenderForm: false });
      }
    );
  };
  loadDataWeekly = (isReRenderForm?: any) => {
    if (this.state.patientId != null) {
      // if (!this.state.autoSave) {
      //   this.setState({ isPageLoading: true });
      // }

      this.teacherWeeklyDataService
        .getTeacherWeekDayData(this.state.patientId)
        .then((res: HttpResponse<TeacherWeeklyDataModel>) => {
          // if (!this.state.autoSave) {
          //   this.setState({ isPageLoading: false });
          // }
          this.setState({ autoSave: false });
          if (res && res.result) {
            this.setState({ trailStartDate: res.result.trialStartDate });
          }
          if (
            res &&
            res.result &&
            res.result.teacherTargetSymptomWeeklyList != null &&
            res.result.isBaseline
          ) {
            this.setState({
              teacherWeeklyData: {
                ...this.state.teacherWeeklyData,
                teacherTargetSymptomWeeklyList:
                  res.result.teacherTargetSymptomWeeklyList,
                teacherAdditionalQuestionList:
                  res.result.teacherAdditionalQuestionList,
              },
            })
            if (isReRenderForm == false) {
              this.setState({
                
                isBaseline: res.result.isBaseline,
                trialStatus: res.result.trialStatus,
                trailStartDate: res.result.trialStartDate,
                // currentUserName:res.result.fullName
              });
            } else {
              res.result.teacherTargetSymptomWeeklyList.forEach(item => {
                if (item.isCurrentWeek){
                  this.setState({
                    currentWeekNo : item.weekNo,
                    tab : item.weekNo,
                  });
                }
              })
              this.setState(
                {
                  
                  isBaseline: res.result.isBaseline,
                  trialStatus: res.result.trialStatus,
                  trailStartDate: res.result.trialStartDate,
                  reRenderForm: true,
                },
                () => this.setState({ reRenderForm: false })
              );
            }
          } else {
            this.setState(
              {
                isBaseline: false,
                trialStatus: 0,
                reRenderForm: true,
              },
              () => this.setState({ reRenderForm: false })
            );
          }
        })
        .catch((ex) => {});
    }
  };

  assignWeeklyDataModel = (data: any) => {
    if (data != null && data.teacherWeeklyData != null) {
      this.postTargetSymptomsData({
        teacherTargetSymptomWeeklyList: data.teacherWeeklyData,
      });
    }
  };

  assignWeeklyQuestionModel = (data: any) => {
    if (data != null && data.teacherAdditionalQuestionList != null) {
      this.postTeacherAdditionalQuestionWeeklyData({
        teacherAdditionalQuestionList: data.teacherAdditionalQuestionList,
      });
    }
  };

  render() {
    return (
      <Fragment>
        <PageTitleSetter title="Child Weekly Status" />
        {/* <div>
        <BreadcrumSetter crumIcon={imgAddUser} crumType="static" crumItem1="Teacher" 
        crumItem2={this.state.selectedPatientName}   crumItem3=""/>
        </div> */}
        <div className="medicalTeacher__data">
          <div className="medication__form teacher__list">
            <div className="list__heading__search">
              <Heading
                heading="Patients"
                subHeading=""
                styleName="medicalData__style"
              />
            </div>
            <div className="list__heading__search">
              <Search
                placeholder="Search by Name"
                type="text"
                onChange={this.handleSearchChange}
                value={this.state.searchText}
                variants="search__list"
                name="searchText"
                searchIcon=""
              />
            </div>
            <section className="list-container">
              <div id="targetDiv" className="scrollBar">
                <InfiniteScroll
                  dataLength={this.state.result.collection.length}
                  next={this.loadMore}
                  hasMore={true}
                  loader={false}
                  scrollableTarget="targetDiv"
                  className="teacher__list__scroll"
                >
                  <ul className="user-list" style={{ marginTop: 5 }}>
                    {!this.state.isLoading &&
                      this.state.result.paging.totalItems > 0 &&
                      this.state.result.collection.map(
                        (item: TeacherListModel, i) => {
                          return (
                            <LeftListing
                              index={i}
                              linkStyle="link__style"
                              initials={item.initials}
                              firstName={item.firstName}
                              lastName={item.lastName}
                              age={item.age}
                              dateOfBirth={dayjs(item.dateOfBirth).format(
                                "MM/DD/YYYY"
                              )}
                              isSelected={item.id == this.state.patientId}
                              listIcon=""
                              navUrl={`/medical/teacher-weekly-severity-update/${item.id}`}
                              patientChange={() =>
                                this.patientChange(item.id, i + 1)
                              }
                              isListingTypeTeacher={
                                this.state.isListingTypeTeacher
                              }
                              gender={item.gender}
                              accStatus=""
                              border=""
                            />
                          );
                        }
                      )}
                    {!this.state.isLoading &&
                      this.state.result.paging.totalItems === 0 && (
                        <div className="text-danger text-center">
                          <div className="noRecord__table">
                            <img src={oops} alt="oops" />
                            <h2>No result found</h2>
                            <p>
                              There are no matching records for your search,
                              please try again.
                            </p>
                          </div>
                        </div>
                      )}
                    {this.state.isLoading && (
                      <Loader loading={this.state.isLoading} />
                    )}
                  </ul>
                </InfiniteScroll>
              </div>
            </section>
          </div>
          <div className="medication__table">
            <div className="child__status">
              {!this.state.reRenderForm && (
                <TeacherDetailHeader
                  patientId={this.state.patientId}
                  bcColor={this.state.bcColor}
                  isBaseline={this.state.isBaseline}
                  trialStatus={this.state.trialStatus}
                  selectedPatientName={this.selectedPatientName}
                ></TeacherDetailHeader>
              )}
            </div>
            {!this.state.isBaseline &&
              !this.state.isPageLoading &&
              this.state.trialStatus == 0 && (
                <div className="text-center">
                  <h4 className="weekly__msg">
                    The weekly update section will be visible when the week 1
                    starts. Please connect with the parent for further
                    information.
                  </h4>
                </div>
              )}
            {this.state.trialStatus == 1 && !this.state.isPageLoading && (
              <div className="text-center">
                <h4 className="weekly__msg">
                  The trial of this child is completed. Please reach out to the
                  parent for any further information.
                  <br />
                </h4>
              </div>
            )}
            {this.state.trialStatus == 3 && !this.state.isPageLoading && (
              <div className="text-center">
                <h4 className="weekly__msg">
                  The daily update section will be available from date{" "}
                  {this.state.trailStartDate != null
                    ? dayjs(this.state.trailStartDate).format("MM/DD/YYYY")
                    : ""}
                  . <br />
                </h4>
              </div>
            )}
            {/* Present trial */}
            {this.state.trialStatus == 2 && !this.state.isBaseline && (
              <div className="text-center">
                <h4 className="weekly__msg">
                  The weekly update section will be visible when the week 1
                  starts. Please connect with the parent for further
                  information.
                </h4>
              </div>
            )}
            {<Loader loading={this.state.isPageLoading} />}
            {this.state.isBaseline &&
              this.state.trialStatus == 2 &&
              !this.state.isMobileView &&
              !this.state.isPageLoading && (
                <div className="weekly__severity_wrapper">
                  <Nav className="nav nav-pills nav-fill">
                    {this.state.teacherWeeklyData &&
                      this.state.teacherWeeklyData.teacherTargetSymptomWeeklyList.map(
                        (item, index) => {
                          return (
                            <Fragment>
                              <Tabs
                                styleName={
                                  item.weekType == 3
                                    ? "weeklySev__tab remove-cursor"
                                    : "weeklySev__tab add-cursor"
                                }
                                text={dayjs(item.weekStartDate).format(
                                  "MM/DD/YYYY"
                                )}
                                steps=""
                                stepsAct=""
                                fullText={dayjs(item.weekStartDate).format(
                                  "MM/DD/YYYY"
                                )}
                                familyTextName={"Week" + item.weekNo}
                                image=""
                                activeImage=""
                                tabChange={() =>
                                  this.changeTab(item.weekNo)
                                }
                                selected={item.weekNo == this.state.tab}
                              />
                            </Fragment>
                          );
                        }
                      )}
                  </Nav>
                  <div className="weekly__tableStr">
                    <TeacherWeek
                      patientId={this.state.patientId}
                      teacherWeeklyDataModel={this.state.teacherWeeklyData}
                      assignWeeklyDataModel={this.assignWeeklyDataModel}
                      styleName=""
                      assignWeeklyQuestionModel={this.assignWeeklyQuestionModel}
                      selectedPatientName={this.state.selectedPatientName}
                      weekNo={this.state.tab}
                    />
                  </div>
                </div>
              )}
            {this.state.isBaseline &&
              this.state.trialStatus == 2 &&
              this.state.isMobileView &&
              !this.state.isPageLoading && (
                <div className="weekly__severity_wrapper">
                  <div className="weekly__tableStr">
                    <TeacherWeekMobile
                      patientId={this.state.patientId}
                      teacherWeeklyDataModel={this.state.teacherWeeklyData}
                      assignWeeklyDataModel={this.assignWeeklyDataModel}
                      styleName=""
                      assignWeeklyQuestionModel={this.assignWeeklyQuestionModel}
                      selectedPatientName={this.state.selectedPatientName}
                      weekNo={this.state.tab}
                      dayNum={this.state.dayNum}
                      startDate={dayjs(this.state.trailStartDate)}
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(TeacherWeeklyUpdate);
