import React, { Component, Fragment } from "react";
import "./navigation.scss";
import { Link, useLocation, useNavigate, useParams, NavLink } from "react-router-dom";
import $ from "jquery";
//import { setTimeout } from "timers";
import logo from "../../assets/images/CCFLogoWhite.png";
import { GetUserInfo } from "../../shared/helpers";
import { Role } from "../../shared/enums/role.enum";
import { RoleModel } from "../../models/users";
import { Menu } from "antd";
import "../../App.scss";

interface IState {
    collapsed: boolean;
    roles: RoleModel[];
    list: string[];
}
export class NavigationBar extends Component<any, IState> {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            roles: [] as RoleModel[],
            list: [],
        };
    }
    componentDidMount() {
        let wndowSize = window.screen.width;
        let pageHeight = document.documentElement.scrollHeight;
        if (wndowSize <= 1024) {
            $(".scrollBar").height(pageHeight - 400);
        }

        if (wndowSize <= 1024) {
            $("[data-toggle='sidebar']").addClass("ant-layout-sider-collapsed");
            $("[data-toggle='sidebar']").addClass("ant-layout-sider-collapsed");
        } else {
            $("[data-toggle='sidebar']").removeClass("ant-layout-sider-collapsed");
            $("[data-toggle='sidebar']").removeClass("ant-layout-sider-collapsed");
        }

        //console.log(wndowSize.screen.width)
        setTimeout(() => {
            let winObj = window as any;
            if (winObj.InitSidebar) {
                winObj.InitSidebar();
            }
            if (winObj.toggleSidebar) {
                $("[data-toggle='sidebar']").click(winObj.toggleSidebar);
            }
            $(".menu-toggle").click(winObj.toggleNavBarS);
        }, 1000);
        var userInfo = GetUserInfo();

        this.setState({ list: userInfo.roles });
        //console.log("d", this.state.list);
    }

    setCurrentRoutClass() {
        setTimeout(() => {
            $(".activenav").parents(".dropdown").addClass("expandtoggle").addClass("toggled-s");
            $(".expandtoggle").find("ul:eq(0)").show();
        }, 0);
    }

    render() {
        this.setCurrentRoutClass();
        var userInfo = GetUserInfo();
        const { location } = this.props;
        return (
            <Fragment>
                <div className="main-sidebar sidebar-style-2">
                    <Menu
                        theme="light"
                        selectedKeys={[location.pathname]}
                        // defaultSelectedKeys={["1"]}
                        mode="inline"
                        defaultOpenKeys={["doctors"]}
                        className="sidebar-menu"
                    >
                        <div className="sidebar-brand">
                            <img width="160" alt="logo" src={logo} />
                            {/* <h1 className="m3p__text">M3P</h1>
              <p className="m3p__fullForm">
                Manos
                <br /> Medication <br /> Monitoring Platform
              </p> */}
                        </div>
                        {userInfo.roles.includes(Role.Parent) && (
                            <Menu.Item key="/medical/medicalhistory/" className="dropdown main-menu-item">
                                <Link to="/medical/medicalhistory/" className="nav-link dashboard">
                                    <p className="menu__text">Child Weekly Status</p>
                                </Link>
                            </Menu.Item>
                        )}
                        {userInfo.roles.includes(Role.Parent) && (
                            <Menu.Item key="/medical/addRespondent/" className="dropdown main-menu-item">
                                <Link to="/medical/addRespondent/" className="nav-link management-add">
                                    <p className="menu__text">Add Respondent</p>
                                </Link>
                            </Menu.Item>
                        )}
                        {(userInfo.roles.includes(Role.Admin) || userInfo.roles.includes(Role.SuperAdmin) || userInfo.roles.includes(Role.Clinician)) && (
                            <Menu.Item key="/" className="dropdown main-menu-item">
                                <Link to="/" className="nav-link dashboard">
                                    <p className="menu__text">Home</p>
                                </Link>
                            </Menu.Item>
                        )}
                        {(userInfo.roles.includes(Role.Admin) || userInfo.roles.includes(Role.SuperAdmin) || userInfo.roles.includes(Role.Clinician)) && (
                            <Menu.Item key="/clinician/patientmanagement" className="dropdown main-menu-item">
                                <NavLink to="/clinician/patientmanagement" className="nav-link management">
                                    <p className="menu__text">Patient Management</p>
                                </NavLink>
                            </Menu.Item>
                        )}
                        {(userInfo.roles.includes(Role.Admin) || userInfo.roles.includes(Role.SuperAdmin) || userInfo.roles.includes(Role.Clinician)) && (
                            <Menu.Item key="/clinician/patientdashboard/" className="dropdown main-menu-item">
                                <NavLink to="/clinician/patientdashboard/" className="nav-link management-dashboard">
                                    <p className="menu__text">Weekly Update</p>
                                </NavLink>
                            </Menu.Item>
                        )}
                        {(userInfo.roles.includes(Role.Admin) || userInfo.roles.includes(Role.SuperAdmin) || userInfo.roles.includes(Role.Clinician)) && (
                            <Menu.Item key="/patient/addPatient/" className="dropdown main-menu-item">
                                <NavLink to="/patient/addPatient/" className="nav-link management-add">
                                    <p className="menu__text">Patient Details</p>
                                </NavLink>
                            </Menu.Item>
                        )}




                        {(userInfo.roles.includes(Role.Admin) || userInfo.roles.includes(Role.SuperAdmin)) && (
                            <Menu.Item key="/user/adduser/" className="dropdown main-menu-item">
                                <NavLink to="/user/adduser/" className="nav-link add-patient">
                                    <p className="menu__text">User Management</p>
                                </NavLink>
                            </Menu.Item>
                        )}
                        {(userInfo.roles.includes(Role.Admin) || userInfo.roles.includes(Role.SuperAdmin)) && (
                            <Menu.Item key="/medication/setting" className="dropdown main-menu-item">
                                <NavLink to="/medication/setting" className="nav-link medication-setting">
                                    <p className="menu__text">Medication Settings</p>
                                </NavLink>
                            </Menu.Item>
                        )}
                    </Menu>
                </div>
            </Fragment>
        );
    }
}
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      return (
        <Component
          {...props}
          {...{ location, navigate, params }}
        />
      );
    }
  
    return ComponentWithRouterProp;
  }
export default withRouter(NavigationBar);
