import React from "react";
import "./styles.css";

export interface MedicationProps {
  text: string;
  color: string;
  backgroundColor: string;
  statusStyle: string;
  index?: number;
}
const MedicationStatus: React.FC<MedicationProps> = ({
  text,
  color,
  backgroundColor,
  statusStyle,
  index,
}) => {
  return (
    <span
      key={"MedicationStatus" + index}
      style={{ backgroundColor: backgroundColor, color: color }}
      className={`status__badge ${statusStyle}`}
    >
      {text}
    </span>
  );
};
export default MedicationStatus;
