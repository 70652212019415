import React from "react";
import { Controller, useForm } from "react-hook-form";
import { CheckEmailExistsModel } from "../../../models/patient/check-email.model";
import { AddUserModel, RoleModel } from "../../../models/users";
import { ShowErrorMessage } from "../../../shared/helpers";
import { Loader } from "../../../shared/loaders";
import MultiRoles from "./multiRoles";
import SpecialitySuggestion from "./specialitySuggestion";

interface IProps {
  user: AddUserModel;
  isReadOnly: boolean;
  onSubmit: Function;
  onEditClick: Function;
  isSaving: boolean;
  roleList: any[];
  isInviteSent?: boolean;
  isEmailExists: Function;
  isEmailError: boolean;
  isEmailErrorMessage: string;
}
export function AddUserForm(props: IProps) {
  const { register, handleSubmit, control, formState } = useForm();

  let userModel = props.user;
  const formSubmit = (data) => {
    userModel.id = props.user.id;
    userModel.emailId = data.emailId;
    userModel.firstName = data.firstName;
    userModel.lastName = data.lastName;
    userModel.speciality = data.speciality;
    if (!checkForError()) {
      props.onSubmit(userModel);
    }
  };

  const checkForError = () => {
    let hasError = false;
    props.user.firstNameError = "";
    props.user.lastNameError = "";
    props.user.emailIdError = "";
    props.user.roleError = "";
    if (!userModel.roles || userModel.roles.length <= 0) {
      props.user.roleError = "Please select atleast one role.";
      hasError = true;
    }
    if (userModel.firstName == "") {
      props.user.firstNameError = "Please fill first name";
      hasError = true;
    }
    if (userModel.lastName == "") {
      props.user.lastNameError = "Please fill last name";
      hasError = true;
    }
    if (userModel.emailId == "") {
      props.user.emailIdError = "Please fill email";
      hasError = true;
    }

    let emailval = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ).test(userModel.emailId!);

    if (userModel.emailId !== "" && !emailval) {
      props.user.emailIdError = "Email is not valid.";
      hasError = true;
    }
    return hasError;
  };
  const handleSaveAndInviteClick = () => {
    userModel.isInviteSend = true;
  };
  const handleSaveClick = () => {
    userModel.isInviteSend = false;
  };
  const changeToEdit = () => {
    props.onEditClick();
  };
  const handleRole = (data: RoleModel[]) => {
    if (data != null && data.length > 0) {
      userModel.roles = [];
      data.forEach((x) => {
        userModel.roles?.push(x);
      });
    } else {
      userModel.roles = [];
    }
  };
  const onSpecialityChange = (data) => {
    userModel.speciality = data;
  };
  const handleOnBlur_Email = (emailId) => {
    if (!props.isReadOnly) {
      let checkIsEmailExists = {} as CheckEmailExistsModel;
      checkIsEmailExists.id = userModel.id;
      checkIsEmailExists.emailId = emailId;
      props.isEmailExists({ model: checkIsEmailExists, isAutoSave: true });
    }
  };
  return (
    <form onSubmit={handleSubmit(formSubmit)}>
      <section className="info-section">
        <div className="info-title">
          <h3>User Information to Create New User</h3>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label className="form-label">First Name</label>
              <input
                type="text"
                defaultValue={props.user?.firstName}
                className={!props.user.firstNameError ? "form-control" : " form-control is-invalid"}
                {...register("firstName", {
                  maxLength: 50,
                })}
                readOnly={props.isReadOnly ? true : false}
                placeholder="First Name"
              />
              {props.user.firstNameError && (
                <div className="invalid-feedback">{props.user.firstNameError}</div>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="form-label">Last Name</label>
              <input
                type="text"
                defaultValue={props.user?.lastName}
                className={!props.user.lastNameError ? "form-control" : "form-control  is-invalid"}
                {...register("lastName", {
                  maxLength: 50,
                })}
                readOnly={props.isReadOnly ? true : false}
                placeholder="Last Name"
              />
              {props.user.lastNameError && (
                <div className="invalid-feedback">{props.user.lastNameError}</div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <MultiRoles
              className={!props.user.roleError ? "form-control" : "form-control  is-invalid "}
              options={props.roleList}
              getSelectedRoles={handleRole}
              selectedoption={props.user.roles}
              readOnly={props.isReadOnly ? true : false}
            />
            {props.user.roleError && (
              <div className="invalid-feedback role-error">{props.user.roleError}</div>
            )}
          </div>
          <div className="col-md-3">
            <div className="form-group optional-field">
              <label className="form-label">Speciality</label>
              <span className="optional">Optional</span>
              <input
                type="text"
                defaultValue={props.user.speciality}
                className="form-control"
                {...register("speciality")}
                readOnly={props.isReadOnly ? true : false}
                placeholder="Speciality"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Email</label>
              <input
                type="text"
                maxLength={255}
                defaultValue={props.user?.emailId}
                placeholder="Email"
                
                className={!props.user.emailIdError ? "form-control" : "form-control  is-invalid"}
                readOnly={props.isReadOnly || props.user.identityId ? true : false}
                {...register("emailId", {
                  maxLength: 128,
                  onBlur: (e) => {handleOnBlur_Email(e.target.value)}
                })}
              />
              {!props.isEmailError && props.user.emailIdError && (
                <div className="invalid-feedback">{props.user.emailIdError}</div>
              )}
              {props.isEmailError && (
                <div className="invalid-feedback" style={{ display: "block", textAlign: "left" }}>
                  {props.isEmailErrorMessage}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <Loader
            loading={props.isSaving}
            marginBottom="0px"
            marginTop="8px"
            width="368px"
          ></Loader>
        </div>
        {!props.isSaving && (
          <div className="row btn-box">
            <div className="col-md-6 text-right">
              {!props.isReadOnly && (
                <button
                  type="submit"
                  onClick={handleSaveClick}
                  className="btn btn-primary btn-save"
                >
                  Save {props.isReadOnly}
                </button>
              )}
              {!props.isReadOnly && !props.user.identityId && (
                <button
                  type="submit"
                  className="btn btn-primary btn-save invite-button"
                  onClick={handleSaveAndInviteClick}
                >
                  Save and Invite
                </button>
              )}

              {props.isReadOnly && (
                <button type="button" className="btn btn-primary btn-save" onClick={changeToEdit}>
                  Edit
                </button>
              )}
            </div>
          </div>
        )}
      </section>
    </form>
  );
}
