import React from "react";
import Heading from "../../../heading";
import { Button } from "antd";
import "../../styles.css";
import { useForm } from "react-hook-form";
import { Loader } from "../../../../shared/loaders";
import { AddChildTemperamentModel } from "../../../../models/developmentProfile/childTemperament/add-child-temperament.model";
import { ActivityLevel } from "../../../../shared/enums/childTemperament/activity-level.enum";
import { Rhythmicity } from "../../../../shared/enums/childTemperament/rhythmicity.enum";
import { ApproachOrWithDrawl } from "../../../../shared/enums/childTemperament/approach.enum";
import { Adaptability } from "../../../../shared/enums/childTemperament/adaptability.enum";
import { ThresholdOfResponsiveness } from "../../../../shared/enums/childTemperament/threshold-of-responsiveness.enum";
import { IntensityOfReaction } from "../../../../shared/enums/childTemperament/Intensity-of-reaction.enum";
import { QualityOfMood } from "../../../../shared/enums/childTemperament/quality-of-mood.enum";
import { Distractibility } from "../../../../shared/enums/childTemperament/distractibility.enum";
import { AttentionSpanAndPersistence } from "../../../../shared/enums/childTemperament/attention-span-and-persistence.enum";
interface IProps {
  childTemperamentModel: AddChildTemperamentModel;
  onSubmit: Function;
  isSaving: boolean;
  patientId: string;
  assignModel: Function;
  parentTabChange: Function;
  isBirthHistoryOptional: boolean;
}
export default function AddChildTemperamentForm(props: IProps) {
  let childTemperament = props.childTemperamentModel;
  const { register, handleSubmit, control, getValues, formState, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
  });
  const formSubmit = () => {
    childTemperament.patientId = props.patientId;
    if (checkForError()) {
      props.assignModel({ childTemperament: childTemperament })
      return false;
    }
    props.onSubmit({ childTemperament: childTemperament, isAutoSave: false });
  };
  const checkForError = () => {
    let hasError = false;
    childTemperament.activityLevelError = "";
    childTemperament.rhythmicityError = "";
    childTemperament.approachOrWithdrawlError = "";
    childTemperament.thresholdofResponsivenessError = "";
    childTemperament.adaptabilityError = "";
    childTemperament.intensityOfReactionError = "";
    childTemperament.qualityOfMoodError = "";
    childTemperament.distractibilityError = "";
    childTemperament.attentionSpanAndPersistenceError = "";
    if (childTemperament.activityLevel == null && !props.isBirthHistoryOptional) {
      childTemperament.activityLevelError = "Please select activity level.";
      hasError = true;
    }
    if (childTemperament.rhythmicity == null && !props.isBirthHistoryOptional) {
      childTemperament.rhythmicityError = "Please select rhythmicity.";
      hasError = true;
    }
    if (childTemperament.approachOrWithdrawl == null && !props.isBirthHistoryOptional) {
      childTemperament.approachOrWithdrawlError = "Please select approach/withdrawl.";
      hasError = true;
    }
    if (childTemperament.thresholdofResponsiveness == null && !props.isBirthHistoryOptional) {
      childTemperament.thresholdofResponsivenessError =
        "Please select threshold of responsiveness.";
      hasError = true;
    }
    if (childTemperament.adaptability == null && !props.isBirthHistoryOptional) {
      childTemperament.adaptabilityError = "Please select adaptability.";
      hasError = true;
    }
    if (childTemperament.intensityOfReaction == null && !props.isBirthHistoryOptional) {
      childTemperament.intensityOfReactionError = "Please select intensity of reaction.";
      hasError = true;
    }
    if (childTemperament.qualityOfMood == null && !props.isBirthHistoryOptional) {
      childTemperament.qualityOfMoodError = "Please select quality of mood.";
      hasError = true;
    }
    if (childTemperament.distractibility == null && !props.isBirthHistoryOptional) {
      childTemperament.distractibilityError = "Please select distractibility.";
      hasError = true;
    }
    if (childTemperament.attentionSpanAndPersistence == null && !props.isBirthHistoryOptional) {
      childTemperament.attentionSpanAndPersistenceError =
        "Please select attention span and persistence.";
      hasError = true;
    }
    return hasError;
  };

  const handleKeyUp = (event) => {
    props.assignModel({ childTemperament: childTemperament });
    if (event.key == "Tab") {
      saveDataOnTabChange();
    }
  };
  const setType = (type: number, value: number) => {
    switch (type) {
      case 1: {
        childTemperament.activityLevel = value;
        props.assignModel({ childTemperament: childTemperament });
        break;
      }
      case 2: {
        childTemperament.rhythmicity = value;
        props.assignModel({ childTemperament: childTemperament });
        break;
      }
      case 3: {
        childTemperament.approachOrWithdrawl = value;
        props.assignModel({ childTemperament: childTemperament });
        break;
      }
      case 4: {
        childTemperament.adaptability = value;
        props.assignModel({ childTemperament: childTemperament });
        break;
      }
      case 5: {
        childTemperament.thresholdofResponsiveness = value;
        props.assignModel({ childTemperament: childTemperament });
        break;
      }
      case 6: {
        childTemperament.intensityOfReaction = value;
        props.assignModel({ childTemperament: childTemperament });
        break;
      }
      case 7: {
        childTemperament.qualityOfMood = value;
        props.assignModel({ childTemperament: childTemperament });
        break;
      }
      case 8: {
        childTemperament.distractibility = value;
        props.assignModel({ childTemperament: childTemperament });
        break;
      }
      case 9: {
        childTemperament.attentionSpanAndPersistence = value;
        props.assignModel({ childTemperament: childTemperament });
        break;
      }
      default: {
        break;
      }
    }
    saveDataOnTabChange();
  };
  const saveDataOnTabChange = () => {
    if (!props.isSaving) {
      childTemperament.patientId = props.patientId;
      props.onSubmit({ childTemperament: childTemperament, isAutoSave: true });
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(formSubmit)}>
        <div>
          <Heading
            heading="Child Temperament"
            subHeading="Select the number that most closely resembles your child when she/he was an infant and toddler."
            styleName=""
          />
          <div className="form form-box">
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <p className="med_childPara">Activity level</p>
                <h3 className="med_childHeading">
                  Infant's/toddler's general level of activity was:
                </h3>
                <label className="form-label">
                  (e.g. a parent might say, "She kicks and splashes in the tub all the time", or "In
                  the morning I find him lying in the same place he was when he fall asleep")
                </label>
                <div className="btn-group btnGender">
                  <button
                    type="button"
                    onClick={() => setType(1, ActivityLevel.HighActivity)}
                    className={
                      childTemperament.activityLevel == ActivityLevel.HighActivity
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    1 High activity
                  </button>
                  <button
                    type="button"
                    onClick={() => setType(1, 2)}
                    className={
                      childTemperament.activityLevel == 2
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    2
                  </button>
                  <button
                    type="button"
                    onClick={() => setType(1, ActivityLevel.LowActivity)}
                    className={
                      childTemperament.activityLevel == ActivityLevel.LowActivity
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    3 Low activity
                  </button>
                  
                </div>
                {childTemperament.activityLevelError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {childTemperament.activityLevelError}
                    </div>
                  )}
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <p className="med_childPara">Rhythmicity (regularity)</p>
                <h3 className="med_childHeading">
                  Infant's/toddler's established routines for eating, sleeping and toileting were:
                </h3>
                <label className="form-label">
                  (e.g. fleeting or sleep-wake cycles were predictable or unpredictable; a parent
                  might say. "Her big meal is always at lunch time"; or "I never know when he'll be
                  hungry.")
                </label>
                <div className="btn-group btnGender">
                  <button
                    type="button"
                    onClick={() => setType(2, Rhythmicity.RegularRoutines)}
                    className={
                      childTemperament.rhythmicity == Rhythmicity.RegularRoutines
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    1 Regular routines
                  </button>
                  <button
                    type="button"
                    onClick={() => setType(2, 2)}
                    className={
                      childTemperament.rhythmicity == 2
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    2
                  </button>
                  <button
                    type="button"
                    onClick={() => setType(2, Rhythmicity.IrregularRoutines)}
                    className={
                      childTemperament.rhythmicity == Rhythmicity.IrregularRoutines
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    3 Irregular routines
                  </button>
                  
                </div>
                {childTemperament.rhythmicityError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {childTemperament.rhythmicityError}
                    </div>
                  )}
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <p className="med_childPara">Approach/Withdrawl</p>
                <h3 className="med_childHeading">
                  Infant's/toddler's way of responding to new people and new situations was
                  generally:
                </h3>
                <label className="form-label">
                  (e.g. a parent might say, "He always smiles at strangers", or "She ignores a new
                  toy until it has been around for a few days.")
                </label>
                <div className="btn-group btnGender">
                  <button
                    type="button"
                    onClick={() => setType(3, ApproachOrWithDrawl.Approach)}
                    className={
                      childTemperament.approachOrWithdrawl == ApproachOrWithDrawl.Approach
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    1 Approach
                  </button>
                  <button
                    type="button"
                    onClick={() => setType(3, 2)}
                    className={
                      childTemperament.approachOrWithdrawl == 2
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    2
                  </button>
                  <button
                    type="button"
                    onClick={() => setType(3, ApproachOrWithDrawl.Withdraw)}
                    className={
                      childTemperament.approachOrWithdrawl == ApproachOrWithDrawl.Withdraw
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    3 Withdraw
                  </button>
                  
                </div>
                {childTemperament.approachOrWithdrawlError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {childTemperament.approachOrWithdrawlError}
                    </div>
                  )}
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <p className="med_childPara">Adaptability</p>
                <h3 className="med_childHeading">
                  Infant's/toddler's ability to adapt to changing situations was generally:
                </h3>
                <label className="form-label">
                  (e.g. a parent might say, "She got her tricycle and practiced until she could ride
                  it", or "it took him all of autumn to get used to going to the nursery.")
                </label>
                <div className="btn-group btnGender">
                  <button
                    type="button"
                    onClick={() => setType(4, Adaptability.HighAdaptability)}
                    className={
                      childTemperament.adaptability == Adaptability.HighAdaptability
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    1 High adaptability
                  </button>
                  <button
                    type="button"
                    onClick={() => setType(4, 2)}
                    className={
                      childTemperament.adaptability == 2
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    2
                  </button>
                  <button
                    type="button"
                    onClick={() => setType(4, Adaptability.LowAdaptability)}
                    className={
                      childTemperament.adaptability == Adaptability.LowAdaptability
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    3 Low adaptability
                  </button>
                  
                </div>
                {childTemperament.adaptabilityError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {childTemperament.adaptabilityError}
                    </div>
                  )}
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <p className="med_childPara">Threshold of responsiveness</p>
                <h3 className="med_childHeading">
                  Amount of stimulation needed for the infant/toddler to respond to people or thing
                  was:
                </h3>
                <label className="form-label">
                  (e.g. a parent might say, "If a door closes even softly, he startles and looks
                  up", or "She can bang her head and she doesn't cry or fuss.")
                </label>
                <div className="btn-group btnGender">
                  <button
                    type="button"
                    onClick={() => setType(5, ThresholdOfResponsiveness.QuiteReponsive)}
                    className={
                      childTemperament.thresholdofResponsiveness ==
                      ThresholdOfResponsiveness.QuiteReponsive
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    1 Quite responsive
                  </button>
                  <button
                    type="button"
                    onClick={() => setType(5, 2)}
                    className={
                      childTemperament.thresholdofResponsiveness == 2
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    2
                  </button>
                  <button
                    type="button"
                    onClick={() => setType(5, ThresholdOfResponsiveness.LowResponsive)}
                    className={
                      childTemperament.thresholdofResponsiveness ==
                      ThresholdOfResponsiveness.LowResponsive
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    3 Low responsive
                  </button>
                  
                </div>
                {childTemperament.thresholdofResponsivenessError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {childTemperament.thresholdofResponsivenessError}
                    </div>
                  )}
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <p className="med_childPara">Intensity of reaction</p>
                <h3 className="med_childHeading">
                  The energy level that is expressed across most situations:
                </h3>
                <label className="form-label">
                  (e.g. a parent might say, "When I make his favourite dessert, he shouts with joy
                  and runs to tell his sister" or "We took her to the circus she was excited about
                  and she acted as if she didn't care.")
                </label>
                <div className="btn-group btnGender">
                  <button
                    type="button"
                    onClick={() => setType(6, IntensityOfReaction.HighIntensity)}
                    className={
                      childTemperament.intensityOfReaction == IntensityOfReaction.HighIntensity
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    1 High intensity
                  </button>
                  <button
                    type="button"
                    onClick={() => setType(6, 2)}
                    className={
                      childTemperament.intensityOfReaction == 2
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    2
                  </button>
                  <button
                    type="button"
                    onClick={() => setType(6, IntensityOfReaction.LowIntensity)}
                    className={
                      childTemperament.intensityOfReaction == IntensityOfReaction.LowIntensity
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    3 Low intensity
                  </button>
                  
                </div>
                {childTemperament.intensityOfReactionError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {childTemperament.intensityOfReactionError}
                    </div>
                  )}
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <p className="med_childPara">Quality of mood</p>
                <h3 className="med_childHeading">
                  Infant's/toddler's generally quality of mood was:
                </h3>
                <label className="form-label">
                  (e.g. the amount of pleasant, joyful behaviour as contrasted with unpleasant,
                  crying, unfriendly behaviour; a parent might say, "She comes home from nursery
                  school and complains about the day", or "If he's not laughing of smiling, I
                  automatically think he's sick.")
                </label>
                <div className="btn-group btnGender">
                  <button
                    type="button"
                    onClick={() => setType(7, QualityOfMood.Positive)}
                    className={
                      childTemperament.qualityOfMood == QualityOfMood.Positive
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    1 Positive
                  </button>
                  <button
                    type="button"
                    onClick={() => setType(7, 2)}
                    className={
                      childTemperament.qualityOfMood == 2
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    2
                  </button>
                  <button
                    type="button"
                    onClick={() => setType(7, QualityOfMood.Negative)}
                    className={
                      childTemperament.qualityOfMood == QualityOfMood.Negative
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    3 Negative
                  </button>
                  
                </div>
                {childTemperament.qualityOfMoodError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {childTemperament.qualityOfMoodError}
                    </div>
                  )}
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <p className="med_childPara">Distractibility</p>
                <h3 className="med_childHeading">
                  How sensitive is the infant/toddler to extraneous sights or sound that change the
                  direction of his/her activity?
                </h3>
                <label className="form-label">
                  (e.g. a parent might say, "If she's playing with her toys, she seems oblivious to
                  everything around her" or "Even if he plays with his favourite building blocks, if
                  he hears a noise, he has to go investigate.")
                </label>
                <div className="btn-group btnGender">
                  <button
                    type="button"
                    onClick={() => setType(8, Distractibility.QuiteSensitiveDistractible)}
                    className={
                      childTemperament.distractibility == Distractibility.QuiteSensitiveDistractible
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    1 Quite sensitive distractible
                  </button>
                  <button
                    type="button"
                    onClick={() => setType(8, 2)}
                    className={
                      childTemperament.distractibility == 2
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    2
                  </button>
                  <button
                    type="button"
                    onClick={() => setType(8, Distractibility.LowSensitiveOverFocussed)}
                    className={
                      childTemperament.distractibility == Distractibility.LowSensitiveOverFocussed
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    3 Low sensitive overfocused
                  </button>
                  
                </div>
                {childTemperament.distractibilityError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {childTemperament.distractibilityError}
                    </div>
                  )}
              </div>
            </div>
            <div className="medical__medChild_formRow">
              <div className="medical-group btn-select">
                <p className="med_childPara">Attention span and persistence</p>
                <h3 className="med_childHeading">
                  The infant's/toddler's attention span (the length of time doing one thing) and
                  persistence (staying with an activity even though it might get frustrating) in
                  activities was generally:
                </h3>
                <label className="form-label">
                  (e.g. a parent might say, "If I just give him a big box, he'll play with it for
                  hours", or "If she pushes her wagon and it gets stuck, she'll quite playing with
                  it rather than try to get it out")
                </label>
                <div className="btn-group btnGender">
                  <button
                    type="button"
                    onClick={() =>
                      setType(9, AttentionSpanAndPersistence.HighAttentionQuitePersistent)
                    }
                    className={
                      childTemperament.attentionSpanAndPersistence ==
                      AttentionSpanAndPersistence.HighAttentionQuitePersistent
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    1 High attention quite persistent
                  </button>
                  <button
                    type="button"
                    onClick={() => setType(9, 2)}
                    className={
                      childTemperament.attentionSpanAndPersistence == 2
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    2
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      setType(9, AttentionSpanAndPersistence.LowAttentionQuitePersistent)
                    }
                    className={
                      childTemperament.attentionSpanAndPersistence ==
                      AttentionSpanAndPersistence.LowAttentionQuitePersistent
                      ? "btn btn-primary btn-sm btnAdopted_yes"
                      : "btn btn-outline-secondary btn-sm btnAdopted_others"
                    }
                  >
                    3 Low attention Low persistence
                  </button>
                  
                </div>
                {childTemperament.attentionSpanAndPersistenceError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block", textAlign: "left" }}
                    >
                      {childTemperament.attentionSpanAndPersistenceError}
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="btnContainer">
            {!props.isSaving && (
              <Button
                type="default"
                size="large"
                className="back_btn"
                onClick={() => props.parentTabChange(4)}
              >
                <i className="fa fa-long-arrow-left"></i> Back
              </Button>
            )}
            {!props.isSaving && (
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="btn_add"
              >
                Save <i className="fa fa-long-arrow-right"></i>
              </Button>
            )}
            {props.isSaving && (
              <div style={{ height: "60px" }}>
                <Loader
                  loading={props.isSaving}
                  marginBottom="0px"
                  marginTop="8px"
                  width="368px"
                ></Loader>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
