import React, { Component } from "react";
import Tabs from "../../tabs";
import "../styles.css";
import { PreviousDiagnosis } from "./previousDiagnosis";

interface State {
  tab: number;
  patientId: string;
  isFormSave?: Function;
}
class Diagnosis extends Component<any, State> {
  state: State = { tab: 0, patientId: "" };
  changeTab(tab: any) {
    this.setState({
      tab,
    });
  }

  render() {
    const { tab } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="medical__family_tabs d-none d-xl-block col-3">
            <Tabs
              styleName="familyTab"
              text="Previous Diagnosis"
              steps=""
              stepsAct=""
              fullText="Previous Diagnosis"
              familyTextName="A"
              image=""
              activeImage=""
              tabChange={() => this.changeTab(0)}
              selected={tab === 0}
            />
          </div>
          <div className="medical__family_content col">
            {tab === 0 && (
              <PreviousDiagnosis
                parentTabChange={this.props.parentTab}
                patientId={this.props.patientId}
                nextTabChange={this.props.nextTabChange}
                isFormSave={this.props.isFormSave}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default Diagnosis;
