import React, { useState } from "react";
import addPatient from "../../assets/images/svg-icons/add_patient.svg";
import "./index.css";
import { UploadOutlined } from "@ant-design/icons";
import { Badge, Popover } from "antd";
import notification from "../../assets/images/svg-icons/notification.svg";
import { PageTitle } from "./pageTitle";
import { Button } from "antd";
import { Link } from "react-router-dom";
import password from "../../assets/images/svg-icons/passwordkey-blue.svg";
import logo from "../../assets/images/CCFLogoFullColor.png";
import Modal from "antd/lib/modal/Modal";
import { AddUserModel } from "../../models/users";
import { useForm } from "react-hook-form";
import { Role } from "../../shared/enums/role.enum";
import { Breadcrumb } from "./breadcrumb";
import BreadcrumSetter from "../../shared/breadcrumSetter";
import patientGrey from "../../assets/images/svg-icons/patient-grey.svg";
import userprofile from "../../assets/images/svg-icons/user-profile-line-icon.svg";
import { PatternFormat } from "react-number-format";
import { Relationship } from "../../shared/enums/relationship.enum";
import { AddPatientModel } from "../../components/patient/models/add-patient";
import { AddTeacherModel } from "../../models/teacher/add-teacher.model";
const TopHeader = (props) => {
    const { register,  setError, formState: { errors }, handleSubmit, control, getValues, formState } =
        useForm({});
    const LogOut = () => {
        props.logOut();
    };
    const ChangePassword = () => {
        props.changePassword();
    };
    const cancelEditProfile = () => {
        props.handleCancelEditProfile();
    };

    const openEditProfile = () => {
        props.handleOpenEditProfile();
    };
    const submitForm = () => {
        let model = getValues();
        if (
            props.designation.includes(Role.SuperAdmin) ||
            props.designation.includes(Role.Admin) ||
            props.designation.includes(Role.Clinician)
        ) {
            props.user.firstName = model.firstName;
            props.user.lastName = model.lastName;
            props.user.speciality = model.speciality;
            let user: AddUserModel = {
                id: props.user.id,
                firstName: props.user.firstName,
                lastName: props.user.lastName,
                speciality: props.user.speciality,
                emailId: props.user.emailId,
                firstNameError: "",
                lastNameError: "",
            };
            checkForUserError(props, user);
            props.handleSave(user);
        }
        if (props.designation === Role.Parent) {
            props.patient.guardianFirstName = model.guardianFirstName;
            props.patient.guardianLastName = model.guardianLastName;
            props.patient.contactNo = model.contactNo;
            props.patient.emailId = model.emailId;
            props.patient.addressModel.address1 = model.address1;
            props.patient.addressModel.address2 = model.address2;
            props.patient.addressModel.city = model.city;
            props.patient.addressModel.state = model.state;
            props.patient.addressModel.zipcode = model.zipcode;
            let patient: AddPatientModel = {
                relationshipId: props.patient.relationshipId,
                guardianFirstName: props.patient.guardianFirstName,
                guardianLastName: props.patient.guardianLastName,
                contactNo: props.patient.contactNo,
                emailId: props.patient.emailId,
                addressModel: props.patient.addressModel,
                physicianModel: props.patient.physicianModel,
                guardianFirstNameError: "",
                guardianLastNameError: "",
                relationshipError: "",
                contactNoError: "",
                address1Error: "",
                cityError: "",
                stateError: "",
                zipcodeError: "",
            };
            checkForPatientError(props, patient);
            props.handleParentSave(patient);
        }
        if (props.designation === Role.Teacher) {
            props.teacher.tFirstName = model.tFirstName;
            props.teacher.tLastName = model.tLastName;
            props.teacher.schoolName = model.schoolName;
            props.teacher.schoolContactNo = model.schoolContactNo;
            props.teacher.schoolDistrict = model.schoolDistrict;
            props.teacher.tEmailId = model = model.tEmailId;
            let teacher: AddTeacherModel = {
                teacherId: props.teacher.teacherId,
                tFirstName: props.teacher.tFirstName,
                tLastName: props.teacher.tLastName,
                schoolName: props.teacher.schoolName,
                tEmailId: props.teacher.tEmailId,
                schoolDistrict: props.teacher.schoolDistrict,
                schoolContactNo: props.teacher.schoolContactNo,
                tFirstNameError: "",
                tLastNameError: "",
                schoolContactNoError: "",
                schoolDistrictError: "",
                schoolNameError: "",
            };
            checkForErrorTeacher(props, teacher);
            props.handleTeacherSave(teacher);
        }
        if (props.designation === Role.Respondent){
            props.respondent.firstName = model.rFirstName;
            props.respondent.lastName = model.rLastName;
        }
    };
    const checkForUserError = (props: any, user: AddUserModel) => {
        if (props.user.firstName == "") {
            user.firstNameError = "Please fill first name";
        }
        if (
            (props.user.firstName != null || props.user.firstName != undefined) &&
            props.user.firstName.length > 50
        ) {
            user.firstNameError = "First name too long";
        }
        if (props.user.lastName == "") {
            user.lastNameError = "Please fill last name";
        }
        if (
            (props.user.lastName != null || props.user.lastName != undefined) &&
            props.user.lastName.length > 50
        ) {
            user.lastNameError = "Last name too long";
        }
    };
    const relationChange = (data) => {
        props.patient.relationshipId = parseInt(data.target.value);
    };
    const checkForPatientError = (props: any, patient: AddPatientModel) => {
        if (
            props.patient.relationshipId == "" ||
            props.patient.relationshipId == null ||
            isNaN(props.patient.relationshipId)
        ) {
            patient.relationshipError = "Please select relationship.";
        }
        if (props.patient.guardianFirstName == "") {
            patient.guardianFirstNameError = "Please fill first name";
        }

        if (
            (props.patient.guardianFirstName != null ||
                props.patient.guardianFirstName != undefined) &&
            props.patient.guardianFirstName.length > 128
        ) {
            patient.guardianFirstNameError = "First name too long";
        }
        if (props.patient.guardianLastName == "") {
            patient.guardianLastNameError = "Please fill last name";
        }
        if (
            (props.patient.guardianLastName != null ||
                props.patient.guardianLastName != undefined) &&
            props.patient.guardianLastName.length > 128
        ) {
            patient.guardianLastNameError = "Last name too long";
        }
        if (props.patient.contactNo == "") {
            patient.contactNoError = "Please fill contact no";
        }
        if (!isPhnDigit(props.patient.contactNo)) {
            patient.contactNoError = "Contact number should be 10 digit.";
        }

        if (props.patient.addressModel.address1 == "") {
            patient.address1Error = "Please fill address";
        }

        if (
            (props.patient.addressModel.address1 != null ||
                props.patient.addressModel.address1 != undefined) &&
            props.patient.addressModel.address1.length > 128
        ) {
            patient.address1Error = "Address too long";
        }
        if (props.patient.addressModel.city == "") {
            patient.cityError = "Please fill address";
        }

        if (
            (props.patient.addressModel.city != null ||
                props.patient.addressModel.city != undefined) &&
            props.patient.addressModel.city.length > 128
        ) {
            patient.cityError = "City too long";
        }
        if (props.patient.addressModel.state == "") {
            patient.stateError = "Please fill city";
        }

        if (
            (props.patient.addressModel.state != null ||
                props.patient.addressModel.state != undefined) &&
            props.patient.addressModel.state.length > 128
        ) {
            patient.stateError = "State too long";
        }
        if (props.patient.addressModel.zipcode == "") {
            patient.zipcodeError = "Please fill zipcode";
        }

        if (
            (props.patient.addressModel.zipcode != null ||
                props.patient.addressModel.zipcode != undefined) &&
            props.patient.addressModel.zipcode.length > 128
        ) {
            patient.zipcodeError = "Zipcode too long";
        }
    };
    const isPhnDigit = (event) => {
        if (event.replace(/\D/g, "").length < 10) {
            return false;
        }
        return true;
    };
    const checkForErrorTeacher = (props: any, teacher: AddTeacherModel) => {
        if (props.teacher.tFirstName == "" || teacher.tFirstName == null) {
            teacher.tFirstNameError = "Please fill  first name.";
        }
        if (
            (props.teacher.tFirstName != null ||
                props.teacher.tFirstName != undefined) &&
            props.teacher.tFirstName.length > 128
        ) {
            teacher.tFirstNameError = "First name too long.";
        }
        if (props.teacher.tLastName == "" || teacher.tLastName == null) {
            teacher.tLastNameError = "Please fill  last name.";
        }
        if (
            (props.teacher.tLastName != null ||
                props.teacher.tLastName != undefined) &&
            props.teacher.tLastName!.length > 128
        ) {
            teacher.tFirstNameError = "Last name too long.";
        }

        if (
            (props.teacher.schoolContactNo != null ||
                props.teacher.schoolContactNo != undefined) &&
            props.teacher.schoolContactNo.length > 128
        ) {
            teacher.schoolContactNoError = "School phone number too long.";
        }
        if (
            props.teacher.schoolContactNo.length > 0 &&
            !isPhnDigit(props.teacher.schoolContactNo)
        ) {
            teacher.schoolContactNoError = "Contact number should be 10 digit.";
        }
        if (
            (props.teacher.schoolDistrict != null ||
                props.teacher.schoolDistrict != undefined) &&
            props.teacher.schoolDistrict.length > 128
        ) {
            teacher.schoolDistrictError = "School district too long";
        }
        if (
            (props.teacher.schoolName != null ||
                props.teacher.schoolName != undefined) &&
            props.teacher.schoolName.length > 128
        ) {
            teacher.schoolNameError = "School name too long";
        }
    };
    const isNumber = (evt) => {
        if (evt.which > 31 && (evt.which < 48 || evt.which > 57)) {
            evt.preventDefault();
        }
    };
    return (
        <header className="header">
            {/* <div></div> */}
            <div className={`${props.headingStyle}`}>
                {props.logoVisible && (
                    <div className="ccf__logo__content">
                        <img width="200" alt="logo" src={logo} />
                        {/* <h3>
              M3P
            </h3>
            <p>
              Manos
              <br /> Medication <br /> Monitoring Platform
            </p> */}
                    </div>
                )}
                <PageTitle />
                <Breadcrumb />
            </div>
            <div className="socialHeader__right pr-5">
                <div className="search__wrapper">
                    {props.addPatient && (
                        <Link to="/patient/addPatient/0">
                            <Button
                                type="primary"
                                size="large"
                                className="btn_add"
                                icon={
                                    <img
                                        src={addPatient}
                                        alt="addPatient"
                                        className="addPatient__img"
                                    />
                                }
                            >
                                Add Patient
                            </Button>
                        </Link>
                    )}
                </div>
                {/* <div className="ccf_notificatn" style={{ visibility: "hidden" }}>
          <img src={notification} alt="notification" className="notification" />
          <Badge count={5} className="badge"></Badge>
        </div> */}
                <div className="user-info">
                    <Popover
                        placement="bottomRight"
                        trigger="click"
                        content={
                            <div className="ccf_menu">
                                <ul>
                                    {props.designation !== Role.Respondent && 
                                     props.designation !== Role.Teacher &&
                                     props.designation !== Role.Parent && (
                                        <li onClick={openEditProfile}>
                                        <img
                                            style={{ width: 15 }}
                                            src={userprofile}
                                            alt="my profile"
                                        />
                                        <p>My Profile</p>
                                    </li>
                                    )}
                                    

                                    <li onClick={ChangePassword}>
                                        <img
                                            style={{ width: 16 }}
                                            src={password}
                                            alt="change password"
                                        />
                                        <p>Change Password</p>
                                    </li>
                                    <li onClick={LogOut}>
                                        <UploadOutlined className="menu_icons" />
                                        <p>Log out</p>
                                    </li>
                                </ul>
                            </div>
                        }
                    >
                        <div style={{ cursor: "pointer", position: "relative" }}>
                            <div>
                                <span className="profile-icon">{props.initials}</span>
                                {/* <Popover
                  placement="bottomLeft"
                  trigger="hover"
                  content={<span>{props.username}</span>}
                  className="user_name_hover"
                > */}
                                <p className="ccf_username">
                                    {props.username} <br /> <span>{props.designation}</span>
                                </p>
                                <i className="far fa-angle-down caret-icon"></i>
                                {/* </Popover> */}
                            </div>
                        </div>
                    </Popover>
                </div>
            </div>

            <Modal
                title=""
                open={props.isEditModalVisible}
                onOk={submitForm}
                onCancel={cancelEditProfile}
                okText="Save"
                okType="primary"
                cancelText="Cancel"
                // closable={false}
                maskClosable={false}
                className="editProfile__modal"
            >
                <section className="edit__modal">
                    <h3>Edit Profile</h3>
                    {(props.designation.includes(Role.SuperAdmin) ||
                        props.designation.includes(Role.Admin) ||
                        props.designation.includes(Role.Clinician)) && (
                            <div>
                                <div className="edit__form">
                                    <div className="medical-group">
                                        <label className="form-label">First Name</label>
                                        <input
                                            type="text"
                                            className={
                                                !props.user?.firstNameError
                                                    ? "form-control"
                                                    : " form-control is-invalid"
                                            }
                                            defaultValue={props.user?.firstName}
                                            {...register("firstName")}
                                            placeholder="First Name"
                                        />
                                        {props.user?.firstNameError && (
                                            <div className="invalid-feedback">
                                                {props.user?.firstNameError}
                                            </div>
                                        )}
                                    </div>
                                    <div className="medical-group">
                                        <label className="form-label">Last Name</label>
                                        <input
                                            type="text"
                                            className={
                                                !props.user?.lastNameError
                                                    ? "form-control"
                                                    : "form-control  is-invalid"
                                            }
                                            defaultValue={props.user?.lastName}
                                            {...register("lastName")}
                                            placeholder="Last Name"
                                        />
                                        {props.user?.lastNameError && (
                                            <div className="invalid-feedback">
                                                {props.user?.lastNameError}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {props.designation != Role.SuperAdmin && (
                                    <div className="edit__form__row">
                                        <div className="medical-group optional-field">
                                            <label className="form-label">Speciality</label>
                                            <span className="optional">Optional</span>
                                            <input
                                                type="text"
                                                className="form-control"
                                                defaultValue={props.user?.speciality}
                                                {...register("speciality")}
                                                placeholder="Speciality"
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="edit__form__row">
                                    <div className="medical-group">
                                        <label className="form-label">Email</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={props.user?.emailId}
                                            placeholder="Email"
                                            readOnly={true}
                                            {...register("emailId")}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                    {props.designation === Role.Parent && (
                        <div>
                            <div className="edit__form">
                                <div className="medical-group">
                                    <label className="form-label">Relationship</label>
                                    <select
                                        defaultValue={props.patient.relationshipId}
                                        className={
                                            !props.patient.relationshipError
                                                ? "form-control"
                                                : "form-control  is-invalid"
                                        }
                                        {...register("relationshipId", {
                                            onChange: (e)=>relationChange
                                        })}
                                    >
                                        <option value="">Select</option>
                                        <option value={Relationship.Father}>Father</option>
                                        <option value={Relationship.Mother}>Mother</option>
                                    </select>
                                    {!props.patient.relationshipError && (
                                        <i className="fa fa-angle-down relationPopup_arrow"></i>
                                    )}

                                    {props.patient.relationshipError && (
                                        <div className="invalid-feedback">
                                            {props.patient.relationshipError}
                                        </div>
                                    )}
                                </div>
                                <div className="medical-group">
                                    <label className="form-label">First Name</label>
                                    <input
                                        type="text"
                                        className={
                                            !props.patient.guardianFirstNameError
                                                ? "form-control"
                                                : "form-control is-invalid"
                                        }
                                        defaultValue={props.patient?.guardianFirstName}
                                        {...register("guardianFirstName")}
                                        placeholder="First Name"
                                    />
                                    {props.patient.guardianFirstNameError && (
                                        <div className="invalid-feedback">
                                            {props.patient.guardianFirstNameError}
                                        </div>
                                    )}
                                </div>
                                <div className="medical-group">
                                    <label className="form-label">Last Name</label>
                                    <input
                                        type="text"
                                        className={
                                            !props.patient.guardianLastNameError
                                                ? "form-control"
                                                : "form-control  is-invalid"
                                        }
                                        defaultValue={props.patient?.guardianLastName}
                                        {...register("guardianLastName")}
                                        placeholder="Last Name"
                                    />
                                    {props.patient.guardianLastNameError && (
                                        <div className="invalid-feedback">
                                            {props.patient.guardianLastNameError}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="edit__form">
                                <div className="medical-group">
                                    <label className="form-label">Contact Number</label>
                                    <PatternFormat
                                        format="(###)-###-####"
                                        name="contactNo"
                                        defaultValue={props.patient?.contactNo || ""}
                                        className={
                                            !props.patient.contactNoError
                                                ? "form-control"
                                                : "form-control  is-invalid"
                                        }
                                        placeholder={"Contact Number"}
                                        // readOnly={props.isReadOnly ? true : false}
                                        getInputRef={register}
                                    />

                                    {props.patient.contactNoError && (
                                        <div className="invalid-feedback">
                                            {props.patient.contactNoError}
                                        </div>
                                    )}
                                </div>
                                <div className="medical-group">
                                    <label className="form-label">Email</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        defaultValue={props.patient?.emailId}
                                        placeholder="Email"
                                        readOnly={true}
                                        {...register("emailId")}
                                    />
                                </div>
                            </div>
                            <div className="edit__form">
                                <div className="medical-group">
                                    <label className="form-label">Address 1</label>
                                    <input
                                        type="text"
                                        defaultValue={props.patient?.addressModel?.address1}
                                        placeholder={"Address 1"}
                                        className={
                                            !props.patient.address1Error
                                                ? "form-control"
                                                : "form-control  is-invalid"
                                        }
                                        {...register("address1")}
                                    />
                                    {props.patient.address1Error && (
                                        <div className="invalid-feedback">
                                            {props.patient.address1Error}
                                        </div>
                                    )}
                                </div>
                                <div className="medical-group">
                                    <label className="form-label">Address 2</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        maxLength={128}
                                        defaultValue={props.patient?.addressModel?.address2}
                                        placeholder={"Address 2"}
                                        {...register("address2")}
                                    />
                                </div>
                            </div>
                            <div className="edit__form">
                                <div className="medical-group">
                                    <label className="form-label">City</label>
                                    <input
                                        type="text"
                                        defaultValue={props.patient.addressModel?.city}
                                        readOnly={props.isReadOnly ? true : false}
                                        placeholder="City"
                                        className={
                                            !props.patient.cityError
                                                ? "form-control"
                                                : "form-control  is-invalid"
                                        }
                                        {...register("city")}
                                    />
                                    {props.patient.cityError && (
                                        <div className="invalid-feedback">
                                            {props.patient.cityError}
                                        </div>
                                    )}
                                </div>

                                <div className="medical-group">
                                    <label className="form-label">State</label>
                                    <input
                                        type="text"
                                        defaultValue={props.patient?.addressModel?.state}
                                        placeholder="State"
                                        className={
                                            !props.patient.stateError
                                                ? "form-control"
                                                : "form-control  is-invalid"
                                        }
                                        {...register("state")}
                                    />
                                    {props.patient.stateError && (
                                        <div className="invalid-feedback">
                                            {props.patient.stateError}
                                        </div>
                                    )}
                                </div>

                                <div className="medical-group">
                                    <label className="form-label">Zip code</label>
                                    <input
                                        type="text"
                                        defaultValue={props.patient?.addressModel?.zipcode}
                                        className={
                                            !props.patient.zipcodeError
                                                ? "form-control"
                                                : "form-control  is-invalid"
                                        }
                                        placeholder="Zip code"
                                        {...register("zipcode")}
                                        onKeyPress={(e) => isNumber(e)}
                                    />
                                    {props.patient.zipcodeError && (
                                        <div className="invalid-feedback">
                                            {props.patient.zipcodeError}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {props.designation === Role.Teacher && (
                        <div>
                            <div className="edit__form">
                                <div className="medical-group">
                                    <label className="form-label">First Name</label>
                                    <input
                                        type="text"
                                        className={
                                            !props.teacher.tFirstNameError
                                                ? "form-control"
                                                : "form-control  is-invalid"
                                        }
                                        defaultValue={props.teacher?.tFirstName}
                                        {...register("tFirstName")}
                                        placeholder="First Name"
                                    />
                                    {props.teacher.tFirstNameError && (
                                        <div className="invalid-feedback">
                                            {props.teacher.tFirstNameError}
                                        </div>
                                    )}
                                </div>
                                <div className="medical-group">
                                    <label className="form-label">Last Name</label>
                                    <input
                                        type="text"
                                        className={
                                            !props.teacher.tLastNameError
                                                ? "form-control"
                                                : "form-control  is-invalid"
                                        }
                                        defaultValue={props.teacher?.tLastName}
                                        {...register("tLastName")}
                                        placeholder="Last Name"
                                    />
                                    {props.teacher.tLastNameError && (
                                        <div className="invalid-feedback">
                                            {props.teacher.tLastNameError}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="edit__form">
                                <div className="medical-group">
                                    <label className="form-label">School Name</label>
                                    <input
                                        type="text"
                                        className={
                                            !props.teacher.schoolNameError
                                                ? "form-control"
                                                : "form-control  is-invalid"
                                        }
                                        defaultValue={props.teacher?.schoolName}
                                        {...register("schoolName")}
                                        placeholder="School Name"
                                    />
                                    {props.teacher.schoolNameError && (
                                        <div className="invalid-feedback">
                                            {props.teacher.schoolNameError}
                                        </div>
                                    )}
                                </div>

                                <div className="medical-group">
                                    <label className="form-label">School District</label>
                                    <input
                                        type="text"
                                        className={
                                            !props.teacher.schoolDistrictError
                                                ? "form-control"
                                                : "form-control  is-invalid"
                                        }
                                        defaultValue={props.teacher?.schoolDistrict}
                                        {...register("schoolDistrict")}
                                        placeholder="School District"
                                    />
                                    {props.teacher.schoolDistrictError && (
                                        <div className="invalid-feedback">
                                            {props.teacher.schoolDistrictError}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="edit__form">
                                <div className="medical-group">
                                    <label className="form-label">School Phone</label>
                                    <PatternFormat
                                        format="(###)-###-####"
                                        name="schoolContactNo"
                                        defaultValue={props.teacher?.schoolContactNo || ""}
                                        className={
                                            !props.teacher.schoolContactNoError
                                                ? "form-control"
                                                : "form-control  is-invalid"
                                        }
                                        placeholder={"School Phone"}
                                        // readOnly={props.isReadOnly ? true : false}
                                        getInputRef={register}
                                    />
                                    {props.teacher.schoolContactNoError && (
                                        <div className="invalid-feedback">
                                            {props.teacher.schoolContactNoError}
                                        </div>
                                    )}
                                </div>

                                <div className="medical-group">
                                    <label className="form-label">Email</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        defaultValue={props.teacher?.tEmailId}
                                        placeholder="Email"
                                        readOnly={true}
                                        {...register("tEmailId")}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {props.designation === Role.Respondent && (
                        <div>
                            <div className="edit__form">
                                <div className="medical-group">
                                    <label className="form-label">First Name</label>
                                    <input
                                        type="text"
                                        className={
                                            true
                                                ? "form-control"
                                                : "form-control  is-invalid"
                                        }
                                        defaultValue={props.respondent?.firstName}
                                        {...register("rFirstName")}
                                        placeholder="First Name"
                                    />
                                    {false && (
                                        <div className="invalid-feedback">
                                            {props.teacher.tFirstNameError}
                                        </div>
                                    )}
                                </div>
                                <div className="medical-group">
                                    <label className="form-label">Last Name</label>
                                    <input
                                        type="text"
                                        className={
                                            true
                                                ? "form-control"
                                                : "form-control  is-invalid"
                                        }
                                        defaultValue={props.respondent?.lastName}
                                        {...register("rLastName")}
                                        placeholder="Last Name"
                                    />
                                    {false && (
                                        <div className="invalid-feedback">
                                            {props.teacher.tLastNameError}
                                        </div>
                                    )}
                                </div>
                                <div className="medical-group">
                                    <label className="form-label">Email</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        defaultValue={props.respondent?.email}
                                        placeholder="Email"
                                        readOnly={true}
                                        {...register("rEmailId")}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </section>
            </Modal>
        </header>
    );
};
//OidcLogout
export default TopHeader;
