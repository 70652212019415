import { HttpWrapper, AppConfig } from "../../../core";

export class DevelopmentProfileDetailService {
    private wrapper: HttpWrapper;
    constructor() {
      this.wrapper = new HttpWrapper();
    }
      getDevelopmentProfileDetail(patientId: string) {
        return this.wrapper.get<any>(
          AppConfig.apiEndpoint +
            "patientDetail/development-profile-detail/" +
            patientId
        );
      }
}