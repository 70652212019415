import React, { Component, Fragment } from "react";
import Heading from "../../components/heading";
import { NavLink } from "react-router-dom";
import { Button } from "antd";
import ChildGuardianInfo from "../../components/childGuardianInfo";
import Tabs from "../../components/tabs";
import generalInfo from "../../assets/images/svg-icons/general-info.svg";
import patient from "../../assets/images/svg-icons/smh_gray1.svg";
import family from "../../assets/images/svg-icons/family.svg";
import birth from "../../assets/images/svg-icons/birth.svg";
import referral from "../../assets/images/svg-icons/referral.svg";
import medical from "../../assets/images/svg-icons/medical.svg";
import development from "../../assets/images/svg-icons/development.svg";
import behaviour from "../../assets/images/svg-icons/behaviour.svg";
import therapy from "../../assets/images/svg-icons/therapy.svg";
import psychology from "../../assets/images/svg-icons/psychology.svg";
import diagnosis from "../../assets/images/svg-icons/diagnosis.svg";
import parentMissingHover from "../../assets/images/svg-icons/smh_white1.svg";
import birthIcon from "../../assets/images/svg-icons/smh_white2.svg";
import referralIcon from "../../assets/images/svg-icons/smh_white3.svg";
import medicalIcon from "../../assets/images/svg-icons/smh_white5.svg";
import developmentIcon from "../../assets/images/svg-icons/smh_white6.svg";
import behaviourIcon from "../../assets/images/svg-icons/smh_white7.svg";
import therapyIcon from "../../assets/images/svg-icons/smh_white8.svg";
import physcoIcon from "../../assets/images/svg-icons/smh_white9.svg";
import diagnosisIcon from "../../assets/images/svg-icons/smh_white10.svg";
import MedicalFamilyForm from "../../components/medical/family";
import GeneralForm from "../../components/medical/generalForm";
import "./styles.scss";
import ContentCard from "../../components/contentCard";
import MedicationStatus from "../../components/medicationStatus";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { PatientService } from "../../components/patient/services/patient.service";
import { PatientsListByParentIdModel } from "../../components/patient/models/patients-list-By-parentId.model";
import { GetUserInfo, setCookie } from "../../shared/helpers";
import { AppConfig, HttpResponse } from "../../core";
import MedicalBirthHistory from "../../components/medical/birthHistory";
import MedicalHistory from "../../components/medical/medicalHistory";
import MedicalDevelopment from "../../components/medical/development";
import MedicalBehaviour from "../../components/medical/behaviour";
import MedicalTherapy from "../../components/medical/therapy";
import Psychology from "../../components/medical/psychology";
import Diagnosis from "../../components/medical/diagnosis";
import Referral from "../../components/medical/referral";
import PsychologocialTesting from "../../components/medical/psychology";
import PageTitleSetter from "../../shared/pageTitleSetter";
import fillingForm from "../../assets/images/svg-icons/filling-system.svg";
import { AuthService } from "../../components/auth/services/auth.service";
import withRouter from "../../shared/withRouter/withRouter";

interface IState {
  tab: number|null;
  percentage: number;
  isLoading: boolean;
  patientsListByParentIdModel: PatientsListByParentIdModel;
  id: "";
  isMedicalSocialHistoryFormSubmitted: boolean;
  isModalVisible:boolean;
}

export class MedicalSocialHistory extends Component<any, IState> {
  private patientService: PatientService;
  private service: AuthService;
  isComponentMounted: boolean = false;
  patientId: string;
  parentId: string;
  changeTab(tab: any) {
    this.setState({
      tab,
    });
  }
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.patientId = "";
    this.parentId = "";
    this.patientService = new PatientService();
    this.service = new AuthService();
    this.changeTab = this.changeTab.bind(this);
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      tab: null,
      percentage: 75,
      patientsListByParentIdModel: {} as PatientsListByParentIdModel,
      id: "",
      isMedicalSocialHistoryFormSubmitted: false,
      isModalVisible:false,
    };

    return initialState;
  }

  componentDidUpdate() {
    if (this.props.params.id && this.state.id != this.props.params.id) {
      this.setState({ id: this.props.params.id });
    }
  }
  componentDidMount() {
    // todo
   // window.addEventListener("beforeunload", function (e) {
      // Cancel the event
      //e.preventDefault();
   /// });
   // this.showModal();
    //calll modal here
  //  window.addEventListener('beforeunload',this.myButtonClickHandler);
    this.isComponentMounted = true;
    this.loadPatientList(true);
  }



  setIsModalVisible=(visible: boolean)=>{
    this.setState({
      isModalVisible:visible
    })
  }
   showModal = () => {
    this.setIsModalVisible(true);
  };

   handleOk = () => {
    this.setIsModalVisible(false);
  };

   handleCancel = () => {
    this.setIsModalVisible(false);
  };
  stayHere =()=>{
    this.handleOk();
  }
  leavePage = (event)=>{
   // fetch current event
    localStorage.clear();
    // set cookie expiry time to now, so browser will automatically clear the cookie
    setCookie(AppConfig.refreshTokenKey, '', -1);
    this.service.logOut();
  };

  
 
  isFormSave = () => {
    this.loadPatientList();
  };
  

  loadPatientList(isNotRender?:any) {
    let parentInfo = GetUserInfo();
    //console.log(parentInfo.userId)
    this.setLoading(true);
    this.patientService
      .getPatientsListByParentId(parentInfo.userId)
      .then((res: HttpResponse<PatientsListByParentIdModel>) => {
        if (res && res.result) {
          this.setState({ patientsListByParentIdModel: res.result });
          if (res.result.patientDetails.length > 0) {
            if(!res.result.patientDetails[0].isMedicalSocialHistoryFormSubmitted && isNotRender){
              this.setState({tab:0});
            }
            this.patientId = res.result.patientDetails[0].id;
            this.parentId = res.result.parentId;
            this.setState({
              isMedicalSocialHistoryFormSubmitted:
                res.result.patientDetails[0].isMedicalSocialHistoryFormSubmitted,
            });
              if (res.result.patientDetails[0].isMedicalSocialHistoryFormSubmitted) {

                  this.props.navigate("/medical/childWeeklySeverityUpdate/" + this.patientId);
              }
              else {
                  this.props.navigate("/medical/medicalhistory/" + res.result.patientDetails[0].id);
              }
          } else {
            console.log("no patients list parent id loaded")
            this.props.navigate("/medical/medicalhistory/");
          }
        }
      })
      // .catch((ex) => {console.log("Exception Caught")});
  }

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }
  render() {
    const { tab } = this.state;
    let options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    };
    return (
      <Fragment>
        <div>
          <PageTitleSetter title="Medical-Social Form" />
          <div className="child__status">
            {this.state.patientsListByParentIdModel?.patientDetails?.map((patient, index) => {
              return (
                <ContentCard
                  key={index}
                  styleName="childTab__status remove-cursor"
                  contents={[
                    <div
                      className=""
                      onClick={() => {
                        console.log("button clicked")
                        this.props.navigate("/medical/medicalhistory/" + patient.id);
                      }}
                    >
                      {/* <div className="statusInfo__cover"> */}
                      <div className="row justify-content-between">
                        <div className="statusInfo__cover col">
                        <div className="user-details">
                          <span className="name_wrap">{patient.initials}</span>
                          <p className="ccfChild_username">
                            {patient.lastName + ","} {patient.firstName} <br />{" "}
                            {/* <span>
                              {patient.age}, {patient.genderText}
                            </span> */}
                            <span>MRN:{" "+patient.mr_No}</span> <br />
                            <span>
                              Date of Birth: {new Date(patient.dateOfBirth ?? "").toLocaleDateString('en',options)},{" "} 
                              {patient.genderText}{" "}
                            </span>
                          </p>
                        </div>
                      </div>
                      
                      <div className="statusInfo__cover col text-end">
                        {this.state.patientsListByParentIdModel?.patientDetails?.length < 3 && (
                          // <div className="status__rightside">
                          <div className="text-center">
                            <CircularProgressbar
                              value={patient.completePercentage ?? 0}
                              text={`${patient.completePercentage ?? 0}%`}
                              styles={buildStyles({
                                strokeLinecap: "butt",
                                textColor: "var(--color-slaty)",
                                pathColor: "var(--color-parrot)",
                                trailColor: "var(color-slaty)",
                              })}
                              className="graphStroke"
                            />
                            <ChildGuardianInfo
                              designation=""
                              name={`${patient.completePercentage ?? 0}%` + " " + "Completed"}
                              email={patient.completePercentage === 100 ? "" : (
                                "Complete Remaining" +
                                " " +
                                (100 - (patient.completePercentage ?? 0)) +
                                "%"
                              )}
                              guardianStyle="guardianStyle"
                            />
                            {/* {patient.completePercentage === 50 ? "ashu" : "complete remaining 0"} */}
                            {/* <p>
                              {`${patient.completePercentage ?? 0}%`} Completed
                            </p> */}
                            {/* <div className="childstatus__percent"> */}
                            {/* <p>
                              Complete Remaining{" "}
                              {100 - (patient.completePercentage ?? 0)}%
                            </p> */}
                            {/* </div> */}
                            {/* <i
                             className="fa fa-check-square-o checkIcon"
                             aria-hidden="true"
                           ></i> */}
                          </div>
                          // </div>
                        )}
                      </div>
                      </div>
                      
                    </div>,
                  ]}
                />
              );
            })}
          </div>
          {!this.state.isMedicalSocialHistoryFormSubmitted && tab!=null && (
            <div className="medical__wrapper">
              <Heading
                heading="Patient Medical-Social History"
                subHeading="ADHD form for evaluation and treatment"
                styleName="medicalHeading__style"
              />
              <div className="medical__tabs d-none d-xl-flex">
                <Tabs
                  styleName="profileTab"
                  text="General"
                  steps="Step 1 of 10"
                  stepsAct=""
                  fullText="General Information"
                  familyTextName=""
                  image={patient}
                  activeImage={generalInfo}
                  tabChange={() => this.changeTab(0)}
                  selected={tab === 0}
                />

                <Tabs
                  styleName="profileTab"
                  text="Family"
                  steps="Step 2 of 10"
                  stepsAct=""
                  fullText="Family Background"
                  image={family}
                  familyTextName=""
                  activeImage={parentMissingHover}
                  tabChange={() => this.changeTab(1)}
                  selected={tab === 1}
                />
                <Tabs
                  styleName="profileTab"
                  text="Birth"
                  steps="Step 3 of 10"
                  stepsAct=""
                  fullText="Birth History"
                  image={birth}
                  familyTextName=""
                  activeImage={birthIcon}
                  tabChange={() => this.changeTab(2)}
                  selected={tab === 2}
                />
                <Tabs
                  styleName="profileTab"
                  text="Referral"
                  steps="Step 4 of 10"
                  stepsAct=""
                  fullText="Referral Concerns"
                  image={referral}
                  familyTextName=""
                  activeImage={referralIcon}
                  tabChange={() => this.changeTab(3)}
                  selected={tab === 3}
                />
                <Tabs
                  styleName="profileTab"
                  text="Medical"
                  steps="Step 5 of 10"
                  stepsAct=""
                  fullText="Medical History"
                  image={medical}
                  familyTextName=""
                  activeImage={medicalIcon}
                  tabChange={() => this.changeTab(4)}
                  selected={tab === 4}
                />
                <Tabs
                  styleName="profileTab"
                  text="Development"
                  steps="Step 6 of 10"
                  stepsAct=""
                  fullText="Development Profile"
                  image={development}
                  familyTextName=""
                  activeImage={developmentIcon}
                  tabChange={() => this.changeTab(5)}
                  selected={tab === 5}
                />
                <Tabs
                  styleName="profileTab"
                  text="Behavior"
                  steps="Step 7 of 10"
                  stepsAct=""
                  fullText="Behavior Profile"
                  image={behaviour}
                  familyTextName=""
                  activeImage={behaviourIcon}
                  tabChange={() => this.changeTab(6)}
                  selected={tab === 6}
                />
                <Tabs
                  styleName="profileTab"
                  text="Therapy"
                  steps="Step 8 of 10"
                  stepsAct=""
                  fullText="Therapy/Counseling"
                  image={therapy}
                  familyTextName=""
                  activeImage={therapyIcon}
                  tabChange={() => this.changeTab(7)}
                  selected={tab === 7}
                />
                <Tabs
                  styleName="profileTab"
                  text="Psychology"
                  steps="Step 9 of 10"
                  stepsAct=""
                  fullText="Psychological Testing"
                  image={psychology}
                  familyTextName=""
                  activeImage={physcoIcon}
                  tabChange={() => this.changeTab(8)}
                  selected={tab === 8}
                />
                <Tabs
                  styleName="profileTab"
                  text="Diagnosis"
                  steps="Step 10 of 10"
                  stepsAct=""
                  fullText="Previous Diagnosis"
                  image={diagnosis}
                  familyTextName=""
                  activeImage={diagnosisIcon}
                  tabChange={() => this.changeTab(9)}
                  selected={tab === 9}
                />
              </div>
              {tab === 0 && (
                <div>
                  <GeneralForm
                    id={this.state.id}
                    isFormSave={this.isFormSave}
                    nextTabChange={() => this.changeTab(1)}
                  />
                </div>
              )}
              {tab === 1 && (
                <MedicalFamilyForm
                  tab={1}
                  isFormSave={this.isFormSave}
                  patientId={this.patientId}
                  parentId={this.parentId}
                  parentTab={this.changeTab}
                  nextTabChange={() => this.changeTab(2)}
                />
              )}
              {tab === 2 && (
                <MedicalBirthHistory
                  tab={2}
                  isFormSave={this.isFormSave}
                  patientId={this.patientId}
                  parentId={this.parentId}
                  parentTab={this.changeTab}
                  nextTabChange={() => this.changeTab(3)}
                />
              )}
              {tab === 3 && (
                <Referral
                  tab={3}
                  isFormSave={this.isFormSave}
                  patientId={this.patientId}
                  parentId={this.parentId}
                  parentTab={this.changeTab}
                  previousTabChange={() => this.changeTab(2)}
                  nextTabChange={() => this.changeTab(4)}
                />
              )}
              {tab === 4 && (
                <MedicalHistory
                  tab={4}
                  isFormSave={this.isFormSave}
                  patientId={this.patientId}
                  parentId={this.parentId}
                  parentTab={this.changeTab}
                  nextTabChange={() => this.changeTab(5)}
                />
              )}

              {tab === 5 && (
                <MedicalDevelopment
                  tab={5}
                  isFormSave={this.isFormSave}
                  patientId={this.patientId}
                  parentTab={this.changeTab}
                  nextTabChange={() => this.changeTab(6)}
                />
              )}
              {tab === 6 && (
                <MedicalBehaviour
                  tab={6}
                  isFormSave={this.isFormSave}
                  patientId={this.patientId}
                  parentId={this.parentId}
                  parentTab={this.changeTab}
                  nextTabChange={() => this.changeTab(7)}
                />
              )}
              {tab === 7 && (
                <MedicalTherapy
                  tab={7}
                  isFormSave={this.isFormSave}
                  patientId={this.patientId}
                  parentTab={this.changeTab}
                  nextTabChange={() => this.changeTab(8)}
                />
              )}
              {tab === 8 && (
                <PsychologocialTesting
                  tab={8}
                  isFormSave={this.isFormSave}
                  patientId={this.patientId}
                  parentTab={this.changeTab}
                  nextTabChange={() => this.changeTab(9)}
                />
              )}
              {tab === 9 && (
                <Diagnosis
                  tab={9}
                  patientId={this.patientId}
                  parentTab={this.changeTab}
                  nextTabChange={this.isFormSave}
                  isFormSave={this.isFormSave}
                />
              )}
            </div>
          )}
          {this.state.isMedicalSocialHistoryFormSubmitted && (
            <div className="text-center">
              <h3 className="heading">
                Your form has been submitted successfully!
                <br /> Someone from the clinic will connect with you shortly.
              </h3>
             
            </div>
          )}

{/* <div className="alert__modal">
        <Modal
          title="Basic Modal"
          visible={this.state.isModalVisible}
       onOk={this.handleOk}
           onCancel={this.handleCancel}
          className="alert__modal"
        >
          <div className="alert__content">
            <img src={fillingForm} alt="filling-form" />
            <div className="alert__innerContent">
              <h3>
                <strong>All the form fields filled by you has been auto saved.</strong>
              </h3>
              <p>You will be redirected to this page again on another visit.</p>
              <div className="alert__msgButton">
                <p>Are you sure you want to leave this page?</p>
                <Button type="primary" size="large" className="btn_reset right" onClick={this.leavePage}>
                  Leave This Page
                </Button>
                <Button type="primary" size="large" className="btn_add right" onClick={this.stayHere}>
                  Stay Here
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div> */}
        </div>
       
      </Fragment>
    );
  }
}

export default withRouter(MedicalSocialHistory);
