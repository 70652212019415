import { AppConfig, HttpWrapper } from "../../core";
import { AddPerenatalPeriodPostModel } from "../../models/birthHistory/perenatalPeriod/add-prenatal-period-post";
import { PerinatalPeriodModel } from "../../models/birthHistory/perinatalPeriod/perinatal-period.model";
import { AddPostnatalPeriodModel } from "../../models/birthHistory/postNatal/add-postnatal-period.model";
import { PregnancyModel } from "../../models/birthHistory/pregnancy.model";

export class BirthHistoryService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }
  getPerenatalPeriod(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint + "birthhistory/prenatal-period/detail/" + patientId
    );
  }

  postPerenatalPeriod(primaryCarTaker: AddPerenatalPeriodPostModel) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "birthhistory/prenatal-period/create",
      primaryCarTaker
    );
  }

  postPregnancy(patient: PregnancyModel) {
    return this.wrapper.post<any>(AppConfig.apiEndpoint + "birthhistory/pregnacy/create", patient);
  }
  getPregnancyDetail(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint + "birthhistory/pregnacy/detail/" + patientId
    );
  }
  getPostnatalPeriod(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint + "birthhistory/postnatal-period/detail/" + patientId
    );
  }

  postPostnatalPeriod(primaryCarTaker: AddPostnatalPeriodModel) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "birthhistory/postnatal-period/create",
      primaryCarTaker
    );
  }
  getPerinatalPeriod(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint + "birthhistory/perinatal-period/detail/" + patientId
    );
  }

  postPerinatalPeriod(perinatalPeriodModel: PerinatalPeriodModel) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint + "birthhistory/perinatal-period/create",
      perinatalPeriodModel
    );
  }
  getBirthHistoryDetail(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint + "patientDetail/birth-history-detail/" + patientId
    );
  }
  getBirthHistoryStepStatus(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint + "birthhistory/getBirthHistoryStepStatus/" + patientId
    );
  }
}
