import React from "react";
import Heading from "../heading";
import { Button } from "antd";
import line from "../../assets/images/svg-icons/line.svg";
import "../../components/improvementModal/style.css"
import { ImprovementType } from "../../shared/enums/clinicianWeeklyData/improvement-type";
import { ClinicianWeeklyDataType } from "../../shared/enums/clinicianWeeklyData/clinician-weekly-data-type";
interface Iprops {
  propName?: any;
  assignWeeklyDataModel?: any;
  clinicianWeeklyData?: any;
  teacherWeeklyData?: any;
  parentWeeklyData?: any;
  weeklyDataUpdateByType?: any;
  weekIndex: number;
  type?: number;
  popOverId?:string;
}
function ImprovementModal(props: Iprops) {
  const improvementClick = (improvementType: number) => {
    if (props.type == ClinicianWeeklyDataType.AddImprovements) {
        props.clinicianWeeklyData.lstClinicianAddImprovementsWeeklyData[
          props.weekIndex
        ][props.propName] = improvementType;
        props.assignWeeklyDataModel({
          clinicianWeeklyData: props.clinicianWeeklyData,
          clinicianWeeklyDataType: ClinicianWeeklyDataType.AddImprovements,
        });
      }
      if(props.popOverId){
        document.getElementById(props.popOverId)?.click();
    }
  };
  return (
    <div className="sev__modal">
      <Heading heading="Improvement scale" styleName="weekwise__head" />
      <img src={line} alt="line" />
      <p className="sevr__para">Choose the Improvement</p>
      <Button
        type="primary"
        size="middle"
        className="add__improvement"
        onClick={() => improvementClick(ImprovementType.VerymuchImp)}
      >
        Very Much Imp (1)
      </Button>
      <Button
        type="primary"
        size="middle"
        className="add__improvement"
        onClick={() => improvementClick(ImprovementType.Improved)}
      >
        Improved (2)
      </Button>
      <Button
        type="primary"
        size="middle"
        className="add__improvement"
        onClick={() => improvementClick(ImprovementType.MinimallyImp)}
      >
        Minimally Imp (3)
      </Button>
      <Button
        type="primary"
        size="middle"
        className="add__improvement"
        onClick={() => improvementClick(ImprovementType.Unchanged)}
      >
        Unchanged (4)
      </Button>
      <Button
        type="primary"
        size="middle"
        className="add__improvement add__severity__orangeColor"
        onClick={() => improvementClick(ImprovementType.MinimallyWorse)}
      >
        Minimally Worse (5)
      </Button>
      <Button
        type="primary"
        size="middle"
        className="add__improvement add__severity__redColor"
        onClick={() => improvementClick(ImprovementType.Worse)}
      >
        Worse (6)
      </Button>
      <Button
        type="primary"
        size="middle"
        className="add__improvement add__severity__redColor"
        onClick={() => improvementClick(ImprovementType.VeryMuchWorse)}
      >
        Very Much Worse (7)
      </Button>
    </div>
  );
}
export default ImprovementModal;
