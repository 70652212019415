import { AppConfig, HttpWrapper } from "../../../core";
import { AuthResponseModel } from "../models/auth.response.model";
import { AuthModel, UserDetailResponseModel } from "../models";

export class AuthService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getAccessToken(data: AuthModel) {
    const searchParams = Object.keys(data)
      .map((key) => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
      })
      .join("&");

    return this.wrapper.post<AuthResponseModel>(
      AppConfig.identityUrl + "jwt/token",
      data,
      {
        method: "post",
        body: searchParams,
      },
      "application/x-www-form-urlencoded"
    );
  }

  getGetUserInfo() {
    return this.wrapper.get<UserDetailResponseModel>(
      AppConfig.apiEndpoint + "identity"
    );
  }

  getAccessTokenByRefreshToken(data: AuthModel) {
    const searchParams = Object.keys(data)
      .map((key) => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
      })
      .join("&");
    return this.wrapper.post<AuthResponseModel>(
      AppConfig.identityUrl + "jwt/token",
      data,
      {
        method: "post",
        body: searchParams,
      },
      "application/x-www-form-urlencoded"
    );
  }
  logOut() {
    let returnUrl = "returnUrl=" + AppConfig.redirect_Uri;
    window.location.href =
      AppConfig.identityUrl + "account/OidcLogout" + "?" + returnUrl;
  }
  changePassword(email:string){
    let param = "email=" + email +"&returnUrl=" + AppConfig.redirect_Uri;
    window.location.href =
      AppConfig.identityUrl + "account/ChangePassword" + "?" + param;
  }
}
