import React, { Fragment } from "react";
import { HttpResponse } from "../../../../core";
import { ReferralConcernPostModel } from "../../../../models/referralConcern/referral-concern-post.model";
import { ReferralConcernService } from "../../../../services/referralConcern/referral-concern.service";
import { ShowErrorMessage, ShowSuccessMessage } from "../../../../shared/helpers";
import { ReferralConcernForm } from "./referralConcernForm";
import "../../styles.css";

interface IState {
  isLoading: boolean;
  isReadonly: boolean;
  reRenderForm: boolean;
  referralConcernPostModel: ReferralConcernPostModel;
  patientId: string;
  isFormSave?: Function;
}
export class MedicalReferral extends React.Component<any, IState> {
  private referralConcernService: ReferralConcernService;
  isComponentMounted: boolean = false;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.referralConcernService = new ReferralConcernService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      isReadonly: false,
      referralConcernPostModel: {} as ReferralConcernPostModel,
      reRenderForm: false,
      patientId: this.props.patientId,
    };
    return initialState;
  }

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }

  componentDidMount() {
    this.loadData();
    window.scroll(0, 0);
  }

  loadData(isAutoSave?: any) {
    if (this.state.patientId) {
      this.referralConcernService
        .getReferralConcern(this.state.patientId)
        .then((res: HttpResponse<ReferralConcernPostModel>) => {
          if (res && res.result) {
            if (isAutoSave) {
              let rc = this.state.referralConcernPostModel;
              rc.id = res.result.id;
              rc.patientId = res.result.patientId;
              this.setState({
                referralConcernPostModel: rc,
              });
            } else {
              let referralConcern: ReferralConcernPostModel = {
                id: res.result.id,
                patientId: res.result.patientId,
                reading: res.result.reading,
                oppositionalOrDefiant: res.result.oppositionalOrDefiant,
                poorPeerRelations: res.result.poorPeerRelations,
                spelling: res.result.spelling,
                hyperactive: res.result.hyperactive,
                anxiousness: res.result.anxiousness,
                math: res.result.math,
                aggressiveOrActingout: res.result.aggressiveOrActingout,
                withdrawnOrDepressed: res.result.withdrawnOrDepressed,
                handwriting: res.result.handwriting,
                impulsive: res.result.impulsive,
                inattentionOrDistractiability: res.result.inattentionOrDistractiability,
                speechOrLanguage: res.result.speechOrLanguage,
                disruptiveInGroups: res.result.disruptiveInGroups,
                lowSelfEsteem: res.result.lowSelfEsteem,
                visualPerceptualSkills: res.result.visualPerceptualSkills,
                grossMotorSkills: res.result.grossMotorSkills,
                awkwardOrClumsy: res.result.awkwardOrClumsy,
                writtenExpression: res.result.writtenExpression,
                fineMotorSkills: res.result.fineMotorSkills,
                lowFrustrationTolerance: res.result.lowFrustrationTolerance,
                comments: res.result.comments,
              };
              this.setState({
                referralConcernPostModel: referralConcern,
              });
            }
          }
        })
        .catch((ex) => {});
    }
  }

  postData = (data: any) => {
    let referralConcern = data.referralConcernPostModel;
    if (this.state.isLoading) {
      return;
      }
      if (data.isAutoSave) {
          this.setLoading(false);
      }
      else {
          this.setLoading(true);
      }
    this.referralConcernService
      .postReferralConcern(referralConcern)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
          this.state.referralConcernPostModel.id=res.result;
          if (!data.isAutoSave) {
            ShowSuccessMessage("Data Saved Successfully.");
            this.props.nextTabChange();
          }
        }
        this.setLoading(false);
        this.props.isFormSave();
        if (!data.isAutoSave) {
          this.loadData(data.isAutoSave);
        }
      })
      .catch((ex) => {
        this.setLoading(false);
        ShowErrorMessage("Error in creating referral concern.");
      });
  };
  previousTabChange = (tab: any) => {
    this.props.previousTabChange(tab);
  };

  assignModel = (data: any) => {
    this.setState(
      {
        referralConcernPostModel: data.referralConcernPostModel,
        reRenderForm: true,
      },
      () => this.setState({ reRenderForm: false })
    );
  };

  render() {
    return (
      <Fragment>
        {!this.state.reRenderForm && (
          <ReferralConcernForm
            onSubmit={this.postData}
            isSaving={this.state.isLoading}
            referralConcernPostModel={this.state.referralConcernPostModel}
            patientId={this.props.patientId}
            assignModel={this.assignModel}
            isReadOnly={this.state.isReadonly}
            previousTabChange={this.previousTabChange}
          />
        )}
      </Fragment>
    );
  }
}
