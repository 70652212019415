import React, { Component, Fragment } from "react";
import { HttpResponse } from "../../../core/HttpResponse";
import { AddMedicationModel } from "../../../models/medication/add-medication.model";
import { MedicationListResponseModel } from "../../../models/medication/medication-list-response.model";
import { MedicationListViewModel } from "../../../models/medication/medication-list-view.model";
import { MedicationService } from "../../../services/medication";
import { ShowErrorMessage, ShowSuccessMessage } from "../../../shared/helpers";
import { Loader } from "../../../shared/loaders";
import { DropdownItemModel } from "../../../shared/models/dropdown.model";
import { AddPatientModel } from "../models/add-patient";
import { AddPatientMedicationModel } from "../models/add-patient-medication.model";
import { AddPhysicianModel } from "../models/add-physician";
import { PatientDetailModel } from "../models/patient-detail.model";
import { PatientMedicationDetail } from "../models/patient-medication-detail";
import { PatientService } from "../services";
import { PatientMedicationService } from "../services/patient.medication.service";
import { PhysicianService } from "../services/physician.service";
import { AddPhysicianMedicationForm } from "./addPhysicianMedicationForm";

interface IState {
  isLoading: boolean;
  tab: number;
  isReadonly: boolean;
  reRenderForm: boolean;
  physician: AddPhysicianModel;
  medication: AddPatientMedicationModel;
  medicationListViewModel: MedicationListViewModel[];
  medicationInfoListViewModel: AddMedicationModel[];
  medicationTypeModel: DropdownItemModel[];
  dosageList: string[];
  formatList: string[];
  brandList: string[];
  genericList: string[];
  familyList: string[];
  patientId: string;
  filterList: AddMedicationModel[];
  selectedFamily: string;
  selectedBrandName: string;
  selectedFormat: string;
  selectedMedicationType: string;
  selectedDosage: string;
  selectedGenericName: string;
}
export class PhysicianMedication extends React.Component<any, IState> {
  private physicianService: PhysicianService;
  private patientService: PatientService;
  private patientMedicationService: PatientMedicationService;
  private medicationService: MedicationService;
  isComponentMounted: boolean = false;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.physicianService = new PhysicianService();
    this.patientService = new PatientService();
    this.patientMedicationService = new PatientMedicationService();
    this.medicationService = new MedicationService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      tab: 1,
      isReadonly: false,
      physician: {} as AddPhysicianModel,
      medication: {} as AddPatientMedicationModel,
      medicationListViewModel: {} as MedicationListViewModel[],
      reRenderForm: false,
      patientId: this.props.patientId,
      medicationInfoListViewModel: [] as AddMedicationModel[],
      medicationTypeModel: [],
      dosageList: [],
      formatList: [],
      familyList: [],
      brandList: [],
      filterList: [],
      genericList: [],
      selectedBrandName: "",
      selectedFormat: "",
      selectedMedicationType: "",
      selectedFamily: "",
      selectedDosage: "",
      selectedGenericName: "",
    };
    return initialState;
  }

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
      //buttonHide:loading
    });
  }
  onEditClick = () => {
    this.setState({ isReadonly: false });
  };
  componentDidMount() {
    this.loadInitialgetMedicationTypeData();
    this.loadPhysicianInfo();
    this.loadData();
    //this.loadMedicineList();
    this.loadMedicationInfoList();
  }

  loadMedicineList = () => {
    this.patientMedicationService
      .getMedicineList()
      .then((res: HttpResponse<MedicationListViewModel[]>) => {
        if (res && res.result) {
          this.setState({ medicationListViewModel: res.result });
        }
      })
      .catch((ex) => {
        this.setLoading(false);
      });
  };
  loadInitialgetMedicationTypeData = () => {
    this.medicationService
      .getMedicationType()
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res && res.result) {
          this.setState({
            medicationTypeModel: res.result,
          });
        }
      });
  };
  loadMedicationInfoList = () => {
    let family;
    this.patientMedicationService
      .getMedicineInfoList()
      .then((res: HttpResponse<AddMedicationModel[]>) => {
        if (res && res.result) {
          this.setState({ medicationInfoListViewModel: res.result });
           
            family = res.result
              .map((item) => item.family)
              .filter((value, index, self) => self.indexOf(value) === index);
          
          let model: string[] = [];
          family?.map((x) => {
            model?.push(x);
          });
          this.setState({
            familyList: model,
          });
        }
      })
      .catch((ex) => {
        this.setLoading(false);
      });
  };

  loadData() {
    if (this.props.patientId) {
      //this.setLoading(true);
      this.patientMedicationService
        .getmedicationByPatientId(this.props.patientId)
        .then((res: HttpResponse<PatientMedicationDetail>) => {
          if (res && res.result) {
            let medication: AddPatientMedicationModel = {
              isActive: res.result.isActive,
              patientId: res.result.patientId,
              patientTrialId: res.result.patientTrialId,
              trialNoOfWeeks: res.result.trialNoOfWeeks,
              trialStartDate: res.result.trialStartDate,
              trialEndDate: res.result.trialEndDate,
              createPatientVisitModels: res.result.patientVisitDetails,
              createPatientTrialWeekModels: res.result.patientTrialWeekDetails,
              diagnosisCodes: res.result.patientTrialDiagnosisCodes,
              patientTrialStyle: res.result.patientTrialStyle,
              isPatientTrialStart: res.result.isPatientTrialStart,
            };
            this.setState({
              medication: medication,
            });
            this.setLoading(false);
          }
        })
        .catch((ex) => {
          this.setLoading(false);
        });
    }
  }
  loadPhysicianInfo() {
    if (this.props.patientId) {
      this.physicianService
        .getPhysician(this.props.patientId)
        .then((res: HttpResponse<any>) => {
          if (res && res.result) {
            let physician: AddPhysicianModel = {
              id: res.result.physicianId,
              pLastName: res.result.physicianLastName,
              speciality: res.result.speciality,
              hospitalName: res.result.hospitalName,
              pFirstName: res.result.physicianFirstName,
            };
            this.setState({
              physician: physician,
              isReadonly: true,
            });
          }
        })
        .catch((ex) => {});
    }
  }
  postPatientMedication = (medication: AddPatientMedicationModel) => {
    this.patientMedicationService
      .postMedication(medication)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
          if (res.result) {
            this.loadData();
          }
        }
      })
      .catch((ex) => {
       // this.loadData();
        ShowErrorMessage("Error in creating medication.");
        this.setLoading(false);
        this.props.tabChange();
        this.setState(
          {
            reRenderForm: true,
          },
          () => this.setState({ reRenderForm: false })
        );
      });
  };
  postData = (data: any) => {
    let physician = data.physicianModel;
    physician.id = this.state.physician.id;
    physician.patientId = this.props.patientId;
    this.setLoading(true);
    this.physicianService
      .postPhysician(physician)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
          if (data.medicationModel != undefined) {
            this.postPatientMedication(data.medicationModel);
          }
          if(!data.isAutoSave){
            if (physician.id != null && physician.id != "") {
              ShowSuccessMessage("Data Updated Successfully.");
            } else {
              ShowSuccessMessage("Data Saved Successfully.");
            }
          }
          physician.id = res.result;
          this.setLoading(false);
          if (!data.isAutoSave) {
            if (res.result) {
              this.setState(
                {
                  physician: physician,
                  isReadonly: true,
                  reRenderForm: true,
                },
                () => this.setState({ reRenderForm: false })
              );
            }
          }
        }
      })
      .catch((ex) => {
        ShowErrorMessage("Error in creating physician.");
        this.setLoading(false);
      });
  };
  assignModel = (data: any) => {
    if (data.medication != undefined) {
      this.setState({
        medication: data.medication,
      });
    }
    if (data.physician != undefined) {
      this.setState({
        physician: data.physician,
      });
    }
  };
  assignMedicationTypeModel = (data) => {
    this.setState({
      selectedFamily: data.selectedFamily,
      selectedBrandName: data.selectedBrandName,
      brandList: data.brandList,
      filterList: data.filterList,
      formatList: data.formatList,
      dosageList: data.dosageList,
      selectedFormat: data.selectedFormat,
      selectedMedicationType: data.selectedMedicationType,
      selectedDosage: data.selectedDosage,
      selectedGenericName: data.selectedGenericName,
    });
  };
  deletePatientTrial = () => {
    if (this.props.patientId){
      this.patientMedicationService
      .deletePatientTrial(this.props.patientId)
      .then((res: HttpResponse<any>) => {
        if (res && res.result) {
          if (res.result) {
            this.loadData();
          }
        }
      })
      .catch((ex) => {
       // this.loadData();
        ShowErrorMessage("Error in creating medication.");
        this.setLoading(false);
        this.props.tabChange();
        this.setState(
          {
            reRenderForm: true,
          },
          () => this.setState({ reRenderForm: false })
        );
      });
    }
  }
  // assignFamliyModel=()=>{
  //   this.setState({
  //     medication: data.medication,
  //   });
  // }
  render() {
    return (
      <Fragment>
        {!this.state.reRenderForm && (
          <AddPhysicianMedicationForm
            isReadOnly={this.state.isReadonly}
            onSubmit={this.postData}
            onEditClick={this.onEditClick}
            onDelete={this.deletePatientTrial}
            isSaving={this.state.isLoading}
            physician={this.state.physician}
            medication={this.state.medication}
            medicationListViewModel={this.state.medicationListViewModel}
            medicationInfoListViewModel={this.state.medicationInfoListViewModel}
            tab={this.state.tab}
            patientId={this.props.patientId}
            assignModel={this.assignModel}
            medicationTypeModel={this.state.medicationTypeModel}
            familyList={this.state.familyList}
            brandList={this.state.brandList}
            genericList={this.state.genericList}
            // assignFamliyModel={this.assignFamliyModel}
            assignMedicationTypeModel={this.assignMedicationTypeModel}
            selectedFamily={this.state.selectedFamily}
            selectedBrandName={this.state.selectedBrandName}
            filterList={this.state.filterList}
            dosageList={this.state.dosageList}
            formatList={this.state.formatList}
            selectedFormat={this.state.selectedFormat}
            selectedMedicationType={this.state.selectedMedicationType}
            selectedDosage={this.state.selectedDosage}
          />
        )}
      </Fragment>
    );
  }
}

export default PhysicianMedication;
