import React, { Component, Fragment } from "react";
import Heading from "../../heading";
import Search from "../../search";
import { Loader } from "../../../shared/loaders";
import whiteCal from "../../../assets/images/svg-icons/weekly_calendarWhite.svg";
import rating from "../../../assets/images/svg-icons/rating_white.svg";
import visit from "../../../assets/images/svg-icons/location_white.svg";
import report from "../../../assets/images/svg-icons/file_white.svg";
import calendar from "../../../assets/images/svg-icons/weekly_calendar.svg";
import expand from "../../../assets/images/svg-icons/expand.svg";
import park from "../../../assets/images/svg-icons/location.svg";
import rate from "../../../assets/images/svg-icons/rating.svg";
import file from "../../../assets/images/svg-icons/file.svg";
import medicalRecordWhite from "../../../assets/images/svg-icons/socialMed-white.svg";
import medicalRecord from "../../../assets/images/svg-icons/socialMed-blue.svg";
import merge from "../../../assets/images/svg-icons/collapse.svg";
import searchGrey from "../../../assets/images/svg-icons/search-list.svg";
import searchBlue from "../../../assets/images/svg-icons/search-b.svg";
import filterGrey from "../../../assets/images/svg-icons/filter-g.svg";
import filterBlue from "../../../assets/images/svg-icons/filter-b.svg";
import "./styles.css";
import "../../medication/styles.css";
import LeftListing from "../../leftListing";
import Tabs from "../../tabs";
import PatientSocialMedicalHistory from "./patientSocialMedicalHistory";
import { PatientService } from "../../patient/services";
import { HttpResponse } from "../../../core";
import { PatientDetailModel } from "../../patient/models/patient-detail.model";
import { PatientListResponseModel } from "../../patient/models/patient-list-response.model";
import { PatientListModel } from "../../patient/models/patient-list.model";
import { PatientListFilterModel } from "../../patient/models/patient-list-filter.model";
import InfiniteScroll from "react-infinite-scroll-component";
import PageTitleSetter from "../../../shared/pageTitleSetter";
import PatientDetailHeader from "./patientSocialMedicalHistory/patientDetailHeader";
import PatientWeeklyUpdate from "./patientWeeklyUpdate";
import ParentTeacherRating from "./ParentTeacherRating";
import { ClinicianWeeklyData } from "../../../models/clinicianWeeklyData/clinician-weekly-data.model";
import { ClinicianWeeklyDataService } from "../../../services/clinicianWeeklyData/clinician-weekly-data.service";
import { PatientMedicationService } from "../../patient/services/patient.medication.service";
import { PatientMedicationDetail } from "../../patient/models/patient-medication-detail";
import { AddPatientMedicationModel } from "../../patient/models/add-patient-medication.model";
import { FinalReportSaveDataModel } from "../../../models/report/final-report-save-data";
import { FinalReportDataService } from "../../../services/report/report-data-service";
import { ShowSuccessMessage } from "../../../shared/helpers";
import BreadcrumSetter from "../../../shared/breadcrumSetter";
import weeklyGrey from "../../../assets/images/svg-icons/weekly-grey.svg";
import managementGrey from "../../../assets/images/svg-icons/management-grey.svg";
import oops from "../../../assets/images/svg-icons/no-result-found.svg";
import FinalReportComponent from "./finalReport/FinalReportComponent";
import { PatientVisitStatusService } from "../../../services/patientVisitStatus/patient-visit-status.service";
import { PatientVisitStatus } from "../../../models/patientVisitStatus/patient-visit-status.model";
import { UserStatus } from "../../../shared/enums/user-status.enum";
import { UserAccountStatus } from "../../../shared/enums/show-user-status.enum";
import { VisitSummary } from "./vsitSummary";
import withRouter from "../../../shared/withRouter/withRouter";
import RespondentRating from "./ParentTeacherRating/RespondentRating";
import Modal from "antd/es/modal/Modal";
import PatientTrialNote from "./patientTrialNote";
import { PatientTrialNotesService } from "../../../services/notes/patient-trial-notes.service";
import { PatientTrialNoteModel } from "../../../models/notes/patientTrialNote";
import { GetPatientTrialNoteModel } from "../../../models/notes/getPatientTrialNoteModel";

interface IState {
  result: PatientListResponseModel | any;
  results: PatientListModel[];
  initials?: string;
  searchText: string;
  isLoading?: boolean;
  totalItems: number;
  scrolling?: boolean;
  pageSize: number;
  currentPage: number;
  pageCount: number;
  tab: number;
  filterTab: number;
  tabChangeFor: string;
  patientId: string;
  reRenderForm: boolean;
  reRenderForm1: boolean;
  isExpandAll: boolean | undefined | null;
  reRenderSocialMedicalHistoryForm: boolean;
  bcColor: string;
  patientDetail: PatientDetailModel;
  isMedicalSocialHistoryFormSubmitted: boolean | undefined | null;
  isListingTypeTeacher: boolean;
  isCnoteOpen: boolean;
  isPTnoteOpen: boolean;
  clinicianWeeklyData: ClinicianWeeklyData;
  medication: AddPatientMedicationModel;
  reportSavedData: FinalReportSaveDataModel;
  isShowModel: boolean;
  isBreadcrumChange: boolean;
  currentUserName: string;
  tabText: string;
  patientVisitStatus?: PatientVisitStatus;
  accountStatus: number;
  patientTrialNote: string;
  isPatientTrialCreated: boolean;
  showNote: boolean;
}

class PatientDashboard extends Component<any, IState> {
  private patientService: PatientService;
  private patientVisitStatusService: PatientVisitStatusService;
  scrollListener: any;
  private clinicianWeeklyDataService: ClinicianWeeklyDataService;
  private patientMedicationService: PatientMedicationService;
  private finalReportDataService: FinalReportDataService;
  isComponentMounted: boolean = false;
  noteSevice: PatientTrialNotesService;
  constructor(props) {
    super(props);

    this.noteSevice = new PatientTrialNotesService();
    this.patientService = new PatientService();
    this.clinicianWeeklyDataService = new ClinicianWeeklyDataService();
    this.patientMedicationService = new PatientMedicationService();
    this.finalReportDataService = new FinalReportDataService();
    this.patientVisitStatusService = new PatientVisitStatusService();
    this.state = {
      tab: 0,
      filterTab: 0,
      tabChangeFor: this.props.params.tab,
      result: {
        collection: [],
        paging: {
          currentPage: 1,
          pageSize: 0,
          totalItems: 0,
          pageCount: 0,
        },
      },
      searchText: "",
      initials: "",
      isLoading: false,
      scrolling: false,
      results: [],
      pageSize: 10,
      currentPage: 1,
      totalItems: 0,
      pageCount: 0,
      patientId: this.props.params.id,
      bcColor: "",
      isExpandAll: null,
      reRenderForm: false,
      reRenderForm1: false,
      reRenderSocialMedicalHistoryForm: false,
      patientDetail: {} as PatientDetailModel,
      isMedicalSocialHistoryFormSubmitted: false,
      isListingTypeTeacher: false,
      isCnoteOpen: false,
      isPTnoteOpen: false,
      clinicianWeeklyData: {} as ClinicianWeeklyData,
      medication: {} as AddPatientMedicationModel,
      reportSavedData: {} as FinalReportSaveDataModel,
      isShowModel: false,
      isBreadcrumChange: false,
      currentUserName: "",
      tabText: "Weekly Update",
      accountStatus: 0,
      patientTrialNote: "",
      isPatientTrialCreated: false,
      showNote: false,
    };
  }

  changeTab(tab: any, text: string) {
    this.setState(
      {
        tab: tab,
        isExpandAll: null,
      },
      () => {
        this.setTabText(text);
      }
    );
  }
  changeTabFilter(filterTab: any) {
    this.setState(
      {
        filterTab,
        accountStatus: 0,
        searchText: "",
        currentPage: 1,
      },
      () => {
        this.loadData();
      }
    );
  }
  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }
  private setTabText(text: string) {
    this.setState({
      tabText: text,
    });
  }
  
  loadPatient() {
    this.setLoading(true);
    if (this.state.patientId != undefined) {
      this.patientService
        .getUserById(this.state.patientId)
        .then((res: HttpResponse<PatientDetailModel>) => {
          //this.setLoading(false);
          if (res && res.result) {
            console.log(res.result);
            this.setState({
              patientDetail: res.result,
            });
          }
        })
        .catch(() => {
          this.setLoading(false);
        });
    }
  }

  loadPatientFinalReport() {
    this.setLoading(true);
    if (this.state.patientId != undefined) {
      this.finalReportDataService
        .getFinalReportData(this.state.patientId)
        .then((res: HttpResponse<FinalReportSaveDataModel>) => {
          this.setLoading(false);
          if (res && res.result) {
            this.setState({
              reportSavedData: res.result,
            });
          }
        })
        .catch(() => {
          this.setLoading(false);
        });
    }
  }
  handleNavigateClick = (patientId) => {
    this.props.navigate("/patient/addPatient/" + patientId);
  };
  componentDidMount() {
    this.loadData(true);
  }
  componentWillReceiveProps() {
    if (window.location.pathname == "/clinician/patientdashboard/") {
      window.location.href =
        window.location.origin + "/clinician/patientdashboard/";
    }
  }
  loadMedicationData() {
    if (this.state.patientId != undefined) {
      this.setLoading(true);
      this.patientMedicationService
        .getmedicationByPatientId(this.state.patientId)
        .then((res: HttpResponse<PatientMedicationDetail>) => {
          if (res && res.result) {
            let medication: AddPatientMedicationModel = {
              isActive: res.result.isActive,
              patientId: res.result.patientId,
              patientTrialId: res.result.patientTrialId,
              trialNoOfWeeks: res.result.trialNoOfWeeks,
              trialStartDate: res.result.trialStartDate,
              trialEndDate: res.result.trialEndDate,
              createPatientVisitModels: res.result.patientVisitDetails,
              createPatientTrialWeekModels: res.result.patientTrialWeekDetails,
              diagnosisCodes: res.result.patientTrialDiagnosisCodes,
              patientTrialStyle: res.result.patientTrialStyle,
            };
            this.setState({
              medication: medication,
            });
            this.setLoading(false);
          }
        })
        .catch((ex) => {
          this.setLoading(false);
        });
    }
  }
  loadClinitianData = () => {
    this.setLoading(true);
    if (this.state.patientId != undefined) {
      this.clinicianWeeklyDataService
        .getClinicianWeeklyData(this.state.patientId)
        .then((res: HttpResponse<ClinicianWeeklyData>) => {
          this.setLoading(false);
          if (
            res &&
            res.result &&
            res.result.lstClinicianSideEffectsWeeklyData != null
          ) {
            this.setState({
              clinicianWeeklyData: res.result,
            });
          }
        })
        .catch(() => {
          this.setLoading(false);
        });
    }
  };
  loadPatientVisitStatusData = () => {
    if (this.state.patientId != undefined) {
      this.patientVisitStatusService
        .getPatientVisitStatus(this.state.patientId)
        .then((res: HttpResponse<PatientVisitStatus>) => {
          if (res && res.result && res.result.lstPatientVisitStatus != null) {
            this.setState({
              patientVisitStatus: res.result,
            });
            console.info(res.result);
          }
        })
        .catch(() => {});
      
    }
  };

  loadData = (isNav?: any) => {
    console.log("loadData")
    if (!this.state.scrolling) {
      this.setLoading(true);
    }
    if (this.props.params.id){
      this.noteSevice
      .getPatientTrialNote(this.props.params.id)
      .then((res: HttpResponse<GetPatientTrialNoteModel>) => {
        this.setLoading(false);
        if (res && res.result) {
          console.log(res.result);
          this.setState({
            patientTrialNote: res.result.note,
            isPatientTrialCreated: res.result.wasFound,
          });
        }
      });
    }
    let filterModel: PatientListFilterModel = {
      searchText: this.state.searchText,
      pageSize: this.state.pageSize,
      currentPage: this.state.currentPage,
      totalItems: this.state.totalItems,
      accountStatus: this.state.accountStatus,
    };

    this.patientService
      .getActivePatientList(filterModel)
      .then((res: HttpResponse<PatientListResponseModel>) => {
        if (!this.state.scrolling) {
          this.setLoading(false);
        }

        if (res && res.result) {
          let patientList = this.state.scrolling
            ? this.state.result?.collection || []
            : [];
          patientList = patientList.concat(res.result.collection);
          let result = res.result;
          result.collection = patientList;
          if (this.state.scrolling) {
            this.setState({
              result: result,
              scrolling: false,
            });
            if (this.props.params.id) {  
              result.collection.filter((x, i) => {
                if (x.id == this.props.params.id) {
                  this.setState({
                    bcColor: i % 2 == 0 ? "#d3dee8" : "#dedab7",
                    isMedicalSocialHistoryFormSubmitted:
                      x.isMedicalSocialHistoryFormSubmitted,
                  });
                }
              });
            }
          } else {
            if (
              (this.state.patientId == "" ||
                this.state.patientId == undefined) &&
              result.collection.length > 0
            ) {
              this.props.navigate(
                `/clinician/patientdashboard/${result.collection[0].id}`
              );
            }
            if (result.collection[0].id){
              
                this.noteSevice
                  .getPatientTrialNote(result.collection[0].id)
                  .then((res: HttpResponse<GetPatientTrialNoteModel>) => {
                    this.setLoading(false);
                    if (res && res.result) {
                      console.log(res.result);
                      this.setState({
                        patientTrialNote: res.result.note,
                        isPatientTrialCreated: res.result.wasFound,
                      });
                    }
                  });
              
            }
            this.setState(
              {
                result: result,
                scrolling: false,
                patientId:
                  (this.state.patientId == undefined ||
                    this.state.patientId == "") &&
                  result.collection.length > 0
                    ? res.result.collection[0].id
                    : this.state.patientId,

                reRenderForm: true,
              },
              () => this.setState({ reRenderForm: false })
            );
            if (this.props.params.id) {
              res.result.collection.filter((x, i) => {
                if (x.id == this.props.params.id) {
                  this.setState(
                    {
                      reRenderForm: true,
                      bcColor: i % 2 == 0 ? "#d3dee8" : "#dedab7",
                      isMedicalSocialHistoryFormSubmitted:
                        x.isMedicalSocialHistoryFormSubmitted,
                    },
                    () => this.setState({ reRenderForm: false })
                  );
                }
              });
            }
          }
          this.loadPatientVisitStatusData();
        }

        if (isNav == true) {
          if (this.state.tabChangeFor == "social") {
            this.setState({
              isBreadcrumChange: true,
              tabText: "Medical-Social History",
            });
            this.changeTab(4, "Medical-Social History");
          } else if (this.state.tabChangeFor == "report") {
            this.setState({
              isBreadcrumChange: true,
            });
            this.changeTab(3, "Final Report");
          } else if (this.state.tabChangeFor == "cnote") {
            this.setState({
              isCnoteOpen: true,
              isBreadcrumChange: true,
            });
          } else if (this.state.tabChangeFor == "ptnote") {
            this.setState(
              {
                isPTnoteOpen: true,
                isBreadcrumChange: true,
              },
              () => {
                this.changeTab(2, "View P/T Note");
              }
            );
          } else if (this.state.tabChangeFor == "wUpdate") {
            this.setState({
              isBreadcrumChange: true,
              tabText: "Weekly Update",
            });
          } else if (this.state.tabChangeFor == "vSummary") {
            this.setState({
              isBreadcrumChange: true,
              tabText: "Visit Summary",
            });
            this.changeTab(1, "Visit Summary");
          }
        }
      })
      .catch(() => {
        this.setLoading(false);
      });
  };
  handleSearchChange = (event: any) => {
    this.setState(
      {
        currentPage: 1,
        scrolling: false,
        searchText: event.target.value,
      },
      () => {
        this.loadData();
      }
    );
  };
  currentUserName = (data) => {
    this.setState({
      currentUserName: data,
      tabText: this.state.tabText,
      isBreadcrumChange: this.state.isBreadcrumChange,
    });
  };
  patientChange = (id, isMedicalSocialHistoryFormSubmitted, index?: any) => {
    this.setState({
      isCnoteOpen: false,
      isPTnoteOpen: false,
    });
    this.noteSevice
      .getPatientTrialNote(id)
      .then((res: HttpResponse<GetPatientTrialNoteModel>) => {
        this.setLoading(false);
        if (res && res.result) {
          this.setState({
            patientTrialNote: res.result.note,
            isPatientTrialCreated: res.result.wasFound,
          });
        }
      });
    this.setState(
      {
        patientId: id,
        reRenderForm: true,
        bcColor: index == undefined || index % 2 == 0 ? "#dedab7" : "#d3dee8",
        isMedicalSocialHistoryFormSubmitted:
          isMedicalSocialHistoryFormSubmitted,
        isBreadcrumChange: false,
      },
      () => this.setState({ reRenderForm: false })
    );
    this.loadPatientVisitStatusData();
  };

  loadMore = () => {
    this.setState(
      (prevState) => ({
        currentPage: prevState.currentPage + 1,
        scrolling: true,
      }),
      () => {
        this.loadData();
      }
    );
  };
  expandAll = () => {
    this.setState(
      {
        isExpandAll: !this.state.isExpandAll,
        reRenderSocialMedicalHistoryForm: true,
      },
      () => this.setState({ reRenderSocialMedicalHistoryForm: false })
    );
  };
  setExpandAll = (data) => {
    this.setState({
      isExpandAll: data,
    });
    if (!data) {
      this.setState({ reRenderSocialMedicalHistoryForm: true }, () =>
        this.setState({ reRenderSocialMedicalHistoryForm: false })
      );
    }
  };
  handleClickLink = (patientId) => {
    this.props.navigate("/patient/addPatient/" + patientId + "/1");
  };
  cancelSubmitReportForm = () => {
    this.setState({ isShowModel: false });
  };
  showConfirmation = () => {
    this.setState({ isShowModel: true });
  };
  handleReportSave = (reportsaveModel) => {
    reportsaveModel.pateintId = this.state.patientId;
    this.setLoading(true);
    this.finalReportDataService
      .postFinalReportData(reportsaveModel)
      .then((res: HttpResponse<string>) => {
        this.setLoading(false);
        if (res && res.result) {
          this.setState({ isShowModel: false });
          this.setState({ reportSavedData: reportsaveModel });
          ShowSuccessMessage("Data Saved Successfully.");
        }
        this.loadData();
      })
      .catch(() => {
        this.setLoading(false);
      });
  };
  handleAccountStatusChange = (event) => {
    this.setState(
      {
        currentPage: 1,
        scrolling: false,
        accountStatus: parseInt(event.target.value),
      },
      () => {
        this.loadData();
      }
    );
  };
  handleAccountStatus = (val: any) => {
    let value = "-";
    if (val == 1) value = UserAccountStatus.AccountInitiated;
    else if (val == 2) value = UserAccountStatus.AccountInitiatedAndInvited;
    else if (val == 3) value = UserAccountStatus.AccountActivated;
    else if (val == 4) value = UserAccountStatus.AccountDeactivated;
    return value;
  };
  handleBorderStyle = (value?: number) => {
    let borderStyle = "";
    if (value == 1) borderStyle = "1px solid var(--color-shadeOrange)";
    else if (value == 2) borderStyle = "1px solid var(--color-primary)";
    else if (value == 3) borderStyle = "1px solid var(--color-shadeGreen)";
    else if (value == 4) borderStyle = "1px solid var(--color-shadeBrown)";

    return borderStyle;
  };
//TODO: Save note before closing modal
  handleSaveNote = () =>{
    this.setState({showNote: false})
  };

  handleNoteSave = (data) =>{
    console.log("Save Note: ", data)
    this.setState({patientTrialNote: data, showNote: false,})
    const noteModel: PatientTrialNoteModel = { 
      patientId : this.state.patientId,
      note : data,
    }
    this.noteSevice.savePatientTrialNote(noteModel)
      .then((res: HttpResponse<PatientTrialNoteModel>) =>{
        if (res && res.result){
          ShowSuccessMessage("Data Saved Successfully.");
        }
      })
  };
  render() {
    const { tab, filterTab } = this.state;
    return (
      <Fragment>
        {tab === 0 && <PageTitleSetter title="Patient Weekly Updates" />}

        {/* {tab === 1 && <PageTitleSetter title="Patient Visit Summary" />} */}
        {tab === 2 && <PageTitleSetter title="Parent/Teacher Rating" />}
        {tab === 3 && <PageTitleSetter title="Final Report" />}
        {tab === 4 && <PageTitleSetter title="Medical-Social History" />}
        <Fragment>
          {!this.state.isBreadcrumChange && (
            <BreadcrumSetter
              crumType="static"
              crumItem1="Patient"
              crumItem2={this.state.currentUserName}
              crumIcon={weeklyGrey}
              crumItem3={this.state.tabText}
            />
          )}
          {this.state.isBreadcrumChange && (
            <BreadcrumSetter
              crumType="link"
              crumItem1="Patient Management"
              crumItem2={this.state.currentUserName}
              crumIcon={managementGrey}
              crumItem3={this.state.tabText}
            />
          )}
        </Fragment>
        <div className="medical__data">
          <div className="medication__form">
            <div className="list__heading__search">
              <Heading
                heading="Patients"
                subHeading=""
                styleName="medicalData__style"
              />
              <div className="weekly__listing__filters">
                <Tabs
                  styleName="listFilterTab"
                  text=""
                  steps=""
                  stepsAct=""
                  fullText=""
                  familyTextName=""
                  image={searchGrey}
                  activeImage={searchBlue}
                  tabChange={() => this.changeTabFilter(0)}
                  selected={filterTab === 0}
                  title="Search by name/MRN"
                />

                <Tabs
                  styleName="listFilterTab"
                  text=""
                  steps=""
                  stepsAct=""
                  fullText=""
                  familyTextName=""
                  image={filterGrey}
                  activeImage={filterBlue}
                  tabChange={() => this.changeTabFilter(1)}
                  selected={filterTab === 1}
                  title="Filter by Patient's Status"
                />
              </div>
            </div>
            {filterTab === 0 && (
              <div className="list__heading__search">
                <Search
                  placeholder="Search by Name/MRN"
                  type="text"
                  onChange={this.handleSearchChange}
                  value={this.state.searchText}
                  variants="search__list"
                  name="search"
                  searchIcon=""
                />
              </div>
            )}
            {filterTab === 1 && (
              <div className="list__heading__search">
                <select
                  name="accountStatus"
                  defaultValue={this.state.accountStatus}
                  onChange={this.handleAccountStatusChange}
                  className="form-control"
                  // disabled={props.isReadOnly ? true : false}
                  // ref={register({
                  //   required: "Please Select Relationship.",
                  // })}
                >
                  <option value={UserStatus.All}>
                    Filter by Patient's Status
                  </option>
                  <option value={UserStatus.AccountActivated}>
                    Account Activated
                  </option>
                  <option value={UserStatus.AccountDeactivated}>
                    Account Deactivated
                  </option>
                </select>
                <i className="fa fa-angle-down relation_arrow"></i>
              </div>
            )}
            {/* <hr style={{ marginBottom: 0 }} /> */}
            <section className="list-container">
              <div id="targetDiv" className="weeklyScrollBar">
                <InfiniteScroll
                  dataLength={this.state.result.collection.length}
                  next={this.loadMore}
                  hasMore={true}
                  loader={false}
                  scrollableTarget="targetDiv"
                >
                  <ul className="user-list" style={{ marginTop: 5 }}>
                    {!this.state.isLoading &&
                      this.state.result.paging.totalItems > 0 &&
                      this.state.result.collection.map(
                        (item: PatientListModel, i) => {
                          return (
                            <LeftListing
                              index={i}
                              linkStyle=""
                              initials={item.initials}
                              firstName={item.firstName}
                              lastName={item.lastName}
                              mrNo={item.mr_No}
                              email={item.emailId}
                              isSelected={item.id == this.state.patientId}
                              listIcon=""
                              navUrl={`/clinician/patientdashboard/${item.id}`}
                              patientChange={() =>
                                this.patientChange(
                                  item.id,
                                  item.isMedicalSocialHistoryFormSubmitted,
                                  i + 1
                                )
                              }
                              isListingTypeTeacher={
                                this.state.isListingTypeTeacher
                              }
                              dateOfBirth={item.dateOfBirth}
                              gender={item.gender}
                              accStatus={this.handleAccountStatus(
                                item.accountStatus
                              )}
                              border={this.handleBorderStyle(
                                item.accountStatus
                              )}
                            />
                          );
                        }
                      )}

                    {!this.state.isLoading &&
                      this.state.result.paging.totalItems === 0 && (
                        <div className="text-danger text-center">
                          <div className="noRecord__table">
                            <img src={oops} alt="oops" />
                            <h2>No result found</h2>
                            <p>
                              There are no matching records for your search,
                              please try again.
                            </p>
                          </div>
                        </div>
                      )}
                    {this.state.isLoading && (
                      <Loader loading={this.state.isLoading} />
                    )}
                  </ul>
                </InfiniteScroll>
              </div>
            </section>
          </div>
          <div className="medication__table">
            <div className="patient__formStatus">
              {!this.state.reRenderForm && (
                <PatientDetailHeader
                  patientId={this.state.patientId}
                  handleNavigateClick={this.handleNavigateClick}
                  bcColor={this.state.bcColor}
                  OnCurrentUserName={this.currentUserName}
                  patientVisitStatus={this.state.patientVisitStatus}
                ></PatientDetailHeader>
              )}
              <div className="patient_summary__wrapper">
                <div className="patient__summaryTab">
                  <Tabs
                    styleName="smd_tabs"
                    text="Weekly Update"
                    steps=""
                    stepsAct=""
                    fullText="Weekly Update"
                    familyTextName=""
                    image={calendar}
                    activeImage={whiteCal}
                    tabChange={() => this.changeTab(0, "Weekly Update")}
                    selected={tab === 0}
                  />
                  <Tabs
                    styleName="smd_tabs"
                    text="Visit Summary"
                    steps=""
                    stepsAct=""
                    fullText="Visit Summary"
                    familyTextName=""
                    image={park}
                    activeImage={visit}
                    tabChange={() => this.changeTab(1, "Visit Summary")}
                    selected={tab === 1}
                  />
                  <Tabs
                    styleName="smd_tabs"
                    text="Parent/Teacher Rating"
                    steps=""
                    stepsAct=""
                    fullText="Parent/Teacher Rating"
                    familyTextName=""
                    image={rate}
                    activeImage={rating}
                    tabChange={() => this.changeTab(2, "Parent/Teacher Rating")}
                    selected={tab === 2}
                  />
                  <Tabs
                    styleName="smd_tabs"
                    text="Final Report"
                    steps=""
                    stepsAct=""
                    fullText="Final Report"
                    familyTextName=""
                    image={file}
                    activeImage={report}
                    tabChange={() => this.changeTab(3, "Final Report")}
                    selected={tab === 3}
                  />
                  <Tabs
                    styleName="smd_tabs"
                    text="Medical-Social History"
                    steps=""
                    stepsAct=""
                    fullText="Medical-Social History"
                    familyTextName=""
                    image={medicalRecord}
                    activeImage={medicalRecordWhite}
                    tabChange={() =>
                      this.changeTab(4, "Medical-Social History")
                    }
                    selected={tab === 4}
                  />
                  <Tabs
                    styleName="smd_tabs"
                    text="Note"
                    steps=""
                    stepsAct=""
                    fullText="Note"
                    familyTextName=""
                    image={file}
                    activeImage={report}
                    tabChange={() =>{
                      if (this.state.isPatientTrialCreated){
                        this.setState({showNote: true})}
                      }
                    }
                    selected={tab === 5}
                  />
                </div>
              </div>
              <div>
                {tab === 0 && !this.state.reRenderForm && (
                  <PatientWeeklyUpdate
                    patientId={this.state.patientId}
                    handleClickLink={this.handleClickLink}
                    openNote={this.state.isCnoteOpen}
                    loadPatientVisitStatus={this.loadPatientVisitStatusData}
                  />
                )}
                {tab === 1 && !this.state.reRenderForm && (
                  <VisitSummary
                    patientId={this.state.patientId}
                    handleClickLink={this.handleClickLink}
                  />
                )}
                {tab === 2 && !this.state.reRenderForm && (
                  <RespondentRating
                    handleClickLink={this.handleClickLink}
                    patientId={this.state.patientId}
                    openNote={this.state.isPTnoteOpen}
                  />
                )}
                {tab === 3 && !this.state.reRenderForm && (
                  <FinalReportComponent
                    handleClickLink={this.handleClickLink}
                    patientId={this.state.patientId}
                  />
                )}
                {tab === 4 &&
                  !this.state.reRenderForm &&
                  this.state.isMedicalSocialHistoryFormSubmitted && (
                    <div>
                      <div className="SMH__viewHead">
                        <Heading
                          heading="Medical-Social History of Patient"
                          styleName="SMH__heading"
                        />
                        <div
                          className="expand__accordian"
                          onClick={this.expandAll}
                        >
                          {!this.state.isExpandAll && (
                            <div>
                              <img src={expand} alt="collape accordian" />
                              <p>Expand All </p>
                            </div>
                          )}
                          {this.state.isExpandAll && (
                            <div>
                              <img src={merge} alt="collape accordian" />
                              <p>Collapse All </p>
                            </div>
                          )}
                        </div>
                      </div>

                      <PatientSocialMedicalHistory
                        reRenderSocialMedicalHistoryForm={
                          this.state.reRenderSocialMedicalHistoryForm
                        }
                        isExpandAll={this.state.isExpandAll}
                        setExpandAll={this.setExpandAll}
                        patientId={this.state.patientId}
                        bcColor={this.state.bcColor}
                        isMedicalSocialHistoryFormSubmitted={
                          this.state.isMedicalSocialHistoryFormSubmitted
                        }
                      />
                    </div>
                  )}
                {tab === 4 &&
                  !this.state.reRenderForm &&
                  !this.state.isMedicalSocialHistoryFormSubmitted && (
                    <div className="message-align">
                      {" "}
                      This data will be visible after the parent has submitted
                      their medical-social form.
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="Clinician Note"
          open={this.state.showNote}
          // onOk= {this.handleSaveNote}
          onCancel={() =>
            this.setState({showNote: false})
          }
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          // closable={false}
          maskClosable={false}
          className=""
        >
          <PatientTrialNote 
            onNoteSave={this.handleNoteSave}
            patientTrialNote={this.state.patientTrialNote}
          />
        </Modal>
      </Fragment>
    );
  }
}

export default withRouter(PatientDashboard);
