import React, { Component, Fragment } from "react";
import "../../styles.css";
import { PatientDetailModel } from "../../../../patient/models/patient-detail.model";
import { PatientService } from "../../../../patient/services";
import { HttpResponse } from "../../../../../core";
import { PatientDetailHeaderForm } from "./patientDetailHeaderForm";
import { MedicationService } from "../../../../../services/medication";
import { MedicationOfTheTrialModel } from "../../../../../models/medication/medication-of-the-trial.model";
import { ClinicianWeeklyData } from "../../../../../models/clinicianWeeklyData/clinician-weekly-data.model";
import { ClinicianWeeklyDataService } from "../../../../../services/clinicianWeeklyData/clinician-weekly-data.service";
import { PatientVisitStatus } from "../../../../../models/patientVisitStatus/patient-visit-status.model";
import { PatientVisitStatusService } from "../../../../../services/patientVisitStatus/patient-visit-status.service";

interface IState {
  isLoading: boolean;
  reRenderForm: boolean;
  patientDetail: PatientDetailModel;
  patientId: string;
  medicationOfTheTrial: MedicationOfTheTrialModel;
  currentUserName: string;
  patientVisitStatusData?:PatientVisitStatus;
}
class PatientDetailHeader extends React.Component<any, IState> {
  private patientService: PatientService;
  private patientVisitStatusService: PatientVisitStatusService;
  private medicationService: MedicationService;
  isComponentMounted: boolean = false;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.patientService = new PatientService();
    this.medicationService = new MedicationService();
    this.patientVisitStatusService= new PatientVisitStatusService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      patientDetail: {} as PatientDetailModel,
      reRenderForm: false,
      patientId: this.props.patientId,
      medicationOfTheTrial: {} as MedicationOfTheTrialModel,
      currentUserName: "",
    };
    return initialState;
  }

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }

  componentDidMount() {
    this.loadData();
    this.getMedicationOfTheTrialDetail();
    this.loadPatientVisitStatusData();
  }
  handleNavigateClick = () => {
    this.props.handleNavigateClick();
  };
  loadData() {
    if (this.state.patientId != null) {
      this.setLoading(true);
      this.patientService
        .getUserById(this.state.patientId)
        .then((res: HttpResponse<PatientDetailModel>) => {
          this.setLoading(false);
          if (res && res.result) {
            this.setState({
              patientDetail: res.result,
            },()=>{
              this.props.OnCurrentUserName(this.state.patientDetail.lastName + ", " + this.state.patientDetail.firstName)
            }
            );
          }
         
        })
        .catch(() => {
          this.setLoading(false);
        });
    }
  }
  loadPatientVisitStatusData(){
    if (this.state.patientId != undefined) {
     
      this.patientVisitStatusService
        .getPatientVisitStatus(this.state.patientId)
        .then((res: HttpResponse<PatientVisitStatus>) => {
          if (
            res &&
            res.result &&
            res.result.lstPatientVisitStatus != null
          ) {
            
            this.setState({
              patientVisitStatusData: res.result,
            });
            console.info(res.result)
          }
        })
        .catch(() => {
         
        });
    }
  };
  parentTabChange = (tab: any) => {
    this.props.parentTabChange(tab);
  };

  getMedicationOfTheTrialDetail = () => {
    if (this.state.patientId != null) {
      this.setLoading(true);
      this.medicationService
        .getMedicationOfTheTrialDetail(this.state.patientId)
        .then((res: HttpResponse<MedicationOfTheTrialModel>) => {
          this.setLoading(false);
          if (res && res.result) {
            this.setState({
              medicationOfTheTrial: res.result,
            });
          }
        })
        .catch(() => {
          this.setLoading(false);
        });
    }
  };
  render() {
    return (
      <Fragment>
        <PatientDetailHeaderForm
          isLoading={this.state.isLoading}
          patientDetailModel={this.state.patientDetail}
          handleNavigateClick={this.props.handleNavigateClick}
          bcColor={this.props.bcColor}
          medicationOfTheTrial={this.state.medicationOfTheTrial}
          patientVisitStatus={this.state.patientVisitStatusData}
        />
      </Fragment>
    );
  }
}
export default PatientDetailHeader;
