import React, { Fragment } from "react";
import { HttpResponse } from "../../../../../../core";
import { PreviousDiagnosisModel } from "../../../../../../models/diagnosis/diagnosis.model";
import { DiagnosisService } from "../../../../../../services/diagnosis/diagnosis.service";
import DiagnosisViewForm from "./diagnosisViewForm";

interface IState {
  patientId: string;
  isLoading: boolean;
  previousDiagnosisModel: PreviousDiagnosisModel[];
}
export class DiagnosisTab extends React.Component<any, IState> {
  private diagnosisService: DiagnosisService;

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.diagnosisService = new DiagnosisService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      patientId: this.props.patientId,
      previousDiagnosisModel: [] as PreviousDiagnosisModel[],
    };
    return initialState;
  }
  componentDidMount() {
    if (this.props.patientId != null) {
      this.loadData();
    }
  }
  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }
  loadData = () => {
    this.setLoading(true);
    this.diagnosisService.getDiagnosis(
      this.props.patientId
    ).then((res: HttpResponse<PreviousDiagnosisModel[]>) => {
      if (res && res.result) {
        this.setLoading(false);
        this.setState({
          previousDiagnosisModel: res.result,
        });
      }
    });
  };

  render() {
    return (
      <Fragment>
        <DiagnosisViewForm
          patientId={this.props.patientId}
          isLoading={this.state.isLoading}
          previousDiagnosisModel={this.state.previousDiagnosisModel}
        />
      </Fragment>
    );
  }
}
