import React, { Fragment } from "react";
import "../../styles.css";
import { PatientDetailModel } from "../../../../patient/models/patient-detail.model";
import { PatientService } from "../../../../patient/services";
import { PatientGeneralInfoModel } from "../../../../../models/patient/patient-general-info.model";
import ChildGuardianInfo from "../../../../childGuardianInfo";
import Heading from "../../../../heading";
import { HttpResponse } from "../../../../../core";
import { GeneralDetailForm } from "./generalDetailForm";
import { CurrentPlacementModel } from "../../../../../models/patient/current-placement.model";
import { SpecialEducationModel } from "../../../../../models/patient/special-education.model";
import { ChildsEthnicityModel } from "../../../../../models/patient/childs-ethnicity.model";
import { AddParentModel } from "../../../../patient/models/add-parent.model";
import { AddressModel } from "../../../../../shared/models/address.model";
import { Loader } from "../../../../../shared/loaders";

interface IState {
  isLoading: boolean;
  reRenderForm: boolean;
  patientGeneralInfoModel: PatientGeneralInfoModel;
  patientId: string;
}
export class GeneralDetail extends React.Component<any, IState> {
  private patientService: PatientService;
  isComponentMounted: boolean = false;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.patientService = new PatientService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      patientGeneralInfoModel: {
        patientInfoModel: {
          parentModel: {
            fatherAddressModel: {},
            motherAddressModel: {},
          },
          physicianModel: {},
          specialEducationModel: {},
          currentPlacementModel: {},
          childsEthnicityModel: {},
        },
      } as PatientGeneralInfoModel,
      reRenderForm: false,
      patientId: this.props.patientId,
    };
    return initialState;
  }

  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    if (this.state.patientId) {
      this.setLoading(true);
      this.patientService
        .getUserById(this.state.patientId)
        .then((res: HttpResponse<PatientDetailModel>) => {
          this.setLoading(false);
          if (res && res.result) {
            let currentPlacementModel = {} as CurrentPlacementModel;
            let specialEducationModel = {} as SpecialEducationModel;
            let childsEthnicityModel = {} as ChildsEthnicityModel;
            let fatherAddressModel = {} as AddressModel;
            let motherAddressModel = {} as AddressModel;
            if (res.result.parentModel.fatherAddressModel != null) {
              fatherAddressModel.address1 =
                res.result.parentModel.fatherAddressModel.address1;
              fatherAddressModel.address2 =
                res.result.parentModel.fatherAddressModel.address2;
              fatherAddressModel.city =
                res.result.parentModel.fatherAddressModel.city;
              fatherAddressModel.state =
                res.result.parentModel.fatherAddressModel.state;
              fatherAddressModel.zipcode =
                res.result.parentModel.fatherAddressModel.zipcode;
              fatherAddressModel.addressId =
                res.result.parentModel.fatherAddressModel.addressId;
            }
            if (res.result.parentModel.motherAddressModel != null) {
              motherAddressModel.address1 =
                res.result.parentModel.motherAddressModel.address1;
              motherAddressModel.address2 =
                res.result.parentModel.motherAddressModel.address2;
              motherAddressModel.city =
                res.result.parentModel.motherAddressModel.city;
              motherAddressModel.state =
                res.result.parentModel.motherAddressModel.state;
              motherAddressModel.zipcode =
                res.result.parentModel.motherAddressModel.zipcode;
              motherAddressModel.addressId =
                res.result.parentModel.motherAddressModel.addressId;
            }
            if (res.result.currentPlacementModel != null) {
              currentPlacementModel.regularEducation =
                res.result.currentPlacementModel.regularEducation;
              currentPlacementModel.resourceRoom =
                res.result.currentPlacementModel.resourceRoom;
              currentPlacementModel.specialEducation =
                res.result.currentPlacementModel.specialEducation;
              currentPlacementModel.currentPlacementDescription =
                res.result.currentPlacementModel.currentPlacementDescription;
            }
            if (res.result.specialEducationModel != null) {
              specialEducationModel.learningDisablity =
                res.result.specialEducationModel.learningDisablity;
              specialEducationModel.behaviorDisorder =
                res.result.specialEducationModel.behaviorDisorder;
              specialEducationModel.developmentalDelay =
                res.result.specialEducationModel.developmentalDelay;
              specialEducationModel.specialEducationDescription =
                res.result.specialEducationModel.specialEducationDescription;
            }
            if (res.result.childsEthnicityModel != null) {
              childsEthnicityModel.africanAmerican =
                res.result.childsEthnicityModel.africanAmerican;
              childsEthnicityModel.hispanic =
                res.result.childsEthnicityModel.hispanic;
              childsEthnicityModel.americianIndian =
                res.result.childsEthnicityModel.americianIndian;
              childsEthnicityModel.asian =
                res.result.childsEthnicityModel.asian;
              childsEthnicityModel.fillipino =
                res.result.childsEthnicityModel.fillipino;
              childsEthnicityModel.indianSubcontinent =
                res.result.childsEthnicityModel.indianSubcontinent;
              childsEthnicityModel.seAsian =
                res.result.childsEthnicityModel.seAsian;
              childsEthnicityModel.hawaliain =
                res.result.childsEthnicityModel.hawaliain;
              childsEthnicityModel.caucasian =
                res.result.childsEthnicityModel.caucasian;
              childsEthnicityModel.otherPacificIslander =
                res.result.childsEthnicityModel.otherPacificIslander;
              childsEthnicityModel.childsEthnicityDescription =
                res.result.childsEthnicityModel.childsEthnicityDescription;
            }
            let generalInfoModel: PatientGeneralInfoModel = {
              teacherId: res.result.teacherId,
              tFirstName: res.result.teacherFirstName,
              tLastName: res.result.teacherLastName,
              tEmailId: res.result.teacherEmailId,
              schoolDistrict: res.result.schoolDistrict,
              schoolContactNo: res.result.schoolContactNo,
              schoolName: res.result.schoolName,
              isInviteSend: false,
              isTeacherAccountActivate: res.result.isTeacherAccountActivate,
              isPatientTeacher: res.result.isPatientTeacher,
              patientInfoModel: {
                id: res.result.id,
                guardianId: res.result.guardianId,
                mr_No: res.result.mr_No,
                firstName: res.result.firstName,
                lastName: res.result.lastName,
                dateOfBirth: res.result.dateOfBirth,
                gender: res.result.gender,
                relationshipId: res.result.relationshipId,
                guardianFirstName: res.result.guardianFirstName,
                guardianLastName: res.result.guardianLastName,
                contactNo: res.result.contactNo,
                emailId: res.result.emailId,
                adopted: res.result.adopted,
                adoptedAge: res.result.adoptedAge,
                grade: res.result.grade,
                birthPlace: res.result.birthPlace,
                personCompletingForm: res.result.personCompletingForm,
                initials: res.result.initials,
                relationshipWithPersonCompletingForm:
                  res.result.relationshipWithPersonCompletingForm,
                // motherName: res.result.motherName,
                // fatherName: res.result.fatherName,
                workPhoneNo: res.result.workPhoneNo,
                // addressModel: {
                //   address1: res.result.addressModel.address1,
                //   address2: res.result.addressModel.address2,
                //   city: res.result.addressModel.city,
                //   state: res.result.addressModel.state,
                //   zipcode: res.result.addressModel.zipcode,
                //   addressId: res.result.addressModel.addressId,
                // },
                physicianModel: {
                    id: res.result.monitoringPhysicianId,
                    pLastName: res.result.monitoringPhysicianLastName,
                    pFirstName: res.result.monitoringPhysicianFirstName,
                    pEmailId: res.result.monitoringPhysicianEmailId,
                    pPhoneNo: res.result.monitoringPhysicianPhoneNo,
                    patientId: res.result.id,
                },
                parentModel: {
                  id: res.result.parentModel.id,
                  motherName: res.result.parentModel.motherName,
                  fatherName: res.result.parentModel.fatherName,
                  motherWorkPhoneNo: res.result.parentModel.motherWorkPhoneNo,
                  motherHomePhoneNo: res.result.parentModel.motherHomePhoneNo,
                  fatherWorkPhoneNo: res.result.parentModel.fatherWorkPhoneNo,
                  fatherHomePhoneNo: res.result.parentModel.fatherHomePhoneNo,
                  fatherAddressModel:
                    fatherAddressModel != null
                      ? fatherAddressModel
                      : ({} as AddressModel),
                  motherAddressModel:
                    motherAddressModel != null
                      ? motherAddressModel
                      : ({} as AddressModel),
                },
                currentPlacementModel:
                  currentPlacementModel != null
                    ? currentPlacementModel
                    : ({} as CurrentPlacementModel),

                specialEducationModel:
                  specialEducationModel != null
                    ? specialEducationModel
                    : ({} as SpecialEducationModel),
                childsEthnicityModel:
                  childsEthnicityModel != null
                    ? childsEthnicityModel
                    : ({} as ChildsEthnicityModel),
              },
            };
            this.setState(
              {
                patientGeneralInfoModel: generalInfoModel,
                reRenderForm: true,
              },
              () => this.setState({ reRenderForm: false })
            );
          }
        })
        .catch((ex) => {
          this.setLoading(false);
        });
    }
  }

  render() {
    return (
      <Fragment>
        {!this.state.reRenderForm && !this.state.isLoading && (
          <GeneralDetailForm
            bcColor={this.props.bcColor}
            isSaving={this.state.isLoading}
            patientGeneralInfoModel={this.state.patientGeneralInfoModel}
          />
        )}
        {<Loader loading={this.state.isLoading} />}
      </Fragment>
    );
  }
}
