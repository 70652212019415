import { useState, useEffect, Fragment } from "react";
import { RespondentInformation } from "../../models/Respondent/RespondentInfo";
import { Button } from "antd";
import "./styles.css";
import Modal from "antd/es/modal/Modal";

interface RespondentListProps {
  patientId: string;
  respondentList: RespondentInformation[];
  editRespondent: Function;
  removeRespondent: Function;
  resendInvite: Function;
}
function RespondentList(props: RespondentListProps) {
  const [respondentList, setRespondentList] = useState<RespondentInformation[]>(
    {} as RespondentInformation[]
  );
  const [showResendModal, setShowResendModal] = useState(false);
  const [resendRespondent, setResendRespondent] = useState<RespondentInformation>({} as RespondentInformation);
  useEffect(() => {
    setRespondentList(props.respondentList);
  }, [props]);

  const ResendInvite = (respondent: RespondentInformation) => {
    // console.log("resendInvite: ", respondent);
    // alert("resend invited for: " + respondent.email);
    setResendRespondent(respondent);
    if( respondent.id){
      setShowResendModal(true);
    }
  };

  const ConfirmResend = () =>{
    if (resendRespondent.id){
      props.resendInvite(resendRespondent)
    }
  };

  const CancelResendInvite = () =>{
    setShowResendModal(false);
  };

  return (
    <Fragment>
      <div className="mgmt__table">
        <table className="table table-hover">
          <thead>
            <tr key={"respondentmgmt_"}>
              <th>
                <div>
                  <span>FirstName</span>
                </div>
              </th>
              <th>
                <div>
                  <span>LastName</span>
                </div>
              </th>
              <th>
                <div>
                  <span>Email</span>
                </div>
              </th>
              <th className="d-none d-md-table-cell">
                <div>
                  <span>Relation</span>
                </div>
              </th>
              <th className="d-none d-md-table-cell">
                <div>
                  <span>Gets Additional Questions</span>
                </div>
              </th>
              <th className="d-none d-md-table-cell">
                <div>
                  <span>IsActivated/Resend Invite</span>
                </div>
              </th>
              {/* <th>
                <div>
                    <span>Edit</span>
                </div>
              </th>
              <th>
                <div>
                    <span>Remove</span>
                </div>
              </th> */}
            </tr>
          </thead>
          <tbody>
            {respondentList &&
              respondentList.length > 0 &&
              respondentList.map((item, index) => {
                return (
                  <tr key={"respondentmgmt_" + item.id}>
                    <td>
                      <div className="mgmtName-role">
                        <div>
                          <span>{item.firstName}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="mgmtName-role">
                        <div>
                          <span>{item.lastName}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="mgmtName-role">
                        <div>
                          <span>{item.email}</span>
                        </div>
                      </div>
                    </td>
                    <td className="d-none d-md-table-cell">
                      <div className="mgmtName-role">
                        <div>
                          <span>{item.relation.value}</span>
                        </div>
                      </div>
                    </td>
                    <td className="d-none d-md-table-cell">
                      <div className="mgmtName-role">
                        <div>
                          <span>{item.getsDosageQuestions ? "YES" : "NO"}</span>
                        </div>
                      </div>
                    </td>
                    <td className="d-none d-md-table-cell">
                      <div className="mgmtName-role">
                        <div>
                          <span>{item.isActivated ? "Account Activated" : <Button onClick={() => {ResendInvite(item)}}>Resend Invite</Button>}</span>
                        </div>
                      </div>
                    </td>
                    {/* <td>
                      <div className="mgmtName-role">
                        <div>
                          <Button onClick={() => {props.editRespondent(item)}}>Edit</Button>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="mgmtName-role">
                        <div>
                          <Button onClick={() => {props.removeRespondent(item)}}>Remove</Button>
                        </div>
                      </div>
                    </td> */}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <Modal
        title="Resend Invite"
        open={showResendModal}
        onOk={ConfirmResend}
        onCancel={CancelResendInvite}
        okText="Resend Invite"
        okType="primary"
        cancelText="Cancel"
        // closable={false}
        maskClosable={false}
        className=""
      >
        <div>
          Do you want to send an invitation to {resendRespondent.email}
        </div>
      </Modal>
    </Fragment>
  );
}

export default RespondentList;
