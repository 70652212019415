import React from "react";
import Heading from "../heading";
import { Button } from "antd";
import line from "../../assets/images/svg-icons/line.svg";
import "./styles.css";
import { SeverityType } from "../../shared/enums/clinicianWeeklyData/severity-type";
import { ClinicianWeeklyDataType } from "../../shared/enums/clinicianWeeklyData/clinician-weekly-data-type";
import { WeeklyDataUpdateByType } from "../../shared/enums/clinicianWeeklyData/weekly-data-update-by-type";
import { TeacherTargetSymptomsWeekDayData } from "../../models/teacherWeeklyData/teacher-target-symptoms-week-day-data.model";
interface Iprops {
  propName?: any;
  assignWeeklyDataModel?: any;
  clinicianWeeklyData?: any;
  teacherWeeklyData?: any;
  parentWeeklyData?: any;
  weeklyDataUpdateByType?: any;
  weekIndex: number;
  type?: number;
  popOverId?: string;
}
function SeverityModal(props: Iprops) {
  const severityClick = (severityType: number) => {
    if (props.weeklyDataUpdateByType == WeeklyDataUpdateByType.Teacher) {
      props.teacherWeeklyData[props.weekIndex][props.propName] = severityType;
      props.assignWeeklyDataModel({
        teacherWeeklyData: props.teacherWeeklyData,
      });
    } else if (props.weeklyDataUpdateByType == WeeklyDataUpdateByType.Parent) {
      props.parentWeeklyData[props.weekIndex][props.propName] = severityType;
      props.assignWeeklyDataModel({
        parentWeekDayData: props.parentWeeklyData,
      });
    } else {
      if (props.type == ClinicianWeeklyDataType.SideEffects) {
        props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData[props.weekIndex][
          props.propName
        ] = severityType;
        props.assignWeeklyDataModel({
          clinicianWeeklyData: props.clinicianWeeklyData,
          clinicianWeeklyDataType: ClinicianWeeklyDataType.SideEffects,
        });
      } else if (props.type == ClinicianWeeklyDataType.TargetSymptoms) {
        props.clinicianWeeklyData.lstClinicianTargetSymptomsWeeklyData[props.weekIndex][
          props.propName
        ] = severityType;
        props.assignWeeklyDataModel({
          clinicianWeeklyData: props.clinicianWeeklyData,
          clinicianWeeklyDataType: ClinicianWeeklyDataType.TargetSymptoms,
        });
      } else if (props.type == ClinicianWeeklyDataType.AddImprovements) {
        props.clinicianWeeklyData.lstClinicianAddImprovementsWeeklyData[props.weekIndex][
          props.propName
        ] = severityType;
        props.assignWeeklyDataModel({
          clinicianWeeklyData: props.clinicianWeeklyData,
          clinicianWeeklyDataType: ClinicianWeeklyDataType.AddImprovements,
        });
      }
    }
    if (props.popOverId) {
      document.getElementById(props.popOverId)?.click();
    }
  };
  return (
    <div className="sev__modal">
      <Heading heading="Severity scale" styleName="weekwise__head" />
      <img src={line} alt="line" />
      <p className="sevr__para">Choose the severity rating</p>
      <Button
        type="primary"
        size="middle"
        className="add__severity"
        onClick={() => severityClick(SeverityType.Normal)}
      >
        Normal; Not at all (1)
      </Button>
      <Button
        type="primary"
        size="middle"
        className="add__severity"
        onClick={() => severityClick(SeverityType.BorderlineProblem)}
      >
        Borderline Problem (2)
      </Button>
      <Button
        type="primary"
        size="middle"
        className="add__severity"
        onClick={() => severityClick(SeverityType.MildProblem)}
      >
        Mild Problem (3)
      </Button>
      <Button
        type="primary"
        size="middle"
        className="add__severity"
        onClick={() => severityClick(SeverityType.ModerateProblem)}
      >
        Moderate Problem (4)
      </Button>
     
      <Button
        type="primary"
        size="middle"
        className="add__severity add__severity__orangeColor"
        onClick={() => severityClick(SeverityType.MarkedProblem)}
      >
        Marked Problem (5)
      </Button>
      <Button
        type="primary"
        size="middle"
        className="add__severity add__severity__redColor"
        onClick={() => severityClick(SeverityType.SevereProblem)}
      >
        Severe Problem (6)
      </Button>
      <Button
        type="primary"
        size="middle"
        className="add__severity add__severity__redColor"
        onClick={() => severityClick(SeverityType.MostExtremeProblem)}
      >
        Most Extreme Problem (7)
      </Button>
    </div>
  );
}
export default SeverityModal;
