import './App.css';
import GlobalContextProvider from './shared/globalContext/globalContextProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React, { Fragment } from 'react';
import { ToastContainer, Zoom } from 'react-toastify';
import Login from './components/auth/Login';
import LoginCallback from './components/auth/LoginCallback';
import { PrivateRoute } from './routing/auth-guard';
import { SecurePage } from './pages/SecurePage';
import withRouter from './shared/withRouter/withRouter';
import "bootstrap/dist/css/bootstrap.css";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import "../src/config/global_styles.css";

function App() {
  return (
    <Fragment>
    <GlobalContextProvider>
          <Routes>
            <Route  path="/login" element={<Login />} />
            <Route  path="/login-callback" element={<LoginCallback />} />
            <Route path="/*" element={
              <PrivateRoute >
                <SecurePage />
              </PrivateRoute>
              } />
          </Routes>
    </GlobalContextProvider>
    <ToastContainer transition={Zoom} />
  </Fragment>
  );
}

export default withRouter(App);
