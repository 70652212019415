import React, { Component, Fragment } from "react";
import { HttpResponse } from "../../../core";
import { ShowErrorMessage, ShowSuccessMessage } from "../../../shared/helpers";
import { AddUserModel } from "../../../models/users/add-user.model";
import { RoleModel, UserDetailModel } from "../../../models/users";
import { UserService } from "../../../services/user";
import { AddUserForm } from "./addUserForm";
import { PatientService } from "../../patient/services";
import AddPatient from "../../patient/addPatient";
import withRouter from "../../../shared/withRouter/withRouter";

interface IState {
  roleList: RoleModel[];
  isLoading: boolean;
  isReadonly: boolean;
  user: AddUserModel;
  reRenderForm: boolean;
  isInviteSent?: boolean;
  currentUserName:string;
  isEmailError:boolean;
  isEmailErrorMessage:string;
  isBreadcrumChange: boolean;
  tabText: string;
  tabChangeFor: string;
}

class AddUser extends Component<any, IState> {
  private patientService: PatientService;
  private userService: UserService;
  id: string = "";
  isComponentMounted: boolean = false;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.userService = new UserService();
    this.patientService = new PatientService();
  }
  private getInitialState() {
  let initialState: IState = {
    roleList: [],
    isLoading: false,
    isReadonly: false,
    user: {} as AddUserModel,
    reRenderForm: false,
    isInviteSent: false,
    currentUserName:"",
    tabChangeFor: this.props.params.tab ?? "",
    isEmailError:false,
   isEmailErrorMessage:"",
   isBreadcrumChange: false,
   tabText: ""
  };
  return initialState;
 }
    componentDidUpdate(prevProps) {
      if(prevProps.params.id!=this.props.params.id)
      {
        this.getRoles();
        this.setState(this.getInitialState());
        this.setState({ reRenderForm: true,
        },
        () => this.setState({ reRenderForm: false })
      )
      if (this.props.params.id == null ||this.props.params.id == 0) {
        this.setState(
          {
            isBreadcrumChange: false,
            tabText: "",
          },
          () =>
            this.props.OnCurrentUserName(
              "Add User",
              this.state.isBreadcrumChange,
              this.state.tabText
            )
        );
      }
      }
        if (this.isComponentMounted) {
          if(this.props.params.id !=0){
            this.loadData();
            }
            if(this.props.params.id ==0){
            this.id="0";
            }
        } 
  }
  componentDidMount() {
    this.isComponentMounted = true;
    if(this.props.params.id !=0){
      this.loadData();
      }
    this.getRoles();
    // if(this.props.params.id==null)
    // {
    //   this.props.OnCurrentUserName("Add User")
    // }
  }

  loadData() {
    if (this.props.params.id && this.id != this.props.params.id) {
      this.id = this.props.params.id;
      this.userService
        .getUserById(this.props.params.id)
        .then((res: HttpResponse<UserDetailModel>) => {
          if (res && res.result) {
            let user: AddUserModel = {
              id: res.result.id,
              emailId: res.result.email,
              firstName: res.result.firstName,
              lastName: res.result.lastName,
              isInviteSend: res.result.isInviteSend,
              speciality: res.result.speciality,
              roles: res.result.roles,
              identityId: res.result.identityId,
            };
            this.setState(
              {
                user: user,
                isReadonly: true,
                reRenderForm: true,
                currentUserName:user.lastName + ", " + user.firstName,
              },
              () => this.setState({ 
                reRenderForm: false, 
                })
              
            );
            this.props.OnCurrentUserName(
              this.state.currentUserName,
              this.state.isBreadcrumChange,
              this.state.tabText
            );
            if (this.state.tabChangeFor == "edit") {
              this.setState(
                {
                  isBreadcrumChange: true,
                  tabText: "Edit User",
                },
                () =>
                  this.props.OnCurrentUserName(
                    this.state.currentUserName,
                    this.state.isBreadcrumChange,
                    this.state.tabText
                  )
              );
            }
          }
        })
        .catch((ex) => {});
    }
  }
  onEditClick = () => {
    this.setState({ isReadonly: false });
  };
  getRoles() {
    this.userService
      .getRole()
      .then((res: HttpResponse<RoleModel[]>) => {
        if (res && res.result) {
          this.setState({
            roleList: res.result,
          });
        }
      })
      .catch((ex) => {});
  }

  postData = (user: AddUserModel) => {
    if(!this.state.isEmailError){
    this.setState({
      isInviteSent: user.isInviteSend,
    });
    this.setLoading(true);
    this.userService
      .postUser(user)
      .then((res: HttpResponse<string>) => {
        this.setLoading(false);
        if (res && res.result) {
          if (user.isInviteSend) {
            ShowSuccessMessage("Invite Sent Successfully.");
          } else if (user.id != null && user.id != "") {
            ShowSuccessMessage("Data Updated Successfully.");
          } else {
            ShowSuccessMessage("Data Saved Successfully.");
          }
          if (user.id != null && user.id != "") {
            this.props.onUserSave();
            this.setState({ isReadonly: true });
            this.props.navigate("/user/adduser/" + res.result);
          } else {
            this.props.onUserSave();
            this.props.navigate("/user/adduser/" + res.result);
            
          }
        }
      })
      .catch((ex) => {
        this.setLoading(false);
      });
    }
  };
  private setLoading(loading: boolean) {
    this.setState({ isLoading: loading });
  }
  isEmailExists=(data)=>{
    let user=data.model ;
    if (this.state.isLoading) {
      return;
    }
    if (data.isAutoSave) {
      this.setLoading(false);
    } else {
      this.setLoading(true);
    }
    this.patientService
    .checkIsEmailExists(user)
    .then((res: HttpResponse<any>) => {
      if (res && res.result) {
       if(res.result.isEmail==true){
        this.setState({isEmailError:true,isEmailErrorMessage:'Email Already Exists.'});
       }else{
        this.setState({isEmailError:false});
       }
   
      }else{
        this.setState({isEmailError:false});
      }
    })
    .catch((ex) => {
      this.setLoading(false);
    });
  }
  render() {
    return (
      <Fragment>
        {!this.state.reRenderForm && (
          <AddUserForm
            isReadOnly={this.state.isReadonly}
            isSaving={this.state.isLoading}
            onEditClick={this.onEditClick}
            onSubmit={this.postData}
            user={this.state.user}
            roleList={this.state.roleList}
            isInviteSent={this.state.isInviteSent}
            isEmailExists={this.isEmailExists}
            isEmailError={this.state.isEmailError}
            isEmailErrorMessage={this.state.isEmailErrorMessage}
          />
        )}
      </Fragment>
    );
  }
}

export default withRouter(AddUser)
