import React, { Component, Fragment } from "react";
import "./styles.css";
import Tabs from "../../tabs";
import Search from "../../search";
import { DatePicker, Button, Tooltip } from "antd";
import PatientManagementTable from "./patientManagementTable";
import PageTitleSetter from "../../../shared/pageTitleSetter";
import search from "../../../assets/images/svg-icons/search.svg";
import attention from "../../../assets/images/svg-icons/attention-g.svg";
import attentionWhite from "../../../assets/images/svg-icons/attention-w.svg";
import medication from "../../../assets/images/svg-icons/medication-g.svg";
import medicationWhite from "../../../assets/images/svg-icons/medication-w.svg";
import socialForm from "../../../assets/images/svg-icons/socialform-g.svg";
import socialFormWhite from "../../../assets/images/svg-icons/socialform-w.svg";
import totalPatient from "../../../assets/images/svg-icons/total-patient-g.svg";
import totalPatientWhite from "../../../assets/images/svg-icons/total-patient-w.svg";
import visiting from "../../../assets/images/svg-icons/visiting-g.svg";
import visitingWhite from "../../../assets/images/svg-icons/visiting-w.svg";
import medicalRecordWhite from '../../../assets/images/svg-icons/medical-record-w.svg';
import medicalRecord from '../../../assets/images/svg-icons/medical-record-g.svg';
import alert from "../../../assets/images/svg-icons/alert-parent.svg";
import addPatient from "../../../assets/images/svg-icons/active-patient-g.svg";
import addPatientWhite from "../../../assets/images/svg-icons/active-patient-w.svg";
import {
  PatientManagementList,
  PatientManagementListModel,
} from "../../../models/patientManagement/patient-list.model";
import { PatientManagementService } from "../../../services/patientManagement/patient-management.service";
import { HttpResponse } from "../../../core";
import { PatientListFilterModel } from "../../patient/models/patient-list-filter.model";
import { PatientStatsFilterModel } from "../../../models/patientManagement/patient-stats.model";
import { PatientListResponseModel } from "../../../models/patientManagement/patient-list-response.model";
import moment from "moment";

import BreadcrumSetter from "../../../shared/breadcrumSetter";
import managementGrey  from "../../../assets/images/svg-icons/management-grey.svg";
import { PatientService } from "../../patient/services";
import { ShowSuccessMessage } from "../../../shared/helpers";
import AddPatient from "../../patient/addPatient";
import { DeactivatePatientModel } from "../../../models/patient/deactivate-patient.model";
import whiteCal from "../../../assets/images/svg-icons/weekly_calendarWhite.svg";
import blueCal from "../../../assets/images/svg-icons/calendar-g.svg";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;
interface IState {
  tab: number;
  searchText: string;
  statsNo: number;
  visitStatus: string;
  visitType: string;
  monitoringStartDate?: Date|string|null;
  monitoringEndDate?: Date|string|null;
  patientManagementList: PatientManagementList[];
  patientManagementListModel: PatientManagementListModel;
  reRenderForm: boolean;
  reRenderFilter: boolean;
  result: PatientListResponseModel | any;
  isLoading?: boolean;
  // pageNumber: number;
  pageSize: number;
  totalItems: number;
  currentPage: number;
  pageCount: number | undefined;
  sortName: "";
  sortOrder: "";
  totalPatientCount: number;
  activePatientCount: number;
  visitingStatusCount: number;
  attentionRequiredCount: number;
  socialFormNotSubmittedCount: number;
  patientWeeklyMedicationNotFilledCount: number;
  currentUserName:string;
  tabText: string;
}
export class PatientManagement extends Component<any, IState> {
  private patientManagementService: PatientManagementService;
  private patientService: PatientService;
  constructor(props) {
    super(props);
    this.patientManagementService = new PatientManagementService();
    this.patientService = new PatientService();
    this.state = {
      tab: 1,
      searchText: "",
      statsNo: 1,
      visitStatus: "",
      visitType: "",
      patientManagementList: [] as PatientManagementList[],
      patientManagementListModel: {} as PatientManagementListModel,
      reRenderForm: false,
      reRenderFilter: false,
      result: {
        collection: [],
        paging: {
          currentPage: 1,
          pageSize: 0,
          totalItems: 0,
          pageCount: 0,
        },
      },
      currentPage: 1,
      isLoading: false,
      pageSize: 10,
      totalItems: 0,
      pageCount: 0,
      sortName: "",
      sortOrder: "",
      totalPatientCount: 0,
      activePatientCount: 0,
      visitingStatusCount: 0,
      attentionRequiredCount: 0,
      socialFormNotSubmittedCount: 0,
      patientWeeklyMedicationNotFilledCount: 0,
      currentUserName:"",
      tabText:"Total Patients"
    };
  }
  componentDidMount() {
    this.loadData();
  }
  loadData = () => {
    let filterModel: PatientStatsFilterModel = {
      searchText: this.state.searchText,
      statsNo: this.state.tab,
      sortName:this.state.sortName,
      sortOrder:this.state.sortOrder,
      visitStatus: this.state.visitStatus,
      visitType: this.state.visitType,
      monitoringStartDate: this.state.monitoringStartDate,
      monitoringEndDate: this.state.monitoringEndDate,
      pageSize: this.state.pageSize,
      currentPage: this.state.currentPage,
      totalItems: this.state.totalItems,
      pageCount: this.state.pageCount,
      };
      this.setState({
          isLoading:true
      })
    this.patientManagementService
      .getPatientManagementList(filterModel)
      .then((res: HttpResponse<PatientListResponseModel>) => {
        if (res && res.result) {
          this.setState(
            {
              isLoading:false,
              patientManagementListModel: res.result.record,
              patientManagementList: res.result.record.patientList,
              reRenderForm: true,
              pageCount: res.result.paging.pageCount,
              totalPatientCount: res.result.record.totalPatientCount,
              activePatientCount: res.result.record.activePatientCount,
              visitingStatusCount: res.result.record.visitingStatusCount,
              attentionRequiredCount: res.result.record.attentionRequiredCount,
              socialFormNotSubmittedCount: res.result.record.socialFormNotSubmittedCount,
              patientWeeklyMedicationNotFilledCount:
                res.result.record.patientWeeklyMedicationNotFilledCount,
            },
            () => this.setState({ reRenderForm: false })
          );
        }
        
      });
  };
  changeTab(tab: any,tabText:string) {
    this.setState({
      currentPage: 1,
      isLoading: false,
      totalItems: 0,
      pageCount: 0,
      tab: tab,
      tabText:tabText
    });
    this.resetSecondLevelFilter(tabText);
  }
  pageChange = (page: number) => {
    if (this.state.currentPage === page) return;
    this.setState(
      {
        currentPage: page,
      },
      () => {
        this.loadData();
      }
    );
  };

  resetSecondLevelFilter = (tabText) => {
    this.setState(
      {
        searchText: "",
        visitStatus: "",
        visitType:"",
        monitoringStartDate: undefined,
        monitoringEndDate: undefined,
        reRenderFilter: true,
      },
      () => {
        this.setState({
          reRenderFilter: false,
          
        });
        this.loadData();
      }
    );
  };
  handleSearchChange = (event: any) => {
    this.setState(
      {
            searchText: event.target.value,
            currentPage:1
      },
      () => {
        this.loadData();
      }
    );
  };
  handleSorting = (name: any, sortorder: any ) => {
    this.setState({
      sortName:name,
      sortOrder:sortorder,
      },
      () => {
        this.loadData();
      });
  };
  handleMedicationDateChange = (dateRange: any, dateRangeText: any) => {
    if (dateRange) {
      this.setState(
        {
              monitoringStartDate: dayjs(dateRange[0]._d).format("MM/DD/YYYY"),
              monitoringEndDate: dayjs(dateRange[1]._d).format("MM/DD/YYYY"),
        },
        () => {
          this.loadData();
        }
      );
    } else {
      this.setState(
        {
          monitoringStartDate: undefined,
          monitoringEndDate: undefined,
        },
        () => {
          this.loadData();
        }
      );
    }
  };
  handleVisitChange = (event: any) => {
    this.setState(
      {
        visitStatus: event.target.value,
      },
      () => {
        this.loadData();
      }
    );
  };
  handleVisitType = (event: any) => {
    this.setState(
      {
        visitType: event.target.value,
      },
      () => {
        this.loadData();
      }
    );
  };
  deactivatePatient=(patientId:any)=>{
    let deactivatePatientModel={} as DeactivatePatientModel;
    deactivatePatientModel.patientId=patientId;
    this.patientService
    .deactivatePatient(deactivatePatientModel)
    .then((res: HttpResponse<boolean>) => {
      if (res && res.result) {
        ShowSuccessMessage("Patient has been Deactivated Successfully.");
        this.loadData();
      }
    });
  }
  render() {
    const { tab, searchText, visitStatus } = this.state;
    return (
      <Fragment>
        <PageTitleSetter title="Patient Management" />
        {this.state.tab == 4 && (<BreadcrumSetter crumType="static"
                  crumIcon={managementGrey}
                  crumItem1="Patient Management"
                  crumItem2={this.state.currentUserName}
                  crumItem3="Severity Rating &ge; 5"
                 
                />)}
                {this.state.tab != 4 && (<BreadcrumSetter crumType="static"
                  crumIcon={managementGrey}
                  crumItem1="Patient Management"
                  crumItem2={this.state.currentUserName}
                  crumItem3={this.state.tabText}
                 
                />)}
        
        <div className="mgmt__wrapper">
          <div className="patientMgmt__Tab">
            <Tooltip placement="top" title="All patients added into the platform.">
              <div>
                <Tabs
                  styleName="mgmt__tabs"
                  text={"" + this.state.totalPatientCount}
                  steps="Total Patients"
                  stepsAct="Total Patients"
                  fullText={"" + this.state.totalPatientCount}
                  familyTextName=""
                  image={totalPatient}
                  activeImage={totalPatientWhite}
                  tabChange={() => this.changeTab(1,"Total Patients")}
                  selected={tab === 1}
                />
              </div>
            </Tooltip>
            <Tooltip placement="top" title="Patients currently undergoing the trail.">
              <div>
                <Tabs
                  styleName="mgmt__tabs"
                  text={"" + this.state.activePatientCount}
                  steps="Active Patients"
                  stepsAct="Active Patients"
                  fullText={"" + this.state.activePatientCount}
                  familyTextName=""
                  image={addPatient}
                  activeImage={addPatientWhite}
                  tabChange={() => this.changeTab(2,"Active Patients")}
                  selected={tab === 2}
                />
              </div>
            </Tooltip>
            <Tooltip
              placement="top"
              title="Patients with visits scheduled for today, or current week."
            >
              <div>
              <Tabs
                  styleName="mgmt__tabs"
                  text={"" + this.state.visitingStatusCount}
                  steps="Visit Status"
                  stepsAct="Visit Status"
                  fullText={"" + this.state.visitingStatusCount}
                  familyTextName=""
                  image={visiting}
                  activeImage={visitingWhite}
                  tabChange={() => this.changeTab(3,"Visit Status")}
                  selected={tab === 3}
                />
              </div>
            </Tooltip>
            <Tooltip
              placement="top"
                        title="Patients with a severity rating marked as High, rated by clinician between five and seven. Note: 5 Marked Problem, 6 severe problem, 7 most extreme problem. "
            >
              <div>
                <Tabs
                  styleName="mgmt__tabs"
                  text={"" + this.state.attentionRequiredCount}
                  steps="Severity Rating &ge; 5"
                  stepsAct="Severity Rating &ge; 5"
                  fullText={"" + this.state.attentionRequiredCount}
                  familyTextName=""
                  image={attention}
                  activeImage={attentionWhite}
                  tabChange={() => this.changeTab(4,"Severity Rating>=5")}
                  selected={tab === 4}
                />
              </div>
            </Tooltip>
            <Tooltip
              placement="top"
              title="Patients whose Medical-Social Form is not completed."
            >
              <div>
                <Tabs
                  styleName="mgmt__tabs"
                  text={"" + this.state.socialFormNotSubmittedCount}
                  steps="Medical-Social Form"
                  stepsAct="Medical-Social Form"
                  fullText={"" + this.state.socialFormNotSubmittedCount}
                  familyTextName=""
                  image={medicalRecord}
                  activeImage={medicalRecordWhite}
                                tabChange={() => this.changeTab(5, "Medical-Social Form")}
                  selected={tab === 5}
                />
              </div>
            </Tooltip>
            <Tooltip
              placement="top"
              title="Patients whose Weekly Status Updates have not been completed for 1 or more days by parent."
            >
              <div>
                <Tabs
                  styleName="mgmt__tabs"
                  text={"" + this.state.patientWeeklyMedicationNotFilledCount}
                  steps="Weekly Status Updates"
                  stepsAct="Weekly Status Updates"
                  fullText={"" + this.state.patientWeeklyMedicationNotFilledCount}
                  familyTextName=""
                  image={blueCal}
                  activeImage={whiteCal}
                  tabChange={() => this.changeTab(6,"Weekly Status Updates")}
                  selected={tab === 6}
                />
              </div>
            </Tooltip>
          </div>
          <div className="mgmt__filters">
            {!this.state.reRenderFilter && (
              <div className="mgmt__leftFilters">
                <Search
                  placeholder="Search patient, parent, teacher, physician by name, MRN"
                  type="text"
                  onChange={this.handleSearchChange}
                  value={searchText}
                  variants="search__filter"
                  name="searchText"
                  searchIcon={search}
                />

                <div className="medical-group" style={{width: "260px"}}>
                  <label className="form-label">Monitoring Date</label>
                  <RangePicker
                    onChange={this.handleMedicationDateChange}
                    className="datepicker range__margin"
                    format="MM/DD/YYYY"
                  />
                </div>
                {(tab === 3) && (
                  <div className="medical-group">
                    <label className="form-label">Visit Type</label>
                    <select
                      name="visitDay"
                      onChange={this.handleVisitChange}
                      className="form-control"
                      defaultValue={this.state.visitStatus}
                    >
                      <option value="">All</option>
                      <option value="Baseline">Baseline Visit</option>
                      <option value="Midpoint">Midpoint Visit</option>
                      <option value="Final">Final Visit</option>
                    </select>
                    <i className="fa fa-angle-down arrow"></i>
                  </div>
                )}
                {(tab === 3) && (
                  <div className="medical-group">
                    <label className="form-label">Visit Status</label>
                    <select
                      name="visitDay"
                      onChange={this.handleVisitType}
                      className="form-control"
                      defaultValue={this.state.visitStatus}
                    >
                      <option value="">All</option>
                      <option value="TodayVisit">Today's visit</option>
                      <option value="WeekVisit">This week visit </option>
                    </select>
                    <i className="fa fa-angle-down arrow"></i>
                  </div>
                )}
                <p onClick={this.resetSecondLevelFilter}>Clear All</p>
              </div>
            )}
            {/* <div className="week__noteBtn">
              {tab === 2 && (
                <Button
                  type="primary"
                  size="large"
                  className="week__note"
                  icon={<img src={alert} alt="reminderBtn" className="week__note_img" />}
                >
                  Send Reminder to Visit
                </Button>
              )}
              {tab === 3 && (
                <Button
                  type="primary"
                  size="large"
                  className="week__note"
                  icon={<img src={alert} alt="attentionBtn" className="week__note_img" />}
                >
                  Send Attention Alert
                </Button>
              )}
              {tab === 4 && (
                <Button
                  type="primary"
                  size="large"
                  className="week__note"
                  icon={<img src={alert} alt="reminderBtn" className="week__note_img" />}
                >
                  Send Reminder to Complete Form
                </Button>
              )}
            </div> */}
          </div>
          {!this.state.reRenderForm && (
            <PatientManagementTable
              patientManagementList={this.state.patientManagementList}
              currentPage={this.state.currentPage}
              pageCount={this.state.pageCount}
              onChangePage={this.pageChange}
              handleSorting={this.handleSorting}
              sortName= {this.state.sortName}
              sortOrder= {this.state.sortOrder}
              isLoading={this.state.isLoading}
              deactivatePatient={this.deactivatePatient}
            />
          )}
        </div>
      </Fragment>
    );
  }
}

export default PatientManagement;
