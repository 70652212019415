import { AppConfig, HttpWrapper } from "../../core";
import { AddTherapyPostModel } from "../../models/therapy/add-therapy-post.model";
export class TherapyService {
  private wrapper: HttpWrapper;
  constructor() {
    this.wrapper = new HttpWrapper();
  }
  /* Therapy Start */
  postTherapy(type:number, model: AddTherapyPostModel) {
    return this.wrapper.post<any>(
      AppConfig.apiEndpoint +
        "therapy/create"+"/"+ type,
      model
    );
  }
  getTherapyDetail(patientId: string, type: number) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint +
        "therapy/detail/" +
        patientId +"/"+  type
    );
  }
  deleteTherapy(id: string) {
    return this.wrapper.delete<boolean>(
      AppConfig.apiEndpoint +
        "therapy/delete/" +
        id
    );
  }
  getTherapyStepStatus(patientId: string) {
    return this.wrapper.get<any>(
      AppConfig.apiEndpoint +
        "therapy/getTherapyStepStatus/" +
        patientId
    );
  }
  /* Therapy End */
}
