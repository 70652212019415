import React from "react";
import ChildGuardianInfo from "../../../../childGuardianInfo";
import { PatientGeneralInfoModel } from "../../../../../models/patient/patient-general-info.model";
import Heading from "../../../../heading";
import { Loader } from "../../../../../shared/loaders";

interface IProps {
  patientGeneralInfoModel: PatientGeneralInfoModel;
  isSaving: boolean;
  bcColor:string;
}
export function GeneralDetailForm(props: IProps) {
  let patientGeneralInfoModel = props.patientGeneralInfoModel;

  const CalculateDate = (date: any) => {
    if (date != null) {
      let today = new Date();
      let patientDob = new Date(date);
      let age = today.getFullYear() - patientDob.getFullYear();
      return age + " Year Old";
    } else return "-";
  };
  const getData = (val: any) => {
    let data = "No";
    if (val) data = "Yes";
    return data;
  };
  const handleGender = (val: any) => {
    let value = "-";
    if (val == 1) value = "Male";
    else if (val == 2) value = "Female";
    else if (val == 3) value = "Others";
    return value;
  };
  const handleAdopted = (val: any) => {
    let value = "-";
    if (val == 1) value = "Yes";
    else if (val == 2) value = "No";
    return value;
  };
  const getFullName = (firstName: any, lastName: any) => {
    let name = "-";
    if (firstName) name = firstName + " ";
    if (lastName) name = name + lastName;
    return name;
  };
  const getFullAddress = (address1: any, address2: any, city: any, state: any, zipcode: any) => {
    let address = "-";
    if (address1) address = address1 + ", ";
    if (address2) address = address + address2 + ", ";
    if (city) address = address + city + ", ";
    if (state) address = address + state + ", ";
    if (zipcode) address = address + zipcode;
    return address;
  };
  const checkIfNullOrEmpty = (val: any) => {
    let data = "-";
    if (val == null || val == undefined || val == "") {
      data = "-";
    } else {
      data = val;
    }
    return data;
  };
  return (
    <div className="SMH__left">
      <div className="statusInfo__cover">
        <div className="user-details">
          <span className="name_wrap" style={{backgroundColor:props.bcColor}}>
            {" "}
            {checkIfNullOrEmpty(patientGeneralInfoModel.patientInfoModel?.initials)}
          </span>
          <p className="ccfChild_username">
            {patientGeneralInfoModel.patientInfoModel != null
              ? getFullName(
                  patientGeneralInfoModel.patientInfoModel.lastName + ",",
                  patientGeneralInfoModel.patientInfoModel.firstName
                )
              : "-"}
            <br></br>
            <span>
              MRN:
              {checkIfNullOrEmpty(patientGeneralInfoModel.patientInfoModel?.mr_No)}
            </span>{" "}
            <br />
            <span>
              {patientGeneralInfoModel.patientInfoModel != null
                ? CalculateDate(patientGeneralInfoModel.patientInfoModel.dateOfBirth)
                : "-"}
              {patientGeneralInfoModel.patientInfoModel != null
                ? ", " + handleGender(patientGeneralInfoModel.patientInfoModel.gender)
                : "-"}
            </span>
          </p>
        </div>
      </div>
      <div className="SMH__content">
        <ChildGuardianInfo
          designation="Grade"
          name={checkIfNullOrEmpty(patientGeneralInfoModel.patientInfoModel?.grade)}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo
          designation="Birthplace"
          name={checkIfNullOrEmpty(patientGeneralInfoModel.patientInfoModel?.birthPlace)}
          email=""
          guardian="guardianStyle"
        />
      </div>
      <div className="SMH__content">
        <ChildGuardianInfo
          designation="Adopted"
          name={handleAdopted(patientGeneralInfoModel.patientInfoModel?.adopted)}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo
          designation="Adopted Age"
          name={checkIfNullOrEmpty(patientGeneralInfoModel.patientInfoModel?.adoptedAge)}
          email=""
          guardian="guardianStyle"
        />
      </div>
      <hr />
      <Heading heading="Parent Details" subHeading="" styleName="medicalGeneralDetail__style" />
      <div className="SMH__content">
        <ChildGuardianInfo
          designation="Person Completing Form"
          name={checkIfNullOrEmpty(patientGeneralInfoModel.patientInfoModel?.personCompletingForm)}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo
          designation="Relationship"
          name={checkIfNullOrEmpty(
            patientGeneralInfoModel.patientInfoModel?.relationshipWithPersonCompletingForm
          )}
          email=""
          guardian="guardianStyle"
        />
      </div>
      <div className="SMH__content">
         <ChildGuardianInfo
          designation="Parent 1"
          name={checkIfNullOrEmpty(patientGeneralInfoModel.patientInfoModel?.parentModel.motherName)}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo
          designation="Home Phone"
          name={checkIfNullOrEmpty(patientGeneralInfoModel.patientInfoModel?.parentModel.motherHomePhoneNo)}
          email=""
          guardian="guardianStyle"
        />
      </div>
      <div className="SMH__content">
        <ChildGuardianInfo
          designation="Work Phone"
          name={checkIfNullOrEmpty(patientGeneralInfoModel.patientInfoModel?.parentModel.motherWorkPhoneNo)}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo
          designation="Address"
          name={
            patientGeneralInfoModel.patientInfoModel.parentModel.motherAddressModel != null
              ? getFullAddress(
                  patientGeneralInfoModel.patientInfoModel.parentModel.motherAddressModel.address1,
                  patientGeneralInfoModel.patientInfoModel.parentModel.motherAddressModel.address2,
                  patientGeneralInfoModel.patientInfoModel.parentModel.motherAddressModel.city,
                  patientGeneralInfoModel.patientInfoModel.parentModel.motherAddressModel.state,
                  patientGeneralInfoModel.patientInfoModel.parentModel.motherAddressModel.zipcode
                )
              : "-"
          }
          email=""
          guardian="guardianStyle"
        />
      </div>

      <div className="SMH__content">
         <ChildGuardianInfo
          designation="Parent 2"
          name={checkIfNullOrEmpty(patientGeneralInfoModel.patientInfoModel?.parentModel.fatherName)}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo
          designation="Home Phone"
          name={checkIfNullOrEmpty(patientGeneralInfoModel.patientInfoModel?.parentModel.fatherHomePhoneNo)}
          email=""
          guardian="guardianStyle"
        />
      </div>
      <div className="SMH__content">
        <ChildGuardianInfo
          designation="Work Phone"
          name={checkIfNullOrEmpty(patientGeneralInfoModel.patientInfoModel?.parentModel.fatherWorkPhoneNo)}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo
          designation="Address"
          name={
            patientGeneralInfoModel.patientInfoModel.parentModel.fatherAddressModel != null
              ? getFullAddress(
                  patientGeneralInfoModel.patientInfoModel.parentModel.fatherAddressModel.address1,
                  patientGeneralInfoModel.patientInfoModel.parentModel.fatherAddressModel.address2,
                  patientGeneralInfoModel.patientInfoModel.parentModel.fatherAddressModel.city,
                  patientGeneralInfoModel.patientInfoModel.parentModel.fatherAddressModel.state,
                  patientGeneralInfoModel.patientInfoModel.parentModel.fatherAddressModel.zipcode
                )
              : "-"
          }
          email=""
          guardian="guardianStyle"
        />
      </div>
      {/* // */}
      <div>
        <Loader loading={props.isSaving} marginBottom="0px" marginTop="8px" width="368px"></Loader>
      </div>
    
      
      <hr />
      <Heading heading="Teacher Details" subHeading="" styleName="medicalGeneralDetail__style" />
      <div className="SMH__content">
        <ChildGuardianInfo
          designation="Teacher Name"
          name={getFullName(patientGeneralInfoModel.tFirstName, patientGeneralInfoModel.tLastName)}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo
          designation="Email Id"
          name={checkIfNullOrEmpty(patientGeneralInfoModel?.tEmailId)}
          email=""
          guardian="guardianStyle"
        />
      </div>
      <div className="SMH__content">
        <ChildGuardianInfo
          designation="School District"
          name={checkIfNullOrEmpty(patientGeneralInfoModel?.schoolDistrict)}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo designation="School" name={checkIfNullOrEmpty(patientGeneralInfoModel?.schoolName)} email="" guardian="guardianStyle" />
        <ChildGuardianInfo
          designation="School Phone"
          name={checkIfNullOrEmpty(patientGeneralInfoModel?.schoolContactNo)}
          guardian="guardianStyle"
        />
      </div>
      <hr />
      <Heading heading="Current Placement" subHeading="" styleName="medicalGeneralDetail__style" />
      <div className="SMH__content">
        <ChildGuardianInfo
          designation="Regular Education"
          name={getData(
            patientGeneralInfoModel.patientInfoModel?.currentPlacementModel.regularEducation
          )}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo
          designation="Resource room"
          name={getData(
            patientGeneralInfoModel.patientInfoModel?.currentPlacementModel.resourceRoom
          )}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo
          designation="Special Education"
          name={getData(
            patientGeneralInfoModel.patientInfoModel?.currentPlacementModel.specialEducation
          )}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo
          designation="Other"
          name={checkIfNullOrEmpty(
            patientGeneralInfoModel.patientInfoModel?.currentPlacementModel
              .currentPlacementDescription
          )}
          email=""
          guardian="guardianStyle"
        />
      </div>
      <hr />
      <Heading
        heading="If special education"
        subHeading=""
        styleName="medicalGeneralDetail__style"
      />
      <div className="SMH__content">
        <ChildGuardianInfo
          designation="Learning disability"
          name={getData(
            patientGeneralInfoModel.patientInfoModel?.specialEducationModel.learningDisablity
          )}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo
          designation="Behavior disorder"
          name={getData(
            patientGeneralInfoModel.patientInfoModel?.specialEducationModel.behaviorDisorder
          )}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo
          designation="Development delay"
          name={getData(
            patientGeneralInfoModel.patientInfoModel?.specialEducationModel.developmentalDelay
          )}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo
          designation="Other"
          name={checkIfNullOrEmpty(
            patientGeneralInfoModel.patientInfoModel?.specialEducationModel
              .specialEducationDescription
          )}
          email=""
          guardian="guardianStyle"
        />
      </div>
      <hr />
      <Heading heading="Physician Details" subHeading="" styleName="medicalGeneralDetail__style" />
      <div className="SMH__content">
        <ChildGuardianInfo
          designation="Physician Name"
          name={
            patientGeneralInfoModel.patientInfoModel != null
              ? getFullName(
                  patientGeneralInfoModel.patientInfoModel.physicianModel.pFirstName,
                  patientGeneralInfoModel.patientInfoModel.physicianModel.pLastName
                )
              : "-"
          }
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo
          designation="Email Id"
          name={checkIfNullOrEmpty(
            patientGeneralInfoModel.patientInfoModel?.physicianModel.pEmailId
          )}
          email=""
          guardian="guardianStyle"
        />
      </div>
      <div className="SMH__content">
        <ChildGuardianInfo
          designation="Phone"
          name={checkIfNullOrEmpty(
            patientGeneralInfoModel.patientInfoModel?.physicianModel.pPhoneNo
          )}
          email=""
          guardian="guardianStyle"
        />
      </div>
      <hr />
      <Heading heading="Child Ethnicity" subHeading="" styleName="medicalGeneralDetail__style" />
      <div className="SMH__content">
        <ChildGuardianInfo
          designation="African-American"
          name={getData(
            patientGeneralInfoModel.patientInfoModel?.childsEthnicityModel.africanAmerican
          )}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo designation="Hispanic" name={getData(patientGeneralInfoModel.patientInfoModel?.childsEthnicityModel.hispanic)} email="" guardian="guardianStyle" />
        <ChildGuardianInfo
          designation="American Indian"
          name={getData(
            patientGeneralInfoModel.patientInfoModel?.childsEthnicityModel.americianIndian
          )}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo
          designation="Asian(Chinese, Japanese, Korean)"
          name={getData(patientGeneralInfoModel.patientInfoModel?.childsEthnicityModel.asian)}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo
          designation="Filipino"
          name={getData(patientGeneralInfoModel.patientInfoModel?.childsEthnicityModel.fillipino)}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo
          designation="Indian Subcontinent"
          name={getData(
            patientGeneralInfoModel.patientInfoModel?.childsEthnicityModel.indianSubcontinent
          )}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo
          designation="S.E. Asian(Vietnamese, Cambodian)"
          name={getData(patientGeneralInfoModel.patientInfoModel?.childsEthnicityModel.seAsian)}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo
          designation="Hawailian/Part Hawailian"
          name={getData(patientGeneralInfoModel.patientInfoModel?.childsEthnicityModel.hawaliain)}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo designation="Caucasian" name={getData(patientGeneralInfoModel.patientInfoModel?.childsEthnicityModel.caucasian)} email="" guardian="guardianStyle" />
        <ChildGuardianInfo
          designation="Other Pacific Islander"
          name={getData(
            patientGeneralInfoModel.patientInfoModel?.childsEthnicityModel.otherPacificIslander
          )}
          email=""
          guardian="guardianStyle"
        />
        <ChildGuardianInfo
          designation="Other"
          name={checkIfNullOrEmpty(
            patientGeneralInfoModel.patientInfoModel?.childsEthnicityModel
              .childsEthnicityDescription
          )}
          email=""
          guardian="guardianStyle"
        />
      </div>
    </div>
  );
}
