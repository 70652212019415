import { AddHomeCompositionPostModel } from "../../../../models/familyBackground/homeComposition/add-home-composition-post.model";
import React, { Fragment } from "react";
import { HttpResponse } from "../../../../core";
import { FamilyBackgroundService } from "../../../../services/familyBackground";
import { ShowSuccessMessage } from "../../../../shared/helpers";
import MedicalHomeCompositionForm from "./medicalHomeComposition";
interface IState {
    patientId: string;
    isLoading: boolean;
    homeCompositionList: AddHomeCompositionPostModel;
    isFormSave?: Function;
    familyBackGroundStepUpdate?: Function;
 
  }
  export class MedicalHomeComposition extends React.Component<any, IState> {
    private familyBackgroundService: FamilyBackgroundService;
  
    constructor(props) {
      super(props);
      this.state = this.getInitialState();
      this.familyBackgroundService = new FamilyBackgroundService();
    }
    private getInitialState() {
      let initialState: IState = {
        isLoading: false,
        patientId: this.props.patientId,
        homeCompositionList:{
            patientId: this.props.patientId,
            familyMemberComposition: [],
            nonFamilyMemberComposition:[]
          } as AddHomeCompositionPostModel,
      };
      return initialState;
    }
    componentDidMount() {
      this.loadData();
      window.scroll(0,0);
    }
  
    postData = (data) => {
      let homeCompositionPostData = data.homeCompositionListModel;
      if (this.state.isLoading) {
        return;
        }
        if (data.isAutoSave) {
            this.setLoading(false);
        }
        else {
            this.setLoading(true);
        }
      
      this.familyBackgroundService
        .postHomeComposition(homeCompositionPostData)
        .then((res: HttpResponse<boolean>) => {
          if (res && res.result) {
            this.props.familyBackGroundStepUpdate(4,res.result);
          }
          this.setLoading(false);
          if (!data.isAutoSave) {
            ShowSuccessMessage("Data Saved Successfully.");
            this.props.nextTabChange();
          }
          this.props.isFormSave();
          this.loadData(data.isAutoSave);
        })
        .catch((ex) => {
          this.setLoading(false);
        });
    };
    previousTabChange = () => {
      this.props.previousTabChange();
    };
  
    private setLoading(loading: boolean) {
      this.setState({
        isLoading: loading,
      });
    }
      loadData = (isAutoSave?: any) => {
          if (isAutoSave) {
              this.setLoading(false);
          }
          else {
              this.setLoading(true);
          }
      
      this.familyBackgroundService
        .getHomeCompositionDetail(this.props.patientId)
        .then((res: HttpResponse<AddHomeCompositionPostModel>) => {
          if (res && res.result) {
            //this.props.familyBackGroundStepUpdate(4,(res.result.familyMemberComposition.length>0 || res.result.nonFamilyMemberComposition.length>0)?true:null);
            this.setLoading(false);
            if (isAutoSave) {
              let pm = this.state.homeCompositionList;
              res.result.familyMemberComposition.forEach((item, index) => {
                pm.familyMemberComposition[index].id = item.id;
              });
              res.result.nonFamilyMemberComposition.forEach((item, index) => {
                pm.nonFamilyMemberComposition[index].id = item.id;
              });
              this.setState({
                homeCompositionList: {
                  patientId: this.props.patientId,
                  familyMemberComposition:pm.familyMemberComposition,
                  nonFamilyMemberComposition:pm.nonFamilyMemberComposition
                },
              });
            }
            else {
            this.setState({
              homeCompositionList: {
                ...this.state.homeCompositionList,
                familyMemberComposition:res.result.familyMemberComposition==null?[]:res.result.familyMemberComposition,
                nonFamilyMemberComposition:res.result.nonFamilyMemberComposition==null?[]:res.result.nonFamilyMemberComposition
              },
            });
          }
        }
        });
    };
    assignModel = (data: any) => {
      this.setState({
        homeCompositionList: data.homeCompositionListModel,
      });
    };
  
    deleteHomeComposition = (homeCompositionId) => {
      this.familyBackgroundService
        .deleteHomeComposition(homeCompositionId)
        .then((res: HttpResponse<boolean>) => {
          if (res && res.result) {
           // this.loadData();
          }
        });
    };
  
    render() {
      return (
        <Fragment>
          <MedicalHomeCompositionForm
            onSubmit={this.postData}
            isSaving={this.state.isLoading}
            patientId={this.props.patientId}
            assignModel={this.assignModel}
            homeCompositionList={this.state.homeCompositionList}
            previousTabChange={this.previousTabChange}
            deleteHomeComposition={this.deleteHomeComposition}
          />
        </Fragment>
      );
    }
  }
  