import React, { Component, Fragment } from "react";
import Heading from "../../../heading";
import { Button } from "antd";
import Tabs from "../../../tabs";
import ParentTeacherNote from "./ParentTeacherNote";
import notes from "../../../../assets/images/svg-icons/notes.svg";
import calendar from "../../../../assets/images/svg-icons/calendar-a.svg";
import whiteCal from "../../../../assets/images/svg-icons/calendar-g.svg";
import "./styles.css";
import { ParentOrTeacherRatingDataService } from "../../../../services/parentOrTeacherRating/parentOrTeacherRatingDataService";
import { HttpResponse } from "../../../../core";
import { Loader } from "../../../../shared/loaders";
import { ClinicianWeeklyData } from "../../../../models/clinicianWeeklyData/clinician-weekly-data.model";
import { ClinicianWeeklyDataService } from "../../../../services/clinicianWeeklyData/clinician-weekly-data.service";
import dayjs from "dayjs";
import RespondentWeeklyRatingTable from "./WeeklyRatingTable/RespondentWeeklyRatingTable";
import { RespondentWeeklyDataModel } from "../../../../models/Respondent/respondent-weekly-data.model";
import { RespondentDayData } from "../../../../models/Respondent/respondent-target-symptom-day-data.model";
import { ShowErrorMessage, ShowSuccessMessage, ShowWarningMessage } from "../../../../shared/helpers";

interface IProps {
  patientId: string;
  visible: boolean;
  hideForm: Function;
}
interface IState {
  isLoading: boolean;
  formVisible: boolean;
  tab: number;
  patientId: string;
  allRespondentData?: RespondentWeeklyDataModel;
  clinicianWeeklyData?: ClinicianWeeklyData;
  isFormVisible: boolean;
  targetOneAlias?:string;
  handleClickLink?: Function;
}
class RespondentRating extends Component<any, IState> {
  private parentOrTeacherRatingDataService: ParentOrTeacherRatingDataService;
  private clinicianWeeklyDataService: ClinicianWeeklyDataService;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.parentOrTeacherRatingDataService = new ParentOrTeacherRatingDataService();
    this.clinicianWeeklyDataService = new ClinicianWeeklyDataService();
    this.showForm = this.showForm.bind(this);
    this.hideForm = this.hideForm.bind(this);
  }
  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }
  componentDidMount() {
    this.loadParentOrTeacherRatingData();
  }
  handleClickLink = () => {
    this.props.handleClickLink(this.state.patientId);
}
  loadParentOrTeacherRatingData() {
    this.setLoading(true);
    if (this.state.patientId) {
      this.parentOrTeacherRatingDataService
        .getRespondentRatingData(this.state.patientId)
        .then((res: HttpResponse<RespondentWeeklyDataModel>) => {
          this.setLoading(false);
          if (res && res.result) {
            console.log("RespondentRatingResponse: ", res.result)

           this.setState({allRespondentData:res.result})
            if(this.state.allRespondentData!=null && this.state.allRespondentData.respondentTargetSymptomWeeklyDataDetail!=null && this.state.allRespondentData.respondentTargetSymptomWeeklyDataDetail.find(u=>u.isCurrentWeek==true)?.weekNo!=null){
           var weekNo=  this.state.allRespondentData.respondentTargetSymptomWeeklyDataDetail!=null && this.state.allRespondentData.respondentTargetSymptomWeeklyDataDetail.find(u=>u.isCurrentWeek==true)?.weekNo;
           if(weekNo){
            this.setState({tab:(weekNo-1)});
           }
           }
           this.setState({ isFormVisible: true });
           if(this.props.openNote){
            this.showForm();
          }
          if(res.result.targetOneAlias){
         this.setState({targetOneAlias:res.result.targetOneAlias});
          }
          }else{
            this.setState({ isFormVisible: false });
          }
        })
        .catch((ex) => {  this.setLoading(false);});

        this.clinicianWeeklyDataService
      .getClinicianWeeklyData(this.props.patientId)
      .then((res: HttpResponse<ClinicianWeeklyData>) => {
       
        this.setLoading(false);
        
        if (
          res &&
          res.result &&
          res.result.lstClinicianSideEffectsWeeklyData != null
        ) {
          this.setState({
            clinicianWeeklyData: res.result,
          });
          this.setState({ isFormVisible: true });
          if(this.props.openNote){
            this.showForm();
          }
        } else {
          this.setState({ isFormVisible: false });
        }
      })
      .catch(() => {
        this.setLoading(false);
      });
    }
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      formVisible: false,
      tab: 0,
      patientId: this.props.patientId,
      isFormVisible: true,
      targetOneAlias:""
    };
    return initialState;
  }
  showForm() {
    this.setState({
      formVisible: true,
    });
  }
  hideForm() {
    this.setState({
      formVisible: false,
    });
  }
  changeTab(tab: any) {
    this.setState({
      tab,
    });
  }
  noteChangedData = (respondentDayData: RespondentDayData) => {
    //this.setLoading(true);
    respondentDayData.modifiedAfterWeeksEnd = false;
    console.log(respondentDayData);
    this.parentOrTeacherRatingDataService
    .updateTargetDay(respondentDayData)
    .then((res: HttpResponse<boolean>) => {
      if (res && res.result){
        this.setLoading(false)
        //ShowSuccessMessage("Data Updated Successfully.");
      }
    })
    .catch(() => {
      this.setLoading(false);
      ShowWarningMessage("Data was not updated.");
    });
    

  }

  render() {
    const { formVisible, tab } = this.state;
    return (
      <Fragment>
        <ParentTeacherNote
        patientId={this.props.patientId}
          visible={formVisible}
          
          hideForm={this.hideForm}
        />
 {!this.state.isLoading &&  this.state.isFormVisible && ( <div className="weekly__container">
          <div className="SMH__viewHead">
            <Heading
              heading="Severity Rating given by Parent & Teacher"
              styleName="SMH__heading"
            />
            <Button
              type="primary"
              size="large"
              className="week__note"
              icon={<img src={notes} alt="notes" className="week__note_img" />}
              onClick={this.showForm}
            >
              View Parent/Teacher Notes
            </Button>
          </div>
          <div className="severity__rating">
            <p>
              <span>1</span>Normal; Not at all
            </p>
            <p>
              <span>2</span>Borderline Problem
            </p>
            <p>
              <span>3</span>Mild Problem
            </p>
            <p>
              <span>4</span>Moderate Problem
            </p>
            <p>
              <span>5</span>Marked Problem
            </p>
            <p>
              <span>6</span>Severe Problem
            </p>
            <p>
              <span>7</span>Most Extreme Problem
            </p>
          </div>
          {/* table and tabs */}
          <div className="rating__wrapper">
            <div className="rating__tabs">
              {this.state.allRespondentData && this.state.allRespondentData.respondentTargetSymptomWeeklyDataDetail && this.state.allRespondentData.respondentTargetSymptomWeeklyDataDetail.map(
                (item, index) => {
                  return (
                    <Fragment key={index+1}>
                      <Tabs key={"weeklyParentOrTeacherRating"+(index+1)}
                        styleName={item.weekType == 3 ? "rating__tab remove-cursor" : "rating__tab add-cursor"}
                        text={dayjs(item.weekStartDate).format("MM/DD/YYYY")}
                        steps=""
                        stepsAct=""
                        fullText={dayjs(item.weekStartDate).format("MM/DD/YYYY")}
                        familyTextName={"Week "+ (index+1)}
                        image={whiteCal}
                        activeImage={calendar}
                        tabChange={() => item.weekType!=3 &&  this.changeTab(index)}
                        selected={tab === index}
                      />
                    </Fragment>
                  );
                }
              )}
            </div>
            {this.state.allRespondentData && this.state.allRespondentData?.respondentTargetSymptomWeeklyDataDetail?.map(
                (item, index) => {
                  return(
               tab ==index && <RespondentWeeklyRatingTable key={index+1} clinicianWeeklyData={this.state.clinicianWeeklyData} data={this.state.allRespondentData} index={tab}  targetOneAlias={this.state.targetOneAlias} noteChangedData={this.noteChangedData} />
                  )
                })}
          </div>
        </div>
     )}
     {!this.state.isFormVisible && !this.state.isLoading && (
            <div className="text-center">
                    <h4 className="weekly__msg">The parent/teacher rating section will be available after the medication information has been added for this patient.
                    <br />
                            Click on <a className="link-text" onClick={() => this.handleClickLink()} href={void (0)}><u>link</u></a> to complete the medication information.
                            </h4>
            </div>
          )}
     {this.state.isLoading && (
                      <Loader loading={this.state.isLoading} />
                    )}
        </Fragment>
    );
  }
}

export default RespondentRating;
