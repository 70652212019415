import React, {Fragment, useState} from "react";
import "../../styles.css";
import Heading from "../../../../heading";
import { Button, Tooltip } from "antd";
import { UpOutlined } from "@ant-design/icons";
import WeekSliderBtn from "../../../../weekSliderBtn";
import WeekwiseColumn from "../../../../weekwiseColumn";
import { Popover } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import SeverityModal from "../../../../severityModal";
import { ClinicianWeeklyData } from "../../../../../models/clinicianWeeklyData/clinician-weekly-data.model";
import moment from "moment";
import { ClinicianWeeklyDataType } from "../../../../../shared/enums/clinicianWeeklyData/clinician-weekly-data-type";
import $ from 'jquery';
import ImprovementModal from "../../../../improvementModal";
import editGray from '../../../../../assets/images/svg-icons/edit-gray.svg';
import dayjs from "dayjs";

interface IProps {
  clinicianWeeklyData: ClinicianWeeklyData;
  styleName: any;
  assignWeeklyDataModel: Function;
}

export default function WeeklyTable(props: IProps) {
  const [ weekNum, setWeekNum] = useState({start: 1, end: 3});
  const [prevStyleUpdate, setPrevStyleUpdate] = useState('disabledbutton');
  const [nextStyleUpdate, setNextStyleUpdate] = useState('');

const showNext = () => {
  setWeekNum({start:3, end: 5});
  setPrevStyleUpdate("enabledbutton");
  setNextStyleUpdate("disabledbutton");
  $('.weeklyTable__container table thead th:nth-child(3)').css("display", "none");
  $('.weeklyTable__container table thead th:nth-child(4)').css("display", "none");
  $('.weeklyTable__container table tbody tr td:nth-child(3)').css("display", "none");
  $('.weeklyTable__container table tbody tr td:nth-child(4)').css("display", "none");
  $('.weeklyTable__container table thead th:nth-child(6)').css({display: "table-cell", transition : 'opacity 1s ease-in-out'});
  $('.weeklyTable__container table thead th:nth-child(7)').css({display: "table-cell", transition : 'opacity 1s ease-in-out'});
  $('.weeklyTable__container table tbody tr td:nth-child(6)').css({display: "table-cell", transition : 'opacity 1s ease-in-out'});
  $('.weeklyTable__container table tbody tr td:nth-child(7)').css({display: "table-cell", transition : 'opacity 1s ease-in-out'});
}

const showPrev = () => {
  setWeekNum({start:1, end: 3});
  setPrevStyleUpdate("disabledbutton");
  setNextStyleUpdate("enabledbutton");
  $('.weeklyTable__container table thead th:nth-child(6)').css("display", "none");
  $('.weeklyTable__container table thead th:nth-child(7)').css("display", "none");
  $('.weeklyTable__container table tbody tr td:nth-child(6)').css("display", "none");
  $('.weeklyTable__container table tbody tr td:nth-child(7)').css("display", "none");
  $('.weeklyTable__container table thead th:nth-child(3)').css({display: "table-cell", transition : 'opacity 1s ease-in-out'});
  $('.weeklyTable__container table thead th:nth-child(4)').css({display: "table-cell", transition : 'opacity 1s ease-in-out'});
  $('.weeklyTable__container table tbody tr td:nth-child(3)').css({display: "table-cell", transition : 'opacity 1s ease-in-out'});
  $('.weeklyTable__container table tbody tr td:nth-child(4)').css({display: "table-cell", transition : 'opacity 1s ease-in-out'});
}
  const getStatusStyle = (value?: number) => {
    let statusStyle = "";
    if (value != null) {
      if (value == 5) {
        statusStyle = "weekModerate";
      } else if (value > 5) {
        statusStyle = "weekExtreme";
      }
    }
    return statusStyle;
  };
  const getBorderStyle = (value?: number) => {
    let borderStyle = "";
    if (value != null) {
      if (value == 5) {
        borderStyle = "1px solid var(--color-fadeLightOrange)";
      } else if (value > 5) {
        borderStyle = "1px solid var(--color-reddish)";
      }
    }
    return borderStyle;
  };
  const weekDotStyle = (value?: number) => {
    let dotStyle = "";
    if (value != null) {
      if (value == 5) {
        dotStyle = "weekOrange";
      } else if (value > 5) {
        dotStyle = "weekExtremeDot";
      }
    }
    return dotStyle;
  };
  const getSeverityText = (value?: number) => {
    let severityText=""
    switch (value) {
      case 1:
        severityText="Normal; Not at all 1"
        break;
      case 2:
        severityText="Borderline Problem 2"
        break;
      case 4:
        severityText="Moderate Problem 4"
        break;
      case 3:
        severityText="Mild Problem 3"
        break;
      case 5:
        severityText="Marked Problem 5"
        break;
      case 6:
        severityText="Severe Problem 6"
        break;
      case 7:
        severityText="Most Extreme Problem 7"
        break;
      default: 
      severityText=""
        break;   
    }
    return severityText;
  };
  const getImprovementText = (value?: number) => {
    let improvementText=""
    switch (value) {
      case 1:
        improvementText="Very Much Imp (1)"
        break;
      case 2:
        improvementText="Improved (2)"
        break;
      case 3:
        improvementText="Minimally Imp (3)"
        break;
      case 4:
        improvementText="Unchanged (4)"
        break;
      case 5:
        improvementText="Minimally Worse (5)"
        break;
      case 6:
        improvementText="Worse (6)"
        break;
      case 7:
        improvementText="Very Much Worse (7)"
        break;
      default: 
      improvementText=""
        break;   
    }
    return improvementText;
  };
  const isSelected=(weekIndex?:number, propName?: string, value?: boolean)=>{
    if(weekIndex!=null && propName!=null){
      props.clinicianWeeklyData.lstClinicianAddImprovementsWeeklyData[weekIndex][propName]=value;
      props.assignWeeklyDataModel({
        clinicianWeeklyData: props.clinicianWeeklyData,
        clinicianWeeklyDataType: ClinicianWeeklyDataType.AddImprovements
        }); 
    }  
  }
  const scrollTop = () => {
    window.document.getElementById("scrollWrap")!.scrollTo({ top: 0, behavior: "smooth" });
  };
  
  const handleOnBlur= (patientTrialWeekId:string,rankedPatientTrialWeekId?:string, rank?:any) =>  {
    
      var data= props.clinicianWeeklyData.lstClinicianRankingWeeklyData.find(u=>u.patientTrialWeekId==patientTrialWeekId && u.rankedPatientTrialWeekId==rankedPatientTrialWeekId)
      if(data!=null){
        data.rank=rank;
      }
      props.assignWeeklyDataModel({
        clinicianWeeklyData: props.clinicianWeeklyData,
        clinicianWeeklyDataType: ClinicianWeeklyDataType.Ranking
        }); 
    
  }

  const handleOnTargetOneAliasBlur = (data?: any) => {
    if (data != null) {
      props.clinicianWeeklyData.targetOneAlias = data;
      props.assignWeeklyDataModel({
        clinicianWeeklyData: props.clinicianWeeklyData,
        clinicianWeeklyDataType: ClinicianWeeklyDataType.TargetOneAlias,
      });
    }
  };
  const rankingValueCheck = (rank) => {
    if (rank.target.value > 5 || rank.target.value.length > 1) {
      rank.target.value = ""
    }
}

  return (
    <div id="scrollWrap" className={`weeklyTable__container ${props.styleName}`}>
             {props.clinicianWeeklyData != null &&
          props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.length >
            0 && ( 
        <table>
          <thead>
          <tr className="side__effects">
            <th>
              <Heading heading="Side Effects" styleName="weekHeading" />
            </th>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                    <th style={{
                         backgroundColor: sideEffect.weekType==2 ? '#CCE4CB' : '',
                        }}>
                        {sideEffect.weekType === 2 && <span className="selected__dot"></span>}
                        {sideEffect.weekNo === weekNum.start && <WeekSliderBtn onClick={showPrev} btnStyle={`sliderLeft ${prevStyleUpdate}`} />}
                        {sideEffect.weekNo === weekNum.end && <WeekSliderBtn onClick={showNext} btnStyle={`sliderBtn ${nextStyleUpdate}`} />}
                      <Heading
                        heading={
                          index == 0
                            ? "Baseline (Visit 1)"
                            : "Week " + sideEffect.weekNo
                        }
                        subHeading={dayjs(sideEffect.weekStartDate).format(
                          "MM/DD/YYYY"
                        )}
                        styleName="weekwise__head"
                        ccfSubHeading="week__subHead"
                      />
                    </th>
                  </Fragment>
                );
              }
            )} 
          </tr> 
        </thead>
          <tbody>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Insomnia or trouble sleeping"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                 {sideEffect.weekType!=3 && (<td key={index + 1}>
                      <WeekwiseColumn
                        problemName={sideEffect.insomniaOrTroubleSleeping == null ? "Severity Rating" : ""}
                        marked={getSeverityText(sideEffect.insomniaOrTroubleSleeping)}
                        statusStyle={getStatusStyle(
                          sideEffect.insomniaOrTroubleSleeping
                        )}
                        border={getBorderStyle(
                          sideEffect.insomniaOrTroubleSleeping
                        )}
                        padding={
                          sideEffect.insomniaOrTroubleSleeping != null &&
                          sideEffect.insomniaOrTroubleSleeping > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          sideEffect.insomniaOrTroubleSleeping
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="insomniaOrTroubleSleeping"
                                popOverId={"divInsomniaOrTroubleSleeping"+(index+1)}
                              />
                            }
                            trigger="click"
                          >
                            <div id={"divInsomniaOrTroubleSleeping"+(index+1)}>
                            {sideEffect.insomniaOrTroubleSleeping ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.insomniaOrTroubleSleeping !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>
                  )} </Fragment>
                );
              }
            )}
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                 {sideEffect.weekType==3 &&  (<td rowSpan={24}>
                     <div className="future__weeks"> This Patient Severity columm will be available on  
                     <br/>
                    <span className="month__name"> {dayjs(sideEffect.weekStartDate).format(
                          "MMM"
                        )}</span>
                         <br/>
                         <span className="month__date">  {dayjs(sideEffect.weekStartDate).format(
                          "DD"
                        )}</span>
                       </div> 
                    </td>
                  )} </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Nightmares"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
               {sideEffect.weekType!=3 &&  (<td>
                      <WeekwiseColumn
                       problemName={sideEffect.nightmares == null ? "Severity Rating" : ""}
                       marked={getSeverityText(sideEffect.nightmares)}
                       statusStyle={getStatusStyle(
                         sideEffect.nightmares
                       )}
                       border={getBorderStyle(
                         sideEffect.nightmares
                       )}
                       padding={
                         sideEffect.nightmares != null &&
                         sideEffect.nightmares > 4
                           ? "8px 5px"
                           : ""
                       }
                       weekDot={weekDotStyle(
                         sideEffect.nightmares
                       )}
                       missedOut={false}
                       missed=""
                       popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="nightmares"
                                popOverId={"divNightmares"+(index+1)}
                              />
                            }
                            trigger="click"
                          >
                            <div id={"divNightmares"+(index+1)}>
                            {sideEffect.nightmares ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.nightmares !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                        </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Stares a lot or daydreams"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                    {sideEffect.weekType!=3 &&  ( <td>
                      <WeekwiseColumn
                        problemName={sideEffect.staresALotOrDayDreams == null ? "Severity Rating" : ""}
                        marked={getSeverityText(sideEffect.staresALotOrDayDreams)}
                        statusStyle={getStatusStyle(
                          sideEffect.staresALotOrDayDreams
                        )}
                        border={getBorderStyle(
                          sideEffect.staresALotOrDayDreams
                        )}
                        padding={
                          sideEffect.staresALotOrDayDreams != null &&
                          sideEffect.staresALotOrDayDreams > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          sideEffect.staresALotOrDayDreams
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="staresALotOrDayDreams"
                                popOverId={"divStaresALotOrDayDreams"+(index+1)}
                              />
                            }
                            trigger="click"
                          >
                             <div id={"divStaresALotOrDayDreams"+(index+1)}>
                            {sideEffect.staresALotOrDayDreams ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.staresALotOrDayDreams !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Talks less with others"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                    {sideEffect.weekType!=3 &&  ( <td>
                      <WeekwiseColumn
                        problemName={sideEffect.talkLessWithOthers == null ? "Severity Rating" : ""}
                        marked={getSeverityText(sideEffect.talkLessWithOthers)}
                        statusStyle={getStatusStyle(
                          sideEffect.talkLessWithOthers
                        )}
                        border={getBorderStyle(
                          sideEffect.talkLessWithOthers
                        )}
                        padding={
                          sideEffect.talkLessWithOthers != null &&
                          sideEffect.talkLessWithOthers > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          sideEffect.talkLessWithOthers
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                popOverId= {"divTalkLessWithOthers"+(index+1)}
                                propName="talkLessWithOthers"
                                key={"divTalkLessWithOthers"+(index+1)}
                              />
                            }
                            trigger="click"
                          >
                            <div id={"divTalkLessWithOthers"+(index+1)}>
                            {sideEffect.talkLessWithOthers ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.talkLessWithOthers !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                          </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}{" "}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Uninterested in others"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                    {sideEffect.weekType!=3 &&  ( <td>
                        <WeekwiseColumn
                        problemName={sideEffect.unInterestedWithOthers == null ? "Severity Rating" : ""}
                        marked={getSeverityText(sideEffect.unInterestedWithOthers)}
                        statusStyle={getStatusStyle(
                          sideEffect.unInterestedWithOthers
                        )}
                        border={getBorderStyle(
                          sideEffect.unInterestedWithOthers
                        )}
                        padding={
                          sideEffect.unInterestedWithOthers != null &&
                          sideEffect.unInterestedWithOthers > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          sideEffect.unInterestedWithOthers
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="unInterestedWithOthers"
                                popOverId= {"divUnInterestedWithOthers"+(index+1)}
                                key= {"divUnInterestedWithOthers"+(index+1)}
                              />
                            }
                            trigger="click"
                          ><div id={"divUnInterestedWithOthers"+(index+1)}>
                            {sideEffect.unInterestedWithOthers ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.unInterestedWithOthers !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Decreased appetite"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                   {sideEffect.weekType!=3 &&  (  <td>
                      <WeekwiseColumn
                        problemName={sideEffect.decreasedAppetite == null ? "Severity Rating" : ""}
                        marked={getSeverityText(sideEffect.decreasedAppetite)}
                        statusStyle={getStatusStyle(
                          sideEffect.decreasedAppetite
                        )}
                        border={getBorderStyle(
                          sideEffect.decreasedAppetite
                        )}
                        padding={
                          sideEffect.decreasedAppetite != null &&
                          sideEffect.decreasedAppetite > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          sideEffect.decreasedAppetite
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="decreasedAppetite"
                                popOverId= {"divDecreasedAppetite"+(index+1)}
                                key={"divDecreasedAppetite"+(index+1)}
                              />
                            }
                            trigger="click"
                          >
                            <div id={"divDecreasedAppetite"+(index+1)}>
                            {sideEffect.decreasedAppetite ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.decreasedAppetite !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Irritable"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                    {sideEffect.weekType!=3 &&  ( <td>
                      <WeekwiseColumn
                        problemName={sideEffect.irritable == null ? "Severity Rating" : ""}
                        marked={getSeverityText(sideEffect.irritable)}
                        statusStyle={getStatusStyle(
                          sideEffect.irritable
                        )}
                        border={getBorderStyle(
                          sideEffect.irritable
                        )}
                        padding={
                          sideEffect.irritable != null &&
                          sideEffect.irritable > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          sideEffect.irritable
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="irritable"
                                popOverId= {"divIrritable"+(index+1)}
                                key= {"divIrritable"+(index+1)}
                              />
                            }
                            trigger="click"
                          >
                            <div id={"divIrritable"+(index+1)}>
                            {sideEffect.irritable ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.irritable !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Stomachaches"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                   {sideEffect.weekType!=3 &&  (  <td>
                      <WeekwiseColumn
                        problemName={sideEffect.stomachaches == null ? "Severity Rating" : ""}
                        marked={getSeverityText(sideEffect.stomachaches)}
                        statusStyle={getStatusStyle(
                          sideEffect.stomachaches
                        )}
                        border={getBorderStyle(
                          sideEffect.stomachaches
                        )}
                        padding={
                          sideEffect.stomachaches != null &&
                          sideEffect.stomachaches > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          sideEffect.stomachaches
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="stomachaches"
                                popOverId= {"divStomachaches"+(index+1)}
                                key= {"divStomachaches"+(index+1)}
                              />
                            }
                            trigger="click"
                          ><div id={"divStomachaches"+(index+1)}>
                            {sideEffect.stomachaches ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.stomachaches !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Headaches"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                  {sideEffect.weekType!=3 &&  (  <td>
                      <WeekwiseColumn
                        problemName={sideEffect.headaches == null ? "Severity Rating" : ""}
                        marked={getSeverityText(sideEffect.headaches)}
                        statusStyle={getStatusStyle(
                          sideEffect.headaches
                        )}
                        border={getBorderStyle(
                          sideEffect.headaches
                        )}
                        padding={
                          sideEffect.headaches != null &&
                          sideEffect.headaches > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          sideEffect.headaches
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="headaches"
                                popOverId= {"divHeadaches"+(index+1)}
                                key= {"divHeadaches"+(index+1)}
                              />
                            }
                            trigger="click"
                          >
                            <div id={"divHeadaches"+(index+1)}>
                            {sideEffect.headaches ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.headaches !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Drowsiness"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                    {sideEffect.weekType!=3 &&  (<td>
                      <WeekwiseColumn
                        problemName={sideEffect.drowsiness == null ? "Severity Rating" : ""}
                        marked={getSeverityText(sideEffect.drowsiness)}
                        statusStyle={getStatusStyle(
                          sideEffect.drowsiness
                        )}
                        border={getBorderStyle(
                          sideEffect.drowsiness
                        )}
                        padding={
                          sideEffect.drowsiness != null &&
                          sideEffect.drowsiness > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          sideEffect.drowsiness
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="drowsiness"
                                popOverId= {"divDrowsiness"+(index+1)}
                                key= {"divDrowsiness"+(index+1)}
                              />
                            }
                            trigger="click"
                          ><div id={"divDrowsiness"+(index+1)}>
                            {sideEffect.drowsiness ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.drowsiness !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Sad/Unhappy"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                    {sideEffect.weekType!=3 &&  (<td>
                      <WeekwiseColumn
                       problemName={sideEffect.sadOrUnhappy == null ? "Severity Rating" : ""}
                       marked={getSeverityText(sideEffect.sadOrUnhappy)}
                       statusStyle={getStatusStyle(
                         sideEffect.sadOrUnhappy
                       )}
                       border={getBorderStyle(
                         sideEffect.sadOrUnhappy
                       )}
                       padding={
                         sideEffect.sadOrUnhappy != null &&
                         sideEffect.sadOrUnhappy > 4
                           ? "8px 5px"
                           : ""
                       }
                       weekDot={weekDotStyle(
                         sideEffect.sadOrUnhappy
                       )}
                       missedOut={false}
                       missed=""
                       popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="sadOrUnhappy"
                                popOverId= {"divSadOrUnhappy"+(index+1)}
                                key= {"divSadOrUnhappy"+(index+1)}
                              />
                            }
                            trigger="click"
                          ><div id={"divSadOrUnhappy"+(index+1)}>
                            {sideEffect.sadOrUnhappy ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.sadOrUnhappy !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Prone to crying/easily upset"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                   {sideEffect.weekType!=3 &&  ( <td>
                      <WeekwiseColumn
                       problemName={sideEffect.pronetoCryingOrEasilyUpset == null ? "Severity Rating" : ""}
                        marked={getSeverityText(sideEffect.pronetoCryingOrEasilyUpset)}
                        statusStyle={getStatusStyle(
                          sideEffect.pronetoCryingOrEasilyUpset
                        )}
                        border={getBorderStyle(
                          sideEffect.pronetoCryingOrEasilyUpset
                        )}
                        padding={
                          sideEffect.pronetoCryingOrEasilyUpset != null &&
                          sideEffect.pronetoCryingOrEasilyUpset > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          sideEffect.pronetoCryingOrEasilyUpset
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="pronetoCryingOrEasilyUpset"
                                popOverId= {"divPronetoCryingOrEasilyUpset"+(index+1)}
                                key= {"divPronetoCryingOrEasilyUpset"+(index+1)}
                              />
                            }
                            trigger="click"
                          ><div id={"divPronetoCryingOrEasilyUpset"+(index+1)}>
                            {sideEffect.pronetoCryingOrEasilyUpset ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.pronetoCryingOrEasilyUpset !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Anxious/worried"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                   {sideEffect.weekType!=3 &&  ( <td>
                      <WeekwiseColumn
                        problemName={sideEffect.anxiuosOrWorried == null ? "Severity Rating" : ""}
                        marked={getSeverityText(sideEffect.anxiuosOrWorried)}
                        statusStyle={getStatusStyle(
                          sideEffect.anxiuosOrWorried
                        )}
                        border={getBorderStyle(
                          sideEffect.anxiuosOrWorried
                        )}
                        padding={
                          sideEffect.anxiuosOrWorried != null &&
                          sideEffect.anxiuosOrWorried > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          sideEffect.anxiuosOrWorried
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="anxiuosOrWorried"
                                popOverId= {"divAnxiuosOrWorried"+(index+1)}
                                key= {"divAnxiuosOrWorried"+(index+1)}
                              />
                            }
                            trigger="click"
                          >
                            <div id={"divAnxiuosOrWorried"+(index+1)}>
                            {sideEffect.anxiuosOrWorried ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.anxiuosOrWorried !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Perseveration (verbal or behavioral)"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                   {sideEffect.weekType!=3 &&  ( <td>
                      <WeekwiseColumn
                       problemName={sideEffect.perseveration == null ? "Severity Rating" : ""}
                        marked={getSeverityText(sideEffect.perseveration)}
                        statusStyle={getStatusStyle(
                          sideEffect.perseveration
                        )}
                        border={getBorderStyle(
                          sideEffect.perseveration
                        )}
                        padding={
                          sideEffect.perseveration != null &&
                          sideEffect.perseveration > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          sideEffect.perseveration
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="perseveration"
                                popOverId= {"divPerseveration"+(index+1)}
                                key= {"divPerseveration"+(index+1)}
                              />
                            }
                            trigger="click"
                          ><div id={"divPerseveration"+(index+1)}>                          
                            {sideEffect.perseveration ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.perseveration !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Bites/picks skin or fingernails"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                    {sideEffect.weekType!=3 &&  (<td>
                      <WeekwiseColumn
                        problemName={sideEffect.fingernails == null ? "Severity Rating" : ""}
                        marked={getSeverityText(sideEffect.fingernails)}
                        statusStyle={getStatusStyle(
                          sideEffect.fingernails
                        )}
                        border={getBorderStyle(
                          sideEffect.fingernails
                        )}
                        padding={
                          sideEffect.fingernails != null &&
                          sideEffect.fingernails > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          sideEffect.fingernails
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="fingernails"
                                popOverId= {"divFingernails"+(index+1)}
                                key= {"divFingernails"+(index+1)}
                              />
                            }
                            trigger="click"
                          >
                            <div id={"divFingernails"+(index+1)}>
                            {sideEffect.fingernails ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.fingernails !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                          </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Euphoric/Unusally happy/Mania"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                   {sideEffect.weekType!=3 &&  ( <td>
                      <WeekwiseColumn
                        problemName={sideEffect.euphoricOrUnusuallyHappyOrMania == null ? "Severity Rating" : ""}
                        marked={getSeverityText(sideEffect.euphoricOrUnusuallyHappyOrMania)}
                        statusStyle={getStatusStyle(
                          sideEffect.euphoricOrUnusuallyHappyOrMania
                        )}
                        border={getBorderStyle(
                          sideEffect.euphoricOrUnusuallyHappyOrMania
                        )}
                        padding={
                          sideEffect.euphoricOrUnusuallyHappyOrMania != null &&
                          sideEffect.euphoricOrUnusuallyHappyOrMania > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          sideEffect.euphoricOrUnusuallyHappyOrMania
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="euphoricOrUnusuallyHappyOrMania"
                                popOverId= {"divEuphoricOrUnusuallyHappyOrMania"+(index+1)}
                                key= {"divEuphoricOrUnusuallyHappyOrMania"+(index+1)}
                              />
                            }
                            trigger="click"
                          >
                            <div id={"divEuphoricOrUnusuallyHappyOrMania"+(index+1)}>
                            {sideEffect.euphoricOrUnusuallyHappyOrMania ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.euphoricOrUnusuallyHappyOrMania !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                          </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Dizziness"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                   {sideEffect.weekType!=3 &&  ( <td>
                      <WeekwiseColumn
                        problemName={sideEffect.dizziness == null ? "Severity Rating" : ""}
                        marked={getSeverityText(sideEffect.dizziness)}
                        statusStyle={getStatusStyle(
                          sideEffect.dizziness
                        )}
                        border={getBorderStyle(
                          sideEffect.dizziness
                        )}
                        padding={
                          sideEffect.dizziness != null &&
                          sideEffect.dizziness > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          sideEffect.dizziness
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="dizziness"
                                popOverId= {"divDizziness"+(index+1)}
                                key= {"divDizziness"+(index+1)}
                              />
                            }
                            trigger="click"
                          ><div id={"divDizziness"+(index+1)}>
                            {sideEffect.dizziness ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.dizziness !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Tics or nervous movements"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                   {sideEffect.weekType!=3 &&  ( <td>
                      <WeekwiseColumn
                        problemName={sideEffect.ticsOrNervousMovement == null ? "Severity Rating" : ""}
                        marked={getSeverityText(sideEffect.ticsOrNervousMovement)}
                        statusStyle={getStatusStyle(
                          sideEffect.ticsOrNervousMovement
                        )}
                        border={getBorderStyle(
                          sideEffect.ticsOrNervousMovement
                        )}
                        padding={
                          sideEffect.ticsOrNervousMovement != null &&
                          sideEffect.ticsOrNervousMovement > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          sideEffect.ticsOrNervousMovement
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="ticsOrNervousMovement"
                                popOverId= {"divTicsOrNervousMovement"+(index+1)}
                                key= {"divTicsOrNervousMovement"+(index+1)}
                              />
                            }
                            trigger="click"
                          ><div id={"divTicsOrNervousMovement"+(index+1)}>
                            {sideEffect.ticsOrNervousMovement ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.ticsOrNervousMovement !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Overfocused (tunes others out)"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                  {sideEffect.weekType!=3 &&  (  <td>
                      <WeekwiseColumn
                        problemName={sideEffect.overfocused == null ? "Severity Rating" : ""}
                        marked={getSeverityText(sideEffect.overfocused)}
                        statusStyle={getStatusStyle(
                          sideEffect.overfocused
                        )}
                        border={getBorderStyle(
                          sideEffect.overfocused
                        )}
                        padding={
                          sideEffect.overfocused != null &&
                          sideEffect.overfocused > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          sideEffect.overfocused
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="overfocused"
                                popOverId= {"divOverfocused"+(index+1)}
                                key= {"divOverfocused"+(index+1)}
                              />
                            }
                            trigger="click"
                          >
                            <div id={"divOverfocused"+(index+1)}>
                            {sideEffect.overfocused ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.overfocused !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading={`"Rebound" effects`}
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                    {sideEffect.weekType!=3 &&  !sideEffect.isBaseLine &&  (<td>
                      <WeekwiseColumn
                        problemName={sideEffect.reboundEffects == null ? "Severity Rating" : ""}
                        marked={getSeverityText(sideEffect.reboundEffects)}
                        statusStyle={getStatusStyle(
                          sideEffect.reboundEffects
                        )}
                        border={getBorderStyle(
                          sideEffect.reboundEffects
                        )}
                        padding={
                          sideEffect.reboundEffects != null &&
                          sideEffect.reboundEffects > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          sideEffect.reboundEffects
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="reboundEffects"
                                popOverId= {"divReboundEffects"+(index+1)}
                                key= {"divReboundEffects"+(index+1)}
                              />
                            }
                            trigger="click"
                          ><div id={"divReboundEffects"+(index+1)}>
                            {sideEffect.reboundEffects ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.reboundEffects !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>
                         ,
                        ]}
                      />
                    </td>)}
                    {sideEffect.weekType!=3 &&   sideEffect.isBaseLine &&  (<td style={{backgroundColor:"var(--color-grey)"}}>
                      <WeekwiseColumn
                        problemName=""
                        marked=""
                        statusStyle=""
                        border=""
                        padding=""
                        weekDot=""
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[

                        ]}
                      />
                    </td>)}
                 
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Hallucinosis"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                    {sideEffect.weekType!=3 &&  (<td>
                      <WeekwiseColumn
                       problemName={sideEffect.hallucinosis == null ? "Severity Rating" : ""}
                       marked={getSeverityText(sideEffect.hallucinosis)}
                       statusStyle={getStatusStyle(
                         sideEffect.hallucinosis
                       )}
                       border={getBorderStyle(
                         sideEffect.hallucinosis
                       )}
                       padding={
                         sideEffect.hallucinosis != null &&
                         sideEffect.hallucinosis > 4
                           ? "8px 5px"
                           : ""
                       }
                       weekDot={weekDotStyle(
                         sideEffect.hallucinosis
                       )}
                       missedOut={false}
                       missed=""
                       popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="hallucinosis"
                                popOverId= {"divHallucinosis"+(index+1)}
                                key= {"divHallucinosis"+(index+1)}
                              />
                            }
                            trigger="click"
                          ><div id={"divHallucinosis"+(index+1)}>
                            {sideEffect.hallucinosis ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.hallucinosis !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Flat affect/Emotional blunting"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                   {sideEffect.weekType!=3 &&  ( <td>
                      <WeekwiseColumn
                       problemName={sideEffect.flatAffectOrEmotionalBlunting == null ? "Severity Rating" : ""}
                        marked={getSeverityText(sideEffect.flatAffectOrEmotionalBlunting)}
                        statusStyle={getStatusStyle(
                          sideEffect.flatAffectOrEmotionalBlunting
                        )}
                        border={getBorderStyle(
                          sideEffect.flatAffectOrEmotionalBlunting
                        )}
                        padding={
                          sideEffect.flatAffectOrEmotionalBlunting != null &&
                          sideEffect.flatAffectOrEmotionalBlunting > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          sideEffect.flatAffectOrEmotionalBlunting
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="flatAffectOrEmotionalBlunting"
                                popOverId= {"divFlatAffectOrEmotionalBlunting"+(index+1)}
                                key= {"divFlatAffectOrEmotionalBlunting"+(index+1)}
                              />
                            }
                            trigger="click"
                          >
                            <div id={"divFlatAffectOrEmotionalBlunting"+(index+1)}>
                            {sideEffect.flatAffectOrEmotionalBlunting ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.flatAffectOrEmotionalBlunting !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Dry mouth"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                   {sideEffect.weekType!=3 &&  ( <td>
                      <WeekwiseColumn
                        problemName={sideEffect.dryMouth == null ? "Severity Rating" : ""}
                        marked={getSeverityText(sideEffect.dryMouth)}
                        statusStyle={getStatusStyle(
                          sideEffect.dryMouth
                        )}
                        border={getBorderStyle(
                          sideEffect.dryMouth
                        )}
                        padding={
                          sideEffect.dryMouth != null &&
                          sideEffect.dryMouth > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          sideEffect.dryMouth
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="dryMouth"
                                popOverId= {"divDryMouth"+(index+1)}
                                key= {"divDryMouth"+(index+1)}
                              />
                            }
                            trigger="click"
                          >
                            <div id={"divDryMouth"+(index+1)}>
                            {sideEffect.dryMouth ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.dryMouth !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Numbness or tingling in extremities"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (sideEffect, index) => {
                return (
                  <Fragment>
                    {sideEffect.weekType!=3 &&  (<td>
                      <WeekwiseColumn
                        problemName={sideEffect.numbnessOrTinglingInExtremities == null ? "Severity Rating" : ""}
                        marked={getSeverityText(sideEffect.numbnessOrTinglingInExtremities)}
                        statusStyle={getStatusStyle(
                          sideEffect.numbnessOrTinglingInExtremities
                        )}
                        border={getBorderStyle(
                          sideEffect.numbnessOrTinglingInExtremities
                        )}
                        padding={
                          sideEffect.numbnessOrTinglingInExtremities != null &&
                          sideEffect.numbnessOrTinglingInExtremities > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          sideEffect.numbnessOrTinglingInExtremities
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.SideEffects}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="numbnessOrTinglingInExtremities"
                                popOverId= {"divNumbnessOrTinglingInExtremities"+(index+1)}
                                key= {"divNumbnessOrTinglingInExtremities"+(index+1)}
                              />
                            }
                            trigger="click"
                          ><div id={"divNumbnessOrTinglingInExtremities"+(index+1)}>
                            {sideEffect.numbnessOrTinglingInExtremities ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {sideEffect.numbnessOrTinglingInExtremities !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
        </tbody>
          <thead>
          <tr className="history__sympt">
            <th>
              <Heading
                heading="History: Target Symptoms"
                styleName="weekHeading"
              />
            </th>
          </tr>
        </thead>
          <tbody>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Overactivity; motor restlessness"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianTargetSymptomsWeeklyData.map(
              (targetSymptoms, index) => {
                return (
                  <Fragment>
                    {targetSymptoms.weekType!=3 &&  (<td>
                      <WeekwiseColumn
                        problemName={targetSymptoms.overActivity == null ? "Severity Rating" : ""}
                        marked={getSeverityText(targetSymptoms.overActivity)}
                        statusStyle={getStatusStyle(
                          targetSymptoms.overActivity
                        )}
                        border={getBorderStyle(
                          targetSymptoms.overActivity
                        )}
                        padding={
                          targetSymptoms.overActivity != null &&
                          targetSymptoms.overActivity > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          targetSymptoms.overActivity
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.TargetSymptoms}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="overActivity"
                                popOverId={"divOverActivity"+(index+1)}
                                key={"divOverActivity"+(index+1)}
                              />
                            }
                            trigger="click"
                          ><div id={"divOverActivity"+(index+1)}>
                            {targetSymptoms.overActivity ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {targetSymptoms.overActivity !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>

          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Impulsiveness; acting w/o thinking"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianTargetSymptomsWeeklyData.map(
              (targetSymptoms, index) => {
                return (
                  <Fragment>
                   {targetSymptoms.weekType!=3 && ( <td>
                      <WeekwiseColumn
                        problemName={targetSymptoms.impulsiveness == null ? "Severity Rating" : ""}
                        marked={getSeverityText(targetSymptoms.impulsiveness)}
                        statusStyle={getStatusStyle(
                          targetSymptoms.impulsiveness
                        )}
                        border={getBorderStyle(
                          targetSymptoms.impulsiveness
                        )}
                        padding={
                          targetSymptoms.impulsiveness != null &&
                          targetSymptoms.impulsiveness > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          targetSymptoms.impulsiveness
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.TargetSymptoms}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="impulsiveness"
                                popOverId={"divImpulsiveness"+(index+1)}
                                key={"divImpulsiveness"+(index+1)}
                              />
                            }
                            trigger="click"
                          ><div id={"divImpulsiveness"+(index+1)}>
                            {targetSymptoms.impulsiveness ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {targetSymptoms.impulsiveness !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Distractability; sustaining attention to tasks"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianTargetSymptomsWeeklyData.map(
              (targetSymptoms, index) => {
                return (
                  <Fragment>
                   {targetSymptoms.weekType!=3 && ( <td>
                      <WeekwiseColumn
                        problemName={targetSymptoms.distractability == null ? "Severity Rating" : ""}
                        marked={getSeverityText(targetSymptoms.distractability)}
                        statusStyle={getStatusStyle(
                          targetSymptoms.distractability
                        )}
                        border={getBorderStyle(
                          targetSymptoms.distractability
                        )}
                        padding={
                          targetSymptoms.distractability != null &&
                          targetSymptoms.distractability > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          targetSymptoms.distractability
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.TargetSymptoms}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="distractability"
                                popOverId={"divDistractability"+(index+1)}
                                key={"divDistractability"+(index+1)}
                              />
                            }
                            trigger="click"
                          ><div id={"divDistractability"+(index+1)}>
                            {targetSymptoms.distractability ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {targetSymptoms.distractability !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Task completion; finishing tasks"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianTargetSymptomsWeeklyData.map(
              (targetSymptoms, index) => {
                return (
                  <Fragment>
                    {targetSymptoms.weekType!=3 && (<td>
                      <WeekwiseColumn
                        problemName={targetSymptoms.taskCompletion == null ? "Severity Rating" : ""}
                        marked={getSeverityText(targetSymptoms.taskCompletion)}
                        statusStyle={getStatusStyle(
                          targetSymptoms.taskCompletion
                        )}
                        border={getBorderStyle(
                          targetSymptoms.taskCompletion
                        )}
                        padding={
                          targetSymptoms.taskCompletion != null &&
                          targetSymptoms.taskCompletion > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          targetSymptoms.taskCompletion
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.TargetSymptoms}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="taskCompletion"
                                popOverId={"divTaskCompletion"+(index+1)}
                                key={"divTaskCompletion"+(index+1)}
                              />
                            }
                            trigger="click"
                          ><div id={"divTaskCompletion"+(index+1)}>
                            {targetSymptoms.taskCompletion ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {targetSymptoms.taskCompletion !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Being on time; accepting limits"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianTargetSymptomsWeeklyData.map(
              (targetSymptoms, index) => {
                return (
                  <Fragment>
                    {targetSymptoms.weekType!=3 && ( <td>
                      <WeekwiseColumn
                    problemName={targetSymptoms.beingOnTime == null ? "Severity Rating" : ""}
                    marked={getSeverityText(targetSymptoms.beingOnTime)}
                    statusStyle={getStatusStyle(
                      targetSymptoms.beingOnTime
                    )}
                    border={getBorderStyle(
                      targetSymptoms.beingOnTime
                    )}
                    padding={
                      targetSymptoms.beingOnTime != null &&
                      targetSymptoms.beingOnTime > 4
                        ? "8px 5px"
                        : ""
                    }
                    weekDot={weekDotStyle(
                      targetSymptoms.beingOnTime
                    )}
                    missedOut={false}
                     missed=""
                     popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.TargetSymptoms}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="beingOnTime"
                                popOverId={"divBeingOnTime"+(index+1)}
                                key={"divBeingOnTime"+(index+1)}
                              />
                            }
                            trigger="click"
                          ><div id={"divBeingOnTime"+(index+1)}>
                            {targetSymptoms.beingOnTime ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {targetSymptoms.beingOnTime !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Following instructions"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianTargetSymptomsWeeklyData.map(
              (targetSymptoms, index) => {
                return (
                  <Fragment>
                   {targetSymptoms.weekType!=3 && ( <td>
                      <WeekwiseColumn
                        problemName={targetSymptoms.followingInstructions == null ? "Severity Rating" : ""}
                        marked={getSeverityText(targetSymptoms.followingInstructions)}
                        statusStyle={getStatusStyle(
                          targetSymptoms.followingInstructions
                        )}
                        border={getBorderStyle(
                          targetSymptoms.followingInstructions
                        )}
                        padding={
                          targetSymptoms.followingInstructions != null &&
                          targetSymptoms.followingInstructions > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          targetSymptoms.followingInstructions
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.TargetSymptoms}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="followingInstructions"
                                popOverId={"divFollowingInstructions"+(index+1)}
                                key={"divFollowingInstructions"+(index+1)}
                              />
                            }
                            trigger="click"
                          ><div id={"divFollowingInstructions"+(index+1)}>
                            {targetSymptoms.followingInstructions ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {targetSymptoms.followingInstructions !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Frustration tolerance; appropriately expressing frustration"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianTargetSymptomsWeeklyData.map(
              (targetSymptoms, index) => {
                return (
                  <Fragment>
                    {targetSymptoms.weekType!=3 && ( <td>
                      <WeekwiseColumn
                        problemName={targetSymptoms.frustrationTolerance == null ? "Severity Rating" : ""}
                        marked={getSeverityText(targetSymptoms.frustrationTolerance)}
                        statusStyle={getStatusStyle(
                          targetSymptoms.frustrationTolerance
                        )}
                        border={getBorderStyle(
                          targetSymptoms.frustrationTolerance
                        )}
                        padding={
                          targetSymptoms.frustrationTolerance != null &&
                          targetSymptoms.frustrationTolerance > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          targetSymptoms.frustrationTolerance
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.TargetSymptoms}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="frustrationTolerance"
                                popOverId={"divFrustrationTolerance"+(index+1)}
                                key={"divFrustrationTolerance"+(index+1)}
                                />
                            }
                            trigger="click"
                          ><div id={"divFrustrationTolerance"+(index+1)}>
                            {targetSymptoms.frustrationTolerance ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {targetSymptoms.frustrationTolerance !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Ability to calm self when excited"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianTargetSymptomsWeeklyData.map(
              (targetSymptoms, index) => {
                return (
                  <Fragment>
                   {targetSymptoms.weekType!=3 && ( <td>
                      <WeekwiseColumn
                        problemName={targetSymptoms.abilitytoCalmSelfWhenExcited == null ? "Severity Rating" : ""}
                         marked={getSeverityText(targetSymptoms.abilitytoCalmSelfWhenExcited)}
                         statusStyle={getStatusStyle(
                           targetSymptoms.abilitytoCalmSelfWhenExcited
                         )}
                         border={getBorderStyle(
                           targetSymptoms.abilitytoCalmSelfWhenExcited
                         )}
                         padding={
                           targetSymptoms.abilitytoCalmSelfWhenExcited != null &&
                           targetSymptoms.abilitytoCalmSelfWhenExcited > 4
                             ? "8px 5px"
                             : ""
                         }
                         weekDot={weekDotStyle(
                           targetSymptoms.abilitytoCalmSelfWhenExcited
                         )}
                         missedOut={false}
                         missed=""
                         popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.TargetSymptoms}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="abilitytoCalmSelfWhenExcited"
                                popOverId={"divAbilitytoCalmSelfWhenExcited"+(index+1)}
                                key={"divAbilitytoCalmSelfWhenExcited"+(index+1)}
                              />
                            }
                            trigger="click"
                          ><div id={"divAbilitytoCalmSelfWhenExcited"+(index+1)}>
                            {targetSymptoms.abilitytoCalmSelfWhenExcited ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {targetSymptoms.abilitytoCalmSelfWhenExcited !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Peer relations"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianTargetSymptomsWeeklyData.map(
              (targetSymptoms, index) => {
                return (
                  <Fragment>
                  {targetSymptoms.weekType!=3 && (  <td>
                      <WeekwiseColumn
                        problemName={targetSymptoms.peerRelations == null ? "Severity Rating" : ""}
                        marked={getSeverityText(targetSymptoms.peerRelations)}
                        statusStyle={getStatusStyle(
                          targetSymptoms.peerRelations
                        )}
                        border={getBorderStyle(
                          targetSymptoms.peerRelations
                        )}
                        padding={
                          targetSymptoms.peerRelations != null &&
                          targetSymptoms.peerRelations > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          targetSymptoms.peerRelations
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.TargetSymptoms}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="peerRelations"
                                popOverId={"divPeerRelations"+(index+1)}
                                key={"divPeerRelations"+(index+1)}
                              />
                            }
                            trigger="click"
                          ><div id={"divPeerRelations"+(index+1)}>
                            {targetSymptoms.peerRelations ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {targetSymptoms.peerRelations !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading=""
                subHeading="Adults relations"
                ccfSubHeading="week__subCol"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianTargetSymptomsWeeklyData.map(
              (targetSymptoms, index) => {
                return (
                  <Fragment>
                  {targetSymptoms.weekType!=3 && (  <td>
                      <WeekwiseColumn
                         problemName={targetSymptoms.adultsRelations == null ? "Severity Rating" : ""}
                         marked={getSeverityText(targetSymptoms.adultsRelations)}
                         statusStyle={getStatusStyle(
                           targetSymptoms.adultsRelations
                         )}
                         border={getBorderStyle(
                           targetSymptoms.adultsRelations
                         )}
                         padding={
                           targetSymptoms.adultsRelations != null &&
                           targetSymptoms.adultsRelations > 4
                             ? "8px 5px"
                             : ""
                         }
                         weekDot={weekDotStyle(
                           targetSymptoms.adultsRelations
                         )}
                         missedOut={false}
                         missed=""
                         popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.TargetSymptoms}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="adultsRelations"
                                popOverId={"divAdultsRelations"+(index+1)}
                                key={"divAdultsRelations"+(index+1)}
                              />
                            }
                            trigger="click"
                          ><div id={"divAdultsRelations"+(index+1)}>
                            {targetSymptoms.adultsRelations ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {targetSymptoms.adultsRelations !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>
                          ,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
            <div className="medical-group padd__hoz" key="divTarget1">  
            <input
                  type="text"
                  name="Target1"
                  id="Target1"
                  key="Target1"
                  className="form-control phone_border width-unset"
                  defaultValue={props.clinicianWeeklyData.targetOneAlias}
                  onBlur={(e) => handleOnTargetOneAliasBlur(e.target.value)}
                /></div>
            </td>
            {props.clinicianWeeklyData.lstClinicianTargetSymptomsWeeklyData.map(
              (targetSymptoms, index) => {
                return (
                  <Fragment>
                  {targetSymptoms.weekType!=3 && (  <td>
                      <WeekwiseColumn
                         problemName={targetSymptoms.targetOneAlias == null ? "Severity Rating" : ""}
                         marked={getSeverityText(targetSymptoms.targetOneAlias)}
                         statusStyle={getStatusStyle(
                           targetSymptoms.targetOneAlias
                         )}
                         border={getBorderStyle(
                           targetSymptoms.targetOneAlias
                         )}
                         padding={
                           targetSymptoms.targetOneAlias != null &&
                           targetSymptoms.targetOneAlias > 4
                             ? "8px 5px"
                             : ""
                         }
                         weekDot={weekDotStyle(
                           targetSymptoms.targetOneAlias
                         )}
                         missedOut={false}
                         missed=""
                         popover__align="popover__align"
                        contents={[(props.clinicianWeeklyData.targetOneAlias==null || props.clinicianWeeklyData.targetOneAlias=="")?<div></div>:
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.TargetSymptoms}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="targetOneAlias"
                                popOverId={"divTargetOneAlias"+(index+1)}
                                key={"divTargetOneAlias"+(index+1)}
                              />
                            }
                            trigger="click"
                          ><div id={"divTargetOneAlias"+(index+1)}>
                            {targetSymptoms.targetOneAlias ==null && (props.clinicianWeeklyData.targetOneAlias==null || props.clinicianWeeklyData.targetOneAlias=="") && (<Tooltip placement="right" title="Please add a Target #1 before selecting a rating."> <PlusCircleOutlined disabled={true}   className="addSeverityDisabled" />
                           </Tooltip>)}
                            {targetSymptoms.targetOneAlias ==null && props.clinicianWeeklyData.targetOneAlias && props.clinicianWeeklyData.targetOneAlias!="" && (<PlusCircleOutlined  className="addSeverity" />)}
                           {targetSymptoms.targetOneAlias !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)}                           
                           </div>
                          </Popover>
                          ,
                        ]}
                      />
                    </td>)}
                  </Fragment>
                );
              }
            )}
          </tr>
    
       </tbody>
        <thead>
          <tr className="target">
            <th>
              <Heading heading="Ranking" styleName="weekHeading" />
            </th>
          </tr>
        </thead>
          <tbody>    
        {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (ranking, index) => {
                return (
                  <Fragment>
                        <tr>
                            {index == 0 && <td rowSpan={6}> <p className="rank">RANKING</p><p className="rank-help-text"><span>(Please provide comparative ranking for this week from 1 to 5, where 1 is best and 5 is worst.)</span></p></td>}
            {index!=0 && <td style={{display: 'none'}}></td>}
            {props.clinicianWeeklyData.lstClinicianSideEffectsWeeklyData.map(
              (rankingInner, rankingInnerIndex) => {
                return (
                  <Fragment>
              {rankingInner.weekType!=3 && rankingInnerIndex!=0 && props.clinicianWeeklyData.lstClinicianRankingWeeklyData.find(u=>u.patientTrialWeekId==rankingInner.patientTrialWeekId  && u.rankedPatientTrialWeekId==ranking.patientTrialWeekId)
              &&
              (
              <td className="text-center" key={("rankingTd"+""+rankingInnerIndex+""+index)}>  
              <div className="medical-group padd__hoz" key={("rankingDiv"+""+rankingInnerIndex+""+index)}>
                <label className="form-label"></label>
                <input
                  type="number"
                  //name={String(rankingInnerIndex+index)}
                  id={("rankingId"+""+rankingInnerIndex+""+index)}
                  className="form-control phone_border width-unset"
                  defaultValue={props.clinicianWeeklyData.lstClinicianRankingWeeklyData.find(u=>u.patientTrialWeekId==rankingInner.patientTrialWeekId  && u.rankedPatientTrialWeekId==ranking.patientTrialWeekId)?.rank}
                  onBlur={(e) => handleOnBlur(rankingInner.patientTrialWeekId,ranking.patientTrialWeekId, e.target.value)}
                  key={("rankingKey"+""+rankingInnerIndex+""+index)}
                  onKeyDown={ (evt) => (evt.key === 'e' || evt.key==='-') && evt.preventDefault() }
                  onInput={rankingValueCheck}
                />
              </div>
               </td>
              )
              }
              {rankingInner.weekType!=3 && rankingInnerIndex!=0 && !props.clinicianWeeklyData.lstClinicianRankingWeeklyData.find(u=>u.patientTrialWeekId==rankingInner.patientTrialWeekId  && u.rankedPatientTrialWeekId==ranking.patientTrialWeekId)
              &&
              (
                <td className="empty"></td>
              )
              }
              {rankingInnerIndex==0 && !props.clinicianWeeklyData.lstClinicianRankingWeeklyData.find(u=>u.patientTrialWeekId==rankingInner.patientTrialWeekId  && u.rankedPatientTrialWeekId==ranking.patientTrialWeekId)
              &&
              (
                <td>
              <WeekwiseColumn
                problemName={
                  index == 0
                    ? "Baseline (Visit 1)"
                    : "Week " + ranking.weekNo
                }
                marked={dayjs(ranking.weekStartDate).format(
                  "MM/DD/YYYY"
                )}
                statusStyle="transBorder"
                border=""
                padding=""
                weekDot=""
                missedOut={false}
                missed=""
                popover__align="popover__align"
                contents={[<div></div>]}
              />
            </td>
              )
              }    
             </Fragment>)})}
          </tr>         
          </Fragment>)})}
        </tbody>
        <thead>
          <tr className="target">
            <th>
              <Heading heading="Additional Info" styleName="weekHeading" />
            </th>
          </tr>
        </thead>
         <tbody>
        <tr>
            <td key={"RFI0"} style={{padding: '7px 0'}}>
              <Heading
                heading="RFI"
                subHeading="(room for improvement)"
                styleName="weekCGIHeading"
                ccfSubHeading="weekCGIsubHeading"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianAddImprovementsWeeklyData.map(
              (improvement, index) => {
                return (
                  <Fragment>
                 {improvement.weekType!=3 && !improvement.isBaseLine && (<td key={"RFI"+(index + 1)}>
                  <div className="RFI_btn">
                <div className="medical-group btn-select">
                  <div className="btn-addon btnAdopted">
                    <button
                      type="button"
                       onClick={() => isSelected(improvement.weekNo,"isRoomForImprovement", true)}
                      
                      className={
                        improvement.isRoomForImprovement == true
                          ? "btn btn-sm btnAdopted_yes"
                          : "btn btn-sm btnAdopted_others"
                      }
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                       onClick={() => isSelected(improvement.weekNo,"isRoomForImprovement",false)}
                      
                       className={
                        improvement.isRoomForImprovement == false
                          ? "btn btn-sm btnAdopted_yes"
                          : "btn btn-sm btnAdopted_no"
                      }
                    >
                      No
                    </button>
                  </div>
                </div>
               </div>
                    </td>
                  )}
                   {improvement.weekType!=3 &&   improvement.isBaseLine &&  (
                    <td className="empty"></td>
                  )}
                   </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading="CGI (Improvement of Behavior - Parent)"
                styleName="weekCGIHeading"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianAddImprovementsWeeklyData.map(
              (improvements, index) => {
                return (
                  <Fragment>
                 {improvements.weekType!=3 &&  !improvements.isBaseLine &&  (<td>
                  <WeekwiseColumn
                        problemName={improvements.cgiImprovementOfbehavior == null ? "Improvement Rating" : ""}
                        marked={getImprovementText(improvements.cgiImprovementOfbehavior)}
                        statusStyle={getStatusStyle(
                          improvements.cgiImprovementOfbehavior
                        )}
                        border={getBorderStyle(
                          improvements.cgiImprovementOfbehavior
                        )}
                        padding={
                          improvements.cgiImprovementOfbehavior != null &&
                          improvements.cgiImprovementOfbehavior > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          improvements.cgiImprovementOfbehavior
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <ImprovementModal
                                type={ClinicianWeeklyDataType.AddImprovements}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="cgiImprovementOfbehavior"
                                popOverId={"divCgiImprovementOfbehavior"+(index+1)}
                                key={"divCgiImprovementOfbehavior"+(index+1)}
                              />
                            }
                            trigger="click"
                          >
                            <div id={"divCgiImprovementOfbehavior"+(index+1)}>
                            {improvements.cgiImprovementOfbehavior ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {improvements.cgiImprovementOfbehavior !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>
                  )}
                   {improvements.weekType!=3 &&   improvements.isBaseLine &&  (
                    <td className="empty"></td>
                  )}
                   </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading
                heading="CGI (Severity of Side Effects - Parent)"
                styleName="weekCGIHeading"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianAddImprovementsWeeklyData.map(
              (improvements, index) => {
                return (
                  <Fragment>
                 {improvements.weekType!=3 &&  !improvements.isBaseLine && (<td key={index + 1}>
                  <WeekwiseColumn
                        problemName={improvements.cgiSeverityOfSideEffects ==null?"Severity Rating":""}
                        marked={getSeverityText(improvements.cgiSeverityOfSideEffects)}
                        statusStyle={getStatusStyle(
                          improvements.cgiSeverityOfSideEffects
                        )}
                        border={getBorderStyle(
                          improvements.cgiSeverityOfSideEffects
                        )}
                        padding={
                          improvements.cgiSeverityOfSideEffects != null &&
                          improvements.cgiSeverityOfSideEffects > 4
                            ? "8px 5px"
                            : ""
                        }
                        weekDot={weekDotStyle(
                          improvements.cgiSeverityOfSideEffects
                        )}
                        missedOut={false}
                        missed=""
                        popover__align="popover__align"
                        contents={[
                          <Popover
                            placement="rightTop"
                            getPopupContainer={() => document.getElementById("scrollWrap")!}
                            content={
                              <SeverityModal
                                type={ClinicianWeeklyDataType.AddImprovements}
                                weekIndex={index}
                                clinicianWeeklyData={props.clinicianWeeklyData}
                                assignWeeklyDataModel={
                                  props.assignWeeklyDataModel
                                }
                                propName="cgiSeverityOfSideEffects"
                                popOverId={"divCgiSeverityOfSideEffects"+(index+1)}
                                key={"divCgiSeverityOfSideEffects"+(index+1)}
                              />
                            }
                            trigger="click"
                          ><div id={"divCgiSeverityOfSideEffects"+(index+1)}>
                            {improvements.cgiSeverityOfSideEffects ==null && (<PlusCircleOutlined className="addSeverity" />)}
                           {improvements.cgiSeverityOfSideEffects !=null && (<span className="weekSev__edit"><img src={editGray} alt="line" className="addSeverity"/></span>)} 
                           </div>
                          </Popover>,
                        ]}
                      />
                    </td>
                  )}
                  {improvements.weekType!=3 &&   improvements.isBaseLine &&  (
                    <td className="empty"></td>
                  )}
                   </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td key="isContinueMedicationForGivenWeek0">
              <Heading
                heading="Would you like your child to continue to receive this medication s/he received this week?"
                styleName="weekMedHeading"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianAddImprovementsWeeklyData.map(
              (improvement, index) => {
                return (
                  <Fragment>
                 {improvement.weekType!=3 && !improvement.isBaseLine && (<td key={"isContinueMedicationForGivenWeek"+ (index+1)}>
              <div className="RFI_btn">
                <div className="medical-group btn-select">
                  <div className="btn-addon btnAdopted">
                    <button
                      type="button"
                      onClick={() => isSelected(improvement.weekNo,"isContinueMedicationForGivenWeek", true)}
                      className={
                        improvement.isContinueMedicationForGivenWeek == true
                          ? "btn btn-sm btnAdopted_yes"
                          : "btn btn-sm btnAdopted_others"
                      }
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      onClick={() => isSelected(improvement.weekNo,"isContinueMedicationForGivenWeek", false)}
                      className={
                        improvement.isContinueMedicationForGivenWeek == false
                          ? "btn btn-sm btnAdopted_yes"
                          : "btn btn-sm btnAdopted_no"
                      }
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </td>
                  )}
                   {improvement.weekType!=3 && improvement.isBaseLine &&  (
                    <td className="empty"></td>
                  )}
                   </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td key="isBenefitOfDoseOutweighSideEffects0">
              <Heading
                heading="Does the benefit of this dose outweigh the side effects?"
                styleName="weekMedHeading"
              />
            </td>
            {props.clinicianWeeklyData.lstClinicianAddImprovementsWeeklyData.map(
              (improvement, index) => {
                return (
                  <Fragment>
                 {improvement.weekType!=3 && !improvement.isBaseLine && (<td key={"isBenefitOfDoseOutweighSideEffects" +(index+1)}>
              <div className="RFI_btn">
                <div className="medical-group btn-select">
                  <div className="btn-addon btnAdopted">
                    <button
                      type="button"
                      onClick={() => isSelected(improvement.weekNo,"isBenefitOfDoseOutweighSideEffects", true)}
                      className={
                        improvement.isBenefitOfDoseOutweighSideEffects == true
                          ? "btn btn-sm btnAdopted_yes"
                          : "btn btn-sm btnAdopted_others"
                      }
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      onClick={() => isSelected(improvement.weekNo,"isBenefitOfDoseOutweighSideEffects", false)}
                      className={
                        improvement.isBenefitOfDoseOutweighSideEffects == false
                          ? "btn btn-sm btnAdopted_yes"
                          : "btn btn-sm btnAdopted_no"
                      }
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </td>
                  )}
                   {improvement.weekType!=3 &&   improvement.isBaseLine &&  (
                    <td className="empty"></td>
                  )}
                   </Fragment>
                );
              }
            )}
          </tr>
          <tr>
            <td>
              <Heading heading="Dosage missed" styleName="weekMedHeading" />
            </td>
            {props.clinicianWeeklyData.lstClinicianAddImprovementsWeeklyData.map(
              (improvement, index) => {
                return (
                  <Fragment>
                 {improvement.weekType!=3 && !improvement.isBaseLine && (<td className="text-center">  
              <div className="medical-group padd__hoz">
                <label className="form-label"></label>
                <input
                  type="text"
                  key={"dosageMissed"+(index+1)}
                  id={String("Improvement"+index)}
                  className="form-control phone_border dosage_margin width-unset"
                  defaultValue={improvement.dosageMissed==0?"-":improvement.dosageMissed}                  
                  readOnly={true}
                />
              </div>
               </td>
                  )}
                   {improvement.weekType!=3 &&   improvement.isBaseLine &&  (
                    <td className="empty"></td>
                  )}
                   </Fragment>
                );
              }
            )}
          </tr>
        </tbody>
        </table>
      )}
      <Button
        id="btn"
        type="primary"
        size="middle"
        icon={<UpOutlined className="arrow_btn" />}
        onClick={scrollTop}
        className="scrollTop"
        // style={{ height: 40, display: showScroll ? "flex" : "none" }}
      />
    </div>
  );
}
