import React, { Fragment } from "react";
import { HttpResponse } from "../../../../../../core";
import { PsychologicalTestingPostModel } from "../../../../../../models/psychologicalTesting/psychological-testing-post.model";
import { PsychologicalTestingModel } from "../../../../../../models/psychologicalTesting/psychological-testing.model";
import { PsychologicalTestingService } from "../../../../../../services/psychologicalTesting";
import PsychologicalTestingViewForm from "./psychologicalTestingViewForm";

interface IState {
  patientId: string;
  isLoading: boolean;
  psychologicalTestingModel: PsychologicalTestingModel[];
}
export class PsychologicalTestingTab extends React.Component<any, IState> {
  private PsychologicalTestingService: PsychologicalTestingService;

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.PsychologicalTestingService = new PsychologicalTestingService();
  }
  private getInitialState() {
    let initialState: IState = {
      isLoading: false,
      patientId: this.props.patientId,
      psychologicalTestingModel: [] as PsychologicalTestingModel[],
    };
    return initialState;
  }
  componentDidMount() {
    if (this.props.patientId != null) {
      this.loadData();
    }
  }
  private setLoading(loading: boolean) {
    this.setState({
      isLoading: loading,
    });
  }
  loadData = () => {
    this.setLoading(true);
    this.PsychologicalTestingService.getPsychologicalTestingDetail(
      this.props.patientId
    ).then((res: HttpResponse<PsychologicalTestingModel[]>) => {
      if (res && res.result) {
        this.setLoading(false);
        this.setState({
          psychologicalTestingModel: res.result,
        });
      }
    });
  };

  render() {
    return (
      <Fragment>
        <PsychologicalTestingViewForm
          patientId={this.props.patientId}
          isLoading={this.state.isLoading}
          psychologicalTestingModel={this.state.psychologicalTestingModel}
        />
      </Fragment>
    );
  }
}
