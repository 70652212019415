import React, { Component } from "react";
import { Navigate } from "react-router-dom";

export function PrivateRoute({ children}) {
  let authed = localStorage.getItem('lg')==='true'; 
  //console.log(authed)
  //alert("privateRoute")    
return (
    authed? children : <Navigate to={'/login'} />
  )
}