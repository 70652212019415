import React, { Component } from "react";
import { HttpResponse } from "../../../core";
import { MedicalHistoryService } from "../../../services/medicalHistory";
import { GetMedicalHistoryStepStatus } from "../../patient/models/get-medical-history-step-status.model";
import Tabs from "../../tabs";
import "../styles.css";
import { MedicationHistory } from "./medication";
import { MedicationProblem } from "./medicationProblem";

interface State {
  tab: number;
  patientId: string;
  isFormSave?: Function;
  getMedicalHistoryStepStatus?: GetMedicalHistoryStepStatus
}
class MedicalHistoryForm extends Component<any, State> {
  private medicalHistoryService: MedicalHistoryService;
  state: State = { tab: 0, patientId: "" };
  changeTab(tab: any) {
    this.setState({
      tab,
    });
  }
  constructor(props) {
    super(props);
    this.medicalHistoryService = new MedicalHistoryService();
  }
  componentDidMount() {
    this.loadData();
  }
  loadData() {
    if (this.props.patientId) {
      this.medicalHistoryService
        .getMedicalHistoryStepStatus(this.props.patientId)
        .then((res: HttpResponse<GetMedicalHistoryStepStatus>) => {
          if (res && res.result) {
            console.log(res.result);
            this.setState({ getMedicalHistoryStepStatus: res.result });
            //this.getLastStep();
          }
        })
        .catch((ex) => {});
    }
  }
  medicalHistoryStepUpdateHandler = (key, value) => {
    if (this.state.getMedicalHistoryStepStatus) {
      if (key == 1) {
        this.state.getMedicalHistoryStepStatus.medicationId = value;
      } else if (key == 2) {
        this.state.getMedicalHistoryStepStatus.medicationProblemId = value;
      }
    }
  };
  render() {
    const { tab } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="medical__family_tabs d-none d-xl-block col-3">
            <Tabs
              styleName="familyTab"
              text="Medication"
              steps=""
              stepsAct=""
              fullText="Medication"
              familyTextName="A"
              image=""
              activeImage=""
              tabChange={() => this.changeTab(0)}
              selected={tab === 0}
              isData={
                this.state.getMedicalHistoryStepStatus?.medicationId != null
              }
            />
            <Tabs
              styleName="familyTab"
              text="Medical Problems"
              steps=""
              stepsAct=""
              fullText="Medical Problems"
              familyTextName="B"
              image=""
              activeImage=""
              tabChange={() => this.changeTab(1)}
              selected={tab === 1}
              isData={
                this.state.getMedicalHistoryStepStatus?.medicationProblemId !=
                null
              }
            />
          </div>
          <div className="medical__family_content col">
            {tab === 0 && (
              <MedicationHistory
                patientId={this.props.patientId}
                nextTabChange={() => this.changeTab(1)}
                isFormSave={this.props.isFormSave}
                parentTabChange={this.props.parentTab}
                medicalHistoryStepUpdate={this.medicalHistoryStepUpdateHandler}
              />
            )}
            {tab === 1 && (
              <MedicationProblem
                previousTabChange={() => this.changeTab(0)}
                isFormSave={this.props.isFormSave}
                patientId={this.props.patientId}
                nextTabChange={this.props.nextTabChange}
                medicalHistoryStepUpdate={this.medicalHistoryStepUpdateHandler}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default MedicalHistoryForm;
