import React, { Fragment, useEffect, useState } from "react";
import { Button } from "antd";
import { EyeOutlined, CloseCircleOutlined } from "@ant-design/icons";
import ChildGuardianInfo from "../../../../childGuardianInfo";
import { PatientDetailModel } from "../../../../patient/models/patient-detail.model";
import MedicationStatus from "../../../../medicationStatus";
import { Loader } from "../../../../../shared/loaders";
import ContentCard from "../../../../contentCard";
import editImg from "../../../../../assets/images/svg-icons/edit.svg";
import { Gender } from "../../../../../shared/enums/gender.enum";
import { MedicationOfTheTrialModel } from "../../../../../models/medication/medication-of-the-trial.model";
import ViewMedication from "../viewMedication";
import { ClinicianWeeklyData } from "../../../../../models/clinicianWeeklyData/clinician-weekly-data.model";
import { PatientVisitStatus } from "../../../../../models/patientVisitStatus/patient-visit-status.model";

interface IProps {
  patientDetailModel: PatientDetailModel;
  isLoading: boolean;
  handleNavigateClick: Function;
  bcColor: string;
  medicationOfTheTrial:MedicationOfTheTrialModel;
  patientVisitStatus?:PatientVisitStatus;
}
const anim__class = {
  slideUp: "slideup",
  slideDown: "slidedown",
  slideNull: "",
};
export function PatientDetailHeaderForm(props: IProps) {
  let patientDetailModel = props.patientDetailModel;
  const [viewMedication, setViewMedication] = useState(false);
  const [addAnimation, setAddAnimation] = useState(anim__class.slideNull);

  useEffect(() => {
    console.log("onscroll", viewMedication);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [viewMedication]);

  const handleScroll = () => {
    // hideMedicationModal();
    setViewMedication(false);
    // setAddAnimation(anim__class.slideUp);
  };
  const showMedicationModal = () => {
    console.log("show modal -----------------", viewMedication);
    setViewMedication(true);
    setAddAnimation(anim__class.slideDown);
    console.log("show modal", viewMedication);
  };
  const hideMedicationModal = () => {
    setViewMedication(false);
    setAddAnimation(anim__class.slideUp);
    console.log("hide modal", viewMedication);
    };
    let options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    };
    const handleGender = (val: any) => {
        let value = "-";
        if (val == Gender.Male) value = "Male";
        else if (val == Gender.Female) value = "Female";
        else if (val == Gender.Others) value = "Other";
        return value;
    };
    const visitStatus=(visitType:number)=>{
      var color="var(--color-fadeOrange)";
      if(props.patientVisitStatus!=null && props.patientVisitStatus.lstPatientVisitStatus!=null && props.patientVisitStatus.lstPatientVisitStatus.length>0){
        if(props.patientVisitStatus.lstPatientVisitStatus.find(u=>u.visitNo==visitType) && props.patientVisitStatus.lstPatientVisitStatus.find(u=>u.visitNo==visitType)?.isAttanded){
          var color="var(--color-fadeGreen)";
        }
      }
     return color;
    }
  return (
    <Fragment>
      <ContentCard
        styleName="childTab__status  remove-cursor"
        contents={[
          <div className="patient__header">
            {patientDetailModel != null && (
              <div className="statusInfo__cover">
                <div className="user-details">
                  <span className="name_wrap" style={{ backgroundColor: props.bcColor }}>
                    {patientDetailModel.initials == null ? "" : patientDetailModel.initials}
                  </span>
                  <p className="ccfChild_username">
                    {patientDetailModel.lastName == null ? "" : patientDetailModel.lastName + ","}{" "}
                    {patientDetailModel.firstName == null ? "" : patientDetailModel.firstName}{" "}
                    <br />{" "}
                    <span>
                      MRN: {patientDetailModel.mr_No == null ? "" : patientDetailModel.mr_No}
                    </span>{" "}
                    <br />
                    <span>
                      Date of Birth:{" "}
                      {patientDetailModel.dateOfBirth == null
                        ? ""
                        : new Date(patientDetailModel.dateOfBirth).toLocaleDateString(
                            "en",
                            options
                          )}
                      , {handleGender(patientDetailModel.gender)}
                    </span>
                  </p>
                </div>
              </div>
            )}
            {patientDetailModel.relationshipId && (
              <div className="statusInfo__cover">
                <ChildGuardianInfo
                  designation="Parent/Guardian"
                  name={
                    //"Mother: " + patientDetailModel.motherName
                    (patientDetailModel.relationshipId == 1 ? "Father: " : "Mother: ") +
                    patientDetailModel.guardianFirstName +
                    " " +
                    patientDetailModel.guardianLastName
                  }
                  email={patientDetailModel.emailId}
                  guardian="historyViewHeader"
                  guardianStyle="historyViewH5"
                />
              </div>
            )}
            {(patientDetailModel.primaryContactRelationship ||
              patientDetailModel.primaryContactName) && (
              <div className="statusInfo__cover">
                <ChildGuardianInfo
                  designation="Primary Contact Person"
                  name={
                    (patientDetailModel.primaryContactRelationship != ""
                      ? patientDetailModel.primaryContactRelationship + ": "
                      : "") + patientDetailModel.primaryContactName
                  }
                  guardian="historyViewHeader"
                  guardianStyle="historyViewH5"
                />
              </div>
            )}
            {patientDetailModel.physicianFirstName && (
              <div className="statusInfo__cover">
                <ChildGuardianInfo
                  designation="Physician"
                  name={
                    patientDetailModel.physicianFirstName +
                    " " +
                    patientDetailModel.physicianLastName
                  }
                  email={patientDetailModel.physicianEmailId}
                  guardian="historyViewHeader"
                  guardianStyle="historyViewH5"
                />
              </div>
            )}
            <div>
              <ChildGuardianInfo designation="Visit Status" name="" email="" />
              <div>
                <MedicationStatus
                  text="Visit1"
                  backgroundColor={visitStatus(1)} 
                  color="var(--color-slaty)"
                  statusStyle="patient__header_status"
                />
                <MedicationStatus
                  text="Visit2"
                  backgroundColor={visitStatus(2)} 
                  color="var(--color-slaty)"
                  statusStyle="patient__header_status"
                />
                <MedicationStatus
                  text="Visit3"
                  backgroundColor={visitStatus(3)} 
                  color="var(--color-slaty)"
                  statusStyle="patient__header_status"
                />
              </div>
            </div>
           { patientDetailModel.id!=null &&( <div className="statusBtn__cover">
              <Button
                type="primary"
                size="small"
                className="btn_add"
                ghost
                icon={<img src={editImg} alt="edit form" className="editForm__img" />}
                onClick={() => props.handleNavigateClick(patientDetailModel.id)}
              >
                Edit Info
              </Button>
              {!viewMedication ? (
                <Button
                  type="primary"
                  size="small"
                  className="btn_add"
                  ghost
                  icon={<EyeOutlined className="editForm__img" />}
                  onClick={showMedicationModal}
                >
                  View Details
                </Button>
              ) : (
                <Button
                  type="primary"
                  size="small"
                  className="btn_add close_btn"
                  ghost
                  icon={<CloseCircleOutlined className="editForm__img" />}
                  onClick={hideMedicationModal}
                >
                  Close
                </Button>
              )}
            </div>)}
          </div>,
        ]}
      />
      {viewMedication && (
        <div className={`${addAnimation}`}>
          <ViewMedication medicationOfTheTrial={props.medicationOfTheTrial} />
        </div>
      )}
    </Fragment>
  );
}

export default PatientDetailHeaderForm;
