import React, { Fragment, useEffect, useState } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import notes from "../../../assets/images/svg-icons/notes.svg";
import WeeklyStatus from "../../../components/weeklyStatus";
import Heading from "../../../components/heading";
import WeekwiseColumn from "../../../components/weekwiseColumn";
import { Button, Popover } from "antd";
import editGray from "../../../assets/images/svg-icons/edit-gray.svg";
import $ from "jquery";
import moment from "moment";
import SeverityModal from "../../../components/severityModal";
import { WeeklyDataUpdateByType } from "../../../shared/enums/clinicianWeeklyData/weekly-data-update-by-type";
import { TeacherWeeklyDataModel } from "../../../models/teacherWeeklyData/teacher-weekly-data.model";
import TeacherNote from "../teacherNote";

import { UpOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { TeacherTargetSymptomsWeekDayData } from "../../../models/teacherWeeklyData/teacher-target-symptoms-week-day-data.model";
import { TeacherAdditionalQuestionWeeklyData } from "../../../models/teacherWeeklyData/teacher-additional-question-weekly-data.model";
import { TeacherTargetSymptomsWeeklyData } from "../../../models/teacherWeeklyData/teacher-target-symptoms-weekly-data.model";
import DatePicker, { RangePickerProps } from "antd/es/date-picker";
interface IProps {
  patientId: string;
  teacherWeeklyDataModel: TeacherWeeklyDataModel;
  styleName: any;
  assignWeeklyDataModel?: Function;
  assignWeeklyQuestionModel: Function;
  selectedPatientName: string;
  weekNo: number;
  dayNum: number;
  startDate: dayjs.Dayjs;
}
function TeacherWeekMobile(props: IProps) {
  const [formVisible, setFormVisible] = useState(false);
  const showForm = () => {
    setFormVisible(true);
  };
  const hideForm = () => {
    setFormVisible(false);
  };

  const [patientId, setPatientId] = useState(props.patientId);
  const [teacherWeeklyDataModel, setparentWeeklyDataModel] = useState(props.teacherWeeklyDataModel);
  const [dayNum, setDayNum] = useState(0);
  const [weekIndex, setweekIndex] = useState(props.weekNo);
  const [displayedDate, setDisplayedDate] = useState(dayjs());
  const [weekId, setWeekId] = useState("");
  const [dayData, setdayData] = useState<TeacherTargetSymptomsWeekDayData>();
  const [dayId, setDayId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [teacherWeekDayList, setTeacherWeekDayList] = useState<TeacherTargetSymptomsWeeklyData[]>();
  const [teacherAdditionalQuestionList, setTeacherAdditionalQuestionList] = useState<TeacherAdditionalQuestionWeeklyData[]>();

  useEffect(() => {
    $(".sliderLeft").addClass("disabledbutton");
  });
  useEffect(() => {
    getDayData(displayedDate);
  }, []);
  let isPreviousWeek;

  const getDayData = (date : dayjs.Dayjs) => {
    let newWeekId : string | undefined;
    setIsLoading(true);
    props.teacherWeeklyDataModel != null
    && props.teacherWeeklyDataModel.teacherTargetSymptomWeeklyList.map(
    (x) => x.teacherTargetSymptomsWeekDayDataList.map(
      (y) => {
        if (dayjs(y.weekDayDate).format("YYYYMMDD") == date.format("YYYYMMDD")){
          setWeekId(y.weekId ?? "")
          newWeekId = y.weekId
          setdayData(y);
          setDayNum(y.weekDayNo ?? 0);
          console.log(y, x)
          setDayId(y.patientTrialWeekDayId ?? "");
          isPreviousWeek = x.isPreviousWeek;
          const newTeacherWeekDayList = 
          props.teacherWeeklyDataModel != null
          ? props.teacherWeeklyDataModel.teacherTargetSymptomWeeklyList.filter(
            (item) => item.weekNo == x.weekNo
          )
        : [];
        newTeacherWeekDayList.forEach(item => {
          item.teacherTargetSymptomsWeekDayDataList.forEach(subItem =>{
            subItem.ModifiedAfterWeeksEnd = isPreviousWeek;
          })
        })
        setTeacherWeekDayList(newTeacherWeekDayList);
        }
      })
    );
    const newAdditionalQuestionsList =
      props.teacherWeeklyDataModel != null
        ? props.teacherWeeklyDataModel.teacherAdditionalQuestionList.filter(
            (x) => x.weekId == newWeekId
          )
        : [];

    newAdditionalQuestionsList.forEach(item => {
      item.ModifiedAfterWeeksEnd = isPreviousWeek
    });
    setTeacherAdditionalQuestionList(newAdditionalQuestionsList);
    setIsLoading(false);
  }; 
  

  const getSeverityText = (value?: number) => {
    let severityText = "";
    switch (value) {
      case 1:
        severityText = "Normal; Not at all 1";
        break;
      case 2:
        severityText = "Borderline Problem 2";
        break;
      case 4:
        severityText = "Moderate Problem 4";
        break;
      case 3:
        severityText = "Mild Problem 3";
        break;
      case 5:
        severityText = "Marked Problem 5";
        break;
      case 6:
        severityText = "Severe Problem 6";
        break;
      case 7:
        severityText = "Most Extreme Problem 7";
        break;
      default:
        severityText = "";
        break;
    }
    return severityText;
  };
  const getStatusStyle = (value?: number, weekDay?: number) => {
    let statusStyle = "";
    if (value != null) {
      if (value == 5) {
        statusStyle = "weekModerate";
      } else if (value > 5) {
        statusStyle = "weekExtreme";
      }
    } else if (value == null && weekDay == 1) {
      statusStyle = "weekMissedOut";
    }
    return statusStyle;
  };
  const getBorderStyle = (value?: number) => {
    let borderStyle = "";
    if (value != null) {
      if (value == 5) {
        borderStyle = "1px solid var(--color-fadeLightOrange)";
      } else if (value > 5) {
        borderStyle = "1px solid var(--color-reddish)";
      }
    }
    return borderStyle;
  };
  const weekDotStyle = (value?: number) => {
    let dotStyle = "";
    if (value != null) {
      if (value == 5) {
        dotStyle = "weekOrange";
      } else if (value > 5) {
        dotStyle = "weekExtremeDot";
      }
    }
    return dotStyle;
  };
  const scrollTop = () => {
    window.document
      .getElementById("teacherScroll")!
      .scrollTo({ top: 0, behavior: "smooth" });
  };
  const isSelected = (type: number, isChecked, index: number) => {
    if (teacherAdditionalQuestionList)
    switch (type) {
      case 1: {
        teacherAdditionalQuestionList[index].isPlacebo = isChecked;
        break;
      }
      case 2: {
        teacherAdditionalQuestionList[index].isSideEffectObserved = isChecked;
        break;
      }
      default: {
        break;
      }
    }
    props.assignWeeklyQuestionModel({
      teacherAdditionalQuestionList: teacherAdditionalQuestionList,
    });
  };
  const changeDate = (data) => {
    console.log(data)
    setDisplayedDate(data);
    getDayData(data);
  }

  const disabledDates: RangePickerProps['disabledDate'] = (day) => {
    let earliestStart = dayjs(props.startDate);
    let latestStart = dayjs().endOf('day');
    return earliestStart > day || latestStart < day;
  };
  return (
    <Fragment>
      <TeacherNote
        visible={formVisible}
        patientId={props.patientId}
        showForm={showForm}
        hideForm={hideForm}
      />
      <div className="weekly__statusWrap">
        {/* <WeeklyStatus
          weeklyHeading={
            teacherWeekDayList &&
            teacherWeekDayList[0] &&
            "Fill the severity level of " +
              props.selectedPatientName +
              " " +
              "for" +
              " " +
              dayjs(
                new Date().toLocaleString("en-US", {
                  timeZone: "America/New_York",
                })
              ).format("dddd") +
              " of Week " +
              teacherWeekDayList[0].weekNo +
              "."
          }
          statusStyle=""
        /> */}
        <DatePicker 
            value={displayedDate} 
            onChange={changeDate}
            allowClear={false}
            disabledDate={disabledDates}
          />
        <div className="week__noteBtn">
          <Button
            type="primary"
            size="large"
            className="week__note"
            icon={<img src={notes} alt="notes" className="week__note_img" />}
            onClick={showForm}
          >
            Add Note
          </Button>
        </div>
      </div>
      <div id="scrollWrap" className="weeklyChildTable__container">
        <table>
          <thead>
            <tr className="side__effects">
              <th>
                <Heading heading="Target Symptoms" styleName="weekHeading" />
              </th>
              <Fragment>
                {teacherWeekDayList &&
                  teacherWeekDayList[0].teacherTargetSymptomsWeekDayDataList.map(
                    (item, i) => {
                      return (
                        <th
                          key={"week_day_header" + i}
                          style={{
                            backgroundColor:
                              item.weekDayType == 2 ? "#CCE4CB" : "",
                            display: 
                            item.weekDayNo == dayNum
                            ? "table-cell"
                            : "none",
                          }}
                        >
                          {item.weekDayType === 2 && (
                            <span className="selected__dot"></span>
                          )}
                          <Heading
                            heading={item.weekDayName}
                            subHeading={dayjs(item.weekDayDate).format(
                              "MM/DD/YYYY"
                            )}
                            styleName="weekwise__head"
                            ccfSubHeading="week__subHead"
                          />
                        </th>
                      );
                    }
                  )}
              </Fragment>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Heading
                  heading=""
                  subHeading="Overactivity; motor restlessness"
                  ccfSubHeading="week__subCol"
                />
              </td>
              {teacherWeekDayList && 
                teacherWeekDayList[0].teacherTargetSymptomsWeekDayDataList.map(
                  (targetSymptoms, index) => {
                    return (
                      <Fragment>
                        {targetSymptoms.weekDayNo == dayNum && (
                          <td key={"overActivity" + index}>
                            <WeekwiseColumn
                              problemName={
                                targetSymptoms.overActivity == null
                                  ? "Severity Rating"
                                  : ""
                              }
                              marked={getSeverityText(
                                targetSymptoms.overActivity
                              )}
                              statusStyle={getStatusStyle(
                                targetSymptoms.overActivity,
                                targetSymptoms.weekDayType
                              )}
                              border={getBorderStyle(
                                targetSymptoms.overActivity
                              )}
                              padding={
                                targetSymptoms.overActivity != null &&
                                targetSymptoms.overActivity > 4
                                  ? "8px 5px"
                                  : ""
                              }
                              weekDot={weekDotStyle(
                                targetSymptoms.overActivity
                              )}
                              missedOut={false}
                              missed={
                                targetSymptoms.overActivity == null
                                  ? "Missed Out"
                                  : ""
                              }
                              popover__align="popover__align"
                              contents={[
                                <Popover
                                  placement="rightTop"
                                  getPopupContainer={() =>
                                    document.getElementById("teacherScroll")!
                                  }
                                  trigger="click"
                                  content={
                                    <SeverityModal
                                      weeklyDataUpdateByType={
                                        WeeklyDataUpdateByType.Teacher
                                      }
                                      weekIndex={index}
                                      teacherWeeklyData={
                                        teacherWeekDayList[0]
                                          .teacherTargetSymptomsWeekDayDataList
                                      }
                                      assignWeeklyDataModel={
                                        props.assignWeeklyDataModel
                                      }
                                      propName="overActivity"
                                      popOverId={
                                        "divOverActivity" + (index + 1)
                                      }
                                      key={"divOverActivity" + (index + 1)}
                                    />
                                  }
                                >
                                  <div id={"divOverActivity" + (index + 1)}>
                                    {targetSymptoms.overActivity == null && (
                                      <PlusCircleOutlined className="addSeverity" />
                                    )}
                                    {targetSymptoms.overActivity != null && (
                                      <span className="weekSev__editTeacher">
                                        <img
                                          src={editGray}
                                          alt="line"
                                          className="addSeverity"
                                        />
                                      </span>
                                    )}
                                  </div>
                                </Popover>,
                              ]}
                            />
                          </td>
                        )}
                      </Fragment>
                    );
                  }
                )}
            </tr>
            <tr>
              <td>
                <Heading
                  heading=""
                  subHeading="Impulsiveness; acting w/o thinking"
                  ccfSubHeading="week__subCol"
                />
              </td>
              {teacherWeekDayList &&
                teacherWeekDayList[0].teacherTargetSymptomsWeekDayDataList.map(
                  (targetSymptoms, index) => {
                    return (
                      <Fragment>
                        {targetSymptoms.weekDayNo == dayNum && (
                          <td key={"impulsiveness" + index}>
                            <WeekwiseColumn
                              problemName={
                                targetSymptoms.impulsiveness == null
                                  ? "Severity Rating"
                                  : ""
                              }
                              marked={getSeverityText(
                                targetSymptoms.impulsiveness
                              )}
                              statusStyle={getStatusStyle(
                                targetSymptoms.impulsiveness,
                                targetSymptoms.weekDayType
                              )}
                              border={getBorderStyle(
                                targetSymptoms.impulsiveness
                              )}
                              padding={
                                targetSymptoms.impulsiveness != null &&
                                targetSymptoms.impulsiveness > 4
                                  ? "8px 5px"
                                  : ""
                              }
                              weekDot={weekDotStyle(
                                targetSymptoms.impulsiveness
                              )}
                              missedOut={false}
                              missed={
                                targetSymptoms.impulsiveness == null
                                  ? "Missed Out"
                                  : ""
                              }
                              popover__align="popover__align"
                              contents={[
                                <Popover
                                  placement="rightTop"
                                  getPopupContainer={() =>
                                    document.getElementById("teacherScroll")!
                                  }
                                  content={
                                    <SeverityModal
                                      weeklyDataUpdateByType={
                                        WeeklyDataUpdateByType.Teacher
                                      }
                                      weekIndex={index}
                                      teacherWeeklyData={
                                        teacherWeekDayList[0]
                                          .teacherTargetSymptomsWeekDayDataList
                                      }
                                      assignWeeklyDataModel={
                                        props.assignWeeklyDataModel
                                      }
                                      propName="impulsiveness"
                                      popOverId={
                                        "divImpulsiveness" + (index + 1)
                                      }
                                      key={"divImpulsiveness" + (index + 1)}
                                    />
                                  }
                                  trigger="click"
                                >
                                  <div id={"divImpulsiveness" + (index + 1)}>
                                    {targetSymptoms.impulsiveness == null && (
                                      <PlusCircleOutlined className="addSeverity" />
                                    )}
                                    {targetSymptoms.impulsiveness != null && (
                                      <span className="weekSev__editTeacher">
                                        <img
                                          src={editGray}
                                          alt="line"
                                          className="addSeverity"
                                        />
                                      </span>
                                    )}
                                  </div>
                                </Popover>,
                              ]}
                            />
                          </td>
                        )}
                      </Fragment>
                    );
                  }
                )}
            </tr>
            <tr>
              <td>
                <Heading
                  heading=""
                  subHeading="Distractability; sustaining attention to tasks"
                  ccfSubHeading="week__subCol"
                />
              </td>
              {teacherWeekDayList && 
                teacherWeekDayList[0].teacherTargetSymptomsWeekDayDataList.map(
                  (targetSymptoms, index) => {
                    return (
                      <Fragment>
                        {targetSymptoms.weekDayNo == dayNum && (
                          <td key={"distractability" + index}>
                            <WeekwiseColumn
                              problemName={
                                targetSymptoms.distractability == null
                                  ? "Severity Rating"
                                  : ""
                              }
                              marked={getSeverityText(
                                targetSymptoms.distractability
                              )}
                              statusStyle={getStatusStyle(
                                targetSymptoms.distractability,
                                targetSymptoms.weekDayType
                              )}
                              border={getBorderStyle(
                                targetSymptoms.distractability
                              )}
                              padding={
                                targetSymptoms.distractability != null &&
                                targetSymptoms.distractability > 4
                                  ? "8px 5px"
                                  : ""
                              }
                              weekDot={weekDotStyle(
                                targetSymptoms.distractability
                              )}
                              missedOut={false}
                              missed={
                                targetSymptoms.distractability == null
                                  ? "Missed Out"
                                  : ""
                              }
                              popover__align="popover__align"
                              contents={[
                                <Popover
                                  placement="rightTop"
                                  getPopupContainer={() =>
                                    document.getElementById("teacherScroll")!
                                  }
                                  content={
                                    <SeverityModal
                                      weeklyDataUpdateByType={
                                        WeeklyDataUpdateByType.Teacher
                                      }
                                      weekIndex={index}
                                      teacherWeeklyData={
                                        teacherWeekDayList[0]
                                          .teacherTargetSymptomsWeekDayDataList
                                      }
                                      assignWeeklyDataModel={
                                        props.assignWeeklyDataModel
                                      }
                                      propName="distractability"
                                      popOverId={
                                        "divDistractability" + (index + 1)
                                      }
                                      key={"divDistractability" + (index + 1)}
                                    />
                                  }
                                  trigger="click"
                                >
                                  <div id={"divDistractability" + (index + 1)}>
                                    {targetSymptoms.distractability == null && (
                                      <PlusCircleOutlined className="addSeverity" />
                                    )}
                                    {targetSymptoms.distractability != null && (
                                      <span className="weekSev__editTeacher">
                                        <img
                                          src={editGray}
                                          alt="line"
                                          className="addSeverity"
                                        />
                                      </span>
                                    )}
                                  </div>
                                </Popover>,
                              ]}
                            />
                          </td>
                        )}
                      </Fragment>
                    );
                  }
                )}
            </tr>
            <tr>
              <td>
                <Heading
                  heading=""
                  subHeading="Task completion; finishing tasks"
                  ccfSubHeading="week__subCol"
                />
              </td>
              {teacherWeekDayList &&
                teacherWeekDayList[0].teacherTargetSymptomsWeekDayDataList.map(
                  (targetSymptoms, index) => {
                    return (
                      <Fragment>
                        {targetSymptoms.weekDayNo == dayNum && (
                          <td key={"taskCompletion" + index}>
                            <WeekwiseColumn
                              problemName={
                                targetSymptoms.taskCompletion == null
                                  ? "Severity Rating"
                                  : ""
                              }
                              marked={getSeverityText(
                                targetSymptoms.taskCompletion
                              )}
                              statusStyle={getStatusStyle(
                                targetSymptoms.taskCompletion,
                                targetSymptoms.weekDayType
                              )}
                              border={getBorderStyle(
                                targetSymptoms.taskCompletion
                              )}
                              padding={
                                targetSymptoms.taskCompletion != null &&
                                targetSymptoms.taskCompletion > 4
                                  ? "8px 5px"
                                  : ""
                              }
                              weekDot={weekDotStyle(
                                targetSymptoms.taskCompletion
                              )}
                              missedOut={false}
                              missed={
                                targetSymptoms.taskCompletion == null
                                  ? "Missed Out"
                                  : ""
                              }
                              popover__align="popover__align"
                              contents={[
                                <Popover
                                  placement="rightTop"
                                  getPopupContainer={() =>
                                    document.getElementById("teacherScroll")!
                                  }
                                  content={
                                    <SeverityModal
                                      weeklyDataUpdateByType={
                                        WeeklyDataUpdateByType.Teacher
                                      }
                                      weekIndex={index}
                                      teacherWeeklyData={
                                        teacherWeekDayList[0]
                                          .teacherTargetSymptomsWeekDayDataList
                                      }
                                      assignWeeklyDataModel={
                                        props.assignWeeklyDataModel
                                      }
                                      propName="taskCompletion"
                                      popOverId={
                                        "divTaskCompletion" + (index + 1)
                                      }
                                      key={"divTaskCompletion" + (index + 1)}
                                    />
                                  }
                                  trigger="click"
                                >
                                  <div id={"divTaskCompletion" + (index + 1)}>
                                    {targetSymptoms.taskCompletion == null && (
                                      <PlusCircleOutlined className="addSeverity" />
                                    )}
                                    {targetSymptoms.taskCompletion != null && (
                                      <span className="weekSev__editTeacher">
                                        <img
                                          src={editGray}
                                          alt="line"
                                          className="addSeverity"
                                        />
                                      </span>
                                    )}
                                  </div>
                                </Popover>,
                              ]}
                            />
                          </td>
                        )}
                      </Fragment>
                    );
                  }
                )}
            </tr>
            <tr>
              <td>
                <Heading
                  heading=""
                  subHeading="Being on time; accepting limits"
                  ccfSubHeading="week__subCol"
                />
              </td>
              {teacherWeekDayList &&
                teacherWeekDayList[0].teacherTargetSymptomsWeekDayDataList.map(
                  (targetSymptoms, index) => {
                    return (
                      <Fragment>
                        {targetSymptoms.weekDayNo == dayNum && (
                          <td key={"beingOnTime" + index}>
                            <WeekwiseColumn
                              problemName={
                                targetSymptoms.beingOnTime == null
                                  ? "Severity Rating"
                                  : ""
                              }
                              marked={getSeverityText(
                                targetSymptoms.beingOnTime
                              )}
                              statusStyle={getStatusStyle(
                                targetSymptoms.beingOnTime,
                                targetSymptoms.weekDayType
                              )}
                              border={getBorderStyle(
                                targetSymptoms.beingOnTime
                              )}
                              padding={
                                targetSymptoms.beingOnTime != null &&
                                targetSymptoms.beingOnTime > 4
                                  ? "8px 5px"
                                  : ""
                              }
                              weekDot={weekDotStyle(targetSymptoms.beingOnTime)}
                              missedOut={false}
                              missed={
                                targetSymptoms.beingOnTime == null
                                  ? "Missed Out"
                                  : ""
                              }
                              popover__align="popover__align"
                              contents={[
                                <Popover
                                  placement="rightTop"
                                  getPopupContainer={() =>
                                    document.getElementById("teacherScroll")!
                                  }
                                  content={
                                    <SeverityModal
                                      weeklyDataUpdateByType={
                                        WeeklyDataUpdateByType.Teacher
                                      }
                                      weekIndex={index}
                                      teacherWeeklyData={
                                        teacherWeekDayList[0]
                                          .teacherTargetSymptomsWeekDayDataList
                                      }
                                      assignWeeklyDataModel={
                                        props.assignWeeklyDataModel
                                      }
                                      propName="beingOnTime"
                                      popOverId={"divBeingOnTime" + (index + 1)}
                                      key={"divBeingOnTime" + (index + 1)}
                                    />
                                  }
                                  trigger="click"
                                >
                                  <div id={"divBeingOnTime" + (index + 1)}>
                                    {targetSymptoms.beingOnTime == null && (
                                      <PlusCircleOutlined className="addSeverity" />
                                    )}
                                    {targetSymptoms.beingOnTime != null && (
                                      <span className="weekSev__editTeacher">
                                        <img
                                          src={editGray}
                                          alt="line"
                                          className="addSeverity"
                                        />
                                      </span>
                                    )}
                                  </div>
                                </Popover>,
                              ]}
                            />
                          </td>
                        )}
                      </Fragment>
                    );
                  }
                )}
            </tr>
            <tr>
              <td>
                <Heading
                  heading=""
                  subHeading="Following instructions"
                  ccfSubHeading="week__subCol"
                />
              </td>
              {teacherWeekDayList &&
                teacherWeekDayList[0].teacherTargetSymptomsWeekDayDataList.map(
                  (targetSymptoms, index) => {
                    return (
                      <Fragment>
                        {targetSymptoms.weekDayNo == dayNum && (
                          <td key={"followingInstructions" + index}>
                            <WeekwiseColumn
                              problemName={
                                targetSymptoms.followingInstructions == null
                                  ? "Severity Rating"
                                  : ""
                              }
                              marked={getSeverityText(
                                targetSymptoms.followingInstructions
                              )}
                              statusStyle={getStatusStyle(
                                targetSymptoms.followingInstructions,
                                targetSymptoms.weekDayType
                              )}
                              border={getBorderStyle(
                                targetSymptoms.followingInstructions
                              )}
                              padding={
                                targetSymptoms.followingInstructions != null &&
                                targetSymptoms.followingInstructions > 4
                                  ? "8px 5px"
                                  : ""
                              }
                              weekDot={weekDotStyle(
                                targetSymptoms.followingInstructions
                              )}
                              missedOut={false}
                              missed={
                                targetSymptoms.followingInstructions == null
                                  ? "Missed Out"
                                  : ""
                              }
                              popover__align="popover__align"
                              contents={[
                                <Popover
                                  placement="rightTop"
                                  getPopupContainer={() =>
                                    document.getElementById("teacherScroll")!
                                  }
                                  content={
                                    <SeverityModal
                                      weeklyDataUpdateByType={
                                        WeeklyDataUpdateByType.Teacher
                                      }
                                      weekIndex={index}
                                      teacherWeeklyData={
                                        teacherWeekDayList[0]
                                          .teacherTargetSymptomsWeekDayDataList
                                      }
                                      assignWeeklyDataModel={
                                        props.assignWeeklyDataModel
                                      }
                                      propName="followingInstructions"
                                      popOverId={
                                        "divFollowingInstructions" + (index + 1)
                                      }
                                      key={
                                        "divFollowingInstructions" + (index + 1)
                                      }
                                    />
                                  }
                                  trigger="click"
                                >
                                  <div
                                    id={
                                      "divFollowingInstructions" + (index + 1)
                                    }
                                  >
                                    {targetSymptoms.followingInstructions ==
                                      null && (
                                      <PlusCircleOutlined className="addSeverity" />
                                    )}
                                    {targetSymptoms.followingInstructions !=
                                      null && (
                                      <span className="weekSev__editTeacher">
                                        <img
                                          src={editGray}
                                          alt="line"
                                          className="addSeverity"
                                        />
                                      </span>
                                    )}
                                  </div>
                                </Popover>,
                              ]}
                            />
                          </td>
                        )}
                      </Fragment>
                    );
                  }
                )}
            </tr>
            <tr>
              <td>
                <Heading
                  heading=""
                  subHeading="Frustration tolerance; appropriately expressing frustration"
                  ccfSubHeading="week__subCol"
                />
              </td>
              {teacherWeekDayList &&
                teacherWeekDayList[0].teacherTargetSymptomsWeekDayDataList.map(
                  (targetSymptoms, index) => {
                    return (
                      <Fragment>
                        {targetSymptoms.weekDayNo == dayNum && (
                          <td key={"frustrationTolerance" + index}>
                            <WeekwiseColumn
                              problemName={
                                targetSymptoms.frustrationTolerance == null
                                  ? "Severity Rating"
                                  : ""
                              }
                              marked={getSeverityText(
                                targetSymptoms.frustrationTolerance
                              )}
                              statusStyle={getStatusStyle(
                                targetSymptoms.frustrationTolerance,
                                targetSymptoms.weekDayType
                              )}
                              border={getBorderStyle(
                                targetSymptoms.frustrationTolerance
                              )}
                              padding={
                                targetSymptoms.frustrationTolerance != null &&
                                targetSymptoms.frustrationTolerance > 4
                                  ? "8px 5px"
                                  : ""
                              }
                              weekDot={weekDotStyle(
                                targetSymptoms.frustrationTolerance
                              )}
                              missedOut={false}
                              missed={
                                targetSymptoms.frustrationTolerance == null
                                  ? "Missed Out"
                                  : ""
                              }
                              popover__align="popover__align"
                              contents={[
                                <Popover
                                  placement="rightTop"
                                  getPopupContainer={() =>
                                    document.getElementById("teacherScroll")!
                                  }
                                  content={
                                    <SeverityModal
                                      weeklyDataUpdateByType={
                                        WeeklyDataUpdateByType.Teacher
                                      }
                                      weekIndex={index}
                                      teacherWeeklyData={
                                        teacherWeekDayList[0]
                                          .teacherTargetSymptomsWeekDayDataList
                                      }
                                      assignWeeklyDataModel={
                                        props.assignWeeklyDataModel
                                      }
                                      propName="frustrationTolerance"
                                      popOverId={
                                        "divFrustrationTolerance" + (index + 1)
                                      }
                                      key={
                                        "divFrustrationTolerance" + (index + 1)
                                      }
                                    />
                                  }
                                  trigger="click"
                                >
                                  {" "}
                                  <div
                                    id={"divFrustrationTolerance" + (index + 1)}
                                  >
                                    {targetSymptoms.frustrationTolerance ==
                                      null && (
                                      <PlusCircleOutlined className="addSeverity" />
                                    )}
                                    {targetSymptoms.frustrationTolerance !=
                                      null && (
                                      <span className="weekSev__editTeacher">
                                        <img
                                          src={editGray}
                                          alt="line"
                                          className="addSeverity"
                                        />
                                      </span>
                                    )}
                                  </div>
                                </Popover>,
                              ]}
                            />
                          </td>
                        )}
                      </Fragment>
                    );
                  }
                )}
            </tr>
            <tr>
              <td>
                <Heading
                  heading=""
                  subHeading="Ability to calm self when excited"
                  ccfSubHeading="week__subCol"
                />
              </td>
              {teacherWeekDayList &&
                teacherWeekDayList[0].teacherTargetSymptomsWeekDayDataList.map(
                  (targetSymptoms, index) => {
                    return (
                      <Fragment>
                        {targetSymptoms.weekDayNo == dayNum && (
                          <td key={"abilitytoCalmSelfWhenExcited" + index}>
                            <WeekwiseColumn
                              problemName={
                                targetSymptoms.abilitytoCalmSelfWhenExcited ==
                                null
                                  ? "Severity Rating"
                                  : ""
                              }
                              marked={getSeverityText(
                                targetSymptoms.abilitytoCalmSelfWhenExcited
                              )}
                              statusStyle={getStatusStyle(
                                targetSymptoms.abilitytoCalmSelfWhenExcited,
                                targetSymptoms.weekDayType
                              )}
                              border={getBorderStyle(
                                targetSymptoms.abilitytoCalmSelfWhenExcited
                              )}
                              padding={
                                targetSymptoms.abilitytoCalmSelfWhenExcited !=
                                  null &&
                                targetSymptoms.abilitytoCalmSelfWhenExcited > 4
                                  ? "8px 5px"
                                  : ""
                              }
                              weekDot={weekDotStyle(
                                targetSymptoms.abilitytoCalmSelfWhenExcited
                              )}
                              missedOut={false}
                              missed={
                                targetSymptoms.abilitytoCalmSelfWhenExcited ==
                                null
                                  ? "Missed Out"
                                  : ""
                              }
                              popover__align="popover__align"
                              contents={[
                                <Popover
                                  placement="rightTop"
                                  getPopupContainer={() =>
                                    document.getElementById("teacherScroll")!
                                  }
                                  content={
                                    <SeverityModal
                                      weeklyDataUpdateByType={
                                        WeeklyDataUpdateByType.Teacher
                                      }
                                      weekIndex={index}
                                      teacherWeeklyData={
                                        teacherWeekDayList[0]
                                          .teacherTargetSymptomsWeekDayDataList
                                      }
                                      assignWeeklyDataModel={
                                        props.assignWeeklyDataModel
                                      }
                                      propName="abilitytoCalmSelfWhenExcited"
                                      popOverId={
                                        "divAbilitytoCalmSelfWhenExcited" +
                                        (index + 1)
                                      }
                                      key={
                                        "divAbilitytoCalmSelfWhenExcited" +
                                        (index + 1)
                                      }
                                    />
                                  }
                                  trigger="click"
                                >
                                  <div
                                    id={
                                      "divAbilitytoCalmSelfWhenExcited" +
                                      (index + 1)
                                    }
                                  >
                                    {targetSymptoms.abilitytoCalmSelfWhenExcited ==
                                      null && (
                                      <PlusCircleOutlined className="addSeverity" />
                                    )}
                                    {targetSymptoms.abilitytoCalmSelfWhenExcited !=
                                      null && (
                                      <span className="weekSev__editTeacher">
                                        <img
                                          src={editGray}
                                          alt="line"
                                          className="addSeverity"
                                        />
                                      </span>
                                    )}
                                  </div>
                                </Popover>,
                              ]}
                            />
                          </td>
                        )}
                      </Fragment>
                    );
                  }
                )}
            </tr>
            <tr>
              <td>
                <Heading
                  heading=""
                  subHeading="Peer relations"
                  ccfSubHeading="week__subCol"
                />
              </td>
              {teacherWeekDayList &&
                teacherWeekDayList[0].teacherTargetSymptomsWeekDayDataList.map(
                  (targetSymptoms, index) => {
                    return (
                      <Fragment>
                        {targetSymptoms.weekDayNo == dayNum && (
                          <td key={"peerRelations" + index}>
                            <WeekwiseColumn
                              problemName={
                                targetSymptoms.peerRelations == null
                                  ? "Severity Rating"
                                  : ""
                              }
                              marked={getSeverityText(
                                targetSymptoms.peerRelations
                              )}
                              statusStyle={getStatusStyle(
                                targetSymptoms.peerRelations,
                                targetSymptoms.weekDayType
                              )}
                              border={getBorderStyle(
                                targetSymptoms.peerRelations
                              )}
                              padding={
                                targetSymptoms.peerRelations != null &&
                                targetSymptoms.peerRelations > 4
                                  ? "8px 5px"
                                  : ""
                              }
                              weekDot={weekDotStyle(
                                targetSymptoms.peerRelations
                              )}
                              missedOut={false}
                              missed={
                                targetSymptoms.peerRelations == null
                                  ? "Missed Out"
                                  : ""
                              }
                              popover__align="popover__align"
                              contents={[
                                <Popover
                                  placement="rightTop"
                                  getPopupContainer={() =>
                                    document.getElementById("teacherScroll")!
                                  }
                                  content={
                                    <SeverityModal
                                      weeklyDataUpdateByType={
                                        WeeklyDataUpdateByType.Teacher
                                      }
                                      weekIndex={index}
                                      teacherWeeklyData={
                                        teacherWeekDayList[0]
                                          .teacherTargetSymptomsWeekDayDataList
                                      }
                                      assignWeeklyDataModel={
                                        props.assignWeeklyDataModel
                                      }
                                      propName="peerRelations"
                                      popOverId={
                                        "divPeerRelations" + (index + 1)
                                      }
                                      key={"divPeerRelations" + (index + 1)}
                                    />
                                  }
                                  trigger="click"
                                >
                                  <div id={"divPeerRelations" + (index + 1)}>
                                    {targetSymptoms.peerRelations == null && (
                                      <PlusCircleOutlined className="addSeverity" />
                                    )}
                                    {targetSymptoms.peerRelations != null && (
                                      <span className="weekSev__editTeacher">
                                        <img
                                          src={editGray}
                                          alt="line"
                                          className="addSeverity"
                                        />
                                      </span>
                                    )}
                                  </div>
                                </Popover>,
                              ]}
                            />
                          </td>
                        )}
                      </Fragment>
                    );
                  }
                )}
            </tr>
            <tr>
              <td>
                <Heading
                  heading=""
                  subHeading="Adults relations"
                  ccfSubHeading="week__subCol"
                />
              </td>
              {teacherWeekDayList &&
                teacherWeekDayList[0].teacherTargetSymptomsWeekDayDataList.map(
                  (targetSymptoms, index) => {
                    return (
                      <Fragment>
                        {targetSymptoms.weekDayNo == dayNum && (
                          <td key={"adultsRelations" + index}>
                            <WeekwiseColumn
                              problemName={
                                targetSymptoms.adultsRelations == null
                                  ? "Severity Rating"
                                  : ""
                              }
                              marked={getSeverityText(
                                targetSymptoms.adultsRelations
                              )}
                              statusStyle={getStatusStyle(
                                targetSymptoms.adultsRelations,
                                targetSymptoms.weekDayType
                              )}
                              border={getBorderStyle(
                                targetSymptoms.adultsRelations
                              )}
                              padding={
                                targetSymptoms.adultsRelations != null &&
                                targetSymptoms.adultsRelations > 4
                                  ? "8px 5px"
                                  : ""
                              }
                              weekDot={weekDotStyle(
                                targetSymptoms.adultsRelations
                              )}
                              missedOut={false}
                              missed={
                                targetSymptoms.adultsRelations == null
                                  ? "Missed Out"
                                  : ""
                              }
                              popover__align="popover__align"
                              contents={[
                                <Popover
                                  placement="rightTop"
                                  getPopupContainer={() =>
                                    document.getElementById("teacherScroll")!
                                  }
                                  content={
                                    <SeverityModal
                                      weeklyDataUpdateByType={
                                        WeeklyDataUpdateByType.Teacher
                                      }
                                      weekIndex={index}
                                      teacherWeeklyData={
                                        teacherWeekDayList[0]
                                          .teacherTargetSymptomsWeekDayDataList
                                      }
                                      assignWeeklyDataModel={
                                        props.assignWeeklyDataModel
                                      }
                                      propName="adultsRelations"
                                      popOverId={
                                        "divAdultsRelations" + (index + 1)
                                      }
                                      key={"divAdultsRelations" + (index + 1)}
                                    />
                                  }
                                  trigger="click"
                                >
                                  <div id={"divAdultsRelations" + (index + 1)}>
                                    {targetSymptoms.adultsRelations == null && (
                                      <PlusCircleOutlined className="addSeverity" />
                                    )}
                                    {targetSymptoms.adultsRelations != null && (
                                      <span className="weekSev__editTeacher">
                                        <img
                                          src={editGray}
                                          alt="line"
                                          className="addSeverity"
                                        />
                                      </span>
                                    )}
                                  </div>
                                </Popover>,
                              ]}
                            />
                          </td>
                        )}
                      </Fragment>
                    );
                  }
                )}
            </tr>
            <tr>
              <td>
                <Heading
                  heading="Do you believe your child received active medication (M) or placebo (P) today?"
                  styleName="weekMedHeading"
                />
              </td>
              {teacherAdditionalQuestionList &&
                teacherAdditionalQuestionList.map(
                  (additionalQuestion, index) => {
                    return (
                      <Fragment>
                        {!isLoading && additionalQuestion.patientTrialWeekDayId == dayId && (
                          <td key={"isPlacebo" + index}>
                            <div className="teacherRFI_btn">
                              <div className="medical-group btn-select">
                                <div className="btn-addon btnAdopted">
                                  <button
                                    type="button"
                                    onClick={() => isSelected(1, false, index)}
                                    className={
                                      additionalQuestion.isPlacebo == false
                                        ? "btn btn-sm btnAdopted_yes"
                                        : "btn btn-sm btnAdopted_others"
                                    }
                                    disabled={false}
                                  >
                                    Medication
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => isSelected(1, true, index)}
                                    className={
                                      additionalQuestion.isPlacebo == true
                                        ? "btn btn-sm btnAdopted_yes"
                                        : "btn btn-sm btnAdopted_no"
                                    }
                                    disabled={false}
                                  >
                                    Placebo
                                  </button>
                                </div>
                              </div>
                            </div>
                          </td>
                        )}
                      </Fragment>
                    );
                  }
                )}
            </tr>

            <tr>
              <td>
                <Heading
                  heading="Were side effects observed?"
                  styleName="weekMedHeading"
                />
              </td>
              {teacherAdditionalQuestionList &&
                teacherAdditionalQuestionList.map(
                  (additionalQuestion, index) => {
                    return (
                      <Fragment>
                        
                        {!isLoading && additionalQuestion.patientTrialWeekDayId == dayId && (
                          <td key={"sideEffect" + index}>
                            <div className="teacherRFI_btn">
                              <div className="medical-group btn-select">
                                <div className="btn-addon btnAdopted">
                                  <button
                                    type="button"
                                    onClick={() => isSelected(2, true, index)}
                                    className={
                                      additionalQuestion.isSideEffectObserved ==
                                      true
                                        ? "btn btn-sm btnAdopted_yes"
                                        : "btn btn-sm btnAdopted_others"
                                    }
                                    disabled={false}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => isSelected(2, false, index)}
                                    className={
                                      additionalQuestion.isSideEffectObserved ==
                                      false
                                        ? "btn btn-sm btnAdopted_yes"
                                        : "btn btn-sm btnAdopted_no"
                                    }
                                    disabled={false}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </td>
                        )}
                      </Fragment>
                    );
                  }
                )}
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}
export default TeacherWeekMobile;
