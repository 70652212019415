import React, { Fragment, useEffect, useState } from 'react'
import { TeacherWeeklyDataModel } from '../../models/teacherWeeklyData/teacher-weekly-data.model';
import { ParentWeeklyDataModel } from '../../models/parentWeeklyData/parent-weekly-data.model';
import { RespondentService } from '../../services/respondent/RespondentService';
import { GetUserInfo } from '../../shared/helpers';
import { HttpResponse } from '../../core';
import { RespondentPatientModel } from '../../models/Respondent/RespondentPatientModel';
import Tabs from "../../components/tabs";
import dayjs from 'dayjs';
import { Nav } from 'react-bootstrap';
import { Loader } from '../../shared/loaders';
import PageTitleSetter from '../../shared/pageTitleSetter';
import TeacherWeek from '../teacherWeeklyUpdate/teacherWeek';
import ChildDetailHeader from '../childWeeklyUpdate/childDetailHeader';
import ChildWeek from '../childWeeklyUpdate/childWeek';
import ChildWeekMobile from '../childWeeklyUpdate/childWeekMobile';
import { TeacherDetailHeaderForm } from '../teacherWeeklyUpdate/teacherDetailHeader/teacherDetailHeaderForm';
import TeacherWeekMobile from '../teacherWeeklyUpdate/teacherWeekMobile';

function RespondentWeeklyUpdate() {
    const emptyTeacherWeeklyData = {} as TeacherWeeklyDataModel;
    const emptyParentWeeklyData = {} as ParentWeeklyDataModel;
    const respondentServie = new RespondentService();
    const user = GetUserInfo();

    const [userInfo, setUserInfo] = useState(user);
    const [formVisible, setFormVisible] = useState(false);
    const [patientId, setPatientId] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [teacherWeeklyData, setTeacherWeeklyData] = useState(emptyTeacherWeeklyData);
    const [parentWeeklyData, setParentWeeklyData] = useState(emptyParentWeeklyData);
    const [isFormSave, setIsFormSave] = useState(false);
    const [reRenderForm, setReRenderForm] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [autoSave, setAutoSave] = useState(false);
    const [bcColor, setBcColor] = useState("");
    const [isBaseline, setIsBaseline] = useState(false);
    const [trialStatus, setTrialStatus] = useState(1);
    const [selectedPatientName, setSelectedPatientName] = useState("");
    const [trialStartDate, setTrialStartDate] = useState<Date | null>(null);
    const [isMobileView, setIsMobileView] = useState(false);
    const [currentWeekNo, setCurrentWeekNo] = useState(0);
    const [tab, setTab] = useState(0);
    const [dayNum, setDayNum] = useState(0);
    const [getsDosageQuestions, setGetsDosageQuestions] = useState<boolean>();

    useEffect(() => {
        let windowSize = window.screen.width;
        if (windowSize <= 1024){
            setIsMobileView(true);
        }
        loadPatientData();
    },[])
    useEffect(() => {
      if (patientId){
        loadWeeklyData();
      }
    }, [patientId])

    const changeDayNum = (newDayNum: number) => {
      setDayNum(newDayNum);
      loadWeeklyData(false);
    }

    const changeTab = (newTab: number) => {
      if((currentWeekNo >= newTab) == true){
        setTab(newTab);
      }
    } 
    const loadPatientData = () => {
      respondentServie.getPatientData()
      .then((res: HttpResponse<RespondentPatientModel>) => {
        if (res && res.result){
          setGetsDosageQuestions(res.result.getsDosageQuestions);
          setPatientId(res.result.id);
        }
      })
    };

    const loadWeeklyData = (isReRenderForm?: boolean) => {
      if (getsDosageQuestions){   //Get parent questions
        if(patientId){
          respondentServie.getParentWeeklyData(patientId)
          .then((res: HttpResponse<ParentWeeklyDataModel>) => {
            if (res && res.result){
              setTrialStartDate(res.result.trialStartDate)
            }
            if (res && res.result && res.result.parentTargetSymptomWeeklyDataDetail != null && res.result.isBaseline){
              setParentWeeklyData(res.result);
              if (isReRenderForm == false){
                setIsBaseline(res.result.isBaseline);
                setTrialStatus(res.result.trialStatus);
                setTrialStartDate(res.result.trialStartDate)
              } else {
                res.result.parentTargetSymptomWeeklyDataDetail.forEach(item => {
                  if (item.isCurrentWeek){
                    setCurrentWeekNo(item.weekNo);
                    setTab(item.weekNo);
                  }
                });
                setIsBaseline(res.result.isBaseline);
                setTrialStatus(res.result.trialStatus);
                setTrialStartDate(res.result.trialStartDate)
                setReRenderForm(true);
                //todo: ask why () => setReRenderForm(false);
                setReRenderForm(false);
              }
            } else {
              setIsBaseline(false);
              setTrialStatus(0);
              setReRenderForm(true);
              //todo: ask why () => setReRenderForm(false);
              setReRenderForm(false);
            }
          }).catch((ex) => {});
        }
        
      }else{ //get teacher questions
        if(patientId){
          respondentServie.getTeacherWeeklyData(patientId)
          .then((res: HttpResponse<TeacherWeeklyDataModel>) => {
            if (res && res.result){
              setTrialStartDate(res.result.trialStartDate)
            }
            if (res && res.result && res.result.teacherTargetSymptomWeeklyList != null && res.result.isBaseline){
              setTeacherWeeklyData(res.result);
              if (isReRenderForm == false){
                setIsBaseline(res.result.isBaseline);
                setTrialStatus(res.result.trialStatus);
                setTrialStartDate(res.result.trialStartDate)
              } else {
                res.result.teacherTargetSymptomWeeklyList.forEach(item => {
                  if (item.isCurrentWeek){
                    setCurrentWeekNo(item.weekNo);
                    setTab(item.weekNo);
                  }
                });
                setIsBaseline(res.result.isBaseline);
                setTrialStatus(res.result.trialStatus);
                setTrialStartDate(res.result.trialStartDate)
                setReRenderForm(true);
                //todo: ask why () => setReRenderForm(false);
                setReRenderForm(false);
              }
            } else {
              setIsBaseline(false);
              setTrialStatus(0);
              setReRenderForm(true);
              //todo: ask why () => setReRenderForm(false);
              setReRenderForm(false);
            }
          }).catch((ex) => {});
        }
      }
    };

    const propSelectedPatientName = (data) => {
      setSelectedPatientName(data)
    }

    const postTeacherTargetSymptomsData = (data: any) => {
      let targetSymptomsData = data;
      setAutoSave(true);
  
      if (isLoading) {
        return;
      }
      respondentServie
        .postTeacherTargetSymptomsData(targetSymptomsData)
        .then((res: HttpResponse<boolean>) => {
          if (res && res.result) {
          }
          loadWeeklyData(false);
        })
        .catch((ex) => {});
    };
  
    const postTeacherAdditionalQuestionWeeklyData = (data: any) => {
      let additionalQuestion = data;
      setAutoSave(true);
  
      respondentServie
        .postTeacherAdditionalQuestionWeeklyData(additionalQuestion)
        .then((res: HttpResponse<boolean>) => {
          if (res && res.result) {
          }
          loadWeeklyData(false);
        })
        .catch((ex) => {});
    };

    const assignTeacherWeeklyDataModel = (data: any) => {
      if (data != null && data.teacherWeeklyData != null) {
        postTeacherTargetSymptomsData({
          teacherTargetSymptomWeeklyList: data.teacherWeeklyData,
        });
      }
    };
  
    const assignTeacherWeeklyQuestionModel = (data: any) => {
      if (data != null && data.teacherAdditionalQuestionList != null) {
        postTeacherAdditionalQuestionWeeklyData({
          teacherAdditionalQuestionList: data.teacherAdditionalQuestionList,
        });
      }
    };

    const postParentTargetSymptomsData = (data: any) => {
      let targetSymptomsData = data;
      setAutoSave(true);
  
      if (isLoading) {
        return;
      }
      setIsLoading(true)
      respondentServie
        .postParentTargetSymptomsData(targetSymptomsData)
        .then((res: HttpResponse<boolean>) => {
          setIsLoading(false)
          if (res && res.result) {
          }
          loadWeeklyData(false);
        })
        .catch((ex) => {setIsLoading(false)});
    };
  
    const postParentAdditionalQuestionWeeklyData = (data: any) => {
      let additionalQuestion = data;
      setAutoSave(true);
  
      respondentServie
        .postParentAdditionalQuestionWeeklyData(additionalQuestion)
        .then((res: HttpResponse<boolean>) => {
          if (res && res.result) {
          }
          loadWeeklyData(false);
        })
        .catch((ex) => {});
    };

    const assignParentWeeklyDataModel = (data: any) => {
      if (data != null && data.parentWeekDayData != null) {
        postParentTargetSymptomsData({
          parentTargetSymptomWeeklyDataDetail: data.parentWeekDayData,
        });
      }
    };
  
    const assignParentWeeklyQuestionModel = (data: any) => {
      if (data != null && data.parentAdditionalQuestionList != null) {
        postParentAdditionalQuestionWeeklyData({
          parentAdditionalQuestionDataDetail: data.parentAdditionalQuestionList,
        });
      }
    };
    
  return (
    <Fragment>
        <PageTitleSetter title="Child Weekly Status" />
        {/* <div>
        <BreadcrumSetter crumIcon={imgAddUser} crumType="static" crumItem1="Teacher" 
        crumItem2={this.state.selectedPatientName}   crumItem3=""/>
        </div> */}
        {/* if does not get dosage Questions--Mimic teacher */}
        {!getsDosageQuestions && patientId &&(
          <div className="medicalResondent__data">
          <div className="medication__table">
            <div className="child__status">
              {!reRenderForm && (
                  <TeacherDetailHeaderForm
                  patientId={patientId}
                  bcColor={bcColor}
                  isBaseline={isBaseline}
                  trialStatus={trialStatus}
                  selectedPatientName={propSelectedPatientName}
                  />
              )}
            </div>
            {!isBaseline &&
              !isPageLoading &&
              trialStatus == 0 && (
                <div className="text-center">
                  <h4 className="weekly__msg">
                    The weekly update section will be visible when the week 1
                    starts. Please connect with the parent for further
                    information.
                  </h4>
                </div>
              )}
            {trialStatus == 1 && !isPageLoading && (
              <div className="text-center">
                <h4 className="weekly__msg">
                  The trial of this child is completed. Please reach out to the
                  parent for any further information.
                  <br />
                </h4>
              </div>
            )}
            {trialStatus == 3 && !isPageLoading && (
              <div className="text-center">
                <h4 className="weekly__msg">
                  The daily update section will be available from date{" "}
                  {trialStartDate != null
                    ? dayjs(trialStartDate).format("MM/DD/YYYY")
                    : ""}
                  . <br />
                </h4>
              </div>
            )}
            {/* Present trial */}
            {trialStatus == 2 && !isBaseline && (
              <div className="text-center">
                <h4 className="weekly__msg">
                  The weekly update section will be visible when the week 1
                  starts. Please connect with the parent for further
                  information.
                </h4>
              </div>
            )}
            {<Loader loading={isPageLoading} />}
            {isBaseline &&
              trialStatus == 2 &&
              !isMobileView &&
              !isPageLoading && (
                <div className="weekly__severity_wrapper">
                  <Nav className="nav nav-pills nav-fill">
                    {teacherWeeklyData &&
                      teacherWeeklyData.teacherTargetSymptomWeeklyList.map(
                        (item, index) => {
                          return (
                            <Fragment>
                              <Tabs
                                styleName={
                                  item.weekType == 3
                                    ? "weeklySev__tab remove-cursor"
                                    : "weeklySev__tab add-cursor"
                                }
                                text={dayjs(item.weekStartDate).format(
                                  "MM/DD/YYYY"
                                )}
                                steps=""
                                stepsAct=""
                                fullText={dayjs(item.weekStartDate).format(
                                  "MM/DD/YYYY"
                                )}
                                familyTextName={"Week" + item.weekNo}
                                image=""
                                activeImage=""
                                tabChange={() =>
                                  changeTab(item.weekNo)
                                }
                                selected={item.weekNo == tab}
                              />
                            </Fragment>
                          );
                        }
                      )}
                  </Nav>
                  <div className="weekly__tableStr">
                    <TeacherWeek
                      patientId={patientId ?? ""}
                      teacherWeeklyDataModel={teacherWeeklyData}
                      assignWeeklyDataModel={assignTeacherWeeklyDataModel}
                      styleName=""
                      assignWeeklyQuestionModel={assignTeacherWeeklyQuestionModel}
                      selectedPatientName={selectedPatientName}
                      weekNo={tab}
                    />
                  </div>
                </div>
              )}
            {isBaseline &&
              trialStatus == 2 &&
              isMobileView &&
              !isPageLoading && (
                <div className="weekly__severity_wrapper">
                  <div className="weekly__tableStr">
                    <TeacherWeekMobile
                    patientId={patientId ?? ""}
                    teacherWeeklyDataModel={teacherWeeklyData}
                    assignWeeklyDataModel={assignTeacherWeeklyDataModel}
                    styleName=""
                    assignWeeklyQuestionModel={assignTeacherWeeklyQuestionModel}
                    selectedPatientName={selectedPatientName} 
                    weekNo={tab}
                    dayNum={dayNum}
                    startDate={dayjs(trialStartDate)}                    />
                  </div>
                </div>
              )}
          </div>
        </div>
        )}
        {/* if gets dosage Questions -- Mimic Parent*/}
        {getsDosageQuestions && (
          <div>
            <PageTitleSetter title="Child Weekly Status" />
        <div className="child__status">
          {!reRenderForm && (
            <ChildDetailHeader
              patientId={patientId}
              bcColor={bcColor}
              isBaseLine={isBaseline}
              selectedPatientName={propSelectedPatientName}
              trialStatus={trialStatus}
            ></ChildDetailHeader>
          )}
        </div>

        {!isPageLoading &&
          trialStatus == 0 &&
          !isBaseline && (
            <div className="text-center">
              <h4 className="weekly__msg">
                Your form has been submitted successfully!
                <br /> Someone from the clinic will connect with you shortly.
              </h4>
            </div>
          )}
        {/* Past trial */}
        {trialStatus == 1 && !isPageLoading && (
          <div className="text-center">
            <h4 className="weekly__msg">
              The trial of this child is completed. Please reach out to the
              clinician for any further information. <br />
            </h4>
          </div>
        )}
        {/* Future trial */}
        {trialStatus == 3 && !isPageLoading && (
          <div className="text-center">
            <h4 className="weekly__msg">
              The daily update section will be available from date{" "}
              {trialStartDate != null
                ? dayjs(trialStartDate).format("MM/DD/YYYY")
                : ""}
              . <br />
            </h4>
          </div>
        )}
        {/* Present trial */}
        {trialStatus == 2 && !isBaseline && (
          <div className="text-center">
            <h4 className="weekly__msg">
              Someone from the clinic will connect with you shortly.
            </h4>
          </div>
        )}
        {/* Present trial */}
        {!isPageLoading &&
          trialStatus == 2 &&
          !isMobileView &&
          isBaseline && (
            <div className="weekly__severity_wrapper">
              <div className="weekly__severity_container">
                {parentWeeklyData &&
                  parentWeeklyData.parentTargetSymptomWeeklyDataDetail.map(
                    (item, index) => {
                      return (
                        <Fragment>
                          <Tabs
                            styleName={item.weekType == 3
                              ? "weeklySev__tab remove-cursor"
                              : "weeklySev__tab add-cursor"}
                            text={dayjs(item.weekStartDate).format(
                              "MM/DD/YYYY"
                            )}
                            steps=""
                            stepsAct=""
                            fullText={dayjs(item.weekStartDate).format(
                              "MM/DD/YYYY"
                            )}
                            familyTextName={"Week" + item.weekNo}
                            image=""
                            activeImage=""
                            tabChange={() => changeTab(item.weekNo)}
                            selected={item.weekNo == tab}
                          />
                        </Fragment>
                      );
                    }
                  )}
              </div>
              <div className="weekly__tableStr">
                {
                  <ChildWeek
                    patientId={patientId ?? ""}
                    parentWeeklyDataModel={parentWeeklyData}
                    assignWeeklyDataModel={assignParentWeeklyDataModel}
                    assignWeeklyQuestionModel={assignParentWeeklyQuestionModel}
                    styleName=""
                    selectedPatientName={selectedPatientName}
                    weekNo={tab}
                  />
                }
              </div>
            </div>
          )}
        {!isPageLoading &&
          trialStatus == 2 &&
          isMobileView &&
          isBaseline && (
            <div className="weekly__severity_wrapper">
              {/* <Nav className="nav nav-pills nav-fill">
                {parentWeeklyData &&
                  parentWeeklyData.parentTargetSymptomWeeklyDataDetail.map(
                    (item, index) => {
                      return (
                        <Fragment>
                          <Tabs
                            styleName={"weeklySev__tab add-cursor"}
                            text={dayjs(item.weekStartDate).format(
                              "MM/DD/YYYY"
                            )}
                            steps=""
                            stepsAct=""
                            fullText={dayjs(item.weekStartDate).format(
                              "MM/DD/YYYY"
                            )}
                            familyTextName={"Week" + item.weekNo}
                            image=""
                            activeImage=""
                            tabChange={() => changeTab(item.weekNo)}
                            selected={item.weekNo == tab}
                          />
                        </Fragment>
                      );
                    }
                  )}
              </Nav>
              <br />
              <Nav className="nav nav-pills nav-fill">
                {parentWeeklyData &&
                  parentWeeklyData.parentTargetSymptomWeeklyDataDetail[tab - 1].parentTargetSymptomWeekDayDataList.map(
                    (item, index) => {
                      return (
                        <Fragment>
                          <Tabs
                            styleName={"weeklySev__tab add-cursor"}
                            text={dayjs(item.weekDayDate).format(
                              "MM/DD/YYYY"
                            )}
                            steps=""
                            stepsAct=""
                            fullText={dayjs(item.weekDayDate).format(
                              "MM/DD/YYYY"
                            )}
                            familyTextName={"Day " + item.weekDayNo}
                            image=""
                            activeImage=""
                            tabChange={() => changeDayNum(item.weekDayNo)}
                            selected={item.weekDayNo == dayNum}
                          />
                        </Fragment>
                      );
                    }
                  )}
              </Nav> */}
              <div className="weekly__tableStr">
                {
                  <ChildWeekMobile
                    patientId={patientId ?? ""}
                    parentWeeklyDataModel={parentWeeklyData}
                    assignWeeklyDataModel={assignParentWeeklyDataModel}
                    assignWeeklyQuestionModel={assignParentWeeklyQuestionModel}
                    styleName=""
                    selectedPatientName={selectedPatientName}
                    weekNo={tab}
                    dayNum={dayNum}
                    startDate={dayjs(trialStartDate)}
                  />
                }
              </div>
            </div>
          )}
          </div>
        )}
      </Fragment>
  );
}

export default RespondentWeeklyUpdate