import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Tooltip } from "antd";
import { Link, NavLink } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { HttpResponse } from "../../../core";
import { Loader } from "../../../shared/loaders";
import { PatientListFilterModel } from "../models/patient-list-filter.model";
import { PatientListResponseModel } from "../models/patient-list-response.model";
import { PatientListModel } from "../models/patient-list.model";
import { PatientService } from "../services";
import Heading from "../../heading";
import Tabs from "../../tabs";
import Search from "../../search";
import searchGrey from "../../../assets/images/svg-icons/search-list.svg";
import searchBlue from "../../../assets/images/svg-icons/search-b.svg";
import filterGrey from "../../../assets/images/svg-icons/filter-g.svg";
import filterBlue from "../../../assets/images/svg-icons/filter-b.svg";
import "./index.scss";
import { Gender } from "../../../shared/enums/gender.enum";
import oops from "../../../assets/images/svg-icons/no-result-found.svg";
import { UserStatus } from "../../../shared/enums/user-status.enum";
import { UserAccountStatus } from "../../../shared/enums/show-user-status.enum";
import { Interface } from "readline";

interface IState {
  result: PatientListResponseModel | any;
  tab: number;
  results: PatientListModel[];
  initials?: string;
  searchText: string;
  isLoading?: boolean;
  totalItems: number;
  scrolling?: boolean;
  pageSize: number;
  currentPage: number;
  pageCount: number;
  hasMore: boolean;
  componntUpdate: boolean;
  patientId: string;
  accountStatus: number;
}
interface IProps {
  isRefresh: boolean;
  counter?: any,
  id: any;
}
export class PatientList extends Component<IProps, IState> {
  private patientService: PatientService;
  isComponentMounted: boolean = true;
  scrollListener: any;
  constructor(props: IProps) {
    super(props);
    this.patientService = new PatientService();
    this.state = {
      result: {
        collection: [],
        paging: {
          currentPage: 1,
          pageSize: 0,
          totalItems: 0,
          pageCount: 0,
        },
      },
      tab: 0,
      searchText: "",
      initials: "",
      isLoading: false,
      scrolling: false,
      results: [],
      pageSize: 10,
      currentPage: 1,
      totalItems: 0,
      pageCount: 0,
      hasMore: true,
      componntUpdate: true,
      patientId: this.props.id,
      accountStatus: 0
    };
  }
  componentDidMount() {
    // let wndowSize = window.screen.width;
    // let pageHeight = document.documentElement.scrollHeight;
    // if(wndowSize <= 1024){
    //   $('.scrollBar').height(pageHeight - 400);
    // }
    this.setState({ searchText: "" }, () => {
      this.loadData();
    })
  }
  componentWillReceiveProps() {
    if (window.location.pathname == "/patient/addPatient/0/pt" || window.location.pathname == "/patient/addPatient/0"  || window.location.pathname =="/patient/addPatient/") {
      this.setState({ searchText: "" }, () => {
        this.loadData();
      })
    }
  }

  changeTab(tab: any) {
    this.setState({
      tab,
      accountStatus: 0,
      currentPage: 1,
      searchText: "", scrolling: false,

    },
      () => {
        this.loadData();
      }
    );
  }
  loadData = () => {
    if (!this.state.scrolling) {
      this.setLoading(true);
    }
    let filterModel: PatientListFilterModel = {
      searchText: this.state.searchText,
      pageSize: this.state.pageSize,
      currentPage: this.state.currentPage,
      totalItems: this.state.totalItems,
      accountStatus: this.state.accountStatus
    };
    this.patientService
      .getPatientList(filterModel)
      .then((res: HttpResponse<PatientListResponseModel>) => {
        if (!this.state.scrolling) {
          this.setLoading(false);
        }
        if (res && res.result) {
          let patientList = this.state.scrolling ? this.state.result?.collection || [] : [];
          patientList = patientList.concat(res.result.collection);
          let result = res.result;
          result.collection = patientList;

          if (this.state.scrolling) {
            this.setState({
              result: result,
              scrolling: false,
            });
          } else {
            // if (
            //   (this.state.patientId == "" || this.state.patientId == undefined) &&
            //   result.collection.length > 0
            // ) {
            //   this.props.history.push(`/patient/addPatient/${result.collection[0].id}`);
            // }
            this.setState({
              result: result,
              scrolling: false,
              patientId:
                (this.state.patientId == undefined || this.state.patientId == "") &&
                  result.collection.length > 0
                  ? res.result.collection[0].id
                  : this.state.patientId,
            });
          }

          this.setState(
            {
              result: result,
              scrolling: false,
            },
            () => { }
          );
        }
      })
      .catch((ex) => {
        this.setLoading(false);
      });
  };
  handleSearchChange = (event: any) => {
    this.setState(
      {
        currentPage: 1,
        scrolling: false,
        searchText: event.target.value,
      },
      () => {
        this.loadData();
      }
    );
  };
  private setLoading(loading: boolean) {
    this.setState({ isLoading: loading });
  }
  loadMore = () => {
    this.setState(
      (prevState) => ({
        currentPage: prevState.currentPage + 1,
        scrolling: true,
      }),
      () => {
        this.loadData();
      }
    );
  };

  // onAddNewClick = () => {
  //   this.setState(
  //     // {
  //     //   currentUserName: "Add Patient",
  //     // },
  //     () => this.props.history.push("/patient/addPatient/")
  //   );
  // };
  handleAccountStatusChange = (event) => {
    this.setState(
      {
        currentPage: 1,
        scrolling: false,
        accountStatus: parseInt(event.target.value),
      },
      () => {
        this.loadData();
      }
    );
  };
  handleAccountStatus = (val: any) => {
    let value = "-";
    if (val == 1) value = UserAccountStatus.AccountInitiated;
    else if (val == 2) value = UserAccountStatus.AccountInitiatedAndInvited;
    else if (val == 3) value = UserAccountStatus.AccountActivated;
    else if (val == 4) value = UserAccountStatus.AccountDeactivated;
    return value;
  };
  handleBorderStyle = (value?: number) => {
    let borderStyle = "";
    if (value == 1) borderStyle = "1px solid var(--color-shadeOrange)";
    else if (value == 2) borderStyle = "1px solid var(--color-primary)";
    else if (value == 3) borderStyle = "1px solid var(--color-shadeGreen)";
    else if (value == 4) borderStyle = "1px solid var(--color-shadeBrown)";

    return borderStyle;

  };
  render() {
    let options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const { tab } = this.state;
    return (
      <section className="list-container">
        <div className="list__heading__search">
          <Heading heading="Patients" subHeading="" styleName="medicalData__style" />
          <div className="listing__filters">
            <Tabs
              styleName="listFilterTab"
              text=""
              steps=""
              stepsAct=""
              fullText=""
              familyTextName=""
              image={searchGrey}
              activeImage={searchBlue}
              tabChange={() => this.changeTab(0)}
              selected={tab === 0}
              title="Search by Name/MRN"
            />
            <Tabs
              styleName="listFilterTab"
              text=""
              steps=""
              stepsAct=""
              fullText=""
              familyTextName=""
              image={filterGrey}
              activeImage={filterBlue}
              tabChange={() => this.changeTab(1)}
              selected={tab === 1}
              title="Filter by Patient's Status"
            />
            <NavLink to="/patient/addPatient/0">
              <Tooltip placement="top" title="Add New Patient">
                <PlusOutlined className="add__icon" />
              </Tooltip>
            </NavLink>
          </div>
        </div>
        {tab === 0 && (
          <div className="list__heading__search">
            <Search
              placeholder="Search by Name/MRN"
              type="text"
              onChange={this.handleSearchChange}
              value={this.state.searchText}
              variants="search__list"
              name="searchText"
              searchIcon=""
            />
          </div>
        )}
        {tab === 1 && (
          <div className="list__heading__search">
            <select
              name="relationshipId"
              defaultValue={this.state.accountStatus}
              onChange={this.handleAccountStatusChange}
              className="form-control"
            // disabled={props.isReadOnly ? true : false}
            // ref={register({
            //   required: "Please Select Relationship.",
            // })}
            >
              <option value={UserStatus.All}>Filter by Patient's Status</option>
              <option value={UserStatus.AccountInitiated}>Account Initiated</option>
              <option value={UserStatus.AccountInitiatedAndInvited}>Account Initiated & Invited</option>
              <option value={UserStatus.AccountActivated}>Account Activated</option>
              <option value={UserStatus.AccountDeactivated}>Account Deactivated</option>
            </select>
            <i className="fa fa-angle-down relation_arrow"></i>
          </div>
        )}
        <div id="targetDiv" className="scrollBar">
          <InfiniteScroll
            dataLength={this.state.result.collection.length}
            next={this.loadMore}
            hasMore={true}
            loader={false}
            scrollableTarget="targetDiv"
          >
            <ul className="user-list">
              {!this.state.isLoading &&
                this.state.result.paging.totalItems > 0 &&
                this.state.result.collection.map((item: PatientListModel, index) => {
                  return (
                    <li className="user-list-item" key={index}>
                      <NavLink to={"/patient/addPatient/" + item.id} className="user-link">
                        <div className="user-icon">
                          <span>{item.initials}</span>
                        </div>
                        <div className="name-role">
                          <span className="name">
                            {item.lastName + ","} {item.firstName}
                          </span>
                          <span className="mrNo">MRN: {item.mr_No}</span>
                          <span className="mrNo">
                            Date of Birth:{" "}
                            {item.dateOfBirth == null
                              ? ""
                              : new Date(item.dateOfBirth).toLocaleDateString("en", options)}
                            ,{" "}
                            {item.gender == null
                              ? ""
                              : item.gender == Gender.Male
                                ? "Male"
                                : item.gender == Gender.Female
                                  ? "Female"
                                  : "Other"}
                          </span>
                          <span className="acc__status" style={{ border: this.handleBorderStyle(item.accountStatus) }}>{this.handleAccountStatus(item.accountStatus)}</span>
                        </div>
                      </NavLink>
                    </li>
                  );
                })}
              {!this.state.isLoading && this.state.result.paging.totalItems === 0 && (
                <div className="text-danger text-center">
                  <div className="noRecord__table">
                    <img src={oops} alt="oops" />
                    <h2>No result found</h2>
                    <p>There are no matching records for your search, please try again.</p>
                  </div>
                </div>
              )}
              {this.state.isLoading && <Loader loading={this.state.isLoading} />}
            </ul>
          </InfiniteScroll>
        </div>
      </section>
    );
  }
}

export default PatientList;
